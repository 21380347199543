import { Flex } from "@chakra-ui/react";
import { CommentForm } from "./CommentForm";
import { Comment } from "./Comment";
import { useHydrateComments } from "../../hooks/useHydrateComments";
import { useComments } from "../../hooks/useComments";

export function CommentsMenuWrapper() {
  useHydrateComments();
  return <CommentsMenu />;
}

export function CommentsMenu() {
  const {
    releaseCommentsData,
    newCommentValue,
    setNewCommentValue,
    postReleaseComment,
    setParentComment,
    parentComment,
  } = useComments();

  return (
    <Flex
      position="relative"
      height="100%"
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      paddingTop="96px"
    >
      <Flex
        p="24px"
        pt="0"
        overflowY="auto"
        maxWidth="100%"
        height="calc(100% - 80px)"
        width="100%"
        flexDirection="column"
        position="relative"
        justifyContent="flex-start"
        gap="24px"
      >
        {releaseCommentsData?.map((comment) => (
          <Comment
            key={comment.id}
            replyTo={setParentComment}
            comment={comment}
          />
        ))}
      </Flex>
      <CommentForm
        newCommentValue={newCommentValue}
        setNewCommentValue={setNewCommentValue}
        postReleaseComment={postReleaseComment}
        parentComment={parentComment}
      />
    </Flex>
  );
}
