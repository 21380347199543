import { useCallback, useMemo } from "react";
import { useAccountData } from "../../../hooks/useAccountData";
import { Comic } from "../../../types";

export function useMatchPercentage(comic: Comic) {
  const accountData = useAccountData();

  const getMatchPercentage = useCallback(() => {
    const themeId = 2;

    const comicThemeList =
      comic.comic_facets?.filter((facet) => {
        return facet.comic_facet_group_id == themeId;
      }) || [];

    const genreWeight = 1;
    const typeWeight = 1;
    const themeWeight = comicThemeList.length;

    const maxUnits = genreWeight + typeWeight + themeWeight;

    let matchCounter = 0;

    if (accountData) {
      const { interests } = accountData;
      const {
        comic_genres: interestGenres,
        comic_types: interestTypes,
        comic_facets: interestThemes,
      } = interests;

      if (interestGenres.includes(comic.section_id)) {
        matchCounter++;
      }
      if (interestTypes.includes(comic.comic_type_id)) {
        matchCounter++;
      }
      comicThemeList.forEach((comicTheme) => {
        if (interestThemes.includes(comicTheme.comic_facet_group_option_id)) {
          matchCounter++;
        }
      });

      return Math.round((matchCounter * 100) / maxUnits);
    }

    return 0;
  }, [accountData, comic]);

  const hasMatchPercentage = useMemo(() => {
    if (!accountData) return false;
    const { interests } = accountData;
    const {
      comic_genres: interestGenres,
      comic_types: interestTypes,
      comic_facets: interestThemes,
    } = interests;

    return (
      interestGenres.length || interestTypes.length || interestThemes.length
    );
  }, [accountData]);

  return { getMatchPercentage, hasMatchPercentage };
}
