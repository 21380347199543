import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface CollapsibleNavBarProps {
  isCollapsed?: boolean;
  children: ReactNode;
  shouldCollapse?: boolean;
  isWrapped?: boolean;
}

export function CollapsibleNavBar({
  isCollapsed = false,
  children,
  shouldCollapse = true,
  isWrapped = false,
}: CollapsibleNavBarProps) {
  const maxNavBarHeight = isWrapped ? "116px" : "100px";
  return shouldCollapse ? (
    <Box
      width="full"
      maxHeight={!isCollapsed ? maxNavBarHeight : "0"}
      transition={"opacity 0.2s ease-out, max-height 0.3s ease-out"}
      opacity={!isCollapsed ? 1 : 0}
    >
      {children}
    </Box>
  ) : (
    children
  );
}
