import { useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { findComponentForRoute } from "../../utils/findComponentForRoute";
import { applicationRoutes } from "../../screens/router";

interface PreloadLinkProps extends LinkProps {
  name?: string;
}
export function PreloadLink({ to, name, ...props }: PreloadLinkProps) {
  const handlePreloadOnHover = useCallback(() => {
    const matchedComponent = findComponentForRoute(to, applicationRoutes);
    if (!matchedComponent) {
      return;
    }

    matchedComponent.preload?.();
  }, [to]);

  return (
    <Link
      aria-label={name}
      to={to}
      {...props}
      onMouseEnter={handlePreloadOnHover}
    />
  );
}
