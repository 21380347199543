import { appStore } from "../../appStore";
import { useHookstate } from "@hookstate/core";
import { ToggleInterestType } from "./types";
import { ComicCategoryEntity } from "../../services/gc/types";
import { ComicInterestsCarousel } from "./ComicInterestsCarousel";

export function ComicGenresStep({
  activeIds,
  toggleInterest,
}: {
  activeIds: number[];
  toggleInterest: ToggleInterestType;
}) {
  const comicGenres = useHookstate(
    appStore.init.comic_genres,
  ).get() as ComicCategoryEntity[];

  return (
    <ComicInterestsCarousel
      type="comic_genres"
      activeIds={activeIds}
      toggleInterest={toggleInterest}
      interests={comicGenres}
    />
  );
}
