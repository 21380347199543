import { Flex } from "@chakra-ui/react";
import { ImmutableArray, State } from "@hookstate/core";
import { FilterState as LibraryFilterState } from "../../screens/library/components/LibraryFilters";
import { FilterState as BrowseFilterState } from "../../screens/browse/components/filters/BrowseFilters";
import { FilterState as ArtistFilterState } from "../../screens/artist/components/ArtistComicsFilters";
import { SortDurationMenu } from "./SortDurationMenu";
import { SortOrderMenu } from "./SortOrderMenu";

export interface SortMenusProps {
  invalidate: () => void;
  filterState:
    | State<LibraryFilterState>
    | State<BrowseFilterState>
    | State<ArtistFilterState>;
  sortOrders?: ImmutableArray<{
    id: string;
    name: string;
  }>;
}
export function SortMenus({
  invalidate,
  filterState,
  sortOrders,
}: SortMenusProps) {
  return (
    <Flex position="relative" gap={2}>
      <SortOrderMenu
        invalidate={invalidate}
        filterState={filterState}
        sortOrders={sortOrders}
      />
      <SortDurationMenu invalidate={invalidate} filterState={filterState} />
    </Flex>
  );
}
