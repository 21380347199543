import { Button, Text } from "@chakra-ui/react";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ReactionTypeEntity } from "../../../../services/gc/types/reactionTypeEntity";
import {
  ReactionSummaryEntity,
  Release,
  ServerSingleResponse,
} from "../../../../types";
import { useUserData } from "../../../../hooks/useUserData";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";

interface ReactionToggleProps {
  reactionType: ReactionTypeEntity;
  reactionSummary?: ReactionSummaryEntity;
  toggleReaction: UseMutateFunction<
    AxiosResponse<ServerSingleResponse<ReactionSummaryEntity>, any>,
    Error,
    number,
    AxiosResponse<ServerSingleResponse<Release>, any>
  >;
}
export function ReactionToggle({
  reactionType,
  reactionSummary,
  toggleReaction,
}: ReactionToggleProps) {
  const { userData } = useUserData();
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.reaction,
  });

  return (
    <Button
      variant="reactionToggle"
      size="thin"
      gap="14px"
      isActive={reactionSummary?.is_clicked}
      onClick={() => {
        if (userData) {
          toggleReaction(reactionType.id);
        } else {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.signup,
            options: {
              onAuthenticated: () => toggleReaction(reactionType.id),
              navigationContext,
            },
          });
        }
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: reactionType.display_name }} />
      <Text fontWeight="bold">{reactionSummary?.value ?? 0}</Text>
    </Button>
  );
}
