import { apiClient } from "../../../services/axiosInstance";
import { Comic, ServerListResponse } from "../../../types";

export function getLibraryCount() {
  return apiClient.get<
    ServerListResponse<{
      comic_user_reading_status_id: number;
      count: number;
    }>
  >(`/v1/account/library/counts`);
}
export function getLibraryList(queryParams: URLSearchParams) {
  return apiClient.get<ServerListResponse<Comic>>(
    `/v1/account/library${queryParams.size > 0 ? "?" + queryParams : ""}`,
  );
}
export const GET_LIBRARY_COUNT_QUERY_KEY = ["library-count"];
export const GET_LIBRARY_LIST_QUERY_KEY = ["library-list"];
