import { Icon, IconProps } from "@chakra-ui/react";

export function ArrowForwardIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08301 10C2.08301 9.76989 2.26956 9.58334 2.49967 9.58334H17.4997C17.7298 9.58334 17.9163 9.76989 17.9163 10C17.9163 10.2301 17.7298 10.4167 17.4997 10.4167H2.49967C2.26956 10.4167 2.08301 10.2301 2.08301 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.039 5.5387C13.2017 5.37598 13.4656 5.37598 13.6283 5.5387L17.795 9.70536C17.8731 9.7835 17.917 9.88948 17.917 9.99999C17.917 10.1105 17.8731 10.2165 17.795 10.2946L13.6283 14.4613C13.4656 14.624 13.2017 14.624 13.039 14.4613C12.8763 14.2986 12.8763 14.0347 13.039 13.872L16.9111 9.99999L13.039 6.12795C12.8763 5.96523 12.8763 5.70141 13.039 5.5387Z"
        fill="currentColor"
      />
    </Icon>
  );
}
