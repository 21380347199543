import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { PreloadLink } from "../../../components/navigation/PreloadLink.tsx";
import { ConversationEntity } from "../../../types.ts";
import { postAcknowledgeConversation } from "../api/inboxScreenApi.ts";

interface ConversationListProps {
  results: ConversationEntity[];
}

export default function ConversationList({ results }: ConversationListProps) {
  const { mutate: acknowledgeConversation } = useMutation({
    mutationFn: postAcknowledgeConversation,
  });

  const handleAckConversation = useCallback(async (id: number) => {
    await acknowledgeConversation(id);
  }, []);

  return (
    <Flex
      py={3}
      px={1}
      width="full"
      backgroundColor="charcoal.charcoal"
      flexDirection="column"
      gap={1}
    >
      {results.map((conversation: ConversationEntity, i) => (
        <>
          <PreloadLink
            to={`/my/inbox/${conversation.thread_id}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleAckConversation(conversation.thread_id);
            }}
          >
            <Flex
              width="full"
              direction="row"
              gap={2}
              cursor="pointer"
              justifyContent="space-between"
            >
              <Flex direction="row" gap={2}>
                <Image
                  loading="lazy"
                  src={conversation.last_author.avatar_small_url}
                  width="48px"
                  height="48px"
                  alt={`Avatar for ${conversation.last_author.name}`}
                />
                <Flex direction="column" color="neutral.white">
                  <Text
                    fontSize="m"
                    dangerouslySetInnerHTML={{ __html: conversation.body }}
                  />
                  <Text fontSize="xs">{conversation.last_post_date}</Text>
                </Flex>
              </Flex>
            </Flex>
          </PreloadLink>
          {i < results.length - 1 ? (
            <Box minHeight="1px" w="full" background="neutral.500" />
          ) : null}
        </>
      ))}
    </Flex>
  );
}
