export const PAGE_LAYOUT_TYPES = {
  auto: "auto",
  single: "single",
  double: "double",
  vertical: "vertical",
  interactive: "interactive",
};

export const PAGE_SIZING_TYPES = {
  auto: "auto",
  height: "height",
  width: "width",
};

export const READING_DIRECTION_TYPES = {
  leftToRight: "l",
  rightToLeft: "r",
  vertical: "v",
};

export const READER_PADDING = 10;
export const READER_PADDING_PX = READER_PADDING + "px";

export const P2P_MAX_SCALE = 2;
export const FRAGMENT_BASE_SCALE = 0.95;

export const CHAPTER_TYPES: Record<string, string> = {
  b: "Bandes DessinÃ©e",
  c: "Issue",
  e: "Episode",
  g: "Graphic Novel",
  k: "Omake",
  m: "Compendium",
  n: "One-shot",
  o: "Omnibus",
  s: "Special",
  v: "Volume",
};
