import { Text, Flex, Spinner, Divider } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Comic, Release } from "../../../types";
import {
  getComicReleases,
  getRelease,
  useSlideInMenu,
} from "../hooks/useSlideInMenu";
import { useComicReleasesData } from "../../../screens/reader/hooks/hookstate/useComicReleasesData";
import { useState } from "react";
import { ReleaseInfoHeader } from "./ReleaseInfoHeader";
import { ReleaseInfoCoverCarousel } from "./ReleaseInfoCoverCarousel";
import { ReleaseInfoPageCarousel } from "./ReleaseInfoPageCarousel";
import { ShowMoreSection } from "../../containers/ShowMoreSection";
import { ArtistSection } from "../comicDetails/ArtistSection";
import { ComicContributorList } from "../comicDetails/ComicDescription";
import { ComicLabels } from "../comicDetails/ComicLabels";
import { ReleaseInfoActions } from "./ReleaseInfoActions";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";

export function ReleaseInfoMenu() {
  const { releaseUuid, comicSlug } = useSlideInMenu();

  const [activeReleaseUuid, setActiveReleaseUuid] = useState(releaseUuid);

  const { comicReleasesData } = useComicReleasesData();
  const { releaseData } = useReleaseData();

  const { data: fetchedRelease, isLoading } = useQuery({
    enabled: !releaseData || releaseData.key !== activeReleaseUuid,
    queryKey: ["releaseDetailsRelease", activeReleaseUuid],
    queryFn: () => {
      return getRelease(activeReleaseUuid ?? "");
    },
  });

  const { data: fetchedComicReleasesResponse } = useQuery({
    enabled: !comicReleasesData,
    queryKey: ["fetchedComicReleases", comicSlug],
    queryFn: () => {
      return getComicReleases(comicSlug ?? "");
    },
  });

  const fetchedComicReleasesData =
    fetchedComicReleasesResponse?.data.payload.results;
  var releases =
    (comicReleasesData as Release[]) || (fetchedComicReleasesData ?? []);

  const activeRelease =
    releaseData && releaseData.key === activeReleaseUuid
      ? releaseData
      : fetchedRelease?.data.payload.results;

  if (!activeRelease && !releases) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  const comic = activeRelease?.comic as Comic;

  return (
    <Flex
      flexDirection="column"
      pt="64px"
      overflowY="auto"
      pb="24px"
      height="100%"
      width="100%"
    >
      <ReleaseInfoCoverCarousel
        releases={releases}
        activeReleaseUuid={activeReleaseUuid}
        setActiveReleaseUuid={setActiveReleaseUuid}
      />
      {!activeRelease || isLoading ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner color="blaze.blaze" />
        </Flex>
      ) : (
        <Flex flexDirection="column" pt="16px" px="24px" gap="16px">
          <ReleaseInfoHeader comic={comic} release={activeRelease as Release} />
          <Divider color="transparent.white.10" />
          <ReleaseInfoActions release={activeRelease as Release} />
          <Divider color="transparent.white.10" />
          <ReleaseInfoPageCarousel release={activeRelease as Release} />
          <Divider color="transparent.white.10" />
          <ComicLabels comicData={comic} />
          <Divider color="transparent.white.10" />
          <ShowMoreSection collapsedHeight={150} expandedMarginBottom="24px">
            <Text
              fontFamily="Roboto"
              fontSize="14px"
              fontWeight="400"
              lineHeight="22.4px"
              color="neutral.300"
            >
              {activeRelease.description}
            </Text>
          </ShowMoreSection>
          {comic.artist && <ArtistSection artist={comic.artist} />}
          {comic.contributors && (
            <>
              <Divider color="transparent.white.10" />
              <ComicContributorList comicData={comic} byRole />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}
