import { Box, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { StripePayment, StripePaymentConfigTypes } from "./StripePayment";
import {
  GET_RELEASE_QUERY_KEY,
  postReleasePDFPurchase,
} from "../../../screens/reader/api/readerScreenApi";
import { useState } from "react";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import axios from "axios";
import { TextField } from "../../fields/TextField";
import { ServerResponse } from "../../../types";
import { ImmutableObject } from "@hookstate/core";
import { Release } from "../../../types";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { FunnelComponentProps } from "../../funnel/types";
import useValidatedAmount from "../../fields/hooks/useValidatedAmount";
import { usePaymentMutation } from "../hooks/usePaymentMutation";

interface PdfModalFooterProps {
  release?: ImmutableObject<Release>;
}

export function PdfModalFooter({
  release,
  isModal,
  setFunnelType,
}: FunnelComponentProps & PdfModalFooterProps) {
  if (!release) return null;
  const {
    display_price,
    is_discounted,
    display_price_discounted,
    allow_variable_max_price,
    price_float,
    price_float_discounted,
  } = release.price;

  const pdfPriceString = is_discounted
    ? display_price_discounted
    : display_price;
  const pdfPriceFloat = is_discounted
    ? (price_float_discounted ?? price_float)
    : price_float;

  const [netAmountError, setNetAmountError] = useState<string>();
  const [variablePrice, setVariablePrice] = useState<number | undefined>(
    pdfPriceFloat,
  );
  const { value, setValue } = useValidatedAmount(
    setVariablePrice,
    pdfPriceFloat.toString(),
  );
  const invalidateRelease = useInvalidateQueries(GET_RELEASE_QUERY_KEY);
  const mutationFn = (stripePaymentConfig: StripePaymentConfigTypes) => {
    return postReleasePDFPurchase(release.id, {
      ...stripePaymentConfig,
      netAmount: variablePrice ?? pdfPriceFloat ?? price_float,
    });
  };

  const onSuccess = () => {
    setFunnelType(FUNNEL_TYPES.downloadPdf);
    invalidateRelease();
  };

  const onError = (error: Error) => {
    let errorMessage = t("components.payments.donate.donationDeclined");
    if (axios.isAxiosError<ServerResponse<string>>(error)) {
      // Check for validation error for netAmount
      const errorData = error.response?.data;
      const netAmountField = errorData?.payload.form_fields?.find(
        (formField) => formField.name === "netAmount",
      );

      if (netAmountField?.error) {
        setNetAmountError(netAmountField?.error);
        return;
      }

      errorMessage = error.response?.data?.payload.results || errorMessage;
    }

    return errorMessage;
  };

  const {
    mutate: purchasePDF,
    failedCard,
    cardValidationError,
    waiting,
  } = usePaymentMutation(mutationFn, onSuccess, onError);

  return (
    <Flex flexDirection="column" width="100%" gap="24px">
      {!!allow_variable_max_price && (
        <>
          <Flex flexDirection="column">
            <Text fontSize="14px" mb={4} color="dune.700">
              {t("components.payments.pdf.enterAmountYouWantToPay", {
                minPrice: pdfPriceString,
              })}
            </Text>
            <TextField
              type="text"
              placeholder=""
              height="48px"
              variant="outline"
              prefix="$"
              inputMode="decimal"
              error={netAmountError}
              value={`${value}`}
              onBlur={(_) => {
                if (
                  !variablePrice ||
                  (variablePrice && variablePrice < pdfPriceFloat)
                ) {
                  return setNetAmountError(
                    `${t("components.payments.pdf.minimumAmount")} ${pdfPriceString} `,
                  );
                } else {
                  setNetAmountError(undefined);
                }
              }}
              onChange={setValue}
            />
          </Flex>
          <Box width="100%" height="1px" background="transparent.white.10" />
        </>
      )}

      <StripePayment
        waiting={waiting}
        completePurchase={purchasePDF}
        isDisabled={!!netAmountError || !variablePrice}
        submitButtonText={
          allow_variable_max_price
            ? t("components.payments.pdf.purchaseRelease")
            : `${t("components.payments.pdf.purchaseReleaseFor")} ${pdfPriceString}`
        }
        responseError={cardValidationError}
        failedCard={failedCard}
        isModal={isModal}
      />
    </Flex>
  );
}
