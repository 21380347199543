import { Box, Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea } from "../../funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { useHookstate } from "@hookstate/core";
import { rootComponentsStore } from "../../layouts/RootLayout";

export function LoginMenu() {
  const { t } = useTranslation();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.navBar,
  });

  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const funnelType = useHookstate(rootComponentsStore.funnelModal.type).get();
  const showingReaderPaywall =
    inReader && funnelType === FUNNEL_TYPES.subscription;

  return (
    <Flex direction="row" pr={2} align="center">
      <Button
        id="btnNavLogin"
        background="brand.500"
        color="white"
        size="sm"
        onClick={() => {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.login,
            options: {
              navigationContext,
              modalContext: FunnelContextArea.navBar,
              inPaywall: showingReaderPaywall,
            },
          });
        }}
      >
        {t("components.navigation.auth.login")}
      </Button>
      <Box width="8px" />
      <Button
        id="btnNavSignup"
        variant="primary"
        size="sm"
        onClick={() => {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.signup,
            options: {
              navigationContext,
              modalContext: FunnelContextArea.navBar,
              inPaywall: showingReaderPaywall,
            },
          });
        }}
      >
        {t("components.navigation.auth.signUpItsFree")}
      </Button>
    </Flex>
  );
}
