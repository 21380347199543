import { ImmutableObject } from "@hookstate/core";
import { t } from "i18next";

import { Release } from "../../../types";
import { Flex, Text } from "@chakra-ui/react";

export function ReleasePurchaseData({
  release,
  alignItems = "start",
  pagesTextSuffix,
}: {
  release: ImmutableObject<Release>;
  alignItems?: "start" | "center";
  pagesTextSuffix?: string;
}) {
  return (
    <Flex flexDirection="column" width="100%" alignItems={alignItems}>
      <Text variant="modalBody">{release.comic.name}</Text>
      <Text variant="modalTitle" mt={1}>
        {release.title}
      </Text>
      <Text variant="modalBody" color="dune.700" mt={1}>
        {release.page_count}{" "}
        {pagesTextSuffix ?? t("components.payments.pdf.pagesPDF")}
      </Text>
    </Flex>
  );
}
