import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useReleaseTags } from "../../hooks/useReleaseTags";
import { CTAButtons } from "./CTAButtons";
import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { useIsSmallBreakpoint } from "../../../../utils/useBreakpoints";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function ReleaseSummary() {
  const releaseData = useHookstate(readerDataStore.releaseData).get({
    noproxy: true,
  });
  const tags = useReleaseTags(releaseData);
  const isSmallBreakPoint = useIsSmallBreakpoint();
  const { onToggleSlideInMenu } = useSlideInMenu(true);

  return (
    <Box
      as={motion.div}
      animate={{ x: [-10, 25], opacity: [0, 1] }}
      position="absolute"
      top="85px"
      height="calc(100% - 200px)"
      width="calc(100% - 88px)"
      left={0}
      display="flex"
      flexDirection="column"
      gap="24px"
    >
      <Flex flexDirection="column">
        <Link
          aria-label={`Read ${releaseData?.comic.name}`}
          href={releaseData?.comic.url}
        >
          <Text
            color="reader.neutral.200"
            fontSize={isSmallBreakPoint ? "16px" : "20px"}
            fontWeight="600"
            lineHeight={isSmallBreakPoint ? "24px" : "28px"}
            _hover={{
              textDecoration: "underline",
            }}
          >
            {releaseData?.comic.name}
          </Text>
        </Link>
        <Text
          fontSize={isSmallBreakPoint ? "24px" : "30px"}
          fontWeight="600"
          lineHeight={isSmallBreakPoint ? "32px" : "36px"}
          textOverflow="clip"
        >
          {releaseData?.title}
        </Text>
        <Box
          aria-label={`View ${releaseData?.comic?.artist?.roman_name}`}
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            onToggleSlideInMenu(SLIDE_IN_MENUS.publisher, {
              artistId: releaseData?.comic?.artist.id,
            });
          }}
        >
          <Text
            color="reader.blue.200"
            fontSize={isSmallBreakPoint ? "16px" : "20px"}
            fontWeight="600"
            lineHeight={isSmallBreakPoint ? "24px" : "28px"}
            _hover={{
              textDecoration: "underline",
            }}
          >
            {releaseData?.comic?.artist?.roman_name}
          </Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" gap="6px">
        {tags.map((tag, i) => {
          if (!tag) return null;
          const { text, borderColor } = tag;
          return (
            <Box
              key={i}
              py="4px"
              px="12px"
              borderRadius="full"
              border="1px solid"
              borderColor={borderColor ? borderColor : "transparent.white.40"}
              width="fit-content"
            >
              <Text
                fontSize="10px"
                fontWeight="600"
                lineHeight="15px"
                textTransform="uppercase"
              >
                {text}
              </Text>
            </Box>
          );
        })}
      </Flex>
      {releaseData && <CTAButtons releaseData={releaseData} />}
    </Box>
  );
}
