import { Icon, IconProps } from "@chakra-ui/react";

export function CloseCircleIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <g id="close">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3536 6.35355C18.5489 6.15829 18.5489 5.84171 18.3536 5.64645C18.1583 5.45118 17.8417 5.45118 17.6465 5.64645L12 11.2929L6.35359 5.64645C6.15833 5.45118 5.84174 5.45118 5.64648 5.64645C5.45122 5.84171 5.45122 6.15829 5.64648 6.35355L11.2929 12L5.64645 17.6465C5.45118 17.8417 5.45118 18.1583 5.64645 18.3536C5.84171 18.5489 6.15829 18.5489 6.35355 18.3536L12 12.7071L17.6464 18.3535C17.8417 18.5488 18.1583 18.5488 18.3536 18.3535C18.5488 18.1583 18.5488 17.8417 18.3536 17.6464L12.7071 12L18.3536 6.35355Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
