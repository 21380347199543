import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

function generatePaddingGradient(color: string) {
  return `linear-gradient(var(--chakra-colors-${color}), var(--chakra-colors-${color})) padding-box`;
}

function generateBorderGradient(color: string) {
  return `linear-gradient(to bottom, color-mix(in srgb, white 20%, var(--chakra-colors-${color})), color-mix(in srgb, white 0%, var(--chakra-colors-${color})) 80%) border-box`;
}

function generateRadialGradient(color: string) {
  return `radial-gradient(50% 76.22% at 50% 100%, color-mix(in srgb, white 25%, var(--chakra-colors-${color})) 0%, var(--chakra-colors-${color}) 100%) padding-box`;
}

const buttonSizes = defineStyle({});

const primary = defineStyle({
  ...buttonSizes,
  bgColor: "blaze.600",
  color: "dune.100",
  position: "relative",
  // This provides the border gradient, done in two steps:
  //   1) Using a gradient, fill the content of the box with our intended background color
  //   2) Using a gradient, repaint the border with a mix of 20% white and intended color to 0%
  background: [
    generatePaddingGradient("blaze-600"),
    generateBorderGradient("blaze-600"),
  ].join(","),
  border: "1px solid transparent",
  borderBottom: "0px solid transparent",
  // Used for creating an animation on the gradient
  backgroundSize: "100% 300%",
  clipPath: {
    base: "inset(0 0 0 0 round 12px)",
    lg: "inset(0 0 0 0 round 20px)",
  },
  transition:
    "background 0.2s linear, opacity 0.2s linear, box-shadow 0.2s linear",
  _hover: {
    boxShadow: "0px 8px 24px 0px rgba(255, 90, 44, 0.30)",
    // Same trick on hover, except we're using a radial gradient instead
    background: [
      generateRadialGradient("blaze-600"),
      generateBorderGradient("blaze-600"),
    ].join(","),
    // Used for creating an animation on the gradient
    backgroundSize: "100% 100%",
    // Have to duplicate this selector to override hover disabled state
    _disabled: {
      opacity: 0.4,
      boxShadow: "none",
      background: [
        generatePaddingGradient("blaze-600"),
        generateBorderGradient("blaze-600"),
      ].join(","),
    },
    _loading: {
      boxShadow: "none",
      opacity: 1,
    },
  },
  _active: {
    boxShadow: "none",
    background: [
      generatePaddingGradient("blaze-700"),
      generateBorderGradient("blaze-700"),
    ].join(","),
  },
  _disabled: {
    opacity: 0.4,
    background: [
      generatePaddingGradient("blaze-600"),
      generateBorderGradient("blaze-600"),
    ].join(","),
  },
});

const secondary = defineStyle({
  ...buttonSizes,
  bgColor: "charcoal.400",
  color: "dune.100",
  position: "relative",
  background: [
    generatePaddingGradient("charcoal-400"),
    generateBorderGradient("charcoal-400"),
  ].join(","),
  border: "1px solid transparent",
  borderBottom: "0px solid transparent",
  // Used for creating an animation on the gradient
  backgroundSize: "100% 300%",
  transition: "background 0.2s linear, opacity 0.2s linear",
  _hover: {
    background: [
      generateRadialGradient("charcoal-400"),
      generateBorderGradient("charcoal-400"),
    ].join(","),
    // Used for creating an animation on the gradient
    backgroundSize: "100% 100%",
    // Have to duplicate this selector to override hover disabled state
    _disabled: {
      opacity: 0.4,
      background: [
        generatePaddingGradient("charcoal-400"),
        generateBorderGradient("charcoal-400"),
      ].join(","),
    },
    _loading: {
      opacity: 1,
    },
  },
  _active: {
    background: [
      generatePaddingGradient("neutral-800"),
      generateBorderGradient("neutral-800"),
    ].join(","),
  },
  _disabled: {
    opacity: 0.4,
    background: [
      generatePaddingGradient("charcoal-400"),
      generateBorderGradient("charcoal-400"),
    ].join(","),
  },
});

const tertiary = defineStyle({
  ...buttonSizes,
  bgColor: "none",
  color: "dune.100",
  position: "relative",
  border: "1px solid",
  borderColor: "transparent.white.40",
  transition: "border-color 0.2s linear, opacity 0.2s linear",
  _hover: {
    borderColor: "blaze.blaze",
    // Have to duplicate this selector to override hover disabled state
    _disabled: {
      opacity: 0.4,
      borderColor: "transparent.white.40",
    },
    _loading: {
      opacity: 1,
    },
  },
  _active: {
    borderColor: "blaze.700",
  },
  _disabled: {
    opacity: 0.4,
    borderColor: "transparent.white.40",
  },
});

const iconButtonStyles = {
  sm: { fontSize: "14px", paddingY: 1.5, paddingX: 2 },
  md: { fontSize: "16px", paddingY: 2, paddingX: 2.5 },
  lg: { fontSize: "20px", paddingY: 3.5, paddingX: 4 },
};

type ButtonSizes = "sm" | "md" | "lg";

const primaryIcon = defineStyle((props) => ({
  ...primary,
  minWidth: 0,
  ...iconButtonStyles[props.size as ButtonSizes],
}));

const secondaryIcon = defineStyle((props) => ({
  ...secondary,
  minWidth: 0,
  ...iconButtonStyles[props.size as ButtonSizes],
}));

const tertiaryIcon = defineStyle((props) => ({
  ...tertiary,
  minWidth: 0,
  ...iconButtonStyles[props.size as ButtonSizes],
}));

const ghostIcon = defineStyle({
  _hover: {
    background: "charcoal.charcoal",
  },
});

const hoverBlazeIcon = {
  ...ghostIcon,
  backgroundColor: "transparent",
  path: {
    fill: "currentColor",
    transition: "fill 0.2s linear",
  },
  rect: {
    fill: "currentColor",
    transition: "fill 0.2s linear",
  },

  _hover: {
    path: {
      fill: "blaze.blaze",
    },
    rect: {
      fill: "blaze.blaze",
    },
  },
  _active: {
    backgroundColor: "transparent",
  },
  _disabled: {
    opacity: 0.25,
    _hover: {
      cursor: "default",
    },
  },
};

const iconOnly = defineStyle({
  padding: 0,
  px: 0,
  py: 0,
  _hover: {},
});

const readerOverlayButton = defineStyle({
  ...iconOnly,
  borderRadius: "32px",
  bg: "transparent",
  opacity: 1,
  _disabled: {
    opacity: 0.25,
    _hover: {
      cursor: "default",
    },
  },
});

const readerActionButton = defineStyle({
  ...iconOnly,
  borderRadius: "9999px",
  bg: "reader.slate.900_90",
  padding: "20px 16px",
  height: "48px",
  gap: "8px",
  opacity: 1,
  _disabled: {
    opacity: 0.25,
    _hover: {
      cursor: "default",
    },
  },
});

const readerActionIconButton = defineStyle({
  ...iconOnly,
  borderRadius: "9999px",
  bg: "reader.slate.900_90",
  padding: 0,
  height: "48px",
  gap: "8px",
  opacity: 1,
  _disabled: {
    opacity: 0.25,
    _hover: {
      cursor: "default",
    },
  },
});

const reactionToggle = defineStyle({
  borderRadius: "9999px",
  py: "7px",
  px: "14px",
  border: "1px solid transparent",
  _active: {
    border: "1px solid",
    borderColor: "blaze.blaze",
  },
  background: "reader.slate.700",
  fontSize: "12px",
  lineHeight: "16px",
});

const navbar = defineStyle({
  borderRadius: 16,
  height: "48px",
  width: "42px",
  padding: 0,
  alignItems: "center",
  background: "none",
  marginBottom: "8px",
  pv: "8px",
  transition: "all .2s linear",
  flexDirection: "column",
  gap: "4px",
  _hover: {
    background: "transparent.white.10",
  },
  _expanded: {
    height: "56px",
    borderBottomRadius: 0,
    background: "transparent.white.10",
    margin: 0,
    pb: "8px",
  },
});

const filterTag = {
  transition: "all .2s linear",
  borderRadius: "100px",
  border: "1px solid",
  borderColor: "transparent",
  padding: "8px 12px",
  fontSize: "10px",
  lineHeight: "11px",
  letterSpacing: "0.5px",
  color: "dune.100",
  background: "transparent.white.10",
  _hover: {
    background: "transparent.white.20",
  },
  _active: {
    background: "transparent.white.10",
    border: "1px solid",
    borderColor: "transparent.white.50",
  },
};

const filterTagAlt = {
  borderRadius: "100px",
  border: "1px solid",
  borderColor: "transparent.white.20",
  padding: "8px 12px",
  fontSize: "10px",
  lineHeight: "11px",
  letterSpacing: "0.5px",
  color: "dune.100",
  _hover: {
    borderColor: "transparent.white.50",
  },
  _active: {
    background: "transparent.white.20",
    borderColor: "transparent.white.50",
  },
};

const sortMenuStyle = {
  borderRadius: "8px",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.40)",
  borderColor: "transparent",
  _disabled: {
    background: "white.tranparent.10",
    cursor: "not-allowed",
    borderColor: "transparent",
    _hover: {
      borderColor: "transparent",
    },
  },
};
const sortMenu = {
  ...filterTag,
  ...sortMenuStyle,
};
const sortMenuAlt = {
  ...filterTagAlt,
  ...sortMenuStyle,
  borderColor: "transparent.white.20",
  _disabled: {
    borderColor: "transparent.white.20",
    background: "transparent",
    cursor: "not-allowed",
    _hover: {
      borderColor: "transparent.white.20",
    },
  },
};

const browseTab = {
  ...ghostIcon,
  _active: {
    color: "dune.100",
  },
  _hover: {
    color: "blaze.blaze",
  },
  color: "dune.700",
  fontSize: "lg",
  fontWeight: "bold",
  lineHeight: "130%",
  p: 0,
};

const readerCTAPrimary = {
  ...iconOnly,
  bgGradient: "linear(to-l, blaze.blaze, blaze.700)",
  width: "fit-content",
  borderRadius: "9999px",
  alignItems: "center",
  padding: "6px 16px",
  gap: "10px",
  color: "white",
};

const readerCTASecondary = {
  ...readerCTAPrimary,
  bgGradient: "linear(to-l, info.600, info.700)",
};

const readingStatusCTA = {
  ...tertiary,
  background: "transparent.black.70",
  borderColor: "transparent.white.10",
  _hover: {
    borderColor: "transparent.white.50",
    background: "transparent.black.90",
  },
};

export const buttonTheme = defineStyleConfig({
  sizes: {
    thin: {
      px: 1,
      py: 1,
      height: "fit-content",
      width: "fit-content",
    },
    sm: {
      height: "26px",
      borderRadius: "10px",
      fontSize: "12px",
      padding: "6px 16px",
      fontWeight: "500",
      lineHeight: "11px",
      gap: "8px",
      letterSpacing: ".75px",
      width: "fit-content",
    },
    md: {
      borderRadius: "12px",
      fontSize: "13px",
      padding: "8px 24px",
      fontWeight: "500",
      lineHeight: "11px",
      gap: "8px",
      letterSpacing: ".75px",
      height: "32px",
      width: "fit-content",
    },
    lg: {
      borderRadius: "20px",
      fontSize: "16px",
      padding: "14px 32px",
      fontWeight: "500",
      lineHeight: "11px",
      gap: "8px",
      letterSpacing: ".75px",
      height: "48px",
      width: "fit-content",
    },
  },

  variants: {
    primary,
    secondary,
    tertiary,
    primaryIcon,
    secondaryIcon,
    tertiaryIcon,
    ghostIcon,
    hoverBlazeIcon,
    navbar,
    filterTag,
    filterTagAlt,
    sortMenu,
    sortMenuAlt,
    browseTab,
    iconOnly,
    readerOverlayButton,
    readerActionButton,
    readerActionIconButton,
    reactionToggle,
    readerCTAPrimary,
    readerCTASecondary,
    readingStatusCTA,
  },
});
