import { ImmutableObject } from "@hookstate/core";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useReleaseDataQueryKey } from "./useReleaseDataQueryKey";
import { AxiosResponse } from "axios";
import {
  Comic,
  LibraryStatusEntity,
  Release,
  ServerSingleResponse,
} from "../../../types";
import {
  postAddToLibrary,
  postManageLibrary,
  postRemoveFromLibrary,
} from "../../../api/comics";
import { useUserData } from "../../../hooks/useUserData";

export function useAddToMenu(comicData: ImmutableObject<Comic> | undefined) {
  const { userData } = useUserData();
  const queryClient = useQueryClient();
  const QUERY_KEY = useReleaseDataQueryKey();

  const [status, setStatus] = useState<number | undefined>(1);
  const [disableEmails, setDisableEmails] = useState(true);
  const [disableNotifications, setDisableNotifications] = useState(true);

  const libraryPayload = useCallback(() => {
    return {
      comic_user_reading_status_id: status,
      disable_notifications: disableNotifications,
      disable_emails: disableEmails,
    };
  }, [status, disableEmails, disableNotifications]);

  const { mutate: removeFromLibrary } = useMutation({
    mutationFn: () => postRemoveFromLibrary(comicData?.id ?? 0),
    onMutate: () => {
      const prevState = queryClient.getQueryData(QUERY_KEY) as AxiosResponse<
        ServerSingleResponse<Release>
      >;
      const prevReleaseData = prevState.data.payload.results;
      const prevComicData = prevReleaseData.comic;

      const newState = {
        ...prevState,
        data: {
          payload: {
            results: {
              ...prevReleaseData,
              comic: {
                ...prevComicData,
                library_status: null,
              },
            },
          },
        },
      };

      queryClient.setQueryData(QUERY_KEY, newState);
      return prevState;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(QUERY_KEY, context);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (add: boolean) => {
      return add
        ? postAddToLibrary(comicData?.id ?? 0, libraryPayload())
        : postManageLibrary(comicData?.id ?? 0, libraryPayload());
    },
    onMutate: () => {
      const prevState = queryClient.getQueryData(QUERY_KEY) as AxiosResponse<
        ServerSingleResponse<Release>
      >;
      const prevReleaseData = prevState.data.payload.results;
      const prevComicData = prevReleaseData.comic;

      const libraryStatus: LibraryStatusEntity = {
        entity_type: "ComicFavorite",
        comic_id: comicData?.id ?? 0,
        user_id: userData?.id ?? 0,
        comic_user_reading_status_id: status ?? 0,
        disable_notifications: disableNotifications ? 1 : 0,
        disable_emails: disableEmails ? 1 : 0,
      };

      const newState = {
        ...prevState,
        data: {
          payload: {
            results: {
              ...prevReleaseData,
              comic: {
                ...prevComicData,
                library_status: libraryStatus,
              },
            },
          },
        },
      };

      queryClient.setQueryData(QUERY_KEY, newState);
      return prevState;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(QUERY_KEY, context);
    },
  });

  const mutateLibrary = useCallback(() => {
    if (comicData?.library_status) {
      mutate(false);
    } else {
      mutate(true);
    }
  }, [comicData]);

  useEffect(() => {
    if (comicData?.library_status) {
      setStatus(comicData.library_status.comic_user_reading_status_id);
      setDisableEmails(!!comicData.library_status.disable_emails);
      setDisableNotifications(!!comicData.library_status.disable_notifications);
    }
  }, [comicData]);

  const setLibraryStatus = useCallback(
    (newStatus: string) => {
      setStatus(parseInt(newStatus));
    },
    [status],
  );

  const toggleDisableEmails = useCallback(() => {
    setDisableEmails(!disableEmails);
  }, [disableEmails]);

  const toggleDisableNotifications = useCallback(() => {
    setDisableNotifications(!disableNotifications);
  }, [disableNotifications]);

  return {
    comicData,
    status,
    disableEmails,
    disableNotifications,
    setLibraryStatus,
    toggleDisableEmails,
    toggleDisableNotifications,
    mutateLibrary,
    removeFromLibrary,
  };
}
