import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { MenuItem } from "./MenuItem";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../hooks/useUserData";

export function ArtistMenu() {
  const { t } = useTranslation();
  const { userData } = useUserData();
  if (!userData?.user_creator_data) {
    return null;
  }

  return (
    <Flex direction="column">
      <Box
        height="4px"
        background="transparent.white.10"
        width="full"
        mt="24px"
        mb="24px"
      />
      <Link href={userData.user_creator_data.url}>
        <Flex direction="row" align="center">
          <Image
            loading="lazy"
            src={userData.user_creator_data.avatar_small_url}
            height="48px"
            width="48px"
            borderRadius="14px"
            alt={`Avatar image for ${userData.user_creator_data.roman_name}`}
          />
          <Box width="16px" />
          <Flex direction="column">
            <Text
              color="dune.dune"
              fontWeight="700"
              fontSize="16px"
              lineHeight="18.4px"
              fontFamily="Bricolage Grotesque"
            >
              {userData.user_creator_data.roman_name}
            </Text>
            <Text
              color="neutral.300"
              fontWeight="700"
              fontSize="12px"
              lineHeight="14.06px"
              letterSpacing="0.5px"
              fontFamily="Bricolage Grotesque"
              pt="4px"
            >
              {t("components.navigation.me.viewCreatorProfile")}
            </Text>
          </Flex>
        </Flex>
      </Link>
      <Box height="12px" />
      <MenuItem
        label={t("components.navigation.me.pageSettings")}
        url={`${userData.user_creator_data.edit_url}profile`}
        underline
      />
      <MenuItem
        label={t("components.navigation.me.analytics")}
        url={`${userData.user_creator_data.edit_url}analytics`}
        underline
      />
      <MenuItem
        label={t("components.navigation.me.manageSeries")}
        url={`${userData.user_creator_data.edit_url}manage-comics`}
      />
      <Box
        height="4px"
        background="transparent.white.10"
        width="full"
        mt="24px"
        mb="12px"
      />
    </Flex>
  );
}
