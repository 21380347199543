import { useTranslation } from "react-i18next";
import { Release } from "../../types";
import { Flex, Text } from "@chakra-ui/react";
import { useAccountData } from "../../hooks/useAccountData";

export interface ReleasePriceLabelProps {
  release: Release;
}

export function ReleasePriceLabel({ release }: ReleasePriceLabelProps) {
  const accountData = useAccountData();
  let userIsGold = accountData?.user?.gold;

  if (
    release.is_free ||
    release.release_user_purchase ||
    (userIsGold && !release.premium_only)
  )
    return;

  if (release.ppb_gold_price_point || release.ppb_price_point) {
    let pricesAreDifferent =
      release.ppb_gold_price_point &&
      release.ppb_price_point &&
      release.ppb_gold_price_point != release.ppb_price_point;

    let ppb_gold_price_point = !!release.ppb_gold_price_point
      ? release.ppb_gold_price_point / 100
      : undefined;

    let ppb_price_point = !!release.ppb_price_point
      ? release.ppb_price_point / 100
      : undefined;

    if (release.premium_only) {
      if (!pricesAreDifferent) {
        return (
          <Text variant="unlockableCard" color="neutral.200">
            {" "}
            ${ppb_price_point}
          </Text>
        );
      }

      return (
        <Flex direction="row">
          <Text
            variant="unlockableCard"
            color={!userIsGold ? "charcoal.200" : "blaze.blaze"}
          >
            ${userIsGold ? ppb_gold_price_point : ppb_price_point}
            {userIsGold ? "" : " • "}
          </Text>
          <Text
            variant="unlockableCard"
            paddingLeft={userIsGold ? "4px" : "2px"}
            color={userIsGold ? "charcoal.200" : "blaze.blaze"}
            textDecorationLine={userIsGold ? "line-through" : undefined}
          >
            ${userIsGold ? ppb_price_point : ppb_gold_price_point}
            {userIsGold ? "" : " with Gold"}
          </Text>
        </Flex>
      );
    } else {
      return (
        <Flex direction="row" gap="2px">
          <Text variant="unlockableCard" color="charcoal.200">
            ${ppb_price_point} •{" "}
          </Text>
          <UnlockWithGoldLabel />
        </Flex>
      );
    }
  } else if (!userIsGold) {
    return <UnlockWithGoldLabel />;
  }
}

function UnlockWithGoldLabel() {
  const { t } = useTranslation();

  return (
    <Text variant="unlockableCard">
      {t("components.cards.unlockReleaseCard.freeWithGold")}
    </Text>
  );
}
