import { Flex, Text } from "@chakra-ui/react";
import { GCStream } from "../../types";
import { ButtonCarousel } from "../carousel/ButtonCarousel";
import { Link } from "react-router-dom";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";

interface StreamTabProps {
  streams: GCStream[];
  activeStreamId?: string;
  isHidden?: boolean;
}

export function StreamTabs({ streams, activeStreamId }: StreamTabProps) {
  const isMobile = useMobileBreakpoint();

  return (
    <Flex gap={2} top="0px">
      {streams.length ? (
        <ButtonCarousel
          width="100%"
          includeButtons={!isMobile} // No fading should show on mobile
          slidesToScroll={3}
          wrapperProps={{ gap: "16px" }}
        >
          {streams.map(function (tab) {
            return (
              <Link
                key={tab.id}
                to={`${!tab.slug ? "/" : "/channel/" + tab.slug}`}
              >
                <Flex
                  as="button"
                  key={tab.id}
                  transition="all .2s linear"
                  cursor="pointer"
                  layerStyle="channelTab"
                  direction="column"
                  py="8px"
                  px="16px"
                  height="40px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="16px"
                  bg={
                    activeStreamId === tab.id
                      ? "blaze.600"
                      : "transparent.white.10"
                  }
                  color={"neutral.white"}
                  _hover={{
                    color:
                      activeStreamId === tab.id ? "neutral.white" : "dune.100",
                    background:
                      activeStreamId === tab.id
                        ? undefined
                        : "transparent.white.20",
                  }}
                  marginInline="4px"
                >
                  <Text
                    fontSize={{ base: "12px", md: "14px" }}
                    textAlign="center"
                  >
                    {tab.display_name}
                  </Text>
                </Flex>
              </Link>
            );
          })}
        </ButtonCarousel>
      ) : null}
    </Flex>
  );
}
