import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useParams } from "react-router";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";
import { useP2PSettings } from "../../../screens/reader/hooks/useP2PSettings";
import { usePageLayout } from "../../../screens/reader/hooks/usePageLayout";
import { LockIcon } from "../../icons/reader/LockIcon";

export function ReaderLockedPages() {
  const { releaseData } = useReleaseData();
  const { pageLayout } = usePageLayout();
  const { p2pEnabledAndAugmented } = useP2PSettings();

  const { pageOrder } = useParams();
  const pageCount = releaseData?.page_count ?? 0;

  const pagesRead =
    parseInt(pageOrder ?? "1") +
    (!p2pEnabledAndAugmented && pageLayout === "double" ? 1 : 0);
  const pagesLeft = pageCount - pagesRead;

  return <LockedPages pagesLeft={pagesLeft} />;
}

export function LockedPages({ pagesLeft }: { pagesLeft: number }) {
  return (
    <Flex
      gap="16px"
      justifyContent="center"
      alignItems="center"
      py="16px"
      px="40px"
      border="1px solid"
      color="transparent.white.30"
      bg="transparent.white.10"
      borderRadius="16px"
    >
      <LockIcon height="24px" width="24px" color="blaze.blaze" />
      <Text
        fontSize="16px"
        lineHeight="140%"
        letterSpacing=".5px"
        color="dune.100"
        fontFamily="Roboto"
        fontWeight="500"
      >
        +{pagesLeft} {t("components.payments.subscription.lockedPages")}
      </Text>
    </Flex>
  );
}
