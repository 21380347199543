import { Icon, IconProps } from "@chakra-ui/react";

export function ChapterIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 13 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.78811 3.00737L7.54535 3.06711L9.25049 9.9973L9.49325 9.93757L7.78811 3.00737ZM7.30643 2.09607C6.77014 2.22802 6.44236 2.76974 6.57431 3.30603L7.02819 5.15074C6.87492 5.05521 6.6939 5.00003 6.5 5.00003H6.25C5.69772 5.00003 5.25 5.44774 5.25 6.00003V10C5.25 10.5523 5.69772 11 6.25 11H6.5C7.05228 11 7.5 10.5523 7.5 10V7.0683L8.27945 10.2362C8.41141 10.7725 8.95312 11.1003 9.48941 10.9683L9.73217 10.9086C10.2685 10.7767 10.5962 10.2349 10.4643 9.69865L8.75915 2.76845C8.62719 2.23216 8.08548 1.90438 7.54919 2.03634L7.30643 2.09607ZM3.75 4.50003H3.5V10H3.75V4.50003ZM3.5 3.50003C2.94772 3.50003 2.5 3.94774 2.5 4.50003V10C2.5 10.5523 2.94772 11 3.5 11H3.75C4.30228 11 4.75 10.5523 4.75 10V4.50003C4.75 3.94774 4.30228 3.50003 3.75 3.50003H3.5ZM6.25 6.00003H6.5V10H6.25V6.00003Z"
        fill="currentColor"
      />
    </Icon>
  );
}
