import { apiClient } from "../../../services/axiosInstance";
import { Comic, ServerListResponse } from "../../../types";

export function getArtistComics(
  artistId: number,
  queryParams: URLSearchParams,
) {
  var path = `/v1/artists/${artistId}/comics${queryParams.size > 0 ? "?" + queryParams : ""}`;

  return apiClient.get<ServerListResponse<Comic>>(path);
}

export const GET_ARTIST_COMICS_QUERY_KEY = ["artist-comics-list"];
