import { Icon, IconProps } from "@chakra-ui/react";

export function SendIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3419 4.03331C19.7256 3.91527 20.0849 4.27461 19.9669 4.65824L15.1025 20.4674C14.9699 20.8985 14.3792 20.9474 14.1774 20.5439L10.8394 13.8678L15.5074 9.19974C15.7026 9.00448 15.7026 8.6879 15.5074 8.49264C15.3121 8.29738 14.9955 8.29738 14.8003 8.49264L10.1322 13.1607L3.45626 9.82276C3.0528 9.62103 3.10169 9.03031 3.53283 8.89766L19.3419 4.03331ZM20.9227 4.95233C21.2768 3.80141 20.1988 2.72341 19.0479 3.07753L3.23874 7.94188C1.94534 8.33985 1.79868 10.112 3.00905 10.7172L9.70928 14.0673C9.80604 14.1157 9.8845 14.1941 9.93289 14.2909L13.283 20.9911C13.8882 22.2015 15.6603 22.0548 16.0583 20.7614L20.9227 4.95233Z"
        fill="currentColor"
      />
    </Icon>
  );
}
