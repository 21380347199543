import { Button, ButtonProps, Spinner, Text } from "@chakra-ui/react";

export function PurchaseButton({
  text,
  loading,
  ...props
}: ButtonProps & {
  text: string;
  loading?: boolean;
}) {
  return (
    <Button
      variant="primary"
      gc-testing-id="btnSubmitPayment"
      width="100%"
      size="lg"
      type="submit"
      {...props}
    >
      {loading ? <Spinner color="white" /> : <Text>{text}</Text>}
    </Button>
  );
}
