import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useEnsureFocus } from "../../../hooks/useEnsureFocus";
import { useIsOverlayOpen } from "../../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import {
  useIsSmallBreakpoint,
  useMobileBreakpoint,
} from "../../../utils/useBreakpoints";
import { SquiggleIconBox } from "../../icons/SquiggleIconBox";
import { Avatar } from "../../user/Avatar";
import { useUserData } from "../../../hooks/useUserData";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import { logout } from "../../auth/api/authApi";
import { ArtistMenu } from "./ArtistMenu";
import { MenuItem } from "./MenuItem";
import { GET_SAVED_PAYMENT_METHODS_QUERY_KEY } from "../../payments/api/paymentApi";
import { useIsSidebarOpen } from "../../../hooks/useIsSidebarOpen";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { OwnerPaymentServiceTokenTypes } from "../../payments/types";
import { ServerListResponse } from "../../../types";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { useHookstate } from "@hookstate/core";
import { GoldUpsellBanner } from "./GoldUpsellBanner";
import { pendingAuthPaymentConfig } from "../../payments/SubscriptionModal";
import { isMobile } from "react-device-detect";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { useAccountData } from "../../../hooks/useAccountData";
import { NAVBAR_HEIGHT } from "../../../screens/root/constants";
import { useNavigate } from "react-router";
import { ContextReferrer } from "../../funnel/types";
import { ComplexMenuItem } from "./ComplexMenuItem";
import { CopyIcon } from "@chakra-ui/icons";

type AccountMenuItem = {
  url: string;
  label: React.ReactNode;
  noArrow?: boolean;
  onClick?: () => void;
};

export function AccountMenu() {
  const [t] = useTranslation();

  const accountData = useAccountData();
  const { userData } = useUserData();
  const isMobileScreen = useMobileBreakpoint();
  const { isOverlayOpen: isReaderOverlayOpen } = useIsOverlayOpen();
  const popoverRef = useRef(null);
  const ensureFocus = useEnsureFocus();
  const invalidateAccountQuery = useInvalidateQueries(ACCOUNT_INIT_QUERY_KEY);
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateReleaseData = useInvalidateQueries(
    releaseQueryKey as string[],
  );
  const toast = useToast();
  const { setIsSidebarOpen } = useIsSidebarOpen();
  const isSmall = useIsSmallBreakpoint();
  const navigate = useNavigate();
  const isGoldUser = userData?.gold;

  // When the popover appears, the account box should be focused.
  // However, the container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (!popoverRef.current) {
        return;
      }
      ensureFocus(popoverRef.current);
    },
  });

  const menuItems = useMemo<AccountMenuItem[]>(() => {
    if (!userData) return [];
    return [
      {
        url: `${userData.edit_url}profile`,
        label: t("components.navigation.me.accountSettings"),
      },
      {
        url: `${userData.url}/lists`,
        label: t("components.navigation.me.lists"),
      },
      userData.onboarding === "completed"
        ? {
            onClick: () =>
              setFunnelModalConfig({
                type: FUNNEL_TYPES.onboarding,
                options: { trapFocus: true },
              }),
            label: t("components.navigation.me.interests"),
          }
        : null,
      {
        url: `${userData.edit_url}credits`,
        label: t("components.navigation.me.promoCodes"),
      },
      {
        url: `${userData.edit_url}downloads`,
        label: accountData?.features["ppb"]
          ? t("components.navigation.me.purchases")
          : t("components.navigation.me.downloads"),
      },
      {
        url: `${userData.edit_url}subscriptions`,
        label: t("components.navigation.me.subscriptions"),
      },
      userData.is_admin
        ? {
            url: "/admin/",
            label: t("components.navigation.me.siteAdministration"),
          }
        : undefined,
      {
        label: t("components.navigation.me.submitFeedback"),
        url: "/support/webnext-feedback",
      },
      {
        url: "/forums/21/bug-reporting",
        label: t("components.navigation.me.reportABug"),
      },
    ].filter((n) => n !== undefined) as AccountMenuItem[];
  }, [accountData]);

  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const handleLogout = async () => {
    try {
      await logout();
      invalidateAccountQuery();
      if (inReader) invalidateReleaseData();
      if (pendingAuthPaymentConfig.get()) {
        pendingAuthPaymentConfig.set(undefined);
      }
      queryClient.setQueryData(
        GET_SAVED_PAYMENT_METHODS_QUERY_KEY,
        (
          oldData: AxiosResponse<
            ServerListResponse<OwnerPaymentServiceTokenTypes>
          >,
        ) => {
          if (oldData) oldData.data.payload.results = [];
          return oldData;
        },
      );
    } catch (e) {
      toast({
        title: t("components.navigation.auth.logoutFailed"),
        status: "error",
      });
    }
  };

  const copyBaf = async () => {
    if (!accountData?.baf_token?.token) return;
    await navigator.clipboard.writeText(accountData.baf_token.token);
    toast({
      title: t("components.navigation.me.bafToast"),
      status: "success",
    });
  };

  const goldUpsellTestEnabled = accountData?.features["gold_upsell"];
  const queryClient = useQueryClient();

  return (
    <Popover gutter={1} variant="navbar" initialFocusRef={focusHandler} isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <AccountMenuTrigger
              onClick={() => {
                if (isSmall) {
                  setIsSidebarOpen(false);
                }
              }}
            />
          </PopoverTrigger>
          <Portal>
            <Box
              zIndex={isReaderOverlayOpen ? 1401 : 1}
              sx={{
                "@supports (-webkit-touch-callout: none)": {
                  height: "-webkit-fill-available",
                },
              }}
              position="relative"
              w="full"
              h="full"
            >
              <PopoverContent
                ref={popoverRef}
                variants={{
                  enter: { opacity: 1, top: 0, right: 0 },
                  exit: { opacity: 0, top: -NAVBAR_HEIGHT, right: 0 },
                }}
                height={isMobileScreen ? "100%" : undefined}
                maxHeight={isMobileScreen ? "100%" : "90%"}
                width={isMobileScreen ? "100%" : "350px"}
                borderRadius={isMobileScreen ? 0 : undefined}
              >
                <PopoverHeader pb="0px">
                  <Flex direction="row" justifyContent="space-between">
                    <Flex direction="column">
                      <Text
                        marginBottom="1px"
                        fontSize={
                          (userData?.name?.length ?? 0) > 22 ? "16px" : "20px"
                        }
                        fontWeight="700"
                        lineHeight="26px"
                        color="dune.dune"
                        fontFamily="Bricolage Grotesque"
                        style={{ scrollbarGutter: "auto" }}
                      >
                        {userData?.name}
                      </Text>
                      <SquiggleIconBox
                        color="blaze.blaze"
                        width="68px"
                        height="auto"
                      />
                    </Flex>
                    <Link
                      href={userData?.url}
                      variant="brand"
                      fontSize="14px"
                      fontWeight="500"
                      letterSpacing=".5px"
                      paddingTop="4px"
                    >
                      {t("components.navigation.me.viewProfile")}
                    </Link>
                  </Flex>
                </PopoverHeader>
                <PopoverBody>
                  <Flex
                    direction="column"
                    paddingBottom={isMobile ? "60px" : undefined}
                  >
                    {accountData?.baf_token && (
                      <>
                        <ComplexMenuItem
                          title={t("components.navigation.me.baf")}
                          subtitle={t("components.navigation.me.bafSubtitle", {
                            remaining: accountData.baf_token.remaining_uses,
                            total: accountData.baf_token.original_uses,
                            expires: accountData.baf_token.expires_in,
                          })}
                          icon={<CopyIcon />}
                          onClick={copyBaf}
                        />
                        <Box
                          height="2px"
                          background="transparent.white.10"
                          width="full"
                          mt="16px"
                          mb="12px"
                        />
                      </>
                    )}
                    {!isGoldUser && (
                      <>
                        <GoldUpsellBanner
                          onClick={
                            goldUpsellTestEnabled
                              ? () => {
                                  navigate("/gold");
                                  rootComponentsStore.eventContextReferrer.set(
                                    ContextReferrer.settings,
                                  );
                                  onClose();
                                }
                              : undefined
                          }
                        />
                        <Box
                          height="2px"
                          background="transparent.white.10"
                          width="full"
                          mt="16px"
                          mb="12px"
                        />
                      </>
                    )}
                    {menuItems.map((item, index) =>
                      item ? (
                        <MenuItem
                          key={index}
                          onClick={item.onClick}
                          {...item}
                          underline={
                            index !== menuItems.length - 1 ||
                            !userData?.user_creator_data
                          }
                        />
                      ) : null,
                    )}
                    <ArtistMenu />

                    <MenuItem
                      key="logout"
                      label={t("components.navigation.me.logout")}
                      onClick={handleLogout}
                      noArrow
                    />
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Portal>
        </>
      )}
    </Popover>
  );
}

const AccountMenuTrigger = forwardRef<HTMLDivElement, ButtonProps>(
  ({ ...props }, ref) => {
    const { userData } = useUserData();
    return (
      <Flex ref={ref} height="full" alignItems="flex-end">
        <Tooltip
          variant="navbar"
          label="Account"
          aria-label="account-tooltip"
          isDisabled={!!props["aria-expanded"]}
        >
          <Button
            gc-testing-id="btnProfileMenu"
            variant="navbar"
            _hover={{ bg: "transparent" }}
            _expanded={{ bg: "transparent" }}
            {...props}
          >
            <Avatar
              isGold={userData?.gold}
              username={userData?.name}
              imageSrc={userData?.avatar_tiny_url}
            />
          </Button>
        </Tooltip>
      </Flex>
    );
  },
);
