import { FUNNEL_TYPES_TYPES } from "./utils/contants";

export type FunnelComponentProps = {
  onClose: () => void;
  onSuccess: () => void;
  isModal?: boolean;
  setFunnelType: (type: FUNNEL_TYPES_TYPES) => void;
  sendEvent?: (event: any) => void;
};

export enum FunnelContextArea {
  reader = "reader",
  navBar = "nav-bar",
  followArtist = "follow-artist",
  addToLibrary = "add-to-library",
  reaction = "reaction",
  donate = "donate",
  purchasePdf = "purchase-pdf",
  comment = "comment",
  settings = "settings",
  gold = "gold",
  startNextReleaseButton = "start_next_release_button",
  seriesInfo = "series-info",
  chatSchedule = "chat-schedule",
  globalChat = "global-chat",
  releasesList = "releases-list",
  releaseDetails = "release-details",
  ppbPurchase = "ppb-purchase",
}

export enum ContextReferrer {
  settings = "settings",
  sidebar = "sidebar",
}

export interface FunnelNavigationContext {
  contextArea: FunnelContextArea;
  contextReferrer?: ContextReferrer;
  data?: {
    comicId?: number;
    releaseId?: number;
    releaseOrder?: number;
    comicName?: string;
    artistId?: number;
  };
}
