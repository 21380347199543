import {
  Message,
  PaginatedResult,
  PresenceMember,
  useChatClient,
  useMessages,
  useOccupancy,
  usePresence,
  usePresenceListener,
} from "@ably/chat";
import { useUserData } from "../../../hooks/useUserData";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactionTypeEntity } from "../../../services/gc/types/reactionTypeEntity";
import { MessageReaction } from "../types";
import { useDebounce } from "../../../hooks/useDebounce";

export function useChat() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState<Record<number, Message>>({});
  const [members, setMembers] = useState<Record<string, PresenceMember>>({});
  const { userData } = useUserData();
  const chat = useChatClient();

  // The total count of present members anon and signed in
  const { presenceMembers } = useOccupancy();

  // Configure the presence data of the current user
  usePresence({
    enterWithData: {
      username: userData?.name,
      isGold: userData?.gold,
      avatar: userData?.avatar_tiny_url,
    },
  });

  // Listen for users joining and leaving the room
  const { presenceData } = usePresenceListener({
    listener: (member) => {
      if (member.action === "leave") {
        setMembers((prevMembers) => {
          const newMembers = { ...prevMembers };
          delete newMembers[member.clientId];
          return newMembers;
        });
      } else {
        setMembers((prevMembers) => ({
          ...prevMembers,
          [member.clientId]: {
            ...member,
            extras: {},
            updatedAt: Date.now(),
          } as PresenceMember,
        }));
      }
    },
  });

  const membersInitialized = useRef(false);
  useEffect(() => {
    if (presenceData && !membersInitialized.current) {
      setMembers(
        presenceData.reduce((acc: Record<string, PresenceMember>, curr) => {
          acc[curr.clientId] = curr;
          return acc;
        }, {}),
      );
      membersInitialized.current = true;
    }
  }, [presenceData]);

  // Listen for message events in the room and updates
  // the messages state and deletes messages when needed
  const {
    roomStatus,
    getPreviousMessages,
    send: handleSendMessage,
    update: updateMessage,
    deleteMessage,
  } = useMessages({
    listener: (message) => {
      if (message.type === "message.deleted") {
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          delete newMessages[message.message.createdAt.getTime()];
          return newMessages;
        });
      } else {
        const timestamp = message.message.createdAt.getTime();
        setMessages((prevMessages) => ({
          ...prevMessages,
          [timestamp]: {
            ...message.message,
          },
        }));
      }
    },
  });

  const saveOldMessages = async (
    oldMessages: PaginatedResult<Message> | undefined,
  ) => {
    const oldMessageByTimestamp: Record<number, Message> = {};
    oldMessages?.items.forEach((message) => {
      if (message.action === "message.delete") return;
      const timestamp = message.createdAt.getTime();
      oldMessageByTimestamp[timestamp] = message;
    });

    setMessages((prevMessages) => ({
      ...prevMessages,
      ...oldMessageByTimestamp,
    }));
    setIsLoaded(true);
  };

  // Fetches the previous 100 messages and filters out deleted messages
  const fetchPreviousMessages = async () => {
    if (getPreviousMessages === undefined) return;

    const oldMessages = await getPreviousMessages({
      limit: 100,
    });

    return oldMessages;
  };

  const throttledMessageHistory = useDebounce(async () => {
    const oldMessages = await fetchPreviousMessages();
    saveOldMessages(oldMessages);
  }, 250);

  useEffect(() => {
    setMembers({});
    membersInitialized.current = false;
    setMessages({});
    if (roomStatus === "attached") {
      throttledMessageHistory();
    } else {
      setIsLoaded(false);
    }
  }, [roomStatus]);

  const handleDeleteMessage = async (message: Message) => {
    try {
      await deleteMessage(message);
    } catch (error) {
      console.error("Failed to delete message", error);
    }
  };

  // Populates the reaction object by adding or removing
  // the currents user's reactions
  const handleToggleReaction = async (
    message: Message,
    reaction: ReactionTypeEntity,
  ) => {
    const reactions = (message.metadata.reactions ?? {}) as Record<
      string,
      MessageReaction[]
    >;

    if (!reactions[reaction.id]) reactions[reaction.id] = [];

    const reactionArray = reactions[reaction.id];
    const index = reactionArray.findIndex((r) => r.clientId === chat.clientId);
    if (index > -1) {
      reactionArray.splice(index, 1);
    } else {
      reactionArray.push({ clientId: chat.clientId, reaction });
    }

    try {
      updateMessage(message, {
        ...message,
        metadata: { ...message.metadata, reactions },
      });
    } catch (error) {
      console.error("Failed to add reaction", error);
    }
  };

  const messageArray = useMemo(() => {
    return Object.values(messages).sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
    );
  }, [messages]);

  return {
    isLoaded,
    messageArray,
    members,
    presenceMembers,
    roomStatus,
    handleSendMessage,
    handleDeleteMessage,
    handleToggleReaction,
  };
}
