import { Icon, IconProps } from "@chakra-ui/react";

export function AutoSizingIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 94 94" {...props}>
      <path
        d="M25.5 32C25.5 28.4102 28.4101 25.5 32 25.5H62C65.5899 25.5 68.5 28.4101 68.5 32V62C68.5 65.5899 65.5899 68.5 62 68.5H32C28.4102 68.5 25.5 65.5899 25.5 62V32Z"
        fill="transparent"
        stroke="#B8BCC6"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 47C6 46.4477 6.44772 46 7 46L17 46C17.5523 46 18 46.4477 18 47C18 47.5523 17.5523 48 17 48L7 48C6.44772 48 6 47.5523 6 47Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 42.2929C12.0976 42.6834 12.0976 43.3166 11.7071 43.7071L8.41421 47L11.7071 50.2929C12.0976 50.6834 12.0976 51.3166 11.7071 51.7071C11.3166 52.0976 10.6834 52.0976 10.2929 51.7071L6.29289 47.7071C5.90237 47.3166 5.90237 46.6834 6.29289 46.2929L10.2929 42.2929C10.6834 41.9024 11.3166 41.9024 11.7071 42.2929Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 47C76 46.4477 76.4477 46 77 46H87C87.5523 46 88 46.4477 88 47C88 47.5523 87.5523 48 87 48H77C76.4477 48 76 47.5523 76 47Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.2929 42.2929C82.6834 41.9024 83.3166 41.9024 83.7071 42.2929L87.7071 46.2929C87.8946 46.4804 88 46.7348 88 47C88 47.2652 87.8946 47.5196 87.7071 47.7071L83.7071 51.7071C83.3166 52.0976 82.6834 52.0976 82.2929 51.7071C81.9024 51.3166 81.9024 50.6834 82.2929 50.2929L85.5858 47L82.2929 43.7071C81.9024 43.3166 81.9024 42.6834 82.2929 42.2929Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 6C47.5523 6 48 6.44772 48 7L48 17C48 17.5523 47.5523 18 47 18C46.4477 18 46 17.5523 46 17L46 7C46 6.44772 46.4477 6 47 6Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2929 6.29289C46.6834 5.90237 47.3166 5.90237 47.7071 6.29289L51.7071 10.2929C52.0976 10.6834 52.0976 11.3166 51.7071 11.7071C51.3166 12.0976 50.6834 12.0976 50.2929 11.7071L47 8.41421L43.7071 11.7071C43.3166 12.0976 42.6834 12.0976 42.2929 11.7071C41.9024 11.3166 41.9024 10.6834 42.2929 10.2929L46.2929 6.29289Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 76C47.5523 76 48 76.4477 48 77V87C48 87.5523 47.5523 88 47 88C46.4477 88 46 87.5523 46 87V77C46 76.4477 46.4477 76 47 76Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2929 82.2929C42.6834 81.9024 43.3166 81.9024 43.7071 82.2929L47 85.5858L50.2929 82.2929C50.6834 81.9024 51.3166 81.9024 51.7071 82.2929C52.0976 82.6834 52.0976 83.3166 51.7071 83.7071L47.7071 87.7071C47.5196 87.8946 47.2652 88 47 88C46.7348 88 46.4804 87.8946 46.2929 87.7071L42.2929 83.7071C41.9024 83.3166 41.9024 82.6834 42.2929 82.2929Z"
        fill="#667085"
      />
    </Icon>
  );
}
