import { Text, Image, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";

export function QRCode() {
  return (
    <Box
      as={motion.div}
      animate={{ x: [-10, 25], opacity: [0, 1] }}
      position="absolute"
      bottom="25px"
      left={0}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="0"
    >
      <Image
        width="120px"
        height="120px"
        src="/static/images/app_qr.png"
        alt="Mobile app QR code"
      />
      <Text fontSize="12px" fontWeight="500" lineHeight="16px">
        {t("screens.reader.overlay.scan_to_get")}
      </Text>
    </Box>
  );
}
