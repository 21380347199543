import { Text } from "@chakra-ui/react";
import React, { ErrorInfo } from "react";

type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
  onError?: (error: Error, info: ErrorInfo) => void;
  errorComponent?: React.ReactNode;
};

export class ErrorBoundary extends React.Component {
  declare props: ErrorBoundaryProps;
  state: ErrorBoundaryState;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;
    onError?.(error, info);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.errorComponent) return this.props.errorComponent;
      return <Text>Something went wrong.</Text>;
    }

    return this.props.children;
  }
}
