import { useQuery } from "@tanstack/react-query";
import { useSlideInMenu } from "../hooks/useSlideInMenu";
import { Comic } from "../../../types";
import { Flex, Image, Text } from "@chakra-ui/react";
import { TitleWithGearIcon } from "../../../screens/reader/components/menuTitles";
import { useTranslation } from "react-i18next";

export function ReleaseInfoMenuTitle() {
  const { t } = useTranslation();
  const { comicCacheKey } = useSlideInMenu();
  const { data: comicData } = useQuery<Comic>({
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  if (!comicData) {
    return (
      <TitleWithGearIcon
        title={t("components.slideInMenu.releaseInfoMenu.releaseInfo")}
        label={t("components.slideInMenu.releaseInfoMenu.editRelease")}
        enabled={false}
        editUrl={""}
      />
    );
  }

  return (
    <Flex flexDirection="row" gap="8px" width="100%" alignItems="center">
      <Image
        width="22px"
        height="34px"
        src={comicData.image_tiny_url}
        alt={`Comic cover for ${comicData.name}`}
        borderRadius="2px"
        border="1px solid"
        borderColor="transparent.white.10"
        marginRight="8px"
      />
      <Text
        fontSize="18px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="130%"
      >
        {comicData.name}
      </Text>
    </Flex>
  );
}
