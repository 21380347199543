import { useEffect, useRef } from "react";
import { Page } from "../../../types";
import { ImmutableArray } from "@hookstate/core";
import { useReaderNavigation } from "./useReaderNavigation";
import { useImageSizing } from "./useImageSizing";

export function usePreloadPages(
  releasePagesData: ImmutableArray<Page> | undefined,
) {
  const { getImageSrc } = useImageSizing();
  const { activePage } = useReaderNavigation();

  const preloadedPageMap = useRef<Map<number, boolean>>(new Map());

  useEffect(() => {
    if (activePage && releasePagesData?.length) {
      for (let i = activePage - 2; i < activePage + 6; i++) {
        if (
          i === activePage ||
          !releasePagesData?.[i] ||
          preloadedPageMap.current.has(releasePagesData[i].id)
        ) {
          continue;
        }
        preloadedPageMap.current.set(releasePagesData[i].id, true);
        new Image().src = getImageSrc(releasePagesData[i]);
      }
    }
  }, [activePage]);
}
