import { Icon, IconProps } from "@chakra-ui/react";

export function StatusIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <circle cx="4" cy="4" r="4" fill="currentColor" />
      <circle
        cx="4"
        cy="4"
        r="3.5"
        stroke="url(#paint0_linear_164_143984)"
        strokeOpacity="0.5"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_164_143984"
          x1="4"
          y1="0"
          x2="4"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
