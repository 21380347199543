import { appStore } from "../../../appStore";
import { postToggleReaction } from "../api/readerScreenApi";
import { useMemo } from "react";
import {
  ReactionSummaryEntity,
  Release,
  ServerSingleResponse,
} from "../../../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useReleaseDataQueryKey } from "./useReleaseDataQueryKey";
import { useReleaseData } from "./hookstate/useReleaseData";

interface ReactionTypeValues {
  [index: number]: ReactionSummaryEntity;
}
export function useReactions() {
  const reactionTypes = appStore.init.reaction_types.get();
  const { releaseData } = useReleaseData();
  const queryClient = useQueryClient();
  const QUERY_KEY = useReleaseDataQueryKey();

  const { mutate: toggleReaction } = useMutation({
    mutationFn: (id: number) =>
      postToggleReaction(releaseData?.id ?? 0, { reaction_type_id: id }),
    onMutate: async (id: number) => {
      const prevState = queryClient.getQueryData(QUERY_KEY) as AxiosResponse<
        ServerSingleResponse<Release>
      >;
      const prevReleaseData = prevState.data.payload.results;
      const prevReactionSummaries = prevReleaseData.reactions;

      if (prevReactionSummaries) {
        const newReactionIndex = prevReactionSummaries.findIndex((summary) => {
          return summary.reaction_type.id === id;
        });
        if (newReactionIndex > -1) {
          const newReactionSummaries = [...prevReactionSummaries];
          const newReaction = newReactionSummaries[newReactionIndex];
          newReaction.is_clicked = !newReaction.is_clicked;
          newReaction.value =
            newReaction.value + (newReaction.is_clicked ? 1 : -1);

          const newState = {
            ...prevState,
            data: {
              payload: {
                results: {
                  ...prevReleaseData,
                  reactions: newReactionSummaries,
                },
              },
            },
          };

          queryClient.setQueryData(QUERY_KEY, newState);
        }
        return prevState;
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(QUERY_KEY, context);
    },
  });

  const reactionTypesValues = useMemo(() => {
    const reactionTypesValues: ReactionTypeValues = {};
    releaseData?.reactions.forEach((reaction) => {
      reactionTypesValues[reaction.reaction_type.id] = reaction;
    });
    return reactionTypesValues;
  }, [releaseData]);

  return {
    reactionTypes,
    reactionTypesValues,
    toggleReaction,
  };
}
