import { t } from "i18next";
import { useComments } from "../hooks/useComments";
import { useComicData } from "../hooks/hookstate/useComicData";
import { SLIDE_IN_MENUS } from "../../root/constants";
import { Flex, Tooltip } from "@chakra-ui/react";
import { GearIcon } from "../../../components/icons/GearIcon";
import { useSlideInMenu } from "../../../components/slideInMenu/hooks/useSlideInMenu";
import { Artist, Comic } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import { ReleaseInfoMenuTitle } from "../../../components/slideInMenu/releaseInfo/ReleaseInfoMenuTitle";

export const menuTitles = {
  [SLIDE_IN_MENUS.settings]: <SettingsMenuTitle />,
  [SLIDE_IN_MENUS.allPages]: <AllPagesMenuTitle />,
  [SLIDE_IN_MENUS.addTo]: <AddToMenuTitle />,
  [SLIDE_IN_MENUS.comments]: <CommentsMenuTitle />,
  [SLIDE_IN_MENUS.comicDetails]: <ComicDetailsMenuTitle />,
  [SLIDE_IN_MENUS.publisher]: <PublisherProfileMenuTitle />,
  [SLIDE_IN_MENUS.releaseInfo]: <ReleaseInfoMenuTitle />,
};

export function TitleWithGearIcon({
  editUrl,
  title,
  label,
  enabled,
}: {
  enabled: boolean;
  editUrl: string;
  title: string;
  label: string;
}) {
  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      pr="8px"
    >
      {title}
      {enabled && (
        <Tooltip
          variant="navbar"
          label={label}
          aria-label={label}
          placement="bottom"
          gutter={8}
        >
          <a href={editUrl}>
            <GearIcon
              transition="color .1s linear"
              _hover={{ color: "blaze.400" }}
              color="dune.100"
              boxSize="24px"
            />
          </a>
        </Tooltip>
      )}
    </Flex>
  );
}
export function ComicDetailsMenuTitle() {
  const { comicCacheKey } = useSlideInMenu(true);
  const { data: comicData } = useQuery<Comic>({
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
  });

  return (
    <TitleWithGearIcon
      enabled={!!comicData?.can_edit}
      editUrl={comicData?.edit_url ?? ""}
      title={t("components.slideInMenu.comicDetailsMenu.comicDetails")}
      label={t("components.slideInMenu.comicDetailsMenu.editComic")}
    />
  );
}

export function SettingsMenuTitle() {
  return <span>{t("screens.reader.settingsMenu.settings")}</span>;
}
export function AllPagesMenuTitle() {
  return <span>{t("screens.reader.allPagesMenu.all_pages")}</span>;
}
export function AddToMenuTitle() {
  const { comicData } = useComicData();
  return comicData?.library_status ? (
    <span>{t("screens.reader.addToMenu.change_status")}</span>
  ) : (
    <span>{t("screens.reader.addToMenu.add_to_library")}</span>
  );
}
export function CommentsMenuTitle() {
  const { releaseCommentsData } = useComments();
  let commentCount = 0;
  releaseCommentsData?.forEach((comment) => {
    commentCount += comment.replies.length;
    commentCount++;
  });
  return (
    <span>{`${commentCount} ${t("screens.reader.commentsMenu.comments")}`}</span>
  );
}

export function PublisherProfileMenuTitle() {
  const { artistCacheKey } = useSlideInMenu(true);
  const { data: artistData } = useQuery<Artist>({
    queryKey: [artistCacheKey],
    networkMode: "offlineFirst",
  });
  return (
    <TitleWithGearIcon
      enabled={!!artistData?.can_edit}
      editUrl={artistData?.edit_url ?? ""}
      title={t("screens.artist.publisher_profile")}
      label={t("screens.artist.editArtist")}
    />
  );
}
