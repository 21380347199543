import { Text, Box, keyframes } from "@chakra-ui/react";
import { useReaderNavigation } from "../../hooks/useReaderNavigation";
import { motion } from "framer-motion";

const animation = keyframes`
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
`;
export function ReleaseProgress() {
  const { activePage, pageCount } = useReaderNavigation();

  if (!pageCount) return null;
  return (
    <Box
      as={motion.div}
      animate={{ scale: [0.5, 1], opacity: [0, 1] }}
      display="flex"
      padding="20px"
      borderRadius="12px"
      background="reader.slate.900_90"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text
        color="blaze.300"
        fontSize="12px"
        fontWeight="500"
        lineHeight="16px"
      >
        {`${activePage + 1} of ${pageCount}`}
      </Text>
      <Text
        fontSize="12px"
        fontWeight="500"
        lineHeight="16px"
        animation={`${animation} 2s ease-in-out infinite`}
      >
        Click to continue reading
      </Text>
    </Box>
  );
}
