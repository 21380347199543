import { Text, Button, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { ReleasesList } from "./ReleasesList";
import { RelatedComicsList } from "./RelatedComicsList";
import { t } from "i18next";
import { ImmutableObject } from "@hookstate/core";
import { Comic } from "../../../types";

export function ComicDetailsTabs({
  comicData,
}: {
  comicData: ImmutableObject<Comic> | undefined;
}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Flex
        gap="4px"
        p="4px"
        background="transparent.black.30"
        borderRadius="16px"
      >
        <Button
          transition="background 0.2s, textColor 0.2s"
          flex={1}
          borderRadius="12px"
          borderTop="none"
          background="transparent"
          isActive={activeTab === 0}
          _active={{
            background: "transparent.white.20",
            box_shadow: "0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset",
            textColor: "neutral.300",
          }}
          _hover={{}}
          onClick={() => setActiveTab(0)}
          textColor="neutral.500"
        >
          <Text
            fontSize="14px"
            fontWeight="500"
            fontStyle="normal"
            lineHeight="11px"
            letterSpacing="0.75"
          >
            {t("components.slideInMenu.comicDetailsMenu.releases")}
          </Text>
        </Button>
        <Button
          transition="background 0.2s, textColor 0.2s"
          flex={1}
          borderRadius="12px"
          borderTop="none"
          background="transparent"
          isActive={activeTab === 1}
          _active={{
            background: "transparent.white.20",
            box_shadow: "0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset",
            textColor: "neutral.300",
          }}
          textColor="neutral.500"
          _hover={{}}
          onClick={() => setActiveTab(1)}
        >
          <Text
            fontSize="14px"
            fontWeight="500"
            fontStyle="normal"
            lineHeight="11px"
            letterSpacing="0.75"
          >
            {t("components.slideInMenu.comicDetailsMenu.related")}
          </Text>
        </Button>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-end"
        gap="12px"
        paddingX={activeTab !== 0 ? "16px" : undefined}
      >
        {activeTab === 0 ? (
          <ReleasesList comicData={comicData} />
        ) : (
          <RelatedComicsList comicData={comicData} />
        )}
      </Flex>
    </>
  );
}
