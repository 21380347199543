import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export interface DealElementProps {
  title: string;
  icon: React.ReactNode;
  description: string;
}

export function DealElement({ title, icon, description }: DealElementProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="row" gap="8px" alignItems="center">
        {icon}
        <Text
          fontFamily="Bricolage Grotesque"
          fontWeight="500"
          fontSize={isSmallBreakpoint ? "18px" : "24px"}
          lineHeight="120%"
          letterSpacing="0.5px"
          color="blaze.blaze"
        >
          {title}
        </Text>
      </Flex>
      <Text
        fontFamily="Roboto"
        fontWeight="400"
        fontSize={isSmallBreakpoint ? "16px" : "14px"}
        lineHeight="150%"
        letterSpacing="0.5px"
        color="dune.dune"
      >
        {description}
      </Text>
    </Flex>
  );
}
