import { useMutation } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { GET_SAVED_PAYMENT_METHODS_QUERY_KEY } from "../api/paymentApi";
import { OwnerPaymentServiceTokenTypes } from "../types";
import axios from "axios";
import { ServerResponse } from "../../../types";

export function usePaymentMutation(
  mutationFn: (config: any) => Promise<any>,
  onSuccess: () => void,
  onError: (error: Error) => string | undefined,
) {
  const [waiting, setWaiting] = useState(false);
  const [cardValidationError, setCardValidationError] = useState<string>();
  const [failedCard, setFailedCard] = useState<OwnerPaymentServiceTokenTypes>();

  const invalidateSavedCards = useInvalidateQueries(
    GET_SAVED_PAYMENT_METHODS_QUERY_KEY,
  );

  const { mutate } = useMutation({
    mutationFn: mutationFn,
    onMutate: () => {
      setCardValidationError(undefined);
      setFailedCard(undefined);
      setWaiting(true);
    },
    onSuccess: () => {
      onSuccess();
      setWaiting(false);
    },
    onError: (error) => {
      setWaiting(false);
      setCardValidationError(onError(error));

      if (
        axios.isAxiosError<
          ServerResponse<
            string,
            { ownerPaymentServiceToken: OwnerPaymentServiceTokenTypes }
          >
        >(error)
      ) {
        setFailedCard(error.response?.data?.meta.ownerPaymentServiceToken);
      }

      invalidateSavedCards();
    },
  });

  return useMemo(() => {
    return {
      waiting,
      setWaiting,
      failedCard,
      cardValidationError,
      setCardValidationError,
      mutate,
    };
  }, [waiting, failedCard, cardValidationError, mutate]);
}
