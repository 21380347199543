import { Icon, IconProps } from "@chakra-ui/react";

export function CalendarIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5C16.2761 1.5 16.5 1.72386 16.5 2V3.5H19C19.8284 3.5 20.5 4.17157 20.5 5V8V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V8V5C3.5 4.17157 4.17157 3.5 5 3.5H7.5V2C7.5 1.72386 7.72386 1.5 8 1.5C8.27614 1.5 8.5 1.72386 8.5 2V3.5H15.5V2C15.5 1.72386 15.7239 1.5 16 1.5ZM8 4.5H5C4.72386 4.5 4.5 4.72386 4.5 5V7.5H19.5V5C19.5 4.72386 19.2761 4.5 19 4.5H16H8ZM19.5 8.5H4.5V19C4.5 19.2761 4.72386 19.5 5 19.5H19C19.2761 19.5 19.5 19.2761 19.5 19V8.5ZM15.3536 12.3536C15.5488 12.1583 15.5488 11.8417 15.3536 11.6464C15.1583 11.4512 14.8417 11.4512 14.6464 11.6464L11 15.2929L9.35355 13.6464C9.15829 13.4512 8.84171 13.4512 8.64645 13.6464C8.45118 13.8417 8.45118 14.1583 8.64645 14.3536L10.6464 16.3536C10.8417 16.5488 11.1583 16.5488 11.3536 16.3536L15.3536 12.3536Z"
        fill="currentColor"
      />
    </Icon>
  );
}
