import { useMemo } from "react";
import { PAGE_LAYOUT_TYPES, READING_DIRECTION_TYPES } from "../constants";
import { usePageLayout } from "./usePageLayout";
import { useComicData } from "./hookstate/useComicData";
import { useP2PSettings } from "./useP2PSettings";

export function useReadingDirection() {
  const { pageLayout } = usePageLayout();
  const { comicData } = useComicData();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const comicIsVertical = comicData?.comic_format_id === 2;

  const readingDirection = useMemo(() => {
    if (comicIsVertical) return READING_DIRECTION_TYPES.vertical;
    if (!p2pEnabledAndAugmented && pageLayout === PAGE_LAYOUT_TYPES.vertical) {
      return READING_DIRECTION_TYPES.vertical;
    } else {
      return (
        comicData?.reading_direction ?? READING_DIRECTION_TYPES.leftToRight
      );
    }
  }, [p2pEnabledAndAugmented, pageLayout, comicData]);

  return { readingDirection, comicIsVertical };
}
