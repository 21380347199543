import { Flex, Text } from "@chakra-ui/react";
import { calculateTimeLeft } from "../../utils/time";
import { useTimer } from "../../screens/root/components/TimerContext";
import { memo } from "react";
import { ClockIconThick } from "../icons/ClockIconThick";

export const CountdownTimer = memo(
  ({ targetTimestamp }: { targetTimestamp: number; context: string }) => {
    useTimer();

    const timeLeft = calculateTimeLeft(targetTimestamp);

    return (
      <Flex
        width="fit-content"
        borderRadius="8px"
        padding="4px 8px"
        gap="4px"
        alignItems="center"
        bg="blaze.600"
        color="dune.dune"
      >
        <ClockIconThick boxSize="13px" />
        <Text variant="monospaceTime">
          {timeLeft.days
            ? String(timeLeft.days ?? 0).padStart(2, "0") + ":"
            : ""}
          {String(timeLeft.hours ?? 0).padStart(2, "0")}:
          {String(timeLeft.minutes ?? 0).padStart(2, "0")}:
          {String(timeLeft.seconds ?? 0).padStart(2, "0")}
        </Text>
      </Flex>
    );
  },
);
