import { BoxProps, Flex, Text, useMultiStyleConfig } from "@chakra-ui/react";
import { SharpCornerBox } from "./SharpCornerBox";
import { GCSmallLogoIcon } from "../../icons/GCSmallLogoIcon";
import { useTranslation } from "react-i18next";

type TranslationKey =
  | "components.cards.customCard.ribbonFacetText.exclusive"
  | "components.cards.customCard.ribbonFacetText.staffPick"
  | "components.cards.customCard.ribbonFacetText.comingSoon"
  | "components.cards.customCard.ribbonFacetText.newArrival"
  | "components.cards.customCard.ribbonFacetText.verticals"
  | "components.cards.customCard.ribbonFacetText.originals";

export const FacetContentMap: Record<
  number,
  { textKey: TranslationKey; color: string }
> = {
  57: {
    textKey: "components.cards.customCard.ribbonFacetText.exclusive",
    color: "blue.500",
  },
  58: {
    textKey: "components.cards.customCard.ribbonFacetText.staffPick",
    color: "success.500",
  },
  99: {
    textKey: "components.cards.customCard.ribbonFacetText.verticals",
    color: "blaze.blaze",
  },
  100: {
    textKey: "components.cards.customCard.ribbonFacetText.originals",
    color: "blaze.blaze",
  },
  101: {
    textKey: "components.cards.customCard.ribbonFacetText.comingSoon",
    color: "blue.500",
  },
  102: {
    textKey: "components.cards.customCard.ribbonFacetText.newArrival",
    color: "blue.500",
  },
};

interface CustomCardFacetProps extends BoxProps {
  facetId: keyof typeof FacetContentMap;
  variant?: "heroCard" | "featureCard";
}

export function CustomCardFacet({
  facetId,
  variant,
  ...props
}: CustomCardFacetProps) {
  const { textKey, color } = FacetContentMap[facetId];
  const { t } = useTranslation();

  if (!FacetContentMap[facetId]) {
    console.warn("Unable to determine custom card facet from id", facetId);
    return null;
  }

  const styles = useMultiStyleConfig("CustomCardFacet", { variant });

  return (
    <SharpCornerBox
      corners={["bottomLeft", "topRight"]}
      borderColor={color}
      background={color}
      padding={styles.container.padding as string}
      {...props}
    >
      <Flex alignItems="center" gap="4px">
        {(facetId === 99 || facetId === 100) && (
          <GCSmallLogoIcon
            color="neutral.white"
            h={styles.logo.height as string}
            w={styles.logo.width as string}
          />
        )}
        <Text
          mt="2px"
          as="span"
          fontSize={styles.text.fontSize as string}
          fontWeight={800}
          color="neutral.white"
          textTransform="uppercase"
        >
          {t(textKey)}
        </Text>
      </Flex>
    </SharpCornerBox>
  );
}
