import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FocusEventHandler, Ref, useEffect, useRef, useState } from "react";
import { CloseCircleIcon } from "../icons/CloseCircleIcon";
import { SearchIcon } from "../icons/SearchIcon";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../appStore";
import { useTranslation } from "react-i18next";

interface PopoverSearchBarProps {
  isOpen: boolean;
  inputRef: Ref<HTMLInputElement>;
  handleSearch: (s: string) => void;
}
export function PopoverSearchBar({
  isOpen,
  inputRef,
  handleSearch,
}: PopoverSearchBarProps) {
  const [value, setValue] = useState("");
  const [] = useTranslation();

  const initData = useHookstate(appStore.init).get({ noproxy: true });

  useEffect(() => {
    if (isOpen) setValue("");
  }, [isOpen]);

  useEffect(() => {
    handleSearch(value);
  }, [value]);

  return (
    <SearchField
      placeholder={initData.search_cta}
      value={value}
      isOpen={isOpen}
      inputRef={inputRef}
      onChange={setValue}
      // Need to delay clearing the search term to allow redirection to happen
      // before the search results are cleared
      onClear={() => setTimeout(() => setValue(""), 200)}
    />
  );
}

interface SearchFieldProps {
  placeholder?: string;
  value?: string;
  isOpen: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClear?: () => void;
  onChange?: (value: string) => void;
  clearOnBlur?: boolean;
}

export function SearchField({
  value,
  placeholder,
  isOpen,
  inputRef,
  onBlur,
  onChange,
  onClear,
  clearOnBlur = true,
}: SearchFieldProps) {
  const rightGroup = useRef<HTMLDivElement>(null);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(false);

  useEffect(() => {
    if (placeholder) {
      setIsPlaceholderVisible(true);
    }
  }, [placeholder]);

  return (
    <Flex
      w="full"
      pointerEvents={isOpen ? "auto" : "none"}
      transition="all .2s linear"
    >
      <InputGroup data-gc-ignore-input="1">
        <InputLeftElement pointerEvents="none" pl={4}>
          <SearchIcon color="dune.100" w={6} h={6} />
        </InputLeftElement>
        <Input
          ref={inputRef}
          pl={14}
          type="text"
          backgroundColor="transparent.white.10"
          borderRadius="16px"
          textColor="dune.100"
          borderColor="transparent"
          boxShadow="none"
          focusBorderColor="transparent.white.10"
          outline="none"
          placeholder={placeholder}
          _placeholder={{
            color: "dune.100",
            opacity: isPlaceholderVisible ? 1 : 0,
            transition: "opacity 0.2s ease-in-out",
          }}
          _hover={{
            backgroundColor: "transparent.white.20",
          }}
          _focusVisible={{
            outline: "none",
            backgroundColor: "transparent.white.20",
            _placeholder: {
              color: "transparent",
            },
          }}
          data-peer="search-field"
          transition="all .2s linear"
          fontWeight="bold"
          fontSize="lg"
          lineHeight="130%"
          value={value}
          onChange={(e) => {
            onChange?.(e.target.value);
          }}
          onMouseUp={(e) => {
            e.stopPropagation();

            if (!rightGroup.current) {
              return;
            }
            // Track hits on the clear area button because we've set its
            // pointerEvents to none to allow focus events to passthrough.
            // This solution is easier to deal with than forcing a focus
            // sequence
            const box = (
              rightGroup.current as HTMLElement
            ).getBoundingClientRect();

            const isWithinResetXBounds =
              e.pageX >= box.left && e.pageX <= box.right;
            const isWithinResetYBounds =
              e.pageY >= box.top && e.pageY <= box.bottom;

            if (isWithinResetXBounds && isWithinResetYBounds) {
              onClear?.();
            }
          }}
          onFocus={() => {
            if (clearOnBlur) onChange?.("");
          }}
          onBlur={(e) => {
            if (clearOnBlur) onClear?.();
            onBlur?.(e);
          }}
        />
        <InputRightElement
          _peerFocus={{ display: "flex" }}
          display="flex"
          data-peer="search-field"
          pointerEvents="none"
          ref={rightGroup}
        >
          <CloseCircleIcon
            boxSize="24px"
            color="info.200"
            aria-label="Clear search field"
            background="inherit"
            cursor="pointer"
            pointerEvents="all"
            borderRightRadius="16px"
            opacity={value ? 1 : 0}
            transition="all .1s linear"
            onClick={() => onClear?.()}
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
}
