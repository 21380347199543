import { createContext, useContext, useEffect, useRef, useState } from "react";

const TimerContext = createContext(0);

export default function TimerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [timer, setTimer] = useState(0);

  useInterval(() => {
    setTimer(Date.now());
  }, 1000);

  return (
    <TimerContext.Provider value={timer}>{children}</TimerContext.Provider>
  );
}

export const useTimer = () => useContext(TimerContext);

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
