import {
  Button,
  Flex,
  Link,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { BookIcon } from "../icons/BookIcon";
import { DownloadIcon } from "../icons/DownloadIcon";
import { ForumsIcon } from "../icons/ForumsIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { NewsIcon } from "../icons/NewsIcon";
import { PublishIcon } from "../icons/PublishIcon";
import { SearchIcon } from "../icons/SearchIcon";
import { StarIcon } from "../icons/StarIcon";
import { SideBarItem } from "./SideBarItem";
import { useAccountData } from "../../hooks/useAccountData";
import { GCThinLogoIcon } from "../icons/GCThinLogoIcon";
import { HOME_CHANNEL_ID } from "../../screens/root/constants";
import { useGaTracking } from "../../hooks/useGaTracking";
import { PreloadLink } from "./PreloadLink";
import { SealCheckThickIcon } from "../icons/SealCheckThickIcon";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../appStore";
import { SideBarFooterItem } from "./components/SideBarFooterItem";
import { useTranslation } from "react-i18next";
import { GiftCardIcon } from "../icons/GiftCardIcon.tsx";
import { ChatIcon } from "../icons/ChatIcon.tsx";
import { rootComponentsStore } from "../layouts/RootLayout.tsx";
import { ContextReferrer } from "../funnel/types.ts";
import { useChatSchedule } from "../../hooks/useChatSchedule.ts";
import { CountdownTimer } from "../time/CountdownTimer.tsx";
import { LiveIndicator } from "../chat/components/LiveIndicator.tsx";
import { sqlDateToTimestamp } from "../../utils/time.ts";

interface SideBarProps {
  collapsed?: boolean;
}

export function SideBar({ collapsed }: SideBarProps) {
  const accountData = useAccountData();
  const { trackGaEvent } = useGaTracking();
  const { t } = useTranslation();

  const { liveEvents, futureEvents } = useChatSchedule();
  const nextEvent = futureEvents?.[0];

  let publishHref = "/publish";
  if (accountData?.user?.user_creator_data) {
    publishHref =
      accountData?.user?.user_creator_data.edit_url + "manage-comics";
  }

  const initData = useHookstate(appStore.init).get({ noproxy: true });
  const baseUrl = window.location.origin;

  return (
    <VStack
      gap={1}
      paddingX={0}
      paddingTop={8}
      paddingBottom={8}
      alignItems="start"
    >
      <SideBarItem
        to="/"
        icon={<HomeIcon w={6} h={6} />}
        text="Home"
        collapsed={collapsed}
      />

      <SideBarItem
        to="/channel/originals"
        icon={<GCThinLogoIcon w={6} h={6} />}
        text="Originals"
        collapsed={collapsed}
        disabled={!accountData}
      />
      <SideBarItem
        to="/browse"
        icon={<SearchIcon w={6} h={6} />}
        text="Explore"
        collapsed={collapsed}
      />
      {!accountData?.user ? null : (
        <SideBarItem
          to="/library"
          icon={<BookIcon w={6} h={6} />}
          text="Library"
          collapsed={collapsed}
        />
      )}
      {accountData?.features["web_next_chat"] ? (
        <SideBarItem
          to="/chat"
          icon={<ChatIcon w={6} h={6} />}
          buttonElement={
            liveEvents?.length ? (
              <LiveIndicator />
            ) : nextEvent?.start_time ? (
              <CountdownTimer
                context="sidebar"
                targetTimestamp={sqlDateToTimestamp(nextEvent.start_time)}
              />
            ) : undefined
          }
          text="Chat"
          collapsed={collapsed}
        />
      ) : null}

      {accountData?.user?.gold ? null : (
        <Flex
          direction="column"
          width="100%"
          alignItems={collapsed ? "center" : "flex-start"}
          justifyContent="center"
        >
          <StackDivider
            height="1px"
            backgroundColor="transparent.white.10"
            my={3}
          />
          {!collapsed && (
            <>
              <Text
                variant="navbar"
                color="dune.dune"
                fontSize="14px"
                lineHeight="20px"
              >
                Upgrade Your Account
              </Text>
              <Text
                variant="navbar"
                fontSize="11px"
                color="slate.100"
                pb="12px"
              >
                {initData.gold_upsell ?? "Unlimited Access to all books"}
              </Text>
            </>
          )}
          <PreloadLink
            to="/gold"
            onClick={() =>
              rootComponentsStore.eventContextReferrer.set(
                ContextReferrer.sidebar,
              )
            }
            style={{ width: "100%" }}
          >
            <Tooltip
              variant="navbar"
              label={
                <Stack gap="0">
                  <Text
                    variant="navbar"
                    color="dune.dune"
                    fontSize="14px"
                    lineHeight="20px"
                  >
                    Upgrade Your Account with Gold
                  </Text>
                  <Text variant="navbar" fontSize="11px" color="slate.100">
                    {initData.gold_upsell ?? "Unlimited access to all books"}
                  </Text>
                </Stack>
              }
              aria-label={`Navigate to gold page`}
              placement="right"
              isDisabled={!collapsed}
              gutter={4}
              style={{
                width: "100%",
                cursor: "pointer",
                opacity: 1,
              }}
            >
              <Button
                variant="primary"
                gap="8px"
                minWidth={0}
                id="btnSidebarUpsell"
                px={collapsed ? "8px" : undefined}
                width="100%"
                disabled
              >
                <SealCheckThickIcon boxSize="16px" color="white" />
                {!collapsed && "Get GlobalComix Gold"}
              </Button>
            </Tooltip>
          </PreloadLink>
        </Flex>
      )}
      <StackDivider
        height="1px"
        backgroundColor="transparent.white.10"
        my={3}
      />

      {!collapsed && (
        <Text variant="navbar" color="dune.dune" pb={"12px"} fontSize="14px">
          Channels
        </Text>
      )}

      {accountData?.home_tab_channels
        .filter(
          (channel) =>
            channel.config &&
            channel.streams.length > 0 &&
            channel.id != HOME_CHANNEL_ID,
        )
        .map(function (channel) {
          const initialStream = channel.streams[0];
          const iconUrl = channel?.config[0]?.icon;
          return (
            <SideBarItem
              key={channel.id}
              to={`/channel/${initialStream.slug}`}
              icon={
                iconUrl ? (
                  <img
                    src={iconUrl}
                    alt="channel icon"
                    style={{ width: "22px", height: "auto" }}
                  />
                ) : (
                  <GCThinLogoIcon w={6} h={6} />
                )
              }
              text={channel.display_name}
              collapsed={collapsed}
            />
          );
        })}

      <StackDivider
        height="1px"
        backgroundColor="transparent.white.10"
        my={3}
      />
      <SideBarItem
        to="/news"
        icon={<NewsIcon w={6} h={6} />}
        text="News"
        collapsed={collapsed}
        external
      />
      <SideBarItem
        to="/downloads"
        icon={<DownloadIcon w={6} h={6} />}
        text="Downloads"
        collapsed={collapsed}
        external
      />
      <SideBarItem
        to="/forums"
        icon={<ForumsIcon w={6} h={6} />}
        text="Forums"
        collapsed={collapsed}
        external
      />
      <SideBarItem
        to="/talent/projects"
        icon={<StarIcon w={6} h={6} />}
        text="Commissions"
        collapsed={collapsed}
        external
      />
      <SideBarItem
        to="/gift-card"
        icon={<GiftCardIcon w={6} h={6} />}
        text="Gift Cards"
        collapsed={collapsed}
      />
      <StackDivider
        height="1px"
        backgroundColor="transparent.white.10"
        my={3}
      />
      <Link aria-label="Publish" href={publishHref} w="full">
        <Tooltip
          variant="navbar"
          label="Publish"
          aria-label="Navigate to publisher page"
          placement="right"
          isDisabled={!collapsed}
          gutter={4}
        >
          <Button
            aria-label="Publish"
            size="md"
            variant="tertiary"
            w="full"
            paddingRight={collapsed ? 4 : 8}
            paddingLeft={collapsed ? 4 : 8}
            minWidth={collapsed ? "40px" : "46px"}
            onClick={() => {
              trackGaEvent("clicked", {
                gcElementIdentifier: "sidebar-publish",
                gcTrackingIdentifier: "publish-webnext",
              });
            }}
          >
            <Flex w="full" alignItems="center" justifyContent="center" gap={2}>
              <PublishIcon w={4} h={4} />
              {collapsed ? null : (
                <Text
                  fontSize="sm"
                  fontWeight="700"
                  lineHeight="11px"
                  letterSpacing=".5px"
                >
                  Publish
                </Text>
              )}
            </Flex>
          </Button>
        </Tooltip>
      </Link>

      {!collapsed && (
        <>
          <StackDivider
            height="1px"
            backgroundColor="transparent.white.10"
            my={3}
          />
          <Flex columnGap={2} wrap="wrap">
            <SideBarFooterItem to={`${baseUrl}/about`}>
              {t("components.navigation.footer.about")}
            </SideBarFooterItem>
            <SideBarFooterItem to={`${baseUrl}/support/contact`}>
              {t("components.navigation.footer.contactUs")}
            </SideBarFooterItem>
            <SideBarFooterItem preloadLink to="/content-policy">
              {t("components.navigation.footer.contentPolicy")}
            </SideBarFooterItem>
            <SideBarFooterItem preloadLink to="/publisher-terms-of-service">
              {t("components.navigation.footer.publisherSLA")}
            </SideBarFooterItem>
            <SideBarFooterItem preloadLink to="/privacy-policy">
              {t("components.navigation.footer.privacyPolicy")}
            </SideBarFooterItem>
            <SideBarFooterItem to={`${baseUrl}/support/faq`}>
              {t("components.navigation.footer.faq")}
            </SideBarFooterItem>
            <SideBarFooterItem preloadLink to="/terms-of-use">
              {t("components.navigation.footer.termsOfUse")}
            </SideBarFooterItem>
          </Flex>
        </>
      )}
    </VStack>
  );
}
