import { Flex, keyframes, Spinner } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { GCBigLogoIcon } from "../icons/GCBigLogoIcon";
import { GCSmallLogoIcon } from "../icons/GCSmallLogoIcon";
import { GlobalSearch } from "../layouts/components/GlobalSearch";
import { ConversationMenu } from "./components/ConversationMenu";
import { LoginMenu } from "./components/LoginMenu.tsx";
import { MenuToggle } from "./components/MenuToggle";
import { NotificationMenu } from "./components/NotificationMenu";
import { useIsOverlayOpen } from "../../screens/reader/hooks/hookstate/useIsOverlayOpen.ts";
import { AccountMenu } from "./components/AccountMenu.tsx";
import { useUserData } from "../../hooks/useUserData.ts";
import { useHookstate } from "@hookstate/core";
import { rootComponentsStore } from "../layouts/RootLayout.tsx";
import { NAVBAR_HEIGHT } from "../../screens/root/constants.ts";

interface NavBarProps {}

const slideIn = keyframes`
  0% {
    margin-top: -${NAVBAR_HEIGHT}px;
  }
  100% {
    margin-top: 0px;
  }
`;
export function NavBar({}: NavBarProps) {
  const { userData, isFetching } = useUserData();
  const isSmall = useIsSmallBreakpoint();
  const { isOverlayOpen: isReaderOverlayOpen } = useIsOverlayOpen();
  const location = useLocation();
  const inReader = useHookstate(rootComponentsStore.inReader).get();

  const isGoldPage = location.pathname.includes("gold");

  return (
    <Flex
      position="relative"
      height={NAVBAR_HEIGHT + "px"}
      minHeight={NAVBAR_HEIGHT + "px"}
      paddingLeft={isSmall ? "10px" : "32px"}
      background="charcoal.charcoal"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.4)"
      borderBottom="1px solid"
      borderColor="transparent.white.10"
      zIndex={isReaderOverlayOpen ? 1402 : inReader ? 0 : 2}
      animation={isReaderOverlayOpen ? `${slideIn} .1s linear 1` : "none"}
    >
      <Flex direction="row" gap={isSmall ? "0" : "4px"} alignItems="center">
        {!isGoldPage && <MenuToggle />}
        <Link to="/" aria-label="GlobalComix Logo">
          {isSmall ? (
            <GCSmallLogoIcon color="blaze.blaze" boxSize="24px" />
          ) : (
            <GCBigLogoIcon color="blaze.blaze" width="136px" height="26px" />
          )}
        </Link>
      </Flex>
      <Flex flex={1} height="100%" position="relative" justifyContent="center">
        {!isSmall && <GlobalSearch />}
      </Flex>
      <Flex
        direction="row"
        gap={1}
        height="100%"
        alignItems="center"
        paddingRight={isSmall ? "14px" : "38px"}
      >
        {isFetching ? (
          <Flex
            height="100%"
            alignItems="center"
            justifyContent="center"
            width="146px"
          >
            <Spinner color="blaze.blaze" />
          </Flex>
        ) : (
          <>
            {isSmall && <GlobalSearch />}
            {userData ? (
              <>
                <NotificationMenu />
                <ConversationMenu />
                <AccountMenu />
              </>
            ) : (
              <Flex alignItems="center">
                <LoginMenu />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
