import { useQuery } from "@tanstack/react-query";
import { getSections } from "../../root/api/rootScreenApi";
import { DisplayFlags, LayoutStyle } from "../../../types";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { ContentCard } from "../../../components/cards/ContentCard";
import { ButtonCarousel } from "../../../components/carousel/ButtonCarousel";
import { useMemo, useState } from "react";
import React from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { sizeEstimator } from "../../../components/cards/hooks/useGridCardDimensions";

export function ListOfPopularComics() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const [scrollByCount, setScrollByCount] = useState(4);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { data } = useQuery({
    queryKey: [
      "POPULAR_COMICS_SECTION",
      "_internal.gc.www.pages.gold.__webnext-sections",
    ],
    queryFn: ({ queryKey }) => {
      const sectionKey = queryKey[1];
      return getSections(sectionKey, 1, new AbortController().signal);
    },
  });

  const section = data?.data.payload.results[0];
  const comics = section?.comics;
  const layout: {
    style: LayoutStyle;
    display: DisplayFlags;
  } = {
    display: {
      include_cover_image: true,
      include_name: true,
      include_add_library_cta: true,
    },
    style: {
      context: "comics",
      size: "l",
      mode: "horizontal",
      corners_bottom: "left",
      corners_top: "right",
      rows: 1,
      section_is_inset: false,
    },
  };

  const cardWidth = useMemo(() => {
    const dimensions = sizeEstimator(
      layout.style.context,
      layout.style.mode,
      layout.style.size,
    );
    return dimensions.width;
  }, [section]);

  useResizeObserver(containerRef, (entry) => {
    setScrollByCount(Math.floor(entry.contentRect.width / cardWidth + 16));
  });

  if (comics && comics.length > 0) {
    return (
      <Flex flexDirection="column" width="100%" ref={containerRef}>
        <Text
          textAlign={isSmallBreakpoint ? "center" : "left"}
          variant="goldTitle"
          paddingBottom="40px"
          sx={{
            textWrap: "balance",
          }}
        >
          Unlock tens of thousands of award-winning stories
        </Text>
        <Box position="relative" role="group">
          <ButtonCarousel width="100%" slidesToScroll={scrollByCount} big>
            {comics.map((comic) => (
              <Box
                key={comic.id}
                data-context={layout.style.context}
                data-contentsize={layout.style.size}
                data-mode={layout.style.mode}
                data-includes={JSON.stringify(layout.display)}
                marginRight="16px"
              >
                <ContentCard content={comic} layout={layout} />
              </Box>
            ))}
          </ButtonCarousel>
        </Box>
      </Flex>
    );
  }

  return null;
}
