import { useP2PSettings } from "../../hooks/useP2PSettings";
import { t } from "i18next";
import { useReleaseFragmentsData } from "../../hooks/hookstate/useReleaseFragmentsData";
import { SettingsToggle } from "./SettingsToggle";

export function P2PEnabledToggle() {
  const { releaseFragmentsData } = useReleaseFragmentsData();
  const { p2pEnabledAndAugmented, toggleP2PEnabled } = useP2PSettings();

  return (
    <SettingsToggle
      isDisabled={!releaseFragmentsData?.length}
      isChecked={p2pEnabledAndAugmented}
      onChange={toggleP2PEnabled}
      title={t("screens.reader.settingsMenu.enableP2P")}
    />
  );
}
