import { motion } from "framer-motion";
import { useComicNavigation } from "../../hooks/useComicNavigation";
import { Text, Box, Flex } from "@chakra-ui/react";
import { t } from "i18next";
import { ReactionToggles } from "./ReactionToggles";
import { PanelActions } from "./PanelActions";
import { EndCallToAction } from "./EndCallToActions";
import { StartNextRelease } from "./EndOfReleaseCTA";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";

interface EndInterstitialProps {
  isVertical?: boolean;
}
export function EndInterstitial({ isVertical = false }: EndInterstitialProps) {
  const { hasNextRelease } = useComicNavigation();
  const { onCloseMenu } = useSlideInMenu();
  return (
    <Box
      as={motion.div}
      animate={{ opacity: 1, scale: [0.6, 1] }}
      width="100%"
      height={isVertical ? "auto" : "100%"}
      position={isVertical ? "relative" : "fixed"}
      top={0}
      left={0}
      display="flex"
      opacity={0}
      padding="8px"
      bg="black"
      onClick={(e) => {
        e.stopPropagation();
        onCloseMenu();
      }}
      overflowY={isVertical ? "visible" : "auto"}
      pointerEvents={"auto"}
    >
      <Flex
        flex={1}
        flexDirection="column"
        borderRadius="12px"
        width="100%"
        bg="reader.slate.700"
        alignItems="center"
      >
        <Flex
          width="100%"
          bg="reader.slate.500"
          px="24px"
          py="48px"
          justifyContent="center"
          flexDirection="column"
          borderTopRadius="12px"
          gap="24px"
          alignItems="center"
        >
          {hasNextRelease && <StartNextRelease isBig />}
          <Text
            fontWeight="600"
            fontSize="16px"
            textAlign="center"
            color="reader.neutral.400"
            lineHeight="24px"
          >
            {hasNextRelease
              ? t("screens.reader.interstitial.end_of_release")
              : t("screens.reader.interstitial.end_of_series")}
          </Text>
          <Text
            fontSize="20px"
            textAlign="center"
            fontWeight="700"
            lineHeight="28px"
          >
            {t("screens.reader.interstitial.what_did_you_think")}
          </Text>
          <ReactionToggles />
          <PanelActions />
        </Flex>
        <EndCallToAction hasNextRelease={hasNextRelease} />
      </Flex>
    </Box>
  );
}
