import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { SectionTitle } from "../../components/text/SectionTitle";
import { cloneElement, useEffect, useState } from "react";
import { useFunnel } from "../../components/funnel/hooks/useFunnel";
import {
  FUNNEL_TYPES_TYPES,
  FUNNEL_TYPES,
} from "../../components/funnel/utils/contants";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { useNavigate } from "react-router";
import { t } from "i18next";
import { publishViewedGiftOfferEvent } from "../root/api/eventsApi";
import { FunnelContextArea } from "../../components/funnel/types";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";
import { DownloadOurApp } from "../../components/downloadOurApp/DownloadOurApp";

export function GiftScreen() {
  const [funnelType, setFunnelType] = useState<FUNNEL_TYPES_TYPES | undefined>(
    FUNNEL_TYPES.subscription,
  );
  const navigate = useNavigate();
  const createToast = useToast();
  const { funnelBody } = useFunnel(
    () => setFunnelType(undefined),
    (funnelConfig) => setFunnelType(funnelConfig.type),
    funnelType,
    {
      skipAuthentication: true,
      modalContext: "gift",
      onAuthenticated: () => {
        navigate("/");
        createToast({
          status: "success",
          description: t("screens.gift.giftSubscriptionActivated"),
          duration: 5000,
        });
      },
    },
  );

  const isSmallBreakpoint = useIsSmallBreakpoint();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.gold,
  });
  useEffect(() => publishViewedGiftOfferEvent(navigationContext), []);

  return (
    <Flex
      overflow="auto"
      height="100%"
      width="100%"
      position="relative"
      flexDirection="column"
      paddingTop="56px"
      alignItems="center"
      px={isSmallBreakpoint ? "24px" : undefined}
      pr={isSmallBreakpoint ? undefined : "72px"}
      pb="50px"
    >
      <Flex gap="48px" flexDirection={"column"} zIndex={1} maxWidth="1200px">
        <Flex width="100%">
          {cloneElement(funnelBody, { horizontal: true })}
        </Flex>
        <SectionTitle fontSize="32px">
          The perfect gift for comic fans
        </SectionTitle>
        <Flex flexDirection="column">
          <Text variant="faqTitle">
            Welcome to a world of limitless stories!
          </Text>
          <br />
          <Text variant="faqAnswer">
            GlobalComix is a digital reading and publishing service for comics,
            manga, graphic novels, and vertical scroll stories. Read on any
            device with the GlobalComix app* and website.
            <br />
            <br />* Available on iOS and Android phones and tablets
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Text variant="faqTitle">Unlimited Reading Access</Text>
          <br />
          <Text variant="faqAnswer">
            Enjoy access to 77,000+ books featuring classics like Batman,
            Stranger Things, and Invincible, plus independent hits from
            publishers and creators.
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Text variant="faqTitle">Download and Read Offline</Text>
          <br />
          <Flex flexDirection="column">
            <Text variant="faqAnswer">
              Download any books available to GlobalComix Gold members on the
              GlobalComix app. This allows you to read offline, even without a
              data connection. Store as many books as your device can support.
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text variant="faqTitle">
            Reading comics support Indie publishers and creators
          </Text>
          <br />
          <Text variant="faqAnswer">
            Your membership directly funds creators and publishers, with up to
            70% of your subscription distributed directly to the owners of the
            content you read.
          </Text>
        </Flex>
        <Box height="1px" width="100%" bg="transparent.white.20" />
        <DownloadOurApp />
      </Flex>
      <Box
        position="absolute"
        top={0}
        zIndex={0}
        left={0}
        height="50%"
        width="100%"
        bgImage={`${window.gc.global.assets_url}/static/images/web-ui/goldBackground.jpeg`}
        bgRepeat="no-repeat"
        backgroundColor="charcoal.charcoal"
        boxShadow="25px 25px 50px 0 var(--chakra-colors-charcoal-charcoal) inset, -25px -25px 50px 0 var(--chakra-colors-charcoal-charcoal) inset"
        opacity={0.13}
        backgroundPosition="center center"
        backgroundSize="cover"
      />
    </Flex>
  );
}
