import { Text, Flex, Button, Box } from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";

interface ShowMoreSectionProps {
  children: React.ReactNode;
  collapsedHeight?: number;
  expandedMarginBottom?: string;
}

export function ShowMoreSection({
  children,
  collapsedHeight = 95,
  expandedMarginBottom,
}: ShowMoreSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const toggleExpansion = () => setIsExpanded((prev) => !prev);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > collapsedHeight);
    }
  }, [children, collapsedHeight]);

  return (
    <Box style={{ marginBottom: "10px" }}>
      <Box
        ref={contentRef}
        style={{
          maxHeight: isExpanded
            ? `${(contentRef.current?.scrollHeight ?? 0) + parseInt(expandedMarginBottom ?? "0")}px`
            : `${collapsedHeight}px`,
          overflow: "hidden",
          transition: "max-height 0.7s ease",
          willChange: "max-height",
        }}
      >
        {children}
      </Box>

      {isOverflowing && (
        <Box
          position="relative"
          h="5rem"
          w="100%"
          bgGradient="linear(to-t, charcoal.charcoal, transparent)"
          mt="-5rem"
          style={{
            opacity: isExpanded ? 0 : 1,
            transition: "opacity 0.5s ease",
            pointerEvents: "none",
          }}
        />
      )}

      {isOverflowing && (
        <Flex align="center" position="relative" mt="20px">
          <Box flex="1" h="1px" bg="whiteAlpha.100" />
          <Button
            size="sm"
            onClick={toggleExpansion}
            variant="outline"
            borderRadius="10px"
            borderWidth="1px"
            borderColor="whiteAlpha.100"
          >
            <Text fontSize="12px" fontWeight="400" color="neutral.400">
              {isExpanded ? "Show less" : "Show more"}
            </Text>
          </Button>
          <Box flex="1" h="1px" bg="whiteAlpha.100" />
        </Flex>
      )}
    </Box>
  );
}
