import { Text, Flex, Modal, ModalBody, ModalContent } from "@chakra-ui/react";
import { ReleaseProgressBar } from "./ReleaseProgressBar";
import { ReleaseProgress } from "./ReleaseProgress";
import { OverlayActions } from "./OverlayActions";
import { ReleaseSummary } from "./ReleaseSummary";
import { MenuActions } from "./MenuActions";
import { QRCode } from "./QRCode";
import {
  useIsLandscape,
  useIsSmallBreakpoint,
  useIsSmallLandscape,
  useMobileBreakpoint,
} from "../../../../utils/useBreakpoints";
import { t } from "i18next";
import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { isMobile as isMobileBrowser } from "react-device-detect";

interface ReaderOverlayProps {
  openMenu?: string;
  isOpen: boolean;
  onToggle: () => void;
}
export function ReaderOverlay({
  openMenu,
  isOpen,
  onToggle,
}: ReaderOverlayProps) {
  const isMobile = useMobileBreakpoint();
  const isSmall = useIsSmallBreakpoint();
  const isSmallLandscape = useIsSmallLandscape();
  const isLandscape = useIsLandscape();
  const responseError = useHookstate(readerDataStore.responseError).get();

  if (responseError) return null;

  return (
    <Modal
      size="full"
      trapFocus={false}
      isOpen={isOpen}
      onClose={onToggle}
      blockScrollOnMount={false}
      motionPreset="none"
      isCentered
    >
      <ModalContent
        onClick={onToggle}
        bg={isOpen ? "transparent.black.80" : "transparent"}
        overflow="hidden"
        pr={!isMobile && openMenu ? "400px" : 0}
        transition="padding-right .2s linear"
      >
        {isSmallLandscape ? (
          <ModalBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" fontWeight="600" lineHeight="32px">
              {t("screens.reader.overlay.noLandscape")}
            </Text>
            <Text
              fontSize="18px"
              fontWeight="400"
              lineHeight="28px"
              textAlign="center"
            >
              {t("screens.reader.overlay.noLandscapeDescOne")}
              <br />
              {t("screens.reader.overlay.noLandscapeDescTwo")}
            </Text>
          </ModalBody>
        ) : (
          <ModalBody display="flex" p={0} position="relative">
            <ReleaseSummary />
            <MenuActions />
            {isSmall && openMenu ? null : (
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Flex width="100%" height="116px" />
                <ReleaseProgress />
                <ReleaseProgressBar />
              </Flex>
            )}
            {!isMobile && !(isMobileBrowser && isLandscape) && <QRCode />}
            {((isMobileBrowser && !isLandscape) || !isMobileBrowser) && (
              <OverlayActions />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
