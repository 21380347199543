import {
  Box,
  Flex,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
} from "@chakra-ui/react";
import { Immutable } from "@hookstate/core";
import { Fragment } from "react";
import { CheckIcon } from "../icons/CheckIcon.tsx";
import { RadioOffIcon } from "../icons/RadioOffIcon.tsx";
import { RadioOnIcon } from "../icons/RadioOnIcon.tsx";
import { Option } from "../../types.ts";

interface RadioFilterProps<T> extends UseRadioGroupProps {
  value: Immutable<string | undefined>;
  options: Option<T>[];
  onChange: (nextValue: string) => void;
  useCheck?: boolean;
}

export function RadioFilter<T extends string>({
  value,
  options,
  onChange,
  ...props
}: RadioFilterProps<T>) {
  if (!options.length) {
    console.warn("No options provided for radio filter");
    return null;
  }

  const { getRadioProps } = useRadioGroup({
    value: `${value}`,
    defaultValue: options[0].value,
    onChange,
  });

  return (
    <Flex direction="column">
      <Stack data-gc-ignore-input="1" className="ignore-radio">
        {options.map((option, i) => (
          <Fragment key={option.value}>
            <CustomRadio
              key={option.value}
              {...getRadioProps({ value: option.value })}
              {...props}
            >
              <Text
                pointerEvents="none"
                variant="filterContent"
                color={option.value === value ? "dune.100" : "dune.700"}
              >
                {option.label}
              </Text>
            </CustomRadio>
            {i < options.length - 1 && (
              <Box w="full" h="1px" bg="transparent.white.10" />
            )}
          </Fragment>
        ))}
      </Stack>
    </Flex>
  );
}

interface CustomRadioProps extends UseRadioProps {
  children: React.ReactNode;
  useCheck?: boolean;
}
function CustomRadio(props: CustomRadioProps) {
  const { state, getInputProps } = useRadio(props);
  const input = getInputProps();

  return (
    <Box layerStyle="filterList" as="label" cursor="pointer" role="group">
      <input {...input} hidden />
      {props.children}
      {state.isChecked ? (
        props.useCheck ? (
          <CheckIcon pointerEvents="none" color="blaze.300" w={5} h={5} />
        ) : (
          <RadioOnIcon pointerEvents="none" color="blaze.300" w={5} h={5} />
        )
      ) : props.useCheck ? (
        <></>
      ) : (
        <RadioOffIcon
          pointerEvents="none"
          _groupHover={{ color: "transparent.white.80" }}
          color="transparent.white.60"
          w={5}
          h={5}
        />
      )}
    </Box>
  );
}
