import { Icon, IconProps } from "@chakra-ui/react";

export function GoogleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M11.9998 10.1816V14.0544H17.3816C17.1452 15.2998 16.4361 16.3544 15.3724 17.0635L18.6179 19.5817C20.5088 17.8363 21.5997 15.2726 21.5997 12.2272C21.5997 11.5181 21.5361 10.8362 21.4179 10.1817L11.9998 10.1816Z"
        fill="#4285F4"
      />
      <path
        d="M6.39568 13.9043L5.66371 14.4646L3.07275 16.4828C4.7182 19.7464 8.09068 22.0009 11.9997 22.0009C14.6997 22.0009 16.9633 21.11 18.6179 19.5828L15.3724 17.0646C14.4815 17.6646 13.3451 18.0283 11.9997 18.0283C9.39976 18.0283 7.19073 16.2737 6.39976 13.9101L6.39568 13.9043Z"
        fill="#34A853"
      />
      <path
        d="M3.07265 7.51758C2.39087 8.86298 2 10.3812 2 11.9993C2 13.6175 2.39087 15.1357 3.07265 16.4811C3.07265 16.4901 6.39998 13.8993 6.39998 13.8993C6.19998 13.2993 6.08177 12.663 6.08177 11.9992C6.08177 11.3355 6.19998 10.6992 6.39998 10.0992L3.07265 7.51758Z"
        fill="#FBBC05"
      />
      <path
        d="M11.9999 5.98182C13.4727 5.98182 14.7818 6.49089 15.8272 7.47272L18.6908 4.609C16.9633 2.96355 14.6998 2 11.9999 2C8.09089 2 4.71836 4.25455 3.07291 7.51818L6.39991 10.0998C7.19089 7.73618 9.39991 5.98182 11.9999 5.98182Z"
        fill="#EA4335"
      />
    </Icon>
  );
}
