import { useHookstate } from "@hookstate/core";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterState, libraryFilterStore } from "../components/LibraryFilters";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../components/sortMenus/SortDurationMenu";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../components/sortMenus/SortOrderMenu";
import { DEFAULT_READING_STATUS_TYPE } from "../components/LibraryFilterCarousel";

export function useLibraryFilterState() {
  const filterState = useHookstate(libraryFilterStore);
  const filters = useMemo(() => {
    const filters = new URLSearchParams();
    const { query, sortMenus, comicReadingStatus } = filterState.get();

    if (query) {
      filters.set("q", query);
    }

    if (
      comicReadingStatus &&
      comicReadingStatus !== DEFAULT_READING_STATUS_TYPE
    ) {
      filters.set("comic_user_reading_status_id", comicReadingStatus);
    }

    if (
      sortMenus.sortOrder &&
      sortMenus.sortOrder !== DEFAULT_SORT_ORDER_TYPE
    ) {
      filters.set("sort", sortMenus.sortOrder);
    }

    if (
      sortMenus.sortDuration &&
      sortMenus.sortDuration !== DEFAULT_SORT_DURATION_TYPE
    ) {
      filters.set("sd", sortMenus.sortDuration);
    }

    return filters;
  }, [filterState]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSettled, setUrlSettled] = useState(false);

  useEffect(() => {
    const newLibraryFilterState: FilterState = {
      query: searchParams.get("q") ?? undefined,
      comicReadingStatus:
        searchParams.get("comic_user_reading_status_id") ??
        DEFAULT_READING_STATUS_TYPE,
      sortMenus: {
        sortDuration: searchParams.get("sd") ?? DEFAULT_SORT_DURATION_TYPE,
        sortOrder: searchParams.get("sort") ?? DEFAULT_SORT_ORDER_TYPE,
      },
    };
    if (!isEqual(newLibraryFilterState, filterState.get({ noproxy: true }))) {
      libraryFilterStore.set(newLibraryFilterState);
    }
    if (!urlSettled) {
      setUrlSettled(true);
    }
  }, [urlSettled, searchParams]);

  useEffect(() => {
    const searchFilters = new URLSearchParams(filters);

    if (urlSettled) {
      const newSearch = "?" + searchFilters.toString();
      if (newSearch !== location.search) {
        setSearchParams(newSearch);
      }
    }
  }, [urlSettled, filters]);

  return {
    urlSettled,
    filters,
  };
}
