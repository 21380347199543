import { Button, Flex, ModalHeader, Text } from "@chakra-ui/react";
import { FunnelComponentProps } from "../../funnel/types";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Comic } from "../../../types";
import { UseFunnelOptionsTypes } from "../../funnel/hooks/useFunnel";

export function ReadingStatusModalHeader({
  onClose,
  cacheKey,
}: FunnelComponentProps & { cacheKey?: UseFunnelOptionsTypes["cacheKey"] }) {
  const { t } = useTranslation();

  const { data } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
  });

  const libraryStatus = data?.library_status;

  return (
    <ModalHeader flexDirection="column" padding="16px 24px 16px 24px">
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Text
          variant="title"
          color="dune.dune"
          fontSize="20px"
          lineHeight="130%"
        >
          {!libraryStatus
            ? t("components.readingStatus.addToLibrary")
            : t("components.readingStatus.manageLibrary")}
        </Text>
        <Button
          variant="iconOnly"
          onClick={onClose}
          color="dune.600"
          _hover={{ color: "dune.100" }}
        >
          <CloseIcon w="16px" h="16px" />
        </Button>
      </Flex>
    </ModalHeader>
  );
}
