import { LayoutStyle, DisplayFlags } from "../../types";

export function comicsResultsCard(mode: LayoutStyle["mode"]) {
  return {
    display: {
      include_name: true,
      include_author: true,
      include_cover_image: true,
      include_progress_meter: true,
      include_match: mode === "grid",
      include_ribbon_badge: mode === "grid",
      include_add_library_cta: mode === "list",
      include_description: mode === "list",
    },
    style: {
      context: "comics",
      mode,
      size: mode === "list" ? "c_1" : "l",
      rows: 1,
      corners_top: "left",
      corners_bottom: "left",
      section_is_inset: false,
    },
  } as { display: DisplayFlags; style: LayoutStyle };
}

export function libraryResultsCard(mode: LayoutStyle["mode"]) {
  const layout = comicsResultsCard(mode);
  return {
    display: {
      ...layout.display,
      include_match: false,
      include_ribbon_badge: false,
      include_reading_status_button: true,
    },
    style: { ...layout.style, size: mode === "grid" ? "l" : "c_1" },
  } as { display: DisplayFlags; style: LayoutStyle };
}

export function publisherComicsResultsCard(mode: LayoutStyle["mode"]) {
  const layout = comicsResultsCard(mode);
  return {
    display: {
      ...layout.display,
      include_description: mode === "list",
      include_match: false,
      include_ribbon_badge: false,
      include_add_library_cta: true,
    },
    style: { ...layout.style, size: mode === "grid" ? "l" : "c_1" },
  } as { display: DisplayFlags; style: LayoutStyle };
}
