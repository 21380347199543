import { Icon, IconProps } from "@chakra-ui/react";

export function TextIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.5C3.72386 5.5 3.5 5.72386 3.5 6C3.5 6.27614 3.72386 6.5 4 6.5H20C20.2761 6.5 20.5 6.27614 20.5 6C20.5 5.72386 20.2761 5.5 20 5.5H4ZM3.5 10C3.5 9.72386 3.72386 9.5 4 9.5H14C14.2761 9.5 14.5 9.72386 14.5 10C14.5 10.2761 14.2761 10.5 14 10.5H4C3.72386 10.5 3.5 10.2761 3.5 10ZM4 13.5C3.72386 13.5 3.5 13.7239 3.5 14C3.5 14.2761 3.72386 14.5 4 14.5H20C20.2761 14.5 20.5 14.2761 20.5 14C20.5 13.7239 20.2761 13.5 20 13.5H4ZM4 17.5C3.72386 17.5 3.5 17.7239 3.5 18C3.5 18.2761 3.72386 18.5 4 18.5H14C14.2761 18.5 14.5 18.2761 14.5 18C14.5 17.7239 14.2761 17.5 14 17.5H4Z"
        fill="currentColor"
      />
    </Icon>
  );
}
