import { Box, keyframes } from "@chakra-ui/react";
import { GCSmallLogoIcon } from "../../../components/icons/GCSmallLogoIcon";

const animation = keyframes`
  50% {
    color: var(--chakra-colors-blaze-700);
  }
  100% {
    color: var(--chakra-colors-blaze-blaze);
  }
`;
export function GCLogo() {
  return (
    <Box
      layerStyle="readerContainer"
      zIndex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="blaze.blaze"
      animation={`${animation} 3s ease-in-out infinite`}
      flexDirection="column"
      gap="24px"
    >
      <GCSmallLogoIcon width="77px" height="77px" />
    </Box>
  );
}
