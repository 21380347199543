import { Button, Flex, useToast } from "@chakra-ui/react";
import { TextFieldGroup } from "../../fields/TextFieldGroup";
import { useState } from "react";
import {
  LOGIN_EMAIL_PASSWORD_KEY,
  loginWithEmailAndPassword,
} from "../../auth/api/authApi";
import { useMutation } from "@tanstack/react-query";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import axios from "axios";
import { ServerResponse } from "../../../types";
import { useTranslation } from "react-i18next";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";

export function LoginForm({
  onClose,
  invalidate = true,
  loading,
  loadingText,
}: {
  onClose: () => void;
  invalidate?: boolean;
  loading?: boolean;
  loadingText?: string;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const toast = useToast();
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateQueries = useInvalidateQueries(
    ACCOUNT_INIT_QUERY_KEY,
    releaseQueryKey as string[],
  );
  const { t } = useTranslation();

  const { mutate, isPending } = useMutation({
    mutationKey: LOGIN_EMAIL_PASSWORD_KEY,
    mutationFn: () => loginWithEmailAndPassword(email, password),
    onSuccess: (_) => {
      if (invalidate) invalidateQueries();
      onClose();
    },
    onError: (error) => {
      if (axios.isAxiosError<ServerResponse<unknown>>(error)) {
        const errorData = error.response?.data;
        const fieldsWithErrors = errorData?.payload.form_fields?.filter(
          (field) => field.error !== null,
        );

        if (fieldsWithErrors) {
          toast({
            title: fieldsWithErrors.pop()?.error,
            status: "error",
          });
          return;
        }
        toast({
          title: t("components.auth.loginFailed"),
          status: "error",
        });
      }
    },
  });

  const handleLoginClick = async () => {
    if (!email || !password) {
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setEmailIsValid(isValidEmail);

    if (isValidEmail) {
      mutate();
    }
  };

  return (
    <Flex
      direction="column"
      pb="24px"
      gap={1}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleLoginClick();
        }
      }}
    >
      <TextFieldGroup
        textFields={[
          {
            id: "inputLoginEmail",
            type: "email",
            placeholder: t("components.auth.email"),
            onChange: (value) => setEmail(value),
            error: emailIsValid
              ? undefined
              : t("components.auth.invalidEmailFormat"),
            autoFocus: true,
          },
          {
            id: "inputLoginPassword",
            type: "password",
            placeholder: t("components.auth.password"),
            onChange: (value) => setPassword(value),
          },
        ]}
      />
      <Button
        id="btnLoginSubmit"
        variant="primary"
        size="lg"
        type="submit"
        mt="24px"
        width={"full"}
        onClick={handleLoginClick}
        isLoading={loading || isPending}
        loadingText={loadingText}
      >
        {t("components.auth.login")}
      </Button>
    </Flex>
  );
}
