import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

import { ReleaseContentCardProps } from "../../types";
import { BookmarkIcon } from "../icons/BookmarkIcon";
import {
  ContentCardBackdrop,
  ExtendedContentCardBackdrop,
} from "./components/ContentCardBackdrop";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { FreeRibbon } from "./components/FreeRibbon";
import { GridCardWrapper } from "./components/GridCardWrapper";
import { SharpCornerBox } from "./components/SharpCornerBox";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { useMatchPercentage } from "./hooks/useMatchPercentage";

export function ReleaseGridCard({
  content,
  layout: { display, style },
}: ReleaseContentCardProps) {
  const layout_size = style.size;

  const { height, width } = useGridCardDimensions(
    "releases",
    style.mode,
    layout_size,
  );

  switch (layout_size) {
    case "s":
      return (
        <WideReleaseGridCard
          content={content}
          layout={{
            display,
            style,
          }}
          width={width}
          height={height}
        />
      );
    case "m":
    case "l":
      return (
        <GridCardWrapper layout={{ display, style }}>
          <TallReleaseGridCard
            content={content}
            layout={{
              display,
              style,
            }}
            width={width}
            height={height}
            isSmall={layout_size === "m"}
          />
        </GridCardWrapper>
      );
    default:
      console.warn("Unsupported card size", layout_size);
      return null;
  }
}

function WideReleaseGridCard({
  content,
  layout: { display },
  height,
  width,
}: ReleaseContentCardProps & {
  height?: number;
  width?: number;
}) {
  const { include_name, include_author, include_title } = display;

  return (
    <Flex
      height={`${height}px`}
      width={`${width}px`}
      direction="row"
      position="relative"
      alignItems="center"
      justifyContent="start"
    >
      <ContentCardBackdrop
        position="absolute"
        bottom={0}
        width="calc(100% - 8px)"
        right={0}
      />
      <Box position="relative">
        <Image
          src={content.thumbnail_url}
          width="50px"
          minWidth="50px"
          borderRadius={10}
          loading="lazy"
          alt={`Cover image for ${content.localized_comic_name}`}
        />
        {content.comic.is_free ? (
          <FreeRibbon
            color="success.500"
            position="absolute"
            top={0}
            left="-1px"
          />
        ) : null}
      </Box>
      <Flex
        direction="column"
        position="relative"
        pl={3}
        width="calc(100% - 58px)"
      >
        {include_name ? (
          <Text variant="cardSmallHeader" noOfLines={1}>
            {content.localized_comic_name}
          </Text>
        ) : null}
        {include_title ? (
          <Text variant="cardSmallHeader" noOfLines={1}>
            {content.title}
          </Text>
        ) : null}
        {include_author ? (
          <Text color="neutral.200" variant="cardSubtitle">
            {content.artist?.roman_name}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
}

function TallReleaseGridCard({
  content,
  layout: { display },
  onToggleFavorite,
  isSmall,
}: ReleaseContentCardProps & {
  height?: number;
  width?: number;
  isSmall?: boolean;
}) {
  const {
    include_name,
    include_author,
    include_match,
    include_favorite,
    include_title,
  } = display;

  const { hasMatchPercentage, getMatchPercentage } = useMatchPercentage(
    content.comic,
  );
  return (
    <>
      <ExtendedContentCardBackdrop
        position="absolute"
        bottom="0px"
        h="calc(100% - 63px)"
        topBox={{
          height: "63px",
        }}
        bottomBox={{
          height: "calc(100% - 63px)",
        }}
      />
      <Box className="card-img-wrapper" aspectRatio=".646">
        <Image
          src={content.thumbnail_url}
          borderRadius={10}
          loading="lazy"
          width="100%"
          aspectRatio=".646"
          alt={`Cover image for ${content.localized_comic_name}`}
        />
        {content.comic.is_free ? (
          <FreeRibbon
            color="success.500"
            position="absolute"
            top={0}
            left="-1px"
            width="3px"
            height="8px"
            fontSize="sm"
          />
        ) : null}
        {include_match &&
        hasMatchPercentage &&
        content.comic.relevance_match ? (
          <SharpCornerBox
            position="absolute"
            bottom={0}
            left={0}
            corners={["topLeft", "bottomRight"]}
            borderWidth={0}
          >
            <Text
              textTransform="uppercase"
              fontSize="xs"
              fontWeight="bold"
              color="neutral.200"
            >
              {getMatchPercentage()}% MATCH
            </Text>
          </SharpCornerBox>
        ) : null}
      </Box>
      {!isSmall ? (
        <ContentCardFooter
          total_favorites={content.comic.total_favorites}
          total_pageviews={content.comic.total_pageviews}
          relevance_match={content.comic.relevance_match}
          update_time={content.update_time}
          total_published_releases={content.comic.total_published_releases}
          page_count={content.page_count}
          {...display}
          include_match={false}
          layoutMode="list"
        />
      ) : null}
      <Flex direction="column" position="relative" width="100%">
        {include_name ? (
          <Text variant={isSmall ? "cardSubtitle" : "cardHeader"} noOfLines={1}>
            {content.localized_comic_name}
          </Text>
        ) : null}
        {include_title ? (
          <Text variant={isSmall ? "cardSubtitle" : "cardHeader"} noOfLines={1}>
            {content.title}
          </Text>
        ) : null}

        {include_author ? (
          <Text color="neutral.200" variant="cardSubtitle">
            {content.artist?.roman_name}
          </Text>
        ) : null}
      </Flex>
      <Flex
        direction="column"
        gap={2}
        alignItems="start"
        width="100%"
        position="relative"
      >
        {include_favorite ? (
          <Button
            variant="ghost"
            w="full"
            h={7}
            bgGradient="linear(to-l, alt.blue_gradient_top, alt.blue_gradient_bottom)"
            boxShadow="0px 8px 12px #12151a4d"
            borderRadius={10}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onToggleFavorite?.(content.comic.slug, content.comic.is_favorite);
            }}
          >
            <Flex direction="row" gap={2} alignItems="center">
              <BookmarkIcon color="neutral.white" />
              <Text fontWeight="700" fontSize="10px" lineHeight="11px">
                ADD
              </Text>
            </Flex>
          </Button>
        ) : null}
      </Flex>
    </>
  );
}
