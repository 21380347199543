import { Button, ButtonProps, Flex, Tooltip } from "@chakra-ui/react";
import { forwardRef, useEffect, useState } from "react";
import { SearchField } from "../../../fields/SearchField";
import { SearchIcon } from "../../../icons/SearchIcon";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../../../appStore";

export const SmallSearchTrigger = forwardRef<HTMLDivElement, ButtonProps>(
  ({ ...props }, ref) => {
    return (
      <Flex height="100%" ref={ref} alignItems="flex-end">
        <Tooltip
          variant="navbar"
          label="Search"
          aria-label="search-tooltip"
          isDisabled={!!props["aria-expanded"]}
        >
          <Button {...props} variant="navbar" aria-label="Toggle Search">
            <SearchIcon w={6} h={6} />
          </Button>
        </Tooltip>
      </Flex>
    );
  },
);

interface LargeSearchTriggerProps {
  handleSearch: (term: string) => void;
  resultsOpen: boolean;
}
export const LargeSearchTrigger = forwardRef<
  HTMLDivElement,
  LargeSearchTriggerProps
>(({ handleSearch, resultsOpen, ...props }, ref) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    handleSearch(value);
  }, [value]);

  const initData = useHookstate(appStore.init).get({ noproxy: true });

  return (
    <Flex as="button" alignItems="center" ref={ref} width="100%" {...props}>
      <Flex
        width="100%"
        p={2}
        overflow="auto"
        borderTopColor="none"
        borderTopRadius="24px"
        border="1px solid"
        background={resultsOpen ? "transparent.black.90" : "inherit"}
        borderColor={resultsOpen ? "transparent.white.10" : "transparent"}
        borderBottom="none"
      >
        <SearchField
          placeholder={initData.search_cta}
          onChange={setValue}
          value={value}
          isOpen={true}
          // Need to delay clearing the search term to allow redirection to happen
          // before the search results are cleared
          onClear={() => setTimeout(() => setValue(""), 200)}
        />
      </Flex>
    </Flex>
  );
});
