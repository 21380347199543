import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { GCSmallLogoIcon } from "../../icons/GCSmallLogoIcon";
import { LabeledCountdownTimer } from "../../time/LabeledCountdownTimer";

export function ChatEventCountdown({
  title = "Chat",
  icon,
  startTime,
}: {
  title?: string;
  icon?: string | JSX.Element;
  startTime: number;
}) {
  const isSmallScreen = useIsSmallBreakpoint();

  return (
    <Flex
      direction="column"
      alignItems="center"
      gap={isSmallScreen ? 2 : 4}
      margin="auto"
    >
      <Box position="relative" boxSize={isSmallScreen ? "146px" : "180px"}>
        {typeof icon === "string" ? (
          <Image
            src={icon}
            boxSize="57%"
            borderRadius="30%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="2"
          />
        ) : (
          <GCSmallLogoIcon
            color="blaze.blaze"
            boxSize="57%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="2"
          />
        )}
        <Image
          src={`${window.gc.global.assets_url}/static/images/web-ui/chatEventIconBackgroundDots.png`}
          alt="Background image"
          boxSize="100%"
          borderRadius="30%"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1"
        />
      </Box>

      <Text
        fontWeight="700"
        fontSize={isSmallScreen ? "20px" : "32px"}
        color="dune.dune"
        fontFamily="Bricolage Grotesque"
        textAlign="center"
        lineHeight="1.2"
        mb="8px"
      >
        {`Live ${title} chat`}
        <br />
        {"will start in:"}
      </Text>

      <LabeledCountdownTimer targetTimestamp={startTime} />
    </Flex>
  );
}
