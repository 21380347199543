import { Flex, Text } from "@chakra-ui/react";

interface FilterTitleProps {
  text: string;
  filterValue: string | undefined;
  activeFilters?: number;
  open?: boolean;
}

export function FilterTitle({
  text,
  filterValue = "All",
  activeFilters = 0,
  open = false,
}: FilterTitleProps) {
  return (
    <Flex
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent="space-between"
      w="full"
      p="18px 0"
      pr="12px"
      role="group"
    >
      <Text
        transition="all .2s linear"
        fontSize="lg"
        fontFamily="Roboto"
        whiteSpace="nowrap"
        color={open ? "dune.100" : "dune.700"}
        _groupHover={{ color: "dune.100" }}
        lineHeight="130%"
        fontWeight="400"
        letterSpacing=".5px"
      >
        {text}
      </Text>
      {activeFilters <= 1 ? (
        <Text
          fontFamily="Roboto"
          fontSize="10px"
          fontWeight="700"
          whiteSpace="nowrap"
          lineHeight="150%"
          letterSpacing="0.25px"
          color="blaze.300"
        >
          {filterValue}
        </Text>
      ) : (
        <Flex
          flexDirection="column"
          w="20px"
          h="20px"
          alignItems="center"
          justifyContent="center"
          color="charcoal.charcoal"
          fontWeight="bold"
          fontSize="sm"
          background="blaze.300"
          lineHeight="15px"
          letterSpacing="-1px"
          borderRadius="50%"
        >
          {activeFilters}
        </Flex>
      )}
    </Flex>
  );
}
