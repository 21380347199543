import { Flex, Text } from "@chakra-ui/react";
import { StatusIcon } from "../../icons/StatusIcon";

type Status = "ongoing" | "updated";

export function ComicStatusBadge({ statusText }: { statusText: Status }) {
  const colorMap: Record<Status, string> = {
    ongoing: "fresh.fresh",
    updated: "fresh.fresh", // TODO determine color to use
  };
  return (
    <Flex direction="row" gap={1} alignItems="center">
      <StatusIcon color={colorMap[statusText]} w={2} h={2} />
      <Text variant="cardVitals" textTransform="uppercase">
        {statusText}
      </Text>
    </Flex>
  );
}
