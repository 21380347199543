import { Icon, IconProps } from "@chakra-ui/react";

export function MoneyIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5ZM1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM12 5C12.2761 5 12.5 5.22386 12.5 5.5V6.5H13.1667C14.7315 6.5 16 7.76853 16 9.33333C16 9.60948 15.7761 9.83333 15.5 9.83333C15.2239 9.83333 15 9.60948 15 9.33333C15 8.32081 14.1792 7.5 13.1667 7.5H11C9.89543 7.5 9 8.39543 9 9.5C9 10.6046 9.89543 11.5 11 11.5H13C14.6569 11.5 16 12.8431 16 14.5C16 16.1569 14.6569 17.5 13 17.5H12.5V18.5C12.5 18.7761 12.2761 19 12 19C11.7239 19 11.5 18.7761 11.5 18.5V17.5H10.8333C9.26853 17.5 8 16.2315 8 14.6667C8 14.3905 8.22386 14.1667 8.5 14.1667C8.77614 14.1667 9 14.3905 9 14.6667C9 15.6792 9.82081 16.5 10.8333 16.5H13C14.1046 16.5 15 15.6046 15 14.5C15 13.3954 14.1046 12.5 13 12.5H11C9.34315 12.5 8 11.1569 8 9.5C8 7.84315 9.34315 6.5 11 6.5H11.5V5.5C11.5 5.22386 11.7239 5 12 5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
