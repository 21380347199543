import { Icon, IconProps } from "@chakra-ui/react";

export function MenuIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 17C4.5 16.7239 4.72386 16.5 5 16.5H19C19.2761 16.5 19.5 16.7239 19.5 17C19.5 17.2761 19.2761 17.5 19 17.5H5C4.72386 17.5 4.5 17.2761 4.5 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12C4.5 11.7239 4.72386 11.5 5 11.5H19C19.2761 11.5 19.5 11.7239 19.5 12C19.5 12.2761 19.2761 12.5 19 12.5H5C4.72386 12.5 4.5 12.2761 4.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7C4.5 6.72386 4.72386 6.5 5 6.5H19C19.2761 6.5 19.5 6.72386 19.5 7C19.5 7.27614 19.2761 7.5 19 7.5H5C4.72386 7.5 4.5 7.27614 4.5 7Z"
        fill="currentColor"
      />
    </Icon>
  );
}
