import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const filters = definePartsStyle({
  dialog: {
    w: "400px",
    height: "600px",
    bg: "charcoal.charcoal",
    border: "1px solid transparent.white.10",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.40)",
    backdropFilter: "blur(18px)",
    borderRadius: "16px",
    p: 0,
  },
  header: {
    fontSize: "26px",
    fontWeight: "bold",
    lineHeight: "130%",
    color: "dune.dune",
    pl: "24px",
    pr: "12px",
    py: "16px",
    borderBottom: "1px solid",
    borderColor: "transparent.white.10",
    display: "flex",
    w: "full",
    justifyContent: "space-between",
  },
  closeButton: {},
  body: {
    p: "24px",
    overflow: "auto",
  },
  footer: {
    borderTop: "1px solid",
    borderColor: "transparent.white.10",
    display: "flex",
    w: "full",
    justifyContent: "space-between",
    pl: "6px",
    pr: "24px",
    py: "16px",
  },
});

const sidebar = definePartsStyle({
  dialogContainer: {
    p: 0,
    alignItems: "start",
    justifyContent: "start",
    height: "100%",
  },
  dialog: {
    background: "transparent",
    top: 16,
    height: "100%",
    width: "275px",
    overflow: "none",
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: {
    filters,
    sidebar,
  },
});
