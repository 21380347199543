import {
  Box,
  Button,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { usePopoverOpenState } from "../../utils/usePopoverOpenState";
import { MoreIcon } from "../icons/MoreIcon";
import { forwardRef, ReactElement } from "react";
import { InfoIcon } from "../icons/reader/InfoIcon";
import { ShareIcon } from "../icons/ShareIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { CaretRightIcon } from "../icons/CaretRightIcon";
import { useTranslation } from "react-i18next";
import { SharePopover } from "../buttons/share/SharePopover";

export interface ReleaseCardMenuProps {
  onStartReading?: () => void;
  onContinueReading?: () => void;
  shareUrl?: string;
  onReleaseDetails?: () => void;
  onPreviewRelease?: () => void;
}

export function ReleaseCardMenu({
  onReleaseDetails,
  shareUrl,
  onStartReading,
  onPreviewRelease,
  onContinueReading,
}: ReleaseCardMenuProps) {
  const { ref, isOpen, onOpen } = usePopoverOpenState();
  const { t } = useTranslation();

  return (
    <Popover variant="filterSmall" gutter={4} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpen();
          }}
          variant="unstyled"
          aria-label="More"
        >
          <MoreIcon boxSize="20px" color="neutral.300" />
        </Button>
      </PopoverTrigger>
      <Portal>
        <Box position="relative" zIndex="1402">
          <PopoverContent
            width="250px"
            ref={ref}
            variants={{
              enter: { opacity: 1, top: 0 },
              exit: { opacity: 0, top: -20 },
            }}
          >
            <PopoverBody flexDirection="column">
              {!!onStartReading && (
                <ReleaseCardMenuItem
                  text={t("components.cards.releaseCardMenu.startReading")}
                  icon={
                    <CaretRightIcon
                      boxSize="20px"
                      transition="inherit"
                      _groupHover={{ color: "blaze.blaze" }}
                    />
                  }
                  onClick={onStartReading}
                  isLastItem={
                    !onPreviewRelease && !onReleaseDetails && !shareUrl
                  }
                />
              )}
              {!!onContinueReading && (
                <ReleaseCardMenuItem
                  text={t("components.cards.releaseCardMenu.continueReading")}
                  icon={
                    <CaretRightIcon
                      boxSize="20px"
                      transition="inherit"
                      _groupHover={{ color: "blaze.blaze" }}
                    />
                  }
                  onClick={onContinueReading}
                  isLastItem={
                    !onPreviewRelease && !onReleaseDetails && !shareUrl
                  }
                />
              )}
              {!!onPreviewRelease && (
                <ReleaseCardMenuItem
                  text={t("components.cards.releaseCardMenu.previewRelease")}
                  icon={
                    <ArrowRightIcon
                      boxSize="20px"
                      transition="inherit"
                      _groupHover={{ color: "blaze.blaze" }}
                    />
                  }
                  onClick={onPreviewRelease}
                  isLastItem={!onReleaseDetails && !shareUrl}
                />
              )}
              {!!onReleaseDetails && (
                <ReleaseCardMenuItem
                  text={t("components.cards.releaseCardMenu.releaseDetails")}
                  icon={
                    <InfoIcon
                      boxSize="20px"
                      transition="inherit"
                      _groupHover={{ color: "blaze.blaze" }}
                    />
                  }
                  onClick={onReleaseDetails}
                  isLastItem={!shareUrl}
                />
              )}
              {!!shareUrl && <ShareReleaseCardMenuItem shareUrl={shareUrl} />}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

function ShareReleaseCardMenuItem({ shareUrl }: { shareUrl: string }) {
  const { onToggle, ref, isOpen } = usePopoverOpenState(true);
  const { t } = useTranslation();

  return (
    <Popover isOpen={isOpen} placement="top-start">
      <PopoverTrigger>
        <ReleaseCardMenuItem
          text={t("components.cards.releaseCardMenu.share")}
          icon={
            <ShareIcon
              boxSize="20px"
              transition="inherit"
              _groupHover={{ color: "blaze.blaze" }}
            />
          }
          onClick={onToggle}
          isLastItem={true}
        />
      </PopoverTrigger>
      <Portal>
        <SharePopover
          shareUrl={shareUrl}
          title={t("components.slideInMenu.releaseInfoMenu.share")}
          contentRef={ref}
        />
      </Portal>
    </Popover>
  );
}

interface ReleaseCardMenuItemProps {
  text: string;
  onClick: () => void;
  icon: ReactElement;
  isLastItem?: boolean;
}

const ReleaseCardMenuItem = forwardRef<
  HTMLAnchorElement,
  ReleaseCardMenuItemProps
>(({ text, icon, onClick, isLastItem = false }, ref) => {
  return (
    <Link
      role="group"
      onClick={(e) => {
        onClick();
        e.preventDefault();
        e.stopPropagation();
      }}
      transition="all .2s linear"
      ref={ref}
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding="12px 8px 12px 16px"
        borderBottom={!isLastItem ? "1px solid" : undefined}
        borderColor="transparent.white.10"
        transition="inherit"
      >
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="130%"
          letterSpacing="0.5px"
          color="dune.700"
          transition="inherit"
          _groupHover={{ color: "dune.100" }}
        >
          {text}
        </Text>
        {icon}
      </Flex>
    </Link>
  );
});

ReleaseCardMenuItem.displayName = "ReleaseCardMenuItem"; // Add display name for better debugging
