import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useReleaseData } from "./hookstate/useReleaseData";
import {
  GET_RELEASE_COMMMENTS,
  getReleaseComments,
} from "../api/readerScreenApi";
import { readerDataStore } from "../ReaderStateWrapper";

export function useHydrateComments() {
  const { releaseData } = useReleaseData();

  const results = useQuery({
    enabled: !!releaseData,
    queryKey: [...GET_RELEASE_COMMMENTS, releaseData?.id],
    queryFn: () => getReleaseComments(releaseData?.id ?? 0),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const releaseCommentsData = results.data?.data.payload.results;
    if (releaseCommentsData) {
      readerDataStore.releaseCommentsData.set(releaseCommentsData);
    }
  }, [results]);
}
