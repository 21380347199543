import { postTrackPageView } from "../api/readerScreenApi";
import { usePageLayout } from "./usePageLayout";
import { useCallback, useEffect, useState } from "react";
import { PAGE_LAYOUT_TYPES } from "../constants";
import { TrackViewPayload } from "../types";
import { useP2PSettings } from "./useP2PSettings";
import { useReleasePagesData } from "./hookstate/useReleasePagesData";
import { useAccessData } from "./hookstate/useAccessData";
import { useReleaseFragmentsData } from "./hookstate/useReleaseFragmentsData";
import { useParams } from "react-router";
import { useReadingDirection } from "./useReadingDirection";

export function useTrackPageView() {
  const { activePageFragments, activeFragmentIndex } =
    useReleaseFragmentsData();
  const { pageOrder, releaseOrder } = useParams();
  const { releasePagesData } = useReleasePagesData();
  const { pageLayout } = usePageLayout();
  const { comicIsVertical } = useReadingDirection();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { setAccessData } = useAccessData();

  const activePage = parseInt(pageOrder ?? "1") - 1;
  const activeRelease = parseInt(releaseOrder ?? "1") - 1;
  const trackPageView = useCallback(async () => {
    if (releasePagesData) {
      const page = releasePagesData[activePage];
      const payload: TrackViewPayload = {
        viewer_mode: comicIsVertical ? "vertical" : (pageLayout ?? ""),
        page_id: page.id,
      };
      if (p2pEnabledAndAugmented) {
        const activeFragment = activePageFragments?.[activeFragmentIndex];
        if (activeFragment) {
          payload.viewer_mode = PAGE_LAYOUT_TYPES.interactive;
          payload.fragment_id = activeFragment.id;
        } else {
          payload.viewer_mode = PAGE_LAYOUT_TYPES.single;
        }
      } else if (
        pageLayout === PAGE_LAYOUT_TYPES.double &&
        page.is_double_with_next
      ) {
        payload.double_page_id = releasePagesData?.[activePage + 1].id;
      }

      const result = await postTrackPageView(page.release_id, payload);
      setAccessData(result.data.payload.results);
    }
  }, [
    activeRelease,
    activePage,
    p2pEnabledAndAugmented,
    activePageFragments,
    activeFragmentIndex,
    releasePagesData,
  ]);

  const [trackReleaseIndex, setTrackReleaseIndex] = useState(-1);
  const [trackPageIndex, setTrackPageIndex] = useState(-1);
  const [trackFragmentIndex, setTrackFragmentIndex] = useState(-1);
  useEffect(() => {
    if (trackPageIndex !== activePage && releasePagesData) {
      setTrackPageIndex(activePage);
    }
    if (trackReleaseIndex !== activeRelease && releasePagesData) {
      setTrackReleaseIndex(activeRelease);
    }
    if (
      p2pEnabledAndAugmented &&
      trackFragmentIndex !== activeFragmentIndex &&
      activePageFragments
    ) {
      setTrackFragmentIndex(activeFragmentIndex);
    }
  }, [
    activePage,
    activeRelease,
    releasePagesData,
    activeFragmentIndex,
    activePageFragments,
    trackPageIndex,
    trackReleaseIndex,
    trackFragmentIndex,
    p2pEnabledAndAugmented,
  ]);

  useEffect(() => {
    if (
      trackPageIndex > -1 ||
      trackReleaseIndex > -1 ||
      trackFragmentIndex > -1
    ) {
      trackPageView();
    }
  }, [pageLayout, trackPageIndex, trackReleaseIndex, trackFragmentIndex]);
}
