import { Button } from "@chakra-ui/react";
import { LayoutStyle } from "../../types";
import { GridIcon } from "../icons/GridIcon";
import { ListIcon } from "../icons/ListIcon";

interface modeToggleProps {
  mode: LayoutStyle["mode"];
  setMode: (mode: LayoutStyle["mode"]) => void;
}
export function ModeToggle({ mode, setMode }: modeToggleProps) {
  return (
    <Button
      variant="sortMenuAlt"
      aria-label="Toggle Grid Layout"
      borderRadius="14px"
      p="8px 10px"
      onClick={() => setMode(mode === "grid" ? "list" : "grid")}
    >
      {mode === "grid" ? (
        <GridIcon width="16px" height="16px" color="dune.dune" />
      ) : (
        <ListIcon width="16px" height="16px" color="dune.dune" />
      )}
    </Button>
  );
}
