import { Icon, IconProps } from "@chakra-ui/react";

export function CaretUpIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6464 8.64645C11.8417 8.45118 12.1583 8.45118 12.3536 8.64645L19.3536 15.6464C19.5488 15.8417 19.5488 16.1583 19.3536 16.3536C19.1583 16.5488 18.8417 16.5488 18.6464 16.3536L12 9.70711L5.35355 16.3536C5.15829 16.5488 4.84171 16.5488 4.64645 16.3536C4.45118 16.1583 4.45118 15.8417 4.64645 15.6464L11.6464 8.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
