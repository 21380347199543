import { FunnelNavigationContext } from "../../../components/funnel/types";
import { apiClient } from "../../../services/axiosInstance";

export enum EventTypes {
  viewed_subscription_offer = "viewed_subscription_offer",
  viewed_registration = "viewed_registration",
  viewed_gift_offer = "viewed_gift_offer",
}

export function publishViewedSubscriptionOfferEvent(
  navigationContext: FunnelNavigationContext,
) {
  publishEvent({
    event: EventTypes.viewed_subscription_offer,
    comic_id: navigationContext.data?.comicId,
    comic_name: navigationContext.data?.comicName,
    release_id: navigationContext.data?.releaseId,
    context_area: navigationContext.contextArea,
    context_referrer: navigationContext.contextReferrer,
  });
}

export function publishViewedGiftOfferEvent(
  navigationContext: FunnelNavigationContext,
) {
  publishEvent({
    event: EventTypes.viewed_gift_offer,
    comic_id: navigationContext.data?.comicId,
    comic_name: navigationContext.data?.comicName,
    release_id: navigationContext.data?.releaseId,
    context_area: navigationContext.contextArea,
  });
}

export function publishViewedRegistrationEvent(
  navigationContext: FunnelNavigationContext,
) {
  publishEvent({
    event: EventTypes.viewed_registration,
    comic_id: navigationContext.data?.comicId,
    comic_name: navigationContext.data?.comicName,
    release_id: navigationContext.data?.releaseId,
    context_area: navigationContext.contextArea,
    release_order: navigationContext.data?.releaseOrder,
    artist_id: navigationContext.data?.artistId,
  });
}

export function publishEvent(event: any) {
  return apiClient.post(`v1/account/publish-event`, {
    meta: {},
    payload: event,
  });
}
