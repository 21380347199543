import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { components } from "./components/i18n";
import { screens } from "./screens/i18n";

export const translation = {
  components,
  screens,
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      en: {
        translation,
      },
    },
  });

export default i18next;
