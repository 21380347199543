import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { LegalHeader } from "./LegalHeader";
import { SectionTitle } from "../../../components/text/SectionTitle";
import { LegalLink } from "./LegalLink";
import { ContentDivider } from "./ContentDivider";

export function TermsOfUse() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const baseUrl = window.location.origin;

  return (
    <>
      <LegalHeader title="Terms Of Use" />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Your Acceptance
      </SectionTitle>
      <Text variant="legalContent">
        Welcome to our website ("GlobalComix Website" or "Website").
        GlobalComix, Inc. ("GlobalComix") provides the services available on the
        Website to you subject to the following terms and conditions ("Terms of
        Service"). BY USING AND/OR VISITING THIS WEBSITE (collectively,
        including all Content available through the globalcomix.com domain name
        (including its sub-domains), the "GlobalComix Website", or "Website"),
        YOU ARE ACKNOWLEDGING THAT YOU HAVE READ, UNDERSTAND, AND AGREE, WITHOUT
        LIMITATION OR QUALIFICATION, TO BE BOUND BY THESE TERMS AND CONDITIONS
        AND THE TERMS AND CONDITIONS OF GlobalComix' PRIVACY NOTICE, WHICH ARE
        PUBLISHED AT{" "}
        <LegalLink href="/privacy-policy">{baseUrl}/privacy-policy</LegalLink>,
        AND WHICH ARE INCORPORATED HEREIN BY REFERENCE. If you agree to these
        Terms of Services, please click on the appropriate "acceptance" link. If
        you do not agree to any of these terms, then please do not use the
        GlobalComix Website.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        GlobalComix Website
      </SectionTitle>
      <Text variant="legalContent">
        These Terms of Service apply to all users of the GlobalComix Website,
        including users who are also contributors of electronic and media
        contents, information, and other materials or services on or through the
        Website, electronically or printed. The GlobalComix Website may contain
        links to third party websites that are not owned or controlled by
        GlobalComix. GlobalComix has no control over, and assumes no
        responsibility for, the content, privacy policies, or practices of any
        third party websites. In addition, GlobalComix will not and cannot
        censor or edit the content of any third-party site. By using the
        Website, you expressly relieve GlobalComix from any and all liability
        arising from your use of any third-party website. Accordingly, we
        encourage you to be aware when you leave the GlobalComix Website and to
        read the terms and conditions and privacy policy of each other website
        that you visit.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Website Access
      </SectionTitle>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            GlobalComix hereby grants you permission to use the Website as set
            forth in this Terms of Service, provided that:
          </Text>
        </ListItem>
        <UnorderedList marginLeft="24px">
          <ListItem>
            <Text variant="legalContent">
              Your use of the Website as permitted is solely for your personal,
              noncommercial use;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              You will not copy or distribute any part of the Website in any
              medium without GlobalComix prior written authorization;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              You will not alter or modgloy any part of the Website other than
              as may be reasonably necessary to use the Website for its intended
              purpose; and
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              You will otherwise comply with the terms and conditions of these
              Terms of Service.
            </Text>
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Text variant="legalContent">
            In order to access some features of the Website, you will have to
            create an account. You may never use another's account without
            permission. When creating your account, you must provide accurate
            and complete information. You are solely responsible for the
            activity that occurs on your account, and you must keep your account
            password secure. You must notify GlobalComix immediately of any
            breach of security or unauthorized use of your account. Although
            GlobalComix will not be liable for your losses caused by any
            unauthorized use of your account, you may be liable for the losses
            of GlobalComix or others due to such unauthorized use.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            You agree not to use or launch any automated system, including
            without limitation, "robots," "spiders," "offline readers," etc.,
            that accesses the Website in a manner that sends more request
            messages to the GlobalComix servers in a given period of time than a
            human can reasonably produce in the same period by using a
            conventional on-line web browser. Notwithstanding the foregoing,
            GlobalComix grants the operators of public search engines permission
            to use spiders to copy materials from the site for the sole purpose
            of creating publicly available searchable indices of the materials,
            but not caches or archives of such materials. GlobalComix reserves
            the right to revoke these exceptions either generally or in specific
            cases. You agree not to collect or harvest any personally
            identifiable information, including account names, from the Website,
            nor to use the communication systems provided by the Website for any
            commercial solicitation purposes. You agree not to solicit, for
            commercial purposes, any users of the Website with respect to their
            User Submissions.
          </Text>
        </ListItem>
      </UnorderedList>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Intellectual Property Rights
      </SectionTitle>
      <Text variant="legalContent">
        The content on the GlobalComix Website, except all User Submissions (as
        defined below), including without limitation, the text, software,
        scripts, documents, graphics, photos, images, sounds, music, videos,
        interactive features and the like, displayed or distributed in softcopy
        or hardcopy, ("Content") and the trademarks, service marks and logos
        contained therein ("Marks"), are owned by or licensed to GlobalComix,
        subject to copyright and other intellectual property rights under United
        States and foreign laws and international conventions. Content on the
        Website is provided to you AS IS for your information and personal use
        only and may not be used, copied, reproduced, distributed, transmitted,
        broadcast, displayed, sold, licensed, or otherwise exploited for any
        other purposes whatsoever without the prior written consent of the
        respective owners. GlobalComix reserves all rights not expressly granted
        in and to the Website and the Content. You agree to not engage in the
        use, copying, or distribution of any of the Content other than expressly
        permitted herein, including any use, copying, or distribution of User
        Submissions of third parties obtained through the Website for any
        commercial purposes. We grant you a limited, revocable, and nonexclusive
        license to access and make personal use of the Website. Please note that
        you may not frame or utilize framing techniques to enclose the Website
        or any portion thereof without our prior written consent. If you
        download or print a copy of the Content for personal use, you must
        retain all copyright and other proprietary notices contained therein.
        You agree not to circumvent, disable or otherwise interfere with
        security related features of the GlobalComix Website or features that
        prevent or restrict use or copying of any Content or enforce limitations
        on use of the GlobalComix Website or the Content therein.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        User Submissions
      </SectionTitle>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            The GlobalComix Website may now or in the future permit the
            submission of any type of electronic contents, based upon
            GlobalComix Website's discretion, or other communications submitted
            by you and other users ("User Submissions") and the hosting,
            sharing, and/or publishing of such User Submissions. You understand
            that whether or not such User Submissions are published, GlobalComix
            does not guarantee any confidentiality with respect to any
            submissions.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            You shall be solely responsible for your own User Submissions and
            the consequences of posting or publishing them. In connection with
            User Submissions, you affirm, represent, and/or warrant that:
          </Text>
        </ListItem>
        <UnorderedList marginLeft="24px">
          <ListItem>
            <Text variant="legalContent">
              You own or have the necessary licenses, rights, consents, and
              permissions to use and authorize GlobalComix to use all patent,
              trademark, trade secret, copyright or other proprietary rights in
              and to any and all User Submissions to enable inclusion and use of
              the User Submissions in the manner contemplated by the Website and
              these Terms of Service; and
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in the User Submission to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of the User
              Submissions in the manner contemplated by the Website and these
              Terms of Service. You may not use a false e-mail address,
              impersonate any person or entity, or otherwise mislead us as to
              the origin of any User Submission submitted by you. You agree to
              indemnify GlobalComix for all claims arising from your claims to
              any rights in any User Submission.
            </Text>
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Text variant="legalContent">
            With respect to posted content, the submitting user retains
            ownership of such content.
          </Text>
        </ListItem>
      </UnorderedList>
      <Text variant="legalContent">
        By submitting, posting or displaying content on, to, or through
        GlobalComix Website (or its sub-domains, successors and affiliates), you
        grant GlobalComix, Inc. a worldwide, non-exclusive, royalty-free license
        to use, reproduce, distribute, display and perform such content on
        GlobalComix Website (or its sub-domains, successors and affiliates).
        GlobalComix, Inc. reserves the right to syndicate User Submissions
        submitted, posted or displayed by you on or through GlobalComix Website
        (or its sub-domains, successors and affiliates) and uses that content in
        connection with any service offered by GlobalComix, Inc. The
        aforementioned license granted by you terminates once you remove or
        delete a User Submission from GlobalComix Website. You also acknowledge
        that GlobalComix, Inc. may retain, but not display, distribute, or
        perform, server copies of User Submissions that have been removed or
        deleted. In addition you acknowledge and grant users of the GlobalComix
        Websites a non-exclusive license to access your User Submissions through
        the GlobalComix Website (or its sub-domains, successors and affiliates)
        and to use such User Submissions as applicable by their respective
        licenses.
      </Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            In connection with User Submissions, you further agree that you
            will:
          </Text>
        </ListItem>
        <UnorderedList marginLeft="24px">
          <ListItem>
            <Text variant="legalContent">
              Further agree to claim all responsibilities for your submitted
              material that is copyrighted, protected by trade secret or
              otherwise subject to third party proprietary rights, including
              privacy and publicity rights, unless you are the owner of such
              rights or have permission from their rightful owner to post the
              material and to grant GlobalComix all of the license rights
              granted herein;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Not publish falsehoods or misrepresentations that could damage
              GlobalComix and GlobalComix Website or any third party;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Not submit material that is unlawful, obscene, defamatory,
              libelous, threatening, pornographic, harassing, hateful, racially
              or ethnically offensive, or encourages conduct that would be
              considered a criminal offense, give rise to civil liability,
              violate any law, or is otherwise inappropriate ("Offensive
              Content");
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Not post advertisements or solicitations of business:
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Not to impersonate another person. GlobalComix does not endorse
              any User Submission or any opinion, recommendation, or advice
              expressed therein, and GlobalComix expressly disclaims any and all
              liability in connection with User Submissions. GlobalComix does
              not permit copyright infringing activities and infringement of
              intellectual property rights on its Website, and GlobalComix will
              remove all Content and User Submissions if properly notified that
              such Content or User Submission infringes on another's
              intellectual property rights. GlobalComix reserves the right to
              remove Content and User Submissions without prior notice.
              GlobalComix will also terminate a User's access to its Website, if
              they are determined to be a repeat infringer. A repeat infringer
              is a User who has been notified of infringing activity more than
              twice and/or has had a User Submission removed from the Website
              more than twice. GlobalComix also reserves the right to decide
              whether Content or a User Submission is appropriate and complies
              with these Terms of Service for violations other than copyright
              infringement and violations of intellectual property law, such as,
              but not limited to, pornography, obscene or defamatory material,
              or excessive length. GlobalComix may remove such User Submissions
              and/or terminate a User's access for uploading such material in
              violation of these Terms of Service at any time, without prior
              notice and at its sole discretion.
            </Text>
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Text variant="legalContent">
            In particular, if you are a copyright owner or an agent thereof and
            believe that any User Submission or other content infringes upon
            your copyrights, you may submit a notification pursuant to the
            Digital Millennium Copyright Act ("DMCA") by providing our Copyright
            Agent with the following information in writing (see 17 U.S.C
            512(c)(3) for further detail):
          </Text>
        </ListItem>
        <UnorderedList marginLeft="24px">
          <ListItem>
            <Text variant="legalContent">
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled and information reasonably
              sufficient to permit the service provider to locate the material;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Information reasonably sufficient to permit the service provider
              to contact you, such as an address, telephone number, and, if
              available, an electronic mail;
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law; and
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that you are authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </Text>
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <Text variant="legalContent">
        GlobalComix's designated Copyright Agent to receive notifications of
        claimed infringement is:{" "}
        <LegalLink href="mailto:admin@globalcomix.com">
          admin@globalcomix.com
        </LegalLink>
        . You may also contact this address for any other feedback, comments,
        requests for technical support, and other communications. You
        acknowledge that if you fail to comply with all of the requirements of
        this Section 5(D), your DMCA notice may not be valid.
      </Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            You understand that when using the GlobalComix Website, you will be
            exposed to User Submissions from a variety of sources, and that
            GlobalComix is not responsible for the accuracy, usefulness, safety,
            or intellectual property rights of or relating to such User
            Submissions. You further understand and acknowledge that you may be
            exposed to User Submissions that are inaccurate, offensive,
            indecent, or objectionable, and you agree to waive, and hereby do
            waive, any legal or equitable rights or remedies you have or may
            have against GlobalComix with respect thereto, and agree to
            indemnify and hold GlobalComix, its Owners/Operators, affiliates,
            and/or licensors, harmless to the fullest extent allowed by law
            regarding all matters related to your use of the site.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix permits you to link to materials on the Website for
            personal, non-commercial purposes only. In addition, GlobalComix
            reserves the right to discontinue any aspect of the GlobalComix
            Website at any time.
          </Text>
        </ListItem>
      </UnorderedList>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Warranty Disclaimer
      </SectionTitle>
      <Text variant="legalContent">
        YOU AGREE THAT YOUR USE OF THE GlobalComix WEBSITE SHALL BE AT YOUR SOLE
        RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, GlobalComix, ITS OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF.
        GlobalComix MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THIS SITE'S CONTENT OR THE CONTENT OF ANY SITES LINKED
        TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
        ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR
        PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
        AND USE OF OUR WEBSITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
        SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM OUR WEBSITE, (IV) ANY BUGS, VIRUSES, TROJAN
        HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE
        BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONSIN ANY CONTENT OR
        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
        ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
        VIA THE GlobalComix WEBSITE. GlobalComix DOES NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE GlobalComix WEBSITE
        OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER
        ADVERTISING, AND GlobalComix WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
        PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
        SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
        BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Limitation of Liability
      </SectionTitle>
      <Text variant="legalContent">
        IN NO EVENT SHALL GlobalComix, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR
        AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING LOST PROFITS)
        RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT,
        (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
        RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (III) ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV)
        ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE,
        (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE
        TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, (V) ANY
        INTERRUPTION OF BUSINESS; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF
        ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
        VIA THE GlobalComix WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT,
        OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGES; AND/OR (VII) EVENTS BEYOND GlobalComix'
        REASONABLE CONTROL. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO
        THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU
        SPECIFICALLY ACKNOWLEDGE THAT GlobalComix SHALL NOT BE LIABLE FOR USER
        SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
        THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
        ENTIRELY WITH YOU. FURTHER, IN NO EVENT SHALL GlobalComix' MAXIMUM
        AGGREGATE LIABILITY EXCEED ONE HUNDRED DOLLARS ($100.00). The Website is
        controlled and offered by GlobalComix from its facilities in the United
        States. GlobalComix makes no representations that the GlobalComix
        Website is appropriate or available for use in other locations. Those
        who access or use the GlobalComix Website from other jurisdictions do so
        at their own volition and are responsible for compliance with local law.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Indemnity
      </SectionTitle>
      <Text variant="legalContent">
        You agree to defend, indemnify and hold harmless GlobalComix, its parent
        corporation, officers, directors, employees and agents, from and against
        any and all claims, damages, obligations, losses, liabilities, costs or
        debt, and expenses (including but not limited to attorney's fees)
        arising from:
      </Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            Your use of and access to the GlobalComix Website;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Your violation of any term of these Terms of Service;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Your violation of any third party right, including without
            limitation any copyright, property, or privacy right; or
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Any claim that one of your User Submissions caused damage to a third
            party. This defense and indemnification obligation will survive
            these Terms of Service and your use of the GlobalComix Website.
          </Text>
        </ListItem>
      </UnorderedList>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Ability to Accept Terms of Service
      </SectionTitle>
      <Text variant="legalContent">
        You affirm that you are either more than 18 years of age, or an
        emancipated minor, or possess legal parental or guardian consent, and
        are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth in
        these Terms of Service, and to abide by and comply with these Terms of
        Service. In any case, you affirm that you are over the age of 13, as the
        GlobalComix Website is not intended for children under 13. If you are
        under 13 years of age, then please do not use the GlobalComix Website.
      </Text>
      <Text variant="legalContent">
        However GlobalComix Website does contain some materials that are not
        suitable for Users who are less than 18 years old, or the legal age of
        their jurisdictions ("Minors"). In that case, GlobalComix will not hold
        the responsibilities of consequences and effects that mat may took place
        in the events when Minors are accessing materials that are not intended
        for them, when they are accessing materials that are marked in any
        manner, expressed or implied, such as, but not limited to forum names,
        forum or thread links or forum descriptions.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Assignment
      </SectionTitle>
      <Text variant="legalContent">
        These Terms of Service, and any rights and licenses granted hereunder,
        may not be transferred or assigned by you, but may be assigned by
        GlobalComix without restriction.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        General
      </SectionTitle>
      <Text variant="legalContent">You agree that:</Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            The GlobalComix Website shall be deemed solely based in New York;
            and
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            The GlobalComix Website shall be deemed a passive website that does
            not give rise to personal jurisdiction over GlobalComix, either
            specific or general, in jurisdictions other than New York.
          </Text>
        </ListItem>
      </UnorderedList>
      <Text variant="legalContent">
        These Terms of Service shall be governed by the internal substantive
        laws of New York, without respect to its conflict of laws principles, as
        if these Terms of Service were a contract wholly entered into and wholly
        performed without New York. Any claim or dispute between you and
        GlobalComix that arises in whole or in part from the GlobalComix Website
        shall be submitted to confidential arbitration under the rules then
        prevailing of the American Arbitration Association in a proceeding
        located in New York City . The arbitrator's award shall be binding and
        may be entered as a judgment in any court of competent jurisdiction. To
        the fullest extent permitted by applicable law, no arbitration under
        these Terms of Service shall be joined to an arbitration involving any
        other party subject to these Terms of Service, whether through class
        arbitration proceeding or otherwise. Notwithstanding the arbitration
        provision above, to the extent you have in any manner violated or
        threatened to violate our intellectual property rights, GlobalComix may
        seek injunctive or other appropriate relief in New York , and you
        consent to exclusive jurisdiction and venue in such courts. These Terms
        of Service, together with the Privacy Notice at the{" "}
        <LegalLink href="/privacy-policy">Privacy Policy</LegalLink> and any
        other legal notices published by GlobalComix on the Website shall
        constitute the entire agreement between you and GlobalComix concerning
        the GlobalComix Website. If any provision of these Terms of Service is
        deemed invalid by a court of competent jurisdiction, the invalidity of
        such provision shall not affect the validity of the remaining provisions
        of these Terms of Service, which shall remain in full force and effect.
        No waiver of any term of these Terms of Service shall be deemed a
        further or continuing waiver of such term or any other term, and
        GlobalComix's failure to assert any right or provision under these Terms
        of Service shall not constitute a waiver of such right or provision.
        GlobalComix reserves the right to amend these Terms of Service at any
        time and without notice, and it is your responsibility to review these
        Terms of Service for any changes. Your use of the GlobalComix Website
        following any amendment of these Terms of Service will signify your
        assent to and acceptance of its revised terms. YOU AND GLOBALCOMIX AGREE
        THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE GLOBALCOMIX
        WEBSITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
        ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        GlobalComix Gold (Reader Subscription) Terms
      </SectionTitle>
      <Text variant="legalContent">
        GlobalComix provides a subscription service that allows our members to
        view designated content ("GlobalComix content") over the Internet.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Membership
      </SectionTitle>
      <Text variant="legalContent">
        Your GlobalComix membership will continue and automatically renew until
        terminated. To use the GlobalComix service you must have Internet access
        and provide us with one or more Payment Methods. "Payment Method" means
        a current, valid, accepted method of payment, as may be updated from
        time to time and which may include payment through your account with a
        third party. You must cancel your membership before it renews in order
        to avoid billing of the membership fees for the next billing cycle to
        your Payment Method (see "Cancellation" below).
      </Text>
      <Text variant="legalContent">
        We may offer a number of membership plans, including special promotional
        plans or memberships offered by third parties in conjunction with the
        provision of their own products and services. We are not responsible for
        the products and services provided by such third parties. Some
        membership plans may have differing conditions and limitations, which
        will be disclosed at your sign-up or in other communications made
        available to you. You can find specific details regarding your
        GlobalComix membership by visiting our website and clicking on the
        "Account" link.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Free Trials
      </SectionTitle>
      <Text variant="legalContent">
        Your GlobalComix membership may start with a free trial. The duration of
        the free trial period of your membership lasts will be specified during
        sign-up and is intended to allow new and certain former members to try
        the service.
      </Text>
      <Text variant="legalContent">
        Free trial eligibility is determined by GlobalComix at its sole
        discretion and we may limit eligibility or duration to prevent free
        trial abuse. We reserve the right to revoke the free trial and put your
        account on hold in the event that we determine that you are not
        eligible. Members of households with an existing or recent GlobalComix
        membership are not eligible. We may use information such as device ID,
        method of payment or an account email address used with an existing or
        recent GlobalComix membership to determine eligibility. For combinations
        with other offers, restrictions may apply.
      </Text>
      <Text variant="legalContent">
        We will charge the membership fee for your next billing cycle to your
        Payment Method at the end of the free trial period and your membership
        will automatically renew unless you cancel your membership prior to the
        end of the free trial period. To view the applicable membership price
        and end date of your free trial period, visit our website and click the
        "Subscriptions" link on the "Account" page.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Billing and Cancellation
      </SectionTitle>
      <Text variant="legalContent">
        Billing Cycle. The membership fee for the GlobalComix service any other
        charges you may incur in connection with your use of the service, such
        as taxes and possible transaction fees, will be charged to your Payment
        Method on the specific billing date indicated on your "Account" page.
        The length of your billing cycle will depend on the type of subscription
        that you choose when you sign-up for the service. Membership fees are
        fully earned upon payment. In some cases your payment date may change,
        for example if your Payment Method has not successfully settled or if
        your paid membership began on a day not contained in a given month.
        Visit our website and click on the "Billing details" link on the
        "Account" page to see your next payment date. We may authorize your
        Payment Method in anticipation of membership or service-related charges
        through various methods, including authorizing it up to approximately
        one month of service as soon as you register. In some instances, your
        available balance or credit limit may be reduced to reflect the
        authorization during your free trial period.
      </Text>
      <Text variant="legalContent">
        Payment Methods. To use the GlobalComix service you must provide one or
        more Payment Methods. You authorize us to charge any Payment Method
        associated with your account in case your primary Payment Method is
        declined or no longer available to us for payment of your subscription
        fee. You remain responsible for any uncollected amounts. If a payment is
        not successfully settled, due to expiration, insufficient funds, or
        otherwise, and you do not cancel your account, we may suspend your
        access to the service until we have successfully charged a valid Payment
        Method. For some Payment Methods, the issuer may charge you certain
        fees, such as foreign transaction fees or other fees relating to the
        processing of your Payment Method. Check with your Payment Method
        service provider for details.
      </Text>
      <Text variant="legalContent">
        Updating your Payment Methods. You can update your Payment Methods by
        going to the "Account" page. We may also update your Payment Methods
        using information provided by the payment service providers. Following
        any update, you authorize us to continue to charge the applicable
        Payment Method(s).
      </Text>
      <Text variant="legalContent">
        Cancellation. You can cancel your GlobalComix membership at any time,
        and you will continue to have access to the GlobalComix service through
        the end of your billing period. To cancel, go to the "Account" page on
        our website and follow the instructions for cancellation. If you cancel
        your membership, your account will automatically close at the end of
        your current billing period. To see when your account will close, click
        "Billing details" on the "Account" page. If you signed up for
        GlobalComix using your account with a third party as a Payment Method
        and wish to cancel your GlobalComix membership, you may need to do so
        through that third party, for example by visiting your account with the
        applicable third party and turning off auto-renew, or unsubscribing from
        the GlobalComix service through that third party. You may also find
        billing information about your GlobalComix membership by visiting your
        account with the applicable third party.
      </Text>
      <Text variant="legalContent">
        Changes to the Price and Subscription Plans. We reserve the right to
        change our subscription plans or adjust pricing for our service or any
        components thereof in any manner and at any time as we may determine in
        our sole and absolute discretion. Except as otherwise expressly provided
        for in these Terms of Use, any price changes or changes to your
        subscription plan will take effect following notice to you.
      </Text>
      <Text variant="legalContent">
        No Refunds. Payments are nonrefundable and there are no refunds or
        credits for partially used periods. Following any cancellation, however,
        you will continue to have access to the service through the end of your
        current billing period. At any time, and for any reason, we may provide
        a refund, discount, or other consideration to some or all of our members
        ("credits"). The amount and form of such credits, and the decision to
        provide them, are at our sole and absolute discretion. The provision of
        credits in one instance does not entitle you to credits in the future
        for similar instances, nor does it obligate us to provide credits in the
        future, under any circumstance.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Billing and Cancellation
      </SectionTitle>
      <Text variant="legalContent">
        You must be 18 years of age, or the age of majority in your province,
        territory or country, to become a member of the GlobalComix service.
        Individuals under the age of 18, or applicable age of majority, may
        utilize the service only with the involvement of a parent or legal
        guardian, under such person's account and otherwise subject to these
        Terms of Use.
      </Text>
      <Text variant="legalContent">
        The GlobalComix service and any content viewed through our service are
        for your personal and non-commercial use only. During your GlobalComix
        membership, we grant you a limited, non-exclusive, non-transferable
        right to access the GlobalComix service and view GlobalComix content
        through the service. Except for the foregoing, no right, title or
        interest shall be transferred to you. You agree not to use the service
        for public performances.
      </Text>
      <Text variant="legalContent">
        You may view GlobalComix content primarily within the country in which
        you have established your account and only in geographic locations where
        we offer our service and have licensed such content. The content that
        may be available to watch will vary by geographic location and will
        change from time to time.
      </Text>
      <Text variant="legalContent">
        The GlobalComix service, including the content library, is regularly
        updated. In addition, we continually test various aspects of our
        service, including but not limited to our website, user interfaces,
        promotional features and availability of GlobalComix content..
      </Text>
      <Text variant="legalContent">
        You agree to use the GlobalComix service, including all features and
        functionalities associated therewith, in accordance with all applicable
        laws, rules and regulations, or other restrictions on use of the service
        or content therein. Except as explicitly authorized in these Terms of
        Use, you agree not to archive, download, reproduce, distribute, modify,
        display, perform, publish, license, create derivative works from, offer
        for sale, or use content and information contained on or obtained from
        or through the GlobalComix service. You also agree not to circumvent,
        remove, alter, deactivate, degrade or thwart any of the content
        protections in the GlobalComix service; use any robot, spider, scraper
        or other automated means to access the GlobalComix service; decompile,
        reverse engineer or disassemble any software or other products or
        processes accessible through the GlobalComix service; insert any code or
        product or manipulate the content of the GlobalComix service in any way;
        or use any data mining, data gathering or extraction method. In
        addition, you agree not to upload, post, e-mail or otherwise send or
        transmit any material designed to interrupt, destroy or limit the
        functionality of any computer software or hardware or telecommunications
        equipment associated with the GlobalComix service, including any
        software viruses or any other computer code, files or programs. We may
        terminate or restrict your use of our service if you violate these Terms
        of Use or are engaged in illegal or fraudulent use of the service.
      </Text>
      <Text variant="legalContent">
        The quality of the display of the GlobalComix content may vary from
        device to device, and may be affected by a variety of factors, such as
        your location, the bandwidth available through and/or speed of your
        Internet connection. You are responsible for all Internet access
        charges. Please check with your Internet provider for information on
        possible Internet data usage charges. GlobalComix makes no
        representations or warranties about the quality of your experience on
        your display.
      </Text>
      <Text variant="legalContent">
        GlobalComix software may solely be used for authorized viewing of
        content from GlobalComix. This software may vary by device and medium,
        and functionalities may also differ between devices. By using our
        service, you agree to receive, without further notice or prompting,
        updated versions of the GlobalComix and related third-party software. If
        you do not accept the foregoing terms, do not use our service.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Passwords and Account Access
      </SectionTitle>
      <Text variant="legalContent">
        The member who created the GlobalComix account and whose Payment Method
        is charged (the "Account Owner") has access and control over the
        GlobalComix account and the GlobalComix ready devices that are used to
        access our service and is responsible for any activity that occurs
        through the GlobalComix account. To maintain control over the account
        and prevent anyone from accessing the account (which could include
        information on viewing history for the account), the Account Owner
        should maintain control over the GlobalComix ready devices that are used
        to access the service and not reveal the password or details of the
        Payment Method associated to the account to anyone. You are responsible
        for updating and maintaining the accuracy of the information you provide
        to us relating to your account. We can terminate your account or place
        your account on hold in order to protect you, GlobalComix or our
        partners from identity theft or other fraudulent activity. GlobalComix
        is not obligated to credit or discount a membership for holds placed on
        the account by either a representative of GlobalComix or by the
        automated processes of GlobalComix.
      </Text>
      <ContentDivider />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Donations and SuperFan Program
      </SectionTitle>
      <Text variant="legalContent">
        Superfans are defined as any user who elects to financially contribute
        to another creator or publisher either one-time or on a recurring basis.
        You may become a Superfan of any Creator/Publisher by creating an
        account, visiting a creator or publisher profile, and sending a donation
        of any size (minimum $3). The terms for Billing, the GlobalComix
        Service, and Password and Account Access shall be the same as the
        GlobalComix Subscription Terms Above.
      </Text>
    </>
  );
}
