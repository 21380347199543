import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  useBreakpoint,
} from "@chakra-ui/react";
import { useMemo } from "react";

import { InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { publisherComicsResultsCard } from "../../../components/resultsGrid/ComicsResultsCard";
import { sizeEstimator } from "../../../components/cards/hooks/useGridCardDimensions";
import { Comic, LayoutStyle, ServerListResponse } from "../../../types";
import { ContentCard } from "../../../components/cards/ContentCard";

export function ArtistProfileComicsList({
  data,
  isFetchingNextPage,
  isRefetching,
  mode,
}: {
  data:
    | InfiniteData<AxiosResponse<ServerListResponse<Comic>, any>, unknown>
    | undefined;
  isFetchingNextPage: boolean;
  isRefetching: boolean;
  mode: LayoutStyle["mode"];
}) {
  var allPages = data?.pages;

  const layout = publisherComicsResultsCard(mode);
  const gridColumnCount = 3;

  const breakpoint = useBreakpoint({ ssr: false });
  const dimensions = useMemo(() => {
    return sizeEstimator(
      layout.style.context,
      layout.style.mode,
      layout.style.size,
    );
  }, [layout]);

  const columnCount = useMemo(() => {
    if (layout.style.mode === "list") return 1;

    switch (breakpoint) {
      case "xs":
      case "sm":
        return 2;
      case "md":
      case "lg":
      case "xl":
      case "2xl":
      default:
        return gridColumnCount;
    }
  }, [breakpoint, dimensions]);

  const isSmall = breakpoint === "xs" || breakpoint === "sm";

  return (
    <>
      {allPages?.map((page) => {
        const results = page?.data.payload.results ?? [];
        return (
          <Box>
            {isRefetching && (
              <Flex
                justifyContent="center"
                alignItems="center"
                grow={1}
                pt="16px"
                height="10px"
              >
                <Spinner color="blaze.blaze" />
              </Flex>
            )}
            <SimpleGrid
              opacity={isRefetching ? 0 : 1}
              columns={columnCount}
              columnGap={isSmall ? undefined : "32px"}
              rowGap={16 + "px"}
              width="100%"
            >
              {[...results].map((item, i) => (
                <Box
                  key={i}
                  margin={mode === "grid" ? "auto" : undefined}
                  pl={mode === "list" ? "16px" : undefined}
                  cursor="pointer"
                >
                  <ContentCard
                    content={item}
                    layout={layout}
                    // openReader
                    fullWidth
                  />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        );
      })}
      {isFetchingNextPage && (
        <Flex
          justifyContent="center"
          alignItems="center"
          grow={1}
          pt="16px"
          width="100%"
        >
          <Spinner color="blaze.blaze" />
        </Flex>
      )}
    </>
  );
}
