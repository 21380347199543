import { ImmutableObject, useHookstate } from "@hookstate/core";
import { forEach, isEqual } from "lodash";
import {
  browseFilterStore,
  FilterState,
} from "../components/filters/BrowseFilters";
import { ARTIST_TYPE_ALL } from "../components/filters/ArtistTypeFilter";
import { COMIC_CATEGORY_ALL } from "../components/filters/ComicCategoryFilter";
import { COMIC_CONTRIBUTOR_TYPE_ALL } from "../components/filters/ComicContributerTypeFilter";
import {
  COMIC_AUGMENTED_ENABLED,
  COMIC_FORMAT_ALL,
} from "../components/filters/ComicFormatFilter";
import { COMIC_TYPE_ALL } from "../components/filters/ComicTypeFilter";
import { CROWDFUNDING_TYPE_ALL } from "../components/filters/CrowdfundingFilter";
import { LICENSE_TYPE_ALL } from "../components/filters/LicenseTypeFilter";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appStore } from "../../../appStore";
import { LANGUAGE_ALL } from "../components/filters/LanguageFacetGroupFilter";
import {
  ComicCategoryEntity,
  ComicTypeEntity,
  LanguageEntity,
} from "../../../services/gc/types";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../components/sortMenus/SortDurationMenu";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../components/sortMenus/SortOrderMenu";
import { COMIC_USER_READING_STATUS_ALL } from "../../../components/readingStatusButton/ReadingStatusButton";

export function useBrowseFilterState(tab: 0 | 1) {
  const filterState = useHookstate(browseFilterStore);
  const filters = useMemo(() => {
    const filters = new URLSearchParams();
    const {
      query,
      comicEntityType,
      comicCategory,
      licenseType,
      artistEntityType,
      comicFormat,
      crowdfundingType,
      countryFacets,
      languageFacets,
      comicFacets,
      comicContributorType,
      comicUserReadingStatus,
      sortMenus,
      listId,
      contributor,
    } = filterState.get();

    // Single value
    if (comicEntityType && comicEntityType !== COMIC_TYPE_ALL) {
      filters.set("comic_type_id", comicEntityType);
    }

    if (comicCategory && comicCategory !== COMIC_CATEGORY_ALL) {
      filters.set("comic_genre_id", comicCategory);
    }

    if (licenseType && licenseType !== LICENSE_TYPE_ALL) {
      filters.set("license_type_id", licenseType);
    }

    if (artistEntityType && artistEntityType !== ARTIST_TYPE_ALL) {
      filters.set("artist_type_id", artistEntityType);
    }

    if (comicFormat) {
      if (comicFormat === COMIC_AUGMENTED_ENABLED) {
        filters.set("is_augmented", "1");
      } else if (comicFormat !== COMIC_FORMAT_ALL) {
        filters.set("comic_format_id", comicFormat);
      }
    }

    if (
      comicContributorType &&
      comicContributorType !== COMIC_CONTRIBUTOR_TYPE_ALL
    ) {
      filters.set("comic_contributor_type_id", comicContributorType);
    }

    if (
      comicUserReadingStatus &&
      comicUserReadingStatus !== COMIC_USER_READING_STATUS_ALL
    ) {
      filters.set("comic_user_reading_status_id", comicUserReadingStatus);
    }

    if (crowdfundingType && crowdfundingType !== CROWDFUNDING_TYPE_ALL) {
      filters.set("crowdfunding", crowdfundingType);
    }

    if (
      sortMenus.sortOrder &&
      sortMenus.sortOrder !== DEFAULT_SORT_ORDER_TYPE
    ) {
      filters.set("sort", sortMenus.sortOrder);
    }

    if (
      sortMenus.sortDuration &&
      sortMenus.sortDuration !== DEFAULT_SORT_DURATION_TYPE
    ) {
      filters.set("sd", sortMenus.sortDuration);
    }

    // Multi value
    forEach(comicFacets, (selectedFacets, facetGroupId) => {
      if (!selectedFacets.length) {
        return;
      }

      selectedFacets.forEach((facet) => {
        filters.append(`facets[${facetGroupId}][]`, facet);
      });
    });

    if (countryFacets?.length) {
      countryFacets.forEach((facet) => {
        filters.append(`country_id[]`, facet);
      });
    }

    if (languageFacets && languageFacets !== LANGUAGE_ALL) {
      filters.set("lang_id[]", languageFacets);
    }

    if (query && query !== "") {
      filters.set("q", query);
    }

    if (listId && listId !== "") {
      filters.set("sort", "list");
      filters.set("list_id", listId);
    }

    if (contributor && contributor !== "") {
      filters.set("contributor", contributor);
    }

    return filters;
  }, [filterState]);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug_1, slug_2, slug_3 } = useParams();
  const [urlSettled, setUrlSettled] = useState(false);

  type filterMapsType = {
    typesBySlug: { [key: string]: ComicTypeEntity };
    typesById: { [key: number]: ComicTypeEntity };
    genresBySlug: { [key: string]: ComicCategoryEntity };
    genresById: { [key: number | string]: ComicCategoryEntity };
    langsById: { [key: string]: LanguageEntity };
  };
  const initData = useHookstate(appStore.init).get({ noproxy: true });
  const filterMaps = useMemo(() => {
    const {
      comic_types: types,
      comic_genres: genres,
      languages: langs,
    } = initData;
    if (!types || !genres || !langs) return null;

    const filterMaps: filterMapsType = {
      typesBySlug: {},
      typesById: {},
      genresBySlug: {},
      genresById: {},
      langsById: {},
    };
    types.forEach((type) => {
      filterMaps.typesBySlug[type.slug] = type;
      filterMaps.typesById[type.id] = type;
    });
    genres.forEach((genre) => {
      filterMaps.genresBySlug[genre.slug] = genre;
      filterMaps.genresById[genre.id] = genre;
    });
    langs.forEach((langs) => {
      filterMaps.langsById[langs.id] = langs;
    });
    return filterMaps;
  }, [initData]);

  const getSlugFilters = (filterMaps: filterMapsType) => {
    if (!filterMaps) return null;
    const {
      typesBySlug: types,
      genresBySlug: genres,
      langsById: langs,
    } = filterMaps;

    let slugFilters: {
      genre?: ImmutableObject<ComicCategoryEntity>;
      type?: ImmutableObject<ComicTypeEntity>;
      lang?: LanguageEntity;
    } = {};

    if (slug_1 && slug_2 && slug_3) {
      slugFilters = {
        lang: langs[slug_1],
        type: types[slug_2],
        genre: genres[slug_3],
      };
    } else if (slug_1 && slug_2) {
      if (langs[slug_1]) {
        if (types[slug_2]) {
          slugFilters = {
            lang: langs[slug_1],
            type: types[slug_2],
          };
        } else {
          slugFilters = {
            lang: langs[slug_1],
            genre: genres[slug_2],
          };
        }
      } else {
        slugFilters = {
          type: types[slug_1],
          genre: genres[slug_2],
        };
      }
    } else if (slug_1) {
      if (langs[slug_1]) {
        slugFilters = { lang: langs[slug_1] };
      } else {
        if (genres[slug_1]) {
          slugFilters = { genre: genres[slug_1] };
        } else {
          slugFilters = {
            type: types[slug_1],
          };
        }
      }
    }
    return slugFilters;
  };

  useEffect(() => {
    if (!filterMaps) return;
    const slugFilters = getSlugFilters(filterMaps);
    if (!slugFilters) return;
    const { genre, type, lang } = slugFilters;

    const newBrowseFilterState: FilterState = {
      comicCategory: "" + (genre?.id ?? COMIC_CATEGORY_ALL),
      comicEntityType: "" + (type?.id ?? COMIC_TYPE_ALL),
      languageFacets: lang?.id ?? LANGUAGE_ALL,

      query: searchParams.get("q") ?? "",
      licenseType: searchParams.get("license_type_id") ?? LICENSE_TYPE_ALL,
      artistEntityType: searchParams.get("artist_type_id") ?? ARTIST_TYPE_ALL,
      comicFormat:
        searchParams.get("is_augmented") === "1"
          ? COMIC_AUGMENTED_ENABLED
          : (searchParams.get("comic_format_id") ?? COMIC_FORMAT_ALL),
      comicContributorType:
        searchParams.get("comic_contributor_type_id") ??
        COMIC_CONTRIBUTOR_TYPE_ALL,
      comicUserReadingStatus:
        searchParams.get("comic_user_reading_status_id") ??
        COMIC_USER_READING_STATUS_ALL,
      crowdfundingType:
        searchParams.get("crowdfunding") ?? CROWDFUNDING_TYPE_ALL,
      sortMenus: {
        sortDuration: searchParams.get("sd") ?? DEFAULT_SORT_DURATION_TYPE,
        sortOrder: searchParams.get("sort") ?? DEFAULT_SORT_ORDER_TYPE,
      },
      countryFacets: searchParams.getAll("country_id") ?? [],
      comicFacets: {
        "1": searchParams.getAll(`facet[1][]`) ?? [],
        "2": searchParams.getAll(`facet[2][]`) ?? [],
        "3": searchParams.getAll(`facet[3][]`) ?? [],
        "5": searchParams.getAll(`facet[5][]`) ?? [],
      },
      listId: searchParams.get("list") ?? searchParams.get("list_id") ?? "",
      contributor: searchParams.get("contributor") ?? "",
    };

    if (
      newBrowseFilterState.sortMenus.sortOrder === "list" &&
      !newBrowseFilterState.listId
    ) {
      newBrowseFilterState.sortMenus.sortOrder = DEFAULT_SORT_ORDER_TYPE;
    }

    if (!isEqual(newBrowseFilterState, filterState.get({ noproxy: true }))) {
      browseFilterStore.set(newBrowseFilterState);
    }
    if (!urlSettled) {
      setUrlSettled(true);
    }
  }, [urlSettled, location, filterMaps]);

  useEffect(() => {
    const typeId = filters.get("comic_type_id");
    const genreId = filters.get("comic_genre_id");
    const langId = filters.getAll("lang_id[]");

    const searchFilters = new URLSearchParams(filters);
    searchFilters.delete("comic_type_id");
    searchFilters.delete("comic_genre_id");
    searchFilters.delete("lang_id[]");

    if (!filterMaps) return;

    const {
      typesById: types,
      genresById: genres,
      langsById: langs,
    } = filterMaps;
    const type = types[parseInt(typeId ?? "")];
    const genre = genres[parseInt(genreId ?? "")];
    const lang = langs[langId[0]];

    let newUrl = `/${tab === 0 ? "browse" : "creators"}`;
    if (lang) newUrl += `/${lang.id}`;
    if (type) newUrl += `/${type.slug}`;
    if (genre) newUrl += `/${genre.slug}`;

    /// Mantain standalone query param on browse screen if it exists,
    // even if search is performed.
    const existingParams = new URLSearchParams(location.search);
    const standaloneValue = existingParams.get("standalone");

    if (urlSettled) {
      const combinedUrl =
        newUrl + (standaloneValue ? `?standalone=${standaloneValue}` : "");
      if (location.pathname !== newUrl) {
        navigate(combinedUrl);
      }

      if (standaloneValue) {
        searchFilters.set("standalone", standaloneValue);
      }

      const newSearch = "?" + searchFilters.toString();
      if (newSearch !== location.search) {
        setSearchParams(newSearch);
      }
    }
  }, [tab, filters, filterMaps]);

  return {
    urlSettled,
    filters,
    browseFilterStore,
    filterMaps,
    getSlugFilters,
  };
}
