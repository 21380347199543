import { Text, Image, Box, Flex, Link } from "@chakra-ui/react";
import { t } from "i18next";
import { PageThumbnailEntity, Release } from "../../../types";
import { ButtonCarousel } from "../../carousel/ButtonCarousel";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";
import { NavigationTrigger } from "../../../screens/reader/types";
import { rootComponentsStore } from "../../layouts/RootLayout";

export function ReleaseInfoPageCarousel({ release }: { release: Release }) {
  return (
    <Box position="relative" userSelect="none">
      <ButtonCarousel
        width="100%"
        onlyFade
        slidesToScroll={1}
        containerProps={{ gap: "12px" }}
      >
        {Object.values(release?.page_thumbnails ?? {}).map((page) => (
          <ReleasePageCard key={page.page} page={page} release={release} />
        ))}
      </ButtonCarousel>
    </Box>
  );
}

interface ReleasePageCardProps {
  release: Release;
  page: PageThumbnailEntity;
}

function ReleasePageCard({ page, release }: ReleasePageCardProps) {
  const fadeBottom = Math.floor(62 * (1 / page.aspect_x)) > 95;
  const navigationContext =
    rootComponentsStore.slideInMenuConfig.get()?.navigationContext;

  const { goToCTAUrl } = useNavigateToReaderWithContext(
    {
      ...navigationContext,
      pageToNavigate: page.page,
      releaseToNavigate: release,
      trigger: NavigationTrigger.pageThumbnailCard,
    },
    [release],
  );

  return (
    <Link onClick={goToCTAUrl}>
      <Box
        display="flex"
        position="relative"
        minWidth={Math.max(62, Math.floor(page.aspect_x * 95)) + "px"}
        height="110px"
        overflow="hidden"
      >
        <Flex
          alignItems="center"
          position="relative"
          minWidth="62px"
          height={fadeBottom ? "calc(100% - 15px)" : "95px"}
          overflow="hidden"
        >
          <Image
            margin="auto"
            borderRadius="10px"
            alt={page.alt_text}
            src={page.url}
            maxHeight={fadeBottom ? undefined : "95px"}
            aspectRatio={fadeBottom ? undefined : page.aspect_x}
          />
          {fadeBottom && (
            <Box
              position="absolute"
              bottom="0"
              width="100%"
              height="96px"
              background="linear-gradient(rgba(255, 255, 255, 0) 0%,  rgba(20, 21, 23, 0.95) 90%, rgba(20, 21, 23, 1) 100%)"
              pointerEvents="none"
            />
          )}
        </Flex>
        <Flex
          position="absolute"
          bottom="0px"
          width="100%"
          background="#141517"
          justifyContent="center"
        >
          <Text variant="bodyExtraSmallMedium">
            {page.page === 1
              ? t("components.slideInMenu.releaseInfoMenu.cover")
              : `${t("components.slideInMenu.releaseInfoMenu.page")} ${page.page - 1}`}
          </Text>
        </Flex>
      </Box>
    </Link>
  );
}
