import { ImmutableObject, useHookstate } from "@hookstate/core";
import { Comic } from "../../../types";
import { TagBadge } from "./TagBadge";
import { appStore } from "../../../appStore";
import { ComicFacetGroupEntity } from "../../../services/gc/types";
import { GCSmallLogoIcon } from "../../icons/GCSmallLogoIcon";

export interface GCVerticalsAndOriginalsBadgeProps {
  comicData: Comic;
  isLarge?: boolean;
}

const GC_INTERNAL_FACET_GROUP_ID = 6;
const GC_VERTICALS_FACET_GROUP_OPTION_ID = 99;
const GC_ORIGINALS_FACET_GROUP_OPTION_ID = 100;

export function GCVerticalsAndOriginalsBadge({
  comicData,
  isLarge = false,
}: GCVerticalsAndOriginalsBadgeProps) {
  const { comic_facet_groups } = useHookstate(appStore.init).get();
  const internalGlobalComixFacetGroup = comic_facet_groups.find(({ id }) => {
    return id === GC_INTERNAL_FACET_GROUP_ID;
  });

  if (!internalGlobalComixFacetGroup) {
    return null;
  }

  const isGCOriginals = comicData.comic_facets?.some(
    (facet) =>
      facet.comic_facet_group_option_id === GC_ORIGINALS_FACET_GROUP_OPTION_ID,
  );
  const gcOriginalsDisplayName =
    getDisplayNameByGroupId(
      GC_ORIGINALS_FACET_GROUP_OPTION_ID,
      internalGlobalComixFacetGroup,
    ) ?? "";

  const isGCVerticals = comicData.comic_facets?.some(
    (facet) =>
      facet.comic_facet_group_option_id === GC_VERTICALS_FACET_GROUP_OPTION_ID,
  );
  const gcVeriticalsDisplayName =
    getDisplayNameByGroupId(
      GC_VERTICALS_FACET_GROUP_OPTION_ID,
      internalGlobalComixFacetGroup,
    ) ?? "";

  return (
    <>
      {(isGCOriginals || isGCVerticals) && (
        <TagBadge
          background="blaze.blaze"
          text={
            isGCVerticals ? gcVeriticalsDisplayName : gcOriginalsDisplayName
          }
          icon={<GCSmallLogoIcon boxSize="12px" color="white" />}
          isLarge={isLarge}
        />
      )}
    </>
  );
}

function getDisplayNameByGroupId(
  id: number,
  group: ImmutableObject<ComicFacetGroupEntity>,
) {
  return group?.comic_facet_group_options.find((option) => option.id === id)
    ?.display_name;
}
