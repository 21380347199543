import { extendTheme } from "@chakra-ui/react";

import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { components } from "./components";
import { config } from "./config";
import { fontSizes } from "./fontSizes";
import { lineHeights } from "./lineHeights";
import { sizes } from "./sizes";
import { styles } from "./styles";
import { layerStyles } from "./layerStyles";

export const theme = extendTheme({
  breakpoints,
  colors,
  components,
  config,
  fontSizes,
  layerStyles,
  lineHeights,
  sizes,
  styles,
});
