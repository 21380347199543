import { Icon, IconProps } from "@chakra-ui/react";

export function VerticalSquiggleSmallIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 6 26" {...props}>
      <path
        d="M5 1L5 25L1 8.86885"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
