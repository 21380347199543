import { hookstate } from "@hookstate/core";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../components/sortMenus/SortOrderMenu";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../components/sortMenus/SortDurationMenu";

export type FilterState = {
  sortMenus: {
    sortOrder?: string;
    sortDuration?: string;
  };
};

const DEFAULT_FILTER_STATE = {
  sortMenus: {
    sortOrder: DEFAULT_SORT_ORDER_TYPE,
    sortDuration: DEFAULT_SORT_DURATION_TYPE,
  },
};

export const artistComicsFilterStore =
  hookstate<FilterState>(DEFAULT_FILTER_STATE);
