import { ReactElement } from "react";
import { ArtistContentCardProps } from "../../types";
import { ArtistGridCard } from "./ArtistGridCard";
import { ArtistListCard } from "./ArtistListCard";
import { ArtistHorizontalCard } from "./ArtistHorizontalCard";
import { Link } from "@chakra-ui/react";
import { useSlideInMenu } from "../slideInMenu/hooks/useSlideInMenu";
import { SLIDE_IN_MENUS } from "../../screens/root/constants";

export function ArtistCard(props: ArtistContentCardProps) {
  const mode = props.layout.style.mode;
  const { onToggleSlideInMenu } = useSlideInMenu(true);
  const cards: { [key: string]: ReactElement } = {
    grid: <ArtistGridCard {...props} />,
    horizontal: <ArtistHorizontalCard {...props} />,
    list: <ArtistListCard {...props} />,
  };

  if (cards[mode]) {
    return (
      <Link
        href={props.content.url}
        draggable={false}
        onClick={(e) => {
          e.preventDefault();
          if (props.dontOpenInfo) return;
          onToggleSlideInMenu(SLIDE_IN_MENUS.publisher, {
            artistId: props.content.id,
          });
        }}
      >
        {cards[mode]}
      </Link>
    );
  }

  console.warn("Unable to determine layout mode for ArtistCard");
  return null;
}
