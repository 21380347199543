import { Icon, IconProps } from "@chakra-ui/react";

export function CheckboxCheckedIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.5C3.17157 2.5 2.5 3.17157 2.5 4V20C2.5 20.8284 3.17157 21.5 4 21.5H20C20.8284 21.5 21.5 20.8284 21.5 20V4C21.5 3.17157 20.8284 2.5 20 2.5H4ZM3.5 4C3.5 3.72386 3.72386 3.5 4 3.5H20C20.2761 3.5 20.5 3.72386 20.5 4V20C20.5 20.2761 20.2761 20.5 20 20.5H4C3.72386 20.5 3.5 20.2761 3.5 20V4ZM16.3737 9.33219C16.5572 9.1258 16.5386 8.80976 16.3322 8.6263C16.1258 8.44284 15.8098 8.46143 15.6263 8.66782L10.6667 14.2474L8.3737 11.6678C8.19025 11.4614 7.87421 11.4428 7.66782 11.6263C7.46143 11.8098 7.44284 12.1258 7.6263 12.3322L10.293 15.3322C10.3878 15.4389 10.5238 15.5 10.6667 15.5C10.8095 15.5 10.9455 15.4389 11.0404 15.3322L16.3737 9.33219Z"
        fill="currentColor"
      />
    </Icon>
  );
}
