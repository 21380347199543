import { ThemeOverride } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

/**
 * Overrides for global styles
 */
export const styles = {
  global(props) {
    return {
      body: {
        bg: mode("neutral.white", "slate.850")(props),
        color: mode("neutral.black", "neutral.white")(props),
        fontFamily: "Roboto",
      },
    };
  },
} as ThemeOverride["styles"];
