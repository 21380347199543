import { Button, Flex } from "@chakra-ui/react";
import { TrashcanIcon } from "../../icons/reader/TrashcanIcon";
import { t } from "i18next";

interface ReadingStatusSubmitButtonsProps {
  remove: () => void;
  update: () => void;
  contextual?: boolean;
  inLibrary?: boolean;
}
export function ReadingStatusSubmitButtons({
  remove,
  update,
  contextual = false,
  inLibrary = false,
}: ReadingStatusSubmitButtonsProps) {
  return (
    <Flex w="full" justifyContent="space-between" gap="16px">
      {inLibrary ? (
        <Button
          variant="tertiaryIcon"
          size={contextual ? "sm" : "md"}
          onClick={remove}
        >
          <TrashcanIcon color="dune.dune" />
        </Button>
      ) : null}
      <Button
        variant="primary"
        size={contextual ? "sm" : "md"}
        width={contextual ? undefined : "full"}
        onClick={update}
      >
        {inLibrary
          ? t("components.readingStatus.updateStatus")
          : t("components.readingStatus.addToLibrary")}
      </Button>
    </Flex>
  );
}
