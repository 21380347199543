import { Icon, IconProps } from "@chakra-ui/react";

export function GCThinLogoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="home">
        <path
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2151 20.5202C15.6351 20.5202 17.9051 19.5102 19.5151 17.7402L20.2951 18.3602C18.4851 20.3702 15.9351 21.5102 13.2151 21.5102C7.97508 21.5102 3.70508 17.2402 3.70508 12.0002C3.70508 6.76023 7.97508 2.49023 13.2051 2.49023C15.9251 2.49023 18.4851 3.63023 20.2851 5.64023L19.5051 6.26023C17.8951 4.49023 15.6351 3.48023 13.2151 3.48023C8.51508 3.48023 4.69508 7.30023 4.69508 12.0002C4.69508 16.7002 8.51508 20.5202 13.2151 20.5202ZM16.155 8.92023C15.365 8.16023 14.305 7.73023 13.195 7.73023C10.825 7.73023 8.89503 9.66023 8.89503 12.0302C8.89503 14.4002 10.825 16.3302 13.195 16.3302C14.845 16.3302 16.365 15.3702 17.075 13.8802L17.415 13.1802L16.655 13.0302H14.215V11.9302H18.485V12.0302C18.485 14.9502 16.115 17.3202 13.195 17.3202C10.275 17.3202 7.90503 14.9502 7.90503 12.0302C7.90503 9.11023 10.275 6.74023 13.195 6.74023C14.615 6.74023 15.955 7.30023 16.945 8.30023L16.155 8.92023Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
