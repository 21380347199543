import { Box, Flex, keyframes, Text } from "@chakra-ui/react";
import React from "react";
import { AwaLogo } from "./publishers/AwaLogo";
import { DarkHorseLogo } from "./publishers/DarkHorseLogo";
import { DCLogo } from "./publishers/DCLogo";
import { ImageComicsLogo } from "./publishers/ImageComicsLogo";
import { BoomLogo } from "./publishers/BoomLogo";
import { OniPressLogo } from "./publishers/OniPressLogo";
import { VaultLogo } from "./publishers/VaultLogo";
import { BadEggLogo } from "./publishers/BadEggLogo";
import { MadCaveLogo } from "./publishers/MadCaveLogo";
import { HumanoidsLogo } from "./publishers/HumanoidsLogo";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
export interface FeaturedPublisher {
  name: string;
  logo?: React.ReactNode;
}

export function ListOfFeaturedPublishers() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const scrollAnimation = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  `;

  const publishers = [
    <DCLogo color="white" width="84" height="83" />,
    <ImageComicsLogo color="white" width="119" height="43" />,
    <DarkHorseLogo color="white" width="143" height="38" />,
    <BoomLogo color="white" width="95" height="46" />,
    <OniPressLogo color="white" width="105" height="62" />,
    <VaultLogo color="white" width="101" height="47" />,
    <AwaLogo color="white" width="92" height="38" />,
    <BadEggLogo color="white" width="50px" height="120px" />,
    <MadCaveLogo color="white" width="50px" height="212px" />,
    <HumanoidsLogo color="white" width="200px" height="31px" />,
  ];

  const animationDuration = `${publishers.length * (isSmallBreakpoint ? 2 : 5)}s`;
  return (
    <Flex flexDirection={"column"} marginBottom={"-48px"}>
      <Text
        fontFamily="Bricolage Grotesque 18pt"
        fontWeight="400"
        fontSize="16.906px"
        fontStyle="normal"
        lineHeight="140%"
        letterSpacing="0.169px"
        color="#646464"
        sx={{
          textWrap: "balance",
        }}
      >
        FEATURED PUBLISHERS
      </Text>
      <Box
        overflow="hidden"
        whiteSpace="nowrap"
        width="100%"
        position="relative"
      >
        <Flex
          as="div"
          animation={`${scrollAnimation} linear infinite`}
          alignItems="center"
          style={{ animationDuration }}
        >
          {publishers.map((publisher, index) => (
            <Box key={index} marginRight="40px">
              {publisher}
            </Box>
          ))}
          {publishers.map((publisher, index) => (
            <Box key={index * 2} marginRight="40px">
              {publisher}
            </Box>
          ))}
        </Flex>
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          width="24px"
          bgGradient="linear-gradient(270deg, rgba(20, 21, 23, 0) 0%, #141517 100%)"
          pointerEvents="none"
        />
        <Box
          position="absolute"
          top="0"
          bottom="0"
          right="0"
          width="24px"
          bgGradient="linear-gradient(90deg, rgba(20, 21, 23, 0) 0%, #141517 100%)"
          pointerEvents="none"
        />
      </Box>
    </Flex>
  );
}
