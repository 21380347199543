import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

export function useGaTracking(trackLocation?: boolean) {
  const location = useLocation();
  const lastTrackedPath = useRef<string>();

  useEffect(() => {
    if (!trackLocation) return;

    if (lastTrackedPath.current === location.pathname + location.search) return;
    tracking.trackGaPageView();
    lastTrackedPath.current = location.pathname + location.search;
  }, [location]);

  const tracking = {
    trackGaPageView: () => {
      if (!import.meta.env.PROD) {
        console.info(
          "tracking.trackGaPageView",
          location.pathname + location.search,
        );
      }
      try {
        window.TrackingHandlers.trackGaPageView();
      } catch (e) {
        console.warn("Tracking page view failed:", e);
      }
    },
    removeThirdPartyGaId: (name: string) => {
      if (!import.meta.env.PROD) {
        console.info("tracking.removeThirdPartyGaId", name);
      }
      try {
        window.TrackingHandlers.removeThirdPartyGaId(name);
      } catch (e) {
        console.warn("Removing third party GA ID failed:", e);
      }
    },
    addThirdPartyGaId: (name: string, gaId: string) => {
      if (!import.meta.env.PROD) {
        console.info("tracking.addThirdPartyGaId", name, gaId);
      }
      try {
        window.TrackingHandlers.addThirdPartyGaId(name, gaId);
      } catch (e) {
        console.warn("Adding third party GA ID failed:", e);
      }
    },
    trackGaEvent: (type: string, props: Object) => {
      window.gtag("event", type, { webnext: true, ...props });
    },
  };

  return tracking;
}
