import { BoxProps, Flex, Text } from "@chakra-ui/react";
import { BorderCorner, SharpCornerBox } from "./SharpCornerBox";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

interface LeavingSoonBadgeProps extends BoxProps {
  corners?: BorderCorner[];
  leavingSoonDate: string;
  showBackground?: boolean;
}

export function LeavingSoonBadge({
  leavingSoonDate,
  corners,
  ...props
}: LeavingSoonBadgeProps) {
  const [t] = useTranslation();

  const date = DateTime.fromISO(leavingSoonDate);

  // Format the date to "MMM DD", only one digit if leading number is zero.
  const formattedDate = date.toFormat("MMM d").toUpperCase();
  return (
    <SharpCornerBox
      corners={corners}
      background={"warning.600"}
      borderWidth={0}
      top={0}
      fontSize="10px"
      {...props}
    >
      <Flex
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent={"inherit"}
        fontSize="inherit"
      >
        <Text
          fontWeight={700}
          lineHeight={"150%"}
          textTransform="uppercase"
          textAlign={"center"}
        >
          {t("components.cards.facets.leaving")} {formattedDate}
        </Text>
      </Flex>
    </SharpCornerBox>
  );
}

export function ComicCardLeavingSoonBadge({
  leavingSoonDate,
  ...props
}: LeavingSoonBadgeProps) {
  return (
    <LeavingSoonBadge
      leavingSoonDate={leavingSoonDate}
      corners={["bottomLeft", "bottomRight"]}
      position="absolute"
      width="100%"
      color="black"
      boxShadow="0px 1px 1px 0px var(--chakra-colors-black)"
      justifyContent="center"
      {...props}
    />
  );
}

export function CustomCardLeavingSoonBadge({
  leavingSoonDate,
  ...props
}: LeavingSoonBadgeProps) {
  return (
    <LeavingSoonBadge
      leavingSoonDate={leavingSoonDate}
      corners={["bottomLeft", "topRight"]}
      borderRadius={"16px"}
      color="reader.neutral.900_75"
      fontSize={"12px"}
      {...props}
    />
  );
}
