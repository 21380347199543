import {
  Box,
  Flex,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  useToast,
} from "@chakra-ui/react";
import Clipboard from "react-clipboard.js";
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import { CopyIcon } from "@chakra-ui/icons";
import { LegacyRef } from "react";

export interface SharePopoverProps {
  shareUrl: string;
  title: string;
  contentRef?: LegacyRef<HTMLDivElement>;
}

export function SharePopover({
  shareUrl,
  title,
  contentRef,
}: SharePopoverProps) {
  const toast = useToast();

  return (
    <Box zIndex="2000" position="relative">
      <PopoverContent
        ref={contentRef}
        w="fit-content"
        background="charcoal.900"
        borderColor="neutral.white"
      >
        <PopoverArrow bg="charcoal.900" />
        <PopoverBody>
          <Flex direction="row" gap={2} onClick={(e) => e.stopPropagation()}>
            <Clipboard
              data-clipboard-text={shareUrl}
              onSuccess={() => {
                toast({
                  status: "success",
                  title: "Copied URL to clipboard",
                });
              }}
              onError={() => {
                toast({
                  status: "error",
                  title: "Something went wrong, unable to copy the url",
                });
              }}
            >
              <CopyIcon w="24px" color="neutral.white" />
            </Clipboard>
            <RedditShareButton title={title} url={shareUrl}>
              <RedditIcon size={24} round />
            </RedditShareButton>
            <FacebookShareButton title={title} url={shareUrl}>
              <FacebookIcon size={24} round />
            </FacebookShareButton>
            <TwitterShareButton title={title} url={shareUrl}>
              <TwitterIcon size={24} round />
            </TwitterShareButton>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Box>
  );
}
