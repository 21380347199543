import { Flex, Text } from "@chakra-ui/react";

import {
  Artist,
  Comic,
  ContentCardProps,
  DisplayFlags,
  Release,
} from "../../../types";
import { BookIcon } from "../../icons/BookIcon";
import { BookmarkIcon } from "../../icons/BookmarkIcon";
import { ChapterIcon } from "../../icons/ChapterIcon";
import { EyeIcon } from "../../icons/EyeIcon";
import { FollowIcon } from "../../icons/FollowIcon";
import { PagesIcon } from "../../icons/PagesIcon";
import { ReleaseIcon } from "../../icons/ReleaseIcon";
import { ComicStatusBadge } from "./ComicStatusBadge";
import { SharpCornerBox } from "./SharpCornerBox";
import { StatsTag } from "./StatsTag";
import { ComicCardLeavingSoonBadge } from "./LeavingSoonBadge";
import { timeAgoSqlDate } from "../../../utils/time";
import { CalendarEmptyIcon } from "../../icons/CalendarEmptyIcon";

type ReleaseProps = Partial<
  Pick<Release, "total_pageviews" | "update_time" | "page_count">
>;

type ComicProps = Partial<
  Pick<
    Comic,
    | "total_favorites"
    | "relevance_match"
    | "total_published_releases"
    | "status"
    | "status_name"
    | "total_chapters"
    | "leaving_soon_date"
  >
>;

type ArtistProps = Partial<Pick<Artist, "total_releases" | "total_projects">>;

type FooterReleaseProps = ReleaseProps & ComicProps & ArtistProps;

type FooterIncludesProps = Partial<
  Pick<
    DisplayFlags,
    | "include_total_views"
    | "include_match"
    | "include_total_favorites"
    | "include_last_updated"
    | "include_total_releases"
    | "include_total_pages"
    | "include_comic_status"
    | "include_total_releases"
    | "include_chapter_num"
  > & {
    include_total_projects: boolean;
  }
>;

type LayoutMode = ContentCardProps["layout"]["style"]["mode"];
type ContentCardFooterProps = FooterReleaseProps &
  FooterIncludesProps & {
    layoutMode: LayoutMode;
    // Any custom props
    useAlternateFavoritesIcon?: boolean;
  };

export function ContentCardFooter({
  total_favorites,
  total_pageviews,
  total_published_releases,
  total_chapters,
  page_count,
  relevance_match,
  status_name,
  include_last_updated,
  include_match,
  include_total_favorites,
  include_total_views,
  include_total_releases,
  include_total_pages,
  include_comic_status,
  include_chapter_num,
  include_total_projects,
  total_projects,
  layoutMode,
  update_time,
  useAlternateFavoritesIcon,
  leaving_soon_date,
}: ContentCardFooterProps) {
  return (
    <Flex
      direction="row"
      gap=".75rem"
      data-include-last-updated={include_last_updated}
      data-include-match={include_match}
      data-include-total-favorites={include_total_favorites}
      data-include-total-views={include_total_views}
      data-include-total-releases={include_total_releases}
      data-include-total-pages={include_total_pages}
      data-include-comic-status={include_comic_status}
      data-include-chapter-num={include_chapter_num}
    >
      {leaving_soon_date && (
        <ComicCardLeavingSoonBadge
          leavingSoonDate={leaving_soon_date}
          position="relative"
          paddingX="0px"
          background={undefined}
          color="reader.neutral.200"
          fontSize="10px"
          justifyContent="flex-start"
          width={undefined}
        />
      )}
      {include_match && relevance_match ? (
        layoutMode === "grid" ? (
          <SharpCornerBox>
            <Text
              variant="cardVitals"
              textTransform="uppercase"
              color="neutral.white"
            >
              {relevance_match}%
            </Text>
          </SharpCornerBox>
        ) : (
          <Text variant="cardVitals" textTransform="uppercase">
            {relevance_match}% match
          </Text>
        )
      ) : null}
      {include_comic_status && status_name ? (
        <ComicStatusBadge statusText={status_name} />
      ) : null}
      {include_last_updated ? (
        <Flex gap={1} alignItems="center">
          <CalendarEmptyIcon color="fresh.300" boxSize="12px" />
          <Text variant="cardVitals">{timeAgoSqlDate(update_time ?? "")}</Text>
        </Flex>
      ) : null}
      {include_total_views ? (
        <StatsTag
          count={total_pageviews ?? 0}
          icon={<EyeIcon color="ice.300" boxSize="12px" />}
        />
      ) : null}
      {include_total_favorites ? (
        <StatsTag
          count={total_favorites ?? 0}
          icon={
            useAlternateFavoritesIcon ? (
              <FollowIcon color="ice.300" boxSize="12px" />
            ) : (
              <BookmarkIcon color="blaze.300" boxSize="12px" />
            )
          }
        />
      ) : null}
      {include_total_releases ? (
        <StatsTag
          count={total_published_releases ?? 0}
          icon={<ReleaseIcon color="electric_pink.300" boxSize="12px" />}
        />
      ) : null}
      {include_total_pages ? (
        <StatsTag
          count={page_count ?? 0}
          icon={<PagesIcon color="electric_pink.300" boxSize="12px" />}
        />
      ) : null}
      {include_chapter_num ? (
        <StatsTag
          count={total_chapters ?? 0}
          icon={<ChapterIcon color="eletric_pink.300" boxSize="12px" />}
        />
      ) : null}
      {include_total_projects ? (
        <StatsTag
          count={total_projects ?? 0}
          icon={<BookIcon color="orange.300" boxSize="12px" />}
        />
      ) : null}
    </Flex>
  );
}
