import { Button, Flex } from "@chakra-ui/react";
import { LibraryCTAButton } from "../../buttons/LibraryCTAButton";
import { ReadingStatusButton } from "../../readingStatusButton/ReadingStatusButton";
import { ShareButton } from "../../buttons/share/ShareButton";

interface ContentCardActionsProps {
  isFavorite?: boolean;

  showFollow?: boolean;
  isFollowing?: boolean;

  showLibraryCTA?: boolean;
  smallLibrary?: boolean;
  isInLibrary?: boolean;

  cacheKey?: string;
  showReadingStatusButton?: boolean;

  onToggleFavorite?: () => void;
  onToggleFollow?: () => void;

  shareUrl?: string;
}

export function ContentCardActions({
  showFollow,
  isFollowing,

  smallLibrary,
  showLibraryCTA,
  isInLibrary,

  showReadingStatusButton,
  cacheKey,

  onToggleFollow,

  shareUrl,
}: ContentCardActionsProps) {
  if (!showReadingStatusButton && !showLibraryCTA && !showFollow && !shareUrl) {
    return null;
  }

  return (
    <Flex direction="row" gap={2} position="relative">
      {showFollow ? (
        <Button
          onClick={onToggleFollow}
          isActive={isFollowing}
          variant={isFollowing ? "tertiary" : "secondary"}
          textTransform="uppercase"
        >
          {isFollowing ? "unfollow" : "follow"}
        </Button>
      ) : null}
      {showLibraryCTA ? (
        <LibraryCTAButton
          noIcon
          onClick={onToggleFollow}
          active={isInLibrary}
          small={smallLibrary}
          showBorder={false}
        />
      ) : null}
      {shareUrl && (
        <ShareButton shareUrl={shareUrl} title="Share" color="info.200" />
      )}
      {showReadingStatusButton && cacheKey ? (
        <ReadingStatusButton cacheKey={cacheKey} isSmall={smallLibrary} />
      ) : null}
    </Flex>
  );
}
