import { Icon, IconProps } from "@chakra-ui/react";

export function FitToWidthIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 94 94" {...props}>
      <path
        d="M25.5 32C25.5 28.4102 28.4101 25.5 32 25.5H62C65.5899 25.5 68.5 28.4101 68.5 32V62C68.5 65.5899 65.5899 68.5 62 68.5H32C28.4102 68.5 25.5 65.5899 25.5 62V32Z"
        fill="transparent"
        stroke="#B8BCC6"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 47C6 46.4477 6.44772 46 7 46L17 46C17.5523 46 18 46.4477 18 47C18 47.5523 17.5523 48 17 48L7 48C6.44772 48 6 47.5523 6 47Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 42.2929C12.0976 42.6834 12.0976 43.3166 11.7071 43.7071L8.41421 47L11.7071 50.2929C12.0976 50.6834 12.0976 51.3166 11.7071 51.7071C11.3166 52.0976 10.6834 52.0976 10.2929 51.7071L6.29289 47.7071C5.90237 47.3166 5.90237 46.6834 6.29289 46.2929L10.2929 42.2929C10.6834 41.9024 11.3166 41.9024 11.7071 42.2929Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 47C76 46.4477 76.4477 46 77 46H87C87.5523 46 88 46.4477 88 47C88 47.5523 87.5523 48 87 48H77C76.4477 48 76 47.5523 76 47Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.2929 42.2929C82.6834 41.9024 83.3166 41.9024 83.7071 42.2929L87.7071 46.2929C87.8946 46.4804 88 46.7348 88 47C88 47.2652 87.8946 47.5196 87.7071 47.7071L83.7071 51.7071C83.3166 52.0976 82.6834 52.0976 82.2929 51.7071C81.9024 51.3166 81.9024 50.6834 82.2929 50.2929L85.5858 47L82.2929 43.7071C81.9024 43.3166 81.9024 42.6834 82.2929 42.2929Z"
        fill="#667085"
      />
    </Icon>
  );
}
