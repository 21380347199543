import { Icon, IconProps } from "@chakra-ui/react";

export function PlayIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74275 3.57125C4.89335 3.48089 5.08033 3.47617 5.23529 3.55882L20.2353 11.5588C20.3982 11.6457 20.5 11.8153 20.5 12C20.5 12.1847 20.3982 12.3543 20.2353 12.4412L5.23529 20.4412C5.08033 20.5238 4.89335 20.5191 4.74275 20.4287C4.59215 20.3384 4.5 20.1756 4.5 20V4C4.5 3.82437 4.59215 3.66162 4.74275 3.57125ZM5.5 4.83333V19.1667L18.9375 12L5.5 4.83333Z"
        fill="currentColor"
      />
    </Icon>
  );
}
