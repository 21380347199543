import { Icon, IconProps } from "@chakra-ui/react";

export function PublishIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6464 1.64645C18.8417 1.45118 19.1583 1.45118 19.3536 1.64645L22.3536 4.64645C22.5488 4.84171 22.5488 5.15829 22.3536 5.35355L19.3536 8.35355L13.3536 14.3536C13.2598 14.4473 13.1326 14.5 13 14.5H10C9.72386 14.5 9.5 14.2761 9.5 14V11C9.5 10.8674 9.55268 10.7402 9.64645 10.6464L15.6464 4.64645L18.6464 1.64645ZM16 5.70711L10.5 11.2071V13.5H12.7929L18.2929 8L16 5.70711ZM19 7.29289L16.7071 5L19 2.70711L21.2929 5L19 7.29289ZM5 4.5C4.72386 4.5 4.5 4.72386 4.5 5V19C4.5 19.2761 4.72386 19.5 5 19.5H19C19.2761 19.5 19.5 19.2761 19.5 19V14C19.5 13.7239 19.7239 13.5 20 13.5C20.2761 13.5 20.5 13.7239 20.5 14V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V5C3.5 4.17157 4.17157 3.5 5 3.5H10C10.2761 3.5 10.5 3.72386 10.5 4C10.5 4.27614 10.2761 4.5 10 4.5H5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
