import { Button, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { FooterBanner } from "../../../components/banners/FooterBanner";
import { t } from "i18next";
import { setFunnelModalConfig } from "../../../components/funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../../components/funnel/utils/contants";

export function OnboardingBanner() {
  const openOnboarding = () => {
    setFunnelModalConfig({
      type: FUNNEL_TYPES.onboarding,
      options: { trapFocus: true },
    });
  };

  return (
    <FooterBanner
      text={
        <Trans
          i18nKey="screens.root.finishOnboarding"
          components={{
            bold: <Text as="span" fontWeight="bold" />,
          }}
        />
      }
      actions={
        <Button variant="tertiary" size="sm" onClick={openOnboarding}>
          {t("screens.root.finishNow")}
        </Button>
      }
    />
  );
}
