import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { DonateIcon } from "../../../../components/icons/DonateIcon";
import { ComicCrowdfundingTypeEntity, Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const CROWDFUNDING_TYPE_ALL = "0";

interface CrowdfundingTypeFilterProps {
  value: Immutable<string | undefined>;
  crowdfundingTypes: ComicCrowdfundingTypeEntity[];
  onChange: (nextValue: string) => void;
}
export function CrowdfundingTypeFilter({
  value,
  crowdfundingTypes,
  onChange,
}: CrowdfundingTypeFilterProps) {
  const typeOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: CROWDFUNDING_TYPE_ALL },
      ...crowdfundingTypes.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <RadioFilter value={value} options={typeOptions} onChange={onChange} />
  );
}

interface CrowdfundingTypeFilterTitleProps {
  isTrigger: boolean;
  crowdfundingTypes: ComicCrowdfundingTypeEntity[];
  open?: boolean;
  value?: string;
}
export const CrowdfundingTypeFilterTitle = forwardRef(
  (
    {
      isTrigger,
      crowdfundingTypes,
      open,
      value,
      ...props
    }: CrowdfundingTypeFilterTitleProps,
    ref,
  ) => {
    const crowdfundingType = crowdfundingTypes.find((type) => type.id == value);
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<DonateIcon />}
          popoverRef={ref}
          text="Crowdfunding"
          isFiltering={crowdfundingType?.name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Crowdfunding"
        filterValue={crowdfundingType?.name}
        open={open}
      />
    );
  },
);
