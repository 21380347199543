import ReactPixel from "react-facebook-pixel";

if (process.env.NODE_ENV === "production") {
  const options = {
    autoConfig: true,
    debug: false,
  };

  ReactPixel.init("484185286243290", undefined, options);
  ReactPixel.pageView();
}
