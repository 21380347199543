import { apiClient } from "../../../services/axiosInstance";
import { ConversationEntity, ServerListResponse } from "../../../types";

export function getConversations() {
  return apiClient.get<ServerListResponse<ConversationEntity>>(
    `/v1/account/conversations`,
  );
}

export const GET_CONVERSATIONS_QUERY_KEY = ["conversations"];

export function postAcknowledgeConversation(conversationId: number) {
  return apiClient.post(`/v1/account/conversations/mark-seen`, {
    meta: {},
    payload: {
      conversation_thread_id: conversationId,
    },
  });
}
