import { useCallback } from "react";
import { getCTAUrl } from "../../../utils/getCTA";
import { useNavigate } from "react-router";
import { readerNavigationContext } from "../ReaderScreen";
import { Comic, Release } from "../../../types";
import {
  useGetReaderNavigationContext,
  UseGetReaderNavigationContextProps,
} from "./useGetReaderNavigationContext";

export function useNavigateToReaderWithContext(
  {
    comicToNavigate,
    releaseToNavigate,
    trigger,
    contextComicId,
    section,
    cardPosition,
    sectionPosition,
    notificationTypeId,
    area,
    pageToNavigate,
  }: UseGetReaderNavigationContextProps & {
    releaseToNavigate?: Release;
    comicToNavigate?: Comic;
    pageToNavigate?: number;
  },
  dependencies: any[] = [],
) {
  const navigationContext = useGetReaderNavigationContext({
    trigger,
    contextComicId,
    section,
    cardPosition,
    sectionPosition,
    notificationTypeId,
    area,
  });

  const navigate = useNavigate();

  const goToCTAUrl = useCallback(() => {
    readerNavigationContext.set(navigationContext);
    const entityToNavigate = releaseToNavigate ?? comicToNavigate;
    if (entityToNavigate) {
      let ctaUrl = getCTAUrl(entityToNavigate);
      if (pageToNavigate) {
        ctaUrl = ctaUrl.replace(/\/\d+$/, `/${pageToNavigate}`);
      }

      navigate(ctaUrl);
    }
  }, [navigationContext, ...dependencies]);

  return {
    goToCTAUrl,
    navigationContext,
  };
}
