import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { hookstate, useHookstate } from "@hookstate/core";
import { Suspense, useEffect, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { Location, Outlet, useLocation } from "react-router";
import { useIsSidebarOpen } from "../../hooks/useIsSidebarOpen";
import { useIsOverlayOpen } from "../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { NavBar } from "../navigation/NavBar";
import { SideBar } from "../navigation/SideBar";
import { ErrorBoundary } from "../errors/ErrorBoundary";
import { FunnelModal } from "../funnel/FunnelModal";
import { FUNNEL_TYPES_TYPES } from "../funnel/utils/contants";
import { UseFunnelOptionsTypes } from "../funnel/hooks/useFunnel";
import { useGaTracking } from "../../hooks/useGaTracking";
import { CollapsibleNavBar } from "../navbar/CollapsibleNavBar";
import { SomethingWentWrong } from "../errors/components/SomethingWentWrong";
import { setUser } from "@sentry/react";
import { useAccountData } from "../../hooks/useAccountData";
import { SlideInMenu } from "../slideInMenu/SlideInMenu";
import { useSlideInMenu } from "../slideInMenu/hooks/useSlideInMenu";
import { ReaderNavigationContext } from "../../screens/reader/types";
import { EmailVerificationBanner } from "../../screens/root/components/EmailVerificationBanner";
import { OnboardingBanner } from "../../screens/root/components/OnboardingBanner";
import { NAVBAR_HEIGHT } from "../../screens/root/constants";
import { ContextReferrer } from "../funnel/types";
import { useSearchParams } from "react-router-dom";

export type FunnelConfigTypes = {
  type?: FUNNEL_TYPES_TYPES;
  options?: UseFunnelOptionsTypes;
};
export const rootComponentsStore = hookstate<{
  isSidebarOpen: boolean;
  funnelModal: FunnelConfigTypes;
  inReader?: boolean;
  isTopbarHidden?: boolean;
  eventTrackingQueryParams?: string;
  eventContextReferrer?: ContextReferrer;

  openSlideInMenu?: string;
  slideInMenuConfig?: {
    comicSlug?: string;
    releaseUuid?: string;
    artistId?: number;
    navigationContext?: ReaderNavigationContext;
  };

  locationHistory?: Location;
}>({
  isSidebarOpen: true,
  funnelModal: {},
  inReader: false,
  eventTrackingQueryParams: undefined,
  isTopbarHidden: false,
  eventContextReferrer: undefined,
});
export function RootLayout() {
  useGaTracking(true);

  const isSmallScreen = useIsSmallBreakpoint();
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isMobile, isTablet, isAndroid } = selectors;

  const { openMenu, onCloseMenu } = useSlideInMenu();
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  const { isOverlayOpen } = useIsOverlayOpen();
  const isReader = useHookstate(rootComponentsStore.inReader).get();
  const isTopbarHidden = useHookstate(rootComponentsStore.isTopbarHidden).get();

  const collapseSidebarForSmalldevices = isSmallScreen || isMobile || isTablet;
  const [defaultedSidebar, setDefaultedSidebar] = useState(false);

  const [searchParams] = useSearchParams();
  const isStandalone = searchParams.get("standalone") === "1";

  useEffect(() => {
    setDefaultedSidebar(true);
    if (defaultedSidebar || !collapseSidebarForSmalldevices) return;
    setIsSidebarOpen(!collapseSidebarForSmalldevices);
  }, [collapseSidebarForSmalldevices, defaultedSidebar]);

  useEffect(() => {
    if (!isReader) return;
    rootComponentsStore.isSidebarOpen.set(false);
  }, [isReader]);

  const location = useLocation();
  useEffect(() => {
    if (openMenu) onCloseMenu();
    return () => rootComponentsStore.locationHistory.set(location);
  }, [location]);

  const accountData = useAccountData();
  const userData = accountData?.user;

  useEffect(() => {
    if (accountData) {
      setUser({
        id: accountData.user?.id,
        email: accountData.user?.email_address,
        guest_id: accountData.guest_id,
      });
    }
  }, [accountData]);

  // Render only after initialization
  if (!defaultedSidebar) return null;

  const isGoldPage = location.pathname.includes("gold");
  const isGiftCardPage = location.pathname.includes("gift-card");
  const isChatPage = location.pathname.includes("chat");
  const showEmailVerificationBanner = userData && !userData.verified;
  const showOnboardingBanner = userData && userData.onboarding !== "completed";

  const shouldHideBanners = isReader || isChatPage;

  return (
    <ErrorBoundary errorComponent={<SomethingWentWrong />}>
      <Flex
        direction="column"
        h="100dvh"
        data-is-android={isAndroid}
        overflow="hidden"
        position="relative"
      >
        <FunnelModal />
        {!isStandalone && (
          <CollapsibleNavBar
            isCollapsed={isTopbarHidden}
            shouldCollapse={!isReader && isSmallScreen}
          >
            <NavBar />
          </CollapsibleNavBar>
        )}
        <Flex
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          background="transparent.black.70"
          zIndex={2}
          transition="all .4s ease"
          opacity={openMenu ? 1 : 0}
          visibility={openMenu ? "visible" : "hidden"}
          onClick={onCloseMenu}
        />
        <SlideInMenu
          isTopBarHidden={isTopbarHidden && !isReader && isSmallScreen}
          openMenu={openMenu}
          onCloseMenu={onCloseMenu}
        />

        <Flex
          gap={isGoldPage ? undefined : 6}
          grow={1}
          maxHeight={`calc(100dvh - ${NAVBAR_HEIGHT}px)`}
        >
          {!isStandalone &&
            (!isGoldPage && (isOverlayOpen || isSmallScreen) ? (
              <Modal
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                onOverlayClick={() => setIsSidebarOpen(false)}
                motionPreset="slideInLeft"
                trapFocus={false}
                variant="sidebar"
              >
                <ModalOverlay
                  pointerEvents={isOverlayOpen ? "none" : "auto"}
                  width={isOverlayOpen ? "260px" : "100%"}
                  height={`calc(100% - ${NAVBAR_HEIGHT}px)`}
                  marginTop={isOverlayOpen ? "0" : "65px"}
                  bg={isOverlayOpen ? "charcoal.charcoal" : "blackAlpha.50"}
                  onClick={() => setIsSidebarOpen(false)}
                />
                <ModalContent
                  onClick={() => setIsSidebarOpen(false)}
                  containerProps={{
                    pointerEvents: "none",
                  }}
                  height={`calc(100% - ${NAVBAR_HEIGHT}px)`}
                  p={0}
                  m={0}
                >
                  <Box
                    pointerEvents="all"
                    width={isOverlayOpen ? "100%" : "80vw"}
                    height="100%"
                    pl={isOverlayOpen ? 8 : 4}
                    pr={8}
                    overflowY="auto"
                    zIndex={2}
                    background={
                      !isSmallScreen && isOverlayOpen
                        ? "charcoal.charcoal"
                        : "black"
                    }
                  >
                    <SideBar />
                  </Box>
                </ModalContent>
              </Modal>
            ) : (
              <>
                <Box
                  display={isSidebarOpen && !isGoldPage ? "block" : "none"}
                  width={256}
                  ml={8}
                  pr={4}
                  overflowY="auto"
                >
                  <SideBar />
                </Box>
                <Box
                  width={100}
                  display={!isSidebarOpen && !isGoldPage ? "block" : "none"}
                  pl={8}
                  pr={7}
                  overflowY="auto"
                >
                  <SideBar collapsed />
                </Box>
              </>
            ))}

          <Flex
            flex={1}
            minWidth={0}
            sx={{
              WebkitOverflowScrolling: "touch",
            }}
          >
            <Suspense fallback={<Spinner color="blaze.blaze" />}>
              <Outlet />
            </Suspense>
          </Flex>
        </Flex>
        <Box zIndex={isGoldPage || isGiftCardPage ? 2 : undefined}>
          {showEmailVerificationBanner && !shouldHideBanners && (
            <EmailVerificationBanner emailAddress={userData.email_address} />
          )}
          {showOnboardingBanner &&
            !showEmailVerificationBanner &&
            !shouldHideBanners && <OnboardingBanner />}
        </Box>
      </Flex>
    </ErrorBoundary>
  );
}
