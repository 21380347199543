import { Icon, IconProps } from "@chakra-ui/react";

export function HomeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="home">
        <path
          id="home_2"
          d="M19 20.5H5C4.17 20.5 3.5 19.83 3.5 19V10.45C3.5 10.02 3.69 9.61002 4.01 9.32002L11.01 3.20002C11.57 2.71002 12.42 2.71002 12.99 3.20002L19.99 9.32002C20.32 9.60002 20.5 10.02 20.5 10.45V19C20.5 19.83 19.83 20.5 19 20.5ZM12 3.83002C11.88 3.83002 11.76 3.87002 11.67 3.95002L4.67 10.08C4.56 10.17 4.5 10.31 4.5 10.46V19.01C4.5 19.29 4.72 19.51 5 19.51H19C19.28 19.51 19.5 19.29 19.5 19.01V10.45C19.5 10.31 19.44 10.17 19.33 10.07L12.33 3.95002C12.24 3.87002 12.12 3.83002 12 3.83002Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
