import { useEffect, useState } from "react";

export function useIsSmallWindowHeight() {
  const heightBreakpoint = 1080;
  const [isSmallHeight, setIsSmallHeight] = useState(
    window.innerHeight < heightBreakpoint,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallHeight(window.innerHeight < heightBreakpoint);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightBreakpoint]);

  return isSmallHeight;
}
