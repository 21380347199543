export function replacePathAtIndex(
  pathname: string,
  index: number,
  value: string | number,
) {
  const pathArr = pathname.split("/");
  pathArr[index] = value + "";
  return pathArr.join("/");
}

export const buildReaderUrl = (
  path: string,
  uuid?: string,
  pageOrder?: number,
  fragmentOrder?: number,
) => {
  if (uuid) path = replacePathAtIndex(path, 2, uuid);
  if (pageOrder) path = replacePathAtIndex(path, 3, pageOrder);
  if (fragmentOrder) {
    path = replacePathAtIndex(path, 4, fragmentOrder);
  } else {
    let pathArr = path.split("/");
    pathArr.splice(4);
    path = pathArr.join("/");
  }

  return path;
};
