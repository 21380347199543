import { Box, Flex, Text, useToast, Grid, GridItem } from "@chakra-ui/react";
import { cloneElement, useEffect, useState } from "react";
import { useFunnel } from "../../components/funnel/hooks/useFunnel";
import {
  FUNNEL_TYPES_TYPES,
  FUNNEL_TYPES,
} from "../../components/funnel/utils/contants";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { useNavigate } from "react-router";
import { t } from "i18next";
import { publishViewedSubscriptionOfferEvent } from "../root/api/eventsApi";
import { FunnelContextArea } from "../../components/funnel/types";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";
import { useTitleOnNavigation } from "../../hooks/useTitleOnNavigation";
import { DealElement } from "./components/DealElement";
import { PagesThickIcon } from "../../components/icons/PagesThickIcon";
import { WifiOffThickIcon } from "../../components/icons/WifiOffThickIcon";
import { HeartThickIcon } from "../../components/icons/HeartThickIcon";
import { GiftThickIcon } from "../../components/icons/GiftThickIcon";
import { ListOfPopularComics } from "./components/ListOfPopularComics";
import { ListOfReviews } from "./components/ListOfReviews";
import { ListOfFeaturedPublishers } from "./components/ListOfPublishers";
import { rootComponentsStore } from "../../components/layouts/RootLayout";
import { DownloadOurApp } from "../../components/downloadOurApp/DownloadOurApp";

export function GoldScreen() {
  const [funnelType, setFunnelType] = useState<FUNNEL_TYPES_TYPES | undefined>(
    FUNNEL_TYPES.subscription,
  );

  useTitleOnNavigation(t("screens.gold.subscribeToGlobalComixGold"));

  const navigate = useNavigate();
  const createToast = useToast();
  const { funnelBody } = useFunnel(
    () => setFunnelType(undefined),
    (funnelConfig) => setFunnelType(funnelConfig.type),
    funnelType,
    {
      skipAuthentication: true,
      modalContext: "gold",
      onAuthenticated: () => {
        navigate("/");
        createToast({
          status: "success",
          description: t("screens.gold.goldSubscriptionActivated"),
          duration: 5000,
        });
      },
    },
  );

  const isSmallBreakpoint = useIsSmallBreakpoint();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.gold,
  });

  useEffect(() => {
    publishViewedSubscriptionOfferEvent(navigationContext);
    rootComponentsStore.eventContextReferrer.set(undefined);
  }, []);

  return (
    <Flex
      overflow="auto"
      height="100%"
      width="100%"
      position="relative"
      flexDirection="column"
      paddingTop="56px"
      alignItems="center"
      pr={isSmallBreakpoint ? undefined : "72px"}
      pb="50px"
    >
      <Flex
        pl={isSmallBreakpoint ? undefined : "72px"}
        flexDirection={"column"}
        zIndex={1}
        width="100%"
        maxWidth="1200px"
      >
        <Flex width="100%" paddingBottom="40px">
          {cloneElement(funnelBody, { horizontal: "true" })}
        </Flex>
        <Flex
          flexDirection="column"
          px={isSmallBreakpoint ? "28px" : "0px"}
          gap="48px"
        >
          <Flex flexDirection="column">
            <Text
              textAlign={isSmallBreakpoint ? "center" : "left"}
              variant="goldTitle"
              sx={{
                textWrap: "balance",
              }}
            >
              Enjoy the best deal in comics, ever!
            </Text>
            <Grid
              paddingTop="40px"
              templateColumns={`repeat(${isSmallBreakpoint ? 1 : 2}, 1fr)`}
              gap={isSmallBreakpoint ? "40px" : "38px"}
            >
              <GridItem>
                <DealElement
                  title="24/7 unlimited reading"
                  description="Get complete access to all comics, and read on any device for a low monthly fee."
                  icon={<PagesThickIcon boxSize="24px" color="blaze.blaze" />}
                />
              </GridItem>
              <GridItem>
                <DealElement
                  title="Read comics offline"
                  description="Download comics in the GlobalComix app and read comics without an internet connection."
                  icon={<WifiOffThickIcon boxSize="24px" color="blaze.blaze" />}
                />
              </GridItem>
              <GridItem>
                <DealElement
                  title="Support comic creators"
                  description="Up to 70% of your membership payment gets distributed among the books you read."
                  icon={<HeartThickIcon boxSize="24px" color="blaze.blaze" />}
                />
              </GridItem>
              <GridItem>
                <DealElement
                  title="Share stories with friends"
                  description="Get FREE access codes you can share with friends who does not have a Gold Membership."
                  icon={<GiftThickIcon boxSize="24px" color="blaze.blaze" />}
                />
              </GridItem>
            </Grid>
          </Flex>
          <ListOfPopularComics />
          <ListOfFeaturedPublishers />
          <ListOfReviews />
          <Box height="1px" width="100%" bg="transparent.white.20" />
          <DownloadOurApp />
        </Flex>
      </Flex>
      <Box
        position="absolute"
        top={0}
        zIndex={0}
        left={0}
        height="70%"
        width="100%"
        bgImage={`${window.gc.global.assets_url}/static/images/web-ui/goldBackground.jpeg`}
        bgRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backdropBlur="8px"
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "blaze.900",
          opacity: 0.87,
        }}
      >
        <Box
          position="absolute"
          bottom={0}
          height="50%"
          width="100%"
          background="linear-gradient(180deg, rgba(20, 21, 23, 0) 0%, #141517 100%)"
        />
      </Box>
    </Flex>
  );
}
