import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";

export function useReleaseCommentsData() {
  const releaseCommentsData = useHookstate(
    readerDataStore.releaseCommentsData,
  ).get({
    noproxy: true,
  });

  return { releaseCommentsData };
}
