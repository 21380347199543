import { Icon, IconProps } from "@chakra-ui/react";

export function GiftThickIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.05051C12.6353 2.40223 13.5207 2 14.5 2C16.433 2 18 3.567 18 5.5C18 6.0368 17.8792 6.54537 17.6632 7H20C21.1046 7 22 7.89543 22 9V11C22 11.7471 21.5904 12.3985 20.9835 12.7419C20.9944 12.8264 21 12.9125 21 13V19C21 20.1046 20.1046 21 19 21H12H5C3.89543 21 3 20.1046 3 19V13C3 12.9125 3.00562 12.8264 3.01651 12.7419C2.40961 12.3985 2 11.7471 2 11V9C2 7.89543 2.89543 7 4 7H6.33682C6.12085 6.54537 6 6.0368 6 5.5C6 3.567 7.567 2 9.5 2C10.4793 2 11.3647 2.40223 12 3.05051ZM13 5.5V7H14.5C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5ZM11 9H9.5H4V11H5H11V9ZM9.5 7H11V5.5C11 4.67157 10.3284 4 9.5 4C8.67157 4 8 4.67157 8 5.5C8 6.32843 8.67157 7 9.5 7ZM13 11V9H14.5H20V11H19H13ZM5 13V19H11V13H5ZM19 13V19H13V13H19Z"
        fill="currentColor"
      />
    </Icon>
  );
}
