import { Box, Button, Flex, Image, Text, Link, Spacer } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { Artist } from "../../../types";
import { CountryFlag } from "../../../components/cards/components/CountryFlag";
import { useToggleArtistFollow } from "../../../components/cards/hooks/useToggleArtistFollow";
import { Stats } from "../../reader/components/shared/Stats";
import { setFunnelModalConfig } from "../../../components/funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../../components/funnel/utils/contants";
import { ShareButton } from "../../../components/buttons/share/ShareButton";
import { FunnelContextArea } from "../../../components/funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { t } from "i18next";

interface ArtistProfileHeaderProps {
  artist: ImmutableObject<Artist>;
}

export function ArtistProfileHeader({ artist }: ArtistProfileHeaderProps) {
  const bannerUrl = artist?.banner_url;
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        gap={4}
        position="relative"
        bgImage={`url(${artist?.banner_url})`}
        bgSize="cover"
        bgPosition="center"
        pt="24px"
        pb="16px"
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgGradient: bannerUrl
            ? "linear(to-b, rgba(0, 0, 0, 0.5), charcoal.charcoal)"
            : undefined,
          zIndex: 0,
        }}
      >
        <Flex
          flexDirection="row"
          gap={4}
          zIndex={1}
          px="24px"
          style={{ scrollbarWidth: "thin" }}
        >
          <Image
            loading="lazy"
            src={artist.avatar_url}
            height="100%"
            boxSize="112px"
            position="relative"
            borderRadius="20px"
            border="1px solid"
            borderColor="transparent.white.10"
            boxShadow=" 0px 7px 8px 0px rgba(0, 0, 0, 0.3)"
            alt={`Cover image for ${artist?.roman_name}`}
          />
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
          >
            <ArtistProfileHeaderDetails artist={artist} />
            <ArtistProfileHeaderActions artist={artist} />
          </Flex>
        </Flex>
      </Flex>
      <Box
        bgGradient="linear(to-b, rgba(0, 0, 0, 0) 20%, charcoal.charcoal) 80%"
        px="24px"
        zIndex={1}
      >
        <Stats
          stats={[
            { value: artist.total_favorites, text: "followers" },
            { value: artist.total_projects, text: "comics" },
            { value: artist.total_releases, text: "releases" },
          ]}
          width="100%"
          bg="transparent.white.10"
        />
      </Box>
    </Flex>
  );
}

export function ArtistProfileHeaderDetails({
  artist,
}: {
  artist: ImmutableObject<Artist>;
}) {
  return (
    <Flex flexDirection="column">
      <Link href={artist.url}>
        <Text
          fontSize="18px"
          fontWeight={700}
          color="neutral.200"
          lineHeight="120%"
        >
          {artist.roman_name}
        </Text>
      </Link>
      <Flex direction="row" alignItems="center" gap={2}>
        <CountryFlag countryId={artist.country_id} />
        <Text
          fontSize="14px"
          fontWeight={700}
          color="neutral.400"
          noOfLines={1}
        >
          {artist.type_display}
        </Text>
      </Flex>
    </Flex>
  );
}

export function ArtistProfileHeaderActions({
  artist,
}: {
  artist: ImmutableObject<Artist>;
}) {
  const cacheKey = `${artist?.entity_type}-${artist?.id}`;
  const handleToggleArtistFollow = useToggleArtistFollow(cacheKey);
  const isFollowing = artist.user_is_following;

  const donateNavigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.donate,
    artistId: artist.id,
  });

  return (
    <Flex flexDirection="row" gap="8px">
      <Button
        onClick={handleToggleArtistFollow}
        isActive={isFollowing}
        variant={isFollowing ? "tertiary" : "secondary"}
        fontSize="12px"
        px="16px"
        height="26px"
        sx={{
          _active: {
            borderColor: "transparent.white.40",
          },
        }}
      >
        {isFollowing ? "Following" : "Follow"}
      </Button>
      <Button
        onClick={() => {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.donate,
            options: {
              modalContext: FunnelContextArea.donate,
              donationArtistId: artist.id,
              navigationContext: donateNavigationContext,
            },
          });
        }}
        variant="secondary"
        fontSize="12px"
        px="16px"
        height="26px"
      >
        {t("screens.artist.donate")}
      </Button>

      <Spacer />
      <ShareButton
        shareUrl={artist.share_url}
        title="Share"
        color="neutral.300"
      />
    </Flex>
  );
}
