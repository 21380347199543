import { ImmutableArray, ImmutableObject } from "@hookstate/core";
import { appStore } from "../../../appStore";
import { ComicFacetEntity } from "../../../types";
import {
  ComicFacetGroupEntity,
  ComicFacetGroupOptionEntity,
} from "../../../services/gc/types";

export const extractComicFacetOptionsByGroupId = (
  comicFacets: ComicFacetEntity[],
  comicFacetGroupId: number,
) => {
  if (
    comicFacets.find(
      (facet) => facet.comic_facet_group_id === comicFacetGroupId,
    )
  ) {
    return comicFacets[comicFacetGroupId];
  }
  return null;
};

export const ID_AUDIENCE = 1;
export const ID_THEME = 2;
export const ID_TIME = 3;
export const ID_CONTENT_RATING = 4;
export const ID_ART_STYLE = 5;
export const ID_GC_INTERNAL = 6;

export const mapFacets = (comicFacets: ImmutableObject<ComicFacetEntity[]>) => {
  const { comic_facet_groups } = appStore.init.get();

  const comicFacetMap: {
    [key: number]: ImmutableObject<ComicFacetEntity>[];
  } = {};
  comicFacets.forEach((facet) => {
    if (!comicFacetMap[facet.comic_facet_group_id]) {
      comicFacetMap[facet.comic_facet_group_id] = [facet];
    } else {
      comicFacetMap[facet.comic_facet_group_id].push(facet);
    }
  });

  const comicFacetGroupMap: {
    [key: string]: ImmutableObject<ComicFacetGroupEntity>;
  } = {};
  comic_facet_groups.forEach((facetGroup) => {
    if (facetGroup.id !== ID_GC_INTERNAL && facetGroup) {
      comicFacetGroupMap[facetGroup.id] = facetGroup;
    }
  });

  return { comicFacetMap, comicFacetGroupMap };
};

export function getFacetsByType(
  comicFacets: ImmutableArray<ComicFacetEntity> | undefined,
) {
  if (!comicFacets) return [];

  const { comicFacetGroupMap, comicFacetMap } = mapFacets(comicFacets);

  const allFacets: {
    [key: ComicFacetGroupEntity["display_name"]]: {
      facetGroup: ImmutableObject<ComicFacetGroupEntity>;
      facets: ImmutableObject<ComicFacetGroupOptionEntity>[];
    };
  } = {};
  Object.values(comicFacetMap).forEach((facetsArr) => {
    const facets: ImmutableObject<ComicFacetGroupOptionEntity>[] = [];
    facetsArr.forEach((facet) => {
      const activeFacet = comicFacetGroupMap[
        facet.comic_facet_group_id
      ]?.comic_facet_group_options.find(
        (option) => option.id === facet.comic_facet_group_option_id,
      );
      if (activeFacet) facets.push(activeFacet);
    });
    if (facets.length) {
      const activeFacetGroup =
        comicFacetGroupMap[facets[0].comic_facet_group_id];
      allFacets[activeFacetGroup.display_name] = {
        facetGroup: activeFacetGroup,
        facets,
      };
    }
  });
  return allFacets;
}

export function getAudienceShort(
  comicFacets: ImmutableArray<ComicFacetEntity> | undefined,
) {
  if (!comicFacets?.length) return "All";

  const { comicFacetMap, comicFacetGroupMap } = mapFacets(comicFacets);

  const facetGroup = comicFacetGroupMap[ID_AUDIENCE];

  const audiences: (
    | ImmutableObject<ComicFacetGroupOptionEntity>
    | undefined
  )[] = [];
  comicFacetMap[ID_AUDIENCE]?.forEach((facet) => {
    audiences.push(
      facetGroup?.comic_facet_group_options?.find(
        (option) => option.id === facet.comic_facet_group_option_id,
      ),
    );
  });

  if (audiences.length) {
    switch (audiences[0]?.name) {
      case "Teenagers":
        return "13+";

      case "Adults":
        return "17+";

      default:
        return "All";
    }
  }

  return "All";
}
