import { Icon, IconProps } from "@chakra-ui/react";

export function LockIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.66732C12.7113 4.66732 11.6667 5.71199 11.6667 7.00065V9.33399L16.3333 9.33398V7.00065C16.3333 5.71199 15.2887 4.66732 14 4.66732ZM9.33333 7.00065L9.33333 9.33399L5.83333 9.33399C4.54467 9.33399 3.5 10.3787 3.5 11.6673V23.334C3.5 24.6227 4.54467 25.6673 5.83334 25.6673H22.1667C23.4553 25.6673 24.5 24.6227 24.5 23.334L24.5 11.6673C24.5 10.3787 23.4553 9.33398 22.1667 9.33398L18.6667 9.33399L18.6667 7.00065C18.6667 4.42332 16.5773 2.33398 14 2.33398C11.4227 2.33398 9.33333 4.42332 9.33333 7.00065ZM5.83333 11.6673H10.7683H10.7692L17.2308 11.6673H22.1667V23.334L5.83333 23.334L5.83333 11.6673ZM15.1667 16.334C15.1667 15.6897 14.6443 15.1673 14 15.1673C13.3557 15.1673 12.8333 15.6897 12.8333 16.334V18.6673C12.8333 19.3117 13.3557 19.834 14 19.834C14.6443 19.834 15.1667 19.3117 15.1667 18.6673V16.334Z"
        fill="currentColor"
      />
    </Icon>
  );
}
