import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Artist } from "../../../types";
import { postToggleFollow } from "../../../api/artists";
import { useUserData } from "../../../hooks/useUserData";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea } from "../../funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { t } from "i18next";
import { useAccountData } from "../../../hooks/useAccountData";
import { GET_ACCOUNT_ME_QUERY_KEY } from "../../auth/api/authApi";

export function useToggleArtistFollow(cacheKey: string) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const accountData = useAccountData();
  const { data } = useQuery<Artist>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const updateArtistFavorites = useCallback(
    async (artistFavorites: number[]) => {
      await queryClient.setQueryData([GET_ACCOUNT_ME_QUERY_KEY], () => {
        return {
          data: {
            payload: {
              results: {
                ...accountData,
                artist_favorites: artistFavorites,
              },
            },
          },
        };
      });
    },
    [accountData],
  );

  const { mutate } = useMutation({
    mutationFn: () => {
      return postToggleFollow(data?.id ?? 0, data?.user_is_following);
    },
    onMutate: () => {
      if (data) {
        if (!data?.user_is_following) {
          updateArtistFavorites([
            ...(accountData?.artist_favorites ?? []),
            data.id,
          ]);
        } else {
          updateArtistFavorites(
            (accountData?.artist_favorites ?? []).filter(
              (id) => id !== data.id,
            ),
          );
        }
      }
      queryClient.setQueryData([cacheKey], {
        ...data,
        user_is_following: !data?.user_is_following,
      });

      toast({
        status: "success",
        title: `${data?.user_is_following ? "Unfollowed" : "Following"} ${data?.roman_name}`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        title: `Unable to ${data?.user_is_following ? "unfollow" : "follow"} ${data?.roman_name}`,
      });
    },
  });

  const { userData } = useUserData();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.followArtist,
    artistId: data?.id,
  });
  const handleToggleArtistFollow = useCallback(() => {
    if (!userData) {
      setFunnelModalConfig({
        options: {
          onAuthenticated: () => mutate(),
          modalContext: FunnelContextArea.followArtist,
          welcomeText: t("components.auth.welcomeText.getNotified", {
            artist: data?.roman_name,
          }),
          navigationContext,
        },
        type: FUNNEL_TYPES.signup,
      });
    } else {
      mutate();
    }
  }, [data, userData]);

  return handleToggleArtistFollow;
}
