import { Icon, IconProps } from "@chakra-ui/react";

export function FacebookIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M22 12C22 6.4772 17.5228 2 12 2C6.4772 2 2 6.4772 2 12C2 16.6896 5.2288 20.6248 9.5844 21.7056V15.056H7.5224V12H9.5844V10.6832C9.5844 7.2796 11.1248 5.702 14.4664 5.702C15.1 5.702 16.1932 5.8264 16.6404 5.9504V8.7204C16.4044 8.6956 15.9944 8.6832 15.4852 8.6832C13.8456 8.6832 13.212 9.3044 13.212 10.9192V12H16.4784L15.9172 15.056H13.212V21.9268C18.1636 21.3288 22.0004 17.1128 22.0004 12H22Z"
        fill="#0866FF"
      />
      <path
        d="M15.9168 15.0571L16.478 12.0011H13.2116V10.9203C13.2116 9.30552 13.8452 8.68432 15.4848 8.68432C15.994 8.68432 16.404 8.69672 16.64 8.72152V5.95152C16.1928 5.82712 15.0996 5.70312 14.466 5.70312C11.1244 5.70312 9.58397 7.28073 9.58397 10.6843V12.0011H7.52197V15.0571H9.58397V21.7067C10.3576 21.8987 11.1668 22.0011 11.9996 22.0011C12.4096 22.0011 12.814 21.9759 13.2112 21.9279V15.0571H15.9164H15.9168Z"
        fill="white"
      />
    </Icon>
  );
}
