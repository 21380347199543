import { Icon, IconProps } from "@chakra-ui/react";

export function CaretDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64645 8.64645C4.84171 8.45118 5.15829 8.45118 5.35355 8.64645L12 15.2929L18.6464 8.64645C18.8417 8.45118 19.1583 8.45118 19.3536 8.64645C19.5488 8.84171 19.5488 9.15829 19.3536 9.35355L12.3536 16.3536C12.2598 16.4473 12.1326 16.5 12 16.5C11.8674 16.5 11.7402 16.4473 11.6464 16.3536L4.64645 9.35355C4.45118 9.15829 4.45118 8.84171 4.64645 8.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
