import { Box, BoxProps, Text, TextProps } from "@chakra-ui/react";

export function Tag({
  text,
  boxProps = {},
  textProps = {},
}: {
  text: string;
  textProps?: TextProps;
  boxProps?: BoxProps;
}) {
  return (
    <Box
      bg="transparent.white.10"
      borderRadius="full"
      py="8px"
      px="12px"
      {...boxProps}
    >
      <Text
        fontFamily="Roboto"
        fontSize="12px"
        fontWeight="500"
        lineHeight="14.06px"
        letterSpacing="0.5px"
        color="dune.700"
        {...textProps}
      >
        {text}
      </Text>
    </Box>
  );
}
