export const navigation = {
  me: {
    viewProfile: "View profile",
    accountSettings: "Account settings",
    lists: "Lists",
    promoCodes: "Promo codes",
    downloads: "Downloads",
    subscriptions: "Subscriptions",
    siteAdministration: "Site administration",
    submitFeedback: "Submit feedback",
    reportABug: "Report bug",
    pageSettings: "Page settings",
    analytics: "Analytics",
    manageSeries: "Manage series",
    viewCreatorProfile: "View creator profile",
    logout: "Log out",
    interests: "Interests",
    purchases: "Purchases",
    baf: "Gift 1 Week of Free Gold Membership",
    bafSubtitle: "{{remaining}}/{{total}} uses remaining, expires: {{expires}}",
    bafToast: "Gift code copied to clipboard!",
  },
  notifications: {
    markAllRead: "Mark all as read",
    viewAll: "View all",
    title: "Notifications",
    empty: "No notifications",
  },
  conversations: {
    title: "Messages",
    markAllRead: "Mark all read",
    markRead: "Mark read",
    newMessage: "New message",
    viewAll: "View all",
    empty: "No messages",
  },
  auth: {
    signUpItsFree: "Sign up - it's free",
    login: "Log in",
    logoutFailed: "Logout failed, please try again",
  },
  footer: {
    about: "About",
    contactUs: "Contact us",
    contentPolicy: "Content policy",
    publisherSLA: "Publisher SLA",
    privacyPolicy: "Privacy policy",
    faq: "FAQ",
    termsOfUse: "Terms of use",
    publishingTermsOfService: "Publishing terms of service",
  },
};
