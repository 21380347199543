import { useState } from "react";

const useValidatedAmount = (
  onValidAmountChange: (value: number | undefined) => void,
  initialValue = "",
  maxDecimals = 5,
) => {
  // Initialize value with initial value
  const [value, setValue] = useState<string>(initialValue);

  const handleValueChange = (newValue: string) => {
    /// Allows only numbers, 1 comma/dot as decimal separator and maxDecimals decimal places
    const isValid = new RegExp(`^(\\d+([.,]\\d{0,${maxDecimals}})?)?$`).test(
      newValue,
    );

    if (isValid) {
      setValue(newValue);

      const parsedValue = parseFloat(newValue.replace(",", "."));
      if (!isNaN(parsedValue)) {
        onValidAmountChange(parsedValue);
      } else {
        onValidAmountChange(undefined);
      }
    }
  };

  return {
    value,
    setValue: handleValueChange,
  };
};

export default useValidatedAmount;
