import {
  Button,
  ButtonProps,
  Popover,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import { ShareIcon } from "../../icons/ShareIcon";
import { forwardRef } from "react";
import { SharePopover } from "./SharePopover";

interface ShareButtonProps {
  shareUrl: string;
  title: string;
  color?: string;
}

export function ShareButton({ shareUrl, title, color }: ShareButtonProps) {
  return (
    <Popover placement="top-start">
      <PopoverTrigger>
        <ShareButtonTrigger color={color} />
      </PopoverTrigger>
      <Portal>
        <SharePopover shareUrl={shareUrl} title={title} />
      </Portal>
    </Popover>
  );
}

const ShareButtonTrigger = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="ghostIcon"
        size="thin"
        color={"info.200"}
        _hover={{
          backgroundColor: "transparent",
          color: "info.400",
        }}
        {...props}
        onClick={(e) => {
          props.onClick?.(e);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ShareIcon w={5.5} h={5.5} />
      </Button>
    );
  },
);
