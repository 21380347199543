import { Route } from "react-router-dom";
import { LegalScreen } from "./LegalScreen";

export const routes = (
  <>
    <Route path="privacy-policy" Component={LegalScreen} />
    <Route path="terms-of-use" Component={LegalScreen} />
    <Route path="content-policy" Component={LegalScreen} />
    <Route path="publisher-terms-of-service" Component={LegalScreen} />
  </>
);
