import { Box, Flex } from "@chakra-ui/react";
import { useReadingDirection } from "../hooks/useReadingDirection";
import { HorizontalReader } from "./horizontalReading/HorizontalReader";
import { READING_DIRECTION_TYPES } from "../constants";
import { VerticalReader } from "./verticalReading/VerticalReader";
import { GCLogo } from "./GCLogo";
import { NSFWInterstitial } from "./NSFWInterstitial";
import { DirectionIndicator } from "./DirectionIndicator";
import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../ReaderStateWrapper";
import { useReleasePagesData } from "../hooks/hookstate/useReleasePagesData";
import { useIsOverlayOpen } from "../hooks/hookstate/useIsOverlayOpen";
import { t } from "i18next";
import { SomethingWentWrong } from "../../../components/errors/components/SomethingWentWrong";
import { memo } from "react";
import { useReaderLoaded } from "../hooks/useReaderLoaded";
import { useMobileBreakpoint } from "../../../utils/useBreakpoints";

interface ReaderReleasePageProps {}
export const ReaderReleasePages = memo(({}: ReaderReleasePageProps) => {
  const { readingDirection, comicIsVertical } = useReadingDirection();
  const { releasePagesData } = useReleasePagesData();
  const responseError = useHookstate(readerDataStore.responseError).get();
  const { toggleIsOverlayOpen } = useIsOverlayOpen();
  const isMobile = useMobileBreakpoint();
  const { loaded, timedOut } = useReaderLoaded();

  if (responseError || timedOut) {
    return (
      <Flex onClick={toggleIsOverlayOpen} width="full" zIndex={1}>
        <SomethingWentWrong
          text={t("screens.reader.loading.releaseNotFound")}
        />
      </Flex>
    );
  }

  return (
    <>
      {!loaded ? (
        <GCLogo />
      ) : (
        <Box
          margin="auto"
          id="readerReleasePages"
          height="fit-content"
          padding={isMobile ? "0" : "10px"}
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={2}
          overflow="auto"
          sx={{
            backfaceVisibility: "hidden",
          }}
        >
          {readingDirection === READING_DIRECTION_TYPES.vertical ? (
            <VerticalReader
              releasePagesData={releasePagesData}
              comicIsVertical={comicIsVertical}
            />
          ) : (
            <HorizontalReader
              releasePagesData={releasePagesData}
              readingDirection={readingDirection}
            />
          )}
          <DirectionIndicator readingDirection={readingDirection} />
          <NSFWInterstitial />
        </Box>
      )}
    </>
  );
});
