import { Box, Button, Text, Flex } from "@chakra-ui/react";
import { RadioOffIcon } from "../../icons/RadioOffIcon";
import { RadioOnIcon } from "../../icons/RadioOnIcon";
import { SubscriptionPriceTypes } from "../types";
import { memo } from "react";

export const SubscriptionSelector = memo(
  ({
    subscriptionPrice,
    active,
    onClick,
    isSmall,
  }: {
    subscriptionPrice?: SubscriptionPriceTypes;
    active?: boolean;
    onClick: (type: SubscriptionPriceTypes["billing_frequency"]) => void;
    isSmall?: boolean;
  }) => {

    const hasPriceFrequency = !!subscriptionPrice?.price_string?.split("/")[1];
    return (
      <Box position="relative">
        <Button
          onClick={
            subscriptionPrice?.billing_frequency
              ? () => onClick(subscriptionPrice?.billing_frequency)
              : undefined
          }
          bg="transparent"
          p={isSmall ? "16px" : "32px"}
          gap="8px"
          borderRadius="20px"
          border="2px solid"
          borderColor="transparent.white.30"
          isActive={active}
          _active={{
            borderColor: "blaze.blaze",
            boxShadow: "0px 0px 12px 1px rgba(255, 90, 44, 0.90)",
          }}
          width="100%"
          maxWidth="100%"
          height="fit-content"
        >
          <Flex gap="16px" alignItems="center" width="100%" minWidth="0">
            {active ? (
              <RadioOnIcon
                boxSize={isSmall ? "24px" : "36px"}
                color="blaze.blaze"
                stroke="blaze.blaze"
                strokeWidth="1px"
              />
            ) : (
              <RadioOffIcon
                boxSize={isSmall ? "24px" : "36px"}
                color="dune.600"
                stroke="dune.600"
                strokeWidth="1px"
              />
            )}
            <Flex flexDirection="column" textAlign="left" width="100%">
              <Text
                variant="title"
                textTransform="capitalize"
                color="dune.dune"
                fontSize={isSmall ? "18px" : "20px"}
                fontWeight="600"
                lineHeight="130%"
                textOverflow="ellipsis"
                width="100%"
              >
                {subscriptionPrice?.tier_name}
              </Text>
              {subscriptionPrice?.renewal_context && (
                <Text
                  fontFamily={isSmall ? "16px" : "14px"}
                  fontWeight="500"
                  lineHeight="140%"
                  letterSpacing=".5px"
                  color="neutral.400"
                  whiteSpace="pre-wrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  width="100%"
                >
                  {subscriptionPrice?.renewal_context}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Flex>
              <Text
                fontSize={isSmall ? "18px" : "28px"}
                fontWeight="700"
                lineHeight={isSmall ? undefined : "32px"}
                letterSpacing="-0.5px"
                color="dune.dune"
              >
                {subscriptionPrice?.price_string?.split("/")[0]}
              </Text>
              <Text
                fontSize={isSmall ? "12px" : "16px"}
                fontWeight="700"
                lineHeight="150%"
                alignSelf="flex-end"
                color="dune.dune"
              >
                {hasPriceFrequency ? "/" : ""}{subscriptionPrice?.price_string?.split("/")[1]}
              </Text>
            </Flex>
            {subscriptionPrice?.discount_string && (
              <Text
                color="blaze.600"
                fontSize={isSmall ? "12px" : "14px"}
                fontWeight="700"
                letterSpacing=".5px"
                lineHeight="140%"
                textDecoration="line-through"
                textAlign="right"
              >
                {subscriptionPrice?.discount_price_string}
              </Text>
            )}
          </Flex>
        </Button>
        {subscriptionPrice?.discount_string && (
          <Box
            position="absolute"
            bg="blaze.600"
            right="32px"
            top="-14px"
            py={isSmall ? "6px" : "8px"}
            px={isSmall ? "12px" : "16px"}
            borderRadius="100px"
          >
            <Text
              fontSize={isSmall ? "10px" : "12px"}
              fontWeight="700"
              lineHeight="11px"
              letterSpacing="1px"
              textTransform="uppercase"
            >
              {subscriptionPrice?.discount_string}
            </Text>
          </Box>
        )}
      </Box>
    );
  },
);
