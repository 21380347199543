import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Text, Button } from "@chakra-ui/react";
import { t } from "i18next";
import { FunnelComponentProps } from "../../funnel/types";

export function DonationModalHeader({ onClose }: FunnelComponentProps) {
  return (
    <Flex flexDirection="row" justifyContent="space-between" width="100%">
      <Text variant="title" fontSize="20px" color="dune.dune" fontWeight="600">
        {t("components.payments.donate.donateToCreator")}
      </Text>
      <Button
        alignSelf="center"
        variant="iconOnly"
        onClick={onClose}
        minWidth={0}
      >
        <CloseIcon alignSelf="center" color="dune.100" />
      </Button>
    </Flex>
  );
}
