import { Icon, IconProps } from "@chakra-ui/react";

export function FollowingIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 8C5.5 6.067 7.067 4.5 9 4.5C10.933 4.5 12.5 6.067 12.5 8C12.5 9.933 10.933 11.5 9 11.5C7.067 11.5 5.5 9.933 5.5 8ZM9 3.5C6.51472 3.5 4.5 5.51472 4.5 8C4.5 10.4853 6.51472 12.5 9 12.5C11.4853 12.5 13.5 10.4853 13.5 8C13.5 5.51472 11.4853 3.5 9 3.5ZM21.3536 9.64645C21.5488 9.84171 21.5488 10.1583 21.3536 10.3536L17.3536 14.3536C17.1583 14.5488 16.8417 14.5488 16.6464 14.3536L14.6464 12.3536C14.4512 12.1583 14.4512 11.8417 14.6464 11.6464C14.8417 11.4512 15.1583 11.4512 15.3536 11.6464L17 13.2929L20.6464 9.64645C20.8417 9.45118 21.1583 9.45118 21.3536 9.64645ZM5.03471 16.5876C4.03831 17.2519 3.5 18.1146 3.5 19C3.5 19.2761 3.27614 19.5 3 19.5C2.72386 19.5 2.5 19.2761 2.5 19C2.5 17.6762 3.30483 16.539 4.48001 15.7555C5.65868 14.9698 7.2582 14.5 9 14.5C10.7418 14.5 12.3413 14.9698 13.52 15.7555C14.6952 16.539 15.5 17.6762 15.5 19C15.5 19.2761 15.2761 19.5 15 19.5C14.7239 19.5 14.5 19.2761 14.5 19C14.5 18.1146 13.9617 17.2519 12.9653 16.5876C11.9724 15.9257 10.5719 15.5 9 15.5C7.42809 15.5 6.02761 15.9257 5.03471 16.5876Z"
        fill="currentColor"
      />
    </Icon>
  );
}
