import { Flex, Text, Image, Box, Button } from "@chakra-ui/react";
import { appStore } from "../../appStore";
import { useHookstate } from "@hookstate/core";
import { AddedBubbleIcon } from "../icons/AddedBubbleIcon";
import { AddBubbleIcon } from "../icons/AddBubbleIcon";
import { ToggleInterestType } from "./types";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

export function ComicTypesStep({
  activeIds,
  toggleInterest,
}: {
  activeIds: number[];
  toggleInterest: ToggleInterestType;
}) {
  const isSmall = useIsSmallBreakpoint();
  const comicTypes = useHookstate(appStore.init.comic_types).get();

  return (
    <Flex
      flexDirection={isSmall ? "column" : "row"}
      gap="16px"
      justifyContent="center"
      width="100%"
    >
      {comicTypes.map((comicType, i) => (
        <Flex
          key={comicType.id}
          id={`btnComicType-${comicType.id}`}
          flex={isSmall ? undefined : 1}
        >
          {isSmall ? (
            <ComicTypeCardSmall
              key={comicType.id}
              index={i}
              title={comicType.plural_name}
              isSelected={activeIds.findIndex((id) => id === comicType.id) > -1}
              onClick={() =>
                toggleInterest({ type: "comic_types", id: comicType.id })
              }
            />
          ) : (
            <ComicTypeCard
              key={comicType.id}
              index={i}
              title={comicType.plural_name}
              isSelected={activeIds.findIndex((id) => id === comicType.id) > -1}
              onClick={() =>
                toggleInterest({ type: "comic_types", id: comicType.id })
              }
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
}

export interface ComicTypeCardProps {
  index: number;
  title: string;
  isSelected?: boolean;
  onClick: () => void;
}
export function ComicTypeCardSmall({
  index,
  title,
  isSelected,
  onClick,
}: ComicTypeCardProps) {
  return (
    <Button
      height="122px"
      width="100%"
      gap="16px"
      background="transparent.black.40"
      borderRadius="24px"
      border="1px solid"
      borderColor={isSelected ? "success.300" : "transparent.white.10"}
      padding="16px 24px"
      userSelect="none"
      onClick={onClick}
      boxShadow="0px 6px 52px 0px rgba(0, 0, 0, 0.45)"
      justifyContent="space-between"
    >
      <Flex flex="1" position="relative" width="100%" height="93px">
        <Image
          width="54px"
          height="83px"
          src={coverImagesMap[index][0]}
          border="1px solid"
          borderColor="transparent.white.10"
          borderRadius="6px"
        />
        <Image
          position="absolute"
          left="40px"
          top="8px"
          width="54px"
          height="83px"
          src={coverImagesMap[index][1]}
          border="1px solid"
          borderColor="transparent.white.10"
          borderRadius="6px"
        />
      </Flex>
      <Flex
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box>
          {isSelected ? (
            <AddedBubbleIcon boxSize="40px" color="success.300" />
          ) : (
            <AddBubbleIcon boxSize="40px" color="neutral.500" />
          )}
        </Box>
        <Text
          fontSize="20px"
          fontWeight="600"
          color="neutral.300"
          fontFamily="Bricolage Grotesque"
          lineHeight="100%"
          letterSpacing="-.5px"
          alignSelf="flex-start"
        >
          {title}
        </Text>
      </Flex>
    </Button>
  );
}

const coverImagesMap: string[][] = [
  [
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/59994_4db9acdcbfa9ab26e0acb2b772b95683_large.jpg",
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/45251_4c3c6acde449d9404dcc8486e8bb6f5d_large.jpg",
  ],
  [
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/58358_f109edf6ff4fce0c45ca8ef332feb0c6_large.jpg",
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/58352_6e09a1301bbd2cec9e0650eda1567d63_large.jpg",
  ],
  [
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/56786_da44b62eb4df8c04a052b26b4a9dc4da_large.jpg",
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/22750_5f57487ff112c970e55e215f56e177d2_large.jpg",
  ],
  [
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/55797_8718bd9f5529a921296fd52cabe70c3e_large.jpg",
    "https://globalcomix.com/img/processed/comic-cover/5300f30389dceae746a08ab8399db66b/57930_f8d4a79a8a8a5264cd4c42127b28c5bc_large.jpg",
  ],
];

export function ComicTypeCard({
  index,
  title,
  isSelected = false,
  onClick,
}: ComicTypeCardProps) {
  return (
    <Button
      flexDirection="column"
      height={"280px"}
      flex={1}
      gap="16px"
      background="transparent.black.40"
      borderRadius="24px"
      border="1px solid"
      borderColor={isSelected ? "success.300" : "transparent.white.10"}
      padding="16px 24px"
      userSelect="none"
      onClick={onClick}
      boxShadow="0px 6px 52px 0px rgba(0, 0, 0, 0.45)"
    >
      <Flex flex="1" position="relative" width="100%">
        <Box position="absolute" top="0" right="0">
          {isSelected ? (
            <AddedBubbleIcon boxSize="40px" color="success.300" />
          ) : (
            <AddBubbleIcon boxSize="40px" color="neutral.500" />
          )}
        </Box>
        <Image
          position="absolute"
          top="0"
          left="0"
          width="68px"
          src={coverImagesMap[index][0]}
          border="1px solid"
          borderColor="transparent.white.10"
          borderRadius="6px"
          boxShadow="0px 3.206px 16.028px 0px rgba(0, 0, 0, 0.45)"
        />
        <Image
          position="absolute"
          top="25%"
          left="25%"
          width="68px"
          src={coverImagesMap[index][1]}
          border="1px solid"
          borderColor="transparent.white.10"
          borderRadius="6px"
          boxShadow="0px 3.206px 16.028px 0px rgba(0, 0, 0, 0.45)"
        />
      </Flex>
      <Text
        fontSize="20px"
        fontWeight="600"
        color="neutral.300"
        fontFamily="Bricolage Grotesque"
        lineHeight="100%"
        letterSpacing="-.5px"
        alignSelf="flex-start"
      >
        {title}
      </Text>
    </Button>
  );
}
