import { Box, Button } from "@chakra-ui/react";

interface SsoButtonProps {
  id?: string;
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  isSmall?: boolean;
}

export function SsoButton({ id, icon, onClick, isLoading }: SsoButtonProps) {
  return (
    <Button
      id={id}
      border="1px solid var(--chakra-colors-transparent-white-20)"
      background={"transparent"}
      height="64px"
      isLoading={isLoading}
      _hover={{
        background: "transparent.white.10",
      }}
      _pressed={{
        background: "transparent.white.20",
      }}
      width="full"
      onClick={onClick}
    >
      <Box position="absolute" top="50%" transform="translateY(-50%)">
        {icon}
      </Box>
    </Button>
  );
}
