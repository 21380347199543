import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  forwardRef,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slide,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { FilterIcon } from "../../../../components/icons/FilterIcon";
import { getArtists, getComics } from "../../api/browseScreenApi";
import { BrowseFilters, browseFilterStore } from "./BrowseFilters";
import { useBrowseFilterState } from "../../hooks/useBrowseFiltersState";
import { isSafari } from "react-device-detect";

interface BrowseFiltersModalProps extends UseDisclosureProps {
  clearAll: () => void;
  // 0 for comics and 1 for creators
  type: 0 | 1;
  small?: boolean;
}
export function BrowseFiltersModal({
  clearAll,
  isOpen,
  onOpen,
  onClose,
  type,
  small,
}: BrowseFiltersModalProps) {
  return (
    <>
      {small ? (
        <SmallFilterModalTrigger onClick={onOpen} />
      ) : (
        <FilterTagButton
          alt={true}
          onClick={onOpen}
          isActive={isOpen}
          icon={<FilterIcon />}
          text="All Filters"
        />
      )}
      <FilterModal
        isOpen={isOpen}
        onClose={onClose}
        clearAll={clearAll}
        small={small}
        type={type}
      />
    </>
  );
}

export const SmallFilterModalTrigger = forwardRef((props, ref) => (
  <Button
    borderRadius="20px"
    background="transparent.black.70"
    backdropFilter="blur(18px)"
    border="1px solid"
    borderColor="transparent.white.10"
    boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
    height="48px"
    justifyContent="center"
    alignItems="center"
    px="16px"
    py="14px"
    ref={ref}
    aria-label="All Filters Modal"
    {...props}
  >
    <FilterIcon width="20px" height="20px" color="dune.dune" />
  </Button>
));

interface FilterModalProps {
  isOpen: boolean | undefined;
  onClose: (() => void) | undefined;
  clearAll: () => void;
  small?: boolean;
  type: 0 | 1;
}
function FilterModal({
  isOpen,
  onClose,
  clearAll,
  small,
  type,
}: FilterModalProps) {
  const closeAndReset = async () => {
    browseFilterStore.rollback("modal");
    onClose?.();
  };

  return (
    <Modal
      variant="filters"
      isOpen={!!isOpen}
      onClose={closeAndReset}
      isCentered={!small}
    >
      <ModalOverlay />
      {isOpen &&
        (small ? (
          <Slide
            in={isOpen}
            direction="bottom"
            style={{ width: "100%", height: "100%", zIndex: 1409 }}
          >
            <ModalContentContent
              small={!!small}
              close={onClose}
              closeAndReset={closeAndReset}
              clearAll={clearAll}
              type={type}
            />
          </Slide>
        ) : (
          <ModalContentContent
            small={!!small}
            close={onClose}
            closeAndReset={closeAndReset}
            clearAll={clearAll}
            type={type}
          />
        ))}
    </Modal>
  );
}

interface ModalContentContentProps {
  small: boolean;
  clearAll: () => void;
  close?: () => void;
  closeAndReset: () => void;
  type: 0 | 1;
}
function ModalContentContent({
  small,
  close,
  closeAndReset,
  clearAll,
  type,
}: ModalContentContentProps) {
  return (
    <ModalContent
      containerProps={{
        alignItems: small ? "flex-end" : undefined,
      }}
      width={small ? "100vh" : undefined}
      margin={small ? 0 : undefined}
      minHeight={undefined}
      maxHeight={isSafari ? "90vh" : "100vh"}
      maxWidth="none"
      borderBottomRadius={small ? "none" : undefined}
      border="1px solid"
      borderColor="transparent.white.10"
    >
      <ModalHeader>
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          Filters
          <Button
            variant="ghostIcon"
            onClick={closeAndReset}
            color="dune.600"
            _hover={{ color: "dune.100" }}
          >
            <CloseIcon w="16px" h="16px" />
          </Button>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Flex w="full">
          <BrowseFilters isModal />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="ghostIcon"
          fontSize="xs"
          color="dune.600"
          _hover={{ color: "dune.100" }}
          fontWeight="500"
          lineHeight="150%"
          letterSpacing=".25px"
          onClick={clearAll}
        >
          Clear all
        </Button>
        <ShowResultsButton type={type} close={close} />
      </ModalFooter>
    </ModalContent>
  );
}

interface ShowResultsButtonProps {
  type: 0 | 1;
  close?: () => void;
}
function ShowResultsButton({ type, close }: ShowResultsButtonProps) {
  const { filters } = useBrowseFilterState(type);

  const { data: artistData } = useQuery({
    enabled: type === 1,
    queryKey: [filters.toString(), type],
    queryFn: () => getArtists(filters),
  });

  const { data: comicData } = useQuery({
    enabled: type === 0,
    queryKey: [filters.toString(), type],
    queryFn: () => getComics(filters),
  });

  const data = type === 0 ? comicData : artistData;

  const totalResults = data?.data?.payload.pagination.total_results;
  return (
    <Button variant="primary" onClick={close}>
      Show {totalResults !== undefined ? totalResults : ""} Results
    </Button>
  );
}
