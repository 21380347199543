import { Box, BoxProps } from "@chakra-ui/react";

interface ContentCardBackdropProps extends BoxProps {
  full?: boolean;
}

export function ContentCardBackdrop({
  full,
  ...boxProps
}: ContentCardBackdropProps) {
  const borderLeftRadius = full ? 32.5 : 8;
  const borderRightRadius = full ? 5 : 8;

  return (
    <Box
      borderLeftRadius={borderLeftRadius}
      borderRightRadius={borderRightRadius}
      background="charcoal.900"
      height="100%"
      width="100%"
      {...boxProps}
    />
  );
}

interface ExtendedContentCardBackdropProps extends BoxProps {
  topBox?: BoxProps;
  bottomBox?: BoxProps;
}

export function ExtendedContentCardBackdrop({
  topBox,
  bottomBox,
  ...props
}: ExtendedContentCardBackdropProps) {
  return (
    <Box height="100%" width="100%" {...props}>
      <Box
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
        background="charcoal.900"
        height="100%"
        width="100%"
        {...topBox}
      />
      <Box
        height="100%"
        width="100%"
        bgGradient={`linear(to-b, charcoal.900, charcoal.charcoal_00)`}
        {...bottomBox}
      />
    </Box>
  );
}
