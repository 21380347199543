import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { RootLayout } from "../components/layouts/RootLayout";
import { routes as browseRoutes } from "./browse/routes";
import { routes as downloadsPaths } from "./downloads/routes";
import { routes as inboxRoutes } from "./inbox/routes";
import { routes as newsPaths } from "./news/routes";
import { routes as libraryPaths } from "./library/routes";
// import { routes as adminPaths } from "./admin/routes";
import { routes as readerPaths } from "./reader/routes";
import { routes as goldPaths } from "./gold/routes";
import { routes as giftPaths } from "./gift/routes";
import RootScreen from "./root/RootScreen";
import { routes as legalPaths } from "./legal/routes";
import { routes as newsRoutes } from "./news/routes";
import { routes as globalChatRoutes } from "./globalChat/routes";
import { routes as redemptionSuccessRoutes } from "./redemptionSuccess/routes";

export const applicationRoutes = createRoutesFromElements(
  <Route path="/" element={<RootLayout />}>
    {browseRoutes}
    {downloadsPaths}
    {libraryPaths}
    {newsPaths}
    {inboxRoutes}
    {readerPaths}
    {goldPaths}
    {giftPaths}
    {legalPaths}
    {newsRoutes}
    {globalChatRoutes}
    {redemptionSuccessRoutes}
    {/*{window.gc.global.is_superadmin && adminPaths}*/}
    <Route path="/channel?/:channel?/:slug?" Component={RootScreen} />
  </Route>,
);

export const router = createBrowserRouter(applicationRoutes);
