import { Icon, IconProps } from "@chakra-ui/react";

export function StarIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="star">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0918 2.775C11.4492 2.00013 12.5505 2.00013 12.9079 2.775L12.4539 2.98442L12.9079 2.775L15.4402 8.26491L21.4439 8.97675C22.2913 9.07722 22.6316 10.1246 22.0051 10.704L17.5664 14.8088L18.7447 20.7386C18.911 21.5756 18.02 22.2229 17.2754 21.8061L11.9999 18.8531L6.72435 21.8061C5.97974 22.2229 5.08878 21.5756 5.25508 20.7386L6.43334 14.8088L1.99463 10.704C1.36813 10.1246 1.70844 9.07722 2.55584 8.97675L8.55956 8.26491L11.0918 2.775ZM14.5321 8.68376L11.9999 3.19385L9.46762 8.68376C9.32195 8.99957 9.02267 9.21701 8.6773 9.25796L2.67358 9.9698L7.11229 14.0746C7.36762 14.3107 7.48194 14.6625 7.41416 15.0037L6.23591 20.9335L11.5114 17.9805C11.8149 17.8106 12.1848 17.8106 12.4883 17.9805L17.7638 20.9335L16.5856 15.0037C16.5178 14.6625 16.6321 14.3107 16.8875 14.0746L21.3262 9.9698L15.3224 9.25796C14.9771 9.21701 14.6778 8.99957 14.5321 8.68376L14.9862 8.47434L14.5321 8.68376Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
