import { buildReaderUrl } from "../../../utils/buildReaderUrl";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { usePageLayout } from "./usePageLayout";
import { PAGE_LAYOUT_TYPES } from "../constants";
import { useReleaseData } from "./hookstate/useReleaseData";
import { useComicReleasesData } from "./hookstate/useComicReleasesData";
import { useP2PSettings } from "./useP2PSettings";

export function useComicNavigation() {
  const navigate = useNavigate();
  const { pageLayout } = usePageLayout();
  const location = useLocation() as unknown as Location;
  const { comicReleasesData } = useComicReleasesData();
  const { releaseData } = useReleaseData();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { releaseOrder } = useParams();
  const releaseOrderNum = parseInt(releaseOrder ?? "");

  const hasPrevRelease = useMemo(() => {
    return releaseData?.has_previous_release;
  }, [releaseData]);

  const hasNextRelease = useMemo(() => {
    return releaseData?.has_next_release;
  }, [releaseData]);

  const goToRelease = useCallback(
    (newActiveRelease: number, toBeginning: boolean) => {
      const release = comicReleasesData?.filter(
        (release) => release.order === newActiveRelease,
      );
      let newPageOrder = 1;
      if (release?.[0] && !toBeginning) {
        const newPageCount = release[0].page_count;
        newPageOrder =
          pageLayout === PAGE_LAYOUT_TYPES.double
            ? newPageCount - 1
            : newPageCount;
      }
      if (release?.[0]) {
        navigate(
          buildReaderUrl(
            window.location.pathname,
            release[0].key,
            newPageOrder,
          ),
          {
            replace: true,
          },
        );
      }
    },
    [
      p2pEnabledAndAugmented,
      releaseOrder,
      comicReleasesData,
      location,
      pageLayout,
    ],
  );

  const goToPrevRelease = useCallback(
    (toBeginning: boolean = true) => {
      if (
        hasPrevRelease &&
        releaseData?.previous_release_read_url &&
        releaseData?.previous_release_order
      ) {
        goToRelease(releaseData.previous_release_order, toBeginning);
      }
    },
    [releaseData, hasPrevRelease],
  );

  const goToNextRelease = useCallback(
    (toBeginning: boolean = true) => {
      if (hasNextRelease && releaseData) {
        goToRelease(releaseData.next_release_order, toBeginning);
      }
    },
    [releaseData, hasNextRelease],
  );

  return {
    activeRelease: releaseOrderNum - 1,
    hasPrevRelease,
    hasNextRelease,
    goToPrevRelease,
    goToNextRelease,
  };
}
