import { accordionTheme } from "./components/Accordian";
import { buttonTheme } from "./components/Button";
import { customCardFacetTheme } from "./components/CustomCardFacet";
import { inputTheme } from "./components/Input";
import { modalTheme } from "./components/Modal";
import { popoverTheme } from "./components/Popover";
import { progressTheme } from "./components/Progress";
import { radioTheme } from "./components/Radio";
import { switchTheme } from "./components/Switch";
import { tabsTheme } from "./components/Tabs";

const cardTextStyle = {
  fontFamily: "Roboto",
  fontWeight: 700,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export const components = {
  Text: {
    variants: {
      title: {
        fontSize: [
          "18px",
          "18px",
          "18px",
          "24px",
          "24px",
          "24px",
          "24px",
          "24px",
          "24px",
        ],
        fontFamily: "Bricolage Grotesque",
        fontWeight: 700,
        lineHeight: "24px",
      },
      subtitle: {
        fontFamily: "Roboto",
        fontWeight: [
          "500",
          "500",
          "500",
          "400",
          "400",
          "400",
          "400",
          "400",
          "400",
        ],
        lineHeight: [
          "18px",
          "18px",
          "18px",
          "24px",
          "24px",
          "24px",
          "24px",
          "24px",
          "24px",
        ],
        letterSpacing: [
          "0",
          "0",
          "0",
          "0.5",
          "0.5",
          "0.5",
          "0.5",
          "0.5",
          "0.5",
        ],
        color: "dune.700",
        fontSize: [
          "12px",
          "12px",
          "12px",
          "14px",
          "14px",
          "14px",
          "14px",
          "14px",
          "14px",
        ],
      },
      navbar: {
        fontFamily: "Bricolage Grotesque",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
        color: "dune.700",
      },
      filterTag: {
        transition: "all .2s linear",
        _groupHover: { color: "dune.100" },
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: "500",
        letterSpacing: ".5px",
        color: "dune.100",
      },
      filterContent: {
        transition: "all .2s linear",
        _groupHover: { color: "dune.100" },
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "130%",
        letterSpacing: ".5px",
        color: "dune.100",
      },
      cardLargeHeader: {
        ...cardTextStyle,
        fontSize: "1.125rem",
        lineHeight: "130%",
      },
      cardHeader: {
        ...cardTextStyle,
        fontSize: "1rem",
        lineHeight: "130%",
      },
      cardSmallHeader: {
        ...cardTextStyle,
        fontSize: ".875rem",
        lineHeight: "130%",
      },
      cardSubtitle: {
        ...cardTextStyle,
        fontSize: ".75rem",
        lineHeight: "150%",
      },
      cardVitals: {
        ...cardTextStyle,
        fontSize: ".625rem",
        lineHeight: "150%",
        letterSpacing: ".01563rem",
        fontWeight: 500,
      },
      brand: {
        transition: "all .1s linear",
        color: "neutral.100",
        _hover: {
          textDecoration: "none",
          color: "blaze.blaze",
        },
      },
      modalBody: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.dune",
        fontWeight: "500",
        fontSize: { base: "14px", lg: "16px" },
        lineHeight: { base: "16.8px", lg: "19px" },
      },

      modalTitle: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.dune",
        fontWeight: "500",
        fontSize: { base: "20px", lg: "24px" },
        lineHeight: "120%",
      },

      modalDescription: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.700",
        fontWeight: "500",
        fontSize: { base: "10px", lg: "12px" },
        lineHeight: "130%",
      },
      faqTitle: {
        fontSize: "22px",
        fontWeight: 500,
        color: "dune.100",
        letterSpacing: "0.5px",
        fontFamily: "Bricolage Grotesque",
      },
      faqAnswer: {
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.5px",
        color: "dune.dune",
        fontFamily: "Roboto",
      },
      legalTitle: {
        fontFamily: "Bricolage Grotesque",
        fontWeight: 600,
        letterSpacing: "0.5px",
        lineHeight: "130%",
        fontSize: [
          "24px",
          "24px",
          "24px",
          "36px",
          "36px",
          "36px",
          "36px",
          "36px",
          "36px",
        ],
      },
      legalContent: {
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.5px",
        marginBottom: [
          "24px",
          "24px",
          "24px",
          "22px",
          "22px",
          "22px",
          "22px",
          "22px",
          "22px",
        ],
        lineHeight: [
          "170%",
          "170%",
          "170%",
          "150%",
          "150%",
          "150%",
          "150%",
          "150%",
          "150%",
        ],
        fontSize: "16px",
      },
      lastUpdate: {
        fontFamily: "Roboto Mono, monospace",
        fontWeight: 400,
        letterSpacing: "0.5px",
        lineHeight: "131.8%",
        fontSize: [
          "14px",
          "14px",
          "14px",
          "16px",
          "16px",
          "16px",
          "16px",
          "16px",
          "16px",
        ],
      },
      goldTitle: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.dune",
        fontWeight: [
          "700",
          "700",
          "700",
          "600",
          "600",
          "600",
          "600",
          "600",
          "600",
        ],
        letterSpacing: "0.5px",
        lineHeight: "130%",
        fontSize: [
          "20px",
          "20px",
          "20px",
          "32px",
          "32px",
          "32px",
          "32px",
          "32px",
          "32px",
        ],
      },
      onboardingTitle: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.dune",
        fontWeight: { base: "600", lg: "700" },
        letterSpacing: "-0.5px",
        lineHeight: { base: "110%", lg: "32px" },
        fontSize: { base: "24px", lg: "40px" },
      },
      onboardingDescription: {
        fontFamily: "Bricolage Grotesque",
        color: "dune.800",
        fontWeight: "600",
        lineHeight: "110%",
        fontSize: "24px",
        letterSpacing: "-0.5px",
      },
      onboardingSkip: {
        color: "dune.700",
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: "0.5px",
        _hover: {
          color: "dune.dune",
        },
      },
      bodyExtraSmallMedium: {
        fontFamily: "Roboto",
        fontSize: "10px",
        fontWeight: "500",
        letterSpacing: "0.25px",
        lineHeight: "150%",
      },
      releaseInfoHeaderSuperscript: {
        fontFamily: "Roboto",
        color: "neutral.400",
        fontSize: "12px",
        fontWeight: "500",
      },
      bodyLargeBold: {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "130%",
      },
      bodySmallBold: {
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: "700",
        lineHight: "150%",
      },
      newsTitle: {
        fontSize: [
          "32px",
          "32px",
          "32px",
          "46px",
          "46px",
          "46px",
          "46px",
          "46px",
          "46px",
        ],
        fontFamily: "Bricolage Grotesque",
        fontWeight: "700",
        letterSpacing: "0.5px",
      },
      newsHeader: {
        fontSize: [
          "12px",
          "12px",
          "12px",
          "14px",
          "14px",
          "14px",
          "14px",
          "14px",
          "14px",
        ],
        fontFamily: "Roboto Mono, monospace",
        color: "dune.700",
        fontWeight: "400",
        letterSpacing: "0.5px",
      },
      unlockableCard: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "150%",
        letterSpacing: "0.25px",
        color: "blaze.blaze",
      },
      chatScheduleHeader: {
        color: "dune.dune",
        fontSize: "20px",
        fontWeight: "600",
        lineHeight: "26px",
      },
      chatEventTitle: {
        color: "dune.dune",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: ".5px",
      },
      chatEventSubtitle: {
        color: "dune.700",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: ".5px",
      },
      chatEventDescription: {
        color: "dune.700",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        letterSpacing: ".5px",
      },
      monospaceTime: {
        fontFamily: "Roboto Mono, monospace",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "normal",
        color: "white",
      },
    },
  },
  Tooltip: {
    variants: {
      navbar: {
        py: "8px",
        px: "24px",
        background: "transparent.white.10",
        backdropFilter: "blur(12px)",
        color: "neutral.100",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.20)",
        borderRadius: "8px",
      },
    },
  },
  FormLabel: {
    baseStyle: {
      color: "neutral.white",
    },
  },
  Input: inputTheme,
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: "none",
      },
    },
    variants: {
      brand: {
        transition: "all .1s linear",
        color: "neutral.100",
        _hover: {
          textDecoration: "none",
          "@media (hover: hover)": {
            color: "blaze.blaze",
          },
        },
      },
    },
  },
  Accordion: accordionTheme,
  Button: buttonTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Tabs: tabsTheme,
  CustomCardFacet: customCardFacetTheme,
  Progress: progressTheme,
  Switch: switchTheme,
  Radio: radioTheme,
};
