import { Flex, Text, Image } from "@chakra-ui/react";
import { GCSmallLogoIcon } from "../../icons/GCSmallLogoIcon";
import { t } from "i18next";

export function SomethingWentWrong({ text }: { text?: string }) {
  return (
    <Flex
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="32px"
    >
      <Flex gap="8px" alignItems="center">
        <GCSmallLogoIcon color="blaze.blaze" width="32px" height="32px" />
        <Text variant="title">
          {text ?? t("components.layouts.errorScreen.somethingWentWrong")}...
        </Text>
      </Flex>
      <Image
        alt={t("components.layouts.errorScreen.somethingWentWrong")}
        src={`${window.gc.global.assets_url}/static/images/beta/error.gif`}
        borderRadius="50px"
      />
    </Flex>
  );
}
