import {
  Button,
  ButtonProps,
  Flex,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slide,
} from "@chakra-ui/react";
import { CSSProperties, useMemo, useRef } from "react";
import { useEnsureFocus } from "../../hooks/useEnsureFocus";
import { ReadingStatusPopoverContent } from "./components/ReadingStatusPopoverContent";
import { useQuery } from "@tanstack/react-query";
import { Comic } from "../../types";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../appStore";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { CloseIcon } from "@chakra-ui/icons";
import { ComicThumbnail } from "../comics/ComicThumbnail";
import { ReadingStatusButtonTrigger } from "./components/ReadingStatusButtonTrigger";
import { FUNNEL_TYPES } from "../funnel/utils/contants";
import { setFunnelModalConfig } from "../funnel/FunnelModal";
import { FunnelContextArea } from "../funnel/types";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";

export const COMIC_USER_READING_STATUS_ALL = "0";

interface ReadingStatusButtonProps extends ButtonProps {
  cacheKey: string;
  currentStatusId?: number;
  isSmall?: boolean;
  useBookmark?: boolean;
  ctaButton?: JSX.Element;
  contextual?: boolean;
  width?: CSSProperties["width"];
}
export function ReadingStatusButton({
  cacheKey,
  currentStatusId,
  isSmall,
  useBookmark,
  ctaButton,
  contextual,
  width = "100%",
  ...buttonProps
}: ReadingStatusButtonProps) {
  const isMobile = useMobileBreakpoint();
  const popoverContentRef = useRef<HTMLElement>(null);
  const ensureFocus = useEnsureFocus();

  // When the popover appears, the reading status box should be focused.
  // However, the container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (!popoverContentRef.current) {
        return;
      }
      ensureFocus(popoverContentRef.current);
    },
  });

  const { data } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.addToLibrary,
    comic: data,
  });

  const libraryStatus = data?.library_status;
  const allStatuses = useHookstate(appStore.readingStatusMap).get();
  const readingStatus = useMemo(() => {
    return allStatuses[libraryStatus?.comic_user_reading_status_id ?? 0]
      ?.display_name;
  }, [allStatuses, data]);
  return (
    <Flex
      width={width}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popover
        isLazy
        variant="filter"
        initialFocusRef={focusHandler}
        placement="bottom-end"
        gutter={2}
      >
        {({ onClose, isOpen }) => (
          <>
            {contextual ? (
              <PopoverTrigger>
                {ctaButton ? (
                  ctaButton
                ) : (
                  <ReadingStatusButtonTrigger
                    {...buttonProps}
                    value={readingStatus}
                    isSmall={isSmall}
                    useBookmark={useBookmark}
                    inLibrary={!!libraryStatus}
                  />
                )}
              </PopoverTrigger>
            ) : (
              <Box
                width="full"
                onClick={() => {
                  setFunnelModalConfig({
                    type: FUNNEL_TYPES.addToLibrary,
                    options: {
                      modalContext: FunnelContextArea.addToLibrary,
                      cacheKey,
                      navigationContext,
                    },
                  });
                }}
              >
                {ctaButton ? (
                  ctaButton
                ) : (
                  <ReadingStatusButtonTrigger
                    {...buttonProps}
                    value={readingStatus}
                    isSmall={isSmall}
                    useBookmark={useBookmark}
                    inLibrary={!!libraryStatus}
                    contextual={false}
                  />
                )}
              </Box>
            )}
            {isMobile && data && (
              <Modal variant="filters" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <Slide
                  in={isOpen}
                  direction="bottom"
                  style={{ width: "100%", height: "100%", zIndex: 1409 }}
                >
                  <ModalContent
                    containerProps={{
                      alignItems: "flex-end",
                    }}
                    width="100vw"
                    margin={0}
                    maxHeight="95vh"
                    height="auto"
                    maxWidth="none"
                    borderRadius="none"
                    border="1px solid"
                    borderColor="transparent.white.10"
                  >
                    <ModalHeader px={0} flexDirection="column" gap="16px">
                      <Flex
                        width="100%"
                        pl={6}
                        pr={3}
                        justifyContent="space-between"
                      >
                        <ComicThumbnail comicData={data} />
                        <Button
                          variant="iconOnly"
                          onClick={onClose}
                          color="dune.600"
                          _hover={{ color: "dune.100" }}
                        >
                          <CloseIcon w="16px" h="16px" />
                        </Button>
                      </Flex>
                    </ModalHeader>
                    <ModalBody px={0}>
                      <ReadingStatusPopoverContent
                        onClose={onClose}
                        data={data}
                        isModal
                        contextual={contextual}
                        inLibrary={!!libraryStatus}
                      />
                    </ModalBody>
                  </ModalContent>
                </Slide>
              </Modal>
            )}
            {!isMobile && data && (
              <Portal>
                <PopoverContent ref={popoverContentRef} maxHeight="none">
                  <PopoverBody px={0}>
                    <ReadingStatusPopoverContent
                      onClose={onClose}
                      data={data}
                      inLibrary={!!libraryStatus}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            )}
          </>
        )}
      </Popover>
    </Flex>
  );
}
