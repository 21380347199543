import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://72e8a21b0bf969ef465f9dd8e97c4e81@o24374.ingest.us.sentry.io/4508321616953344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.httpClientIntegration(),
  ],
  environment: window.gc.global.environment,
});
