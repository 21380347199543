import {
  Text,
  Box,
  Stack,
  useRadio,
  useRadioGroup,
  RadioProps,
} from "@chakra-ui/react";
import { t } from "i18next";

interface LibraryStatusRadioProps {
  value?: number;
  setLibraryStatus: (status: string) => void;
}
export function LibraryStatusRadio({
  value,
  setLibraryStatus,
}: LibraryStatusRadioProps) {
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: "Library Status",
    value: value?.toString(),
    onChange: setLibraryStatus,
  });

  const options = [
    { value: "1", text: t("screens.reader.addToMenu.reading") },
    { value: "6", text: t("screens.reader.addToMenu.on_hold") },
    { value: "4", text: t("screens.reader.addToMenu.finished") },
    { value: "2", text: t("screens.reader.addToMenu.read_later") },
    { value: "5", text: t("screens.reader.addToMenu.re_reading") },
    { value: "3", text: t("screens.reader.addToMenu.dropped") },
  ];

  return (
    <Stack {...getRootProps()} gap="0">
      {options.map(({ text, value }) => (
        <CustomRadio key={value} {...getRadioProps({ value })}>
          {text}
        </CustomRadio>
      ))}
    </Stack>
  );
}

function CustomRadio(props: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={4}
      background="reader.slate.600"
      cursor="pointer"
      borderBottom="1px solid"
      borderRight="1px solid"
      borderLeft="1px solid"
      borderColor="reader.neutral.800"
      _first={{
        borderTopRadius: "8px",
        borderTop: "1px solid",
        borderColor: "reader.neutral.800",
      }}
      _last={{ borderBottomRadius: "8px" }}
    >
      <input {...input} hidden />
      <Text fontSize="14px" fontWeight="600" lineHeight="20px">
        {props.children}
      </Text>
      <Box
        {...checkbox}
        outline="2px solid"
        outlineColor="reader.neutral.300"
        outlineOffset="2px"
        _checked={{
          outline: "2px solid",
          outlineColor: "blaze.blaze",
          outlineOffset: "2px",
          background: "blaze.blaze",
        }}
        height="12px"
        width="12px"
        borderRadius="full"
      />
    </Box>
  );
}
