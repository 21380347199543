import { Box, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { GearIcon } from "../../../../components/icons/GearIcon";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import { ReleasesIcon } from "../../../../components/icons/reader/ReleasesIcon";
import { P2PIcon } from "../../../../components/icons/reader/P2PIcon";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { useReleaseFragmentsData } from "../../hooks/hookstate/useReleaseFragmentsData";
import { useReleaseData } from "../../hooks/hookstate/useReleaseData";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function MenuActions() {
  const { onToggleSlideInMenu } = useSlideInMenu();
  const { p2pEnabled, toggleP2PEnabled } = useP2PSettings();
  const { releaseFragmentsData } = useReleaseFragmentsData();
  const { releaseData } = useReleaseData();

  const canToggleP2P =
    releaseData?.is_augmented ||
    (releaseData?.can_edit && releaseFragmentsData?.length);
  return (
    <Box
      as={motion.div}
      animate={{ x: [0, -25], opacity: [0, 1] }}
      position="absolute"
      top="85px"
      right={0}
      display="flex"
      flexDirection="column"
      gap="0"
    >
      <Button
        variant="iconOnly"
        aria-label="Toggle settings menu"
        padding="30px 0 20px 0"
        background="reader.neutral.900_75"
        borderTopRadius="full"
        borderBottomRadius="none"
        onClick={(e) => {
          e.stopPropagation();
          onToggleSlideInMenu(SLIDE_IN_MENUS.settings);
        }}
      >
        <GearIcon height="24px" width="24px" />
      </Button>
      <Button
        variant="iconOnly"
        aria-label="Toggle all pages menu"
        background="reader.neutral.900_75"
        borderTopRadius="none"
        borderBottomRadius={canToggleP2P ? "none" : "full"}
        padding={releaseFragmentsData ? "20px 0 20px 0" : "20px 0 30px 0"}
        onClick={(e) => {
          e.stopPropagation();
          onToggleSlideInMenu(SLIDE_IN_MENUS.allPages);
        }}
      >
        <ReleasesIcon height="24px" width="24px" />
      </Button>
      {!!canToggleP2P && (
        <Button
          variant="iconOnly"
          aria-label="Toggle panel to panel reading"
          padding="20px 0 30px 0"
          background="reader.neutral.900_75"
          borderTopRadius="none"
          borderBottomRadius="full"
          onClick={(e) => {
            e.stopPropagation();
            toggleP2PEnabled();
          }}
        >
          <P2PIcon
            height="24px"
            width="24px"
            fill="none"
            color={p2pEnabled ? "blaze.blaze" : "inherit"}
          />
        </Button>
      )}
    </Box>
  );
}
