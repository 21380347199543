import { Text } from "@chakra-ui/react";
import { CSSProperties } from "react";

export function PaymentTitle({
  text,
  textAlign = "center",
  size = "24px",
}: {
  text: string;
  textAlign?: CSSProperties["textAlign"];
  size?: CSSProperties["fontSize"];
}) {
  return (
    <Text
      variant="title"
      color="dune.dune"
      fontSize={size}
      fontWeight="600"
      lineHeight="130%"
      textAlign={textAlign}
      sx={{
        textWrap: "balance",
      }}
    >
      {text}
    </Text>
  );
}
