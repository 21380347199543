import { Flex, Spinner, Text } from "@chakra-ui/react";
import { ReactionTypeEntity } from "../../../services/gc/types/reactionTypeEntity";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { ChatMessage } from "./ChatMessage";
import { Message, PresenceMember } from "@ably/chat";

export function ChatLog({
  messages,
  members,
  isLoaded = false,
  isAttached = false,
  deleteMessage,
  toggleReaction,
}: {
  messages: Message[];
  members: Record<string, PresenceMember>;
  isLoaded: boolean;
  isAttached: boolean;
  deleteMessage: (message: Message) => void;
  toggleReaction: (message: Message, data: ReactionTypeEntity) => void;
}) {
  const isSmallScreen = useIsSmallBreakpoint();
  const showSpinner = !isAttached || !isLoaded;
  const showMessages = messages.length > 0;

  return (
    <Flex
      flexGrow={1}
      width="100%"
      flexDirection="column-reverse"
      maxHeight="inherit"
      overflow="auto"
      gap="8px"
      py="8px"
      px={isSmallScreen ? "16px" : 0}
      alignItems={!showMessages ? "center" : "flex-start"}
      justifyContent={!showMessages ? "center" : "flex-start"}
    >
      {!showMessages ? (
        <>
          {showSpinner ? (
            <Text color="dune.dune">Joining room...</Text>
          ) : (
            <Text textAlign="center" color="dune.dune">
              There's nothing here yet.
              <br />
              Be the first to send a message!
            </Text>
          )}
          {showSpinner ? (
            <Spinner color="blaze.blaze" emptyColor="transparent" size="xl" />
          ) : null}
        </>
      ) : (
        messages.map((message, i) => (
          <ChatMessage
            message={message}
            members={members}
            deleteMessage={deleteMessage}
            toggleReaction={toggleReaction}
            key={i}
          />
        ))
      )}
    </Flex>
  );
}
