import { Divider, Flex, Text } from "@chakra-ui/react";

export function LoginOptionsDivider() {
  return (
    <Flex align="center" pb="24px">
      <Divider color="neutral.700" />
      <Text
        color="neutral.300"
        fontStyle="normal"
        fontWeight="500"
        fontSize="12px"
        lineHeight="150%"
        fontFamily="Roboto"
        padding="0 16px 0 16px"
      >
        or
      </Text>
      <Divider color="neutral.700" />
    </Flex>
  );
}
