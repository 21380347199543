import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { GlobalIcon } from "../../../../components/icons/GlobalIcon";
import { CountryEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { browseFilterStore } from "./BrowseFilters";
import { FacetGroupFilter } from "./FacetGroupFilter";
import { FilterTitle } from "../../../../components/filters/FilterTitle";

interface CountryFacetGroupFilterProps {
  value: Immutable<string[] | undefined>;
  countries: CountryEntity[];
  onChange: (nextValue: string[]) => void;
}
export function CountryFacetGroupFilter({
  value,
  countries,
  onChange,
}: CountryFacetGroupFilterProps) {
  const countryOptions: Option<string>[] = useMemo(
    () => [
      ...countries.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <FacetGroupFilter
      value={value}
      options={countryOptions}
      onChange={onChange}
    />
  );
}

interface CountryFacetGroupFilterTitleProps {
  isTrigger: boolean;
  countries: CountryEntity[];
  open?: boolean;
}
export const CountryFacetGroupFilterTitle = forwardRef(
  (
    { isTrigger, countries, open, ...props }: CountryFacetGroupFilterTitleProps,
    ref,
  ) => {
    const countryState = browseFilterStore.countryFacets.get();
    // Hookstate is doing some fuzzy matching for us
    const country = countries.find(
      (country) => `${country.id}` == (countryState as unknown as string),
    );
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<GlobalIcon />}
          popoverRef={ref}
          text="Country"
          activeFilters={countryState?.length}
          isFiltering={(countryState?.length ?? 0) > 0}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Country"
        activeFilters={countryState?.length}
        filterValue={country?.name}
        open={open}
      />
    );
  },
);
