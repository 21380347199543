import { Flex, Text } from "@chakra-ui/react";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea, FunnelNavigationContext } from "../../funnel/types";

export interface SimpleLoginMenuProps {
  navigationContext?: FunnelNavigationContext;
}

export function SimpleLoginMenu({ navigationContext }: SimpleLoginMenuProps) {
  return (
    <Flex pt="8px" fontSize="16px">
      <Text
        color="reader.blue.link"
        textDecoration="underline"
        cursor="pointer"
        onClick={() =>
          setFunnelModalConfig({
            type: FUNNEL_TYPES.login,
            options: { navigationContext },
          })
        }
      >
        Log in
      </Text>
      &nbsp;or&nbsp;
      <Text
        color="reader.blue.link"
        textDecoration="underline"
        cursor="pointer"
        onClick={() =>
          setFunnelModalConfig({
            type: FUNNEL_TYPES.signup,
            options: {
              navigationContext,
              modalContext: FunnelContextArea.navBar,
            },
          })
        }
      >
        sign up
      </Text>
      &nbsp;to comment
    </Flex>
  );
}
