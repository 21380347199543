import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import { FunnelComponentProps } from "../../funnel/types";

export function AuthenticationHeader({
  onClose,
  text,
  locked = false,
}: FunnelComponentProps & {
  text?: string;
  locked?: boolean;
}) {
  return (
    <Flex direction="row" justify="space-between" align="center">
      <Text
        fontWeight="600"
        fontSize="20px"
        lineHeight="26px"
        fontFamily="Bricolage Grotesque"
        color="dune.dune"
      >
        {text}
      </Text>
      {!locked && (
        <Button variant="iconOnly" onClick={onClose}>
          <CloseIcon color="dune.100" />
        </Button>
      )}
    </Flex>
  );
}
