import { Flex, Text } from "@chakra-ui/react";
import { formatLargeNumber } from "../../../utils/formatLargeNumber";

interface StatsTagProps {
  count: number;
  icon: React.ReactNode;
}
export function StatsTag({ count, icon }: StatsTagProps) {
  return (
    <Flex direction="row" gap=".25rem" alignItems="center">
      {icon} <Text variant="cardVitals">{formatLargeNumber(count)}</Text>
    </Flex>
  );
}
