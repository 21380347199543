/**
 * Converts a string with 'px' suffix to a number
 */
export function pxToNumber(value: string): number {
  if (value.endsWith("px")) {
    const number = parseInt(value, 10);
    if (isNaN(number)) {
      throw new Error(`Invalid pixel value: ${value}`);
    }
    return number;
  }

  throw new Error(
    `Invalid format: ${value}. Expected a string ending with 'px'.`,
  );
}
