import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import { TrashcanIcon } from "../icons/reader/TrashcanIcon";
import { useHookstate } from "@hookstate/core";
import { ReactionTypeEntity } from "../../services/gc/types/reactionTypeEntity";
import { Message } from "@ably/chat";
import { appStore } from "../../appStore";
import { useUserData } from "../../hooks/useUserData";
import { useIsMouseInput } from "../../hooks/useIsMouseInput";

export const reactionIdsToEmoji: Record<number, string> = {
  1: "😂",
  2: "😐",
  3: "🤯",
  4: "😱",
  5: "🤬",
  6: "👍",
  7: "🤩",
  8: "💗",
  9: "👍",
  10: "👎",
};

const reactionsToUse = [3, 4, 6, 8, 1, 10];

export function ReactionsPopover({
  deleteMessage,
  toggleReaction,
  onToggle,
  message,
  isOpen,
  position,
}: {
  deleteMessage: (message: Message) => void;
  toggleReaction: (message: Message, data: ReactionTypeEntity) => void;
  onToggle: () => void;
  message: Message;
  isOpen?: boolean;
  position?: "top" | "bottom";
}) {
  const isMouseInput = useIsMouseInput();
  const { userData } = useUserData();
  const reactionTypes = useHookstate(appStore.init.reaction_types).get();

  if (!userData) return null;

  return (
    <Flex
      position="absolute"
      zIndex={2}
      opacity={isOpen ? 1 : 0}
      transition={
        isMouseInput
          ? undefined
          : "opacity 0.1s ease-in-out, transform 0.1s ease-out"
      }
      pointerEvents={isOpen ? "all" : "none"}
      _hover={isMouseInput ? { opacity: 1, pointerEvents: "all" } : undefined}
      _groupHover={
        isMouseInput ? { opacity: 1, pointerEvents: "all" } : undefined
      }
      sx={
        isMouseInput
          ? {
              top: "-32px",
            }
          : {
              ...(position === "bottom"
                ? { bottom: "-54px" }
                : { top: "-54px" }),
              transform: isOpen
                ? "translateX(-50%) scale(1)"
                : "translateX(-50%) scale(0.8)",
              left: "50%",
            }
      }
      right={isMouseInput ? "16px" : undefined}
      gap="4px"
      bg="charcoal.charcoal"
      border="1px solid"
      borderRadius="8px"
      p="8px"
      borderColor="transparent.white.10"
      alignItems="center"
      onMouseDown={(e) => e.preventDefault()}
    >
      {reactionTypes.map((reactionType) => {
        if (!reactionsToUse.includes(reactionType.id)) return null;
        return (
          <Button
            key={reactionType.id}
            size="thin"
            boxSize={isMouseInput ? "24px" : "32px"}
            fontSize={isMouseInput ? "16px" : "20px"}
            onClick={() => {
              toggleReaction(message, reactionType);
              onToggle();
            }}
            bg="transparent"
            _hover={{ bg: "transparent.white.10" }}
          >
            {reactionIdsToEmoji[reactionType.id]}
          </Button>
        );
      })}
      {userData?.is_admin && (
        <>
          <Divider width="1px" height="24px" bg="transparent.white.20" />
          <Button
            size="thin"
            onClick={() => {
              deleteMessage(message);
              onToggle();
            }}
            bg="transparent"
            gap="4px"
            _hover={{ bg: "transparent.white.10" }}
          >
            <TrashcanIcon boxSize={isMouseInput ? "16px" : "20px"} />{" "}
            {isMouseInput && (
              <Text fontSize="12px" fontWeight="400" color="dune.dune">
                Delete
              </Text>
            )}
          </Button>
        </>
      )}
    </Flex>
  );
}
