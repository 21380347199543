import { useHookstate } from "@hookstate/core";
import { readerSettingsStore } from "../ReaderStateWrapper";

export function usePageLayout() {
  const pageLayout = useHookstate(readerSettingsStore.pageLayout).get();

  const setPageLayout = (layout: string) => {
    readerSettingsStore.pageLayout.set(layout);
  };

  return { pageLayout, setPageLayout };
}
