import { useHookstate } from "@hookstate/core";
import { readerSettingsStore } from "../ReaderStateWrapper";

export function usePageSize() {
  const pageSizing = useHookstate(readerSettingsStore.pageSizing).get();

  const setPageSize = (size: string) => {
    readerSettingsStore.pageSizing.set(size);
  };

  return { pageSizing, setPageSize };
}
