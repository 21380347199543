import { Icon, IconProps } from "@chakra-ui/react";

export function AppStoreIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 96 24" {...props}>
      <path
        d="M14.6688 11.3542C14.6788 10.5595 14.8901 9.77482 15.2824 9.08069C15.6748 8.38656 16.2381 7.80309 16.9222 7.38058C16.4896 6.76693 15.9162 6.25387 15.2523 5.90178C14.5883 5.53963 13.8539 5.34849 13.0994 5.31831C11.4899 5.14729 9.9306 6.28405 9.11575 6.28405C8.30091 6.28405 7.02331 5.33843 5.66523 5.36861C4.79002 5.39879 3.93494 5.65028 3.18045 6.11304C2.42596 6.56573 1.81231 7.20956 1.37974 7.98417C-0.471274 11.1832 0.906923 15.9013 2.67746 18.4866C3.56272 19.7542 4.59889 21.1726 5.95696 21.1223C7.28486 21.072 7.77779 20.2773 9.37731 20.2773C10.9768 20.2773 11.4295 21.1223 12.8178 21.0921C14.2463 21.072 15.1416 19.8145 15.9967 18.5369C16.6305 17.6316 17.1234 16.6356 17.4453 15.5793C16.6204 15.2273 15.9162 14.6438 15.4132 13.8994C14.9203 13.1549 14.6487 12.2697 14.6487 11.3743L14.6688 11.3542Z"
        fill="white"
      />
      <path
        d="M12.0539 3.61721C12.8285 2.68165 13.2108 1.48452 13.1202 0.277344C11.9332 0.398062 10.8366 0.971473 10.052 1.8668C9.6697 2.30943 9.36791 2.81242 9.18683 3.36571C8.9957 3.91901 8.92527 4.50248 8.95545 5.08595C9.54898 5.08595 10.1325 4.96523 10.6757 4.71373C11.2089 4.46224 11.6817 4.09002 12.0539 3.62727V3.61721Z"
        fill="white"
      />
      <path
        d="M31.4389 17.9021H26.9119L25.8255 21.1111H23.9041L28.1895 9.23047H30.1814L34.4669 21.1111H32.5153L31.4288 17.9021H31.4389ZM27.3848 16.4233H30.9761L29.2056 11.2123H29.1553L27.3848 16.4233Z"
        fill="white"
      />
      <path
        d="M43.743 16.7871C43.743 19.4832 42.3044 21.2135 40.1315 21.2135C39.5782 21.2436 39.035 21.1129 38.5521 20.8513C38.0693 20.5797 37.6769 20.1874 37.4053 19.7045H37.3651V24H35.5845V12.4715H37.3047V13.91H37.3349C37.6166 13.4271 38.019 13.0348 38.5018 12.7632C38.9847 12.4916 39.538 12.3608 40.0913 12.3809C42.2944 12.3809 43.743 14.1213 43.743 16.8073V16.7871ZM41.9222 16.7871C41.9222 15.0367 41.0168 13.8798 39.6285 13.8798C38.2403 13.8798 37.355 15.0568 37.355 16.7871C37.355 18.5174 38.2705 19.7045 39.6285 19.7045C40.9866 19.7045 41.9222 18.5577 41.9222 16.7871Z"
        fill="white"
      />
      <path
        d="M53.2791 16.7871C53.2791 19.4832 51.8406 21.2135 49.6677 21.2135C49.1144 21.2436 48.5711 21.1129 48.0883 20.8513C47.6054 20.5797 47.2131 20.1874 46.9414 19.7045H46.9012V24H45.1206V12.4715H46.8408V13.91H46.871C47.1527 13.4271 47.5551 13.0348 48.038 12.7632C48.5208 12.4916 49.0741 12.3608 49.6274 12.3809C51.8305 12.3809 53.2791 14.1213 53.2791 16.8073V16.7871ZM51.4482 16.7871C51.4482 15.0367 50.5429 13.8798 49.1546 13.8798C47.7663 13.8798 46.8811 15.0568 46.8811 16.7871C46.8811 18.5174 47.7965 19.7045 49.1546 19.7045C50.5127 19.7045 51.4482 18.5577 51.4482 16.7871Z"
        fill="white"
      />
      <path
        d="M59.587 17.8073C59.7178 18.9843 60.8646 19.7589 62.4239 19.7589C63.9832 19.7589 64.9992 18.9843 64.9992 17.918C64.9992 16.9925 64.3453 16.4392 62.8062 16.067L61.267 15.6948C59.0841 15.1717 58.068 14.1456 58.068 12.4957C58.068 10.4435 59.8587 9.03516 62.3937 9.03516C64.9288 9.03516 66.6289 10.4435 66.6792 12.4957H64.8886C64.7779 11.3087 63.8021 10.5944 62.3636 10.5944C60.925 10.5944 59.9492 11.3187 59.9492 12.375C59.9492 13.2201 60.5729 13.713 62.102 14.0852L63.4098 14.4071C65.8443 14.9805 66.8603 15.9664 66.8603 17.6967C66.8603 19.9199 65.0898 21.3082 62.273 21.3082C59.6373 21.3082 57.8568 19.9501 57.7461 17.7973H59.5669L59.587 17.8073Z"
        fill="white"
      />
      <path
        d="M70.7129 10.4046V12.4568H72.3627V13.8652H70.7129V18.6436C70.7129 19.388 71.0449 19.7301 71.7692 19.7301C71.9603 19.7301 72.1615 19.7099 72.3526 19.6898V21.0881C72.0307 21.1485 71.6987 21.1787 71.3668 21.1686C69.6164 21.1686 68.9323 20.5147 68.9323 18.8247V13.8551H67.6748V12.4467H68.9323V10.3945H70.7229L70.7129 10.4046Z"
        fill="white"
      />
      <path
        d="M73.3176 16.788C73.3176 14.0617 74.9272 12.3516 77.422 12.3516C79.9169 12.3516 81.5365 14.0617 81.5365 16.788C81.5365 19.5142 79.9471 21.2243 77.422 21.2243C74.897 21.2243 73.3176 19.5142 73.3176 16.788ZM79.7257 16.788C79.7257 14.9168 78.8707 13.8102 77.4321 13.8102C75.9935 13.8102 75.1385 14.9269 75.1385 16.788C75.1385 18.649 75.9935 19.7556 77.4321 19.7556C78.8707 19.7556 79.7257 18.6591 79.7257 16.788Z"
        fill="white"
      />
      <path
        d="M83.0054 12.4517H84.7055V13.9305H84.7457C84.8564 13.4677 85.128 13.0653 85.5103 12.7736C85.8925 12.4919 86.3553 12.341 86.8281 12.3612C87.0293 12.3612 87.2406 12.3813 87.4418 12.4316V14.0915C87.1802 14.011 86.9086 13.9808 86.647 13.9808C86.3855 13.9707 86.134 14.011 85.8925 14.1116C85.6511 14.2122 85.4399 14.3631 85.2588 14.5441C85.0878 14.7353 84.9469 14.9566 84.8664 15.2081C84.786 15.4495 84.7558 15.7111 84.786 15.9726V21.1132H83.0054V12.4517Z"
        fill="white"
      />
      <path
        d="M95.6309 18.5685C95.3895 20.1379 93.8604 21.2243 91.8987 21.2243C89.3838 21.2243 87.8145 19.5343 87.8145 16.8282C87.8145 14.1221 89.3838 12.3516 91.8283 12.3516C94.2729 12.3516 95.7315 14.0014 95.7315 16.627V17.2406H89.6152V17.3513C89.585 17.6732 89.6252 17.9951 89.7359 18.2969C89.8365 18.5987 90.0075 18.8804 90.2288 19.1118C90.4501 19.3432 90.7217 19.5242 91.0135 19.6449C91.3052 19.7657 91.6271 19.826 91.949 19.8059C92.3716 19.8461 92.7941 19.7455 93.1462 19.5242C93.5083 19.3029 93.79 18.9709 93.9409 18.5786H95.6309V18.5685ZM89.6252 15.9832H93.961C93.9811 15.7015 93.9308 15.4098 93.8302 15.1381C93.7296 14.8665 93.5787 14.6251 93.3775 14.4138C93.1763 14.2026 92.9349 14.0416 92.6734 13.931C92.4118 13.8203 92.1201 13.77 91.8384 13.77C91.5467 13.77 91.265 13.8203 90.9934 13.931C90.7217 14.0416 90.4803 14.2026 90.2791 14.4038C90.0779 14.605 89.9069 14.8464 89.7962 15.118C89.6856 15.3896 89.6252 15.6713 89.6252 15.963V15.9832Z"
        fill="white"
      />
      <path
        d="M27.1541 0.284984C27.5263 0.254805 27.8985 0.315164 28.2506 0.445942C28.6027 0.57672 28.9146 0.787977 29.1661 1.05959C29.4276 1.33121 29.6188 1.65312 29.7294 2.01528C29.8502 2.36737 29.8803 2.74965 29.83 3.12186C29.83 4.94269 28.8442 5.99897 27.1441 5.99897H25.0818V0.284984H27.1441H27.1541ZM25.9872 5.18413H27.0636C27.3251 5.20425 27.5967 5.15395 27.8482 5.05335C28.0997 4.95275 28.3211 4.80185 28.5021 4.60066C28.6832 4.39946 28.814 4.16808 28.8945 3.91659C28.9749 3.66509 28.9951 3.39348 28.9548 3.13192C28.9951 2.87036 28.9649 2.59875 28.8844 2.34725C28.8039 2.09576 28.6731 1.86438 28.4921 1.66318C28.311 1.47205 28.0897 1.31109 27.8482 1.21049C27.6068 1.10989 27.3352 1.06965 27.0736 1.07971H25.9972V5.17407L25.9872 5.18413Z"
        fill="white"
      />
      <path
        d="M30.846 3.83651C30.8159 3.55483 30.846 3.26309 30.9366 3.00154C31.0271 2.72992 31.1679 2.48849 31.3591 2.27723C31.5502 2.06597 31.7816 1.89496 32.0432 1.7843C32.3047 1.67364 32.5864 1.61328 32.8681 1.61328C33.1497 1.61328 33.4314 1.67364 33.693 1.7843C33.9545 1.89496 34.1859 2.06597 34.377 2.27723C34.5682 2.48849 34.709 2.73998 34.7996 3.00154C34.8901 3.27315 34.9203 3.55483 34.8901 3.83651C34.9203 4.11818 34.8901 4.40992 34.7996 4.67147C34.709 4.94309 34.5682 5.19458 34.377 5.40584C34.1859 5.6171 33.9545 5.78811 33.693 5.89877C33.4314 6.00943 33.1497 6.06979 32.8681 6.06979C32.5864 6.06979 32.3047 6.00943 32.0432 5.89877C31.7816 5.78811 31.5502 5.6171 31.3591 5.40584C31.1679 5.19458 31.0271 4.94309 30.9366 4.67147C30.846 4.39986 30.8159 4.11818 30.846 3.83651ZM34.035 3.83651C34.035 2.90094 33.6125 2.35771 32.8781 2.35771C32.1438 2.35771 31.7212 2.90094 31.7212 3.83651C31.7212 4.77207 32.1337 5.3153 32.8781 5.3153C33.6226 5.3153 34.035 4.77207 34.035 3.83651Z"
        fill="white"
      />
      <path
        d="M40.3122 6.00483H39.4269L38.5316 2.83598H38.4612L37.5759 6.00483H36.7007L35.5137 1.69922H36.3788L37.1534 4.98879H37.2138L38.0991 1.69922H38.9139L39.7992 4.98879H39.8696L40.6341 1.69922H41.4892L40.3022 6.00483H40.3122Z"
        fill="white"
      />
      <path
        d="M42.495 1.68556H43.3098V2.36963H43.3702C43.4808 2.12819 43.6619 1.91694 43.8933 1.7761C44.1247 1.63526 44.3963 1.5749 44.6578 1.59502C44.8691 1.5749 45.0804 1.61514 45.2715 1.68556C45.4626 1.76604 45.6437 1.88676 45.7845 2.03766C45.9254 2.18855 46.036 2.37969 46.0964 2.58089C46.1568 2.78208 46.1769 2.99334 46.1467 3.2046V5.99117H45.2916V3.41585C45.2916 2.72173 44.9898 2.37969 44.3661 2.37969C44.2253 2.37969 44.0844 2.39981 43.9536 2.45011C43.8229 2.50041 43.7022 2.58089 43.6016 2.69155C43.501 2.79214 43.4305 2.92292 43.3802 3.0537C43.3299 3.18448 43.3199 3.33538 43.3299 3.47621V6.00123H42.4749V1.69562L42.495 1.68556Z"
        fill="white"
      />
      <path
        d="M47.5054 0.0078125H48.3605V6.00348H47.5054V0.0078125Z"
        fill="white"
      />
      <path
        d="M49.5365 3.83651C49.5063 3.55483 49.5365 3.26309 49.627 3.00154C49.7175 2.72992 49.8584 2.47843 50.0495 2.27723C50.2407 2.06597 50.472 1.89496 50.7336 1.7843C50.9951 1.67364 51.2768 1.61328 51.5585 1.61328C51.8402 1.61328 52.1218 1.67364 52.3834 1.7843C52.645 1.89496 52.8763 2.06597 53.0675 2.27723C53.2586 2.48849 53.3994 2.73998 53.49 3.00154C53.5805 3.27315 53.6107 3.55483 53.5805 3.83651C53.6107 4.11818 53.5805 4.40992 53.49 4.67147C53.3994 4.94309 53.2586 5.19458 53.0675 5.40584C52.8763 5.6171 52.645 5.78811 52.3834 5.89877C52.1218 6.00943 51.8402 6.06979 51.5585 6.06979C51.2768 6.06979 50.9951 6.00943 50.7336 5.89877C50.472 5.78811 50.2407 5.6171 50.0495 5.40584C49.8584 5.19458 49.7175 4.94309 49.627 4.67147C49.5365 4.39986 49.5063 4.11818 49.5365 3.83651ZM52.7355 3.83651C52.7355 2.90094 52.313 2.35771 51.5786 2.35771C50.8442 2.35771 50.4217 2.90094 50.4217 3.83651C50.4217 4.77207 50.8342 5.3153 51.5786 5.3153C52.323 5.3153 52.7355 4.77207 52.7355 3.83651Z"
        fill="white"
      />
      <path
        d="M54.5066 4.78047C54.5066 4.00586 55.08 3.55317 56.1061 3.49281L57.273 3.42239V3.05018C57.273 2.59749 56.9712 2.33593 56.3878 2.33593C55.915 2.33593 55.583 2.50695 55.4925 2.8188H54.6675C54.7581 2.07438 55.4522 1.60156 56.428 1.60156C57.5044 1.60156 58.1181 2.13473 58.1181 3.05018V5.99771H57.3032V5.39412H57.2328C57.092 5.61544 56.9008 5.78646 56.6795 5.90717C56.4481 6.02789 56.1966 6.07819 55.9451 6.06813C55.7641 6.08825 55.583 6.06813 55.412 6.00777C55.241 5.94741 55.08 5.85687 54.9492 5.73616C54.8184 5.61544 54.7078 5.46454 54.6273 5.30358C54.5569 5.14263 54.5166 4.96155 54.5166 4.78047H54.5066ZM57.273 4.41832V4.05616L56.2168 4.12658C55.6232 4.16682 55.3516 4.36802 55.3516 4.75029C55.3516 5.13257 55.6836 5.36394 56.1463 5.36394C56.2771 5.374 56.418 5.36394 56.5487 5.3237C56.6795 5.28346 56.8002 5.21305 56.9008 5.13257C57.0014 5.04203 57.092 4.94143 57.1523 4.81065C57.2127 4.68993 57.2529 4.55916 57.263 4.41832H57.273Z"
        fill="white"
      />
      <path
        d="M59.2346 3.84061C59.2346 2.48253 59.9388 1.61739 61.0253 1.61739C61.2969 1.60733 61.5584 1.66769 61.7999 1.79846C62.0313 1.92924 62.2224 2.13044 62.3532 2.37187H62.4135V0.0078125H63.2686V6.00348H62.4538V5.31941H62.3834C62.2526 5.56084 62.0514 5.75198 61.8099 5.88276C61.5685 6.01354 61.2969 6.08395 61.0253 6.07389C59.9287 6.07389 59.2346 5.20875 59.2346 3.85067V3.84061ZM60.1098 3.84061C60.1098 4.75606 60.5424 5.30935 61.2566 5.30935C61.9709 5.30935 62.4135 4.75606 62.4135 3.85067C62.4135 2.94529 61.9709 2.38193 61.2566 2.38193C60.5424 2.38193 60.1098 2.93523 60.1098 3.84061Z"
        fill="white"
      />
      <path
        d="M66.7796 3.83651C66.7494 3.55483 66.7796 3.26309 66.8702 3.00154C66.9607 2.72992 67.1015 2.48849 67.2927 2.27723C67.4838 2.06597 67.7152 1.89496 67.9768 1.7843C68.2383 1.67364 68.52 1.61328 68.8017 1.61328C69.0833 1.61328 69.365 1.67364 69.6266 1.7843C69.8881 1.89496 70.1195 2.06597 70.3106 2.27723C70.5018 2.48849 70.6426 2.73998 70.7331 3.00154C70.8237 3.27315 70.8539 3.55483 70.8237 3.83651C70.8539 4.11818 70.8237 4.40992 70.7331 4.67147C70.6426 4.94309 70.5018 5.19458 70.3106 5.40584C70.1195 5.6171 69.8881 5.78811 69.6266 5.89877C69.365 6.00943 69.0833 6.06979 68.8017 6.06979C68.52 6.06979 68.2383 6.00943 67.9768 5.89877C67.7152 5.78811 67.4838 5.6171 67.2927 5.40584C67.1015 5.19458 66.9607 4.94309 66.8702 4.67147C66.7796 4.39986 66.7494 4.11818 66.7796 3.83651ZM69.9686 3.83651C69.9686 2.90094 69.5461 2.35771 68.8117 2.35771C68.0774 2.35771 67.6548 2.90094 67.6548 3.83651C67.6548 4.77207 68.0673 5.3153 68.8117 5.3153C69.5561 5.3153 69.9686 4.77207 69.9686 3.83651Z"
        fill="white"
      />
      <path
        d="M71.9791 1.68556H72.794V2.36963H72.8543C72.965 2.12819 73.146 1.91694 73.3774 1.7761C73.6088 1.63526 73.8804 1.5749 74.142 1.59502C74.3532 1.5749 74.5645 1.61514 74.7556 1.68556C74.9468 1.76604 75.1278 1.88676 75.2687 2.03766C75.4095 2.18855 75.5202 2.37969 75.5805 2.58089C75.6409 2.78208 75.661 2.99334 75.6308 3.2046V5.99117H74.7757V3.41585C74.7757 2.72173 74.4739 2.37969 73.8502 2.37969C73.7094 2.37969 73.5686 2.39981 73.4378 2.45011C73.307 2.50041 73.1863 2.58089 73.0857 2.69155C72.9851 2.79214 72.9147 2.92292 72.8644 3.0537C72.8141 3.18448 72.804 3.33538 72.8141 3.47621V6.00123H71.959V1.69562L71.9791 1.68556Z"
        fill="white"
      />
      <path
        d="M80.4508 0.619434V1.71596H81.3863V2.43021H80.4508V4.64337C80.4508 5.09606 80.6319 5.29726 81.0544 5.29726C81.165 5.29726 81.2757 5.29726 81.3763 5.27714V5.98133C81.2254 6.01151 81.0644 6.02157 80.9135 6.02157C79.9679 6.02157 79.5957 5.68959 79.5957 4.85463V2.42015H78.9116V1.7059H79.5957V0.609375H80.4508V0.619434Z"
        fill="white"
      />
      <path
        d="M82.5437 0.0100601H83.3888V2.38418H83.4592C83.5699 2.13269 83.761 1.93149 83.9924 1.79065C84.2237 1.64981 84.4954 1.58946 84.767 1.60957C84.9782 1.59951 85.1895 1.62969 85.3806 1.71017C85.5718 1.79065 85.7528 1.91137 85.8937 2.06227C86.0345 2.21316 86.1452 2.39424 86.2055 2.59544C86.2659 2.79664 86.286 3.00789 86.2558 3.20909V5.99566H85.4007V3.42035C85.4007 2.73628 85.0788 2.38418 84.4752 2.38418C84.3344 2.37412 84.1835 2.38418 84.0427 2.44454C83.9018 2.5049 83.7811 2.57532 83.6805 2.67592C83.5799 2.77652 83.4994 2.90729 83.4491 3.04813C83.3988 3.18897 83.3787 3.32981 83.3888 3.4807V5.99566H82.5337V0L82.5437 0.0100601Z"
        fill="white"
      />
      <path
        d="M91.2051 4.83858C91.0844 5.23092 90.843 5.57295 90.5009 5.80433C90.1589 6.03571 89.7465 6.1363 89.3441 6.086C89.0624 6.086 88.7807 6.03571 88.5192 5.92505C88.2576 5.81439 88.0262 5.64337 87.8351 5.43211C87.6439 5.22086 87.5031 4.96936 87.4226 4.69775C87.3422 4.42613 87.322 4.14445 87.3522 3.86278C87.312 3.5811 87.3422 3.28937 87.4226 3.01775C87.5031 2.74614 87.6439 2.49464 87.8351 2.28338C88.0262 2.07213 88.2576 1.90111 88.5192 1.78039C88.7807 1.65967 89.0624 1.60938 89.3441 1.60938C90.5412 1.60938 91.2655 2.42422 91.2655 3.7823V4.07404H88.2274V4.12434C88.2174 4.28529 88.2274 4.44625 88.2878 4.58709C88.3381 4.73799 88.4186 4.87882 88.5292 4.98948C88.6399 5.1102 88.7707 5.20074 88.9115 5.2611C89.0523 5.32146 89.2133 5.35164 89.3742 5.35164C89.5754 5.37176 89.7867 5.34158 89.9678 5.25104C90.1489 5.1605 90.2998 5.0096 90.4003 4.83858H91.2152H91.2051ZM88.2174 3.45033H90.3903C90.4003 3.30949 90.3903 3.15859 90.3299 3.02781C90.2796 2.88697 90.2092 2.76626 90.1086 2.66566C90.008 2.56506 89.8873 2.47452 89.7565 2.42422C89.6257 2.36386 89.4748 2.34374 89.334 2.34374C89.1831 2.34374 89.0423 2.37392 88.9014 2.42422C88.7606 2.48458 88.6399 2.56506 88.5393 2.66566C88.4387 2.76626 88.3481 2.89703 88.2978 3.02781C88.2375 3.16865 88.2174 3.30949 88.2174 3.46039V3.45033Z"
        fill="white"
      />
    </Icon>
  );
}
