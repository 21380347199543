import { State } from "@hookstate/core";
import { FilterTagButton } from "../../../components/buttons/FilterTag";
import { ComicFacetGroupEntity } from "../../../services/gc/types";
import { Option } from "../../../types";
import { FilterState } from "../components/filters/BrowseFilters";
import { FacetGroupFilter } from "../components/filters/FacetGroupFilter";
import { FilterTitle } from "../../../components/filters/FilterTitle";
import { useMemo } from "react";

export function useComicFacetGroupFilter(
  filterStore: State<FilterState>,
  isModal: boolean,
  comicFacetGroups: ComicFacetGroupEntity[],
  onChange: (categoryId: number, value: string[]) => void,
  icon: React.ReactElement,
) {
  return useMemo(() => {
    return comicFacetGroups.map((comicFacetGroup, i) => {
      const comicFacets = filterStore.comicFacets.get({ noproxy: true });
      const filterState = comicFacets[`${comicFacetGroup.id}`];
      const activeFacetCount = filterState?.length;
      let activeFacet;
      const options: Option<string>[] =
        comicFacetGroup.comic_facet_group_options.map((option) => {
          if (filterState?.length && filterState[0] === option.id.toString()) {
            activeFacet = option.name;
          }
          return {
            label: option.name,
            value: "" + option.id,
          };
        });

      const title = isModal ? (
        <FilterTitle
          text={comicFacetGroup.display_name}
          filterValue={activeFacet}
          activeFilters={activeFacetCount}
        />
      ) : (
        <FilterTagButton
          icon={icon}
          text={comicFacetGroup.display_name}
          isFiltering={activeFacetCount > 0}
          activeFilters={activeFacetCount}
        />
      );

      const filter = (
        <FacetGroupFilter
          key={i}
          options={options}
          value={filterState ? filterState.map((val) => val) : []}
          onChange={(nextValue) => {
            onChange(comicFacetGroup.id, nextValue);
          }}
        />
      );

      return [title, filter, filterStore.comicFacets[comicFacetGroup.id]];
    });
  }, [comicFacetGroups, filterStore]);
}
