import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ComicContentCardProps } from "../../types";
import { ComicReleasesListCard } from "./ComicReleasesListCard";
import { ContentCardActions } from "./components/ContentCardActions";
import { FreeRibbon } from "./components/FreeRibbon";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { useHookstate } from "@hookstate/core";
import { cardLayoutStore } from "../../appStore";
import { CustomListCard } from "./CustomListCard";

export function ComicListCard({
  content,
  position,
  layout: { display, style },
  onToggleFollow,
  expanded,
  fixedHeight,
  fullWidth,
}: ComicContentCardProps & { expanded?: boolean; fixedHeight?: number }) {
  const layout_size = style.size;

  const comicsCardLayoutStore = useHookstate(cardLayoutStore.comics).get();

  const { scaleX, scaleY } = comicsCardLayoutStore.calendar_c_1 ?? {};

  const isSmall = layout_size === "s";
  let { width, height } = useGridCardDimensions(
    "comics",
    style.mode,
    layout_size,
  );

  height = fixedHeight ?? height;

  if (layout_size === "r_list") {
    return (
      <ComicReleasesListCard content={content} layout={{ display, style }} />
    );
  }

  var widthMultiplier = 1;
  var heightMultiplier = 1;

  if (style.mode === "calendar" && layout_size === "c_1") {
    widthMultiplier = scaleX ?? 1;
    heightMultiplier = scaleY ?? 1;
  }

  if (isSmall) {
    return (
      <SmallComicListCard
        width={width * widthMultiplier}
        height={height * heightMultiplier}
        content={content}
        position={position}
        layout={{ display, style }}
      />
    );
  }

  return (
    <CustomListCard
      width={width * widthMultiplier}
      height={height * heightMultiplier}
      fullWidth={fullWidth || expanded}
      content={{
        ...content,
        image_url: display.include_cover_image
          ? content.image_small_url
          : undefined,
        title: content.name,
        subTitle:
          display.include_author || display.include_owner_name
            ? content.artist?.roman_name
            : undefined,
        description: display.include_description
          ? content.description
          : undefined,
        share_url: display.include_share ? content.share_url : undefined,
      }}
      position={position}
      onToggleFollow={onToggleFollow}
      display={{ ...display }}
    />
  );
}

export function SmallComicListCard({
  content,
  position,
  width,
  height,
  layout: { display },
}: ComicContentCardProps & {
  height: number;
  width: number;
}) {
  const {
    include_name,
    include_author,
    include_owner_name,
    include_cover_image,
    include_ribbon_badge,
    include_position_num,
  } = display;

  return (
    <Flex width={width} height={height} direction="row" alignItems="flex-end">
      <Flex
        borderLeftRadius={64}
        borderRightRadius={8}
        width="100%"
        height="calc(100% - 10px)"
        position="relative"
        background="charcoal.900"
        gap={4}
        alignItems="center"
        pl={7}
      >
        {include_position_num ? (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            pl={2}
            pr={0}
          >
            <Text fontWeight={900} fontSize="3xl" color="charcoal.400">
              {position}
            </Text>
          </Flex>
        ) : null}
        <Flex direction="column" justifyContent="center">
          <Box position="relative" bottom="16px" pl={2}>
            {include_cover_image ? (
              <Image
                src={content.image_url}
                height="65px"
                w="42px"
                minWidth="42px"
                position="relative"
                border="1px solid"
                borderColor="transparent.white.10"
                borderRadius={10}
                loading="lazy"
                alt={`Cover image for ${content.name}`}
              />
            ) : null}
            {include_ribbon_badge && content.is_free ? (
              <FreeRibbon
                color="success.500"
                position="absolute"
                top="-1px"
                left="-1px"
              />
            ) : null}
          </Box>
        </Flex>
        <Flex direction="column" flex={1}>
          <Flex
            direction="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box maxWidth={include_position_num ? "200px" : "250px"}>
              {include_name ? (
                <Text variant="cardSmallHeader">{content.name}</Text>
              ) : null}
              {include_owner_name || include_author ? (
                <Text textColor="neutral.200" variant="cardSubtitle">
                  {content.artist?.roman_name}
                </Text>
              ) : null}
            </Box>
            <Box marginRight="16px">
              <ContentCardActions showFollow={false} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
