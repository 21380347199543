import { ArticleContentCardProps } from "../../types";
import { NewsListCard } from "./NewsListCard";

export function NewsHorizontalCard(props: ArticleContentCardProps) {
  switch (props.layout.style.size) {
    case "xl":
      return <NewsListCard {...props} />;
    case "s":
      return <NewsListCard {...props} />;
    default:
      console.warn("Unsupported card size", props.layout.style.size);
      return null;
  }
}
