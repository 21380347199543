import {
  Box,
  Flex,
  Stack,
  Text,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
} from "@chakra-ui/react";
import { Immutable } from "@hookstate/core";
import { Fragment } from "react";
import { CheckboxCheckedIcon } from "../../../../components/icons/CheckboxCheckedIcon";
import { CheckboxUncheckedIcon } from "../../../../components/icons/CheckboxUncheckedIcon";
import { Option } from "../../../../types";

interface FacetGroupFilter<T> {
  value: Immutable<string[] | undefined>;
  options: Option<string>[];
  onChange: (nextValue: T[]) => void;
}
export function FacetGroupFilter<T extends string>({
  value,
  options,
  onChange,
}: FacetGroupFilter<T>) {
  const { getCheckboxProps } = useCheckboxGroup({
    value: value as (string | number)[],
    defaultValue: [],
    onChange,
  });

  return (
    <Flex direction="column">
      <Stack data-gc-ignore-input="1">
        {options.map((option, i) => (
          <Fragment key={option.value}>
            <CustomCheckbox
              key={option.value}
              {...getCheckboxProps({ value: option.value })}
            >
              <Text
                variant="filterContent"
                color={value?.includes(option.value) ? "dune.100" : "dune.700"}
                _groupHover={{ color: "dune.100" }}
              >
                {option.label}
              </Text>
            </CustomCheckbox>
            {i < options.length - 1 && (
              <Box w="full" h="1px" bg="transparent.white.10" />
            )}
          </Fragment>
        ))}
      </Stack>
    </Flex>
  );
}

interface CustomCheckboxProps extends UseCheckboxProps {
  children?: React.ReactNode;
}
function CustomCheckbox(props: CustomCheckboxProps) {
  const { state, getInputProps } = useCheckbox(props);
  const input = getInputProps();

  return (
    <Box layerStyle="filterList" as="label" cursor="pointer" role="group">
      <input {...input} hidden />
      {props.children}
      {state.isChecked ? (
        <CheckboxCheckedIcon color="blaze.300" w={5} h={5} />
      ) : (
        <CheckboxUncheckedIcon
          _groupHover={{ color: "transparent.white.80" }}
          color="transparent.white.60"
          w={5}
          h={5}
        />
      )}
    </Box>
  );
}
