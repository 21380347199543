import { Box, Flex, useBreakpoint } from "@chakra-ui/react";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import { useState } from "react";
import { ContentSectionCustomCard, Image, Section } from "../../types";
import { CustomCard } from "./CustomCard";
import EmblaCarousel from "./EmblaCarousel";
import { HeroCardContent } from "./HeroCardContent";

interface HeroCardContainerProps {
  data: ContentSectionCustomCard[];
  sectionIndex?: number;
  section?: Section;
}

export const bottomPaddingHeights: Record<string, string> = {
  base: "40px",
  md: "44px",
  xl: "48px",
  "2xl": "51px",
  "3xl": "57px",
  "4xl": "94px",
};

export const backgroundTopOffset: Record<string, string> = {
  base: "48px",
  md: "32px",
  lg: "29px",
  "2xl": "32px",
  "3xl": "42px",
  "4xl": "61px",
};

export function HeroCardContainer({
  data,
  section,
  sectionIndex,
}: HeroCardContainerProps) {
  const emblaOptions: EmblaOptionsType = {
    align: "start",
    loop: true,
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSlideChange = (index: number) => {
    setSelectedIndex(index);
  };

  const displaySize = useBreakpoint({ ssr: false });

  const slides = (emblaApi: EmblaCarouselType | undefined) =>
    data.map((customCardData, index) => (
      <CustomCard
        key={customCardData.id}
        // Move the first and second image to the head of the load queue for initial load
        fetchPriority={index === 0 || index === 1 ? "high" : "low"}
        coverImage={getCoverImage(customCardData.images, displaySize)}
        contentCard={customCardData}
        isHidden={selectedIndex !== index}
        emblaApi={emblaApi}
        section={section}
        sectionIndex={sectionIndex}
        index={index}
      />
    ));

  // calculated values are based on the width of HeroCardContent for the given screen size
  const carouselWidths = {
    base: "100%",
    lg: "calc(100% - 269px)",
    "2xl": "calc(100% - 296px)",
    "3xl": "calc(100% - 377px)",
    "4xl": "calc(100% - 560px)",
  };

  return (
    <Box position="relative" zIndex="0" pl={4} userSelect="none">
      <Box
        backgroundColor="charcoal.900"
        borderLeftRadius={{ base: "0", lg: "30px" }}
        boxShadow="inset 0px -1px 1px 0px var(--chakra-colors-transparent-white-20)"
        position="absolute"
        top={backgroundTopOffset}
        left="0"
        right="50%"
        bottom={bottomPaddingHeights}
        zIndex="-1"
      />
      <Box
        backgroundColor="charcoal.900"
        position="absolute"
        top={backgroundTopOffset}
        left="49%"
        right="0"
        bottom="0"
        zIndex="-1"
      />
      <Flex
        mt={{
          base: "10px",
          lg: "18px",
        }}
        direction={{ base: "column", lg: "row" }}
      >
        <Flex order={{ base: 2, lg: 1 }}>
          <HeroCardContent
            content={data[selectedIndex]}
            section={section}
            sectionIndex={sectionIndex}
            index={selectedIndex}
          />
        </Flex>
        <Flex w={carouselWidths} order={{ base: 1, lg: 2 }}>
          <EmblaCarousel
            slides={slides}
            slideType="hero"
            options={emblaOptions}
            onSlideChange={handleSlideChange}
          />
        </Flex>
      </Flex>
      <Box
        borderTopRightRadius="30px"
        height={bottomPaddingHeights}
        backgroundColor="charcoal.charcoal"
        position="relative"
        _after={{
          content: '""',
          position: "absolute",
          bottom: 0,
          right: 0,
          width: `calc(51% + 9px)`,
          height: "100%",
          boxShadow:
            "1px -1px 1px 0px var(--chakra-colors-transparent-white-20)",
          borderTopRightRadius: "30px",
        }}
      />
    </Box>
  );
}

function getCoverImage(images: Image[], displaySize: string): string {
  if (displaySize === "4xl") {
    return images[1]?.large_url; // 16:9 - 1600x900
  } else if (["xl", "2xl", "3xl"].includes(displaySize)) {
    return images[1]?.small_url; // 16:9 - 1024x576
  } else if (["md", "lg"].includes(displaySize)) {
    return images[2]?.small_url; // 3:2 - 864x576
  } else {
    return images[0]?.small_url; // 1:1 - 640x640
  }
}
