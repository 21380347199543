import { layouts } from "./layouts/i18n";
import { navigation } from "./navigation/components/i18n";
import { cards } from "./cards/i18n";
import { readingStatus } from "./readingStatusButton/i18n";
import { fields } from "./fields/i18n";
import { payments } from "./payments/i18n";
import { auth } from "./auth/components/i18n";
import { onboarding } from "./onboarding/i18n";
import { slideInMenu } from "./slideInMenu/i18n.";
import { downloadOurApp } from "./downloadOurApp/i18n";

// Rollup components
export const components = {
  navigation,
  payments,
  cards,
  layouts,
  readingStatus,
  fields,
  auth,
  onboarding,
  slideInMenu,
  downloadOurApp,
};
