import { ButtonProps, Button, Text, Box } from "@chakra-ui/react";
import { forwardRef } from "react";
import { MoreIcon } from "../../icons/MoreIcon";
import { BookmarkAddedIcon } from "../../icons/BookmarkAddedIcon";
import { BookmarkIcon } from "../../icons/BookmarkIcon";
import { t } from "i18next";

interface ReadingStatusButtonTriggerProps extends ButtonProps {
  isSmall?: boolean;
  useBookmark?: boolean;
  inLibrary?: boolean;
  contextual?: boolean;
}
export const ReadingStatusButtonTrigger = forwardRef<
  HTMLDivElement,
  ReadingStatusButtonTriggerProps
>(({ isSmall, useBookmark, inLibrary, contextual = true, ...props }, ref) => {
  const active = props["aria-expanded"];
  return (
    <Box role="group">
      <Button
        aria-label={t("components.readingStatus.manageLibrary")}
        ref={ref}
        className="radio"
        minWidth="none"
        variant="readingStatusCTA"
        transition="all .2s linear"
        {...props}
        background={
          isSmall
            ? "transparent"
            : active
              ? "transparent.black.90"
              : "transparent.black.70"
        }
        border="1px solid"
        borderColor={
          isSmall
            ? "transparent"
            : active
              ? "transparent.white.50"
              : "transparent.white.10"
        }
        borderRadius="100px"
        padding={isSmall ? 0 : "8px 12px"}
        _hover={{
          background: isSmall ? "transparent" : "transparent.black.90",
          borderColor: isSmall ? "transparent" : "transparent.white.20",
        }}
        active={{}}
        gap="6px"
      >
        {!isSmall && (
          <Text fontSize="12px" fontWeight="500" letterSpacing=".5px">
            {props.value}
          </Text>
        )}
        {useBookmark ? (
          inLibrary ? (
            <BookmarkAddedIcon
              w="32px"
              h="32px"
              color="blaze.300"
              _hover={{ color: "blaze.blaze" }}
              transition="all .1s linear"
            />
          ) : (
            <BookmarkIcon
              w="32px"
              h="32px"
              color="neutral.300"
              _hover={{ color: "neutral.100" }}
              transition="all .1s linear"
            />
          )
        ) : (
          <MoreIcon
            w="18px"
            h="18px"
            transition="all .2s linear"
            color={active ? "blaze.400" : "dune.dune"}
            _groupHover={{
              color: isSmall ? "blaze.400" : active ? "blaze.400" : "dune.dune",
            }}
          />
        )}
      </Button>
    </Box>
  );
});
