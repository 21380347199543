import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { CaretUpIcon } from "../../../../components/icons/CaretUpIcon";
import { CaretDownIcon } from "../../../../components/icons/CaretDownIcon";
import { cloneElement } from "react";

interface BrowseFiltersAccordionProps {
  filters: any[];
}
export function BrowseFiltersAccordion({
  filters,
}: BrowseFiltersAccordionProps) {
  return (
    <Accordion allowMultiple borderRadius={4} variant="filterList">
      {filters
        .filter((n) => !!n)
        .map((group, index) => {
          const [title, filter] = group;

          return (
            <AccordionItem key={index}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton>
                    {cloneElement(title, { open: isExpanded })}
                    {isExpanded ? (
                      <CaretUpIcon w={5} h={5} color="neutral.400" />
                    ) : (
                      <CaretDownIcon w={5} h={5} color="neutral.400" />
                    )}
                  </AccordionButton>
                  <AccordionPanel>{filter}</AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
    </Accordion>
  );
}
