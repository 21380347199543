import { Page } from "../../../../types";
import { Flex, Image, Spinner, ImageProps } from "@chakra-ui/react";
import { useImageSizing } from "../../hooks/useImageSizing";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { animated, SpringValue } from "@react-spring/web";
import { useMemo, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { usePageSize } from "../../hooks/usePageSize";
import { useReleaseFragmentsData } from "../../hooks/hookstate/useReleaseFragmentsData";

interface BouncyImageProps {
  containerRef?: (instance: HTMLImageElement | null) => void;
  fullHeightReader: boolean;
  releasePageData: Page | null;
  parentHeight?: number;
  parentWidth?: number;
  swipeProps?: { opacity?: SpringValue<number>; x?: SpringValue<number> };
}
export function BouncyImage({
  containerRef,
  fullHeightReader,
  releasePageData,
  parentHeight,
  parentWidth,
  swipeProps,
}: BouncyImageProps) {
  const { getImageSize } = useImageSizing();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { pageSizing } = usePageSize();

  const [lastResize, setLastResize] = useState(Date.now());
  useResizeObserver(window.document.body, () => {
    setLastResize(Date.now());
  });

  const { imageHeight, imageWidth } = useMemo(() => {
    return parentHeight && parentWidth
      ? {
          imageHeight: parentHeight,
          imageWidth: parentWidth,
        }
      : getImageSize(releasePageData);
  }, [
    pageSizing,
    parentHeight,
    releasePageData,
    p2pEnabledAndAugmented,
    fullHeightReader,
    lastResize,
  ]);

  return (
    <animated.div
      key={releasePageData?.id}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "inherit",
        minHeight: !fullHeightReader ? imageHeight : "100%",
        justifyContent: "center",
        overflow: "hidden",
        ...swipeProps,
      }}
    >
      <ImageContainer
        containerRef={containerRef}
        fullHeightReader={fullHeightReader}
        releasePageData={releasePageData}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
      />
    </animated.div>
  );
}

interface ImageContainerProps {
  containerRef?: (instance: HTMLImageElement | null) => void;
  fullHeightReader: boolean;
  releasePageData: Page | null;
  imageHeight: number;
  imageWidth: number;
}
export function ImageContainer({
  containerRef,
  fullHeightReader,
  releasePageData,
  imageHeight,
  imageWidth,
}: ImageContainerProps) {
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { activePageFragments } = useReleaseFragmentsData();
  const { getImageSrc } = useImageSizing();

  const isP2P = p2pEnabledAndAugmented && activePageFragments.length;
  const props: ImageProps = {
    maxHeight: isP2P ? undefined : fullHeightReader ? "100%" : imageHeight,
    maxWidth: "100%",
    width: imageWidth,
    filter: releasePageData?.display_paid_access_block
      ? "blur(16px) grayscale(20%)"
      : "none",
  };
  return (
    <Image
      margin={"auto"}
      style={{
        backfaceVisibility: "hidden",
      }}
      ref={containerRef}
      height={isP2P ? "auto" : imageHeight}
      {...props}
      src={getImageSrc(releasePageData)}
      alt={`Image for page ${releasePageData?.order}`}
      fallback={
        <Flex
          margin="auto"
          height={imageHeight}
          width={imageWidth}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="blaze.blaze" />
        </Flex>
      }
      draggable={false}
    />
  );
}
