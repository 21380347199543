import { useAccountData } from "../../../hooks/useAccountData";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";
import { useHookstate } from "@hookstate/core";
import { GET_SECTIONS_QUERY_KEY } from "../../../screens/root/api/rootScreenApi";
import { FunnelContextArea } from "../../funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { ComplexMenuItem } from "./ComplexMenuItem";

export function GoldUpsellBanner({ onClick }: { onClick?: () => void }) {
  const account = useAccountData();

  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateReleaseData = useInvalidateQueries(
    releaseQueryKey as string[],
  );
  const invalidateCMSData = useInvalidateQueries([GET_SECTIONS_QUERY_KEY]);

  const subscriptionValueProps =
    account?.account_links.subscription_value_props;
  if (!subscriptionValueProps || typeof subscriptionValueProps !== "object") {
    return null;
  }

  const { headline, sub_head } = subscriptionValueProps;

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.settings,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    setFunnelModalConfig({
      type: FUNNEL_TYPES.subscription,
      options: {
        locked: false,
        modalContext: "modal",
        onAuthenticated: () => {
          if (inReader) invalidateReleaseData();
          else invalidateCMSData();
        },
        navigationContext,
      },
    });
  };

  return (
    <ComplexMenuItem
      onClick={handleClick}
      title={headline}
      subtitle={sub_head}
    />
  );
}
