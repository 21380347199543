import { Button, Flex, Text, Heading, BoxProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Comic,
  ContentSectionCustomCard,
  ReadStatus,
  Section,
} from "../../types";
import { ComicFrequencyStatusBadge } from "./components/ComicFrequencyStatusBadge";
import { ComicReadingStyleBadge } from "./components/ComicReadingStyleBadge";
import { CONTEXT_ENTITY_TYPES } from "./constants";
import { ReadingStatusButton } from "../readingStatusButton/ReadingStatusButton";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext";

interface FeatureCardContentProps extends BoxProps {
  contentCard: ContentSectionCustomCard;
  isHidden?: boolean;
  cardResponsiveWidths?: Record<string, string> | string;
  isRoadblock?: boolean;
  index?: number;
  sectionIndex?: number;
  section?: Section;
}

export function FeatureCardContent({
  contentCard,
  isHidden = false,
  cardResponsiveWidths,
  isRoadblock = false,
  index,
  sectionIndex,
  section,
  ...props
}: FeatureCardContentProps) {
  const isMobile = useMobileBreakpoint();
  const isComicCard =
    contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.comic;

  const customCardCacheKey = `${contentCard.entity_type}-${contentCard.id}`;
  const comicCacheKey = isComicCard
    ? `${contentCard.comic?.entity_type}-${contentCard.comic?.id}`
    : `${contentCard.release?.comic?.entity_type}-${contentCard.release?.comic?.id}`;

  const { data } = useQuery<ContentSectionCustomCard>({
    queryKey: [customCardCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { data: comicData } = useQuery<Comic>({
    enabled: !!(contentCard.comic || contentCard.release?.comic),
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  // Prefer data cached in query client
  const cardDataToUse = data ?? contentCard;
  const comicDataToUse =
    comicData ??
    (isComicCard ? cardDataToUse.comic : cardDataToUse.release?.comic);

  const { goToCTAUrl } = useNavigateToReaderWithContext({
    comicToNavigate:
      isComicCard && comicDataToUse?.read_url ? comicDataToUse : undefined,
    releaseToNavigate:
      !isComicCard && cardDataToUse.release?.read_url
        ? cardDataToUse.release
        : undefined,
    section: section,
    cardPosition: index,
    sectionPosition: sectionIndex,
  });

  const handleButtonClick = goToCTAUrl;

  return (
    <Flex
      direction="column"
      mt={isRoadblock && !isMobile ? undefined : "16px"}
      width={cardResponsiveWidths}
      height={
        isRoadblock
          ? "250x"
          : {
              base: "230px",
              md: "224px",
            }
      }
      justifyContent="space-between"
      alignItems="start"
      position="relative"
      opacity={isHidden ? 0.2 : 1}
      transition="all .5s ease"
      {...props}
    >
      <Flex direction="column" flex="1" width="100%">
        {comicDataToUse ? (
          <Flex
            alignItems="center"
            width="100%"
            justifyContent={
              isRoadblock && !isMobile ? "start" : "space-between"
            }
          >
            {isComicCard ? (
              <ComicFrequencyStatusBadge
                comic={comicDataToUse}
                fontSize="10px"
              />
            ) : (
              <Text fontSize="12px" fontWeight={700} color="neutral.300">
                {comicDataToUse?.name}
              </Text>
            )}
            <ComicReadingStyleBadge
              ml={isRoadblock && !isMobile ? "16px" : "auto"}
              fontSize="10px"
              comic={comicDataToUse}
            />
          </Flex>
        ) : null}
        <Heading
          as="h2"
          fontSize={{ base: "15px", sm: "20px" }}
          fontWeight={700}
          lineHeight="130%"
          color="neutral.white"
          w="100%"
          mt="8px"
          noOfLines={1}
          className="multiline-clamp"
        >
          {cardDataToUse.display_name}
        </Heading>
        <Text
          fontSize="12px"
          fontWeight={700}
          color="neutral.300"
          textAlign="left"
          w="100%"
          mt="8px"
        >
          {`${comicDataToUse?.artist?.roman_name} • ${comicDataToUse?.category_name}`}
        </Text>
        <Text
          fontSize={isRoadblock ? "14px" : "12px"}
          fontWeight={400}
          color="neutral.300"
          lineHeight="150%"
          textAlign="left"
          w="full"
          maxH={isRoadblock ? undefined : "75px"}
          mt="8px"
          noOfLines={5}
          className="multiline-clamp"
        >
          {cardDataToUse.description
            ? cardDataToUse.description
            : isComicCard
              ? comicDataToUse?.description
              : cardDataToUse?.release?.description}
        </Text>
      </Flex>
      {comicDataToUse ? (
        <Flex
          alignItems="center"
          width="100%"
          mt="16px"
          gap="24px"
          justifyContent={
            !(isRoadblock && !isMobile) ? "space-between" : undefined
          }
        >
          <Button
            onClick={!isHidden ? handleButtonClick : undefined}
            variant="primary"
            width={
              !(isRoadblock && !isMobile)
                ? "full"
                : { base: "200px", sm: "278px", md: "350px" }
            }
          >
            {getButtonText(
              isComicCard
                ? comicDataToUse?.user_read_status
                : cardDataToUse?.release?.user_read_status,
            )}
          </Button>
          <ReadingStatusButton
            cacheKey={`${comicDataToUse.entity_type}-${comicDataToUse.id}`}
            isSmall
            width="24px"
            useBookmark
          />
        </Flex>
      ) : null}
    </Flex>
  );
}

function getButtonText(userReadStatus: ReadStatus | null | undefined): string {
  const [t] = useTranslation();

  if (userReadStatus) {
    return t("components.cards.customCard.buttonText.continueReading");
  } else {
    return t("components.cards.customCard.buttonText.startReading");
  }
}
