import { Flex, Text } from "@chakra-ui/react";

interface SectionHeaderProps {
  text: string;
}
export function SectionHeader({ text }: SectionHeaderProps) {
  return (
    <Flex pl="12px" borderLeft="2px solid" borderColor="blaze.blaze">
      <Text
        fontSize="16px"
        fontWeight="600"
        lineHeight="16px"
        textTransform="capitalize"
      >
        {text}
      </Text>
    </Flex>
  );
}
