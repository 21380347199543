import { Box, BoxProps } from "@chakra-ui/react";
import { clamp } from "lodash-es";
import { Range } from "../../@types/util";

interface ProgressBarProps {
  value: Range<0, 101>;
  color1: BoxProps["backgroundColor"];
  color2: BoxProps["backgroundColor"];
  height?: BoxProps["height"];
  vertical?: boolean;
}

export function ProgressBar({
  value,
  color1,
  color2,
  height = "2px",
  vertical,
}: ProgressBarProps) {
  const direction = vertical ? "to-b" : "to-r";
  const clampedValue = clamp(value ?? 0, 0, 100);

  return (
    <Box w="100%" h={height} backgroundColor="neutral.800" borderRadius={100}>
      <Box
        w={`${clampedValue}%`}
        h={height}
        bgGradient={`linear(${direction}, ${color1}, ${color2})`}
        borderRadius={100}
      />
    </Box>
  );
}
