export const reader = {
  loading: {
    releaseNotFound: "Release not found",
  },
  overlay: {
    next_release: "Next Release",
    prev_release: "Previous Release",
    series_info: "Series info",
    unfollow: "Unfollow",
    follow: "Follow",
    add_to: "Add to",
    manage: "Manage",
    comments: "Comments",
    vertical: "Vertical",
    traditional: "Traditional",
    free: "Free",
    scan_to_get: "Scan to get the app",
    panelToPanel: "panel to panel",
    buyPDF: "buy pdf",
    downloadPDF: "download pdf",
    buyPrintEdition: "buy print edition",
    donate: "donate",
    noLandscape: "Landscape mode not supported",
    noLandscapeDescOne: "The comic overlay does not support landscape mode",
    noLandscapeDescTwo: "on this device. Please rotate to continue.",
    crowdfundingNow: "Crowdfunding now",
  },
  interstitial: {
    end_of_series: "End of comic",
    end_of_release: "End of chapter",
    what_did_you_think: "What did you think?",
    follow_and_get_notified:
      "Follow and get notified when a new release has been published",
    plenty_more_to_explore:
      "There is plenty more to explore, check out these other series...",
    follow: "FOLLOW",
    unfollow: "UNFOLLOW",
    followers: "followers",
    comics: "comics",
    releases: "releases",
    start_reading: "START READING NEXT RELEASE",
  },
  settingsMenu: {
    enabled: "Enabled",
    p2p_description:
      "Enables automatic panel-to-panel immersive reading for supported comics",
    overlay_opacity: "Overlay Opacity",
    p2p_reading: "Panel to panel reading",
    page_layout: "Page layout",
    auto: "auto",
    single_page: "single page",
    double_page: "double page",
    vertical: "stacked",
    page_sizing: "Page sizing",
    fit_to_height: "fit to height",
    fit_to_width: "fit to width",
    vertical_layout_image_margin: "Stacked Layout Image Margin",
    image_margin_description:
      "Separate traditional page layout images with a small margin when viewed in stacked mode",
    settings: "Settings",
    enableP2P: "Enable panel to panel reading",
  },
  addToMenu: {
    reading: "Reading",
    on_hold: "On Hold",
    finished: "Finished",
    read_later: "Read Later",
    re_reading: "Re-Reading",
    dropped: "Dropped",
    send_notifications: "Send me notifications",
    send_emails: "Send me emails",
    update_status: "update status",
    add_to_library: "Add to library",
    change_status: "Change status",
  },
  allPagesMenu: {
    all_pages: "All pages",
    free: "Free",
    page: "Page",
    continueReading: "Continue Reading",
  },
  commentsMenu: {
    comments: "Comments",
    reply: "Reply",
    seeMore: "See more",
    seeLess: "See less",
  },
  paywall: {
    unlockThisAnd:
      "Unlock this and all other comics, webcomics and manga with GlobalComix Gold",
    subscribe: "Subscribe",
    whatsIncluded: "What's included in Gold?",
    unlockThis: "Unlock this and 60,000+ stories",
    readOn: "Read on web, iOS, and Android",
    supportYour: "Support your favorite creators",
    lockedPages: "locked pages",
  },
  nsfwInterstitial: {
    matureContentWarning: "Mature Content Warning",
    areYouSure:
      "Are you sure you want to enable mature content on this device?",
    iAccept: "i accept",
    takeMeBack: "No, take me back",
  },
};
