import "./sentryInit.ts";
import "./metaPixelInit.ts";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { AppProviders } from "./AppProviders.tsx";
import "./wdyr.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <AppProviders>
    <App />
  </AppProviders>,
);
