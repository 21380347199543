import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { FunnelComponentProps } from "../../funnel/types";
import { Release } from "../../../types";
import { ReleasePurchaseData } from "./ReleasePurchaseData";
import { useTranslation } from "react-i18next";
import { PaymentValueProps } from "./PaymentValueProps";
import { useUserData } from "../../../hooks/useUserData";
import { DiscountBanner } from "./DiscountBanner";
import { PrePayWallActions } from "./PrePaywallActions";

export function PrePaywallModalBody({
  release,
}: FunnelComponentProps & {
  release: Release;
}) {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { ppb_price_point, ppb_gold_price_point, premium_only, is_free } =
    release;
  const releaseIsOnlyFreeWithGold =
    !(userData?.gold ?? true) && !is_free && !ppb_price_point && !premium_only;
  const valueProps = releaseIsOnlyFreeWithGold
    ? [
        t("components.payments.prePaywall.unRestrictedAccessOnlineReading"),
        t("components.payments.prePaywall.supportTheCreators"),
      ]
    : [];
  const isUnlockableWithGold =
    !(userData?.gold ?? true) && !is_free && !premium_only;

  const hasADiscount =
    userData?.gold &&
    premium_only &&
    ppb_gold_price_point &&
    ppb_gold_price_point !== ppb_price_point;

  return (
    <Flex flexDirection="column" gap="32px">
      <Text
        variant="title"
        color="dune.dune"
        fontWeight="600"
        lineHeight="130%"
        textAlign="center"
        sx={{
          textWrap: "balance",
        }}
      >
        {isUnlockableWithGold
          ? t("components.payments.prePaywall.getBookFreeWithGold")
          : t("components.payments.prePaywall.buyTheBookToContinueReading")}
      </Text>
      <Flex alignSelf="start" flexDirection="row" width="100%">
        <Image
          borderRadius="10px"
          height={"215px"}
          aspectRatio={0.645}
          src={release.thumbnail_url}
          alt="Release thumbnail"
          mr={6}
        />
        <Flex flexDirection="column" width="100%" gap="20px">
          <ReleasePurchaseData
            pagesTextSuffix={t("components.payments.purchaseRelease.pages")}
            release={release}
          />
          <ReleasePrice release={release} />
        </Flex>
      </Flex>
      {valueProps.length > 0 && (
        <>
          <Box width="100%" height="1px" bg="transparent.white.10" />
          <PaymentValueProps
            valueProps={valueProps}
            showSeparators={false}
            alignItems="start"
          />
        </>
      )}
      {hasADiscount && (
        <>
          <Box width="100%" height="1px" bg="transparent.white.10" />
          <DiscountBanner
            price={ppb_price_point ?? 0}
            goldPrice={ppb_gold_price_point ?? 0}
            isGold={userData?.gold ?? false}
            fontSize="16px"
          />
        </>
      )}
      <Box width="100%" height="1px" bg="transparent.white.10" />
      <PrePayWallActions
        release={release}
        userIsGold={userData?.gold ?? false}
      />
    </Flex>
  );
}

function ReleasePrice({ release }: { release: Release }) {
  const { t } = useTranslation();
  const { ppb_price_point, ppb_gold_price_point, premium_only } = release;

  const priceString = ppb_price_point ? `$${ppb_price_point / 100}` : "";
  const goldPriceString = ppb_gold_price_point
    ? `$${ppb_gold_price_point / 100}`
    : "";
  let regularText = "";
  let highlightedText = "";
  let crossedOutText = false;
  if (premium_only) {
    if (
      ppb_price_point &&
      ppb_gold_price_point &&
      ppb_price_point !== ppb_gold_price_point
    ) {
      regularText = priceString;
      highlightedText = goldPriceString;
      crossedOutText = true;
    } else if (
      !ppb_gold_price_point ||
      ppb_price_point === ppb_gold_price_point
    ) {
      regularText = priceString;
    }
  } else if (ppb_price_point) {
    highlightedText = t("components.payments.prePaywall.free");
    regularText = priceString;
  }

  return (
    <Flex flexDirection="row" gap="12px">
      {highlightedText && (
        <Text color="blaze.blaze" variant="modalTitle" fontWeight="600">
          {highlightedText}
        </Text>
      )}
      {regularText && (
        <Text
          color="dune.dune"
          variant="modalTitle"
          fontWeight="600"
          textDecoration={crossedOutText ? "line-through" : undefined}
        >
          {regularText}
        </Text>
      )}
    </Flex>
  );
}
