import { t } from "i18next";

import { Release } from "../../../types";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ReleasePurchaseData } from "./ReleasePurchaseData";
import { useUserData } from "../../../hooks/useUserData";
import { FunnelComponentProps } from "../../funnel/types";
import { ImmutableObject } from "@hookstate/core";

export function DownloadPdfModalBody({
  release,
  onClose,
}: FunnelComponentProps & {
  release?: ImmutableObject<Release>;
}) {
  if (!release) return null;

  const { userData } = useUserData();

  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Text
        variant="modalTitle"
        fontSize="24px"
        lineHeight="28px"
        textAlign="center"
        mt={1}
        mb={8}
        mx={8}
      >
        {t("components.payments.pdf.successYouCanNowDownload")}
      </Text>
      <Image
        borderRadius="10px"
        width="139px"
        height="215px"
        src={release.thumbnail_url}
        alt="Release thumbnail"
        mb={6}
      />
      <ReleasePurchaseData release={release} alignItems="center" />
      <Button
        mt={8}
        variant="primary"
        width="100%"
        size="md"
        onClick={() => {
          onClose();
          return window.open(`${userData?.edit_url}downloads`);
        }}
      >
        {t("components.payments.pdf.downloadYourRelease")}
      </Button>
    </Flex>
  );
}
