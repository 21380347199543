import { Icon, IconProps } from "@chakra-ui/react";

export function DownloadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35031 5.88586C4.26408 4.26387 6.00332 3.16663 8 3.16663C10.4992 3.16663 12.5961 4.88515 13.1749 7.20513C14.7746 7.44986 16 8.83181 16 10.5C16 12.3409 14.5076 13.8333 12.6667 13.8333H4C1.79086 13.8333 0 12.0424 0 9.83329C0 7.84531 1.44989 6.19645 3.35031 5.88586ZM8 4.49996C6.40292 4.49996 5.02311 5.43593 4.38167 6.7922C4.27816 7.01106 4.06413 7.1566 3.82255 7.17241C2.43285 7.26337 1.33333 8.4202 1.33333 9.83329C1.33333 11.3061 2.52724 12.5 4 12.5H12.6667C13.7712 12.5 14.6667 11.6045 14.6667 10.5C14.6667 9.39539 13.7712 8.49996 12.6667 8.49996C12.6549 8.49996 12.643 8.50006 12.631 8.50027C12.2949 8.5061 12.0071 8.26081 11.9595 7.92804C11.6825 5.99004 10.0147 4.49996 8 4.49996ZM8 5.83329C8.36819 5.83329 8.66667 6.13177 8.66667 6.49996V9.25434L9.63019 8.612C9.93654 8.40776 10.3505 8.49054 10.5547 8.7969C10.7589 9.10325 10.6761 9.51716 10.3698 9.7214L8.37345 11.0523C8.29034 11.1086 8.19385 11.1466 8.08975 11.1606C8.0295 11.1688 7.96839 11.1687 7.90816 11.1604C7.80556 11.1462 7.7104 11.1087 7.62823 11.0534L5.63019 9.7214C5.32384 9.51716 5.24105 9.10325 5.44529 8.7969C5.64952 8.49054 6.06344 8.40776 6.36979 8.612L7.33333 9.25436V6.49996C7.33333 6.13177 7.63181 5.83329 8 5.83329Z"
        fill="currentColor"
      />
    </Icon>
  );
}
