import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import { RefObject, useRef } from "react";
import { useEnsureFocus } from "../../../../hooks/useEnsureFocus";
import { useScrollbarWidth } from "../../../../utils/scrollbarWidth";
import { ErrorBoundary } from "../../../errors/ErrorBoundary";
import { PopoverSearchBar } from "../../../fields/SearchField";
import { SearchResponse } from "../../api/globalSearchApi";
import { SearchResults } from "./SearchResults";
import { LargeSearchTrigger, SmallSearchTrigger } from "./SearchTrigger";
import { useIsOverlayOpen } from "../../../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import { useIsSidebarOpen } from "../../../../hooks/useIsSidebarOpen";

interface SmallSearchPopoverProps {
  data?: SearchResponse;
  handleSearch: (term: string) => void;
  isFetching?: boolean;
  isOpen?: boolean;
  popoverRef: RefObject<HTMLElement>;
  searchTerm: string;
}

export function SmallSearchPopover({
  data,
  handleSearch,
  isFetching,
  popoverRef,
  searchTerm,
}: SmallSearchPopoverProps) {
  const scrollbarWidth = useScrollbarWidth();
  const { isOverlayOpen } = useIsOverlayOpen();
  const inputRef = useRef<HTMLInputElement>(null);
  const ensureFocus = useEnsureFocus();
  const { setIsSidebarOpen } = useIsSidebarOpen();

  // When the popover appears, the search box should be focused.
  // However, the input's container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (!inputRef.current) {
        return;
      }
      ensureFocus(inputRef.current);
    },
  });

  return (
    <Popover
      gutter={1}
      variant="navbar"
      closeOnBlur={true}
      initialFocusRef={focusHandler}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <SmallSearchTrigger onClick={() => setIsSidebarOpen(false)} />
          </PopoverTrigger>
          <Portal>
            <Box
              sx={{
                "@supports (-webkit-touch-callout: none)": {
                  height: "-webkit-fill-available",
                },
              }}
              zIndex={isOverlayOpen ? 1401 : 1}
              position="relative"
              w="full"
              h="full"
            >
              <PopoverContent
                variants={{
                  enter: { opacity: 1, top: -1, right: 0 },
                  exit: { opacity: 0, top: -50, right: 0 },
                }}
                // Left 0 is needed when right is 0 and we should stretch to fullscreen
                left={0}
                w="100vw"
                maxHeight="100vh"
                mr={scrollbarWidth + 1 + "px"}
                p={2}
                borderRight="1px solid transparent.white.10"
                background="transparent.black.90"
                borderTopRadius={0}
                borderRadius="0 0 24px 24px"
                border="1px solid"
                borderBottom="none"
                borderColor="transparent.white.10"
                backdropFilter="blur(18px)"
                ref={popoverRef}
              >
                <PopoverSearchBar
                  isOpen={true}
                  inputRef={inputRef}
                  handleSearch={handleSearch}
                />
                {isFetching ? (
                  <Flex justifyContent="center" alignItems="center">
                    <Spinner color="blaze.blaze" size="md" />
                  </Flex>
                ) : data ? (
                  <ErrorBoundary>
                    <SearchResults
                      results={data}
                      searchTerm={searchTerm}
                      onNavigate={onClose}
                    />
                  </ErrorBoundary>
                ) : null}
              </PopoverContent>
            </Box>
          </Portal>
        </>
      )}
    </Popover>
  );
}

interface LargeSearchPopoverProps {
  data?: SearchResponse;
  handleSearch: (term: string) => void;
  isOpen?: boolean;
  isFetching?: boolean;
  popoverRef: React.RefObject<HTMLElement>;
  searchTerm: string;
}
export function LargeSearchPopover({
  data,
  handleSearch,
  isOpen = false,
  isFetching = false,
  popoverRef,
  searchTerm = "",
}: LargeSearchPopoverProps) {
  return (
    <Popover
      gutter={1}
      variant="searchResults"
      autoFocus={false}
      isOpen={isOpen}
      placement="bottom-start"
      matchWidth={true}
    >
      <PopoverTrigger>
        <LargeSearchTrigger handleSearch={handleSearch} resultsOpen={isOpen} />
      </PopoverTrigger>
      <Portal>
        <Box zIndex={1402} position="relative" w="full" h="full">
          <PopoverContent
            style={{
              transition: "none",
            }}
            variants={{
              enter: {
                opacity: 1,
                maxHeight: 400,
                top: -5,
              },
              exit: {
                opacity: 0,
                maxHeight: 0,
              },
            }}
            // Match width to search trigger field
            w="100%"
            // Emulating padding since we're unable to style overflow container
            //borderRight="16px solid"
            ref={popoverRef}
          >
            <PopoverBody>
              {isFetching ? (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner color="blaze.blaze" size="md" />
                </Flex>
              ) : data ? (
                <ErrorBoundary>
                  <SearchResults results={data} searchTerm={searchTerm} />
                </ErrorBoundary>
              ) : null}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}
