import { Flex, Switch, Text } from "@chakra-ui/react";

export function SettingsToggle({
  isDisabled,
  isChecked,
  onChange,
  title,
  subtitle,
}: {
  isDisabled: boolean;
  isChecked: boolean;
  onChange: () => void;
  title: string;
  subtitle?: string;
}) {
  return (
    <Flex
      p={!subtitle ? "18px" : "16px"}
      gap="24px"
      alignItems="center"
      background="transparent.black.30"
      borderRadius="8px"
      border="1px solid"
      borderColor="transparent.white.10"
      cursor="pointer"
      justifyContent="space-between"
      opacity={!isDisabled ? 1 : 0.3}
    >
      <Flex flexDirection="column" justifyContent="center" gap="4px">
        <Text
          fontSize="14px"
          fontWeight="500"
          lineHeight="18.2px"
          color="neutral.200"
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            fontSize="10px"
            fontWeight="500"
            lineHeight="16px"
            color="neutral.500"
            letterSpacing=".25px"
          >
            {subtitle}
          </Text>
        )}
      </Flex>
      <Switch
        isDisabled={isDisabled}
        variant="readerSwitch"
        size="lg"
        isChecked={isChecked}
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
      />
    </Flex>
  );
}
