import { Box, Link as ChakraLink, Flex, Image, Text } from "@chakra-ui/react";
import { ContentCardBackdrop } from "./components/ContentCardBackdrop";

import { DateTime } from "luxon";
import { ArticleContentCardProps } from "../../types";
import { ChatIcon } from "../icons/ChatIcon";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { GridCardWrapper } from "./components/GridCardWrapper";
import { formatLargeNumber } from "../../utils/formatLargeNumber";

export function NewsGridCard({
  fullWidth = false,
  content,
  layout: { display, style },
}: ArticleContentCardProps) {
  const layout_size = style.size;

  const { height, width } = useGridCardDimensions(
    "news",
    style.mode,
    layout_size,
  );

  switch (layout_size) {
    case "l":
      return (
        <GridCardWrapper fullWidth={fullWidth} layout={{ display, style }}>
          <LargeNewsGridCard
            content={content}
            layout={{
              display,
              style,
            }}
            width={width}
            height={height}
          />
        </GridCardWrapper>
      );
    default:
      console.warn("Unsupported card size", layout_size);
      return null;
  }
}

function LargeNewsGridCard({
  content,
  layout: { display },
}: ArticleContentCardProps & {
  height?: number;
  width?: number;
}) {
  const {
    include_cover_image,
    include_posted_time,
    include_title,
    include_total_comments,
  } = display;
  return (
    <>
      <ContentCardBackdrop
        position="absolute"
        bottom="0px"
        h="calc(100% - 39px)"
      />
      <Flex
        direction="column"
        alignItems="center"
        position="relative"
        gap={3}
        w="full"
        height="100%"
        justifyContent="space-between"
      >
        <Box position="relative">
          {include_cover_image ? (
            <Image
              loading="lazy"
              src={content.preview_image_url}
              height="78px"
              w="137px"
              minWidth="137px"
              position="relative"
              borderRadius={5}
              alt={`Cover image for ${content.title}`}
              outline="1px solid"
              outlineColor="transparent.white.10"
              outlineOffset="-1px"
            />
          ) : null}
        </Box>
        <Flex gap={2} direction="column" alignSelf="flex-start">
          <Flex direction="column">
            {include_posted_time ? (
              <Text variant="cardVitals" color="neutral.300">
                {DateTime.fromSQL(content.published_time).toLocaleString()}
              </Text>
            ) : null}
            <Text variant="cardVitals" color="blue.200">
              <ChakraLink
                href={content.domain}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {content.domain}
              </ChakraLink>
            </Text>
          </Flex>
          {include_title ? (
            <Text
              variant="cardHeader"
              noOfLines={3}
              className="multiline-clamp"
            >
              {content.title}
            </Text>
          ) : null}
        </Flex>
        <Flex direction="column" width="100%" gap={2}>
          <Box h="1px" width="100%" bgColor="neutral.800" />
          <Flex
            direction="row"
            width="100%"
            justifyContent="space-between"
            pb="16px"
          >
            <Text variant="cardVitals" color="neutral.300">
              {content.author.name}
            </Text>
            {include_total_comments ? (
              <Flex direction="row" gap={1} alignItems="center">
                <ChatIcon w={4.5} h={4.5} color="blaze.300" />
                <Text variant="cardVitals" color="neutral.200">
                  {formatLargeNumber(content.total_comments)}
                </Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
