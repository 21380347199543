export const FUNNEL_TYPES = {
  signup: "signup",
  login: "login",
  signupEmailPassword: "signup-email-password",
  donate: "donate",
  donateConfirmation: "donate-confirmation",
  subscription: "subscription",
  pdf: "pdf",
  downloadPdf: "download-pdf",
  addToLibrary: "add-to-library",
  onboarding: "onboarding",
  purchaseRelease: "purchase-release",
  prePaywall: "pre-paywall",
} as const;

export type FUNNEL_TYPES_TYPES =
  (typeof FUNNEL_TYPES)[keyof typeof FUNNEL_TYPES];
