import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArtistContentCardProps } from "../../types";
import {
  ContentCardBackdrop,
  ExtendedContentCardBackdrop,
} from "./components/ContentCardBackdrop";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { CountryFlag } from "./components/CountryFlag";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { GridCardWrapper } from "./components/GridCardWrapper";

export function ArtistGridCard({
  content,
  layout: { display, style },
  ...props
}: ArtistContentCardProps) {
  const layout_size = style.size;

  const { height, width } = useGridCardDimensions(
    "creators",
    style.mode,
    layout_size,
  );

  const passedProps = {
    ...props,
    content,
    layout: { display, style },
    width,
    height,
  };
  switch (layout_size) {
    case "s":
      return <WideArtistGridCard {...passedProps} />;
    case "m":
    case "l":
      return (
        <GridCardWrapper layout={{ display, style }}>
          <TallArtistGridCard
            {...passedProps}
            isSmallCard={layout_size === "m"}
          />
        </GridCardWrapper>
      );
    default:
      console.warn("Unsupported card size", layout_size);
      return null;
  }
}

function WideArtistGridCard({
  content,
  layout: { display },
  height,
  width,
}: ArtistContentCardProps & {
  height?: number;
  width?: number;
}) {
  const {
    include_name,
    include_update,
    include_total_favorites,
    include_total_releases,
    include_title,
    include_footer = true,
  } = display;

  return (
    <Flex
      height={`${height}px`}
      width={`${width}px`}
      maxW={`${width}px`}
      direction="row"
      position="relative"
      alignItems="center"
      justifyContent="start"
      gap=".5rem"
    >
      <ContentCardBackdrop
        position="absolute"
        bottom={0}
        width="calc(100% - 8px)"
        left={2}
      />
      <Box position="relative">
        <Image
          loading="lazy"
          src={content.avatar_url}
          w="3.5rem"
          minW="3.5rem"
          borderRadius="14px"
          alt={`Cover image for ${content.roman_name}`}
        />
      </Box>
      <Flex
        direction="column"
        position="relative"
        pr={3}
        width="100%"
        minWidth={0}
        gap=".25rem"
      >
        <Flex direction="column" minWidth={0} width="100%">
          {include_name ? (
            <Text variant="cardSubtitle">{content.roman_name}</Text>
          ) : null}
          {include_title ? (
            <Text variant="cardVitals" color="neutral.200">
              {content.artist_type.name}
            </Text>
          ) : null}
        </Flex>
        {include_footer && (
          <ContentCardFooter
            total_favorites={content.total_favorites}
            total_projects={content.total_projects}
            total_published_releases={content.total_releases}
            update_time={content.last_activity}
            include_last_updated={include_update}
            include_total_favorites={include_total_favorites}
            include_total_releases={include_total_releases}
            include_total_projects={true}
            layoutMode="list"
            useAlternateFavoritesIcon
          />
        )}
      </Flex>
    </Flex>
  );
}

function TallArtistGridCard({
  content,
  layout: { display },
  isSmallCard,
  onToggleFollow,
}: ArtistContentCardProps & {
  width?: number;
  isSmallCard?: boolean;
}) {
  const {
    include_name,
    include_update,
    include_total_favorites,
    include_total_releases,
    include_follow,
    include_flag,
    include_title,
    include_footer = true,
  } = display;

  return (
    <>
      <ExtendedContentCardBackdrop
        position="absolute"
        bottom="0"
        h={`calc(100% - ${isSmallCard ? "40" : "55"}px)`}
        topBox={{
          height: `${isSmallCard ? "40" : "55"}px`,
        }}
        bottomBox={{
          height: `calc(100% - ${isSmallCard ? "40" : "55"}px)`,
        }}
      />
      <Image
        className="card-img-wrapper"
        // An aspect ratio of 1 causes other components to shift unexpectedly
        // this stops that from happening. So, creator avatars are not quite square.
        aspectRatio=".9999"
        src={content.avatar_url}
        borderRadius="28px"
        loading="lazy"
        alt={`Cover image for ${content.roman_name}`}
      />
      <Flex direction="column" position="relative" width="100%">
        {include_name ? (
          <Text
            variant={isSmallCard ? "cardSubtitle" : "cardHeader"}
            textAlign="center"
          >
            {content.roman_name}
          </Text>
        ) : null}
        {include_title ? (
          <Flex alignItems="center" justifyContent="center" gap=".5rem">
            {include_flag ? (
              <CountryFlag small={isSmallCard} countryId={content.country_id} />
            ) : null}
            <Text
              variant={isSmallCard ? "cardVitals" : "cardSubtitle"}
              color="neutral.200"
              textAlign="center"
            >
              {content.artist_type.name}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      {include_footer && (
        <ContentCardFooter
          total_favorites={content.total_favorites}
          total_projects={content.total_projects}
          total_published_releases={content.total_releases}
          update_time={content.last_activity}
          include_last_updated={include_update}
          include_total_favorites={include_total_favorites}
          include_total_releases={include_total_releases}
          include_total_projects={true}
          layoutMode="list"
          useAlternateFavoritesIcon
        />
      )}
      {!isSmallCard && include_follow ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (onToggleFollow) {
              onToggleFollow();
            }
          }}
          variant={content.user_is_following ? "tertiary" : "secondary"}
          w="100%"
          h="26px"
        >
          {content.user_is_following ? "Following" : "Follow"}
        </Button>
      ) : null}
    </>
  );
}
