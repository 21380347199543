import { clonable, Clonable } from "@hookstate/clonable";
import { comparable, Comparable } from "@hookstate/comparable";
import { devtools } from "@hookstate/devtools";
import {
  extend,
  hookstate,
  ImmutableArray,
  ImmutableObject,
  InferStateExtensionType,
  useHookstate,
} from "@hookstate/core";
import { initializable, Initializable } from "@hookstate/initializable";
import { snapshotable, Snapshotable } from "@hookstate/snapshotable";
import { cloneDeep, isEqual } from "lodash-es";
import { memo, useCallback, useEffect } from "react";
import { appStore } from "../../../../appStore.ts";
import { AudienceIcon } from "../../../../components/icons/AudienceIcon.tsx";
import { ClockIcon } from "../../../../components/icons/ClockIcon.tsx";
import { GenreIcon } from "../../../../components/icons/GenreIcon.tsx";
import { PenIcon } from "../../../../components/icons/PenIcon.tsx";
import { InitResponsePayloadResults } from "../../../../services/gc/types/index.ts";
import { ComicCrowdfundingTypeEntity } from "../../../../types.ts";
import {
  ARTIST_TYPE_ALL,
  ArtistTypeFilter,
  ArtistTypeFilterTitle,
} from "./ArtistTypeFilter";
import { BrowseFiltersCarousel } from "./BrowseFilterCarousel";
import { BrowseFiltersAccordion } from "./BrowseFiltersAccordion";
import {
  COMIC_CATEGORY_ALL,
  ComicCategoryFilterTitle,
  ComicCategoryTypeFilter,
} from "./ComicCategoryFilter";
import { COMIC_CONTRIBUTOR_TYPE_ALL } from "./ComicContributerTypeFilter";
import {
  COMIC_FORMAT_ALL,
  ComicFormatFilter,
  ComicFormatFilterTitle,
} from "./ComicFormatFilter";
import {
  COMIC_TYPE_ALL,
  ComicTypeFilter,
  ComicTypeFilterTitle,
} from "./ComicTypeFilter";
import {
  CountryFacetGroupFilter,
  CountryFacetGroupFilterTitle,
} from "./CountryFacetGroupFilter";
import {
  CROWDFUNDING_TYPE_ALL,
  CrowdfundingTypeFilter,
  CrowdfundingTypeFilterTitle,
} from "./CrowdfundingFilter.tsx";
import {
  LANGUAGE_ALL,
  LanguageFacetGroupFilter,
  LanguageFacetGroupFilterTitle,
} from "./LanguageFacetGroupFilter";
import {
  LICENSE_TYPE_ALL,
  LicenseTypeFilter,
  LicenseTypeFilterTitle,
} from "./LicenseTypeFilter";
import { useComicFacetGroupFilter } from "../../hooks/useComicFacetGroupFilter.tsx";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../../components/sortMenus/SortOrderMenu.tsx";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../../components/sortMenus/SortDurationMenu.tsx";
import { COMIC_USER_READING_STATUS_ALL } from "../../../../components/readingStatusButton/ReadingStatusButton.tsx";

export type FilterState = {
  query?: string;
  artistEntityType?: ImmutableObject<string>;
  comicEntityType?: ImmutableObject<string>;
  comicCategory?: ImmutableObject<string>;
  comicFormat?: ImmutableObject<string>;
  crowdfundingType?: ImmutableObject<string>;
  licenseType?: ImmutableObject<string>;
  countryFacets?: ImmutableArray<string>;
  languageFacets?: ImmutableObject<string>;
  comicFacets: ImmutableObject<Record<string, string[]>>;
  comicContributorType?: ImmutableObject<string>;
  comicUserReadingStatus?: ImmutableObject<string>;
  sortMenus: {
    sortOrder?: string;
    sortDuration?: string;
  };
  listId?: string;
  contributor?: string;
};

const DEFAULT_FILTER_STATE = {
  artistEntityType: ARTIST_TYPE_ALL,
  comicEntityType: COMIC_TYPE_ALL,
  comicCategory: COMIC_CATEGORY_ALL,
  comicFormat: COMIC_FORMAT_ALL,
  crowdfundingType: CROWDFUNDING_TYPE_ALL,

  licenseType: LICENSE_TYPE_ALL,
  countryFacets: [],
  languageFacets: LANGUAGE_ALL,
  comicContributorType: COMIC_CONTRIBUTOR_TYPE_ALL,
  comicUserReadingStatus: COMIC_USER_READING_STATUS_ALL,

  comicFacets: {},
  sortMenus: {
    sortOrder: DEFAULT_SORT_ORDER_TYPE,
    sortDuration: DEFAULT_SORT_DURATION_TYPE,
  },
};

function extensions<S>() {
  return extend<S, {}, {}, Clonable, Comparable, Snapshotable, Initializable>(
    devtools({ key: "filters" }),
    clonable((v: FilterState) => cloneDeep(v)),
    comparable((v1: FilterState, v2: FilterState) => +isEqual(v1, v2)),
    // if generic type is ommited, the snapshot key can be any string
    // otherwise, only those names which are provided
    snapshotable(),
    initializable((s) => {
      // @ts-ignore
      s.snapshot(); // take the initial snapshot with default key
    }),
  );
}

type Extended = InferStateExtensionType<typeof extensions>;

export const browseFilterStore = hookstate<FilterState, Extended>(
  DEFAULT_FILTER_STATE,
  extensions(),
);

interface BrowseFiltersProps {
  frozen?: boolean;
  isModal?: boolean;
  invalidate?: () => void;
}
export const BrowseFilters = memo(
  ({ frozen = false, isModal = false, invalidate }: BrowseFiltersProps) => {
    const state = useHookstate(appStore);
    const {
      artist_types: artistTypes,
      comic_crowdfunding_types: crowdfundingTypes,
      comic_facet_groups: comicFacetGroups,
      comic_formats: comicFormats,
      comic_types: comicTypes,
      comic_genres: comicGenres,
      countries: countries,
      languages: languages,
      license_types: licenseTypes,
    } = state.init.get() as InitResponsePayloadResults & {
      comic_crowdfunding_types: ComicCrowdfundingTypeEntity[];
    };

    useEffect(() => {
      if (isModal) {
        browseFilterStore.snapshot("modal");
      }
    }, [isModal, frozen]);

    const filterStore = useHookstate(browseFilterStore);
    const handleFacetGroupChange = useCallback(
      (categoryId: number, nextValue: string[]) => {
        filterStore.comicFacets[`${categoryId}`].set(nextValue);
      },
      [],
    );

    const audienceFacetFilter = useComicFacetGroupFilter(
      filterStore,
      isModal,
      comicFacetGroups
        ? comicFacetGroups.filter((facet) => facet.id === 1)
        : [],
      handleFacetGroupChange,
      <AudienceIcon />,
    );
    const themeFacetFilter = useComicFacetGroupFilter(
      filterStore,
      isModal,
      comicFacetGroups
        ? comicFacetGroups.filter((facet) => facet.id === 2)
        : [],
      handleFacetGroupChange,
      <GenreIcon />,
    );
    const artStyleFacetFilter = useComicFacetGroupFilter(
      filterStore,
      isModal,
      comicFacetGroups
        ? comicFacetGroups.filter((facet) => facet.id === 5)
        : [],
      handleFacetGroupChange,
      <PenIcon />,
    );
    const timeEraStyleFacetFilter = useComicFacetGroupFilter(
      filterStore,
      isModal,
      comicFacetGroups
        ? comicFacetGroups.filter((facet) => facet.id === 3)
        : [],
      handleFacetGroupChange,
      <ClockIcon />,
    );

    // TODO figure out a better way to type this
    const filters = [
      comicTypes
        ? [
            <ComicTypeFilterTitle
              isTrigger={!isModal}
              comicTypes={comicTypes}
              value={filterStore.comicEntityType.get()}
            />,
            <ComicTypeFilter
              value={filterStore.comicEntityType.get()}
              comicTypes={comicTypes}
              onChange={(nextType) => {
                filterStore.comicEntityType.set(nextType);
              }}
            />,
            filterStore.comicEntityType,
          ]
        : null,
      comicGenres
        ? [
            <ComicCategoryFilterTitle
              isTrigger={!isModal}
              comicCategories={comicGenres}
              value={filterStore.comicCategory.get()}
            />,
            <ComicCategoryTypeFilter
              value={filterStore.comicCategory.get()}
              comicCategories={comicGenres}
              onChange={(nextCategory) => {
                filterStore.comicCategory.set(nextCategory);
              }}
            />,
            filterStore.comicCategory,
          ]
        : null,
      ...themeFacetFilter,
      licenseTypes
        ? [
            <LicenseTypeFilterTitle
              isTrigger={!isModal}
              licenseTypes={licenseTypes}
              value={filterStore.licenseType.get()}
            />,
            <LicenseTypeFilter
              value={filterStore.licenseType.get()}
              licenseTypes={licenseTypes}
              onChange={(nextLicenseType) => {
                filterStore.licenseType.set(nextLicenseType);
              }}
            />,
            filterStore.licenseType,
          ]
        : null,
      artistTypes
        ? [
            <ArtistTypeFilterTitle
              isTrigger={!isModal}
              artistTypes={artistTypes}
              value={filterStore.artistEntityType.get()}
            />,
            <ArtistTypeFilter
              value={filterStore.artistEntityType.get()}
              artistTypes={artistTypes}
              onChange={(nextAristType) => {
                filterStore.artistEntityType.set(nextAristType);
              }}
            />,
            filterStore.artistEntityType,
          ]
        : null,
      ...audienceFacetFilter,
      comicFormats
        ? [
            <ComicFormatFilterTitle
              isTrigger={!isModal}
              comicFormats={comicFormats}
              value={filterStore.comicFormat.get()}
            />,
            <ComicFormatFilter
              value={filterStore.comicFormat.get()}
              comicFormats={comicFormats}
              onChange={(nextComicFormat) => {
                filterStore.comicFormat.set(nextComicFormat);
              }}
            />,
            filterStore.comicFormat,
          ]
        : null,
      ...artStyleFacetFilter,
      crowdfundingTypes
        ? [
            <CrowdfundingTypeFilterTitle
              isTrigger={!isModal}
              crowdfundingTypes={crowdfundingTypes}
              value={filterStore.crowdfundingType.get()}
            />,
            <CrowdfundingTypeFilter
              value={filterStore.crowdfundingType.get()}
              crowdfundingTypes={crowdfundingTypes}
              onChange={(nextCrowdfundingType) => {
                filterStore.crowdfundingType.set(nextCrowdfundingType);
              }}
            />,
            filterStore.crowdfundingType,
          ]
        : null,
      countries
        ? [
            <CountryFacetGroupFilterTitle
              isTrigger={!isModal}
              countries={countries}
            />,
            <CountryFacetGroupFilter
              value={filterStore.countryFacets.get()}
              countries={countries}
              onChange={(nextCountries) => {
                filterStore.countryFacets.set(nextCountries);
              }}
            />,
            filterStore.countryFacets,
          ]
        : null,
      languages
        ? [
            <LanguageFacetGroupFilterTitle
              isTrigger={!isModal}
              languages={languages}
            />,
            <LanguageFacetGroupFilter
              value={filterStore.languageFacets.get()}
              languages={languages}
              onChange={(nextLanguages) => {
                filterStore.languageFacets.set(nextLanguages);
              }}
            />,
            filterStore.languageFacets,
          ]
        : null,
      ...timeEraStyleFacetFilter,
    ];

    if (isModal) {
      return <BrowseFiltersAccordion filters={filters} />;
    }
    return (
      <BrowseFiltersCarousel
        invalidate={() => {
          invalidate?.();
        }}
        filters={filters}
      />
    );
  },
);
