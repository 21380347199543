import { Text, Flex, BoxProps } from "@chakra-ui/react";

interface StatsProps extends BoxProps {
  stats: { value?: number | string; text: string }[];
}
export function Stats({ stats, ...props }: StatsProps) {
  return (
    <Flex gap="2px">
      {stats.map(({ value, text }) => (
        <Flex
          key={text}
          flexDirection="column"
          justifyContent="center"
          borderRadius="4px"
          _first={{
            borderLeftRadius: "8px",
            borderRightRadius: "4px",
          }}
          _last={{
            borderLeftRadius: "4px",
            borderRightRadius: "8px",
          }}
          bg="transparent.white.10"
          width="full"
          height="48px"
          {...props}
        >
          <Text
            fontFamily="Roboto"
            textAlign="center"
            fontSize="14px"
            lineHeight="18.2px"
            fontWeight="700"
            color="neutral.200"
          >
            {value}
          </Text>
          <Text
            fontFamily="Roboto"
            textAlign="center"
            fontSize="10px"
            lineHeight="15px"
            fontWeight="400"
            color="neutral.300"
          >
            {text}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}
