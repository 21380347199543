import { Flex, Text } from "@chakra-ui/react";
import { cloneElement } from "react";
import { ArrowForwardIcon } from "../../icons/ArrowForwardIcon";

export function ComplexMenuItem({
  title,
  subtitle,
  onClick,
  icon,
}: {
  title: string;
  subtitle: string;
  onClick: () => void;
  icon?: JSX.Element;
}) {
  return (
    <Flex
      as="a"
      cursor="pointer"
      gc-testing-id="btnGetGold"
      width="100%"
      color="dune.700"
      justifyContent="space-between"
      role="group"
      paddingRight="8px"
      _hover={icon ? undefined : { paddingRight: "0px" }}
      transition="all .2s linear"
      onClick={onClick}
    >
      <Flex direction="column" transition="inherit">
        <Text
          fontSize="14px"
          fontWeight="500"
          lineHeight="130%"
          letterSpacing=".5px"
          fontFamily="Roboto"
          transition="inherit"
          color={"blaze.blaze"}
        >
          {title}
        </Text>
        <Text
          fontSize="12px"
          fontWeight="400"
          lineHeight="130%"
          letterSpacing=".5px"
          color={"dune.700"}
          fontFamily="Roboto"
          _groupHover={{ color: "dune.100" }}
          transition="inherit"
        >
          {subtitle}
        </Text>
      </Flex>

      {icon ? (
        cloneElement(icon, {
          boxSize: "20px",
          _groupHover: { color: "blaze.blaze" },
          transition: "inherit",
        })
      ) : (
        <ArrowForwardIcon
          width="20px"
          height="20px"
          _groupHover={{ color: "blaze.blaze" }}
          transition="inherit"
        />
      )}
    </Flex>
  );
}
