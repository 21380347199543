import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const sizes = {
  md: definePartsStyle({
    tab: {
      fontSize: "md",
      py: "8px",
      px: "12px",
    },
  }),
};

const channelTabs = definePartsStyle({
  tab: {
    borderRadius: "100px",
    color: "dune.700",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "130%",
    whiteSpace: "nowrap",
    _selected: {
      bg: "transparent",
      color: "neutral.white",
      ".active-tab-indicator": {
        visibility: "visible",
      },
    },
  },
});

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    channelTabs,
  },
  sizes,
});
