import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { forwardRef, Fragment, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import {
  useIsSmallBreakpoint,
  useMobileBreakpoint,
} from "../../../utils/useBreakpoints";
import { useEnsureFocus } from "../../../hooks/useEnsureFocus";
import { NotificationIcon } from "../../icons/NotificationIcon";
import {
  GET_NOTIFICATIONS_QUERY_KEY,
  GET_NOTIFICATIONS_UNSEEN_COUNT_QUERY_KEY,
  getNotifications,
  getNotificationsUnseenCount,
  NotificationEntity,
  postMarkAllSeen,
  postAcknowledgeNotification,
  postMarkAllRead,
} from "../api/notificationsApi";
import { useIsOverlayOpen } from "../../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import { useIsSidebarOpen } from "../../../hooks/useIsSidebarOpen";
import { isMobile } from "react-device-detect";
import { NAVBAR_HEIGHT } from "../../../screens/root/constants";
import { AxiosResponse } from "axios";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";

export function NotificationMenu() {
  const [t] = useTranslation();
  const isMobileScreen = useMobileBreakpoint();
  const { isOverlayOpen: isReaderOverlayOpen } = useIsOverlayOpen();
  const { setIsSidebarOpen } = useIsSidebarOpen();
  const isSmall = useIsSmallBreakpoint();

  const popoverRef = useRef(null);
  const ensureFocus = useEnsureFocus();

  // When the popover appears, the notification box should be focused.
  // However, the container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (!popoverRef.current) {
        return;
      }
      ensureFocus(popoverRef.current);
    },
  });

  const invalidateNotificationsUnseenCount = useInvalidateQueries(
    GET_NOTIFICATIONS_UNSEEN_COUNT_QUERY_KEY,
  );

  const { mutate: triggerRequest } = useMutation({
    mutationFn: postMarkAllSeen,
    onSuccess: invalidateNotificationsUnseenCount,
  });

  const invalidateNotifications = useInvalidateQueries(
    GET_NOTIFICATIONS_QUERY_KEY,
  );

  const { mutate: triggerMarkAsReadRequest } = useMutation({
    mutationFn: postMarkAllRead,
    onSuccess: invalidateNotifications,
  });

  const handleMarkAllAsSeen = useCallback(
    () => triggerRequest(),
    [triggerRequest],
  );

  const handleMarkAllAsRead = useCallback(
    () => triggerMarkAsReadRequest(),
    [triggerMarkAsReadRequest],
  );

  return (
    <Popover gutter={1} variant="navbar" initialFocusRef={focusHandler} isLazy>
      <PopoverTrigger>
        <NotificationTrigger
          onClick={() => {
            if (isSmall) {
              setIsSidebarOpen(false);
            }
            handleMarkAllAsSeen();
          }}
        />
      </PopoverTrigger>
      <Portal>
        <Box
          sx={{
            "@supports (-webkit-touch-callout: none)": {
              height: "-webkit-fill-available",
            },
          }}
          zIndex={isReaderOverlayOpen ? 1401 : 1}
          position="relative"
          w="full"
          h="full"
        >
          <PopoverContent
            ref={popoverRef}
            variants={{
              enter: { opacity: 1, top: 0, right: 0 },
              exit: { opacity: 0, top: -NAVBAR_HEIGHT, right: 0 },
            }}
            height={isMobileScreen ? "100%" : undefined}
            maxHeight={isMobileScreen ? "100%" : "90%"}
            width={isMobileScreen ? "100%" : undefined}
            borderRadius={isMobileScreen ? 0 : undefined}
          >
            <PopoverHeader>
              <Flex direction="row" justify="space-between">
                <Text
                  fontSize="20px"
                  lineHeight="26px"
                  fontWeight="bold"
                  fontFamily="Bricolage Grotesque"
                >
                  {t("components.navigation.notifications.title")}
                </Text>
                <Text
                  onClick={handleMarkAllAsRead}
                  fontSize="14px"
                  fontWeight={500}
                  color="neutral.400"
                  cursor="pointer"
                >
                  {t("components.navigation.notifications.markAllRead")}
                </Text>
              </Flex>
            </PopoverHeader>
            <PopoverBody>
              <NotificationList />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

const NotificationTrigger = forwardRef<HTMLDivElement, ButtonProps>(
  ({ ...props }, ref) => {
    const { data } = useQuery({
      queryKey: GET_NOTIFICATIONS_UNSEEN_COUNT_QUERY_KEY,
      queryFn: getNotificationsUnseenCount,
    });

    const count = data?.data.payload.results.unseen_notifications_count ?? 0;
    return (
      <Flex height="full" ref={ref} alignItems="flex-end">
        <Tooltip
          variant="navbar"
          label="Notifications"
          aria-label="notifications-tooltip"
          isDisabled={!!props["aria-expanded"]}
        >
          <Button aria-label="Notifications Menu" variant="navbar" {...props}>
            {count > 0 && (
              <Box
                position="absolute"
                top="4px"
                right="21px"
                borderRadius="50%"
                width="4px"
                height="4px"
                background="blaze.blaze"
              />
            )}
            <NotificationIcon w={6} h={6} color="white" />
          </Button>
        </Tooltip>
      </Flex>
    );
  },
);

const NOTIFICATION_TYPES = {
  published: 12,
};
function NotificationList() {
  const { isLoading, data } = useQuery({
    queryKey: GET_NOTIFICATIONS_QUERY_KEY,
    queryFn: getNotifications,
    refetchOnWindowFocus: false,
  });

  const results = data?.data.payload.results ?? [];

  const invalidateNotifications = useInvalidateQueries(
    GET_NOTIFICATIONS_QUERY_KEY,
  );

  const { mutate: acknowledgeNotification } = useMutation({
    mutationFn: postAcknowledgeNotification,
    onSuccess: invalidateNotifications,
  });

  return (
    <Flex
      direction="column"
      gap={4}
      overflow="auto"
      paddingBottom={isMobile ? "60px" : undefined}
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center">
          <Spinner color="blaze.blaze" size="md" />
        </Flex>
      ) : results.length ? (
        results.map((notification: NotificationEntity, i) => (
          <NotificationCard
            notification={notification}
            index={i}
            acknowledgeNotification={acknowledgeNotification}
            addDivider={i < results.length - 1}
          />
        ))
      ) : (
        <NotificationsEmptyState />
      )}
    </Flex>
  );
}

function NotificationCard({
  notification,
  index,
  acknowledgeNotification,
  addDivider,
}: {
  notification: NotificationEntity;
  index: number;
  acknowledgeNotification: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    number,
    unknown
  >;
  addDivider: boolean;
}) {
  const isTypePublished =
    notification.activity_type === NOTIFICATION_TYPES.published;

  const { goToCTAUrl } = useNavigateToReaderWithContext({
    notificationTypeId: notification.activity_id,
    cardPosition: index,
    area: "notifications",
    releaseToNavigate: {
      ...notification.context.release,
      entity_type: "Release",
    },
  });

  return (
    <Fragment key={notification.ack_url}>
      <Link
        onClick={() => {
          acknowledgeNotification(notification.notification_id);
          if (isTypePublished) {
            goToCTAUrl();
          }
        }}
        href={!isTypePublished ? notification.ack_url : undefined}
      >
        <Flex
          direction="row"
          gap={2}
          cursor="pointer"
          justifyContent="space-between"
        >
          <Flex direction="row" alignItems="center" gap={4}>
            <Flex height="full" alignItems="flex-start" gap={4}>
              <Flex height={8} alignItems="center">
                <Box
                  background={notification.is_clicked ? "none" : "orange.500"}
                  height="10px"
                  width="10px"
                  borderRadius="50%"
                ></Box>
              </Flex>
              <Flex
                width={8}
                height={8}
                alignItems="center"
                justifyContent="center"
                border="2px solid"
                borderColor="transparent.white.20"
                borderRadius={isTypePublished ? "8px" : "50%"}
                background="linear-gradient(44.55deg, #000000 0.78%, #2A2E37 100%);"
              >
                <Image
                  loading="lazy"
                  borderRadius={isTypePublished ? "6px" : "50%"}
                  src={
                    isTypePublished
                      ? notification.context.artist?.avatar_tiny_url
                      : notification.context.creator_user?.avatar_small_url
                  }
                  alt={`Image for ${
                    isTypePublished
                      ? notification.context.artist?.roman_name
                      : notification.context.creator_user?.name
                  }`}
                  width={8}
                  height={8}
                  aria-label={`Avatar for ${
                    isTypePublished
                      ? notification.context.artist?.roman_name
                      : notification.context.creator_user?.name
                  }`}
                  role="img"
                />
              </Flex>
            </Flex>
            <Flex direction="column" gap={2}>
              <Flex direction="row" alignItems="center" gap={2}>
                <Text color="neutral.400" fontSize="xs">
                  {notification.time_ago}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text
                  color="neutral.300"
                  fontSize="sm"
                  noOfLines={2}
                  lineHeight="18px"
                  dangerouslySetInnerHTML={{
                    __html: notification.message_html,
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Link>
      {addDivider ? (
        <Box minHeight="1px" w="full" background="neutral.800" />
      ) : null}
    </Fragment>
  );
}

function NotificationsEmptyState() {
  const [t] = useTranslation();
  return (
    <Flex direction="row" justifyContent="center">
      <Text color="neutral.300" fontSize="md" fontWeight="bold">
        {t("components.navigation.notifications.empty")}
      </Text>
    </Flex>
  );
}
