import { Text, Box, Button } from "@chakra-ui/react";
import { useMobileBreakpoint } from "../../../../utils/useBreakpoints";
import { InfoIcon } from "../../../../components/icons/reader/InfoIcon";
import { FollowingIcon } from "../../../../components/icons/FollowingIcon";
import { FollowIcon } from "../../../../components/icons/FollowIcon";
import { BookmarkIcon } from "../../../../components/icons/BookmarkIcon";
import { ChatIcon } from "../../../../components/icons/ChatIcon";
import { motion } from "framer-motion";
import { cloneElement } from "react";
import { t } from "i18next";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import { BookmarkCheckedIcon } from "../../../../components/icons/reader/BookmarkCheckedIcon";
import { useComicData } from "../../hooks/hookstate/useComicData";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";
import { Artist, Comic } from "../../../../types";
import { useQuery } from "@tanstack/react-query";
import { useToggleArtistFollow } from "../../../../components/cards/hooks/useToggleArtistFollow";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function OverlayActions() {
  const { comicData } = useComicData();
  const isMobile = useMobileBreakpoint();

  const artistCacheKey = `${comicData?.artist.entity_type}-${comicData?.artist.id}`;
  const handleToggleArtistFollow = useToggleArtistFollow(artistCacheKey);

  const { onToggleSlideInMenu } = useSlideInMenu();

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.followArtist,
    artistId: comicData?.artist.id,
    comic: comicData as Comic,
  });

  const cacheKey = `${comicData?.entity_type}-${comicData?.id}`;
  const { data: cachedComicData } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { data: artistData } = useQuery<Artist>({
    queryKey: [artistCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const userIsFollowing = artistData?.user_is_following;
  return (
    <Box
      as={motion.div}
      animate={{ x: [0, -25], opacity: [0, 1] }}
      position="absolute"
      bottom={isMobile ? "100px" : "25px"}
      right={0}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      gap="8px"
    >
      {!isMobile && (
        <ReaderActionButton
          text={t("screens.reader.overlay.series_info")}
          icon={<InfoIcon />}
          buttonAction={() => onToggleSlideInMenu(SLIDE_IN_MENUS.comicDetails)}
        />
      )}
      <ReaderActionButton
        text={
          userIsFollowing
            ? t("screens.reader.overlay.unfollow")
            : t("screens.reader.overlay.follow")
        }
        color={userIsFollowing ? "blaze.400" : "white"}
        icon={userIsFollowing ? <FollowingIcon /> : <FollowIcon />}
        buttonAction={() => {
          handleToggleArtistFollow();
        }}
      />
      <ReaderActionButton
        text={
          comicData?.library_status
            ? t("screens.reader.overlay.manage")
            : t("screens.reader.overlay.add_to")
        }
        color={cachedComicData?.library_status ? "blaze.400" : "white"}
        icon={
          cachedComicData?.library_status ? (
            <BookmarkCheckedIcon />
          ) : (
            <BookmarkIcon />
          )
        }
        buttonAction={() => {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.addToLibrary,
            options: {
              cacheKey,
              navigationContext,
              modalContext: FunnelContextArea.addToLibrary,
            },
          });
        }}
      />
      <ReaderActionButton
        text={t("screens.reader.overlay.comments")}
        icon={<ChatIcon />}
        buttonAction={() => onToggleSlideInMenu(SLIDE_IN_MENUS.comments)}
      />
    </Box>
  );
}

interface ReaderActionButtonProps {
  text: string;
  icon: JSX.Element;
  buttonAction?: () => void;
  color?: string;
}
function ReaderActionButton({
  text,
  icon,
  buttonAction,
  color = "white",
}: ReaderActionButtonProps) {
  const isMobile = useMobileBreakpoint();
  return (
    <Button
      variant={isMobile ? "readerActionIconButton" : "readerActionButton"}
      aria-label={text}
      color={color}
      onClick={(e) => {
        e.stopPropagation();
        buttonAction?.();
      }}
    >
      {!isMobile && (
        <Text fontSize="12px" lineHeight="16px" fontWeight="500">
          {text}
        </Text>
      )}
      {cloneElement(icon, { width: "28px", height: "28px" })}
    </Button>
  );
}
