import { Flex } from "@chakra-ui/react";
import { ReleaseCommentEntity } from "../../../../types";
import { ImmutableObject } from "@hookstate/core";
import { CommentBody } from "./CommentBody";
import { CommentHeader } from "./CommentHeader";
import { Dispatch, SetStateAction } from "react";

interface CommentProps {
  comment: ImmutableObject<ReleaseCommentEntity>;
  replyTo: Dispatch<
    SetStateAction<ImmutableObject<ReleaseCommentEntity> | undefined>
  >;
}
export function Comment({ comment, replyTo }: CommentProps) {
  return (
    <Flex flexDirection="column" width="100%">
      <CommentHeader comment={comment} />
      <CommentBody comment={comment} replyTo={replyTo} />
      <Flex
        flexDirection="column"
        paddingLeft="44px"
        paddingTop="4px"
        gap="12px"
      >
        {comment.replies.map((reply) => (
          <Flex
            key={reply.id}
            flexDirection="column"
            width="100%"
            background="transparent.white.05"
            p="12px"
            borderRadius="16px"
          >
            <CommentHeader comment={reply} />
            <CommentBody replyTo={replyTo} comment={reply} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
