import { Icon, IconProps } from "@chakra-ui/react";

export function AddBubbleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.83073C12.1759 5.83073 5.83329 12.1734 5.83329 19.9974C5.83329 27.8214 12.1759 34.1641 20 34.1641C27.824 34.1641 34.1666 27.8214 34.1666 19.9974C34.1666 12.1734 27.824 5.83073 20 5.83073ZM4.16663 19.9974C4.16663 11.2529 11.2555 4.16406 20 4.16406C28.7445 4.16406 35.8333 11.2529 35.8333 19.9974C35.8333 28.7419 28.7445 35.8307 20 35.8307C11.2555 35.8307 4.16663 28.7419 4.16663 19.9974ZM12.5 19.9974C12.5 19.5372 12.8731 19.1641 13.3333 19.1641H19.1666V13.3307C19.1666 12.8705 19.5397 12.4974 20 12.4974C20.4602 12.4974 20.8333 12.8705 20.8333 13.3307V19.1641H26.6666C27.1269 19.1641 27.5 19.5372 27.5 19.9974C27.5 20.4576 27.1269 20.8307 26.6666 20.8307H20.8333V26.6641C20.8333 27.1243 20.4602 27.4974 20 27.4974C19.5397 27.4974 19.1666 27.1243 19.1666 26.6641V20.8307H13.3333C12.8731 20.8307 12.5 20.4576 12.5 19.9974Z"
        fill="#737373"
      />
    </Icon>
  );
}
