import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { Release } from "../../../../types";

export function useReleaseData() {
  const releaseData = useHookstate(readerDataStore.releaseData).get({
    noproxy: true,
  });

  const setReleaseData = (data: Release) => {
    readerDataStore.releaseData.set(data);
  };

  return { releaseData, setReleaseData };
}
