import { Icon, IconProps } from "@chakra-ui/react";

export function CaretLeftLargeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3536 4.64645C15.5488 4.84171 15.5488 5.15829 15.3536 5.35355L8.70711 12L15.3536 18.6464C15.5488 18.8417 15.5488 19.1583 15.3536 19.3536C15.1583 19.5488 14.8417 19.5488 14.6464 19.3536L7.64645 12.3536C7.45118 12.1583 7.45118 11.8417 7.64645 11.6464L14.6464 4.64645C14.8417 4.45118 15.1583 4.45118 15.3536 4.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
