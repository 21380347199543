import { Icon, IconProps } from "@chakra-ui/react";

export function AwaLogo(props: IconProps) {
  return (
    <Icon viewBox="0 0 200 84" {...props} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_17)">
        <path
          d="M0 83.0936H15.0021L30.2528 52.8574H52.9134L68.1641 83.0936L83.0667 82.8947L41.5748 0.888924L0 83.0936ZM35.143 41.0713L41.5914 28.3734L48.0398 41.0878L35.143 41.0713Z"
          fill="currentColor"
        />
        <path
          d="M158.508 0.888924L116.933 83.0771H131.935L147.186 52.8409H169.847L185.097 83.0771L200 82.8781L158.508 0.888924ZM152.06 41.0878L158.508 28.3734L164.956 41.0878H152.06Z"
          fill="currentColor"
        />
        <path
          d="M88.305 83.0937H111.861L100.091 60.0021L88.305 83.0937Z"
          fill="currentColor"
        />
        <path
          d="M128.04 0.690002L114.132 28.1745L100.091 0.690002L86.0506 28.1745L72.126 0.690002H56.7095L86.0506 58.6593L100.24 31.1417L114.132 58.6593L143.456 0.690002H128.04Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_17">
          <rect
            width="200"
            height="82.4036"
            fill="currentColor"
            transform="translate(0 0.690002)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
