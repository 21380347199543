import { useMemo } from "react";
import { ContentCardProps, CustomCardTypeValues } from "../../../types";
import { CUSTOM_CARD_TYPES } from "../constants";

type SizeEstimate = {
  width: number;
  height: number;
};
type LayoutSize = ContentCardProps["layout"]["style"]["size"];
export type LayoutContext =
  | "comics"
  | "creators"
  | "news"
  | "releases"
  | CustomCardTypeValues;
type LayoutMode = ContentCardProps["layout"]["style"]["mode"];

export const sizeMap: Record<
  LayoutContext,
  Record<LayoutMode, Partial<Record<LayoutSize, SizeEstimate>>>
> = {
  comics: {
    list: {
      s: {
        width: 385,
        height: 81,
      },
      c_1: {
        width: 385,
        height: 116,
      },
      r_list: {
        width: 358,
        height: 184,
      },
    },
    grid: {
      s: {
        width: 184,
        height: 93,
      },
      m: {
        width: 118,
        height: 210,
      },
      l: {
        width: 171,
        height: 340,
      },
      // Used by browse and library page (ResultsGrid.tsx component)
      xl: {
        width: 221,
        height: 382,
      },
    },
    horizontal: {
      s: {
        width: 168,
        height: 94,
      },
      m: {
        width: 109,
        height: 195,
      },
      l: {
        width: 155,
        height: 330,
      },
      c_1: {
        width: 333,
        height: 116,
      },
      r_list: {
        width: 342,
        height: 184,
      },
    },
    calendar: {
      c_1: {
        width: 385,
        height: 116,
      },
    },
  },
  releases: {
    list: {
      s: {
        width: 385,
        height: 81,
      },
      c_1: {
        width: 385,
        height: 116,
      },
    },
    grid: {
      s: {
        width: 184,
        height: 93,
      },
      m: {
        width: 118,
        height: 210,
      },
      l: {
        width: 171,
        height: 290,
      },
    },
    horizontal: {
      s: {
        width: 168,
        height: 94,
      },
      m: {
        width: 109,
        height: 195,
      },
      l: {
        width: 155,
        height: 280,
      },
      c_1: {
        width: 385,
        height: 116,
      },
    },
    calendar: {},
  },
  creators: {
    list: {
      s: {
        width: 358,
        height: 72,
      },
      c_1: {
        width: 430,
        height: 96,
      },
    },
    grid: {
      s: {
        width: 171,
        height: 72,
      },
      m: {
        width: 118,
        height: 180,
      },
      l: {
        width: 171,
        height: 255,
      },
    },
    horizontal: {
      s: {
        width: 167,
        height: 72,
      },
      m: {
        width: 109,
        height: 170,
      },
      l: {
        width: 155,
        height: 245,
      },
      c_1: {
        width: 342,
        height: 96,
      },
    },
    calendar: {},
  },
  news: {
    list: {
      s: {
        width: 385,
        height: 110,
      },
      xl: {
        width: 385,
        height: 362,
      },
    },
    grid: {
      l: {
        width: 171,
        height: 210,
      },
    },
    horizontal: {
      s: {
        width: 314,
        height: 126,
      },
      xl: {
        width: 358,
        height: 362,
      },
    },
    calendar: {},
  },
  [CUSTOM_CARD_TYPES.hero]: {
    list: {},
    grid: {},
    horizontal: {},
    calendar: {},
  },
  [CUSTOM_CARD_TYPES.featuredCarousel]: {
    list: {},
    grid: {},
    horizontal: {},
    calendar: {},
  },
  [CUSTOM_CARD_TYPES.featuredSeriesRoadblock]: {
    list: {},
    grid: {},
    horizontal: {},
    calendar: {},
  },
  [CUSTOM_CARD_TYPES.streamLinks]: {
    list: {},
    grid: {},
    horizontal: {},
    calendar: {},
  },
};

export function sizeEstimator(
  layoutContext: LayoutContext,
  layoutMode: LayoutMode,
  layoutSize: LayoutSize,
) {
  const customCards = [
    CUSTOM_CARD_TYPES.hero,
    CUSTOM_CARD_TYPES.featuredCarousel,
    CUSTOM_CARD_TYPES.featuredSeriesRoadblock,
    CUSTOM_CARD_TYPES.streamLinks,
  ];

  if (customCards.includes(layoutContext as CustomCardTypeValues)) {
    return {
      width: 0,
      height: 0,
    };
  }

  const sizeEstimate = sizeMap[layoutContext]?.[layoutMode]?.[layoutSize];

  if (!sizeEstimate) {
    return {
      width: 0,
      height: 0,
    };
  }

  return sizeEstimate;
}

export function useGridCardDimensions(
  layoutContext: LayoutContext,
  layoutMode: LayoutMode,
  layoutSize: LayoutSize,
) {
  const result = useMemo(
    () => sizeEstimator(layoutContext, layoutMode, layoutSize),
    [layoutContext, layoutMode, layoutSize],
  );

  return result;
}
