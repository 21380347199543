import { Box, BoxProps, Flex, Text, TextProps } from "@chakra-ui/react";
import { SharpCornerBox } from "./SharpCornerBox";

interface FreeRibbonProps extends BoxProps {
  fontSize?: TextProps["fontSize"];
}

export function FreeRibbon({
  color,
  fontSize,
  width,
  height,
  ...props
}: FreeRibbonProps) {
  return (
    <SharpCornerBox
      corners={["bottomLeft", "topRight"]}
      background="transparent.black.80"
      borderWidth={0}
      {...props}
    >
      <Flex direction="row" alignItems="center" gap={1}>
        <Box
          borderRadius="100px"
          width={width ?? "2px"}
          height={height ?? "7px"}
          backgroundColor={color}
        />
        <Text
          fontSize={fontSize ?? "xs"}
          fontWeight={700}
          textTransform="uppercase"
        >
          Free
        </Text>
      </Flex>
    </SharpCornerBox>
  );
}
