import { useHookstate } from "@hookstate/core";
import { readerSettingsStore } from "../ReaderStateWrapper";

export function useVerticalMargin() {
  const hasVerticalMargin = useHookstate(
    readerSettingsStore.hasVerticalMargin,
  ).get();

  const toggleHasVerticalMargin = () => {
    readerSettingsStore.hasVerticalMargin.set(!hasVerticalMargin);
  };

  return { hasVerticalMargin, toggleHasVerticalMargin };
}
