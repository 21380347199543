import { Text, Flex, Image, Button, Divider } from "@chakra-ui/react";
import { t } from "i18next";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export function OnboardingSplash({ onSuccess }: { onSuccess: () => void }) {
  const isSmall = useIsSmallBreakpoint();
  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      <Flex mt="24px" px={isSmall ? "24px" : "112px"}>
        <Text variant="onboardingTitle" textAlign="center">
          {t("components.onboarding.splash.title")}
        </Text>
      </Flex>
      <Image
        mt="-16px"
        src={`${window.gc.global.assets_url}/static/images/web-ui/onboarding/onboardingSplashCovers.png`}
        width="100%"
        height={isSmall ? "256px" : "512px"}
      />
      <Flex
        flexDirection="column"
        gap="24px"
        width="100%"
        px={isSmall ? "24px" : "112px"}
      >
        <Divider color="transparent.white.10" />
        <Button
          id="btnOnboardingStartReading"
          variant="primary"
          size="lg"
          onClick={onSuccess}
          width="100%"
        >
          {t("components.onboarding.splash.cta")}
        </Button>
      </Flex>
      <DotLottieReact
        src={`${window.gc.global.assets_url}/static/images/web-ui/stars_confetti.json`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
        autoplay
      />
    </Flex>
  );
}
