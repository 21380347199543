import {
  Tabs,
  TabList,
  Tab,
  Box,
  Spacer,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Info } from "luxon";
import { Comic, Section, SectionLayout } from "../../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SectionTitle } from "../text/SectionTitle";
import useEmblaCarousel from "embla-carousel-react";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { RELEASE_CALENDAR_MAX_WIDTH } from "./constants";
import { ComicReleaseCalendarGrid } from "./ComicReleaseCalendarGrid";

interface ComicReleaseCalendarCardProps {
  data: Comic[];
  layout: SectionLayout;
  section: Section;
  sectionIndex?: number;
}

export function ComicReleaseCalendarContainer({
  data,
  layout,
  section,
  sectionIndex,
}: ComicReleaseCalendarCardProps) {
  const daysOfWeek = Info.weekdays("long", { locale: "en-US" });

  const comicsGroupedByUpdatePeriod = useMemo(() => {
    return data.reduce<Record<string, Comic[]>>((acc, comic) => {
      const updatePeriod = comic.comic_update_period;
      const dayOfWeek = daysOfWeek[updatePeriod - 1];
      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = [];
      }
      acc[dayOfWeek].push(comic);
      return acc;
    }, {});
  }, [data]);

  const isSmallMobile = useMobileBreakpoint();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    watchDrag: true,
    align: isSmallMobile ? "center" : "start",
  });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onTabChange = (index: number) => {
    setSelectedIndex(index);
    if (emblaApi) {
      emblaApi.scrollTo(index);
    }
  };

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect);
    }
  }, [emblaApi, onSelect]);

  const isFitted = useBreakpointValue({ base: true, md: false });

  const headerAndTabs = useMemo(
    () => (
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems="start"
        flex="1"
        width="100%"
        maxWidth={RELEASE_CALENDAR_MAX_WIDTH + "px"}
      >
        <SectionTitle subtitle={section.description} mb={8}>
          {section.display_name}
        </SectionTitle>
        <Spacer />
        <Tabs
          index={selectedIndex}
          onChange={onTabChange}
          isFitted={isFitted}
          variant="unstyled"
          isManual
        >
          <TabList
            mb={{ base: 2, md: 0 }}
            mr={{ base: 0, md: 2 }}
            width={{ base: "100%", md: "auto" }}
            justifyContent={{ base: "center", md: "flex-start" }}
            px={{ base: 4, md: 0 }}
            display="flex"
          >
            {daysOfWeek.map((day, i) => (
              <Tab
                key={i}
                color="neutral.200"
                _selected={{
                  color: "neutral.800",
                  bg: "reader.neutral.100",
                  borderRadius: "14",
                  py: 0,
                }}
              >
                <Text variant="cardSubtitle" fontFamily="Bricolage Grotesque">
                  {isSmallMobile ? day.slice(0, 3) : day}
                </Text>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>
    ),
    [section, isFitted, selectedIndex],
  );

  return (
    <Box mx="auto" mb={8}>
      {headerAndTabs}

      <Box ref={emblaRef} width="100%">
        <Flex>
          {daysOfWeek.map((day) => {
            const comicsToBeReleased = comicsGroupedByUpdatePeriod[day] || [];

            return (
              <ComicReleaseCalendarGrid
                key={day}
                day={day}
                comics={comicsToBeReleased}
                layout={layout}
                sectionIndex={sectionIndex}
                section={section}
              />
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
}
