import {
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { SealCheckIcon } from "../../icons/SealCheckIcon";
import { t } from "i18next";
import { PriceTagIcon } from "../../icons/PriceTagIcon";
import { CloseCircleIcon } from "../../icons/CloseCircleIcon";
import { useState } from "react";
import { AttentionIcon } from "../../icons/AttentionIcon";

export function PromoCodeInput({
  promoApplied,
  isValid,
  applyPromoCode,
  onChange,
  isSmall = false,
}: {
  promoApplied?: string;
  isValid?: boolean;
  applyPromoCode: (promoCode: string) => void;
  onChange?: (value: string) => void;
  isSmall?: boolean;
}) {
  const [promoCode, setPromoCode] = useState<string>();
  const inputElementSize = isSmall ? "40px" : "48px";
  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        if (promoApplied) {
          setPromoCode("");
          onChange?.("");
          applyPromoCode("");
        } else if (promoCode) {
          onChange?.(promoCode);
          applyPromoCode(promoCode);
        }
      }}
    >
      <FormControl isInvalid={!!(promoApplied && !isValid)}>
        <InputGroup>
          <InputLeftElement height={inputElementSize} width={inputElementSize}>
            {promoApplied ? (
              isValid ? (
                <SealCheckIcon color="success.300" boxSize="24px" />
              ) : (
                <AttentionIcon color="error.300" boxSize="24px" />
              )
            ) : (
              <PriceTagIcon color="dune.600" boxSize="24px" />
            )}
          </InputLeftElement>
          <Input
            size={isSmall ? "md" : "lg"}
            borderRadius="16px"
            type="text"
            fontSize="14px"
            lineHeight="130%"
            color={promoApplied ? "transparent" : "dune.600"}
            placeholder={
              promoApplied
                ? ""
                : t("components.payments.promoCode.enterPromoCode")
            }
            borderColor={isValid ? "success.300" : "transparent.white.30"}
            outlineColor="none"
            boxShadow={
              isValid
                ? "0px 0px 5px 0px var(--chakra-colors-success-300)"
                : undefined
            }
            _hover={{}}
            _focusVisible={{
              borderColor: promoApplied ? undefined : "transparent.white.70",
              outlineColor: "none",
            }}
            _invalid={{
              borderColor: "error.300",
              boxShadow: "0px 0px 5px 0px var(--chakra-colors-error-300)",
              outlineColor: "none",
            }}
            value={promoCode}
            onChange={(e) => {
              setPromoCode(e.target.value.toUpperCase());
              onChange?.(e.target.value.toUpperCase());
            }}
          />
          {promoApplied && (
            <Flex
              position="absolute"
              left="48px"
              height="100%"
              alignItems="center"
            >
              <Text
                color={isValid ? "success.300" : "error.300"}
                fontSize="14px"
                fontWeight="500"
                lineHeight="130%"
              >
                {promoApplied}
              </Text>
              <Text
                color="dune.600"
                fontSize="14px"
                fontWeight="500"
                lineHeight="130%"
              >
                &nbsp;
                {isValid
                  ? t("components.payments.promoCode.applied")
                  : t("components.payments.promoCode.cannotBeUsed")}
              </Text>
            </Flex>
          )}
          <InputRightElement height={inputElementSize} width="64px">
            <Button variant="iconOnly" type="submit">
              {promoApplied ? (
                <CloseCircleIcon boxSize="24px" color="dune.100" />
              ) : (
                <Text
                  color="blaze.300"
                  fontSize="13px"
                  lineHeight="130%"
                  letterSpacing="0.5px"
                  fontFamily="Bricolage Grotesque 24pt"
                >
                  {t("components.payments.promoCode.apply")}
                </Text>
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </form>
  );
}
