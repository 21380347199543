import { Route } from "react-router-dom";
import BrowseScreen from "./BrowseScreen";

export const routes = (
  <>
    <Route
      index
      path="browse/:slug_1?/:slug_2?/:slug_3?"
      Component={BrowseScreen}
    />
    <Route
      index
      path="creators/:slug_1?/:slug_2?/:slug_3?"
      Component={BrowseScreen}
    />
  </>
);
