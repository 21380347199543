import { useQuery } from "@tanstack/react-query";
import { Account, ServerSingleResponse } from "../types";
import { GET_ACCOUNT_ME_QUERY_KEY } from "../components/auth/api/authApi";
import { AxiosResponse } from "axios";

export function useAccountData() {
  const { data } = useQuery<AxiosResponse<ServerSingleResponse<Account>>>({
    queryKey: GET_ACCOUNT_ME_QUERY_KEY,
    networkMode: "offlineFirst",
  });
  return data?.data.payload.results;
}
