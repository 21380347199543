import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { Artist } from "../../../types";
import { useTranslation } from "react-i18next";
import { ImmutableObject } from "@hookstate/core";
import { FunnelContextArea, FunnelComponentProps } from "../../funnel/types";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";

export interface DonationConfirmationModalBodyProps {
  artist?: ImmutableObject<Artist>;
}

export function DonationConfirmationModalBody({
  artist,
  onClose,
}: FunnelComponentProps & DonationConfirmationModalBodyProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const { t } = useTranslation();

  if (!artist) return null;

  const { avatar_small_url, roman_name } = artist;
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.donate,
    artistId: artist.id,
  });

  return (
    <Flex flexDirection="column" alignItems="center">
      <Image
        borderRadius="24px"
        boxSize={isSmallBreakpoint ? "90px" : "139px"}
        src={avatar_small_url}
        alt="Creator Avatar"
      />
      <Text
        variant="modalTitle"
        fontWeight={600}
        whiteSpace="pre-line"
        textAlign="center"
        mx={8}
        my={8}
      >
        {t("components.payments.donate.wellDoneYouSuccessfullyDonated", {
          artistName: roman_name,
        })}
      </Text>

      <Flex flexDirection="row" gap="16px" width="100%">
        <Button
          variant="primary"
          size="lg"
          flex="1"
          onClick={() => {
            setFunnelModalConfig({
              type: FUNNEL_TYPES.donate,
              options: {
                donationArtistId: artist.id,
                navigationContext,
              },
            });
          }}
        >
          {t("components.payments.donate.makeAnotherDonation")}
        </Button>
        <Button variant="tertiary" size="lg" flex="1" onClick={onClose}>
          {t("components.payments.donate.imAllSet")}
        </Button>
      </Flex>
    </Flex>
  );
}
