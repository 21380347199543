import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { MoneyIcon } from "../../../../components/icons/MoneyIcon";
import { LicenseTypeEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const LICENSE_TYPE_ALL = "0";

interface LicenseTypeFilterProps {
  value: Immutable<string | undefined>;
  licenseTypes: LicenseTypeEntity[];
  onChange: (nextValue: string) => void;
}
export function LicenseTypeFilter({
  value,
  licenseTypes,
  onChange,
}: LicenseTypeFilterProps) {
  const licenseTypeOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: LICENSE_TYPE_ALL },
      ...licenseTypes.map((type) => ({
        label: type.display_name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <RadioFilter
      value={value}
      options={licenseTypeOptions}
      onChange={onChange}
    />
  );
}

interface LicenseTypeFilterTitleProps {
  isTrigger: boolean;
  licenseTypes: LicenseTypeEntity[];
  open?: boolean;
  value?: string;
}
export const LicenseTypeFilterTitle = forwardRef(
  (
    {
      isTrigger,
      licenseTypes,
      open,
      value,
      ...props
    }: LicenseTypeFilterTitleProps,
    ref,
  ) => {
    const licenseType = licenseTypes.find((type) => `${type.id}` == value);
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<MoneyIcon />}
          popoverRef={ref}
          text="Paywall"
          isFiltering={licenseType?.display_name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Paywall"
        filterValue={licenseType?.display_name}
        open={open}
      />
    );
  },
);
