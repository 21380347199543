import { DisplayFlags, LayoutStyle } from "../../types";

export function artistResultsCard(mode: LayoutStyle["mode"]) {
  return {
    display: {
      include_cover_image: true,
      include_name: true,
      include_flag: true,
      include_title: true,
      include_footer: false,
      include_follow: true,
    },
    style: {
      context: "creators",
      mode,
      size: mode === "list" ? "c_1" : "l",
      rows: 1,
      corners_top: "left",
      corners_bottom: "left",
      section_is_inset: false,
    },
  } as { display: DisplayFlags; style: LayoutStyle };
}
