import { ImmutableObject } from "@hookstate/core";
import { ReleaseCommentEntity } from "../../../../types";
import { Text, Flex } from "@chakra-ui/react";
import { Avatar } from "../../../../components/user/Avatar";
import truncateString from "../../../../utils/truncateString";

interface CommentHeaderProps {
  comment: ImmutableObject<ReleaseCommentEntity>;
}
export function CommentHeader({ comment }: CommentHeaderProps) {
  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="4px"
    >
      <Flex gap="12px" alignItems="center">
        <Avatar
          isGold={comment.author.gold}
          username={comment.author.name}
          imageSrc={comment.author.avatar_tiny_url}
        />
        <Text
          fontSize="16px"
          fontWeight="700"
          lineHeight="20.8px"
          color="neutral.200"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          title={comment.author.name}
        >
          {truncateString(comment.author.name, 20)}
        </Text>
      </Flex>
      <Text
        as="time"
        dateTime={comment.post_date.toString()}
        title={comment.post_date.toString()}
        color="neutral.300"
        fontSize="10px"
        fontWeight="500"
        lineHeight="15px"
        letterSpacing=".25px"
        maxWidth="200px"
        textAlign="end"
      >
        {comment.time_ago}
      </Text>
    </Flex>
  );
}
