import { Icon, IconProps } from "@chakra-ui/react";

export function SearchIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="search">
        <path
          id="search_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10ZM10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C11.8911 17.5 13.6188 16.8001 14.938 15.6451L20.6464 21.3536C20.8417 21.5488 21.1583 21.5488 21.3536 21.3536C21.5488 21.1583 21.5488 20.8417 21.3536 20.6464L15.6451 14.938C16.8001 13.6188 17.5 11.8911 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
