import { Icon, IconProps } from "@chakra-ui/react";

export function FilterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4C10.5 3.72386 10.2761 3.5 10 3.5C9.72386 3.5 9.5 3.72386 9.5 4V5.5H3C2.72386 5.5 2.5 5.72386 2.5 6C2.5 6.27614 2.72386 6.5 3 6.5H9.5V8C9.5 8.27614 9.72386 8.5 10 8.5C10.2761 8.5 10.5 8.27614 10.5 8V6V4ZM14 5.5C13.7239 5.5 13.5 5.72386 13.5 6C13.5 6.27614 13.7239 6.5 14 6.5H21C21.2761 6.5 21.5 6.27614 21.5 6C21.5 5.72386 21.2761 5.5 21 5.5H14ZM16 9.5C16.2761 9.5 16.5 9.72386 16.5 10V12V14C16.5 14.2761 16.2761 14.5 16 14.5C15.7239 14.5 15.5 14.2761 15.5 14V12.5H3C2.72386 12.5 2.5 12.2761 2.5 12C2.5 11.7239 2.72386 11.5 3 11.5H15.5V10C15.5 9.72386 15.7239 9.5 16 9.5ZM19.5 12C19.5 11.7239 19.7239 11.5 20 11.5H21C21.2761 11.5 21.5 11.7239 21.5 12C21.5 12.2761 21.2761 12.5 21 12.5H20C19.7239 12.5 19.5 12.2761 19.5 12ZM6.5 16C6.5 15.7239 6.27614 15.5 6 15.5C5.72386 15.5 5.5 15.7239 5.5 16V17.5H3C2.72386 17.5 2.5 17.7239 2.5 18C2.5 18.2761 2.72386 18.5 3 18.5H5.5V20C5.5 20.2761 5.72386 20.5 6 20.5C6.27614 20.5 6.5 20.2761 6.5 20V18V16ZM9.5 18C9.5 17.7239 9.72386 17.5 10 17.5H21C21.2761 17.5 21.5 17.7239 21.5 18C21.5 18.2761 21.2761 18.5 21 18.5H10C9.72386 18.5 9.5 18.2761 9.5 18Z"
        fill="currentColor"
      />
    </Icon>
  );
}
