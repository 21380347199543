export const fontSizes = {
  xs: ".625rem",
  sm: ".75rem",
  md: ".875rem",
  lg: "1rem",
  xl: "1.125rem",
  "2xl": "1.25rem",
  "3xl": "1.375rem",
  "4xl": "1.5rem",
  "5xl": "2.375rem", // ~38px
};
