import { Box, Flex } from "@chakra-ui/react";
import { ComicUserReadingStatusFilter } from "../../../screens/browse/components/filters/ComicUserReadingStatusFilter";
import { NotificationToggles } from "./NotificationToggles";
import { ReadingStatusSubmitButtons } from "./ReadingStatusSubmitButtons";
import {
  GET_LIBRARY_LIST_QUERY_KEY,
  GET_LIBRARY_COUNT_QUERY_KEY,
} from "../../../screens/library/api/libraryScreenApi";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { useToggleComicFollow } from "../../cards/hooks/useToggleComicFollow";
import { useHookstate } from "@hookstate/core";
import { useState, useEffect } from "react";
import { appStore } from "../../../appStore";
import { Comic } from "../../../types";

interface ReadingStatusPopoverContentProps {
  onClose: () => void;
  data: Comic;
  isModal?: boolean;
  contextual?: boolean;
  inLibrary?: boolean;
}
export function ReadingStatusPopoverContent({
  onClose,
  data,
  isModal = false,
  inLibrary = false,
}: ReadingStatusPopoverContentProps) {
  const [newStatus, setNewStatus] = useState<string>();
  const [emailsEnabled, setEmailsEnabled] = useState<boolean>();
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>();
  const allStatuses = useHookstate(appStore.readingStatusMap).get();
  useEffect(() => {
    if (data?.library_status) {
      const {
        comic_user_reading_status_id,
        disable_emails,
        disable_notifications,
      } = data.library_status;

      setNewStatus(comic_user_reading_status_id + "");
      setEmailsEnabled(!disable_emails);
      setNotificationsEnabled(!disable_notifications);
    } else {
      setNewStatus("1");
      setEmailsEnabled(true);
      setNotificationsEnabled(true);
    }
  }, [data]);

  const invalidateList = useInvalidateQueries(GET_LIBRARY_LIST_QUERY_KEY);
  const invalidateCount = useInvalidateQueries(GET_LIBRARY_COUNT_QUERY_KEY);
  const { unfollow, follow } = useToggleComicFollow(
    `${data?.entity_type}-${data?.id}`,
    undefined,
    () => {
      invalidateList();
      invalidateCount();
    },
    () => {
      invalidateList();
      invalidateCount();
    },
  );
  return (
    <Box
      zIndex={1}
      position="relative"
      w="full"
      h="full"
      onClick={(e) => {
        const control = (e.target as HTMLLabelElement)
          .control as HTMLInputElement;
        if (parseInt(control?.value)) {
          setNewStatus(control.value);
        }
      }}
    >
      <Box p={2} px={6} w="full">
        <ComicUserReadingStatusFilter
          value={newStatus}
          comicUserReadingStatus={Object.values(allStatuses)}
          includeAll={false}
        />
      </Box>
      <Flex pb={"80px"} flexDirection="column" width="100%">
        <Box w="full" h="1px" bg="transparent.white.10" />
        <NotificationToggles
          isModal={isModal}
          notificationsEnabled={!!notificationsEnabled}
          setNotificationsEnabled={() => {
            setNotificationsEnabled(!notificationsEnabled);
          }}
          emailsEnabled={!!emailsEnabled}
          setEmailsEnabled={() => {
            setEmailsEnabled(!emailsEnabled);
          }}
        />
        <Box w="full" h="1px" bg="transparent.white.10" />
      </Flex>
      <Box
        width="full"
        position="absolute"
        bottom="0"
        p="24px"
        backgroundColor={isModal ? "charcoal.charcoal" : "inherit"}
      >
        <ReadingStatusSubmitButtons
          inLibrary={inLibrary}
          contextual={!isModal}
          remove={() => {
            unfollow();
            onClose();
          }}
          update={async () => {
            follow({
              comic_user_reading_status_id: parseInt(newStatus ?? "1"),
              disable_emails: !emailsEnabled,
              disable_notifications: !notificationsEnabled,
            });
            onClose();
          }}
        />
      </Box>
    </Box>
  );
}
