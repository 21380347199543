export const HOME_CHANNEL_ID = "_internal.gc.app.home.index";
export const ORIGINALS_CHANNEL_ID = "_internal.gc.app.originals.index";

const DEFAULT_NAVBAR_HEIGHT = 64;

const queryParams = new URLSearchParams(window.location.search);

// If standalone is set to 1, the navbar will be hidden (RootLayout.tsx)
// If so, the height of the navbar should be 0.
export const NAVBAR_HEIGHT =
  queryParams.get("standalone") === "1" ? 0 : DEFAULT_NAVBAR_HEIGHT;

export const SLIDE_IN_MENUS = {
  settings: "Settings",
  allPages: "All pages",
  comicDetails: "Series info",
  addTo: "Add to library",
  comments: "Comments",
  publisher: "Publisher Profile",
  releaseInfo: "Release info",
};
