import { Icon, IconProps } from "@chakra-ui/react";

export function ForumsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="forums">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.5C3.17157 3.5 2.5 4.17157 2.5 5V16C2.5 16.1939 2.61207 16.3703 2.78758 16.4526C2.96309 16.535 3.17039 16.5085 3.31951 16.3846L6.65279 13.6154C6.74257 13.5408 6.8556 13.5 6.97231 13.5H15C15.8284 13.5 16.5 12.8284 16.5 12V5C16.5 4.17157 15.8284 3.5 15 3.5H4ZM3.5 5C3.5 4.72386 3.72386 4.5 4 4.5H15C15.2761 4.5 15.5 4.72386 15.5 5V12C15.5 12.2761 15.2761 12.5 15 12.5H6.97231C6.62218 12.5 6.28309 12.6225 6.01378 12.8462L6.33329 13.2308L6.01378 12.8462L3.5 14.9346V5ZM18 7.5C17.7239 7.5 17.5 7.72386 17.5 8C17.5 8.27614 17.7239 8.5 18 8.5H20C20.2761 8.5 20.5 8.72386 20.5 9V18.9346L17.9862 16.8462C17.7169 16.6225 17.3778 16.5 17.0277 16.5H9C8.72386 16.5 8.5 16.2761 8.5 16V15C8.5 14.7239 8.27614 14.5 8 14.5C7.72386 14.5 7.5 14.7239 7.5 15V16C7.5 16.8284 8.17157 17.5 9 17.5H17.0277C17.1444 17.5 17.2574 17.5408 17.3472 17.6154L20.6805 20.3846C20.8296 20.5085 21.0369 20.535 21.2124 20.4526C21.3879 20.3703 21.5 20.1939 21.5 20V9C21.5 8.17157 20.8284 7.5 20 7.5H18Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
