import { apiClient } from "../../../services/axiosInstance";
import { ServerListResponse, ServerSingleResponse } from "../../../types";
import {
  OwnerPaymentServiceTokenTypes,
  SubscriptionOffersTypes,
} from "../types";

export const GET_SUBSCRIPTION_PRICING_QUERY_KEY = ["get-subscription-pricing"];
export function getSubscriptionPricing(promoCode?: string) {
  return apiClient.get<ServerSingleResponse<SubscriptionOffersTypes>>(
    `/v1/account/webnext-subscription-pricing?code=${promoCode ?? ""}`,
  );
}

export const GET_GIFTCARD_PRICING_QUERY_KEY = ["get-giftcard-pricing"];
export function getGiftCardPricing(promoCode?: string) {
  return apiClient.get<ServerSingleResponse<SubscriptionOffersTypes>>(
    `/v1/account/webnext-giftcard-pricing?code=${promoCode ?? ""}`,
  );
}

export type WebPurchasePayloadTypes = {
  accessTokenTypeId: number;
  totalMinorUnits: number;
  stripeToken?: string;
  ownerPaymentServiceTokenId?: string;
  incentiveCode?: string;
};
export function webPurchase(payload: WebPurchasePayloadTypes) {
  return apiClient.post<ServerSingleResponse<any>>(`/v1/account/web-purchase`, {
    meta: {},
    payload,
  });
}

export const GET_SAVED_PAYMENT_METHODS_QUERY_KEY = [
  "get-saved-payment-methods",
];
export function getSavedPaymentMethods() {
  return apiClient.get<ServerListResponse<OwnerPaymentServiceTokenTypes>>(
    `/v1/account/payment-methods`,
  );
}

export type ReleasePurchasePayload = {
  price_point_id: number;
  raw_price: number;
  is_gold: boolean;
  stripeToken?: string;
  ownerPaymentServiceTokenId?: string;
};
export function unlockRelease(
  releaseId: number,
  payload: ReleasePurchasePayload,
) {
  return apiClient.post<ServerSingleResponse<any>>(
    `/v1/releases/${releaseId}/unlock`,
    {
      meta: {},
      payload: payload,
    },
  );
}
