import { ImmutableObject, useHookstate } from "@hookstate/core";
import { Comic } from "../../../types";
import { TagBadge } from "./TagBadge";
import { appStore } from "../../../appStore";
import { ComicFacetGroupEntity } from "../../../services/gc/types";
import { StarIcon } from "../../icons/StarIcon";
import { HeartIcon } from "../../icons/HeartIcon";

export interface GCStaffPickAndExclusiveBadgeProps {
  comicData: Comic;
  isLarge?: boolean;
}

const GC_INTERNAL_FACET_GROUP_ID = 6;
const GC_ORIGINALS_FACET_GROUP_OPTION_ID = 100;
const GC_STAFF_PICKS_FACET_GROUP_OPTION_ID = 58;
const GC_EXCLUSIVE_PICKS_FACET_GROUP_OPTION_ID = 57;

export function GCStaffPickAndExclusiveBadge({
  comicData,
  isLarge,
}: GCStaffPickAndExclusiveBadgeProps) {
  const { comic_facet_groups } = useHookstate(appStore.init).get();
  const internalGlobalComixFacetGroup = comic_facet_groups.find(({ id }) => {
    return id === GC_INTERNAL_FACET_GROUP_ID;
  });

  if (!internalGlobalComixFacetGroup) {
    return null;
  }

  const isGCOriginals = comicData.comic_facets?.some(
    (facet) =>
      facet.comic_facet_group_option_id === GC_ORIGINALS_FACET_GROUP_OPTION_ID,
  );

  const isGCExlusive = comicData.comic_facets?.some(
    (facet) =>
      facet.comic_facet_group_option_id ===
      GC_EXCLUSIVE_PICKS_FACET_GROUP_OPTION_ID,
  );
  const gcExclusiveDisplayName =
    getDisplayNameByGroupId(
      GC_EXCLUSIVE_PICKS_FACET_GROUP_OPTION_ID,
      internalGlobalComixFacetGroup,
    ) ?? "";

  const isGCStaffPick = comicData.comic_facets?.some(
    (facet) =>
      facet.comic_facet_group_option_id ===
      GC_STAFF_PICKS_FACET_GROUP_OPTION_ID,
  );
  const gcStaffPickDisplayName =
    getDisplayNameByGroupId(
      GC_STAFF_PICKS_FACET_GROUP_OPTION_ID,
      internalGlobalComixFacetGroup,
    ) ?? "";

  return (
    <>
      {!isGCOriginals && isGCExlusive && (
        <TagBadge
          text={gcExclusiveDisplayName}
          icon={<StarIcon boxSize="12px" color="white" />}
          isLarge={isLarge}
        />
      )}
      {isGCStaffPick && (
        <TagBadge
          text={gcStaffPickDisplayName}
          icon={<HeartIcon boxSize="12px" color="white" />}
          isLarge={isLarge}
        />
      )}
    </>
  );
}

function getDisplayNameByGroupId(
  id: number,
  group: ImmutableObject<ComicFacetGroupEntity>,
) {
  return group?.comic_facet_group_options.find((option) => option.id === id)
    ?.display_name;
}
