import { apiClient } from "../../../services/axiosInstance";
import {
  Article,
  Artist,
  Comic,
  NotificationUser,
  Release,
  ServerListResponse,
  ServerResponse,
} from "../../../types";

export type NotificationEntity = {
  entity_type: string;
  notification_id: number;
  activity_id: number;
  activity_type: number;
  object_source_id: number;
  object_id: number;
  owner_user_id: number;
  artist_id: number;
  comic_id: number;
  object_lang: string;
  creator_user_id: number;
  create_time: string;
  clicked_time: string | null;
  is_clicked: boolean;
  is_deleted: boolean;
  seen_time: string | null;
  is_seen: boolean;
  time_ago: string;
  ack_url: string;
  context: {
    creator_user: NotificationUser;
    comic: Comic;
    artist: Artist;
    release: Release;
    donation: null;
    article: Article;
  };
  message_html: string;
};

type UnseenNotificationResponse = {
  unseen_notifications_count: number;
};

export function getNotifications() {
  return apiClient.get<ServerListResponse<NotificationEntity>>(
    `/v1/account/notifications`,
  );
}

export const GET_NOTIFICATIONS_QUERY_KEY = ["notifications"];

export function getNotificationsUnseenCount() {
  return apiClient.get<ServerResponse<UnseenNotificationResponse>>(
    `/v1/account/notifications/unseen-count`,
  );
}

export const GET_NOTIFICATIONS_UNSEEN_COUNT_QUERY_KEY = [
  "notifications-unseen-count",
];

export function postAcknowledgeNotification(notificationId: number) {
  return apiClient.post(`/v1/account/notifications/ack-click`, {
    meta: {},
    payload: {
      notification_id: notificationId,
    },
  });
}

export function postMarkAllSeen() {
  return apiClient.post(`/v1/account/notifications/mark-all-seen`);
}

export function postMarkAllRead() {
  return apiClient.post(`/v1/account/notifications/mark-all-read`);
}
