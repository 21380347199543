import { Flex } from "@chakra-ui/react";
import { PAGE_SIZING_TYPES } from "../../constants";
import { SettingButton } from "./SettingButton";
import { usePageSize } from "../../hooks/usePageSize";
import { AutoSizingIcon } from "../../../../components/icons/reader/AutoSizingIcon";
import { FitToHeightIcon } from "../../../../components/icons/reader/FitToHeightIcon";
import { FitToWidthIcon } from "../../../../components/icons/reader/FitToWidthIcon";
import { t } from "i18next";
import { SectionHeader } from "../shared/SectionHeader";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { useReadingDirection } from "../../hooks/useReadingDirection";
import { useReleaseFragmentsData } from "../../hooks/hookstate/useReleaseFragmentsData";

export function PageSizingSelector() {
  const { comicIsVertical } = useReadingDirection();
  const { pageSizing, setPageSize } = usePageSize();
  const { activePageFragments } = useReleaseFragmentsData();
  const { p2pEnabledAndAugmented } = useP2PSettings();

  const p2pEnabled = activePageFragments.length && p2pEnabledAndAugmented;

  const autoDisabled = p2pEnabled || comicIsVertical;
  const autoIsActive = pageSizing === PAGE_SIZING_TYPES.auto;

  const fthDisabled = p2pEnabled || comicIsVertical;
  const fthIsActive = PAGE_SIZING_TYPES.height === pageSizing;

  const ftwDisabled = p2pEnabled || comicIsVertical;
  const ftwIsActive = PAGE_SIZING_TYPES.width === pageSizing;
  return (
    <>
      <SectionHeader text={t("screens.reader.settingsMenu.page_sizing")} />
      <Flex gap="12px" justifyContent="center">
        <SettingButton
          isDisabled={autoDisabled}
          text={t("screens.reader.settingsMenu.auto")}
          isActive={autoIsActive}
          onClick={() => setPageSize(PAGE_SIZING_TYPES.auto)}
          icon={<AutoSizingIcon width="80px" height="80px" />}
          size="100px"
        />
        <SettingButton
          isDisabled={fthDisabled}
          text={t("screens.reader.settingsMenu.fit_to_height")}
          isActive={fthIsActive}
          onClick={() => setPageSize(PAGE_SIZING_TYPES.height)}
          icon={<FitToHeightIcon width="80px" height="80px" />}
          size="100px"
        />
        <SettingButton
          isDisabled={ftwDisabled}
          text={t("screens.reader.settingsMenu.fit_to_width")}
          isActive={ftwIsActive}
          onClick={() => setPageSize(PAGE_SIZING_TYPES.width)}
          icon={<FitToWidthIcon width="80px" height="80px" />}
          size="100px"
        />
      </Flex>
    </>
  );
}
