import { ImmutableObject } from "@hookstate/core";
import { Release } from "../../../../types";
import { useQuery } from "@tanstack/react-query";
import { getSimilarComicReleases } from "../../api/readerScreenApi";
import { Image, Text, Flex, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface EndOfComicCTAProps {
  releaseData: ImmutableObject<Release> | undefined;
}
export function EndOfComicCTA({ releaseData }: EndOfComicCTAProps) {
  const { isLoading, data } = useQuery({
    enabled: !!releaseData?.comic.id,
    queryKey: [releaseData?.comic.id],
    queryFn: () => getSimilarComicReleases(releaseData?.comic.id ?? 0),
    refetchOnWindowFocus: false,
  });

  const similarComicData = data?.data.payload.results;
  return (
    <Flex width="100%" gap="16px" overflowX="auto">
      {isLoading && <Spinner />}
      {!isLoading &&
        similarComicData &&
        similarComicData.map((comic) => {
          return (
            <Link
              aria-label={`Read ${comic.name}`}
              to={comic.read_url}
              replace
              style={{
                height: "fit-content",
              }}
            >
              <Flex flexDirection="column" width="130px" px="14px" gap="12px">
                <Image
                  borderRadius="8px"
                  width="100%"
                  height="100%"
                  src={comic.image_small_url}
                  alt={`Thumbnail for comic ${comic.name}`}
                />
                <Flex flexDirection="column" lineHeight="24px">
                  <Text fontSize="16px" fontWeight="bold" lineHeight="24px">
                    {comic.name}
                  </Text>
                  <Text
                    color="reader.blue.200"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="24px"
                    verticalAlign="baseline"
                  >
                    {comic.artist.roman_name}
                  </Text>
                </Flex>
              </Flex>
            </Link>
          );
        })}
    </Flex>
  );
}
