import { useTranslation } from "react-i18next";
import { Release } from "../../../types";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { Button, Flex, Text } from "@chakra-ui/react";
import { DividerWithText } from "./DividerWithText";
import { FunnelContextArea } from "../../funnel/types";

export function openPpbModal(release: Release) {
  setFunnelModalConfig({
    type: FUNNEL_TYPES.purchaseRelease,
    options: {
      purchaseRelease: release,
      modalContext: FunnelContextArea.reader,
    },
  });
}

function openSubscriptionModal(release: Release) {
  setFunnelModalConfig({
    type: FUNNEL_TYPES.subscription,
    options: { purchaseRelease: release },
  });
}

export interface PrePaywallActionsProps {
  release: Release;
  userIsGold: boolean;
}

export function PrePayWallActions({
  release,
  userIsGold,
}: {
  release: Release;
  userIsGold: boolean;
}) {
  const { ppb_price_point, ppb_gold_price_point, premium_only } = release;
  const { t } = useTranslation();

  const priceString = ppb_price_point ? `$${ppb_price_point / 100}` : "";
  const goldPriceString = ppb_gold_price_point
    ? `$${ppb_gold_price_point / 100}`
    : "";

  if (!premium_only && !ppb_price_point && !userIsGold) {
    return (
      <Flex flexDirection="column" gap="16px">
        <Button
          variant="primary"
          width="100%"
          size="lg"
          textTransform="uppercase"
          onClick={() => openSubscriptionModal(release)}
        >
          {t("components.payments.prePaywall.becomeAMember")}
        </Button>
        <Text variant="modalBody" color="dune.700" textAlign="center">
          {t(
            "components.payments.prePaywall.notAvailableWithoutGoldMembership",
          )}
        </Text>
      </Flex>
    );
  }

  if (!premium_only && ppb_price_point && !userIsGold) {
    return (
      <Flex flexDirection="column" gap="16px">
        <Button
          variant="primary"
          width="100%"
          size="lg"
          textTransform="uppercase"
          onClick={() => openSubscriptionModal(release)}
        >
          {t("components.payments.prePaywall.freeWithGoldMembership")}
        </Button>
        <DividerWithText
          text={t("components.payments.prePaywall.orPayFullPrice")}
        />
        <Button
          variant="tertiary"
          width="100%"
          size="lg"
          textTransform="uppercase"
          onClick={() => openPpbModal(release)}
        >
          {t("components.payments.prePaywall.buy", { price: priceString })}
        </Button>
      </Flex>
    );
  }

  if (premium_only) {
    if (
      ppb_price_point &&
      ppb_gold_price_point &&
      ppb_gold_price_point !== ppb_price_point &&
      !userIsGold
    ) {
      return (
        <Flex flexDirection="column" gap="16px">
          <Button
            variant="primary"
            width="100%"
            size="lg"
            textTransform="uppercase"
            onClick={() => openSubscriptionModal(release)}
          >
            {t("components.payments.prePaywall.withGoldMembership", {
              price: goldPriceString,
            })}
          </Button>
          <DividerWithText
            text={t("components.payments.prePaywall.orPayFullPrice")}
          />
          <Button
            variant="tertiary"
            width="100%"
            size="lg"
            textTransform="uppercase"
            onClick={() => openPpbModal(release)}
          >
            {t("components.payments.prePaywall.forNoMembers", {
              price: priceString,
            })}
          </Button>
        </Flex>
      );
    }

    if (ppb_price_point || ppb_gold_price_point) {
      return (
        <Button
          variant="primary"
          width="100%"
          size="lg"
          onClick={() => openPpbModal(release)}
        >
          {t("components.payments.prePaywall.buy", {
            price:
              userIsGold && goldPriceString ? goldPriceString : priceString,
          })}
        </Button>
      );
    }
  }
}
