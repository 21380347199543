import { hookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { localstored } from "@hookstate/localstored";
import { AccessData } from "./types";
import {
  Comic,
  Fragment,
  Page,
  Release,
  ReleaseCommentEntity,
} from "../../types";
import { PAGE_LAYOUT_TYPES, PAGE_SIZING_TYPES } from "./constants";
import { useParams } from "react-router";
import { rootComponentsStore } from "../../components/layouts/RootLayout";
import { useEffect, useRef } from "react";
import { useHydrateReaderData } from "./hooks/useHydrateReaderStore";
import { useGaTracking } from "../../hooks/useGaTracking";
import { setFunnelModalConfig } from "../../components/funnel/FunnelModal";
import { AxiosError } from "axios";
import { getIsLocalStorageAvailable } from "../../utils/getIsLocalStorageAvailable";
import { useViewPortScale } from "../../hooks/useViewPortScale";

export type ReaderDataState = {
  accessData?: AccessData;
  comicData?: Comic;
  comicReleasesData?: Release[];
  releaseData?: Release;
  releaseFragmentsData?: Fragment[];
  releaseAnnotationsData?: [];
  releasePagesData?: Page[];
  relatedReleasesData?: Release[];
  releaseCommentsData?: ReleaseCommentEntity[];
  loaded?: boolean;
  responseError?: AxiosError;
};
export type ReaderComponentsState = {
  isOverlayOpen?: boolean;
  showEndInterstitial?: boolean;
  vpScale?: number;
};
const DEFAULT_COMPONENTS_STATE = {
  showEndInterstitial: false,
  isOverlayOpen: false,
  vpScale: 1,
};
export type ReaderSettingsState = {
  pageLayout: string;
  pageSizing: string;
  hasVerticalMargin: boolean;
  p2pEnabled: boolean;
  p2pOverlayOpacity: number;
  showNSFWInterstitial: boolean;
};

export const IS_MOBILE_MAX_WIDTH = 980;
export const VIEWER_MIN_WIDTH_DOUBLE_PAGE = 1600;
const shouldDefaultToDoublePage =
  window?.outerWidth >= VIEWER_MIN_WIDTH_DOUBLE_PAGE;
const detectedIsMobile = window?.innerWidth < IS_MOBILE_MAX_WIDTH;

let defaultLayoutMode = PAGE_LAYOUT_TYPES.single;
if (detectedIsMobile) defaultLayoutMode = PAGE_LAYOUT_TYPES.vertical;

const DEFAULT_SETTINGS_STATE = {
  pageLayout: shouldDefaultToDoublePage
    ? PAGE_LAYOUT_TYPES.double
    : defaultLayoutMode,
  pageSizing: PAGE_SIZING_TYPES.auto,
  hasVerticalMargin: true,
  p2pEnabled: true,
  p2pOverlayOpacity: 75,
  showNSFWInterstitial: true,
};

export const readerDataStore = hookstate<ReaderDataState>(
  {},
  devtools({ key: "reader" }),
);
export const readerComponentsStore = hookstate<ReaderComponentsState>(
  DEFAULT_COMPONENTS_STATE,
);

const isLocalStorageAvailable = getIsLocalStorageAvailable();
export const readerSettingsStore = hookstate<ReaderSettingsState>(
  DEFAULT_SETTINGS_STATE,
  isLocalStorageAvailable
    ? localstored({
        key: "readerSettingsData",
      })
    : undefined,
);

interface ReaderStateWrapperProps {
  children: JSX.Element | JSX.Element[];
}
export function ReaderStateWrapper({ children }: ReaderStateWrapperProps) {
  const { removeThirdPartyGaId } = useGaTracking();
  const { uuid } = useParams();
  useViewPortScale();
  useHydrateReaderData(uuid ?? "");

  useEffect(() => {
    readerComponentsStore.showEndInterstitial.set(false);
  }, [uuid]);

  const homeSidebarState = useRef(false);
  useEffect(() => {
    rootComponentsStore.inReader.set(true);
    rootComponentsStore.isTopbarHidden.set(false);
    homeSidebarState.current = rootComponentsStore.isSidebarOpen.get();
    return () => {
      readerDataStore.set({});
      readerComponentsStore.set({});
      rootComponentsStore.openSlideInMenu.set(undefined);
      rootComponentsStore.inReader.set(false);
      rootComponentsStore.isSidebarOpen.set(homeSidebarState.current);
      setFunnelModalConfig();
    };
  }, []);

  useEffect(() => {
    const readerData = readerDataStore.get({ noproxy: true });

    const releaseData = readerData.releaseData;
    if (releaseData?.artist) {
      removeThirdPartyGaId(releaseData?.comic.artist.name);
    }

    if (Object.values(readerData).length > 0) {
      readerDataStore.merge({
        accessData: undefined,
        releaseData: undefined,
        releasePagesData: undefined,
        releaseCommentsData: undefined,
        releaseAnnotationsData: undefined,
        releaseFragmentsData: undefined,
        relatedReleasesData: undefined,
        loaded: false,
      });
    }
  }, [uuid]);

  return children;
}
