import { useHookstate } from "@hookstate/core";
import { readerComponentsStore } from "../../ReaderStateWrapper";
import { useCallback } from "react";
import { useIsSidebarOpen } from "../../../../hooks/useIsSidebarOpen";

export function useIsOverlayOpen() {
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  const isOverlayOpen = useHookstate(readerComponentsStore.isOverlayOpen).get({
    noproxy: true,
  });

  const toggleIsOverlayOpen = useCallback(() => {
    if (isOverlayOpen && isSidebarOpen) {
      setIsSidebarOpen(false);
    } else {
      readerComponentsStore.isOverlayOpen.set(!isOverlayOpen);
    }
  }, [isOverlayOpen, isSidebarOpen]);

  const setIsOverlayOpen = useCallback((value: boolean) => {
    if (!value) setIsSidebarOpen(false);
    readerComponentsStore.isOverlayOpen.set(value);
  }, []);

  return { isOverlayOpen, toggleIsOverlayOpen, setIsOverlayOpen };
}
