import { Icon, IconProps } from "@chakra-ui/react";

export function BoomLogo(props: IconProps) {
  return (
    <Icon viewBox="0 0 95 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_8089)">
        <path
          d="M0.291504 36.409H84.8414V0.041748H0.291504V36.4118V36.409ZM59.5106 3.9913H67.9314C68.4647 7.85265 69.0008 11.7305 69.5368 15.6057C69.6216 15.6057 69.7036 15.6112 69.7884 15.6139C70.267 11.7581 70.7456 7.90226 71.2325 3.9913H79.8392V31.1062H74.1971V14.6906C74.085 14.6796 73.9728 14.6686 73.8607 14.6576C73.1578 20.134 72.4577 25.6077 71.7466 31.1448H67.5731C66.8347 25.8062 66.0963 20.4703 65.3606 15.1316C65.2868 15.1344 65.2129 15.1371 65.1391 15.1399V31.1255H59.5134V3.9913H59.5106ZM41.4109 9.42365C41.5368 5.97572 43.7657 3.97476 47.1871 3.55858C47.5043 3.52 47.8189 3.4759 48.1361 3.45109C53.275 3.01838 56.226 5.63671 56.3655 10.8127C56.4256 13.059 56.3764 15.3053 56.3764 17.5515C56.3819 17.5515 56.3846 17.5515 56.3901 17.5515C56.3901 19.7537 56.4256 21.9531 56.3819 24.1552C56.3244 26.9444 55.6817 29.5545 52.9031 30.8085C49.8782 32.1728 46.7714 32.1343 43.8915 30.3207C42.2314 29.2761 41.441 27.5343 41.4055 25.6408C41.3098 20.236 41.2168 14.8229 41.4137 9.42365H41.4109ZM23.314 10.1706C23.4863 6.1025 25.7043 3.85349 29.7137 3.54756C30.4822 3.48968 31.2617 3.48968 32.0302 3.55583C35.9329 3.88381 38.192 6.02809 38.3232 9.97488C38.4901 14.969 38.5201 19.9769 38.3478 24.971C38.1783 29.8687 34.6721 32.3492 29.3773 31.6326C25.6113 31.1227 23.5027 28.8875 23.3441 25.0317C23.2402 22.5126 23.325 19.9879 23.325 17.4633C23.314 17.4633 23.3058 17.4633 23.2949 17.4633C23.2949 15.0324 23.2101 12.5987 23.314 10.1706ZM5.07214 3.89483C9.03503 4.07949 12.9924 3.45661 16.8733 4.51772C18.4677 4.95319 19.6957 6.11077 19.9774 7.71485C20.3275 9.7158 20.3849 11.7939 20.322 13.8307C20.2728 15.4348 18.8753 16.0081 17.0702 16.6062C20.735 17.4799 20.9128 20.0348 20.8772 22.7C20.8581 24.1663 20.921 25.6435 20.7705 27.0988C20.4779 29.9128 19.4523 31.0015 16.6901 31.0924C12.8748 31.222 9.05144 31.1255 5.07487 31.1255V3.89483H5.07214Z"
          fill="currentColor"
        />
        <path
          d="M94.3589 26.4071C94.5722 17.5626 94.7828 8.8229 94.9961 0.000488281H88.0166C88.2436 8.9166 88.4651 17.6591 88.6867 26.4071H94.3589Z"
          fill="currentColor"
        />
        <path
          d="M88.2739 36.4143H94.9116V28.9783H88.2739V36.4143Z"
          fill="currentColor"
        />
        <path
          d="M14.2931 24.7031C14.3068 23.4215 14.3122 22.1427 14.2931 20.8611C14.2712 19.3093 13.9184 19.0172 12.231 19.1467V26.4533C14.0661 26.5139 14.2739 26.3347 14.2903 24.7031H14.2931Z"
          fill="currentColor"
        />
        <path
          d="M14.3034 12.747C14.3062 12.0166 14.3144 11.2835 14.298 10.5531C14.2679 9.05653 14.0026 8.81399 12.2358 8.78918V14.635C14.1175 14.6763 14.2952 14.5082 14.3034 12.747Z"
          fill="currentColor"
        />
        <path
          d="M47.7568 17.9369C47.7595 20.6435 47.7705 23.35 47.7568 26.0565C47.7513 26.9413 47.9756 27.6882 48.9875 27.6551C49.8846 27.6275 50.1417 26.9523 50.1362 26.1034C50.1143 20.6903 50.1143 15.2773 50.1088 9.86421C50.1088 9.02634 49.9858 8.17469 48.9547 8.1802C47.8635 8.18571 47.7431 9.04839 47.7513 9.95516C47.7705 12.6148 47.7595 15.2773 47.7595 17.9369H47.7568Z"
          fill="currentColor"
        />
        <path
          d="M30.9263 27.655C31.9492 27.6825 32.157 26.9521 32.1488 26.0592C32.1269 23.3967 32.1406 20.7343 32.1406 18.0719H32.116C32.116 15.3185 32.1078 12.5623 32.1215 9.80894C32.1269 8.938 31.9519 8.16352 30.9263 8.17731C30.0183 8.19109 29.7612 8.87736 29.7612 9.71799C29.7722 15.1807 29.7722 20.6433 29.7612 26.1088C29.7612 26.9439 29.9992 27.6302 30.9208 27.655H30.9263Z"
          fill="currentColor"
        />
        <path
          d="M2.93681 42.7835L2.21206 42.6237C1.68695 42.5079 1.45996 42.3618 1.45722 42.0614C1.45722 41.7197 1.84011 41.5322 2.38709 41.5322C2.89852 41.5322 3.36072 41.7031 3.7901 41.9594L4.37538 41.0527C3.82839 40.7192 3.06535 40.4766 2.27496 40.4794C1.10715 40.4794 0.207365 41.1409 0.2101 42.0283C0.2101 42.8359 0.68871 43.3816 1.87293 43.6462L2.53204 43.7923C3.16107 43.9328 3.36072 44.0514 3.34158 44.3738C3.32243 44.7101 2.96689 44.914 2.4035 44.9168C1.79909 44.9168 1.15365 44.7431 0.625807 44.4317L-0.000488281 45.3412C0.609398 45.7271 1.50645 45.9751 2.35154 45.9724C3.66703 45.9724 4.54768 45.2971 4.54494 44.3738C4.54494 43.5249 4.0718 43.0371 2.93408 42.7863L2.93681 42.7835Z"
          fill="currentColor"
        />
        <path
          d="M14.7896 41.6155H16.4771V45.8957H17.623V41.6155H19.3077V40.5488H14.7842L14.7896 41.6155Z"
          fill="currentColor"
        />
        <path
          d="M33.7399 43.2829C33.7399 44.2917 33.3516 44.8787 32.3916 44.8787C31.4317 44.8787 31.0433 44.2917 31.0433 43.2774V40.5516H29.8755V43.2774C29.8755 44.9366 30.7288 45.9646 32.3916 45.9646C34.0544 45.9646 34.9214 44.9256 34.9214 43.2829V40.5488H33.7427V43.2829H33.7399Z"
          fill="currentColor"
        />
        <path
          d="M47.8472 40.5398L46.0449 40.5481V45.8922H47.8472C49.7535 45.8922 50.9432 44.787 50.9432 43.2271C50.9432 41.6671 49.7507 40.526 47.8472 40.5371V40.5398ZM47.8445 44.8256H47.2018V41.5954L47.8445 41.6009C49.1846 41.612 49.7808 42.2459 49.7808 43.2326C49.7808 44.2193 49.1819 44.8229 47.8445 44.8284V44.8256Z"
          fill="currentColor"
        />
        <path
          d="M63.023 40.5488H61.8579V45.893H63.023V40.5488Z"
          fill="currentColor"
        />
        <path
          d="M76.787 40.4462C75.1734 40.4462 73.9399 41.6589 73.9399 43.2188C73.9399 44.7788 75.1761 45.9998 76.787 45.9998C78.3979 45.9998 79.6368 44.7788 79.6368 43.2188C79.6368 41.6589 78.4006 40.4462 76.787 40.4462ZM76.7843 44.9028C75.8517 44.9028 75.0968 44.2055 75.0968 43.2216C75.0968 42.2377 75.8517 41.5404 76.7843 41.5404C77.7169 41.5404 78.4717 42.2377 78.4717 43.2216C78.4717 44.2055 77.7169 44.9028 76.7843 44.9028Z"
          fill="currentColor"
        />
        <path
          d="M93.0936 42.7835L92.3688 42.6237C91.8437 42.5079 91.6167 42.3618 91.614 42.0614C91.614 41.7197 91.9969 41.5322 92.5438 41.5322C93.0553 41.5322 93.5175 41.7031 93.9468 41.9594L94.5321 41.0527C93.9851 40.7192 93.2221 40.4766 92.4317 40.4794C91.2639 40.4794 90.3641 41.1409 90.3668 42.0283C90.3668 42.8359 90.8455 43.3816 92.0297 43.6462L92.6888 43.7923C93.3178 43.9328 93.5175 44.0514 93.4983 44.3738C93.4764 44.7101 93.1236 44.914 92.5602 44.9168C91.9558 44.9168 91.3104 44.7431 90.7825 44.4317L90.1562 45.3412C90.7661 45.7271 91.6632 45.9751 92.5083 45.9724C93.8238 45.9724 94.7044 45.2971 94.7017 44.3738C94.7017 43.5249 94.2285 43.0371 93.0908 42.7863L93.0936 42.7835Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_8089">
          <rect width="95" height="46" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  );
}
