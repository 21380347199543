import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useThrottle } from "../../../hooks/useThrottle";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { usePopoverOpenState } from "../../../utils/usePopoverOpenState";
import {
  GET_AUTO_COMPLETE_QUERY_KEY,
  getAutoComplete,
} from "../api/globalSearchApi";
import {
  LargeSearchPopover,
  SmallSearchPopover,
} from "./popover/SearchPopover";

export function GlobalSearch() {
  const isSmall = useIsSmallBreakpoint();
  const [searchTerm, setSearchTerm] = useState("");
  const {
    isOpen: resultsOpen,
    ref: popoverRef,
    onOpen,
  } = usePopoverOpenState();

  const [hasData, setHasData] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: [...GET_AUTO_COMPLETE_QUERY_KEY, searchTerm],
    queryFn: () => getAutoComplete(searchTerm),
    enabled: !!searchTerm,
  });

  useEffect(() => {
    if (data?.data) {
      setHasData(true);
    } else if (!searchTerm) {
      setHasData(false);
    }
  }, [data]);

  const runSearch = useCallback(
    (term: string) => {
      if (term && !resultsOpen) {
        onOpen();
      }
      // Always track changes to term so we can remove the entire thing, and reset
      setSearchTerm(term);
    },
    [onOpen, setSearchTerm],
  );

  const handleSearch = useThrottle(runSearch, 250, {
    leading: true,
    trailing: true,
  });

  return (
    <Flex
      height="full"
      width={isSmall ? undefined : "calc(100% - 500px)"}
      minWidth={isSmall ? undefined : "500px"}
    >
      {isSmall ? (
        <SmallSearchPopover
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          popoverRef={popoverRef}
          data={data?.data}
          isFetching={!hasData && isLoading}
        />
      ) : (
        <LargeSearchPopover
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          popoverRef={popoverRef}
          data={data?.data}
          isFetching={!hasData && isLoading}
          isOpen={resultsOpen && (isLoading || !!data)}
        />
      )}
    </Flex>
  );
}
