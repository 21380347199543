import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { Reaction } from "../../../types";
import {
  GET_NEWS_DETAIL_QUERY_KEY,
  postToggleReaction,
} from "../api/newsScreenApi";
import { reactionIdsToEmoji } from "../../../components/reactions/ReactionsPopover";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

interface ReactionsProps {
  articleId: number;
  reactions: Reaction[];
}
export function Reactions({ articleId, reactions }: ReactionsProps) {
  const isSmall = useIsSmallBreakpoint();
  const toast = useToast();
  const [t] = useTranslation();
  const invalidateQueries = useInvalidateQueries(GET_NEWS_DETAIL_QUERY_KEY, [
    "" + articleId,
  ]);
  const { mutate } = useMutation({
    mutationFn: postToggleReaction,
    onSuccess: invalidateQueries,
  });

  const handleToggleReaction = useCallback(
    async (reactionTypeId: number) => {
      try {
        mutate({
          articleId,
          reactionTypeId,
        });
      } catch (e) {
        toast({
          status: "error",
          title: t("screens.news.errorTitle"),
          description: t("screens.news.reactions.errorTogglingReaction"),
        });
      }
    },
    [mutate],
  );

  return (
    <Flex
      direction="column"
      alignItems="center"
      p="40px"
      background="transparent.white.05"
      borderRadius="24px"
      gap="24px"
    >
      <Text
        as="h2"
        fontSize="16px"
        fontWeight="700"
        lineHeight="130%"
        color="neutral.100"
      >
        {t("screens.news.reactionTitle")}
      </Text>
      <Flex direction="row" gap="56px">
        {reactions.map((reaction) => {
          return (
            <Button
              key={reaction.id}
              variant="ghostIcon"
              borderColor={reaction.is_clicked ? "blaze.blaze" : "transparent"}
              borderWidth={1}
              minWidth="0"
              onClick={() => handleToggleReaction(reaction.reaction_type_id)}
              height={isSmall ? "56px" : "72px"}
              padding="0px"
            >
              <Text
                minWidth={0}
                fontSize={isSmall ? "40px" : "56px"}
                textAlign="center"
              >
                {reactionIdsToEmoji[reaction.reaction_type_id]}
              </Text>
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}
