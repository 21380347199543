import { ImmutableObject } from "@hookstate/core";
import { Page } from "../../../types";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import transformPage from "../utils/transformPage";
import useResizeObserver from "@react-hook/resize-observer";
import { useReleaseFragmentsData } from "./hookstate/useReleaseFragmentsData";

export function useFragmentPosition(
  releasePageData: ImmutableObject<Page> | null,
) {
  const { activeFragmentIndex, activePageFragments } =
    useReleaseFragmentsData();
  const [transform, setTransform] = useState<{
    tx: number;
    ty: number;
    tScale: number;
  }>();

  const activeFragment = useMemo(() => {
    return activePageFragments?.[activeFragmentIndex];
  }, [activePageFragments, activeFragmentIndex]);

  const moveToPosition = () => {
    if (releasePageData && activeFragment && imageElement) {
      const newTransform = transformPage(
        activeFragment,
        releasePageData.desktop_width,
        imageElement,
      );
      if (
        newTransform &&
        (newTransform.tScale !== transform?.tScale ||
          newTransform.tx !== transform.tx ||
          newTransform.ty !== transform.ty)
      ) {
        setTransform(newTransform);
      }
    }
  };

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null,
  );
  const imageRef: React.RefCallback<HTMLImageElement> = useCallback((node) => {
    setImageElement(node);
  }, []);

  const getContainerSize = () => {
    const image = imageElement;
    if (image) {
      setImageSize({
        width: image.clientWidth,
        height: image.clientHeight,
      });
    }
  };

  useResizeObserver(imageElement, getContainerSize);
  useLayoutEffect(() => {
    if (imageElement) {
      imageElement.addEventListener("load", getContainerSize);
      return () => imageElement.removeEventListener("load", getContainerSize);
    }
  }, [imageElement, releasePageData]);

  useResizeObserver(window.document.body, () => {
    if (imageSize.height && imageSize.width) moveToPosition();
  });
  useEffect(() => {
    if (imageSize.height && imageSize.width) moveToPosition();
  }, [imageSize, releasePageData, activeFragmentIndex, activePageFragments]);

  const transformStyle = transform
    ? {
        transform: `
      matrix3d(
        ${transform.tScale}, 0, 0, 0,
        0, ${transform.tScale}, 0, 0,
        0, 0, 1, 0,
        ${transform.tx}, ${transform.ty}, 0, 1
      )
    `,
        transition: "transform 230ms ease-out",
      }
    : undefined;

  return {
    activeFragmentIndex,
    activePageFragments,
    activeFragment,
    transformStyle,
    imageRef,
    ...imageSize,
  };
}
