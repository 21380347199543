import { Image, Text, Box, Flex } from "@chakra-ui/react";
import { ButtonCarousel } from "../../carousel/ButtonCarousel";
import { ProgressBar } from "../../loading/ProgressBar";
import { Release } from "../../../types";
import { Range } from "../../../@types/util";
import { getReleaseTypeName } from "../../../utils/getReleaseTypeName";

export function ReleaseInfoCoverCarousel({
  releases,
  activeReleaseUuid,
  setActiveReleaseUuid,
}: {
  releases: Release[];
  activeReleaseUuid?: string;
  setActiveReleaseUuid: (id: string) => void;
}) {
  const activeRelease = releases.find(
    (release) => release.key === activeReleaseUuid,
  );

  releases = releases.sort((a, b) => a.order - b.order);

  return (
    <Box
      position="relative"
      background="transparent.black.40"
      backgroundBlendMode="multiply"
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.35) inset"
    >
      <ButtonCarousel
        width="100%"
        onlyFade
        slidesToScroll={1}
        wrapperProps={{ px: "16px", py: "20px" }}
        containerProps={{ gap: "16px" }}
        activeSlideIndex={(activeRelease?.chapter ?? 2) - 1}
      >
        {releases?.map((release, i) => (
          <Box
            cursor="pointer"
            onClick={() => setActiveReleaseUuid(release.key ?? "")}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              gap="6.25px"
              width="130px"
            >
              <Image
                src={release.thumbnail_url}
                alt={`Thumbnail for release ${i + 1}`}
                borderRadius="8px"
                boxShadow="0px 10.15px 10.931px 0px rgba(0, 0, 0, 0.25)"
                border="1px solid"
                height="200px"
                opacity={activeRelease?.id === release.id ? 1 : 0.3}
                borderColor={
                  activeRelease?.id === release.id
                    ? "transparent.white.50"
                    : "transparent"
                }
              />
              <ProgressBar
                color1="blaze.blaze"
                color2="blaze.300"
                value={
                  release.user_read_status?.read_progress_percentage as Range<
                    0,
                    101
                  >
                }
              />
              <Text variant="bodyExtraSmallMedium">
                {getReleaseTypeName(release)} #{release.chapter}
              </Text>
            </Flex>
          </Box>
        ))}
      </ButtonCarousel>
    </Box>
  );
}
