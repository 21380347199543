import { Icon, IconProps } from "@chakra-ui/react";

export function GiftCardIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <g id="giftcard">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                      d="M12 3.84111C12.5375 3.03276 13.4565 2.5 14.5 2.5C16.1569 2.5 17.5 3.84315 17.5 5.5C17.5 6.26835 17.2111 6.96924 16.7361 7.5H20C20.8284 7.5 21.5 8.17157 21.5 9V11C21.5 11.6917 21.0318 12.2741 20.3949 12.4475C20.4627 12.6185 20.5 12.8049 20.5 13V19C20.5 19.8284 19.8284 20.5 19 20.5H12H5C4.17157 20.5 3.5 19.8284 3.5 19V13C3.5 12.8049 3.53726 12.6185 3.60504 12.4475C2.96823 12.2741 2.5 11.6917 2.5 11V9C2.5 8.17157 3.17157 7.5 4 7.5H7.26389C6.78885 6.96924 6.5 6.26835 6.5 5.5C6.5 3.84315 7.84315 2.5 9.5 2.5C10.5435 2.5 11.4625 3.03276 12 3.84111ZM12.5 5.5V7.5H13H14.5C15.6046 7.5 16.5 6.60457 16.5 5.5C16.5 4.39543 15.6046 3.5 14.5 3.5C13.3954 3.5 12.5 4.39543 12.5 5.5ZM11 8.5H9.5H4C3.72386 8.5 3.5 8.72386 3.5 9V11C3.5 11.2761 3.72386 11.5 4 11.5H5H11C11.2761 11.5 11.5 11.2761 11.5 11V9C11.5 8.72386 11.2761 8.5 11 8.5ZM9.5 7.5H11H11.5V5.5C11.5 4.39543 10.6046 3.5 9.5 3.5C8.39543 3.5 7.5 4.39543 7.5 5.5C7.5 6.60457 8.39543 7.5 9.5 7.5ZM12.5 9C12.5 8.72386 12.7239 8.5 13 8.5H14.5H20C20.2761 8.5 20.5 8.72386 20.5 9V11C20.5 11.2761 20.2761 11.5 20 11.5H19H13C12.7239 11.5 12.5 11.2761 12.5 11V9ZM5 12.5C4.72386 12.5 4.5 12.7239 4.5 13V19C4.5 19.2761 4.72386 19.5 5 19.5H11.5V13C11.5 12.7239 11.2761 12.5 11 12.5H5ZM13 12.5H19C19.2761 12.5 19.5 12.7239 19.5 13V19C19.5 19.2761 19.2761 19.5 19 19.5H12.5V13C12.5 12.7239 12.7239 12.5 13 12.5Z"
                      fill="currentColor"
                />
            </g>
        </Icon>
    );
}
