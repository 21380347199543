import { Box, Button } from "@chakra-ui/react";
import { useIsSidebarOpen } from "../../../hooks/useIsSidebarOpen";
import { MenuIcon } from "../../icons/MenuIcon";

interface MenuToggleProps {}

export function MenuToggle({}: MenuToggleProps) {
  const { isSidebarOpen, toggleIsSidebarOpen } = useIsSidebarOpen();
  return (
    <Box role="group">
      <Button
        variant="ghostIcon"
        aria-label="Menu Toggle Button"
        pointerEvents="all"
        position="relative"
        size="thin"
        onClick={toggleIsSidebarOpen}
        w="40px"
        h="40px"
        justifyContent="center"
        alignItems="center"
        transition="all .2s linear"
        borderRadius={16}
        boxShadow={
          isSidebarOpen ? "0 0 0 2px var(--chakra-colors-white-10)" : undefined
        }
        _hover={{
          background: "transparent.white.10",
        }}
      >
        <MenuIcon
          transition="inherit"
          w={6}
          h={6}
          color={isSidebarOpen ? "dune.100" : "dune.600"}
        />
      </Button>
    </Box>
  );
}
