import { apiClient } from "../../../services/axiosInstance";
import { Comic, Artist, ServerListResponse } from "../../../types";

export function getComics(queryParams: URLSearchParams) {
  return apiClient.get<ServerListResponse<Comic>>(
    `/v1/comics${queryParams.size > 0 ? "?" + queryParams : ""}`,
  );
}
export function getArtists(queryParams: URLSearchParams) {
  return apiClient.get<ServerListResponse<Artist>>(
    `/v1/artists${queryParams.size > 0 ? "?" + queryParams : ""}`,
  );
}
export const GET_COMIC_LIST_QUERY_KEY = ["comic-lists"];
export const GET_ARTIST_LIST_QUERY_KEY = ["artist-lists"];
