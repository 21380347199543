import { Flex } from "@chakra-ui/react";
import { P2PEnabledToggle } from "./P2PEnabledToggle";
import { P2POverlayOpacitySlider } from "./P2POverlayOpacitySlider";
import { t } from "i18next";
import { SectionHeader } from "../shared/SectionHeader";

export function P2PReadingSettings() {
  return (
    <>
      <SectionHeader text={t("screens.reader.settingsMenu.p2p_reading")} />
      <Flex flexDirection="column" gap="18px">
        <P2PEnabledToggle />
        <P2POverlayOpacitySlider />
      </Flex>
    </>
  );
}
