import { Route } from "react-router-dom";
import ReaderScreen from "./ReaderScreen";
import { ReaderStateWrapper } from "./ReaderStateWrapper";

export const routes = (
  <Route
    index
    path="/read/:uuid/:pageOrder?/:fragmentOrder?"
    element={
      <ReaderStateWrapper>
        <ReaderScreen />
      </ReaderStateWrapper>
    }
  />
);
