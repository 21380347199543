import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { ContentSectionCustomCard, Image, Section } from "../../types";
import { CustomCard } from "./CustomCard";
import { FeatureCardContent } from "./FeatureCardContent";
import EmblaCarousel from "./EmblaCarousel";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import { ButtonCarousel } from "../carousel/ButtonCarousel";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";

const FEATURE_HERO_CARD_TYPE_3_2 = 13;

interface FeatureCardContainerProps {
  data: ContentSectionCustomCard[];
  scrollByCount: number;
  isRoadblock?: boolean;
  section?: Section;
  sectionIndex?: number;
}

export function FeatureCardContainer({
  data,
  scrollByCount,
  isRoadblock = false,
  section,
  sectionIndex,
}: FeatureCardContainerProps) {
  const OPTIONS: EmblaOptionsType = {
    align: "center",
    loop: false,
    watchDrag: data.length > 1, /// Allow drag only if there are more than 1 card
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useMobileBreakpoint();

  const handleSlideChange = (index: number) => {
    setSelectedIndex(index);
  };

  const handleClickHiddenCard = (
    clickedCardIndex: number,
    emblaApi: EmblaCarouselType | undefined,
  ) => {
    if (emblaApi) {
      if (clickedCardIndex < selectedIndex) {
        emblaApi.scrollPrev();
      } else if (clickedCardIndex > selectedIndex) {
        emblaApi.scrollNext();
      }
    }
  };

  const featureCardResponsiveWidths = {
    base: "260px",
    sm: "326px",
    md: "358px",
  };

  const featureCardResponsiveHeights = {
    base: "174px",
    sm: "218px",
    md: "239px",
  };

  const roadblockFeatureCardResponsiveWidths = {
    base: "332px",
    sm: "358px",
    lg: "472px",
    xl: "527px",
  };

  const roadblockFeatureCardResponsiveHeights = {
    base: "221px",
    sm: "239px",
    lg: "315px",
    xl: "352px",
  };

  const roadblockMobileHeight = "calc((100dvw - 8px) * 0.66)";

  const responsiveHeights = isRoadblock
    ? isMobile
      ? roadblockMobileHeight
      : roadblockFeatureCardResponsiveHeights
    : featureCardResponsiveHeights;

  const responsiveWidths = isRoadblock
    ? isMobile
      ? "calc(100% - 8px)"
      : roadblockFeatureCardResponsiveWidths
    : featureCardResponsiveWidths;

  const maxMobileWidth = "400px";
  const maxMobileHeight = "266px";

  const renderSlides = (emblaApi: EmblaCarouselType | undefined) =>
    data.map((customCardData, index) => {
      const isHidden = isMobile && selectedIndex !== index;

      return (
        <Flex
          direction={isRoadblock ? (isMobile ? "column" : "row") : "column"}
          key={customCardData.id}
          onClick={() => isHidden && handleClickHiddenCard(index, emblaApi)}
          style={
            isRoadblock
              ? {
                  flex: "0 0 100%",
                  width: "100%",
                  paddingRight: "16px",
                  gap: !isMobile ? "32px" : undefined,
                  alignItems: isMobile ? "center" : undefined,
                  maxWidth: "1350px",
                  margin: "auto",
                }
              : undefined
          }
        >
          <CustomCard
            key={customCardData.id}
            coverImage={getCoverImage(customCardData.images)}
            contentCard={customCardData}
            cardResponsiveWidths={responsiveWidths}
            cardResponsiveHeights={responsiveHeights}
            cardResponsiveBorderRadius={{ base: "16px" }}
            isHidden={isHidden}
            emblaApi={emblaApi}
            index={index}
            sectionIndex={sectionIndex}
            section={section}
            style={
              isRoadblock
                ? {
                    flexShrink: 0,
                    maxWidth: isMobile ? maxMobileWidth : "100%",
                    maxHeight: isMobile ? maxMobileHeight : undefined,
                  }
                : undefined
            }
          />

          <FeatureCardContent
            isHidden={isHidden}
            contentCard={customCardData}
            isRoadblock={isRoadblock}
            index={index}
            sectionIndex={sectionIndex}
            section={section}
            cardResponsiveWidths={
              isRoadblock ? "100%" : featureCardResponsiveWidths
            }
            style={
              isRoadblock
                ? {
                    maxWidth: isMobile ? maxMobileWidth : undefined,
                    maxHeight: isMobile ? maxMobileHeight : undefined,
                    alignSelf: "center",
                  }
                : undefined
            }
          />
        </Flex>
      );
    });

  return isMobile || isRoadblock ? (
    <EmblaCarousel
      slides={renderSlides}
      slideType={isRoadblock ? "featureRoadblock" : "featureRow"}
      options={OPTIONS}
      onSlideChange={handleSlideChange}
    />
  ) : (
    <Flex role="group">
      <ButtonCarousel
        width="100%"
        slidesToScroll={scrollByCount}
        containerProps={{ gap: 8 }}
        includeSpacer
        includeButtons={!isRoadblock}
        big
      >
        {renderSlides(undefined)}
      </ButtonCarousel>
    </Flex>
  );
}

function getCoverImage(images: Image[]): string {
  let heroImage = images.find(
    (i) => i.image_type_id === FEATURE_HERO_CARD_TYPE_3_2,
  );
  if (heroImage) {
    return heroImage.small_url;
  } else {
    return images[0]?.small_url; // 3:2 - 864x576
  }
}
