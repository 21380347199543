// Pagination utilities for infinite queries

import { GetNextPageParamFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ServerListResponse } from "../types";

export const getNextPageParam: GetNextPageParamFunction<
  number,
  AxiosResponse<ServerListResponse<unknown>>
> = (lastPage, _allPages, pageParam) => {
  if (
    lastPage.data.payload.pagination.page >=
    lastPage.data.payload.pagination.total_pages
  ) {
    return;
  }

  return pageParam + 1;
};

export const getPreviousPageParam: GetNextPageParamFunction<
  number,
  AxiosResponse<ServerListResponse<unknown>>
> = (firstPage, _allPages, pageParam) => {
  if (firstPage.data.payload.pagination.page <= 1) {
    return;
  }
  return pageParam - 1;
};
