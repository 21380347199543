export const readingStatus = {
  updateStatus: "Update status",
  addToLibrary: "Add to library",
  manageLibrary: "Manage library",
  manage: "manage",
  add: "add",
  remove: "remove",
  unfollow: "unfollow",
  follow: "follow",
};
