import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageParam, getPreviousPageParam } from "../../../utils/api";
import {
  GET_ARTIST_LIST_QUERY_KEY,
  GET_COMIC_LIST_QUERY_KEY,
  getArtists,
  getComics,
} from "../api/browseScreenApi";
import { useBrowseFilterState } from "./useBrowseFiltersState";
import { useAccountData } from "../../../hooks/useAccountData";

export function useBrowseFiltersAPI(isOpen: boolean, tab: 0 | 1) {
  const { filters, urlSettled } = useBrowseFilterState(tab);
  const accountData = useAccountData();

  const artistsQueryKey = [
    ...GET_ARTIST_LIST_QUERY_KEY,
    tab,
    accountData?.user?.id,
  ];
  const {
    isLoading: isLoadingArtistData,
    data: artistData,
    error: artistError,
    hasNextPage: hasNextArtistPage,
    isFetchingNextPage: isFetchingNextArtistPage,
    fetchNextPage: fetchNextArtistPage,
    isRefetching: isRefetchingArtistData,
  } = useInfiniteQuery({
    enabled: !isOpen && tab === 1 && urlSettled,
    queryKey: artistsQueryKey,
    queryFn: (ctx) => {
      const paginatedFilters = new URLSearchParams(filters);
      paginatedFilters.set("p", "" + ctx.pageParam);
      return getArtists(paginatedFilters);
    },
    initialPageParam: 1,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
  });

  const comicsQueryKey = [
    ...GET_COMIC_LIST_QUERY_KEY,
    tab,
    accountData?.user?.id,
  ];
  const {
    isLoading: isLoadingComicData,
    data: comicData,
    error: comicError,
    hasNextPage: hasNextComicPage,
    isFetchingNextPage: isFetchingNextComicPage,
    fetchNextPage: fetchNextComicPage,
    isRefetching: isRefetchingComicData,
  } = useInfiniteQuery({
    enabled: !isOpen && tab === 0 && urlSettled,
    queryKey: comicsQueryKey,
    queryFn: (ctx) => {
      const paginatedFilters = new URLSearchParams(filters);
      paginatedFilters.set("p", "" + ctx.pageParam);
      return getComics(paginatedFilters);
    },
    initialPageParam: 1,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
  });

  return tab === 0
    ? {
        urlSettled,
        isLoading: isRefetchingComicData || isLoadingComicData,
        data: comicData,
        error: comicError,
        hasNextPage: hasNextComicPage,
        isFetchingNextPage: isFetchingNextComicPage,
        fetchNextPage: fetchNextComicPage,
        queryKey: artistsQueryKey,
      }
    : {
        urlSettled,
        isLoading: isRefetchingArtistData || isLoadingArtistData,
        data: artistData,
        error: artistError,
        hasNextPage: hasNextArtistPage,
        isFetchingNextPage: isFetchingNextArtistPage,
        fetchNextPage: fetchNextArtistPage,
        queryKey: comicsQueryKey,
      };
}
