import { useCallback } from "react";
import { useReleaseData } from "./hookstate/useReleaseData";

export function useShareRelease() {
  const { releaseData } = useReleaseData();

  const shareRelease = useCallback(() => {
    if (!releaseData) return;
    if (navigator.share) {
      navigator
        .share({
          title: releaseData.title,
          text: `${releaseData.comic.name} Chapter ${releaseData.chapter}`,
          url: releaseData.share_url,
        })
        .catch((e) => console.warn(e));
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(releaseData.share_url)
        .then(() => alert("URL copied to clipboard"))
        .catch((e) => console.warn(e));
    } else {
      console.warn("Sharing failed");
    }
  }, [releaseData]);

  return { shareRelease };
}
