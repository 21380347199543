import { Icon, IconProps } from "@chakra-ui/react";

export function HeartThickIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3591 5.95025C14.2571 3.57778 17.7959 3.38178 19.9442 5.53014C21.8683 7.45423 21.942 10.5503 20.1116 12.5637L12.7398 20.6727C12.5503 20.8812 12.2817 21 11.9999 21C11.7182 21 11.4495 20.8812 11.26 20.6727L3.8882 12.5637C2.0578 10.5503 2.13148 7.45423 4.05557 5.53014C6.20393 3.38178 9.74274 3.57778 11.6407 5.95025L11.9999 6.39924L12.3591 5.95025ZM18.53 6.94436C17.2246 5.63888 15.0742 5.75798 13.9208 7.19964L12.7808 8.62471C12.591 8.86193 12.3037 9.00002 11.9999 9.00002C11.6961 9.00002 11.4088 8.86193 11.219 8.62471L10.079 7.19964C8.92565 5.75798 6.77526 5.63888 5.46978 6.94436C4.30059 8.11355 4.25582 9.99492 5.36808 11.2184L11.9999 18.5134L18.6317 11.2184C19.744 9.99492 19.6992 8.11355 18.53 6.94436Z"
        fill="currentColor"
      />
    </Icon>
  );
}
