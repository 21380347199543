export const payments = {
  subscription: {
    cancelAnytime: "Cancel anytime",
    confirmYourPayment: "Start membership",
    confirmGiftCardPayment: "Buy now",
    lockedPages: "Locked pages",
    subscriptionDeclined:
      "There was a problem completing your subscription purchase",
    signUpToComplete: "Next",
    step2: "Step 2",
    applyPromoToComplete: "Apply your promo code to complete your purchase",
    purchasing: "Completing purchase",
    giftDelivery: "The gift card will be emailed to you.",
  },
  promoCode: {
    enterPromoCode: "Enter promo code",
    apply: "Apply",
    enterAnotherPromoCode: "Enter another promo code",
    applied: "applied",
    cannotBeUsed: "cannot be used",
  },
  donate: {
    donateToCreator: "Donate to creator",
    donate: "Donate",
    creator: "Creator",
    monthlyGoal: "Monthly goal",
    amountToDonate: "Enter amount to donate (${{minDonation}} minimum)",
    helpFund: "Help fund the creator for their time and work",
    supporterBadge: "Get a Supporter badge on your avatar sitewide",
    earlyAccess: "Get early access to new releases",
    repeatMonthly: "Repeat monthly",
    becomeASuperfan:
      "Become a super-fan and repeat this donation amount at the start of every month",
    donationSuccess: "Donation successful!",
    donationDeclined: "There was a problem completing your donation",
    minimumValue: "Minimum value for this field is: 3",
    wellDoneYouSuccessfullyDonated:
      "Well done! You successfully \n donated to {{artistName}}",
    makeAnotherDonation: "Make another donation",
    imAllSet: "I'm all set",
  },
  pdf: {
    digitalDownload: "Digital download (DRM-FREE)",
    unrestrictedAccess: "Unrestricted access",
    toOnlineReading: "to online reading of this release, forever",
    unlimitedDownloadAvailability: "Unlimited download availability",
    ownerDeletesBooks: " - even if the owner deletes their books",
    purchaseRelease: "Purchase release",
    purchaseReleaseFor: "Purchase release for",
    pagesPDF: "page PDF",
    purchaseSuccess: "Purchase successful!",
    purchaseError: "There was an error with your purchase",
    successYouCanNowDownload:
      "Success! You can now download and read your release.",
    downloadYourRelease: "Download your release",
    minimumAmount: "Minimum amount: ",
    minimum: "Minimum: ",
    enterAmountYouWantToPay:
      "Enter amount you want to pay ({{minPrice}} minimum)",
  },
  payments: {
    expires: "Expires",
    newCardNumber: "New card number",
  },
  purchaseRelease: {
    unlockRelease: "Unlock release",
    pages: "pages",
    purchaseDecline: "There was a problem completing your purchase",
    purchaseSuccess: "Purchase successful!",
  },
  prePaywall: {
    buyTheBookToContinueReading: "Buy the book to continue reading",
    becomeAMember: "Become a member",
    buy: "Buy {{price}}",
    orPayFullPrice: "or pay full price",
    forNoMembers: "{{price}} for non-members",
    withGoldMembership: "{{price}} with Gold membership",
    notAvailableWithoutGoldMembership: "Not available without Gold membership",
    unRestrictedAccessOnlineReading:
      "Unrestricted access to online reading of this release",
    supportTheCreators:
      "Support the creators - up to 70% of your purchase goes directly to creators",
    getBookFreeWithGold:
      "Get this book free with a Gold Membership to continue reading",
    freeWithGoldMembership: "Free with Gold membership",
    free: "Free",
  },
};
