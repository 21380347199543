import { Fragment } from "../../../types";
import { FRAGMENT_BASE_SCALE, P2P_MAX_SCALE } from "../constants";

/**
 * Calculate target scale relative to parent
 * @param target {{width: number, height: number}}
 * @param parent {{width: number, height: number}}
 * @returns {number}
 */
function calcScale(
  target: { width: number; height: number },
  parent: { width: number; height: number },
): number {
  const targetRatio = target.width / target.height;
  const parentRatio = parent.width / parent.height;

  // Scale target by Y axis if its relative shape is
  // taller than the parent's relative shape
  return parentRatio > targetRatio
    ? FRAGMENT_BASE_SCALE / (target.height / parent.height)
    : FRAGMENT_BASE_SCALE / (target.width / parent.width);
}

/**
 * Constrain fragment scaling
 * ---
 * Ensures that the image asset isn't scaled more than
 * allowed by the PAGE_MAX_SCALE constant
 * @param scale {number}
 * @param relativeSize {number}
 * @param originalSize {number}
 * @returns {number}
 */
function constrainFragmentScale(
  scale: number,
  relativeSize: number,
  originalSize: number,
): number {
  return Math.min(scale, (originalSize / relativeSize) * P2P_MAX_SCALE);
}

/**
 * Calculate fragment absolute offset
 * @param relativeOffset {number}
 * @param pageSize {number}
 * @param parentSize {number}
 * @returns {number}
 */
function calcFragmentOffset(
  relativeOffset: number,
  pageSize: number,
  parentSize: number,
): number {
  return pageSize * relativeOffset + (parentSize - pageSize) / 2;
}

/**
 * Transform offset relative to parent
 * @param size {number}
 * @param offset {number}
 * @param parentSize {number}
 * @returns {number}
 */
function transformOffset(
  size: number,
  offset: number,
  parentSize: number,
): number {
  return parentSize / 2 - (offset + size / 2);
}

/**
 * Transform page to focus on given fragment
 * @param fragment {Object}
 * @param pageImage {Object}
 * @param pageElement {HTMLElement}
 * @returns {{tx: number, ty: number, tScale: number}}
 */
export default function transformPage(
  fragment: Fragment,
  width: number,
  pageElement: HTMLElement,
): { tx: number; ty: number; tScale: number } | undefined {
  const parent = document.getElementById("p2pReader");
  if (!parent) return undefined;

  // Calculate fragment size and position
  const fragmentWidth = pageElement.clientWidth * fragment.width;
  const fragmentHeight = pageElement.clientHeight * fragment.height;
  const fragmentLeft = calcFragmentOffset(
    fragment.left,
    pageElement.clientWidth,
    parent.clientWidth,
  );
  const fragmentTop = calcFragmentOffset(
    fragment.top,
    pageElement.clientHeight,
    parent.clientHeight,
  );

  // Calculate page transformation
  const tx = transformOffset(fragmentWidth, fragmentLeft, parent.clientWidth);
  const ty = transformOffset(fragmentHeight, fragmentTop, parent.clientHeight);
  const targetScale = calcScale(
    { width: fragmentWidth, height: fragmentHeight },
    { width: parent.clientWidth, height: parent.clientHeight },
  );
  const constrainedScale = constrainFragmentScale(
    targetScale,
    fragmentWidth,
    fragment.width * width,
  );

  return {
    tScale: constrainedScale,
    tx: tx * constrainedScale,
    ty: ty * constrainedScale,
  };
}
