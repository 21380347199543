import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Slide,
  StackDivider,
} from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { CSSProperties } from "react";

interface GCModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  innerPadding?: string;
  width?: CSSProperties["width"];
  minWidth?: CSSProperties["minWidth"];
  maxWidth?: CSSProperties["maxWidth"];
  locked?: boolean;
  trapFocus?: boolean;
  dividerPadding?: string;
  hideDivider?: boolean;
}

export function GCModal({
  isOpen,
  onClose,
  header,
  body,
  footer,
  innerPadding,
  width,
  minWidth,
  maxWidth,
  locked = false,
  trapFocus = false,
  dividerPadding,
  hideDivider = false,
}: GCModalProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="none"
      size={isSmallBreakpoint ? "full" : undefined}
      closeOnEsc={!locked && !trapFocus}
      closeOnOverlayClick={!locked && !trapFocus}
      trapFocus={!locked || trapFocus}
      blockScrollOnMount={false}
    >
      <ModalOverlay
        cursor={trapFocus ? "default" : "pointer"}
        onClick={() => {
          if (trapFocus) return;
          onClose();
        }}
        pointerEvents={!locked ? "all" : "none"}
        zIndex={locked ? undefined : 1409}
      />
      {isSmallBreakpoint ? (
        <Slide
          in={isOpen}
          direction="bottom"
          style={{ width: "100%", height: "100dvh", zIndex: 1409 }}
        >
          <ModalContentContent
            innerPadding={innerPadding}
            width={isSmallBreakpoint ? "100%" : width}
            header={header}
            body={body}
            footer={footer}
            borderRadius={"0px"}
            minWidth={isSmallBreakpoint ? "100%" : minWidth}
            maxWidth={isSmallBreakpoint ? "100%" : maxWidth}
            locked={locked}
            dividerPadding={dividerPadding}
            maxHeight={"100dvh"}
            hideDivider={hideDivider}
          />
        </Slide>
      ) : (
        <ModalContentContent
          width={isSmallBreakpoint ? undefined : maxWidth}
          innerPadding={innerPadding}
          header={header}
          body={body}
          footer={footer}
          minWidth={isSmallBreakpoint ? "100%" : minWidth}
          maxWidth={isSmallBreakpoint ? "100%" : maxWidth}
          locked={locked}
          dividerPadding={dividerPadding}
          maxHeight={"90vh"}
          hideDivider={hideDivider}
        />
      )}
    </Modal>
  );
}

interface ModalContentContentProps {
  innerPadding?: string;
  width?: CSSProperties["width"];
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  borderRadius?: string;
  minWidth?: CSSProperties["minWidth"];
  maxWidth?: CSSProperties["maxWidth"];
  maxHeight?: CSSProperties["maxHeight"];
  locked?: boolean;
  dividerPadding?: string;
  hideDivider: boolean;
}

function ModalContentContent({
  innerPadding,
  header,
  body,
  footer,
  borderRadius,
  minWidth,
  maxWidth,
  locked,
  dividerPadding,
  maxHeight,
  hideDivider = false,
}: ModalContentContentProps) {
  const isSmall = useIsSmallBreakpoint();

  return (
    <ModalContent
      borderRadius={borderRadius || "16px"}
      width="fit-content"
      minWidth={minWidth}
      maxWidth={maxWidth}
      border={"1px solid var(--chakra-colors-transparent-white-10)"}
      background="#141517f2"
      alignSelf={isSmall ? undefined : "center"}
      backdropFilter={"blur(18px)"}
      boxShadow={"0px 4px 24px 0px var(--chakra-colors-transparent-black-40)"}
      containerProps={{
        pointerEvents: "none",
        zIndex: locked ? undefined : 1409,
        overflow: "unset",
      }}
    >
      <ModalBody
        padding="0px"
        pointerEvents="auto"
        maxHeight={maxHeight}
        borderRadius={borderRadius ?? "16px"}
      >
        <Flex
          direction="column"
          maxHeight={maxHeight}
          borderRadius={borderRadius ?? "16px"}
          background={hideDivider ? "#141517f2" : undefined}
        >
          {header && (
            <>
              <Box padding={innerPadding ?? "16px 24px"}>{header}</Box>
              {!locked && !hideDivider && (
                <StackDivider
                  height="1px"
                  backgroundColor="transparent.white.10"
                />
              )}
            </>
          )}
          <Flex
            id="gcModalScrollContainer"
            direction="column"
            background="#141517f2"
            overflowY="auto"
            borderRadius={borderRadius ?? "16px"}
            flexDirection="column"
            height={isSmall ? "100dvh" : undefined}
          >
            <Box
              margin={hideDivider ? "auto" : " auto 0"}
              width={
                hideDivider
                  ? { base: "100%", md: "80%", lg: "100%" }
                  : undefined
              }
            >
              <Box
                padding={innerPadding ?? "24px"}
                paddingTop={hideDivider ? "0px" : undefined}
                borderRadius={borderRadius ?? "16px"}
              >
                {body}
              </Box>
              {footer && (
                <>
                  <StackDivider
                    height="1px"
                    backgroundColor="transparent.white.10"
                    mx={dividerPadding}
                  />
                  <Box padding={innerPadding ?? "24px"}>{footer}</Box>
                </>
              )}
            </Box>
          </Flex>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
}
