import { Button, Flex } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";

export function AllPagesBottomBar() {
  const { onCloseMenu } = useSlideInMenu();

  const { t } = useTranslation();

  return (
    <Flex
      height="96px"
      justifyContent="center"
      position="absolute"
      width="100%"
      bottom={0}
      right={0}
      flexDirection="row"
      backdropFilter="blur(18px)"
      background="#141517CC" //This is charcoal with 80% opacity
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      sx={{
        borderTopColor: "transparent.white.10",
      }}
      borderTop="1px solid"
      p="24px"
    >
      <Button
        variant="primary"
        size="lg"
        width="100%"
        aria-label="Continue reading"
        flex={1}
        textTransform="capitalize"
        onClick={onCloseMenu}
      >
        {t("components.slideInMenu.comicDetailsMenu.continueReading")}
      </Button>
    </Flex>
  );
}
