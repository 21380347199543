import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { PagesIcon } from "../../../../components/icons/PagesIcon";
import { ComicTypeEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const COMIC_TYPE_ALL = "0";

interface ComicTypeFilterProps {
  value: Immutable<string | undefined>;
  comicTypes: ComicTypeEntity[];
  onChange: (nextValue: string) => void;
}
export function ComicTypeFilter({
  value,
  comicTypes,
  onChange,
}: ComicTypeFilterProps) {
  const typeOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: COMIC_TYPE_ALL },
      ...comicTypes.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <RadioFilter value={value} options={typeOptions} onChange={onChange} />
  );
}

interface ComicTypeTitleProps {
  isTrigger: boolean;
  comicTypes: ComicTypeEntity[];
  value?: string;
  open?: boolean;
}
export const ComicTypeFilterTitle = forwardRef(
  (
    { isTrigger, comicTypes, value, open, ...props }: ComicTypeTitleProps,
    ref,
  ) => {
    const comicType = comicTypes.find((type) => `${type.id}` == value);
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<PagesIcon />}
          popoverRef={ref}
          text="Type"
          isFiltering={comicType?.plural_name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Type"
        filterValue={comicType?.plural_name}
        open={open}
      />
    );
  },
);
