import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useP2PSettings } from "./useP2PSettings";
import { buildReaderUrl } from "../../../utils/buildReaderUrl";
import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../ReaderStateWrapper";

export function useP2PUrl() {
  const navigate = useNavigate();
  const { fragmentOrder } = useParams();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const loaded = useHookstate(readerDataStore).get();
  useEffect(() => {
    if (p2pEnabledAndAugmented && loaded) {
      if (!fragmentOrder) {
        navigate(
          buildReaderUrl(window.location.pathname, undefined, undefined, 1),
          { replace: true },
        );
      }
    } else if (loaded && fragmentOrder) {
      navigate(
        buildReaderUrl(
          window.location.pathname,
          undefined,
          undefined,
          undefined,
        ),
        { replace: true },
      );
    }
  }, [p2pEnabledAndAugmented, loaded]);
}
