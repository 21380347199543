import { Icon, IconProps } from "@chakra-ui/react";

export function PanelToPanelIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64645 7.64645C9.84171 7.45118 10.1583 7.45118 10.3536 7.64645L14.3536 11.6464C14.5488 11.8417 14.5488 12.1583 14.3536 12.3536L10.3536 16.3536C10.1583 16.5488 9.84171 16.5488 9.64645 16.3536C9.45118 16.1583 9.45118 15.8417 9.64645 15.6464L13.2929 12L9.64645 8.35355C9.45118 8.15829 9.45118 7.84171 9.64645 7.64645Z"
        fill="currentColor"
      />
      <path d="M5 6H12L11 10H5V6Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.01C4.5 5.72833 4.72834 5.5 5.01 5.5H11.9872C12.319 5.5 12.5624 5.81181 12.482 6.13369L11.487 10.1137C11.4302 10.3407 11.2262 10.5 10.9922 10.5H5.01C4.72836 10.5 4.5 10.2717 4.5 9.99V6.01ZM5.5 6.5V9.5H10.6096L11.3596 6.5H5.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5129 5.88631C15.5697 5.65927 15.7737 5.5 16.0077 5.5H18.9899C19.2716 5.5 19.4999 5.72834 19.4999 6.01V17.99C19.4999 18.2717 19.2715 18.5 18.9899 18.5H13.0127C12.6809 18.5 12.4374 18.1882 12.5179 17.8663L15.5129 5.88631ZM16.3903 6.5L13.6403 17.5H18.4999V6.5H16.3903Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 14.01C4.5 13.7283 4.72834 13.5 5.01 13.5H9.98719C10.319 13.5 10.5624 13.8118 10.482 14.1337L9.48696 18.1137C9.4302 18.3407 9.22621 18.5 8.99219 18.5H5.01C4.72833 18.5 4.5 18.2717 4.5 17.99V14.01ZM5.5 14.5V17.5H8.60961L9.35961 14.5H5.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
