export const cards = {
  customCard: {
    ribbonFacetText: {
      exclusive: "Exclusive",
      staffPick: "Staff Pick",
      verticals: "Verticals",
      originals: "Originals",
      comingSoon: "Coming Soon",
      newArrival: "New Arrival",
    },
    buttonText: {
      continueReading: "Continue Reading",
      startReading: "Start Reading",
      follow: "Follow",
      following: "Following",
      viewProfile: "View Profile",
      learnMore: "Learn More",
    },
    comicReadingStyle: {
      panelToPanel: "Panel To Panel",
      verticalScroll: "Vertical Scroll",
      printLayout: "Print Layout",
    },
    comicUpdateFrequency: {
      weekly: "Weekly",
      biWeekly: "Bi-Weekly",
      monthly: "Monthly",
      biMonthly: "Bi-Monthly",
      quarterly: "Quarterly",
      twiceAYear: "Twice A Year",
      yearly: "Yearly",
      updatedLastMonth: "Updated Last Month",
      updates: "Updates",
      on: "on {{day}}",
      daysOfWeek: {
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday",
        "7": "Sunday",
      },
    },
  },
  expandedContentSection: {
    close: "Close",
    share: "Share",
    backToHome: "Back to home",
  },
  contentReleaseCalendar: {
    showingCards: "Showing {{cardsShown}} out of {{totalCards}}",
  },
  facets: {
    leaving: "Leaving",
  },
  releaseCardMenu: {
    startReading: "Start reading",
    continueReading: "Continue reading",
    previewRelease: "Preview release",
    releaseDetails: "Release details",
    share: "Share",
  },
  unlockReleaseCard: {
    unlock: "Unlock",
    freeWithGold: "Free with Gold",
  },
};
