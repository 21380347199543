import { Text } from "@chakra-ui/react";
import { ContentDivider } from "./ContentDivider";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export interface LegalHeaderProps {
  title: string;
}

export function LegalHeader({ title }: LegalHeaderProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <>
      <Text
        variant="legalTitle"
        pb="16px"
        pt={!isSmallBreakpoint ? "20px" : undefined}
      >
        {title}
      </Text>
      <Text variant="lastUpdate" pb="24px">
        Last Updated: Nov 17, 2020
      </Text>
      <ContentDivider />
    </>
  );
}
