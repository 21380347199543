import { Image, Flex, Text } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { Comic } from "../../types";

interface ComicThumbnailProps {
  comicData: ImmutableObject<Comic> | undefined;
}
export function ComicThumbnail({ comicData }: ComicThumbnailProps) {
  return (
    <Flex gap="16px" alignItems="center">
      <Image
        borderRadius="8px"
        width="64px"
        minWidth="64px"
        alt={`Thumbnail for comic ${comicData?.name}`}
        src={comicData?.image_tiny_url}
        loading="lazy"
      />
      <Flex flexDirection="column">
        <Text fontSize="18px" fontWeight="700" lineHeight="28px">
          {comicData?.name}
        </Text>
        <Text
          color="reader.blue.200"
          fontWeight="600"
          fontSize="14px"
          lineHeight="20px"
        >
          {comicData?.artist?.roman_name}
        </Text>
      </Flex>
    </Flex>
  );
}
