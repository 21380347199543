import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { Release } from "../../../../types";

export function useComicReleasesData() {
  const comicReleasesData = useHookstate(readerDataStore.comicReleasesData).get(
    {
      noproxy: true,
    },
  );

  const setComicReleasesData = (data: Release[]) => {
    readerDataStore.comicReleasesData.set(data);
  };

  return { comicReleasesData, setComicReleasesData };
}
