import { RouteObject, To, matchRoutes, resolvePath } from "react-router-dom";
import { PreloadableComponent } from "./lazyPreload";
import { ComponentType } from "react";

export function findComponentForRoute<T extends ComponentType<any>>(
  to: To,
  routes: RouteObject[],
): PreloadableComponent<T> | null {
  const path = resolvePath(to);
  const matchedRoutes = matchRoutes(routes, path);

  // Matched routes are returned in order of least specific to most specific
  if (matchedRoutes && matchedRoutes.length) {
    return matchedRoutes[matchedRoutes.length - 1].route
      .Component as unknown as PreloadableComponent<T>;
  }

  return null;
}
