import { useLocation } from "react-router";
import { NavigationTrigger, ReaderNavigationContext } from "../types";
import { useMemo } from "react";
import { rootComponentsStore } from "../../../components/layouts/RootLayout";
import { useEndInterstitial } from "./useEndInterstital";
import { Section } from "../../../types";
import { useCurrentCMSStream } from "../../../hooks/useCurrentCMSStream";

export interface UseGetReaderNavigationContextProps {
  trigger?: NavigationTrigger;
  contextComicId?: number;
  sectionPosition?: number;
  cardPosition?: number;
  section?: Section;
  notificationTypeId?: number;
  area?: string;
}

export function useGetReaderNavigationContext(
  {
    trigger,
    contextComicId,
    section,
    cardPosition,
    sectionPosition,
    notificationTypeId,
    area,
  }: UseGetReaderNavigationContextProps,
  dependencies: any[] = [],
) {
  const location = useLocation();
  const { showEndInterstitial } = useEndInterstitial();
  const currentArea =
    area ?? getCurrentArea(location.pathname, !!showEndInterstitial);
  let stream = useCurrentCMSStream(currentArea);

  return useMemo<ReaderNavigationContext>(() => {
    let q = undefined;

    if (currentArea === "search") {
      const queryParams = new URLSearchParams(location.search);
      q = queryParams.get("q") ?? undefined;
    }

    return {
      area: currentArea,
      comicId: contextComicId,
      trigger,
      sectionPosition,
      cardPosition,
      sectionId: section?.id,
      sectionType: section?.source_type,
      q,
      stream: stream?.id,
      notificationTypeId,
    };
  }, [currentArea, trigger, contextComicId, ...dependencies]);
}

const getCurrentArea = (pathname: string, showEndInterstitial: boolean) => {
  const isInReader = rootComponentsStore.inReader.get();

  if (isInReader) {
    if (showEndInterstitial) return "interstitial";
    return "consumption";
  }
  if (pathname.includes("/originals")) return "gc-originals";
  if (pathname.includes("/browse")) return "search";
  if (pathname === "/" || pathname.includes("/channel")) return "home";
  if (pathname.includes("/library")) return "library";
};
