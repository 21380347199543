import { Icon, IconProps } from "@chakra-ui/react";

export function GridIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.5C14.1716 3.5 13.5 4.17157 13.5 5V9C13.5 9.82843 14.1716 10.5 15 10.5H19C19.8284 10.5 20.5 9.82843 20.5 9V5C20.5 4.17157 19.8284 3.5 19 3.5H15ZM14.5 5C14.5 4.72386 14.7239 4.5 15 4.5H19C19.2761 4.5 19.5 4.72386 19.5 5V9C19.5 9.27614 19.2761 9.5 19 9.5H15C14.7239 9.5 14.5 9.27614 14.5 9V5ZM5 13.5C4.17157 13.5 3.5 14.1716 3.5 15V19C3.5 19.8284 4.17157 20.5 5 20.5H9C9.82843 20.5 10.5 19.8284 10.5 19V15C10.5 14.1716 9.82843 13.5 9 13.5H5ZM4.5 15C4.5 14.7239 4.72386 14.5 5 14.5H9C9.27614 14.5 9.5 14.7239 9.5 15V19C9.5 19.2761 9.27614 19.5 9 19.5H5C4.72386 19.5 4.5 19.2761 4.5 19V15ZM13.5 15C13.5 14.1716 14.1716 13.5 15 13.5H19C19.8284 13.5 20.5 14.1716 20.5 15V19C20.5 19.8284 19.8284 20.5 19 20.5H15C14.1716 20.5 13.5 19.8284 13.5 19V15ZM15 14.5C14.7239 14.5 14.5 14.7239 14.5 15V19C14.5 19.2761 14.7239 19.5 15 19.5H19C19.2761 19.5 19.5 19.2761 19.5 19V15C19.5 14.7239 19.2761 14.5 19 14.5H15ZM5 3.5C4.17157 3.5 3.5 4.17157 3.5 5V9C3.5 9.82843 4.17157 10.5 5 10.5H9C9.82843 10.5 10.5 9.82843 10.5 9V5C10.5 4.17157 9.82843 3.5 9 3.5H5ZM4.5 5C4.5 4.72386 4.72386 4.5 5 4.5H9C9.27614 4.5 9.5 4.72386 9.5 5V9C9.5 9.27614 9.27614 9.5 9 9.5H5C4.72386 9.5 4.5 9.27614 4.5 9V5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
