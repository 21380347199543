import { useState, useEffect } from "react";

export function useIsMouseInput() {
  const [isMouseInput, setIsMouseInput] = useState(false);

  useEffect(() => {
    // Check if the device supports touch
    const mediaQuery = window.matchMedia("(pointer: fine)");
    setIsMouseInput(mediaQuery.matches);

    // You can also listen for the first pointer event (pointerdown) to confirm device input
    const handlePointerDown = (event: PointerEvent) => {
      if (event.pointerType === "mouse") {
        setIsMouseInput(true);
      } else {
        setIsMouseInput(false);
      }
    };

    window.addEventListener("pointerdown", handlePointerDown);

    // Clean up event listener
    return () => {
      window.removeEventListener("pointerdown", handlePointerDown);
    };
  }, []);

  return isMouseInput;
}
