import { Icon, IconProps } from "@chakra-ui/react";

export function EyeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0383 7.85904C4.47702 9.1621 3.39055 10.6866 2.84287 11.5538C2.6986 11.7823 2.68097 11.819 2.66509 11.8883C2.6533 11.9397 2.6533 12.0603 2.66509 12.1117C2.68097 12.181 2.6986 12.2177 2.84287 12.4462C3.39055 13.3134 4.47702 14.8379 6.0383 16.141C7.5984 17.443 9.60249 18.5 12.0004 18.5C14.3983 18.5 16.4024 17.443 17.9625 16.141C19.5238 14.8379 20.6103 13.3134 21.1579 12.4462C21.3022 12.2177 21.3198 12.181 21.3357 12.1117C21.3475 12.0603 21.3475 11.9397 21.3357 11.8883C21.3198 11.819 21.3022 11.7823 21.1579 11.5538C20.6103 10.6866 19.5238 9.1621 17.9625 7.85904C16.4024 6.55695 14.3983 5.5 12.0004 5.5C9.60249 5.5 7.5984 6.55696 6.0383 7.85904ZM5.39753 7.0913C7.06997 5.69547 9.29332 4.5 12.0004 4.5C14.7075 4.5 16.9308 5.69547 18.6033 7.0913C20.2745 8.48615 21.4257 10.1051 22.0034 11.0198C22.0096 11.0295 22.0157 11.0392 22.0217 11.0488C22.1427 11.242 22.2529 11.4162 22.3094 11.6669C22.3549 11.8653 22.3549 12.1387 22.3094 12.3371C22.252 12.588 22.1426 12.7621 22.0217 12.9512C21.9851 13.0118 21.9496 13.0719 21.9141 13.1316C21.4264 13.9754 20.3165 15.5231 18.3976 17.0913C16.4767 18.6594 14.2057 19.5 12.0004 19.5C9.79517 19.5 7.52423 18.6594 5.60331 17.0913C3.68442 15.5231 2.57453 13.9754 2.08682 13.1316C2.05129 13.0719 2.01582 13.0118 1.97928 12.9512C1.85839 12.7621 1.74897 12.588 1.69251 12.3371C1.64698 12.1387 1.64698 11.8653 1.69251 11.6669C1.74901 11.4162 1.85925 11.242 1.98026 11.0488C1.98634 11.0392 1.99246 11.0295 1.99861 11.0198C2.57636 10.1051 3.72752 8.48615 5.39753 7.0913ZM15.2275 12C15.2275 13.785 13.7854 15.2271 12.0004 15.2271C10.2153 15.2271 8.77324 13.785 8.77324 12C8.77324 10.2149 10.2153 8.77287 12.0004 8.77287C13.7854 8.77287 15.2275 10.2149 15.2275 12ZM16.2275 12C16.2275 14.4852 14.4856 16.2271 12.0004 16.2271C9.51514 16.2271 7.77324 14.4852 7.77324 12C7.77324 9.51474 9.51514 7.77287 12.0004 7.77287C14.4856 7.77287 16.2275 9.51474 16.2275 12Z"
        fill="currentColor"
      />
    </Icon>
  );
}
