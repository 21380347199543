import { t } from "i18next";
import { useVerticalMargin } from "../../hooks/useVerticalMargin";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { useReadingDirection } from "../../hooks/useReadingDirection";
import { SettingsToggle } from "./SettingsToggle";

export function VerticalLayoutMarginToggle() {
  const { hasVerticalMargin, toggleHasVerticalMargin } = useVerticalMargin();
  const { comicIsVertical } = useReadingDirection();
  const { p2pEnabledAndAugmented } = useP2PSettings();

  return (
    <SettingsToggle
      isDisabled={comicIsVertical || p2pEnabledAndAugmented}
      isChecked={!comicIsVertical && hasVerticalMargin}
      onChange={toggleHasVerticalMargin}
      title={t("screens.reader.settingsMenu.vertical_layout_image_margin")}
      subtitle={t("screens.reader.settingsMenu.image_margin_description")}
    />
  );
}
