import { Icon, IconProps } from "@chakra-ui/react";

export function NotificationIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="notification">
        <path
          id="notification_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6188 1.71273C17.785 1.49219 18.0985 1.44814 18.319 1.61432C19.7672 2.7056 20.9081 4.15332 21.6305 5.81649C21.7406 6.06977 21.6244 6.36428 21.3712 6.4743C21.1179 6.58433 20.8234 6.4682 20.7133 6.21492C20.0597 4.71015 19.0274 3.40031 17.7172 2.41296C17.4967 2.24677 17.4526 1.93327 17.6188 1.71273ZM5.5 9.99994C5.5 6.41009 8.41014 3.49995 12 3.49994C15.5898 3.49993 18.5 6.41008 18.5 9.99994V13.7223C18.5 14.0327 18.6233 14.3303 18.8427 14.5498L19.3536 15.0606C19.4473 15.1544 19.5 15.2816 19.5 15.4142V16C19.5 16.2761 19.2761 16.5 19 16.5H5C4.72386 16.5 4.5 16.2761 4.5 16V15.4142C4.5 15.2816 4.55268 15.1544 4.64645 15.0606L5.15727 14.5498L4.80769 14.2002L5.15727 14.5498C5.37672 14.3303 5.5 14.0327 5.5 13.7224V9.99994ZM12 2.49994C7.85785 2.49995 4.5 5.85781 4.5 9.99994V13.7224C4.5 13.7675 4.48207 13.8108 4.45016 13.8427L3.93934 14.3535C3.65804 14.6348 3.5 15.0163 3.5 15.4142V16C3.5 16.8284 4.17157 17.5 5 17.5H8.5V18C8.5 19.933 10.067 21.5 12 21.5C13.933 21.5 15.5 19.933 15.5 18V17.5H19C19.8284 17.5 20.5 16.8284 20.5 16V15.4142C20.5 15.0163 20.342 14.6348 20.0607 14.3535L19.5498 13.8427C19.5179 13.8107 19.5 13.7675 19.5 13.7223V9.99994C19.5 5.8578 16.1421 2.49993 12 2.49994ZM9.5 18V17.5H14.5V18C14.5 19.3807 13.3807 20.5 12 20.5C10.6193 20.5 9.5 19.3807 9.5 18ZM6.28288 2.41302C6.50342 2.24683 6.54748 1.93333 6.38129 1.71279C6.2151 1.49226 5.9016 1.4482 5.68106 1.61438C4.23289 2.70566 3.09201 4.15338 2.36953 5.81655C2.25951 6.06983 2.37564 6.36434 2.62892 6.47437C2.88219 6.58439 3.17671 6.46826 3.28673 6.21498C3.9404 4.71021 4.97262 3.40037 6.28288 2.41302Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
