import { Icon, IconProps } from "@chakra-ui/react";

export function VerticalScrollIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11943 2.88603C5.46686 2.63786 5.9212 2.5 6.4 2.5H17.6C18.0788 2.5 18.5331 2.63786 18.8806 2.88603C19.227 3.1335 19.5 3.52047 19.5 4V20C19.5 20.4795 19.227 20.8665 18.8806 21.114C18.5331 21.3621 18.0788 21.5 17.6 21.5H6.4C5.9212 21.5 5.46686 21.3621 5.11943 21.114C4.77296 20.8665 4.5 20.4795 4.5 20V4C4.5 3.52047 4.77296 3.13351 5.11943 2.88603ZM5.70067 3.69976C5.54044 3.81421 5.5 3.92724 5.5 4V20C5.5 20.0728 5.54044 20.1858 5.70067 20.3002C5.85994 20.414 6.1056 20.5 6.4 20.5H17.6C17.8944 20.5 18.1401 20.414 18.2993 20.3002C18.4596 20.1858 18.5 20.0728 18.5 20V4C18.5 3.92724 18.4596 3.81421 18.2993 3.69976C18.1401 3.586 17.8944 3.5 17.6 3.5H6.4C6.1056 3.5 5.85994 3.586 5.70067 3.69976ZM9.14645 13.1464C9.34171 12.9512 9.65829 12.9512 9.85355 13.1464L11.5 14.7929V8C11.5 7.72386 11.7239 7.5 12 7.5C12.2761 7.5 12.5 7.72386 12.5 8V14.7929L14.1464 13.1464C14.3417 12.9512 14.6583 12.9512 14.8536 13.1464C15.0488 13.3417 15.0488 13.6583 14.8536 13.8536L12.3542 16.3529C12.3518 16.3553 12.3494 16.3577 12.347 16.36C12.2571 16.4467 12.1348 16.5 12 16.5C11.9322 16.5 11.8676 16.4865 11.8086 16.4621C11.7505 16.438 11.696 16.4026 11.6486 16.3557M11.6462 16.3533L9.14645 13.8536C8.95118 13.6583 8.95118 13.3417 9.14645 13.1464"
        fill="currentColor"
      />
    </Icon>
  );
}
