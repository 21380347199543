export const onboarding = {
  continue: "Continue",
  back: "Back",
  finish: "Finish",
  skip: "Skip for now",
  description: "Select at least 3",
  comicTypes: {
    title: "Tell us what types of comics you are most interested in.",
  },
  comicGenres: {
    title: "Select genres to shape your custom recommendations.",
  },
  comicThemes: {
    title: "Select themes that interest you the most.",
  },
  followComics: {
    title: "Now let’s add some books to your library.",
  },
  followAritsts: {
    title: "Almost done! Let’s follow some creators and publishers.",
  },
  splash: {
    title: "Welcome to GlobalComix! Your comics journey is just beginning.",
    cta: "Start reading",
  },
};
