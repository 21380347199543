import { Icon, IconProps } from "@chakra-ui/react";

export function MediaArrowRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 4.29289C6.31658 3.90237 5.68342 3.90237 5.29289 4.29289C4.90237 4.68342 4.90237 5.31658 5.29289 5.70711L11.5858 12L5.29289 18.2929C4.90237 18.6834 4.90237 19.3166 5.29289 19.7071C5.68342 20.0976 6.31658 20.0976 6.70711 19.7071L13.7071 12.7071C14.0976 12.3166 14.0976 11.6834 13.7071 11.2929L6.70711 4.29289ZM19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5V19C17 19.5523 17.4477 20 18 20C18.5523 20 19 19.5523 19 19V5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
