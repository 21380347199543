import { Flex, Image, Text } from "@chakra-ui/react";
import { ContentSectionCustomCard } from "../../types";
import { PreloadLink } from "../navigation/PreloadLink";

interface StreamLinkCardProps {
  card: ContentSectionCustomCard;
  streamUrl?: string;
}

export function StreamLinkCard({ card, streamUrl }: StreamLinkCardProps) {
  return (
    <PreloadLink to={streamUrl ? streamUrl : ""}>
      <Flex
        width="100%"
        bgColor="charcoal.900"
        height="64px"
        justifyContent="space-between"
        borderRadius="8px"
      >
        <Text
          alignContent="center"
          paddingLeft="24px"
          color="neutral.100"
          fontSize="16px"
          fontWeight="bold"
          lineHeight="130%"
        >
          {card.display_name}
        </Text>
        <Image
          src={card.images[0]?.small_url}
          fit="fill"
          borderRadius="8px"
        ></Image>
      </Flex>
    </PreloadLink>
  );
}
