import { pxToNumber } from "../utils/displaySizeConversions";

const baseFontSize = pxToNumber("16px"); // 16px is current base font size

export const breakpoints = {
  base: "0em", // 0px
  xs: `${320 / baseFontSize}em`,
  sm: `${390 / baseFontSize}em`,
  md: `${744 / baseFontSize}em`,
  lg: `${1000 / baseFontSize}em`,
  xl: `${1280 / baseFontSize}em`,
  "2xl": `${1440 / baseFontSize}em`,
  "3xl": `${1920 / baseFontSize}em`,
  "4xl": `${3440 / baseFontSize}em`,
};
