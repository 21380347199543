import { Flex, Text } from "@chakra-ui/react";
import { ButtonCarousel } from "../../../components/carousel/ButtonCarousel";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import useResizeObserver from "@react-hook/resize-observer";
import { useState } from "react";
import React from "react";

interface Review {
  origin: "app_store" | "google_play";
  review: string;
  id: string;
}

export function ListOfReviews() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const [scrollByCount, setScrollByCount] = useState(1);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const reviews: Review[] = [
    {
      id: "1",
      origin: "app_store",
      review:
        '"I am thrilled that GlobalComix is here now and doing such an amazing job. Tell every comic reader you know about them so they\'ll be with us a few decades."',
    },
    {
      id: "2",
      origin: "google_play",
      review:
        '"What a great app! The UI is great and easy to use; comics load fast and the experience feels smooth (they also introduced offline reading a while ago which is great for people like me). The selection and quality of comics is astounding, and although most of us are going to be reading books from the big publishers (DC, Vertigo, Image, Boom, Dark Horse, etc) I really would urge people to explore the indies. GlobalComix have done a great job of exposing some great otherwise unheard of talent."',
    },
    {
      id: "4",
      origin: "google_play",
      review:
        '"The best comics subscription app out there. Constantly being updated with new comics from so many of the top publishers. AND NOW THEY EVEN HAVE DC!!!!"',
    },
    {
      id: "5",
      origin: "google_play",
      review:
        '"Why aren\'t more people aware of this!? This is an amazing service, I have now subscribed, and cannot recommend highly enough. I subscribe to the other "Big Two" comic services, and can say without doubt, this app offers the best reading experience, and the extensive library is mind blowing. I subscribed to read a couple of books I\'d seen, but just keep finding more and more to read. I tip my hat to the people behind this app, and say thank you."',
    },
    {
      id: "6",
      origin: "app_store",
      review:
        '"I absolutely love using this vertical scroll! The interface is sleek and intuitive, making it super easy to browse and read. The vertical scroll format is perfect for a smooth, uninterrupted reading experience, and the art quality really pops. I also appreciate the vast library of comics available, from popular series to hidden gems. Whether you\'re a casual reader or a comic enthusiast, this app makes reading comics on the go a joy. Highly recommend!"',
    },
    {
      id: "7",
      origin: "google_play",
      review:
        '"This is the digital comic book reader I\'ve always wanted. I am a collector. Now I can read my books without ever breaking the spine."',
    },
    {
      id: "8",
      origin: "app_store",
      review:
        '"Best app to read comics on. I also love all their exclusive content. This is by far and away the best comic reader."',
    },
    {
      id: "9",
      origin: "app_store",
      review:
        "\"Finally a place where good stories can live. Now that we can download and read online, this service is damn near perfect. No, it's not free, nor it should be, we want to support the artists and the industry, but it's got exceptional value. If you're a comic book fan you know what an expensive hobby this is. Now you can read until your eyes bleed and save $$ and space for those really unique titles you want to own. No Marvel or DC? No problem! You got the amazing catalogues of Image, Boom!, ONI, Vault, TKO, AWA and many others as well as Indy creators. Thanks, Global Comix for bringing them all together!\"",
    },
    {
      id: "10",
      origin: "app_store",
      review:
        '"For only $9.99 I\'ve now read over 7000 pages of image comics on my iPad, and binge scrolled through a few dozen webcomics. Fantastic value for the buck on a platform that seems to have hundreds of new release daily 🥰"',
    },
  ];

  useResizeObserver(containerRef, (entry) => {
    const amountOfSlider = Math.floor(entry.contentRect.width / 384);
    setScrollByCount(amountOfSlider === 0 ? 1 : amountOfSlider);
  });

  return (
    <Flex flexDirection="column" width="100%" ref={containerRef}>
      <Text
        textAlign={isSmallBreakpoint ? "center" : "left"}
        variant="goldTitle"
        paddingBottom="40px"
        sx={{
          textWrap: "balance",
        }}
      >
        Reviews by our readers
      </Text>
      <ButtonCarousel
        slidesToScroll={scrollByCount}
        width="100%"
        big={scrollByCount !== 1}
      >
        {reviews.map((review) => (
          <Flex
            key={review.id}
            flexDirection="column"
            marginRight="24px"
            maxWidth="360px"
            flexShrink={0}
          >
            <Text
              fontFamily="Bricolage Grotesque 18pt"
              fontWeight="400"
              fontSize="16.906px"
              fontStyle="normal"
              lineHeight="140%"
              letterSpacing="0.169px"
              color="#646464"
            >
              {review.origin === "app_store" ? "APP STORE" : "GOOGLE PLAY"}
            </Text>
            <Text
              fontFamily="Bricolage Grotesque 18pt"
              fontWeight="400"
              fontSize="16.906px"
              fontStyle="normal"
              lineHeight="140%"
              letterSpacing="0.169px"
              color="white.white"
            >
              {review.review}
            </Text>
          </Flex>
        ))}
      </ButtonCarousel>
    </Flex>
  );
}
