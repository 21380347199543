import { ChakraProvider, DarkMode } from "@chakra-ui/react";
import React from "react";
import { theme } from "./theme/theme.ts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";

type AppProvidersProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

export function AppProviders({ children }: AppProvidersProps) {
  return (
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <DarkMode>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </DarkMode>
      </ChakraProvider>
    </React.StrictMode>
  );
}
