import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { TextIcon } from "../../../../components/icons/TextIcon";
import { LanguageEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { browseFilterStore } from "./BrowseFilters";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const LANGUAGE_ALL = "0";

interface LanguageFacetGroupFilterProps {
  value: Immutable<string | undefined>;
  languages: LanguageEntity[];
  onChange: (nextValue: string) => void;
}
export function LanguageFacetGroupFilter({
  value,
  languages,
  onChange,
}: LanguageFacetGroupFilterProps) {
  const languageOptions: Option<string>[] = useMemo(() => {
    let options = languages.map((type) => ({
      label: type.name,
      value: "" + type.id,
    }));

    return [
      { label: "All", value: LANGUAGE_ALL },
      ...options.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, []);

  return (
    <RadioFilter value={value} options={languageOptions} onChange={onChange} />
  );
}

interface LanguageFacetGroupFilterTitleProps {
  isTrigger: boolean;
  languages: LanguageEntity[];
  open?: boolean;
}
export const LanguageFacetGroupFilterTitle = forwardRef(
  (
    {
      isTrigger,
      languages,
      open,
      ...props
    }: LanguageFacetGroupFilterTitleProps,
    ref,
  ) => {
    const languageState = browseFilterStore.languageFacets.get();
    const language = languages.find(
      // Hookstate is doing some fuzzy matching for us
      (language) => `${language.id}` == (languageState as unknown as string),
    );
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<TextIcon />}
          popoverRef={ref}
          text="Language"
          isFiltering={language?.name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle text="Language" filterValue={language?.name} open={open} />
    );
  },
);
