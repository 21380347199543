import {
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  ButtonProps,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useMemo, forwardRef } from "react";
import { appStore } from "../../appStore";
import { InitResponsePayloadResults } from "../../services/gc/types";
import { usePopoverOpenState } from "../../utils/usePopoverOpenState";
import { RadioFilter } from "../filters/RadioFilter";
import { ClockIcon } from "../icons/ClockIcon";
import { SortMenusProps } from "./SortMenus";

export const DEFAULT_SORT_DURATION_TYPE = "30";
export function SortDurationMenu({ invalidate, filterState }: SortMenusProps) {
  const { ref, onToggle, isOpen, onClose } = usePopoverOpenState();
  const state = useHookstate(appStore);
  const { comic_sort_durations: sortDurations } =
    state.init.get() as InitResponsePayloadResults;
  const value = useHookstate(filterState.sortMenus.sortDuration).get();

  const options = useMemo(
    () =>
      sortDurations
        ? sortDurations.map(({ id, name }) => {
            return {
              value: "" + id,
              label: name,
            };
          })
        : [],
    [sortDurations],
  );
  const sortOrderValue = useHookstate(filterState.sortMenus.sortOrder).get();
  const enabled =
    sortOrderValue && ["featured", "popular", "views"].includes(sortOrderValue);

  const activeOption = options.filter((option) => option.value == value);
  return (
    <Popover variant="filterSmall" gutter={4} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <SortDurationTrigger
          isDisabled={!enabled}
          isActive={isOpen}
          style={{ pointerEvents: isOpen ? "none" : "auto" }}
          value={activeOption.length ? activeOption[0].label : "Last 30 Days"}
          onMouseUp={onToggle}
        />
      </PopoverTrigger>
      <Portal>
        <Box position="relative" zIndex="1402">
          <PopoverContent
            ref={ref}
            variants={{
              enter: { opacity: 1, top: 0 },
              exit: { opacity: 0, top: -20 },
            }}
          >
            <PopoverBody>
              <RadioFilter
                value={value}
                options={options}
                onChange={async (newOrder) => {
                  await filterState.sortMenus.sortDuration.set(newOrder);
                  onClose();
                  invalidate();
                }}
                useCheck
              />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

interface SortDurationTriggerProps extends ButtonProps {}
const SortDurationTrigger = forwardRef<
  HTMLButtonElement,
  SortDurationTriggerProps
>(({ ...props }, ref) => {
  return (
    <Button
      variant="sortMenuAlt"
      size="md"
      height="36px"
      ref={ref}
      isActive={props.isActive}
      gap="8px"
      {...props}
    >
      <Text variant="filterTag">{props.value}</Text>
      <ClockIcon w={4.5} h={4.5} color="dune.100" />
    </Button>
  );
});
