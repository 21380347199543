import { StripePaymentConfigTypes } from "./../../../components/payments/components/StripePayment";
import { apiClient } from "../../../services/axiosInstance";
import {
  Comic,
  Fragment,
  ReactionSummaryEntity,
  Release,
  ReleaseCommentEntity,
  ServerListResponse,
  ServerSingleResponse,
} from "../../../types";
import {
  AccessData,
  ReaderNavigationContext,
  ToggleReactionPayload,
  TrackViewPayload,
} from "../types";

export const GET_RELEASE_FRAGMENTS_QUERY_KEY = ["get-release-fragments"];
export function getReleaseFragments(releaseId: number) {
  return apiClient.get<ServerListResponse<Fragment>>(
    `/v1/releases/${releaseId}/fragments`,
  );
}

export const GET_COMIC_QUERY_KEY = ["get-comic"];
export function getComic(comicSlug: string) {
  return apiClient.get<ServerSingleResponse<Comic>>(`/v1/read/${comicSlug}`);
}

export const GET_RELEASE_QUERY_KEY = ["get-release"];

export function getRelease(
  uuid: string,
  ReaderNavigationContext?: ReaderNavigationContext,
) {
  const contextQueryParams = new URLSearchParams();
  if (ReaderNavigationContext) {
    Object.entries(ReaderNavigationContext).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        contextQueryParams.append(
          `context_${key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)}`,
          value.toString(),
        );
      }
    });
  }

  return apiClient.get<ServerSingleResponse<Release>>(
    `/v1/readV2/${uuid}?${contextQueryParams}`,
  );
}

export const GET_COMIC_RELEASES_QUERY_KEY = ["get-comic-releases"];
export function getComicReleases(comicId: number, langId?: string) {
  return apiClient.get<ServerListResponse<Release>>(
    `/v1/comics/${comicId}/releases?lang_id=${langId ?? ""}&all=true`,
  );
}

export function getSimilarComicReleases(comicId: number) {
  return apiClient.get<ServerListResponse<Comic>>(
    `/v1/comics/${comicId}/similar`,
  );
}

export const GET_RELEASE_COMMMENTS = ["get-release-comments"];
export function getReleaseComments(releaseId: number) {
  return apiClient.get<ServerListResponse<ReleaseCommentEntity>>(
    `/v1/releases/${releaseId}/comments`,
  );
}

export function postTrackPageView(
  releaseId: number,
  payload: TrackViewPayload,
) {
  return apiClient.post<ServerSingleResponse<AccessData>>(
    `/v1/releases/${releaseId}/track-view`,
    { meta: [], payload },
  );
}

export function postToggleReaction(
  releaseId: number,
  payload: ToggleReactionPayload,
) {
  return apiClient.post<ServerSingleResponse<ReactionSummaryEntity>>(
    `/v1/releases/${releaseId}/toggle-reaction`,
    { meta: [], payload },
  );
}

type ReleaseCommentPayload = {
  body: string;
  parent_id?: number;
};
export function postReleaseComment(
  releaseId: number,
  payload: ReleaseCommentPayload,
) {
  return apiClient.post<ServerSingleResponse<ReleaseCommentEntity>>(
    `/v1/releases/${releaseId}/post-comment`,
    { meta: [], payload },
  );
}

type PurchaseReleasePDFPayload = StripePaymentConfigTypes & {
  netAmount: number;
};
export function postReleasePDFPurchase(
  releaseId: number,
  payload: PurchaseReleasePDFPayload,
) {
  return apiClient.post<ServerSingleResponse<any>>(
    `/v1/releases/${releaseId}/purchase`,
    { meta: [], payload },
  );
}
