import { Icon, IconProps } from "@chakra-ui/react";

export function WarningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 19 19" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13634 2.6625C8.74255 1.6125 10.2581 1.6125 10.8643 2.6625L17.6843 14.475C18.2905 15.525 17.5327 16.8375 16.3203 16.8375H2.68038C1.46794 16.8375 0.71017 15.525 1.31639 14.475L8.13634 2.6625ZM9.50033 3.45L2.68038 15.2625H16.3203L9.50033 3.45ZM8.67346 12.9003C8.67346 12.4654 9.02604 12.1128 9.46096 12.1128H9.53971C9.97464 12.1128 10.3272 12.4654 10.3272 12.9003V12.979C10.3272 13.414 9.97464 13.7665 9.53971 13.7665H9.46096C9.02604 13.7665 8.67346 13.414 8.67346 12.979V12.9003ZM10.2876 7.38809C10.2876 6.95316 9.93507 6.60059 9.50015 6.60059C9.06522 6.60059 8.71265 6.95316 8.71265 7.38809V10.5381C8.71265 10.973 9.06522 11.3256 9.50015 11.3256C9.93507 11.3256 10.2876 10.973 10.2876 10.5381V7.38809Z"
        fill="currentColor"
      />
    </Icon>
  );
}
