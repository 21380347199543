import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

const OBSERVER_THRESHOLDS = [0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 1];
export function useReaderIntersectionObserver() {
  const { pageOrder } = useParams();
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const lastObservedPage = useRef<number>();
  const observerRef = useRef(null);

  const navigate = useNavigate();
  const observerCallback: IntersectionObserverCallback = (entries) => {
    const entry = getProminentEntry(entries);
    if (!entry) return;

    const { intersectionRatio, rootBounds, boundingClientRect } = entry;
    if (!rootBounds || !boundingClientRect) return;
    if (
      intersectionRatio >= calcMinThreshold(rootBounds, boundingClientRect) &&
      pageOrder !== entry.target.id
    ) {
      const index = entry.target.id.split("-")[2];
      if (lastObservedPage.current !== parseInt(index)) {
        const pathArr = window.location.pathname.split("/");
        pathArr[3] = (parseInt(index) + 1).toString();
        navigate(pathArr.join("/"), { replace: true });
        lastObservedPage.current = parseInt(index);
      }
    }
  };

  useEffect(() => {
    const newObserver = new IntersectionObserver(observerCallback, {
      threshold: OBSERVER_THRESHOLDS,
    });
    if (observerRef.current) setObserver(newObserver);
    return () => {
      if (observerRef.current) {
        if (observer) newObserver.disconnect();
        setObserver(null);
      }
    };
  }, [observerRef]);

  return { observerRef, observer, lastObservedPage };
}

function calcMinThreshold(rootBounds: DOMRect, pageBounds: DOMRect) {
  return Math.min(1, (rootBounds.height / pageBounds.height) * 0.51);
}
function getProminentEntry(entries: IntersectionObserverEntry[]) {
  return entries.reduce(
    (acc: IntersectionObserverEntry, cur: IntersectionObserverEntry) => {
      if (!cur.isIntersecting) {
        return acc;
      }
      if (!acc.boundingClientRect) {
        return cur;
      }
      if (cur.intersectionRatio > acc.intersectionRatio) {
        return cur;
      }
      return acc;
    },
    {} as IntersectionObserverEntry,
  );
}
