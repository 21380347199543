import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { AccessData } from "../../types";

export function useAccessData() {
  const accessData = useHookstate(readerDataStore.accessData).get({
    noproxy: true,
  });

  const setAccessData = (data: AccessData) => {
    readerDataStore.accessData.set(data);
  };

  return { accessData, setAccessData };
}
