import { Icon, IconProps } from "@chakra-ui/react";

export function AttentionIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM11.4502 15.45C11.4502 15.1738 11.6741 14.95 11.9502 14.95H12.0502C12.3263 14.95 12.5502 15.1738 12.5502 15.45V15.55C12.5502 15.8261 12.3263 16.05 12.0502 16.05H11.9502C11.6741 16.05 11.4502 15.8261 11.4502 15.55V15.45ZM12.5 8.44995C12.5 8.17381 12.2761 7.94995 12 7.94995C11.7239 7.94995 11.5 8.17381 11.5 8.44995V12.45C11.5 12.7261 11.7239 12.95 12 12.95C12.2761 12.95 12.5 12.7261 12.5 12.45V8.44995Z"
        fill="#E25454"
      />
    </Icon>
  );
}
