import { ImmutableObject } from "@hookstate/core";
import { Release } from "../../../types";
import { t } from "i18next";
import { useMemo } from "react";

export function useReleaseTags(
  releaseData: ImmutableObject<Release> | undefined,
) {
  return useMemo(() => {
    return [
      releaseData?.comic.crowdfunding_campaign
        ? {
            text: t("screens.reader.overlay.crowdfundingNow"),
            borderColor: "success.500",
          }
        : undefined,
      releaseData?.is_augmented
        ? {
            text: t("screens.reader.overlay.panelToPanel"),
            borderColor: "blaze.blaze",
          }
        : undefined,
      { text: releaseData?.comic.type_display },
      {
        text: releaseData?.is_vertical_format
          ? t("screens.reader.overlay.vertical")
          : t("screens.reader.overlay.traditional"),
      },
      { text: releaseData?.comic.category_name },
      releaseData?.is_free
        ? { text: t("screens.reader.overlay.free") }
        : undefined,
    ];
  }, [releaseData]);
}
