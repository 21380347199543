import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { PAGE_LAYOUT_TYPES } from "../constants";
import { buildReaderUrl } from "../../../utils/buildReaderUrl";
import { usePageLayout } from "./usePageLayout";
import { useP2PSettings } from "./useP2PSettings";
import { useReleasePagesData } from "./hookstate/useReleasePagesData";
import { useReleaseFragmentsData } from "./hookstate/useReleaseFragmentsData";
import { useEndInterstitial } from "./useEndInterstital";

export const useReaderNavigation = () => {
  const navigate = useNavigate();
  const { pageOrder, uuid } = useParams();
  const { pageLayout } = usePageLayout();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { showEndInterstitial, setShowEndInterstitial } = useEndInterstitial();
  const { releasePagesData } = useReleasePagesData();
  const { releaseFragmentsByPage } = useReleaseFragmentsData();
  const pageOrderNum = parseInt(pageOrder ?? "");

  useEffect(() => {
    if (releasePagesData?.length && releasePagesData.length < pageOrderNum) {
      goToPage(1, true);
    }
  }, [pageOrderNum, releasePagesData]);

  const pageDelta = useMemo(() => {
    return !p2pEnabledAndAugmented && pageLayout === PAGE_LAYOUT_TYPES.double
      ? 2
      : 1;
  }, [p2pEnabledAndAugmented, pageLayout]);

  const onLastPage = useMemo(() => {
    if (releasePagesData?.length) {
      const releasePageData = releasePagesData[pageOrderNum - 1];
      if (
        pageLayout === PAGE_LAYOUT_TYPES.double &&
        releasePageData?.is_double_with_next
      ) {
        return pageOrderNum >= releasePagesData.length - 1;
      }
      return pageOrderNum === releasePagesData.length;
    }
  }, [pageOrder, pageLayout, releasePagesData]);

  const onFirstPage = useMemo(() => {
    return pageOrderNum === 1;
  }, [pageOrder]);

  const location = useLocation();

  const goToPage = useCallback(
    (newPageOrder: number, toBeginning: boolean = true, toEnd = false) => {
      let newPage = newPageOrder;
      let newFragment;
      if (p2pEnabledAndAugmented) {
        if (toBeginning) {
          newFragment = 1;
        } else if (toEnd && releaseFragmentsByPage && releasePagesData) {
          newFragment = Math.max(
            releaseFragmentsByPage[releasePagesData[newPageOrder - 1].page_id]
              .fragments.length,
            1,
          );
        }
      }
      const ctaUrl = buildReaderUrl(
        location.pathname,
        uuid,
        newPage,
        newFragment,
      );

      navigate(ctaUrl, {
        replace: true,
      });
    },
    [location, releaseFragmentsByPage, releasePagesData],
  );

  const goToPrevPage = useCallback(
    (toEnd: boolean = false) => {
      if (showEndInterstitial) {
        setShowEndInterstitial(false);
      } else {
        const releasePageData = releasePagesData?.[pageOrderNum - 2];
        const newPageOrder =
          pageOrderNum - (releasePageData?.is_double_with_prev ? pageDelta : 1);
        if (newPageOrder > 0) {
          goToPage(newPageOrder, false, toEnd);
        }
      }
    },
    [
      p2pEnabledAndAugmented,
      releasePagesData,
      releaseFragmentsByPage,
      showEndInterstitial,
      pageOrderNum,
      pageDelta,
    ],
  );

  const goToNextPage = useCallback(
    (toBeginning: boolean = true) => {
      const releasePageData = releasePagesData?.[pageOrderNum - 1];
      const newPageOrder =
        pageOrderNum + (releasePageData?.is_double_with_next ? pageDelta : 1);
      if (releasePagesData && newPageOrder <= releasePagesData?.length) {
        goToPage(newPageOrder, toBeginning);
      } else {
        setShowEndInterstitial(true);
      }
    },
    [
      p2pEnabledAndAugmented,
      pageOrder,
      releasePagesData,
      releaseFragmentsByPage,
      pageDelta,
    ],
  );

  const releaseProgress = useMemo(() => {
    if (pageLayout === PAGE_LAYOUT_TYPES.double) {
      const releasePageData = releasePagesData?.[pageOrderNum - 1];
      return (
        (pageOrderNum -
          1 +
          (releasePageData?.is_double_with_next ? pageDelta : 1)) /
        (releasePagesData?.length ?? 1)
      );
    }
    return pageOrderNum / (releasePagesData?.length ?? 1);
  }, [pageLayout, pageOrder, releasePagesData]);

  return {
    activePage: pageOrderNum - 1,
    pageId: releasePagesData?.[pageOrderNum - 1]?.page_id,
    pageCount: releasePagesData?.length,
    showEndInterstitial,
    setShowEndInterstitial,
    releaseProgress,
    onFirstPage,
    onLastPage,
    goToPage,
    goToPrevPage,
    goToNextPage,
    releasePagesData,
  };
};
