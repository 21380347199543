import { Icon, IconProps } from "@chakra-ui/react";

export function VerticalLayoutIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 40 94" {...props}>
      <path
        d="M39.5603 84.2121C39.4204 83.8922 39.2404 83.5822 39.0405 83.3023C38.8405 83.0224 38.6106 82.7624 38.3706 82.5225C38.1207 82.2825 37.8508 82.0626 37.5508 81.8726C37.2609 81.6826 36.951 81.5227 36.6211 81.3927C36.2911 81.2627 35.9412 81.1528 35.6013 81.0928C35.2414 81.0228 34.8715 80.9828 34.5116 80.9828H5.50866C5.13875 80.9828 4.77883 81.0228 4.41892 81.0928C4.06901 81.1628 3.71909 81.2627 3.38917 81.4027C3.05925 81.5327 2.74933 81.6926 2.4594 81.8826C2.16947 82.0726 1.88954 82.2925 1.6496 82.5225C1.39966 82.7624 1.16971 83.0324 0.969763 83.3123C0.769812 83.6022 0.589856 83.9022 0.44989 84.2221C0.309924 84.552 0.199951 84.892 0.119971 85.2319C0.0399902 85.5818 0 85.9517 0 86.3216V92.4903C0 93.3202 0.669837 93.99 1.49963 93.99C2.32943 93.99 2.99927 93.3202 2.99927 92.4903V86.3216C2.99927 86.1617 3.01926 86.0117 3.04926 85.8617C3.07925 85.7218 3.12924 85.5818 3.18922 85.4418C3.24921 85.3019 3.31919 85.1719 3.40917 85.0519C3.49915 84.9319 3.59912 84.812 3.70909 84.702C3.81907 84.592 3.93904 84.502 4.079 84.4121C4.20897 84.3221 4.35894 84.2521 4.5089 84.1921C4.65886 84.1321 4.81882 84.0821 4.97878 84.0521C5.14874 84.0221 5.3187 84.0021 5.49866 84.0021H34.4916C34.6615 84.0021 34.8415 84.0221 35.0115 84.0521C35.1714 84.0821 35.3314 84.1321 35.4813 84.1921C35.6313 84.2521 35.7813 84.3321 35.9112 84.4121C36.0412 84.502 36.1712 84.592 36.2811 84.702C36.3911 84.812 36.4911 84.9219 36.5811 85.0519C36.6611 85.1719 36.741 85.3019 36.801 85.4418C36.861 85.5718 36.911 85.7118 36.941 85.8717C36.971 86.0217 36.991 86.1717 36.991 86.3316V92.5003C36.991 93.3302 37.6608 94 38.4906 94C39.3204 94 39.9902 93.3302 39.9902 92.5003V86.3316C39.9902 85.9617 39.9502 85.6018 39.8803 85.2519C39.8103 84.902 39.7003 84.562 39.5503 84.2321L39.5603 84.2121Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M34.5013 17.9962H5.50842C2.47916 17.9962 0.00976562 20.4657 0.00976562 23.495V70.485C0.00976562 73.5144 2.47916 75.9839 5.50842 75.9839H34.5013C37.5306 75.9839 40 73.5144 40 70.485V23.495C40 20.4657 37.5306 17.9962 34.5013 17.9962ZM37.0007 70.485C37.0007 71.8647 35.881 72.9845 34.5013 72.9845H5.50842C4.12876 72.9845 3.00903 71.8647 3.00903 70.485V23.495C3.00903 22.1153 4.12876 20.9956 5.50842 20.9956H34.5013C35.881 20.9956 37.0007 22.1153 37.0007 23.495V70.485Z"
        fill="#B8BCC6"
      />
      <path
        d="M38.5004 0C37.6706 0 37.0007 0.669857 37.0007 1.49968V7.66837C37.0007 7.81833 36.9807 7.9783 36.9507 8.12827C36.9208 8.27824 36.8708 8.41821 36.8108 8.54818C36.7508 8.68815 36.6708 8.81812 36.5908 8.9381C36.5009 9.06807 36.4009 9.18804 36.2909 9.28802C36.1809 9.398 36.051 9.49798 35.921 9.57796C35.791 9.66794 35.6411 9.73793 35.4911 9.79791C35.3411 9.8579 35.1712 9.90789 35.0112 9.93788C34.8413 9.96788 34.6713 9.98787 34.4914 9.98787H5.50842C5.32847 9.98787 5.15851 9.96788 4.99855 9.93788C4.82859 9.90789 4.66863 9.8579 4.51867 9.79791C4.3687 9.73793 4.22874 9.66794 4.09877 9.57796C3.9688 9.48798 3.83883 9.398 3.72886 9.27802C3.61888 9.17804 3.51891 9.05807 3.42893 8.9381C3.33895 8.80812 3.26897 8.67815 3.20898 8.54818C3.149 8.40821 3.09901 8.26824 3.06902 8.11827C3.03903 7.9683 3.01903 7.81833 3.01903 7.65837V1.49968C3.01903 0.669857 2.34919 0 1.5194 0C0.6896 0 0.00976562 0.669857 0.00976562 1.49968V7.66837C0.00976562 8.03829 0.0497559 8.39821 0.119739 8.74814C0.189722 9.08806 0.299695 9.43799 0.449658 9.76792C0.589624 10.0779 0.759583 10.3878 0.969531 10.6777C1.16948 10.9677 1.39943 11.2276 1.64937 11.4676C1.8993 11.7075 2.16924 11.9175 2.45917 12.1074C2.7491 12.2974 3.06902 12.4573 3.38894 12.5873C3.71886 12.7173 4.05878 12.8273 4.41869 12.8973C4.7786 12.9672 5.13851 13.0072 5.50842 13.0072H34.5013C34.8713 13.0072 35.2412 12.9672 35.6011 12.8973C35.951 12.8273 36.2909 12.7273 36.6208 12.5973C36.9507 12.4673 37.2607 12.3074 37.5506 12.1174C37.8405 11.9275 38.1105 11.7175 38.3604 11.4776C38.6103 11.2376 38.8303 10.9777 39.0402 10.6877C39.2402 10.3978 39.4101 10.0978 39.5501 9.78792C39.6901 9.46798 39.8001 9.11806 39.88 8.77813C39.96 8.41821 40 8.05828 40 7.68836V1.49968C40 0.669857 39.3302 0 38.5004 0Z"
        fill="white"
        fillOpacity="0.2"
      />
    </Icon>
  );
}
