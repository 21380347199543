import { Box, Flex, Text } from "@chakra-ui/react";

export interface DividerWithTextProps {
  text: string;
}

export function DividerWithText({ text }: DividerWithTextProps) {
  return (
    <Flex flexDirection="row" gap="16px" alignItems="center">
      <Box width="100%" height="1px" bg="transparent.white.10" />
      <Text color="dune.600" whiteSpace="nowrap" fontSize="12px">
        {text}
      </Text>
      <Box width="100%" height="1px" bg="transparent.white.10" />
    </Flex>
  );
}
