import { Box, Image } from "@chakra-ui/react";
import { appStore } from "../../../appStore";
import { useHookstate } from "@hookstate/core";

interface CountryFlagProps {
  countryId: string;
  small?: boolean;
}

export function CountryFlag({ small, countryId }: CountryFlagProps) {
  const flagUrl = useHookstate(appStore.countryFlagMap[countryId]).get();

  return (
    <Box
      width={small ? ".75rem" : ".875rem"}
      height={small ? ".75rem" : ".875rem"}
      borderRadius="50%"
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        src={flagUrl}
        alt={`${countryId} country flag`}
        width="100%"
        height="100%"
        objectFit="cover"
      />
    </Box>
  );
}
