import { Box, ModalBody } from "@chakra-ui/react";
import { ReadingStatusPopoverContent } from "./ReadingStatusPopoverContent";
import { FunnelComponentProps } from "../../funnel/types";
import { Comic } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import { ComicThumbnail } from "../../comics/ComicThumbnail";
import { UseFunnelOptionsTypes } from "../../funnel/hooks/useFunnel";

export function ReadingStatusModalBody({
  onClose,
  cacheKey,
}: FunnelComponentProps & { cacheKey: UseFunnelOptionsTypes["cacheKey"] }) {
  const { data } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const libraryStatus = data?.library_status;

  if (!data) {
    return null;
  }

  return (
    <ModalBody px="0">
      <Box width="100%" py="16px" px="24px">
        <ComicThumbnail comicData={data} />
      </Box>

      <Box width="100%" height="1px" background="transparent.white.10" />

      <ReadingStatusPopoverContent
        onClose={onClose}
        data={data}
        inLibrary={!!libraryStatus}
        isModal
      />
    </ModalBody>
  );
}
