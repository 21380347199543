export type AccessData = {
  can_read: boolean;
  display_paid_access_block: boolean;
  requires_paid: boolean;
  is_early_access: boolean;
  last_view_id: number | null;
  is_geo_restricted: boolean;
};

export type TrackViewPayload = {
  viewer_mode: string;
  page_id: number;
  double_page_id?: number;
  last_view_id?: number;
  fragment_id?: number;
};

export type ToggleReactionPayload = {
  reaction_type_id: number;
};

export enum NavigationTrigger {
  /// The user tapped on the next release button of the reader
  nextReleaseButton = "next_release_button",

  /// The user tapped on the previous release button of the reader
  previousReleaseButton = "previous_release_button",

  /// The user tapped on a card of the releases list of the comic details
  releasesListCard = "releases_list_card",

  /// The user tapped on a card of the related comics list of
  /// the comic details or interstitial
  relatedListCard = "related_list_card",

  /// The user tapped on the start next release button of interstitial
  startNextReleaseButton = "start_next_release_button",

  /// The user tapped on the start reading button of the comic details
  startReadingButton = "start_reading_button",

  /// The user tapped on the start reading button on the release details
  startReadingButtonReleaseDetails = "start_reading_button_release_details",

  /// The user tapped on the start reading button of the contextual menu of the release
  startReadingButtonReleaseContextual = "start_reading_button_release_contextual",

  /// The user tapped on the continue reading button of the comic details
  continueReadingButton = "continue_reading_button",

  /// The user tapped on the continue reading button on the release details
  continueReadingButtonReleaseDetails = "continue_reading_button_release_details",

  /// The user tapped on the continue reading button of the contextual menu of the release
  continueReadingButtonReleaseContextual = "continue_reading_button_release_contextual",

  /// The user tapped on the preview release button on the
  previewReleaseButtonReleaseDetails = "preview_release_button_release_details",

  /// The user tapped on the preview release button of the contextual menu of the release
  previewReleaseButtonReleaseContextual = "preview_release_button_release_contextual",

  /// The user tapped on the preview release button of the release
  previewReleaseButtonRelease = "preview_release_button_release",

  /// The user tapped on a page thumbnail card in release details
  pageThumbnailCard = "page_thumbnail_card",
}

export type ReaderNavigationContext = {
  /// The name of the area where the read comic action took place
  area?: string;

  /// The position of the card where the read comic action took place
  cardPosition?: number;

  /// The identifier of the comic where the app is focussing at the moment
  /// of navigation (For example: The comic identifier of the one loaded
  /// in the comic details)
  comicId?: number;

  /// The notification type id where the read comic action took place
  notificationTypeId?: number;

  /// The position of the related card where the read comic action took
  /// place
  relatedCardPosition?: number;

  /// The search query where the read comic action took place
  q?: string;

  /// The feed where the read comic action took place
  stream?: string;

  /// The section id where the read comic action took place
  sectionId?: number;

  /// The position of the section where the read comic action took place
  sectionPosition?: number;

  /// The type of the section where the read comic action took place
  sectionType?: string;

  /// The trigger where the read comic action took place
  trigger?: NavigationTrigger;
};
