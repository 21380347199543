import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Comic, LibraryStatusEntity } from "../../../types";
import { useToast } from "@chakra-ui/react";
import {
  ComicLibraryPayload,
  postAddToLibrary,
  postRemoveFromLibrary,
} from "../../../api/comics";
import { useCallback } from "react";
import { useUserData } from "../../../hooks/useUserData";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea } from "../../funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { useAccountData } from "../../../hooks/useAccountData";
import { GET_ACCOUNT_ME_QUERY_KEY } from "../../auth/api/authApi";

export function useToggleComicFollow(
  cacheKey: string,
  isHero?: boolean,
  onUpdate?: () => void,
  onRemove?: () => void,
) {
  const { userData } = useUserData();
  const toast = useToast();
  const queryClient = useQueryClient();

  const accountData = useAccountData();
  const { data } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
  });

  const followPayload = {
    comic_user_reading_status_id: 1,
    disable_notifications: false,
    disable_emails: false,
  };

  const updateComicFavorites = useCallback(
    async (comicFavorites: number[]) => {
      await queryClient.setQueryData([GET_ACCOUNT_ME_QUERY_KEY], () => {
        return {
          data: {
            payload: {
              results: {
                ...accountData,
                comic_favorites: comicFavorites,
              },
            },
          },
        };
      });
    },
    [userData],
  );

  const { mutate: unfollow } = useMutation({
    mutationFn: () => {
      return postRemoveFromLibrary(data?.id ?? 0);
    },
    onMutate: async () => {
      await updateComicFavorites(
        (accountData?.comic_favorites ?? []).filter((id) => id !== data?.id),
      );
      await queryClient.setQueryData([cacheKey], {
        ...data,
        is_favorite: false,
        library_status: null,
      });
      toast({
        status: "success",
        title: "Library Status Updated",
      });
    },
    onError: () => {
      toast({
        status: "error",
        title: "Unable to unfollow comic",
      });
    },
    onSuccess: () => {
      onRemove?.();
    },
  });

  const { mutate: follow } = useMutation({
    mutationFn: (newStatus?: ComicLibraryPayload) => {
      return postAddToLibrary(data?.id ?? 0, newStatus ?? followPayload);
    },
    onMutate: async (newStatus?: ComicLibraryPayload) => {
      const libraryStatus: LibraryStatusEntity = {
        entity_type: "ComicFavorite",
        comic_id: data?.id ?? 0,
        user_id: userData?.id ?? 0,
        comic_user_reading_status_id:
          newStatus?.comic_user_reading_status_id ?? 1,
        disable_notifications: newStatus?.disable_notifications ? 1 : 0,
        disable_emails: newStatus?.disable_emails ? 1 : 0,
      };
      if (data?.id) {
        await updateComicFavorites([
          ...(accountData?.comic_favorites ?? []),
          data.id,
        ]);
      }
      await queryClient.setQueryData([cacheKey], {
        ...data,
        is_favorite: true,
        library_status: libraryStatus,
      });
      toast({
        status: "success",
        title: isHero ? "You will be notified!" : "Added to Library",
      });
    },
    onError: () => {
      toast({
        status: "error",
        title: "Unable to follow comic",
      });
    },
    onSuccess: () => {
      onUpdate?.();
    },
  });

  const toggleFollow = (newStatus?: ComicLibraryPayload) => {
    if (data?.library_status) unfollow();
    else follow(newStatus);
  };

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.addToLibrary,
    comic: data,
  });
  const handleToggleFollow = useCallback(
    (newStatus?: ComicLibraryPayload) => {
      if (!userData) {
        setFunnelModalConfig({
          options: {
            onAuthenticated: () => toggleFollow(newStatus),
            navigationContext,
            modalContext: FunnelContextArea.addToLibrary,
          },
          type: FUNNEL_TYPES.signup,
        });
      } else {
        toggleFollow(newStatus);
      }
    },
    [data],
  );

  return { follow, unfollow, handleToggleFollow };
}
