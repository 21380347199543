import { Flex } from "@chakra-ui/react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useAccountData } from "../../hooks/useAccountData";
import { GCChannel } from "../../types";
import { GET_SECTIONS_QUERY_KEY, getSection } from "./api/rootScreenApi";
import { useLocation, useNavigate, useParams } from "react-router";
import { ExpandedContentSection } from "../../components/cards/ExpandedContentSection";
import { HOME_CHANNEL_ID, ORIGINALS_CHANNEL_ID } from "./constants";
import { HeaderNavigationBar } from "./components/HeaderNavigationBar";
import { useScrollVisibility } from "../../hooks/useScrollVisibility";
import { useTitleOnNavigation } from "../../hooks/useTitleOnNavigation";
import { t } from "i18next";
import { EmailVerificationBanner } from "./components/EmailVerificationBanner";
import { useUserData } from "../../hooks/useUserData";
import { useEntityCache } from "../../hooks/useEntityCache";
import { CMSFeed } from "./components/CMSFeed";

function RootScreen() {
  const [viewAllSection, setViewAllSection] = useState<string>();
  const location = useLocation();
  const { channel, slug } = useParams();
  const navigate = useNavigate();
  const { saveEntityToCache } = useEntityCache();
  const accountData = useAccountData();
  const { userData } = useUserData();

  const queryClient = useQueryClient();
  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: [GET_SECTIONS_QUERY_KEY] });
    };
  }, []);

  const { data: viewAllSectionData } = useQuery({
    enabled: !!viewAllSection,
    queryKey: [viewAllSection],
    queryFn: async () => {
      if (!viewAllSection) return null;
      const response = await getSection(viewAllSection);
      const results = response?.data.payload.results;
      if (results) {
        [
          ...(results?.articles ?? []),
          ...(results?.artists ?? []),
          ...(results?.comics ?? []),
          ...(results?.releases ?? []),
        ].forEach(saveEntityToCache);
      }
      return response;
    },
  });

  const homeTabChannels = accountData?.home_tab_channels ?? [];

  const homeChannel = useMemo(() => {
    return homeTabChannels.find((c) => c.is_primary);
  }, [accountData]);

  const [activeChannel, setActiveChannel] = useState<GCChannel>();
  const [activeStreamId, setActiveStreamId] = useState<string>();

  const activeStream = activeChannel?.streams.find((stream) => {
    return stream.id === activeStreamId;
  });

  const isOriginalsChannel = channel === "originals";
  const isGcChannel = activeChannel?.id === HOME_CHANNEL_ID;
  const originalChannel = accountData?.originals_channel;

  var pageTitle = undefined;
  if (!isGcChannel && !isOriginalsChannel) {
    if (activeStream?.display_name) {
      pageTitle = `${activeStream?.display_name} ${t("screens.root.channelsAtGlobalComix")}`;
    }
  } else {
    pageTitle = t("screens.root.readAndPublishComicsOnline");
  }

  useTitleOnNavigation(pageTitle);

  // Maps slugs to their channels
  const slugsMap = useMemo(() => {
    if (accountData) {
      const map: Record<string, GCChannel> = {};
      homeTabChannels.forEach((channel) => {
        channel.streams.forEach((s) => {
          map[s.slug] = channel;
        });
      });
      return map;
    }
    return undefined;
  }, [accountData]);

  /// User navigates to a channel, we need to set the active channel to the one they navigated to
  useEffect(() => {
    if (!slugsMap) return;

    let channelExists = false;
    if (!channel && !slug) {
      if (homeChannel) findActiveStream(homeChannel);
      return;
    }

    if (isOriginalsChannel && originalChannel) {
      findActiveStream(originalChannel);
    } else {
      if (channel) {
        channelExists = !!slugsMap[channel];
      }
      const newActiveChannel = channel
        ? (slugsMap[`${channel}/${slug}`] ?? slugsMap[channel])
        : undefined;

      if (newActiveChannel) {
        findActiveStream(newActiveChannel);
      } else {
        if (channel === "cms" && slug) {
          setViewAllSection(slug);
          return;
        }
        if (homeChannel) findActiveStream(homeChannel);
        if (channelExists) {
          navigate(`/channel/${channel}`, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    }
  }, [location, slugsMap, homeChannel]);

  /// Whenever the active channel changes, we want to set the active stream.
  /// In can be set the slug defined in the URL or the default first stream for the channel
  const findActiveStream = (channel: GCChannel) => {
    const newStream = channel.streams.find(function (stream) {
      return (
        (slug && stream.slug?.endsWith(slug)) ||
        (!slug && stream.id.endsWith("index")) ||
        (isOriginalsChannel && stream.id.endsWith("index"))
      );
    });
    setActiveChannel(channel);
    if (newStream) {
      setActiveStreamId(newStream.id);
    }
  };

  const { handleScroll, isHidden } = useScrollVisibility();

  const expandedSection = viewAllSectionData?.data.payload.results;

  return (
    <Flex flex={1} minWidth={0} direction="column">
      <ExpandedContentSection
        isOpen={!!viewAllSection}
        onClose={() => {
          setViewAllSection(undefined);
        }}
        section={expandedSection}
        data={[
          ...(expandedSection?.articles ?? []),
          ...(expandedSection?.artists ?? []),
          ...(expandedSection?.comics ?? []),
          ...(expandedSection?.releases ?? []),
          ...(expandedSection?.cards ?? []),
        ]}
      />
      {activeChannel &&
        !isOriginalsChannel &&
        activeChannel?.id !== ORIGINALS_CHANNEL_ID && (
          <HeaderNavigationBar
            activeChannel={activeChannel}
            activeStreamId={activeStreamId}
            showChannelName={!isGcChannel}
            isCollapsed={isHidden}
          />
        )}

      <CMSFeed
        activeStreamId={activeStreamId}
        onScroll={handleScroll}
        showingStreamTab={!isOriginalsChannel}
      />

      {userData && !userData?.verified && (
        <EmailVerificationBanner emailAddress={userData.email_address} />
      )}
    </Flex>
  );
}

export default RootScreen;
