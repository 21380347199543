import {
  Text,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Box,
} from "@chakra-ui/react";
import { t } from "i18next";

interface NotificationTogglesProps {
  notificationsEnabled: boolean;
  setNotificationsEnabled: (value: boolean) => void;
  emailsEnabled: boolean;
  setEmailsEnabled: (value: boolean) => void;
  isModal: boolean;
}
export function NotificationToggles({
  notificationsEnabled,
  setNotificationsEnabled,
  emailsEnabled,
  setEmailsEnabled,
  isModal,
}: NotificationTogglesProps) {
  return (
    <Flex direction="column" px={6} width="full" py={4} gap={4}>
      <Flex
        onClick={
          isModal
            ? undefined
            : () => setNotificationsEnabled(!notificationsEnabled)
        }
      >
        <FormControl width="100%" alignItems="center" display="flex">
          <FormLabel
            cursor="pointer"
            htmlFor="notification-switch"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
            m={0}
          >
            <Text
              variant="filterContent"
              color={notificationsEnabled ? "dune.100" : "dune.700"}
            >
              {t("screens.reader.addToMenu.send_notifications")}
            </Text>
            <Switch
              id="notification-switch"
              size="sm"
              variant="brandSwitch"
              isChecked={notificationsEnabled}
              onChange={
                isModal
                  ? () => setNotificationsEnabled(!notificationsEnabled)
                  : undefined
              }
            />
          </FormLabel>
        </FormControl>
      </Flex>
      <Box w="full" h="1px" bg="transparent.white.10" />
      <Flex
        onClick={isModal ? undefined : () => setEmailsEnabled(!emailsEnabled)}
      >
        <FormControl width="100%" alignItems="center" display="flex">
          <FormLabel
            cursor="pointer"
            htmlFor="email-switch"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
            m={0}
          >
            <Text
              variant="filterContent"
              color={emailsEnabled ? "dune.100" : "dune.700"}
            >
              {t("screens.reader.addToMenu.send_emails")}
            </Text>
            <Switch
              id="email-switch"
              size="sm"
              variant="brandSwitch"
              isChecked={emailsEnabled}
              onChange={
                isModal ? () => setEmailsEnabled(!emailsEnabled) : undefined
              }
            />
          </FormLabel>
        </FormControl>
      </Flex>
    </Flex>
  );
}
