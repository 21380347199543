import { useHookstate } from "@hookstate/core";
import { readerDataStore, readerSettingsStore } from "../ReaderStateWrapper";

export function useP2PSettings() {
  const releaseFragmentsData = useHookstate(
    readerDataStore.releaseFragmentsData,
  ).get();
  const p2pOverlayOpacity = useHookstate(
    readerSettingsStore.p2pOverlayOpacity,
  ).get();
  const p2pEnabled = useHookstate(readerSettingsStore.p2pEnabled).get();

  const toggleP2PEnabled = () => {
    readerSettingsStore.p2pEnabled.set(!p2pEnabled);
  };

  const setP2POverlayOpacity = (value: number) => {
    readerSettingsStore.p2pOverlayOpacity.set(value);
  };

  const p2pEnabledAndAugmented = !!releaseFragmentsData?.length && p2pEnabled;
  return {
    p2pEnabledAndAugmented,
    p2pEnabled,
    p2pOverlayOpacity,
    toggleP2PEnabled,
    setP2POverlayOpacity,
    releaseFragmentsData,
  };
}
