import { Box, Image, Spinner } from "@chakra-ui/react";
import { READING_DIRECTION_TYPES } from "../../constants";
import { Page } from "../../../../types";
import { useImageSizing } from "../../hooks/useImageSizing";
import { animated, SpringValue } from "@react-spring/web";
import { usePageSize } from "../../hooks/usePageSize";
import { useIsOverlayOpen } from "../../hooks/hookstate/useIsOverlayOpen";
import { useMemo, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import isTouchDevice from "../../../../utils/isTouchDevice";

interface DoubleBouncyImageProps {
  readingDirection: string;
  fullHeightReader: boolean;
  releasePageData: Page | null;
  doubleReleasePageData: Page | null;
  swipeProps?: { opacity?: SpringValue<number>; x?: SpringValue<number> };
}
export function DoubleBouncyImage({
  readingDirection,
  fullHeightReader,
  releasePageData,
  doubleReleasePageData,
  swipeProps,
}: DoubleBouncyImageProps) {
  const { toggleIsOverlayOpen } = useIsOverlayOpen();
  const { pageSizing } = usePageSize();
  const { getImageSize } = useImageSizing(false, true);

  const leftPage =
    readingDirection === READING_DIRECTION_TYPES.leftToRight
      ? releasePageData
      : doubleReleasePageData;
  const rightPage =
    readingDirection === READING_DIRECTION_TYPES.leftToRight
      ? doubleReleasePageData
      : releasePageData;

  const [lastResize, setLastResize] = useState(Date.now());
  useResizeObserver(window.document.body, () => {
    setLastResize(Date.now());
  });

  const { imageHeight, imageWidth } = useMemo(
    () => getImageSize(releasePageData),
    [releasePageData, fullHeightReader, lastResize, pageSizing],
  );

  const { imageHeight: doubleImageHeight, imageWidth: doubleImageWidth } =
    useMemo(
      () => getImageSize(doubleReleasePageData),
      [doubleReleasePageData, fullHeightReader, lastResize, pageSizing],
    );

  const maxWidth = fullHeightReader
    ? "100%"
    : imageWidth + doubleImageWidth + "px";

  const isTouch = isTouchDevice();
  return (
    <animated.div
      key={releasePageData?.id}
      onClick={toggleIsOverlayOpen}
      style={{
        position: "relative",
        display: "flex",
        maxWidth,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        touchAction: isTouch ? "auto" : "none",
        overflow: "hidden",
        ...swipeProps,
      }}
    >
      <DoubleImage
        fullHeightReader={fullHeightReader}
        page={leftPage}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
      />
      <DoubleImage
        fullHeightReader={fullHeightReader}
        page={rightPage}
        imageHeight={doubleImageHeight}
        imageWidth={doubleImageWidth}
      />
    </animated.div>
  );
}

interface DoubleImageProps {
  page: Page | null;
  fullHeightReader: boolean;
  imageHeight: number;
  imageWidth: number;
}
function DoubleImage({ page, imageHeight, imageWidth }: DoubleImageProps) {
  const { getImageSrc } = useImageSizing();

  return (
    <Image
      margin="auto"
      key={page?.release_id}
      height={imageHeight}
      width={imageWidth}
      src={getImageSrc(page)}
      alt={`Image for page ${page?.order}`}
      filter={
        page?.display_paid_access_block ? "blur(16px) grayscale(20%)" : "none"
      }
      fallback={
        <Box
          key={page?.release_id}
          height={imageHeight}
          width={imageWidth}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="blaze.blaze" />
        </Box>
      }
      draggable={false}
    />
  );
}
