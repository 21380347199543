import { Flex } from "@chakra-ui/react";
import { TextField, TextFieldProps } from "./TextField";

interface TextFieldGroupProps {
  textFields: TextFieldProps[];
}

export function TextFieldGroup({ textFields }: TextFieldGroupProps) {
  const getBorderRadius = (index: number, itemCount: number) => {
    if (itemCount === 1) {
      return "16px";
    } else if (index === 0) {
      return "16px 16px 4px 4px";
    } else if (index === itemCount - 1) {
      return "4px 4px 16px 16px";
    } else {
      return "4px";
    }
  };

  return (
    <Flex direction="column" gap={1}>
      {textFields.map((textField, index) => (
        <TextField
          key={index}
          {...textField}
          borderRadius={getBorderRadius(index, textFields.length)}
        />
      ))}
    </Flex>
  );
}
