import { throttle, ThrottleSettings } from "lodash";
import { useEffect, useRef } from "react";

export function useThrottle<T extends (...args: any[]) => any>(
  cb: T,
  delay: number,
  options?: ThrottleSettings,
) {
  const throttled = useRef(throttle(cb, delay, options));

  useEffect(() => {
    throttled.current = throttle(cb, delay);
  }, [cb, delay]);

  return throttled.current;
}
