import { Icon, IconProps } from "@chakra-ui/react";

export function AppleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M20.16 17.5861C19.8576 18.2848 19.4996 18.928 19.0848 19.5194C18.5194 20.3255 18.0564 20.8835 17.6996 21.1934C17.1466 21.702 16.554 21.9625 15.9194 21.9773C15.4639 21.9773 14.9145 21.8477 14.2751 21.5847C13.6335 21.323 13.0439 21.1934 12.5047 21.1934C11.9393 21.1934 11.3329 21.323 10.6843 21.5847C10.0347 21.8477 9.51138 21.9847 9.11127 21.9983C8.50277 22.0242 7.89624 21.7563 7.29083 21.1934C6.90442 20.8563 6.4211 20.2786 5.84211 19.4601C5.2209 18.586 4.71017 17.5725 4.31006 16.417C3.88156 15.1689 3.66675 13.9603 3.66675 12.7902C3.66675 11.4498 3.95637 10.2938 4.53647 9.32509C4.99238 8.54697 5.59891 7.93316 6.35802 7.48255C7.11713 7.03195 7.93735 6.80233 8.82066 6.78764C9.30398 6.78764 9.93778 6.93714 10.7254 7.23096C11.5108 7.52576 12.0151 7.67526 12.2362 7.67526C12.4015 7.67526 12.9618 7.50045 13.9115 7.15195C14.8096 6.82875 15.5676 6.69492 16.1886 6.74764C17.8712 6.88344 19.1354 7.54675 19.9761 8.74177C18.4712 9.6536 17.7268 10.9307 17.7416 12.5691C17.7552 13.8452 18.2181 14.9071 19.128 15.7503C19.5403 16.1417 20.0008 16.4441 20.5131 16.6589C20.402 16.9812 20.2847 17.2898 20.16 17.5861Z"
        fill="currentColor"
      />
      <path
        d="M16.3009 2.40011C16.3009 3.40034 15.9355 4.33425 15.2071 5.19867C14.3281 6.22629 13.265 6.8201 12.112 6.7264C12.0973 6.60641 12.0888 6.48011 12.0888 6.3474C12.0888 5.38718 12.5068 4.35956 13.2492 3.51934C13.6198 3.09392 14.0911 2.74019 14.6627 2.45801C15.233 2.18005 15.7725 2.02632 16.2799 2C16.2947 2.13371 16.3009 2.26744 16.3009 2.4001V2.40011Z"
        fill="currentColor"
      />
    </Icon>
  );
}
