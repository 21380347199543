import { AxiosResponse } from "axios";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../services/axiosInstance";
import { ServerSingleResponse, Account } from "../../types";
import { ToggleInterestProps } from "./types";
import { toggleAccountInterests } from "./api";
import { ComicTypesStep } from "./ComicTypesStep";
import { ComicGenresStep } from "./ComicGenresStep";
import { ComicThemesStep } from "./ComicThemesStep";
import { createElement, memo } from "react";
import { OnboardingSplash } from "./OnboardingSplash";

export const INTERESTS_QUERY_KEY = ["accountInterests"];
export const OnboardingContent = memo(
  ({
    activeStep,
    onSuccess,
  }: {
    activeStep: number;
    onSuccess: () => void;
  }) => {
    const { data } = useQuery({
      queryKey: INTERESTS_QUERY_KEY,
      queryFn: () =>
        apiClient.get<ServerSingleResponse<Account["interests"]>>(
          "/v1/account/interests",
        ),
    });

    const queryClient = useQueryClient();
    const { mutate: toggleInterest } = useMutation({
      mutationFn: ({ type }: ToggleInterestProps) => {
        const data = queryClient.getQueryData(
          INTERESTS_QUERY_KEY,
        ) as AxiosResponse<ServerSingleResponse<Account["interests"]>>;
        const interests = { ...data?.data.payload.results };

        return toggleAccountInterests(type, interests[type] ?? []);
      },
      onMutate: async ({ type, id }) => {
        const interests = { ...data?.data.payload.results };
        const interestOfType = interests?.[type] as number[] | undefined;
        let newInterests = { ...interests };
        if (!interests || !interestOfType) {
          newInterests[type] = [id];
        } else {
          const currentIndex = interestOfType?.findIndex((i) => i === id);
          if (currentIndex !== undefined && currentIndex !== -1) {
            newInterests[type] = interestOfType.filter(
              (_, i) => i !== currentIndex,
            );
          } else {
            newInterests[type] = [...interestOfType, id];
          }
        }

        await queryClient.setQueryData(INTERESTS_QUERY_KEY, {
          ...data,
          data: { payload: { results: newInterests } },
        });

        return { prev: interests, new: newInterests };
      },
      onError: (_, __, context) => {
        queryClient.setQueryData(INTERESTS_QUERY_KEY, context?.prev ?? []);
      },
    });

    const interests = data?.data.payload.results;
    const tabProps = [
      interests?.comic_types ?? [],
      interests?.comic_genres ?? [],
      interests?.comic_facets ?? [],
    ];

    const Component = onboardingContent[activeStep];
    return createElement(Component, {
      activeIds: tabProps[activeStep],
      toggleInterest,
      onSuccess,
    });
  },
);

const onboardingContent: Record<number, any> = {
  0: ComicTypesStep,
  1: ComicGenresStep,
  2: ComicThemesStep,
  3: OnboardingSplash,
  // 3: FollowComicsStep,
  // 4: FollowArtistsStep,
};
