import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
} from "@chakra-ui/icons";
import { READING_DIRECTION_TYPES } from "../constants";
import { Text, Box, Flex, keyframes } from "@chakra-ui/react";
import { cloneElement } from "react";
import { useNSFWInterstitial } from "../hooks/useNSFWInterstitial";
import { usePageLayout } from "../hooks/usePageLayout";

const textToDisplay = {
  [READING_DIRECTION_TYPES.leftToRight]: "Read from left to right",
  [READING_DIRECTION_TYPES.rightToLeft]: "Read from right to left",
  [READING_DIRECTION_TYPES.vertical]: "Read from top to bottom",
};
const iconToDisplay = {
  [READING_DIRECTION_TYPES.leftToRight]: <ArrowForwardIcon />,
  [READING_DIRECTION_TYPES.rightToLeft]: <ArrowBackIcon />,
  [READING_DIRECTION_TYPES.vertical]: <ArrowDownIcon />,
};
const fadeInAndOut = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
interface DirectionIndicatorProps {
  readingDirection: string;
}
export function DirectionIndicator({
  readingDirection,
}: DirectionIndicatorProps) {
  const { showNSFWInterstitial } = useNSFWInterstitial();
  const { pageLayout } = usePageLayout();

  if (showNSFWInterstitial) return null;

  return (
    <Flex
      key={pageLayout}
      pointerEvents="none"
      w="100dvw"
      height="100dvh"
      justifyContent="center"
      alignItems="center"
      left="0"
      top="0"
      position="fixed"
    >
      <Box
        animation={`${fadeInAndOut} 2.5s ease-in-out both 1`}
        bg="reader.slate.800_90"
        display="flex"
        px="24px"
        py="16px"
        borderRadius="full"
        gap="16px"
        justifyContent="center"
        alignItems="center"
        flexDirection={
          readingDirection === READING_DIRECTION_TYPES.leftToRight
            ? "row-reverse"
            : "row"
        }
      >
        <Box padding="6px" bg="blaze.blaze" borderRadius="full">
          {cloneElement(iconToDisplay[readingDirection], {
            width: "28px",
            height: "28px",
          })}
        </Box>
        <Text textTransform="uppercase" fontSize="12px" fontWeight="600">
          {textToDisplay[readingDirection]}
        </Text>
      </Box>
    </Flex>
  );
}
