import { Icon, IconProps } from "@chakra-ui/react";

export function SingleLayoutIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 84 60" {...props}>
      <path
        d="M54.5 0.0100098H29.5C26.47 0.0100098 24 2.4796 24 5.50909V54.5009C24 57.5304 26.47 60 29.5 60H54.5C57.53 60 60 57.5304 60 54.5009V5.50909C60 2.4796 57.53 0.0100098 54.5 0.0100098ZM57 54.5009C57 55.8807 55.88 57.0005 54.5 57.0005H29.5C28.12 57.0005 27 55.8807 27 54.5009V5.50909C27 4.12932 28.12 3.00951 29.5 3.00951H54.5C55.88 3.00951 57 4.12932 57 5.50909V54.5009Z"
        fill="#B8BCC6"
      />
      <path
        d="M13.91 0.0100098H1.5C0.67 0.0100098 0 0.679898 0 1.50976C0 2.33962 0.67 3.00951 1.5 3.00951H13.91C14.47 3.00951 15 3.22947 15.39 3.61941C15.78 4.00934 16 4.52926 16 5.06917V54.9408C16 55.4907 15.78 56.0107 15.39 56.3906C14.99 56.7805 14.47 57.0005 13.91 57.0005H1.5C0.67 57.0005 0 57.6704 0 58.5003C0 59.3301 0.67 60 1.5 60H13.91C15.27 60 16.54 59.4801 17.51 58.5202C18.47 57.5604 19 56.2906 19 54.9408V5.06917C19 3.71939 18.47 2.4396 17.5 1.48976C16.54 0.539921 15.26 0.0100098 13.9 0.0100098L13.91 0.0100098Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M82.5002 57.0005H70.0902C69.5302 57.0005 69.0002 56.7805 68.6102 56.3906C68.2202 56.0007 68.0002 55.4807 68.0002 54.9308V5.06915C68.0002 4.51925 68.2202 4.00933 68.6102 3.6094C69.0002 3.21946 69.5302 2.9995 70.0902 2.9995H82.5002C83.3302 2.9995 84.0002 2.32961 84.0002 1.49975C84.0002 0.669888 83.3302 0 82.5002 0H70.0902C68.7302 0 67.4502 0.529912 66.4902 1.47975C65.5302 2.43959 64.9902 3.70938 64.9902 5.05916V54.9408C64.9902 56.2906 65.5202 57.5704 66.4902 58.5202C67.4502 59.4701 68.7302 60 70.0902 60H82.5002C83.3302 60 84.0002 59.3301 84.0002 58.5002C84.0002 57.6704 83.3302 57.0005 82.5002 57.0005Z"
        fill="white"
        fillOpacity="0.2"
      />
    </Icon>
  );
}
