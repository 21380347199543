import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { Range } from "../../@types/util";
import { ComicContentCardProps } from "../../types";
import { ProgressBar } from "../loading/ProgressBar";
import {
  ContentCardBackdrop,
  ExtendedContentCardBackdrop,
} from "./components/ContentCardBackdrop";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { FreeRibbon } from "./components/FreeRibbon";
import { SharpCornerBox } from "./components/SharpCornerBox";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
//import { LibraryCTAButton } from "../buttons/LibraryCTAButton";
import { ComicStatusBadge } from "./components/ComicStatusBadge";
import { GridCardWrapper } from "./components/GridCardWrapper";
import { ReadingStatusButton } from "../readingStatusButton/ReadingStatusButton";
import { useMatchPercentage } from "./hooks/useMatchPercentage";
import { t } from "i18next";
import { BookmarkAddedIcon } from "../icons/BookmarkAddedIcon";
import { BookmarkIcon } from "../icons/BookmarkIcon";
import { ComicCardLeavingSoonBadge } from "./components/LeavingSoonBadge";

export function ComicGridCard({
  content,
  layout: { display, style },
  ...props
}: ComicContentCardProps) {
  const layout_size = style.size;

  const { height, width } = useGridCardDimensions(
    "comics",
    style.mode,
    layout_size,
  );

  const passedProps = {
    ...props,
    content,
    layout: { display, style },
    width,
    height,
  };
  switch (layout_size) {
    case "s":
      return <WideComicGridCard {...passedProps} />;
    case "m":
    case "l":
    case "xl":
      return (
        <GridCardWrapper
          key={passedProps.content.id}
          layout={{ display, style }}
        >
          <TallComicGridCard
            {...passedProps}
            isSmallCard={layout_size === "m"}
          />
        </GridCardWrapper>
      );
    default:
      console.warn("Unsupported card size", layout_size);
      return null;
  }
}

function WideComicGridCard({
  content,
  layout: { display },
  height,
  width,
}: ComicContentCardProps & {
  height?: number;
  width?: number;
}) {
  const {
    include_name,
    include_author,
    include_cover_image,
    include_ribbon_badge,
    include_owner_name,
  } = display;

  return (
    <Flex
      height={`${height}px`}
      width={`${width}px`}
      maxW={`${width}px`}
      direction="row"
      position="relative"
      alignItems="center"
      justifyContent="start"
    >
      <ContentCardBackdrop
        position="absolute"
        bottom={0}
        width="calc(100% - 8px)"
        right={0}
      />
      <Box position="relative">
        {include_cover_image ? (
          <Image
            src={content.image_small_url}
            loading="lazy"
            w="50px"
            minW="50px"
            borderRadius={10}
            alt={`Cover image for ${content.name}`}
          />
        ) : null}
        {include_ribbon_badge && content.is_free ? (
          <FreeRibbon
            color="success.500"
            position="absolute"
            top={0}
            left="-1px"
          />
        ) : null}
      </Box>
      <Flex
        direction="column"
        position="relative"
        width="calc(100% - 58px)"
        pl={3}
      >
        {include_name ? (
          <Text variant="cardSmallHeader">{content.name}</Text>
        ) : null}
        {include_author || include_owner_name ? (
          <Text variant="cardSubtitle" color="neutral.200">
            {content.artist?.roman_name}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
}

function TallComicGridCard({
  content,
  layout,
  isSmallCard,
  onToggleFollow,
}: ComicContentCardProps & {
  height?: number;
  width?: number;
  isSmallCard?: boolean;
}) {
  const {
    include_name,
    include_follow,
    include_cover_image,
    include_author,
    include_progress_meter,
    include_comic_status,
    include_ribbon_badge,
    include_total_favorites,
    include_total_pages,
    include_total_views,
    include_chapter_num,
    include_total_releases,
    include_last_updated,
    include_owner_name,
    include_add_library_cta,
    include_match,
    include_reading_status_button,
  } = layout.display;

  const { hasMatchPercentage, getMatchPercentage } =
    useMatchPercentage(content);

  const leaving_soon_date = content.leaving_soon_date;
  const shouldShowLeavingSoonBadge =
    leaving_soon_date && layout.style.size === "l";
  return (
    <>
      <ExtendedContentCardBackdrop
        position="absolute"
        bottom="0px"
        h="calc(100% - 63px)"
        topBox={{
          height: "63px",
        }}
        bottomBox={{
          height: "calc(100% - 63px)",
        }}
      />
      <Flex className="card-img-wrapper" aspectRatio=".646">
        {include_cover_image ? (
          <Image
            src={content.image_small_url}
            border="1px solid"
            borderColor="transparent.white.10"
            borderRadius={10}
            loading="lazy"
            width="100%"
            aspectRatio=".646"
            alt={`Cover image for ${content.name}`}
          />
        ) : null}
        {include_reading_status_button && (
          <ReadingStatusButton
            cacheKey={`${content.entity_type}-${content.id}`}
            position="absolute"
            right="10px"
            bottom="10px"
            contextual
          />
        )}
        {!shouldShowLeavingSoonBadge &&
        include_ribbon_badge &&
        content.is_free ? (
          <FreeRibbon
            color="success.500"
            position="absolute"
            top={0}
            left="-1px"
            width="3px"
            height="8px"
            fontSize="sm"
          />
        ) : null}

        {shouldShowLeavingSoonBadge && (
          <ComicCardLeavingSoonBadge leavingSoonDate={leaving_soon_date} />
        )}
        {include_match && hasMatchPercentage ? (
          <SharpCornerBox position="absolute" bottom={0}>
            <Text
              variant="cardVitals"
              textTransform="uppercase"
              color="neutral.white"
            >
              {getMatchPercentage()}% MATCH
            </Text>
          </SharpCornerBox>
        ) : null}
        {include_comic_status ? (
          <SharpCornerBox position="absolute" bottom={0}>
            <ComicStatusBadge statusText={content.status_name} />
          </SharpCornerBox>
        ) : null}
      </Flex>
      {!isSmallCard && (
        <ContentCardFooter
          include_match={false}
          include_total_favorites={include_total_favorites}
          include_total_pages={include_total_pages}
          include_total_views={include_total_views}
          include_chapter_num={include_chapter_num}
          include_total_releases={include_total_releases}
          include_last_updated={include_last_updated}
          total_favorites={content.total_favorites}
          total_pageviews={content.total_pageviews}
          relevance_match={content.relevance_match}
          update_time={content.update_time}
          total_published_releases={content.total_published_releases}
          page_count={content.total_published_pages}
          status={content.status}
          status_name={content.status_name}
          layoutMode="grid"
        />
      )}
      {!isSmallCard && include_progress_meter ? (
        <ProgressBar
          color1="blaze.blaze"
          color2="blaze.300"
          value={
            content.user_read_status?.read_progress_percentage as Range<0, 101>
          }
        />
      ) : null}
      <Flex direction="column" position="relative" width="100%">
        {include_name ? (
          <Flex width="100%">
            <Text variant={isSmallCard ? "cardSubtitle" : "cardHeader"}>
              {content.name}
            </Text>
          </Flex>
        ) : null}
        {include_author || include_owner_name ? (
          <Flex width="100%">
            <Text
              variant={isSmallCard ? "cardVitals" : "cardSubtitle"}
              color="neutral.200"
            >
              {content.artist?.roman_name}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      {include_follow && !include_add_library_cta && (
        <Flex width="100%">
          <Button
            variant={content.library_status ? "tertiary" : "secondary"}
            size="sm"
            width="100%"
            gap="8px"
            textTransform="uppercase"
            onClick={(e) => {
              e.stopPropagation();
              onToggleFollow?.();
            }}
          >
            {content.library_status ? <BookmarkAddedIcon /> : <BookmarkIcon />}
            {content.library_status
              ? t("components.readingStatus.unfollow")
              : t("components.readingStatus.follow")}
          </Button>
        </Flex>
      )}
      {!isSmallCard && include_add_library_cta && (
        <Flex width="100%">
          <ReadingStatusButton
            cacheKey={`${content.entity_type}-${content.id}`}
            ctaButton={
              <Button
                variant={content.library_status ? "tertiary" : "secondary"}
                size="sm"
                width="100%"
                gap="8px"
                textTransform="uppercase"
              >
                {content.library_status ? (
                  <BookmarkAddedIcon />
                ) : (
                  <BookmarkIcon />
                )}
                {content.library_status
                  ? t("components.readingStatus.manage")
                  : t("components.readingStatus.add")}
              </Button>
            }
          />
        </Flex>
      )}
    </>
  );
}
