import { Flex, Text, Box } from "@chakra-ui/react";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";

interface FooterBannerProps {
  text: string | React.ReactNode;
  actions?: React.ReactNode;
}

export function FooterBanner({ text, actions }: FooterBannerProps) {
  const isMobile = useMobileBreakpoint();

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      backgroundColor="transparent.black.90"
      padding={isMobile ? "16px" : "24px 40px"}
      backdropFilter="blur(24px)"
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      border="1px solid"
      borderColor="transparent.white.10"
    >
      <Flex
        borderRadius="16px"
        border="1px solid"
        borderColor="blaze.blaze"
        bg="blaze.blaze_10"
        padding="12px"
        flexDir={isMobile ? "column" : "row"}
        gap={isMobile ? "12px" : "24px"}
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontSize={isMobile ? "12px" : "14px"}
          lineHeight="150%"
          color="dune.700"
          textAlign="center"
        >
          {text}
        </Text>
        <Flex flexDir="row" gap="8px">
          {actions}
        </Flex>
      </Flex>
    </Box>
  );
}
