import { Divider } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function ContentDivider() {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <Divider
      height="1px"
      width="100%"
      background="transparent.white.20"
      mb={isSmallBreakpoint ? "24px" : "40px"}
    />
  );
}
