import { Button, Flex, Heading } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  GET_CONVERSATIONS_QUERY_KEY,
  getConversations,
} from "./api/inboxScreenApi.ts";
import ConversationList from "./components/ConversationList.tsx";

export default function InboxScreen() {
  const { isLoading, data, error } = useQuery({
    queryKey: GET_CONVERSATIONS_QUERY_KEY,
    queryFn: getConversations,
  });

  const hasResults = data?.data.payload.results.length;

  return (
    <Flex width="full" px={3} py={3} my={2} color="neutral.white">
      <Flex
        height="full"
        minWidth="250px"
        flexDirection="column"
        backgroundColor="charcoal.charcoal"
        borderLeftRadius={5}
      >
        <Flex flexDirection="row" justifyContent="space-between" p={2}>
          <Heading size="m">My Inbox</Heading>
          <Link to="/my/inbox/new-conversation">
            <Button px={1} size="m">
              New Message
            </Button>
          </Link>
        </Flex>
        {(!isLoading && !hasResults) || error ? <EmptyState /> : null}
        {!isLoading && hasResults ? (
          <ConversationList results={data.data.payload.results} />
        ) : null}
      </Flex>
      <Flex
        width="full"
        px={3}
        backgroundColor="charcoal.300"
        borderRightRadius={5}
      >
        <EmptyState />
      </Flex>
    </Flex>
  );
}

function EmptyState() {
  const [t] = useTranslation();
  return (
    <Flex
      width="full"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>{t("screens.inbox.noConversations")}</Heading>
    </Flex>
  );
}
