import { apiClient } from "../../../services/axiosInstance";
import { Article, Artist, Comic, Release } from "../../../types";

export type SearchResponse = {
  articles: Article[];
  artists: Artist[];
  comics: Comic[];
  releases: Release[];
};

export function getAutoComplete(searchTerm?: string) {
  return apiClient.get<SearchResponse>(
    `${window.location.origin}/search/nav-autocomplete.json?q=${searchTerm}`,
  );
}

export const GET_AUTO_COMPLETE_QUERY_KEY = ["autocomplete"];
