import { apiClient } from "../services/axiosInstance";
import { LibraryStatusEntity, ServerSingleResponse } from "../types";

export function postToggleFavorite(comicSlug: string, is_favorite: boolean) {
  return apiClient.post<ServerSingleResponse<boolean>>(
    `/v1/comics/${comicSlug}/toggle-like`,
    { meta: [], payload: { is_favorite } },
  );
}

export type ComicLibraryPayload = {
  comic_user_reading_status_id?: number;
  disable_emails?: boolean;
  disable_notifications?: boolean;
};
export function postAddToLibrary(
  comicId: number,
  payload: ComicLibraryPayload,
) {
  return apiClient.post<ServerSingleResponse<LibraryStatusEntity>>(
    `/v1/comics/${comicId}/add-library`,
    { meta: [], payload },
  );
}

export function postManageLibrary(
  comicId: number,
  payload: ComicLibraryPayload,
) {
  return apiClient.post<ServerSingleResponse<LibraryStatusEntity>>(
    `/v1/comics/${comicId}/manage-library`,
    { meta: [], payload },
  );
}

export function postRemoveFromLibrary(comicId: number) {
  return apiClient.post<ServerSingleResponse<boolean>>(
    `/v1/comics/${comicId}/remove-library`,
    { meta: [] },
  );
}
