import { Icon, IconProps } from "@chakra-ui/react";

export function GlobalIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2336 3.68379C6.54483 4.46343 3.73864 7.63531 3.51446 11.5H8.50447C8.5462 9.17229 8.87776 7.05766 9.40434 5.47793C9.63693 4.78016 9.91376 4.16781 10.2336 3.68379ZM12 3.5C11.8047 3.5 11.539 3.61831 11.225 4.01675C10.9154 4.40955 10.6148 5.00893 10.353 5.79415C9.86709 7.25196 9.54637 9.25531 9.50464 11.5H14.4954C14.4536 9.25531 14.1329 7.25196 13.647 5.79415C13.3852 5.00893 13.0846 4.40955 12.775 4.01675C12.461 3.61831 12.1953 3.5 12 3.5ZM15.4955 11.5C15.4538 9.17229 15.1222 7.05766 14.5957 5.47793C14.3631 4.78016 14.0862 4.16781 13.7664 3.68379C17.4552 4.46343 20.2614 7.63531 20.4855 11.5H15.4955ZM14.4954 12.5H9.50464C9.54637 14.7447 9.86709 16.748 10.353 18.2058C10.6148 18.9911 10.9154 19.5904 11.225 19.9832C11.539 20.3817 11.8047 20.5 12 20.5C12.1953 20.5 12.461 20.3817 12.775 19.9832C13.0846 19.5904 13.3852 18.9911 13.647 18.2058C14.1329 16.748 14.4536 14.7447 14.4954 12.5ZM13.7664 20.3162C14.0862 19.8322 14.3631 19.2198 14.5957 18.5221C15.1222 16.9423 15.4538 14.8277 15.4955 12.5H20.4855C20.2614 16.3647 17.4552 19.5366 13.7664 20.3162ZM12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM10.2336 20.3162C9.91376 19.8322 9.63693 19.2198 9.40434 18.5221C8.87776 16.9423 8.5462 14.8277 8.50447 12.5H3.51446C3.73864 16.3647 6.54483 19.5366 10.2336 20.3162Z"
        fill="currentColor"
      />
    </Icon>
  );
}
