import { Icon, IconProps } from "@chakra-ui/react";

export function TrashcanIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.5C8.17157 2.5 7.5 3.17157 7.5 4V5.5H6H4C3.72386 5.5 3.5 5.72386 3.5 6C3.5 6.27614 3.72386 6.5 4 6.5H5.5V20C5.5 20.8284 6.17157 21.5 7 21.5H17C17.8284 21.5 18.5 20.8284 18.5 20V6.5H20C20.2761 6.5 20.5 6.27614 20.5 6C20.5 5.72386 20.2761 5.5 20 5.5H18H16.5V4C16.5 3.17157 15.8284 2.5 15 2.5H9ZM15.5 5.5V4C15.5 3.72386 15.2761 3.5 15 3.5H9C8.72386 3.5 8.5 3.72386 8.5 4V5.5H15.5ZM8 6.5H6.5V20C6.5 20.2761 6.72386 20.5 7 20.5H17C17.2761 20.5 17.5 20.2761 17.5 20V6.5H16H8ZM10 9.5C10.2761 9.5 10.5 9.72386 10.5 10L10.5 17C10.5 17.2761 10.2761 17.5 10 17.5C9.72386 17.5 9.5 17.2761 9.5 17L9.5 10C9.5 9.72386 9.72386 9.5 10 9.5ZM14 9.5C14.2761 9.5 14.5 9.72386 14.5 10V17C14.5 17.2761 14.2761 17.5 14 17.5C13.7239 17.5 13.5 17.2761 13.5 17V10C13.5 9.72386 13.7239 9.5 14 9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
