// Generate half point sizes for layout styles, .5 up to 11.5
export const sizes = new Array(12)
  .fill(1)
  .map((_n, i) => {
    const size = i + 0.5;
    return { [size]: `${size * 4}px` };
  })
  .reduce(
    (acc, pairs) => ({
      ...acc,
      ...pairs,
    }),
    {},
  );
