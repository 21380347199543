import { Icon, IconProps } from "@chakra-ui/react";

export function ComicFrequencyStatusIcon(props: IconProps) {
  return (
    <Icon width="8" height="8" viewBox="0 0 8 8" fill="none" {...props}>
      <circle cx="4" cy="4" r="4" fill="#3CA52C" />
      <circle
        cx="4"
        cy="4"
        r="3.5"
        stroke="url(#paint0_linear_2464_974)"
        strokeOpacity="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2464_974"
          x1="4"
          y1="0"
          x2="4"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
