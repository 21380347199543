import { motion } from "framer-motion";
import { useReaderNavigation } from "../../hooks/useReaderNavigation";
import { useComicNavigation } from "../../hooks/useComicNavigation";
import { Text, Box, Button, Flex, Progress } from "@chakra-ui/react";
import { MediaArrowLeftIcon } from "../../../../components/icons/reader/MediaArrowLeftIcon";
import { InfoIcon } from "../../../../components/icons/reader/InfoIcon";
import { MediaArrowRightIcon } from "../../../../components/icons/reader/MediaArrowRightIcon";
import { useMobileBreakpoint } from "../../../../utils/useBreakpoints";
import { t } from "i18next";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import useAccountCreationCTA from "../../hooks/useAccountCreationCTA";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function ReleaseProgressBar() {
  const { releaseProgress } = useReaderNavigation();
  const { hasPrevRelease, hasNextRelease, goToPrevRelease, goToNextRelease } =
    useComicNavigation();
  const isMobile = useMobileBreakpoint();

  // Only show account creation CTA with navigation arrows
  // when user is near the end of the release
  const accountCreationCTAEnabled = releaseProgress > 0.95;

  return (
    <Box
      as={motion.div}
      position="relative"
      animate={{ y: isMobile ? [20, 0] : [20, -20], opacity: [0, 1] }}
      display="flex"
      bg="reader.slate.900_90"
      width={isMobile ? "100%" : "28rem"}
      borderRadius={isMobile ? "12px" : "32px"}
      px="32px"
      py="14px"
      flexDirection="column"
      gap="8px"
      borderTop="1px solid"
      borderColor="transparent.white.20"
    >
      <Flex width="100%" justifyContent="space-between">
        <PrevReleaseButton
          isDisabled={!hasPrevRelease}
          goToPrevRelease={goToPrevRelease}
          accountCreationCTAEnabled={accountCreationCTAEnabled}
        />
        <ComicDetailsTrigger />
        <NextReleaseButton
          isDisabled={!hasNextRelease}
          goToNextRelease={goToNextRelease}
          accountCreationCTAEnabled={accountCreationCTAEnabled}
        />
      </Flex>
      <Flex width="100%" justifyContent="center">
        <Progress
          variant="releaseProgress"
          value={Math.floor(releaseProgress * 100)}
          height=".25rem"
          width="100%"
        />
      </Flex>
    </Box>
  );
}

interface PrevReleaseButtonProps {
  goToPrevRelease: () => void;
  isDisabled: boolean;
  accountCreationCTAEnabled?: boolean;
}
function PrevReleaseButton({
  goToPrevRelease,
  isDisabled,
  accountCreationCTAEnabled = false,
}: PrevReleaseButtonProps) {
  const isMobile = useMobileBreakpoint();

  const onButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    goToPrevRelease();
  };

  const { triggerAccountCreationCTA } = useAccountCreationCTA({
    onCompleteOrDismiss: onButtonClick,
  });

  return (
    <Button
      onClick={(e) =>
        accountCreationCTAEnabled
          ? triggerAccountCreationCTA(e)
          : onButtonClick(e)
      }
      isDisabled={isDisabled}
      aria-label="Go to previous release"
      variant="readerOverlayButton"
      width="150px"
      gap="10px"
      justifyContent="flex-start"
    >
      <MediaArrowLeftIcon width="24px" height="24px" />
      {!isMobile && (
        <Text fontSize="12px" fontWeight={500}>
          {t("screens.reader.overlay.prev_release")}
        </Text>
      )}
    </Button>
  );
}

function ComicDetailsTrigger() {
  const { onToggleSlideInMenu } = useSlideInMenu();

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onToggleSlideInMenu(SLIDE_IN_MENUS.comicDetails);
      }}
      variant="readerOverlayButton"
      aria-label="Toggle series info menu"
    >
      <InfoIcon width="28px" height="28px" />
    </Button>
  );
}

interface NextReleaseButtonProps {
  goToNextRelease: () => void;
  isDisabled: boolean;
  accountCreationCTAEnabled?: boolean;
}
function NextReleaseButton({
  goToNextRelease,
  isDisabled,
  accountCreationCTAEnabled = false,
}: NextReleaseButtonProps) {
  const isMobile = useMobileBreakpoint();

  const onButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    goToNextRelease();
  };

  const { triggerAccountCreationCTA } = useAccountCreationCTA({
    onCompleteOrDismiss: onButtonClick,
  });

  return (
    <Button
      onClick={(e) =>
        accountCreationCTAEnabled
          ? triggerAccountCreationCTA(e)
          : onButtonClick(e)
      }
      isDisabled={isDisabled}
      aria-label="Go to next release"
      variant="readerOverlayButton"
      width="150px"
      gap="10px"
      justifyContent="flex-end"
    >
      {!isMobile && (
        <Text fontSize="12px" fontWeight={500}>
          {t("screens.reader.overlay.next_release")}
        </Text>
      )}
      <MediaArrowRightIcon width="24px" height="24px" />
    </Button>
  );
}
