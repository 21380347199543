import {
  Text,
  Flex,
  Modal,
  Grid,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import { ContentCard } from "./ContentCard";
import { useEffect, useMemo, useRef, useState } from "react";
import { sizeEstimator } from "./hooks/useGridCardDimensions";
import {
  Comic,
  Artist,
  Article,
  Release,
  ContentSectionCustomCard,
  Section,
} from "../../types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useIsSidebarOpen } from "../../hooks/useIsSidebarOpen";
import { ShareIcon } from "../icons/ShareIcon";
import { CloseIcon } from "@chakra-ui/icons";
import { useEnsureFocus } from "../../hooks/useEnsureFocus";
import useResizeObserver from "@react-hook/resize-observer";
import { BackButton } from "../buttons/BackButton";
import { t } from "i18next";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { NAVBAR_HEIGHT } from "../../screens/root/constants";
import { CUSTOM_CARD_TYPES } from "./constants";
import { FeatureCardContainer } from "./FeatureCardContainer";

interface ExpandedContentSection {
  onClose: () => void;
  isOpen: boolean;
  isSmall?: boolean;
  deeplinked?: boolean;
  section?: Section;
  data: (Comic | Artist | Article | Release | ContentSectionCustomCard)[];
}
export function ExpandedContentSection({
  isOpen,
  onClose,
  isSmall,
  deeplinked,
  section,
  data,
}: ExpandedContentSection) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const { slug } = useParams();
  useEffect(() => {
    if (!slug) onClose();
  }, [slug]);
  const { isSidebarOpen } = useIsSidebarOpen();

  const style = section?.meta.layout.style;
  const { context } = style ?? {};

  const dimensions = useMemo(() => {
    const style = section?.meta.layout.style;
    if (!style) return { width: 0 };
    const { context, mode, size } = style;
    return sizeEstimator(context, mode, size);
  }, [section]);

  const modalContentRef = useRef<HTMLElement>(null);
  const ensureFocus = useEnsureFocus();

  // When the popover appears, the reading status box should be focused.
  // However, the container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (modalContentRef.current) {
        ensureFocus(modalContentRef.current);
      }
    },
  });

  const widthAdjustment = isSidebarOpen && !isSmallBreakpoint ? 300 : 0;
  const getColumns = () => {
    const width = window.innerWidth - widthAdjustment;
    return Math.floor(
      width / (dimensions.width + (isSmallBreakpoint ? 16 : 32)),
    );
  };
  const [columnCount, setColumnCount] = useState<number>(() => getColumns());
  useResizeObserver(window.document.body, () => setColumnCount(getColumns()));
  useEffect(() => {
    setColumnCount(getColumns());
  }, [isSmall, isSidebarOpen, dimensions]);

  const share = () => {
    const shareData = {
      title: section?.display_name,
      text: section?.description,
      url: section?.share_url,
    };
    navigator.share(shareData);
  };

  const isFeatureCard =
    context === CUSTOM_CARD_TYPES.featuredCarousel ||
    context === CUSTOM_CARD_TYPES.featuredSeriesRoadblock;

  const isRoadblock = context === CUSTOM_CARD_TYPES.featuredSeriesRoadblock;

  const navigate = useNavigate();
  return (
    <Modal
      initialFocusRef={focusHandler}
      variant="filters"
      isOpen={isOpen}
      onClose={onClose}
      trapFocus={false}
      blockScrollOnMount={false}
    >
      <ModalContent
        ref={modalContentRef}
        height={`calc(100dvh - ${NAVBAR_HEIGHT}px)`}
        width={`calc(100vw - ${widthAdjustment}px)`}
        maxWidth="none"
        backdropFilter="none"
        boxShadow="none"
        borderRadius="none"
        containerProps={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          zIndex: 1,
          pointerEvents: "none",
        }}
        pointerEvents="all"
        margin={0}
      >
        <ModalHeader
          pl={6}
          pr={6}
          alignItems="center"
          border="none"
          width="100%"
        >
          <Flex position="relative" direction="column" gap="8px" width="100%">
            {deeplinked ? null : (
              <BackButton
                text={t("components.cards.expandedContentSection.backToHome")}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(-1);
                  onClose();
                }}
              />
            )}
            <Flex
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Text
                variant="title"
                fontSize={isSmallBreakpoint ? "20px" : "38px"}
                lineHeight="normal"
                letterSpacing=".5px"
              >
                {section?.display_name}
              </Text>
              {!isSmallBreakpoint && !deeplinked && (
                <Button
                  variant="tertiary"
                  gap="8px"
                  alignItems="center"
                  onClick={share}
                  aria-label={t(
                    "components.cards.expandedContentSection.share",
                  )}
                >
                  <ShareIcon w="16px" h="16px" />{" "}
                  {t("components.cards.expandedContentSection.share")}
                </Button>
              )}
            </Flex>
            <Flex gap="8px">
              {section?.description ? (
                <>
                  <Text variant="subtitle" fontSize="16px">
                    {section?.description}
                  </Text>
                  <Text variant="subtitle" fontSize="16px">
                    &#183;
                  </Text>
                </>
              ) : null}
              {data.length ? (
                <Text variant="subtitle" fontSize="16px">
                  {data.length} {section?.meta.layout.style.context}
                </Text>
              ) : null}
            </Flex>
          </Flex>
          {(isSmallBreakpoint || deeplinked) && (
            <Button
              variant="iconOnly"
              onClick={() => {
                if (deeplinked) return;
                share();
              }}
              aria-label={
                deeplinked
                  ? t("components.cards.expandedContentSection.close")
                  : t("components.cards.expandedContentSection.share")
              }
              gap="8px"
              alignItems="center"
              color="dune.dune"
              position="absolute"
              top="20px"
              right="20px"
            >
              {deeplinked ? (
                <Link to="/">
                  <CloseIcon
                    transition="all .1s linear"
                    w="16px"
                    h="16px"
                    _hover={{ color: "blaze.blaze" }}
                  />
                </Link>
              ) : (
                <ShareIcon
                  transition="all .1s linear"
                  w="22px"
                  h="22px"
                  _hover={{ color: "blaze.blaze" }}
                />
              )}
            </Button>
          )}
        </ModalHeader>
        <ModalBody>
          {section ? (
            isFeatureCard ? (
              <FeatureCardContainer
                data={data as ContentSectionCustomCard[]}
                scrollByCount={1}
                isRoadblock={isRoadblock}
              />
            ) : (
              <Grid
                templateColumns={`repeat(${columnCount > data.length ? data.length : columnCount}, ${dimensions.width}px)`}
                gap={isSmallBreakpoint ? "16px" : "32px"}
                p="16px"
                justifyContent="center"
              >
                {data.map((item) => (
                  <ContentCard
                    key={item.id}
                    content={item}
                    layout={section.meta.layout}
                  />
                ))}
              </Grid>
            )
          ) : (
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner color="blaze.blaze" />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
