import { useMemo, useState } from "react";
import { useReaderNavigation } from "./useReaderNavigation";
import {
  PAGE_LAYOUT_TYPES,
  PAGE_SIZING_TYPES,
  READING_DIRECTION_TYPES,
} from "../constants";
import { usePageLayout } from "./usePageLayout";
import { usePageSize } from "./usePageSize";

export function useHorizontalReader(readingDirection: string) {
  const { pageSizing } = usePageSize();
  const { pageLayout } = usePageLayout();
  const readerNavigation = useReaderNavigation();
  const { activePage, releasePagesData } = readerNavigation;
  const [lastActivePage, setLastActivePage] = useState(activePage);

  const releasePageData = useMemo(() => {
    return releasePagesData?.length ? releasePagesData[activePage] : null;
  }, [activePage, releasePagesData]);

  const doubleReleasePageData = useMemo(() => {
    if (pageLayout !== PAGE_LAYOUT_TYPES.double) return null;
    const activeDoublePage = activePage + 1;
    if (releasePagesData?.[activeDoublePage]?.is_double_with_prev) {
      return releasePagesData[activeDoublePage];
    }
  }, [activePage, pageLayout, releasePagesData]);

  const directionMoved = useMemo(() => {
    if (lastActivePage !== activePage) {
      setLastActivePage(activePage);
      if (readingDirection === READING_DIRECTION_TYPES.leftToRight) {
        return activePage > lastActivePage
          ? READING_DIRECTION_TYPES.rightToLeft
          : READING_DIRECTION_TYPES.leftToRight;
      } else if (readingDirection === READING_DIRECTION_TYPES.rightToLeft) {
        return activePage > lastActivePage
          ? READING_DIRECTION_TYPES.leftToRight
          : READING_DIRECTION_TYPES.rightToLeft;
      }
    }
    return null;
  }, [readingDirection, activePage]);

  const fullHeightReader = useMemo(() => {
    return (
      pageSizing === PAGE_SIZING_TYPES.height ||
      (pageLayout === PAGE_LAYOUT_TYPES.double && !!doubleReleasePageData)
    );
  }, [pageSizing, pageLayout, doubleReleasePageData]);

  const readerHorizontalScroll = useMemo(() => {
    return (
      pageSizing === PAGE_SIZING_TYPES.height &&
      pageLayout === PAGE_LAYOUT_TYPES.double
    );
  }, [pageSizing, pageLayout]);

  const showPaywall = useMemo(() => {
    return (
      !!releasePageData?.display_paid_access_block ||
      !!doubleReleasePageData?.display_paid_access_block
    );
  }, [releasePageData, doubleReleasePageData]);

  return {
    ...readerNavigation,
    pageLayout,
    releasePageData,
    doubleReleasePageData,
    directionMoved,
    fullHeightReader,
    readerHorizontalScroll,
    showPaywall,
  };
}
