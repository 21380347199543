import { Icon, IconProps } from "@chakra-ui/react";

export function ReleasesIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.5C3.72386 2.5 3.5 2.72386 3.5 3V15C3.5 15.2761 3.72386 15.5 4 15.5H14C14.2761 15.5 14.5 15.2761 14.5 15V3C14.5 2.72386 14.2761 2.5 14 2.5H4ZM4.5 14.5V3.5H13.5V14.5H4.5ZM17 6.5C17.2761 6.5 17.5 6.72386 17.5 7V18C17.5 18.2761 17.2761 18.5 17 18.5H8C7.72386 18.5 7.5 18.2761 7.5 18C7.5 17.7239 7.72386 17.5 8 17.5H16.5V7C16.5 6.72386 16.7239 6.5 17 6.5ZM20 9.5C20.2761 9.5 20.5 9.72386 20.5 10V21C20.5 21.2761 20.2761 21.5 20 21.5H11C10.7239 21.5 10.5 21.2761 10.5 21C10.5 20.7239 10.7239 20.5 11 20.5H19.5V10C19.5 9.72386 19.7239 9.5 20 9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
