import { Icon, IconProps } from "@chakra-ui/react";

export function ListIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.5C4.17157 4.5 3.5 5.17157 3.5 6V9C3.5 9.82843 4.17157 10.5 5 10.5H19C19.8284 10.5 20.5 9.82843 20.5 9V6C20.5 5.17157 19.8284 4.5 19 4.5H5ZM4.5 6C4.5 5.72386 4.72386 5.5 5 5.5H19C19.2761 5.5 19.5 5.72386 19.5 6V9C19.5 9.27614 19.2761 9.5 19 9.5H5C4.72386 9.5 4.5 9.27614 4.5 9V6ZM5 13.5C4.17157 13.5 3.5 14.1716 3.5 15V18C3.5 18.8284 4.17157 19.5 5 19.5H19C19.8284 19.5 20.5 18.8284 20.5 18V15C20.5 14.1716 19.8284 13.5 19 13.5H5ZM4.5 15C4.5 14.7239 4.72386 14.5 5 14.5H19C19.2761 14.5 19.5 14.7239 19.5 15V18C19.5 18.2761 19.2761 18.5 19 18.5H5C4.72386 18.5 4.5 18.2761 4.5 18V15Z"
        fill="currentColor"
      />
    </Icon>
  );
}
