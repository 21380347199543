import { Icon, IconProps } from "@chakra-ui/react";

export function MoreIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99976 3.37402C8.37844 3.37402 7.87476 3.8777 7.87476 4.49902C7.87476 5.12034 8.37844 5.62402 8.99976 5.62402C9.62108 5.62402 10.1248 5.12034 10.1248 4.49902C10.1248 3.8777 9.62108 3.37402 8.99976 3.37402ZM8.99976 7.87402C8.37844 7.87402 7.87476 8.3777 7.87476 8.99902C7.87476 9.62034 8.37844 10.124 8.99976 10.124C9.62108 10.124 10.1248 9.62034 10.1248 8.99902C10.1248 8.3777 9.62108 7.87402 8.99976 7.87402ZM8.99976 12.374C8.37844 12.374 7.87476 12.8777 7.87476 13.499C7.87476 14.1203 8.37844 14.624 8.99976 14.624C9.62108 14.624 10.1248 14.1203 10.1248 13.499C10.1248 12.8777 9.62108 12.374 8.99976 12.374Z"
        fill="currentColor"
      />
    </Icon>
  );
}
