export const browse = {
  title: "Browse Screen",
  // browse_title_type_category_language_free:
  //   "Read {language.name} {category.name} {type.plural_name} Online",
  browse_title_type_category_language:
    "Read {{language.name}} {{category.name}} {{type.plural_name}} Online",
  // browse_title_type_category_free:
  // "Read {category.name} {type.plural_name} Online",
  browse_title_type_category:
    "Read {{category.name}} {{type.plural_name}} Online",
  // browse_title_type_language_free:
  //   "Read {language.name} {type.plural_name} Online",
  browse_title_type_language:
    "Read {{language.name}} {{type.plural_name}} Online",
  // browse_title_type_free: "Read {type.plural_name} Online",
  browse_title_type: "Read {{type.plural_name}} Online",
  // browse_title_category_language_free:
  // "Read {language.name} {category.name} Titles Online",
  browse_title_category_language:
    "Read {{language.name}} {{category.name}} Titles Online",
  // browse_title_category_free: "Read {category.name} Titles online",
  browse_title_category: "Read {{category.name}} Titles Online",
  // browse_title_language_free: "Read {language.name} Titles Online",
  browse_title_language: "Read {{language.name}} Titles Online",
  // browse_title_free: "All Free Titles",
  browse_title: "All Titles",
  searchPlaceholderComics: "Search series...",
  searchPlaceholderCreators: "Search creators...",
  creators_title: "Community",
  filters: {
    apply: "Apply filters",
    clear: "Clear",
    clearAll: "Clear all",
  },
};
