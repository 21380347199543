import { useMemo } from "react";
import { ContentSectionCustomCard, GCStream } from "../../types";
import { Grid, useBreakpoint } from "@chakra-ui/react";
import { StreamLinkCard } from "./StreamLinkCard";
import { useAccountData } from "../../hooks/useAccountData";

interface HeroCardContainerProps {
  data: ContentSectionCustomCard[];
}

export function StreamLinksContainer({ data }: HeroCardContainerProps) {
  const breakpoint = useBreakpoint({ ssr: false });

  const columnCount = useMemo(() => {
    if (["xs", "sm"].includes(breakpoint)) return 1;
    else if (["md", "lg"].includes(breakpoint)) return 2;
    else if (["xl", "2xl"].includes(breakpoint)) return 3;
    else return 2;
  }, [breakpoint]);

  const accountData = useAccountData();
  const channels = accountData?.home_tab_channels;
  const streams = useMemo(() => {
    const originalsChannel = accountData?.originals_channel.streams[0];
    const originalsWithSlug = {
      ...originalsChannel,
      slug: "originals",
    } as GCStream;
    var streams = channels?.flatMap((channel) => channel.streams);
    if (originalsWithSlug) streams?.push(originalsWithSlug);
    return streams;
  }, [channels]);

  const rowCount = Math.ceil(data.length / columnCount);

  return (
    <Grid
      templateRows={`repeat(${rowCount}, 1fr)`}
      autoColumns="1fr"
      gridAutoFlow={columnCount == 1 ? undefined : "column"}
      gap={4}
      marginRight="16px"
    >
      {data.map((item) => {
        const ctaUrl = streams?.find(
          (stream) => stream.id === item.cta_url,
        )?.slug;

        return <StreamLinkCard card={item} streamUrl={`/channel/${ctaUrl}`} />;
      })}
    </Grid>
  );
}
