import { Icon, IconProps } from "@chakra-ui/react";

export function ReleaseIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3373 4.04359L13.8518 4.16305C13.3155 4.295 12.9877 4.83672 13.1197 5.37301L16.5299 19.2334C16.6619 19.7697 17.2036 20.0975 17.7399 19.9655L18.2254 19.8461C18.7617 19.7141 19.0895 19.1724 18.9575 18.6361L15.5473 4.77571C15.4153 4.23942 14.8736 3.91164 14.3373 4.04359ZM13.6129 3.19201C12.5403 3.45591 11.8847 4.53935 12.1486 5.61193L13.0564 9.30136C12.7498 9.11031 12.3878 8.99993 12 8.99993H11.5C10.3954 8.99993 9.5 9.89536 9.5 10.9999V18.9999C9.5 20.1045 10.3954 20.9999 11.5 20.9999H12C13.1046 20.9999 14 20.1045 14 18.9999V13.1365L15.5589 19.4723C15.8228 20.5449 16.9062 21.2005 17.9788 20.9366L18.4643 20.8171C19.5369 20.5532 20.1925 19.4697 19.9286 18.3972L16.5183 4.53679C16.2544 3.46421 15.171 2.80865 14.0984 3.07255L13.6129 3.19201ZM6.5 6.99993H6C5.44772 6.99993 5 7.44764 5 7.99993V18.9999C5 19.5522 5.44772 19.9999 6 19.9999H6.5C7.05228 19.9999 7.5 19.5522 7.5 18.9999V7.99993C7.5 7.44764 7.05228 6.99993 6.5 6.99993ZM6 5.99993C4.89543 5.99993 4 6.89536 4 7.99993V18.9999C4 20.1045 4.89543 20.9999 6 20.9999H6.5C7.60457 20.9999 8.5 20.1045 8.5 18.9999V7.99993C8.5 6.89536 7.60457 5.99993 6.5 5.99993H6ZM11.5 9.99993H12C12.5523 9.99993 13 10.4476 13 10.9999V18.9999C13 19.5522 12.5523 19.9999 12 19.9999H11.5C10.9477 19.9999 10.5 19.5522 10.5 18.9999V10.9999C10.5 10.4476 10.9477 9.99993 11.5 9.99993Z"
        fill="currentColor"
      />
    </Icon>
  );
}
