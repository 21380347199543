import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FunnelComponentProps } from "../funnel/types";
import { CSSProperties, useEffect, useState } from "react";
import { t } from "i18next";
import { INTERESTS_QUERY_KEY, OnboardingContent } from "./OnboardingContent";
import { apiClient } from "../../services/axiosInstance";
import { useQueryClient } from "@tanstack/react-query";
import { Account, ServerSingleResponse } from "../../types";
import { AxiosResponse } from "axios";
import { useAccountData } from "../../hooks/useAccountData";
import { GET_ACCOUNT_ME_QUERY_KEY, getAccountMe } from "../auth/api/authApi";
import { motion } from "framer-motion";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { CloseIcon } from "@chakra-ui/icons";
import { OnboardingStepTitles } from "./OnboardingStepTitles";
import { SubscriptionModalBody } from "../payments/SubscriptionModal";
import { useAttributionParams } from "../../hooks/useAttributionParams";

const ONBOARDING_STEP_MAP = {
  comic_types: 0,
  comic_genres: 1,
  comic_facets: 2,
  splash: 3,
};
export function OnboardingModalBody({
  onSuccess,
  setFunnelType,
}: FunnelComponentProps) {
  const attributionQueryParams = useAttributionParams();
  const [showSubModal, setShowSubModal] = useState(false);
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState(0);
  const accountData = useAccountData();

  useEffect(() => {
    window.document.getElementById("gcModalScrollContainer")?.scrollTo(0, 0);
  }, [activeStep]);

  // Update the global account data when unmounting this component
  // this will ensure the home page updates on modal close.
  const saveAndClose = () => {
    const interests = queryClient.getQueryData([
      INTERESTS_QUERY_KEY,
    ]) as AxiosResponse<ServerSingleResponse<Account["interests"]>>;
    if (accountData && interests) {
      queryClient.setQueryData(GET_ACCOUNT_ME_QUERY_KEY, {
        data: {
          payload: {
            results: {
              ...accountData,
              interests: interests?.data.payload.results,
            },
          },
        },
      });
    }

    onSuccess();
  };

  const skipOnboarding = async () => {
    if (accountData?.user?.onboarding !== "completed") {
      await apiClient.post("/v1/account/onboarding/skip", {
        meta: [],
        payload: {},
      });
    }
    const response = await getAccountMe(attributionQueryParams);
    queryClient.setQueryData(GET_ACCOUNT_ME_QUERY_KEY, response);

    if (response.data.payload.results?.display_subscription_offer_popup) {
      setShowSubModal(true);
    } else {
      localStorage.setItem("lastOnboardingTime", Date.now().toString());
      saveAndClose();
    }
  };

  const goToNextStep = async () => {
    if (activeStep === ONBOARDING_STEP_MAP.comic_facets) {
      await apiClient.post("/v1/account/onboarding/complete", {
        meta: [],
        payload: {},
      });

      const response = await getAccountMe(attributionQueryParams);
      queryClient.setQueryData(GET_ACCOUNT_ME_QUERY_KEY, response);

      if (response.data.payload.results?.display_subscription_offer_popup) {
        setShowSubModal(true);
      } else {
        setActiveStep(ONBOARDING_STEP_MAP.splash);
      }
    } else if (activeStep === ONBOARDING_STEP_MAP.splash) {
      saveAndClose();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const isSmall = useIsSmallBreakpoint();

  const isSmallProps: CSSProperties = isSmall
    ? {
        background: "rgba(20, 21, 23, 0.80)",
        backdropFilter: "blur(18px)",
      }
    : {};

  if (showSubModal) {
    return (
      <Box maxWidth="558px">
        <SubscriptionModalBody
          setFunnelType={setFunnelType}
          context={"intro-offer"}
          onSuccess={() => {
            setActiveStep(ONBOARDING_STEP_MAP.splash);
            setShowSubModal(false);
          }}
          onClose={() => {
            localStorage.setItem("lastOnboardingTime", Date.now().toString());
            setActiveStep(ONBOARDING_STEP_MAP.splash);
            setShowSubModal(false);
          }}
        />
      </Box>
    );
  }

  const showSplash = activeStep === ONBOARDING_STEP_MAP.splash;
  return (
    <Flex direction="column" width="100%" overflow="none">
      {
        <Flex
          flexDirection="column"
          position="fixed"
          width="100%"
          left="0"
          top="0"
          p="24px"
          pl={isSmall ? "24px" : "48px"}
          pb={isSmall ? 0 : undefined}
          justifyContent="space-between"
          zIndex={1}
          style={{ ...isSmallProps }}
        >
          {isSmall ? null : (
            <Flex flexDirection="row" justifyContent="flex-end" width="100%">
              <Button
                alignSelf="center"
                variant="iconOnly"
                onClick={showSplash ? goToNextStep : skipOnboarding}
                minWidth={0}
              >
                <CloseIcon alignSelf="center" color="dune.100" boxSize="24px" />
              </Button>
            </Flex>
          )}
          {showSplash ? null : (
            <>
              <Flex gap="16px">
                {Array.from({ length: 3 }, (_, index) => (
                  <Box
                    key={index}
                    as="button"
                    maxWidth={isSmall ? undefined : "92px"}
                    flex={1 / 3}
                    height="1px"
                    borderBottom="2px solid"
                    borderColor={
                      index <= activeStep ? "blaze.blaze" : "neutral.500"
                    }
                    _hover={{
                      borderColor:
                        index <= activeStep ? "blaze.300" : "neutral.300",
                    }}
                    transition="border-color .25s linear"
                    onClick={() => setActiveStep(index)}
                  />
                ))}
              </Flex>
              {isSmall && (
                <Button
                  id="btnOnboardingSkip"
                  variant="iconOnly"
                  onClick={skipOnboarding}
                  alignSelf="flex-end"
                  mt="8px"
                >
                  <Text variant="onboardingSkip">
                    {t("components.onboarding.skip")}
                  </Text>
                </Button>
              )}
            </>
          )}
        </Flex>
      }
      <Box
        key={activeStep}
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition=".5s linear"
        p={isSmall ? 0 : "24px"}
        paddingTop={showSplash ? 0 : isSmall ? "62px" : "86px"}
        paddingBottom={showSplash ? 0 : isSmall ? "96px" : "16px"}
        minHeight={
          showSplash && isSmall
            ? "calc(100vh - 48px)"
            : isSmall
              ? undefined
              : "540px"
        }
        display="flex"
        flexDirection="column"
        gap={showSplash ? 0 : isSmall ? "24px" : undefined}
        overflowY="auto"
      >
        <OnboardingStepTitles activeStep={activeStep} />
        <Flex flexGrow={1} alignItems="center">
          <OnboardingContent activeStep={activeStep} onSuccess={goToNextStep} />
        </Flex>
      </Box>
      {!showSplash ? (
        <OnboardingNavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          goToNextStep={goToNextStep}
          skipOnboarding={skipOnboarding}
        />
      ) : null}
    </Flex>
  );
}

function OnboardingNavigationButtons({
  activeStep,
  setActiveStep,
  goToNextStep,
  skipOnboarding,
}: {
  activeStep: number;
  setActiveStep: (step: number) => void;
  goToNextStep: () => void;
  skipOnboarding: () => void;
}) {
  const isSmall = useIsSmallBreakpoint();

  const isSmallProps: CSSProperties = isSmall
    ? {
        position: "fixed",
        left: 0,
        bottom: 0,
        height: "96px",
        padding: "24px",
        background: "rgba(20, 21, 23, 0.80)",
        backdropFilter: "blur(18px)",
        boxShadow: "0px 4px 24px 0px var(--chakra-colors-transparent-black-40)",
        borderTop: "1px solid var(--chakra-colors-transparent-white-20)",
      }
    : {};
  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      style={{ ...isSmallProps }}
    >
      <Flex gap="16px" width="100%">
        {activeStep > 0 && (
          <Button
            variant="tertiary"
            size="lg"
            onClick={() => setActiveStep(activeStep - 1)}
          >
            {t("components.onboarding.back")}
          </Button>
        )}
        <Button
          id="btnOnboardingNext"
          variant="primary"
          size="lg"
          onClick={goToNextStep}
          display="flex"
          flexGrow={isSmall ? 1 : undefined}
        >
          {activeStep === ONBOARDING_STEP_MAP.comic_facets
            ? t("components.onboarding.finish")
            : t("components.onboarding.continue")}
        </Button>
      </Flex>
      {isSmall ? null : (
        <Button
          id="btnOnboardingSkip"
          variant="iconOnly"
          onClick={skipOnboarding}
        >
          <Text variant="onboardingSkip">
            {t("components.onboarding.skip")}
          </Text>
        </Button>
      )}
    </Flex>
  );
}
