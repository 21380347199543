import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ReleaseContentCardProps } from "../../types";
import { ContentCardActions } from "./components/ContentCardActions";
import { FreeRibbon } from "./components/FreeRibbon";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { CustomListCard } from "./CustomListCard";

export function ReleaseListCard({
  content,
  layout: { display, style },
  fixedHeight,
  fullWidth,
  activeBorder,
}: ReleaseContentCardProps & {
  fullWidth?: boolean;
  fixedHeight?: number;
  activeBorder?: boolean;
}) {
  const layout_size = style.size;
  const isSmall = layout_size === "s";
  let dimensions = useGridCardDimensions("releases", style.mode, layout_size);

  const height = fixedHeight || dimensions.height;

  if (isSmall) {
    return (
      <SmallReleaseListCard
        width={dimensions.width}
        height={height}
        content={content}
        layout={{ display, style }}
      />
    );
  }

  return (
    <CustomListCard
      width={dimensions.width}
      height={height}
      fullWidth={fullWidth}
      content={{
        ...content,
        image_url: display.include_cover_image
          ? content.thumbnail_url
          : undefined,
        title: display.include_name ? content.localized_comic_name : undefined,
        secondTitle: display.include_title ? content.title : undefined,
        subTitle: content.artist?.roman_name,
        description: display.include_description
          ? content.description
          : undefined,
        is_free: display.include_ribbon_badge ? content.is_free : false,
        share_url: display.include_share ? content.share_url : undefined,
        total_favorites: content.comic.total_favorites,
        total_published_releases: content.comic.total_published_releases,
        total_published_pages: content.page_count,
        relevance_match: content.comic.relevance_match,
      }}
      display={{ ...display }}
      activeBorder={activeBorder}
    />
  );
}

export function SmallReleaseListCard({
  content,
  width,
  height,
  layout: { display },
}: ReleaseContentCardProps & {
  height: number;
  width: number;
}) {
  const {
    include_name,
    include_author,
    include_owner_name,
    include_cover_image,
    include_title,
  } = display;

  return (
    <Flex width={width} height={height} direction="row" alignItems="flex-end">
      <Flex
        borderLeftRadius={64}
        borderRightRadius={8}
        width="100%"
        height="calc(100% - 10px)"
        position="relative"
        background="charcoal.900"
        gap={4}
        alignItems="center"
        pl={7}
      >
        <Flex direction="column" justifyContent="center">
          <Box position="relative" bottom="16px" pl={2}>
            {include_cover_image ? (
              <Image
                src={content.thumbnail_url}
                height="65px"
                w="42px"
                minWidth="42px"
                position="relative"
                borderRadius={10}
                loading="lazy"
                alt={`Cover image for ${content.localized_comic_name}`}
              />
            ) : null}
            {content.comic.is_free ? (
              <FreeRibbon
                color="success.500"
                position="absolute"
                top="-1px"
                left="-1px"
              />
            ) : null}
          </Box>
        </Flex>
        <Flex direction="column" flex={1}>
          <Flex
            direction="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box maxWidth="250px">
              {include_name ? (
                <Text variant="cardSubtitle">
                  {content.localized_comic_name}
                </Text>
              ) : null}
              {include_title ? (
                <Text variant="cardSubtitle" noOfLines={1}>
                  {content.title}
                </Text>
              ) : null}
              {include_owner_name || include_author ? (
                <Text textColor="neutral.200" variant="cardVitals">
                  {content.artist?.roman_name}
                </Text>
              ) : null}
            </Box>
            <Box marginRight="16px">
              <ContentCardActions showFollow={false} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
