import { hookstate, useHookstate } from "@hookstate/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  GET_ACCOUNT_ME_QUERY_KEY,
  getAccountMe,
} from "./components/auth/api/authApi";
import { apiClient } from "./services/axiosInstance";
import {
  ComicFacetGroupOptionEntity,
  ComicUserReadingStatusEntity,
  InitResponsePayloadResults,
} from "./services/gc/types";
import { LayoutScaleProps, ServerResponse } from "./types";
import { useAttributionParams } from "./hooks/useAttributionParams";
import { useMixpanelRecorder } from "./screens/reader/hooks/useMixpanel";
import { useToast } from "@chakra-ui/react";

type AppState = {
  init: InitResponsePayloadResults;
  facetGroupMap: Record<number, ComicFacetGroupOptionEntity>;
  countryFlagMap: Record<string, string>;
  readingStatusMap: Record<number, ComicUserReadingStatusEntity>;
};

type CardLayoutState = {
  comics: {
    calendar_c_1: LayoutScaleProps;
    r_list: LayoutScaleProps;
  };
};

// Used for state that should be shared across screens
export const appStore = hookstate<AppState>({
  init: {} as InitResponsePayloadResults,
  facetGroupMap: {},
  countryFlagMap: {},
  readingStatusMap: {},
});

export const initialLoadState = hookstate<{
  isInitialLoad: boolean;
}>({
  isInitialLoad: true,
});

export const cardLayoutStore = hookstate<CardLayoutState>({
  comics: {
    calendar_c_1: { scaleY: 1, scaleX: 1 },
    r_list: { scaleY: 1, scaleX: 1 },
  },
});

export const ACCOUNT_INIT_QUERY_KEY = ["ACCOUNT_INIT"];

export function useHydrateUserAccount() {
  const queryClient = useQueryClient();
  const attributionQueryParams = useAttributionParams();
  const initData = useHookstate(appStore.init).get();
  const toast = useToast();

  const { isLoading, data, error } = useQuery({
    enabled: !!initData.app_urls,
    queryKey: ACCOUNT_INIT_QUERY_KEY,
    queryFn: () => getAccountMe(attributionQueryParams),
    refetchOnWindowFocus: false,
  });

  const recordMixpanel = useMixpanelRecorder();
  useEffect(() => {
    if (!isLoading && data && !error) {
      queryClient.setQueryData(GET_ACCOUNT_ME_QUERY_KEY, data);
      window.gtag("config", window.gc.global.config.ga4_id, {
        user_id: data.data.payload.results.user?.id,
      });
      recordMixpanel(data.data.payload.results);

      const lastMessage = data.data.payload.results.flash_messages?.pop();
      if (lastMessage) {
        toast({
          title: lastMessage.content,
          status:
            lastMessage.type_display == "information"
              ? "info"
              : lastMessage.type_display,
        });
      }
    }
  }, [isLoading, data]);
}

export function useHydrateAppStore() {
  const attributionQueryParams = useAttributionParams();

  const { isLoading, data, error } = useQuery({
    networkMode: "offlineFirst",
    queryKey: ["APP_INIT"],
    refetchOnWindowFocus: false,
    queryFn: () =>
      apiClient.get<ServerResponse<InitResponsePayloadResults>>(
        `/v1/app/init`,
        {
          params: {
            ...attributionQueryParams,
          },
        },
      ),
  });

  useEffect(() => {
    if (!isLoading && data && !error) {
      appStore.init.set(data?.data.payload.results);

      // Generate facet group map
      const facetGroupMap: AppState["facetGroupMap"] = {};
      data?.data.payload.results.comic_facet_groups.forEach((group) => {
        group.comic_facet_group_options.forEach((option) => {
          facetGroupMap[option.id] = option;
        });
      });

      // Generate country flag map
      const countryFlagMap: AppState["countryFlagMap"] = {};
      data?.data.payload.results.countries.forEach((country) => {
        countryFlagMap[country.id] = country.flag_url;
      });

      // Generate reading status map
      const readingStatusMap: AppState["readingStatusMap"] = {};
      data?.data.payload.results.comic_user_reading_statuses.forEach(
        (readingStatus) => {
          readingStatusMap[readingStatus.id] = readingStatus;
        },
      );

      appStore.facetGroupMap.set(facetGroupMap);
      appStore.countryFlagMap.set(countryFlagMap);
      appStore.readingStatusMap.set(readingStatusMap);
    }
  }, [isLoading, data]);
}
