import { useQuery } from "@tanstack/react-query";
import { Article, Artist, Comic, ContentCardProps, Release } from "../../types";
import { ComicCard } from "./ComicCard";
import { ArtistCard } from "./ArtistCard";
import { NewsCard } from "./NewsCard";
import { ReleaseCard } from "./ReleaseCard";
import { useToggleComicFollow } from "./hooks/useToggleComicFollow";
import { useToggleArtistFollow } from "./hooks/useToggleArtistFollow";

export function ContentCard({
  content,
  layout,
  section,
  fullWidth,
  dontOpenInfo = false,
  index,
  sectionIndex,
}: ContentCardProps) {
  const context = layout.style.context;
  const cacheKey = `${content?.entity_type}-${content?.id}`;

  const { data } = useQuery<Comic | Artist | Article | Release>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    refetchOnWindowFocus: false,
  });

  // Prefer data cached in query client
  const dataToUse = data ?? content;

  /**
   * image_small_url
   * image_medium_url
   * image_tiny_url
   * image_url
   */

  const handleToggleComicFollow =
    useToggleComicFollow(cacheKey).handleToggleFollow;
  const handleToggleArtistFollow = useToggleArtistFollow(cacheKey);

  switch (context) {
    case "comics": {
      const typedContent = dataToUse as Comic;
      typedContent.image_url = getImageUrl(typedContent);
      return (
        <ComicCard
          onToggleFollow={handleToggleComicFollow}
          section={section}
          content={typedContent}
          layout={layout}
          fullWidth={fullWidth}
          dontOpenInfo={dontOpenInfo}
          index={index}
          sectionIndex={sectionIndex}
        />
      );
    }
    case "creators": {
      const typedContent = dataToUse as Artist;
      return (
        <ArtistCard
          section={section}
          onToggleFollow={handleToggleArtistFollow}
          content={typedContent}
          layout={{
            ...layout,
            display: {
              ...layout.display,
              include_flag: layout.style.size !== "s" ? true : false,
            },
          }}
          dontOpenInfo={dontOpenInfo}
        />
      );
    }
    case "news": {
      const typedContent = dataToUse as Article;
      return (
        <NewsCard section={section} content={typedContent} layout={layout} />
      );
    }
    case "releases": {
      const typedContent = dataToUse as Release;
      typedContent.image_url = getImageUrl(typedContent);
      return (
        <ReleaseCard section={section} content={typedContent} layout={layout} />
      );
    }
  }

  console.warn(
    `Unable to determine type of ContentCard by context: ${context}`,
  );
  return null;
}

function getImageUrl(data: Comic | Release) {
  if (data.image_small_url) {
    return data.image_small_url;
  }

  return data.image_url;
}
