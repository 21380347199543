import { Icon, IconProps } from "@chakra-ui/react";

export function CheckIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0815 7.40377C19.2767 7.59903 19.2767 7.91562 19.0815 8.11088L10.5962 16.5962C10.4009 16.7914 10.0843 16.7914 9.88909 16.5962L5.64645 12.3535C5.45118 12.1583 5.45118 11.8417 5.64645 11.6464C5.84171 11.4511 6.15829 11.4511 6.35355 11.6464L10.2426 15.5355L18.3744 7.40377C18.5696 7.20851 18.8862 7.20851 19.0815 7.40377Z"
        fill="currentColor"
      />
    </Icon>
  );
}
