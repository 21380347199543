import { Text, Box, Flex, Link, Image } from "@chakra-ui/react";
import { SsoSection } from "./SsoSection";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import { PaymentValueProps } from "../../payments/components/PaymentValueProps";
import { ImmutableArray } from "@hookstate/core";
import { t } from "i18next";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { FunnelComponentProps } from "../../funnel/types";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { useAccountData } from "../../../hooks/useAccountData";

export function SignUpModalFooter({ setFunnelType }: FunnelComponentProps) {
  return (
    <Text fontSize="sm" color="neutral.300" align="center">
      {t("components.auth.alreadyHaveAnAccount")}{" "}
      <Link
        color="neutral.200"
        textDecoration="underline"
        onClick={() => {
          setFunnelType(FUNNEL_TYPES.login);
        }}
      >
        {t("components.auth.login")}
      </Link>
    </Text>
  );
}

export function SignUpModalBody({
  onSuccess,
  setFunnelType,
  valueProps,
  welcomeText = t("components.auth.welcomeText.welcomeToGlobalComix"),
  invalidate = true,
  triggeredByStartNextRelease = false,
  showGraphic = false,
  onClose,
}: FunnelComponentProps & {
  valueProps: ImmutableArray<string>;
  welcomeText?: string;
  invalidate?: boolean;
  spinning?: boolean;
  triggeredByStartNextRelease?: boolean;
  showCreateFreeAccountButton?: boolean;
  showGraphic?: boolean;
}) {
  const releaseQueryKey = useReleaseDataQueryKey();
  const accountData = useAccountData();
  const isSmall = useIsSmallBreakpoint();
  const invalidateQueries = useInvalidateQueries(
    ACCOUNT_INIT_QUERY_KEY,
    releaseQueryKey as string[],
  );

  const isAccountCreationCTATestEnabled =
    !!accountData?.features["create_account_cta"] &&
    triggeredByStartNextRelease;

  return (
    <Flex direction="column" margin="auto" width="100%">
      {showGraphic && (
        <Image
          width={{ base: "100%", md: "80%", lg: "465px" }}
          src={`${window.gc.global.assets_url}/static/images/web-ui/signup_modal_image.png`}
          alignSelf="center"
          mb="24px"
        />
      )}
      {welcomeText && (
        <>
          <Text
            fontFamily="Bricolage Grotesque"
            fontWeight={showGraphic ? "600" : "500"}
            lineHeight="31.2px"
            fontSize="24px"
            textAlign={isSmall || showGraphic ? "center" : undefined}
            color="dune.dune"
            maxWidth={showGraphic ? "365px" : undefined}
            alignSelf={showGraphic ? "center" : undefined}
          >
            {welcomeText}
          </Text>
          <Box height="24px" />
        </>
      )}

      {triggeredByStartNextRelease && (
        <Box
          width="100%"
          height="1px"
          background="transparent.white.10"
          mb="24px"
        />
      )}

      {valueProps && valueProps.length > 0 && (
        <>
          <Box alignSelf={isSmall ? "center" : undefined}>
            <PaymentValueProps valueProps={valueProps} showSeparators={false} />
          </Box>
          <Box height="24px" />
          <Box width="100%" height="1px" background="transparent.white.10" />
          <Box height="24px" />
        </>
      )}
      <SsoSection
        setFunnelType={setFunnelType}
        onClose={onClose}
        triggeredByStartNextRelease={triggeredByStartNextRelease}
        isAccountCreationCTATestEnabled={isAccountCreationCTATestEnabled}
        onAuthenticationSuccess={() => {
          if (invalidate) invalidateQueries();
          onSuccess();
        }}
      />
    </Flex>
  );
}
