import { Button, ButtonProps, Flex, Text } from "@chakra-ui/react";
import { cloneElement, forwardRef } from "react";

interface CTAButtonProps extends ButtonProps {
  text?: string;
  icon?: JSX.Element;
  active?: boolean;
}
export const CTAButton = forwardRef<HTMLButtonElement, CTAButtonProps>(
  ({ text, icon, active, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant={active ? "tertiary" : "secondary"}
        size="thin"
        w="full"
        onClick={(e) => {
          props?.onClick?.(e);
        }}
      >
        <Flex direction="row" gap="8px" alignItems="center">
          {icon && cloneElement(icon, { width: "14px", height: "14px" })}
          {text && (
            <Text
              fontWeight="700"
              fontSize="10px"
              lineHeight="11px"
              textTransform="uppercase"
              fontFamily="Roboto"
            >
              {text}
            </Text>
          )}
        </Flex>
      </Button>
    );
  },
);
