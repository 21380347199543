import { Button, Text } from "@chakra-ui/react";
import { AddBubbleIcon } from "../icons/AddBubbleIcon";
import { AddedBubbleIcon } from "../icons/AddedBubbleIcon";

export interface ComicInterestSelectorProps {
  title: string;
  isSelected?: boolean;
  fullWidth?: boolean;
  onClick: () => void;
}
export function ComicInterestSelector({
  title,
  isSelected = false,
  fullWidth = false,
  onClick,
}: ComicInterestSelectorProps) {
  return (
    <Button
      mr="12px"
      width={fullWidth ? "100%" : "193px"}
      height="42px"
      gap="16px"
      background="transparent.black.40"
      borderRadius="200px"
      border="2px solid"
      borderColor={isSelected ? "success.300" : "transparent.white.10"}
      padding="8px"
      paddingLeft="24px"
      userSelect="none"
      onClick={onClick}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text
        fontSize="16px"
        fontWeight="500"
        color="neutral.300"
        fontFamily="Bricolage Grotesque"
        lineHeight="24px"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {title}
      </Text>
      {isSelected ? (
        <AddedBubbleIcon boxSize="24px" color="success.300" />
      ) : (
        <AddBubbleIcon boxSize="24px" color="neutral.500" />
      )}
    </Button>
  );
}
