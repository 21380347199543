import { apiClient } from "../../../services/axiosInstance";
import { ServerSingleResponse } from "../../../types";

export function postResendAccountVerificationEmail() {
  return apiClient.post<ServerSingleResponse<any>>(
    "/v1/account/resend-confirmation",
    { meta: [], payload: {} },
  );
}
export const RESEND_ACCOUNT_VERIFICATION_EMAIL_KEY = [
  "resend-account-verification-email",
];
