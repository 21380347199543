import { Flex, Text } from "@chakra-ui/react";
import { SealCheckIcon } from "../../icons/SealCheckIcon";
import { useTranslation } from "react-i18next";

export interface DiscountBannerProps {
  price: number;
  goldPrice: number;
  isGold: boolean;
  fontSize?: string;
}

export function DiscountBanner({
  price,
  goldPrice,
  isGold,
  fontSize = "12px",
}: DiscountBannerProps) {
  const { t } = useTranslation();
  const discount = Math.round(((price - goldPrice) / price) * 100);

  return (
    <Flex flexDirection="row" gap="8px" alignItems="center" width="100%">
      <SealCheckIcon boxSize="24px" color="blaze.blaze" />
      <Text
        fontFamily="Roboto"
        fontWeight="500"
        fontSize={fontSize}
        lineHeight="150%"
        color="neutral.400"
      >
        {isGold
          ? t("components.slideInMenu.releaseInfoMenu.youSavedAsAGoldMember", {
              discount,
            })
          : t("components.slideInMenu.releaseInfoMenu.getWithAGoldMembership", {
              discount,
            })}
      </Text>
    </Flex>
  );
}
