import { Flex } from "@chakra-ui/react";
import { PageThumbnail } from "./PageThumbnail";
import { useReleasePagesData } from "../../hooks/hookstate/useReleasePagesData";
import { useReadingDirection } from "../../hooks/useReadingDirection";
import { useReleaseData } from "../../hooks/hookstate/useReleaseData";
import { AllPagesBottomBar } from "./AllPagesBottomBar";
import { useEffect, useState } from "react";
import { Page } from "../../../../types";
import { useReaderNavigation } from "../../hooks/useReaderNavigation";

export function AllPagesMenu() {
  const { comicIsVertical } = useReadingDirection();
  const { releasePagesData } = useReleasePagesData();
  const { releaseData } = useReleaseData();
  const { activePage, goToPage } = useReaderNavigation();

  const [pages, setPages] = useState<Page[]>([]);
  useEffect(() => {
    if (!releasePagesData) return;
    setPages(releasePagesData?.slice(0, activePage + 10) || []);
  }, [releasePagesData]);

  const showMorePages = () => {
    if (!releasePagesData) return;
    setPages(releasePagesData?.slice(0, pages.length + 10) || []);
  };

  return (
    <>
      <Flex
        p="24px"
        width="100%"
        height="100%"
        overflowY="auto"
        flexDirection={comicIsVertical ? "column" : "row"}
        flexFlow={comicIsVertical ? undefined : "wrap"}
        position="relative"
        justifyContent={comicIsVertical ? "flex-start" : "space-between"}
        alignItems={comicIsVertical ? "center" : "flex-start"}
        paddingTop="96px"
        paddingBottom="96px"
        onScroll={(e) => {
          const is500pxAboveScrollBottom =
            e.currentTarget.scrollHeight -
              (e.currentTarget.scrollTop + e.currentTarget.clientHeight) <=
            500;

          if (is500pxAboveScrollBottom) {
            showMorePages();
          }
        }}
      >
        {pages.length &&
          pages.map((page) => (
            <PageThumbnail
              page={page}
              blurIfPaid={releaseData?.paid_access_thumbnail_blur}
              goToPage={goToPage}
              activePage={activePage}
            />
          ))}
      </Flex>
      <AllPagesBottomBar />
    </>
  );
}
