import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { ComicContributorTypeEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { browseFilterStore } from "./BrowseFilters";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const COMIC_CONTRIBUTOR_TYPE_ALL = "0";

interface ComicContributorTypeFilterProps {
  value: Immutable<string | undefined>;
  comicContributorTypes: ComicContributorTypeEntity[];
  onChange: (nextValue: string) => void;
}
export function ComicContributorTypeFilter({
  value,
  comicContributorTypes,
  onChange,
}: ComicContributorTypeFilterProps) {
  const categoryOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: COMIC_CONTRIBUTOR_TYPE_ALL },
      ...comicContributorTypes.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <RadioFilter value={value} options={categoryOptions} onChange={onChange} />
  );
}

interface ComicContributorTypeFilterTitleProps {
  isTrigger: boolean;
  comicContributorTypes: ComicContributorTypeEntity[];
  open: boolean;
}
export const ComicContributorTypeFilterTitle = forwardRef(
  (
    {
      isTrigger,
      comicContributorTypes,
      open,
      ...props
    }: ComicContributorTypeFilterTitleProps,
    ref,
  ) => {
    const comicContributorTypeState =
      browseFilterStore.comicContributorType.get();
    const comicContributorType = comicContributorTypes.find(
      (type) => `${type.id}` == comicContributorTypeState,
    );
    if (isTrigger) {
      return (
        <FilterTagButton
          popoverRef={ref}
          text="Contributor"
          isFiltering={comicContributorType?.name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Contributor"
        filterValue={comicContributorType?.name}
        open={open}
      />
    );
  },
);
