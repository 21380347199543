import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    color: "transparent.white.80",

    _placeholder: {
      color: "transparent.white.80",
    },
  },
});

const variants = {
  outline: definePartsStyle({
    field: {
      borderColor: "transparent.white.20",
      _hover: {
        borderColor: "transparent.white.40",
      },
      _focus: {
        borderColor: "transparent.white.40",
      },
    },
  }),
};

export const inputTheme = defineMultiStyleConfig({ baseStyle, variants });
