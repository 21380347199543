import { useHookstate } from "@hookstate/core";
import { FunnelConfigTypes, rootComponentsStore } from "../layouts/RootLayout";
import { GCModal } from "../modals/GCModal";
import { useFunnel } from "./hooks/useFunnel";
import { FUNNEL_TYPES } from "./utils/contants";
import { FunnelContextArea } from "./types";

export function setFunnelModalConfig(config?: FunnelConfigTypes) {
  if (!config) {
    rootComponentsStore.funnelModal.set({});
    return;
  }
  rootComponentsStore.funnelModal.set(config);
}

export function FunnelModal() {
  const onClose = () => {
    setFunnelModalConfig();
  };

  const type = useHookstate(rootComponentsStore.funnelModal.type).get({
    noproxy: true,
  });
  const options = useHookstate(rootComponentsStore.funnelModal.options).get({
    noproxy: true,
  });

  const {
    onClose: onModalClose,
    funnelHeader,
    funnelBody,
    funnelFooter,
  } = useFunnel(onClose, setFunnelModalConfig, type, {
    ...options,
    isModal: true,
  });

  return (
    <GCModal
      isOpen={!!type}
      maxWidth={type === FUNNEL_TYPES.onboarding ? "900px" : "558px"}
      minWidth="558px"
      onClose={onModalClose}
      header={funnelHeader}
      body={funnelBody}
      footer={funnelFooter}
      locked={options?.locked}
      trapFocus={options?.trapFocus}
      dividerPadding={type === FUNNEL_TYPES.pdf ? "24px" : undefined}
      innerPadding={type === FUNNEL_TYPES.addToLibrary ? "0px" : undefined}
      hideDivider={
        type === FUNNEL_TYPES.signup &&
        options?.modalContext === FunnelContextArea.startNextReleaseButton
      }
    />
  );
}
