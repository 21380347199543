import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

export function LiveIndicator({ animate = true }: { animate?: boolean }) {
  return (
    <Flex position="relative" width="fit-content" justifyContent="center">
      <Flex
        zIndex={0}
        as={motion.div}
        position="absolute"
        width="0px"
        height="100%"
        margin="auto"
        opacity={0}
        borderRadius="8px"
        borderLeft="2px solid"
        borderRight="2px solid"
        borderColor="error.300"
        animate={
          animate
            ? {
                width: [0, "100%"],
                opacity: [0, 1, 1, 0],
                transition: {
                  duration: 1.25,
                  repeat: Infinity,
                  ease: "linear",
                },
              }
            : undefined
        }
      />
      <Flex
        zIndex={1}
        height="21px"
        as={motion.div}
        opacity={1}
        padding="4px 10px"
        margin={animate ? "0px 20px" : 0}
        bg="error.300"
        bgGradient="radial(var(--chakra-colors-error-400) 0%, transparent 75%)"
        borderRadius="8px"
        animate={{
          backgroundSize: ["100% 100%", "200% 200%", "100% 100%"],
          transition: {
            duration: 2.5,
            repeat: Infinity,
            ease: "linear",
          },
        }}
      >
        <Text variant="monospaceTime">LIVE</Text>
      </Flex>
    </Flex>
  );
}
