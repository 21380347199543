import { useCallback, useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { EmblaCarouselType } from "embla-carousel";
import { CaretRightLargeIcon } from "../icons/CaretRightLargeIcon";
import { CaretLeftLargeIcon } from "../icons/CaretLeftLargeIcon";

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  onButtonClick?: (emblaApi: EmblaCarouselType) => void,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
};

const buttonSize = {
  base: "22px",
  "2xl": "24px",
  "3xl": "31px",
  "4xl": "46px",
};

export const PrevButton: React.FC<PropType> = ({ children, ...props }) => {
  return (
    <Button
      variant="hoverBlazeIcon"
      minW="auto"
      w={buttonSize}
      h={buttonSize}
      p={0}
      isDisabled={props.disabled}
      {...props}
    >
      <CaretLeftLargeIcon w="100%" h="100%" color="neutral.300" />
      {children}
    </Button>
  );
};

export const NextButton: React.FC<PropType> = ({ children, ...props }) => {
  return (
    <Button
      variant="hoverBlazeIcon"
      minW="auto"
      w={buttonSize}
      h={buttonSize}
      p={0}
      isDisabled={props.disabled}
      {...props}
    >
      <CaretRightLargeIcon w="100%" h="100%" color="neutral.300" />
      {children}
    </Button>
  );
};
