import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { menuTitles } from "../../screens/reader/components/menuTitles";
import { rootComponentsStore } from "../layouts/RootLayout";
import { useHookstate } from "@hookstate/core";
import { SettingsMenu } from "../../screens/reader/components/settingsMenu/SettingsMenu";
import { AllPagesMenu } from "../../screens/reader/components/allPagesMenu/AllPagesMenu";
import { AddToMenuWrapper } from "../../screens/reader/components/addToMenu/AddToMenu";
import { CommentsMenuWrapper } from "../../screens/reader/components/commentsMenu/CommentsMenu";
import { CloseCircleIcon } from "../icons/CloseIcon";
import { ArtistProfile } from "../../screens/artist/ArtistProfile";
import { NAVBAR_HEIGHT, SLIDE_IN_MENUS } from "../../screens/root/constants";
import { ComicDetails } from "./comicDetails/ComicDetails";
import { ReleaseInfoMenu } from "./releaseInfo/ReleaseInfoMenu";

interface SlideInMenuProps {
  isTopBarHidden?: boolean;
  openMenu: string | undefined;
  onCloseMenu: () => void;
}

export function SlideInMenu({
  isTopBarHidden = false,
  openMenu,
  onCloseMenu,
}: SlideInMenuProps) {
  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const inViewAllSection = window.location.pathname.includes("cms/");

  const isSlidingUp = !inReader; // Use bottom-to-top animation when not in reader

  return (
    <Box
      id="slideInMenu"
      position="fixed"
      width={isSlidingUp ? "600px" : "400px"}
      maxWidth="100%"
      height={isSlidingUp ? "full" : `calc(100% - ${NAVBAR_HEIGHT}px)`}
      background="transparent"
      zIndex={!inReader && !inViewAllSection ? 2 : 1401}
      left={isSlidingUp ? "50%" : "auto"}
      bottom={isSlidingUp ? (openMenu ? "0" : "-100%") : "auto"}
      right={isSlidingUp ? "auto" : openMenu ? 0 : -400}
      top={isSlidingUp ? "auto" : isTopBarHidden ? 0 : NAVBAR_HEIGHT + "px"}
      transition={
        isSlidingUp
          ? "bottom 0.3s ease-out, height 0.2s ease-out"
          : "right .2s linear, top .2s ease-out, height .2s ease-out"
      }
      style={isSlidingUp ? { transform: "translateX(-50%)" } : {}}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        position="relative"
        maxWidth="inherit"
        height="calc(100% - 20px)"
        margin="10px"
        background="charcoal.charcoal"
        backdropFilter="blur(12px)"
        transition="right .15s linear"
        transitionDelay={openMenu ? ".1s" : "0"}
        overflow="hidden"
        borderRadius="16px"
        border="1px solid var(--White-Transparency-White-20, rgba(255, 255, 255, 0.20))"
      >
        <Flex
          position="absolute"
          top={0}
          left={0}
          zIndex={2}
          borderBottom="1px solid"
          borderColor="transparent.white.10"
          p="16px 24px "
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          background="#141517CC"
          backdropFilter="blur(16px)"
          boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
          style={{ scrollbarWidth: "thin" }}
        >
          <Text
            fontSize="20px"
            fontWeight="600"
            lineHeight="26px"
            fontFamily="Bricolage Grotesque"
            color="dune.dune"
            width="100%"
          >
            {openMenu && menuTitles?.[openMenu]}
          </Text>
          <Button
            variant="iconOnly"
            aria-label="Close the slide in menu"
            onClick={onCloseMenu}
          >
            <CloseCircleIcon w="24px" h="24px" />
          </Button>
        </Flex>
        <Box position="relative" height="100%">
          {openMenu && menus?.[openMenu]}
        </Box>
      </Box>
    </Box>
  );
}

const menus = {
  [SLIDE_IN_MENUS.settings]: <SettingsMenu />,
  [SLIDE_IN_MENUS.allPages]: <AllPagesMenu />,
  [SLIDE_IN_MENUS.addTo]: <AddToMenuWrapper />,
  [SLIDE_IN_MENUS.comments]: <CommentsMenuWrapper />,
  [SLIDE_IN_MENUS.comicDetails]: <ComicDetails />,
  [SLIDE_IN_MENUS.publisher]: <ArtistProfile />,
  [SLIDE_IN_MENUS.releaseInfo]: <ReleaseInfoMenu />,
};
