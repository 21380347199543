import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { t } from "i18next";
import { PaymentValueProps } from "./PaymentValueProps";
import { Release } from "../../../types";
import { ImmutableObject } from "@hookstate/core";
import { FunnelComponentProps } from "../../funnel/types";
import { ReleasePurchaseData } from "./ReleasePurchaseData";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

interface PdfModalBodyProps {
  release?: ImmutableObject<Release>;
}

export function PdfModalBody({
  release,
}: FunnelComponentProps & PdfModalBodyProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  if (!release) return null;

  const {
    display_price,
    is_discounted,
    display_price_discounted,
    allow_variable_max_price,
  } = release.price;

  const pdfPrice = is_discounted ? display_price_discounted : display_price;

  return (
    <Flex flexDirection="column" gap="24px" width={"100%"}>
      <Flex alignSelf="start" flexDirection="row" width="100%">
        <Image
          borderRadius="10px"
          height={isSmallBreakpoint ? "139px" : "215px"}
          aspectRatio={0.645}
          src={release.thumbnail_url}
          alt="Release thumbnail"
          mr={6}
        />
        <Flex flexDirection="column" width="100%">
          <ReleasePurchaseData release={release} />
          <Flex flexDirection="row" width="100%" alignItems={"baseline"}>
            <Text variant="modalTitle" mt="20px" fontWeight={600} mr={2}>
              {!!allow_variable_max_price &&
                t("components.payments.pdf.minimum")}
              {pdfPrice}
            </Text>
            {is_discounted && (
              <Text
                variant="modalBody"
                mt="20px"
                color="blaze.600"
                fontWeight={600}
                textDecoration="line-through"
              >
                {display_price}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Box width="100%" height="1px" background="transparent.white.10" />
      <PaymentValueProps
        valueProps={[
          [
            t("components.payments.pdf.unrestrictedAccess"),
            t("components.payments.pdf.toOnlineReading"),
          ],
          [
            t("components.payments.pdf.unlimitedDownloadAvailability"),
            t("components.payments.pdf.ownerDeletesBooks"),
          ],
        ]}
        alignItems="start"
        showSeparators={false}
      />
    </Flex>
  );
}
