import { Box, Button } from "@chakra-ui/react";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import { t } from "i18next";
import { TrashcanIcon } from "../../../../components/icons/reader/TrashcanIcon";
import { useUserData } from "../../../../hooks/useUserData";
import { SimpleLoginMenu } from "../../../../components/navigation/components/SimpleLoginMenu";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";

interface SubmitButtonProps {
  inLibrary: boolean;
  mutateLibrary: () => void;
  removeFromLibrary: () => void;
}
export function SubmitButton({
  inLibrary,
  mutateLibrary,
  removeFromLibrary,
}: SubmitButtonProps) {
  const { userData } = useUserData();
  const { onCloseMenu } = useSlideInMenu();
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.addToLibrary,
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="16px"
      background="reader.slate.800"
      borderTopRadius="24px"
      gap="8px"
      position="absolute"
      width="100%"
      bottom={0}
      right={0}
    >
      {userData ? (
        <>
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              mutateLibrary();
              onCloseMenu();
            }}
            aria-label={inLibrary ? "Update library status" : "Add to library"}
            flex={1}
            textTransform="capitalize"
          >
            {inLibrary
              ? t("screens.reader.addToMenu.update_status")
              : t("screens.reader.addToMenu.add_to_library")}
          </Button>
          {inLibrary && (
            <Button
              variant="tertiary"
              size="lg"
              onClick={() => {
                removeFromLibrary();
                onCloseMenu();
              }}
              aria-label="Remove from library"
            >
              <TrashcanIcon height="24px" width="24px" color="error.300" />
            </Button>
          )}
        </>
      ) : (
        <SimpleLoginMenu navigationContext={navigationContext} />
      )}
    </Box>
  );
}
