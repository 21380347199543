import { Chat } from "../../components/chat/Chat";
import { ChatSectionType, useChatSchedule } from "../../hooks/useChatSchedule";
import { Button, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChatSchedule } from "../../components/chatSchedule/ChatSchedule";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { BarRightIcon } from "../../components/icons/BarRightIcon";
import { useNavigate, useParams } from "react-router-dom";
import * as Ably from "ably";
import { ChatClient } from "@ably/chat";
import { apiClient } from "../../services/axiosInstance";
import { ServerResponse } from "../../types";
import { useAccountData } from "../../hooks/useAccountData";
import { hookstate } from "@hookstate/core";

const globalChatId = `general-chat-${import.meta.env.DEV ? "dev" : "prod"}`;
export const globalChatEvent = {
  id: globalChatId,
  image: `${window.gc.global.assets_url}/static/images/web-ui/generalChatIcon.png`,
  title: "Hangout",
  subtitle: "Chill and talk comics",
  startTime: 0,
  endTime: Infinity,
};
type AblyTokenData = {
  token: string;
  expires: number;
  issued: number;
  capability: string;
  clientId: string;
};

export const ablyStore = hookstate<{
  realtimeClient?: Ably.RealtimeClient;
  chatClient?: ChatClient;
}>({});

export const SCHEDULE_PADDING_RIGHT = "60px";
export const SCHEDULE_WIDTH = "350px";
export const SCHEDULE_PADDING_Y = "40px";
export function ChatScreen() {
  const accountData = useAccountData();
  const isSmall = useIsSmallBreakpoint();
  const { uuid } = useParams();
  const { rooms, liveEvents, futureEvents, isLoading } = useChatSchedule();

  const [isScheduleOpen, setIsScheduleOpen] = useState(!isSmall);
  const [activeChatSection, setActiveChatSection] = useState<ChatSectionType>();

  const authCallback = async (
    _: Ably.TokenDetails,
    callback: (
      error: Ably.ErrorInfo | null,
      token: AblyTokenData | null,
    ) => void,
  ) => {
    let tokenResults: AblyTokenData;
    try {
      const newTokenResponse = await fetchNewToken();
      tokenResults = newTokenResponse.data.payload.results;
    } catch (error: Ably.ErrorInfo | any) {
      callback(error, null);
      return;
    }
    callback(null, tokenResults);
  };

  useEffect(() => {
    if (!accountData?.features["web_next_chat"]) {
      return;
    }

    const { realtimeClient: oldRealtimeClient } = ablyStore.get();

    if (oldRealtimeClient) {
      ablyStore.set({ realtimeClient: undefined, chatClient: undefined });
    }

    const realtimeClient = new Ably.Realtime({
      authCallback,
    });
    const chatClient = new ChatClient(realtimeClient);

    ablyStore.merge({
      realtimeClient,
      chatClient,
    });

    return () => {
      realtimeClient.close();
    };
  }, [accountData]);

  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    const userData = accountData?.user;
    if (!isLoading) {
      if (uuid) {
        const chatSections = [...rooms, ...liveEvents, ...futureEvents];

        const newChatSection = chatSections.find((chatSection) => {
          if (!userData?.is_admin) {
            if (!userData && chatSection.requires_auth) {
              return false;
            }
            if (!userData?.gold && chatSection.requires_sub) {
              return false;
            }
          }
          return chatSection.uuid === uuid;
        });

        if (!newChatSection) {
          toast({
            title: `The event has ended, more to come. Stay tuned!`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          const defaultRoom = rooms[0];
          navigate(`/chat/${defaultRoom?.uuid}`);
        } else {
          setActiveChatSection(newChatSection);
        }
      } else {
        const defaultRoom = rooms[0];
        if (defaultRoom) {
          navigate(`/chat/${defaultRoom?.uuid}`);
        }
      }
    }
  }, [isLoading, uuid, accountData?.user]);

  return (
    <Flex
      gap="16px"
      height="100%"
      width="100%"
      position="relative"
      margin="auto"
      alignItems="flex-start"
      justifyContent="center"
      py={isSmall ? "0px" : SCHEDULE_PADDING_Y}
      paddingLeft={isSmall ? "0px" : `calc(${SCHEDULE_PADDING_RIGHT} / 2)`}
      paddingRight={isSmall ? "0px" : SCHEDULE_PADDING_RIGHT}
      transition="padding-right 0.5s ease-in-out"
    >
      {activeChatSection && (
        <Chat
          channelId={activeChatSection.ably_channel}
          title={activeChatSection.display_name}
          icon={activeChatSection.image_small_url}
          eventStartTime={activeChatSection.start_time ?? "0"}
          flexDirection="column"
          height="100%"
          width={isSmall ? "100%" : `calc(100% - ${SCHEDULE_WIDTH})`} // 100%"
          maxWidth="768px"
          flexGrow={1}
          button={
            <Button
              p="0px"
              onClick={() => {
                setIsScheduleOpen(!isScheduleOpen);
              }}
              background={isScheduleOpen ? undefined : "transparent"}
            >
              <BarRightIcon boxSize="24px" color="dune.dune" />
            </Button>
          }
        />
      )}
      {rooms && futureEvents && liveEvents && (
        <ChatSchedule
          activeChatSection={activeChatSection}
          isOpen={isScheduleOpen}
          setIsOpen={setIsScheduleOpen}
          rooms={rooms}
          futureEvents={futureEvents}
          liveEvents={liveEvents}
        />
      )}
    </Flex>
  );
}

const fetchNewToken = () => {
  return apiClient.get<ServerResponse<AblyTokenData>>(`/v1/account/ably-token`);
};
