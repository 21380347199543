import { LegalHeader } from "./LegalHeader";
import { Text } from "@chakra-ui/react";
import { LegalLink } from "./LegalLink";
import { ContentDivider } from "./ContentDivider";
import { SectionTitle } from "../../../components/text/SectionTitle";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function PublisherTerms() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const marginBetweenSections = isSmallBreakpoint ? "24px" : "40px";
  const baseUrl = window.location.origin;

  return (
    <>
      <LegalHeader title="Terms of Service For Creators and Publishers" />
      <Text variant="legalContent">
        You can find the general site Terms and Conditions{" "}
        <LegalLink href="/terms-of-use">here</LegalLink>.
      </Text>
      <Text variant="legalContent">
        The following are terms and conditions of a legal agreement between you,
        any members of your team (collectively “you” and “your”), and
        GlobalComix and its affiliates (collectively, “GlobalComix”, “we”, “us”,
        or “our”). These terms and conditions (collectively with GlobalComix'
        standard Terms and Conditions, Privacy Policy, and Content Policy)
        govern your use of your uploaded content on globalcomix.com (the
        “GlobalComix Website” or “Website”), any mobile applications, and the
        services, features, and content we offer (collectively “GlobalComix
        Services” or “Services”).
      </Text>
      <Text variant="legalContent">
        These Services include a hosted ecommerce service, which enables you to
        publish your comics/graphic novel releases on globalcomix.com and
        promote and sell access to that content. Any new features or tools which
        are added to the Services will also be subject to these.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Publisher Conditions
      </SectionTitle>
      <Text variant="legalContent">
        By offering releases to the public via the Services you acknowledge you
        have read, understood, and agree to be bound by these Publisher Services
        and to comply with all applicable laws and regulations. If you do not
        agree with these Publisher Conditions, you should not use the Website or
        Services. To the extent that there is a conflict between these Publisher
        Conditions and the standard GlobalComix Terms and Conditions, Privacy
        Statement, and/or Content Policy, this agreement shall control.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Description of Service
      </SectionTitle>
      <Text variant="legalContent">
        The Publisher Service is a hosted ecommerce service, which enables you
        to publish content on globalcomix.com and promote and sell access to
        your uploaded content. You understand and agree that the Service is
        provided on an AS IS and AS AVAILABLE basis. We do not warrant that the
        use of the Services will be uninterrupted or error free and therefore
        disclaim all responsibility and liability for the availability,
        timeliness, security or reliability of the Service.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Modifications to the Service and Prices
      </SectionTitle>
      <Text variant="legalContent">
        We reserve the right to modify, suspend or discontinue the Service with
        or without notice at any time and without any liability to you. . We
        reserve the right to modify your listings on the Service including
        titles, descriptions, tags, and other elements if we determine that
        doing so is necessary to comply with any of the requirements of these
        Terms. We reserve the right to charge for the Service under any pricing
        structure we designate at our sole discretion. Prices for using the
        Service are subject to change. Notice of such change may be provided at
        any time by posting the changes to the Service on globalcomix.com. We
        shall not be liable to you or to any third party for any modification,
        price change, suspension or discontinuance of the Service. If we
        terminate your account because you have breached these Terms of Service,
        you shall not be entitled to any refund or unpaid revenue related to the
        breach.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Account Terms</SectionTitle>
      <Text variant="legalContent">
        You must be at least eighteen (18) years of age to use this Service. If
        you are under 18, you may use globalcomix.com only with involvement of a
        parent or guardian. You must provide current, accurate identification,
        contact, and other information that may be required as part of the
        registration process and/or continued use of the Service.
      </Text>
      <Text variant="legalContent">
        You are responsible for maintaining the confidentiality of your Service
        password and account, and are responsible for all activities that occur
        there under. We will not be liable for any loss or damage from your
        failure to maintain the security of your account and password. We
        reserve the right to refuse service to or terminate service of anyone at
        any time without notice for any reason, and to withhold any unpaid fees
        if such fees are related to a violation of these or any other
        GlobalComix policies.
      </Text>
      <Text variant="legalContent">
        Your team members must also create individual accounts and be invited by
        you or another member to join your team. Your team members bear the sole
        responsibility of designating access and control to other members of
        your team. GlobalComix shall not have any liability or responsibility
        for any actions or inactions of any member of your team that was added
        in accordance with the Terms of Service GlobalComix is not responsible
        for any content or financial transfers conducted by a member of a
        content team.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Representations and Warranties
      </SectionTitle>
      <Text variant="legalContent">
        You represent and warrant that (a) all of the information provided by
        you to us to participate in the Services is correct and current; and (b)
        you have all necessary right, power and authority to enter into these
        Terms of Service and to perform the acts required of you hereunder.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Proper Use</SectionTitle>
      <Text variant="legalContent">
        You agree that you will use the Service in compliance with all
        applicable laws, rules and regulations. You shall not, shall not agree
        to, and shall not authorize or encourage any third party to: (i) use the
        Service to upload, promote, sell, transmit or otherwise distribute any
        design or other content that is abusive, harassing, threatening,
        defamatory, obscene, fraudulent, deceptive, misleading, offensive,
        pornographic, illegal or otherwise unlawful; or (ii) upload, promote,
        sell, transmit or otherwise distribute designs or other content that
        infringe upon another party's intellectual property rights or other
        proprietary or contractual rights or obligations. All uploads to the
        Service must comply with the GlobalComix Content Policy.
      </Text>
      <Text variant="legalContent">
        You agree to comply with the mature content tagging requirements of the{" "}
        <LegalLink href="/content-policy">GlobalComix Content Policy</LegalLink>
        .
      </Text>
      <Text variant="legalContent">
        Violation of any of the foregoing may result in immediate termination of
        these Terms of Service. We further expressly preserve the right to
        monitor your designs and other content and to remove, in our own
        discretion, any design or other content from your publisher profile, as
        well as the publisher profile itself.
      </Text>
      <Text variant="legalContent">
        Notwithstanding the above, we have no obligation to review your designs
        or other content and can in no way be held responsible for such content.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Orders Placed Via the Service
      </SectionTitle>
      <Text variant="legalContent">
        By uploading content in your publisher portal and setting it's access
        settings to "paid access", or any variation of paid access that we
        designate, you allow us and grant us the right to sell access to your
        content to any customer in the geographic regions you specify.
      </Text>
      <Text variant="legalContent">
        You acknowledge that in order to provide a consistent experience for
        customers, any and all orders for the access to your content placed via
        your publisher portal shall be solely governed by our general terms and
        conditions. You further acknowledge that this consistency of customer
        experience serves to benefit you as a publisher. No additional terms
        included by you shall be binding upon customers or GlobalComix.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Pricing your Designs
      </SectionTitle>
      <Text variant="legalContent">
        When you upload content to GlobalComix, we will provide you a standard
        price that includes our fees for any structure that we designate, except
        for transaction fees, for selling access to the content and fulfilling
        the order made via our website. We determine the final price for the
        access to the content displayed to customers on the{" "}
        <LegalLink href={baseUrl + "/buy-access"}>buy access credits</LegalLink>{" "}
        page, crowdfunding page,{" "}
        <LegalLink href="/gold">subscription page</LegalLink>, or Superfan page,
        or any other service we designate. When users access your paid content,
        we keep the agreed on revenue share % for each pricing structure we
        designate and send you the rest as earned income. The agreed on revenue
        shares % are displayed permanently in your publisher portal settings. An
        additional transaction fee (the “Payout Fee”) will be charged on all
        earned income and reflected in your income payable statement in the
        publisher portal.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Payout Terms</SectionTitle>
      <Text variant="legalContent">
        INTERNATIONALLY GLOBALCOMIX EXCLUSIVELY USES PAYPAL TO TRANSFER INCOME
        PAYMENTS TO PUBLISHERS. DO NOT OPEN A PUBLISHER ACCOUNT IF YOU LIVE IN A
        COUNTRY WHERE PAYPAL WILL NOT ALLOW YOU TO RECEIVE FUNDS FROM THE UNITED
        STATES. GLOBALCOMIX WILL NOT PAY OR HOLD INCOME ON SALES FOR PUBLISHERS
        WITHOUT A PAYPAL ACCOUNT ABLE TO RECEIVE FUNDS FROM THE UNITED STATES.
        BY AGREEING TO THESE TERMS YOU AGREE TO FORFEIT ANY INCOME EARNED BUT
        NOT PAYABLE DUE TO AN INELIGIBLE PAYPAL ACCOUNT.
      </Text>
      <Text variant="legalContent">
        IN THE UNITED STATES, GLOBALCOMIX OFFERS BANK ACCOUNT / DEBIT CARD
        PAYOUTS USING STRIPE IN ADDITION TO PAYPAL TO TRANSFER INCOME PAYMENTS
        TO PUBLISHERS. DO NOT OPEN A PUBLISHER ACCOUNT IF YOU ARE UNWILLING OR
        UNABLE TO PROVIDE EITHER PAYPAL OR BANK/DEBIT CARD DETAILS TO GET PAID.
        GLOBALCOMIX WILL NOT PAY OR HOLD INCOME ON SALES FOR PUBLISHERS WITHOUT
        A PAYPAL OR STRIPE ACCOUNT ABLE TO RECEIVE FUNDS FROM THE UNITED STATES.
        BY AGREEING TO THESE TERMS YOU AGREE TO FORFEIT ANY INCOME EARNED BUT
        NOT PAYABLE DUE TO AN INELIGIBLE PAYPAL OR STRIPE ACCOUNT.
      </Text>
      <Text variant="legalContent">
        We will pay your accrued publisher income, less the Payout fee, to the
        valid PayPal account you provide to us via your publisher account
        settings, upon request if there is a minimum accrued balance of $30.
        This payment will include all confirmed income earned. Please be aware
        that if you earn less than US$30, before the Payout Fee and before VAT
        (if applicable) then the amount will not be paid, but will accrue to
        your account until such time as it becomes equal to or greater than
        US$30. If you or we terminate your account and / or close your publisher
        profile, and you have less than US$30 in accrued but unpaid income, we
        will send you your final payment of confirmed accrued income upon
        termination, provided payout information is valid and present.
      </Text>
      <Text variant="legalContent">
        All income earned on GlobalComix is temporarily held in a
        pending/unconfirmed state for a total of 14 days before it becomes
        claimable as earned income via a payout. As a publisher, you are able to
        at any time claim and initiate a payout of income that has accrued for
        longer than 14 days, provided it meets the US$30 minimum.
      </Text>
      <Text variant="legalContent">
        If we credit you income for content that is later returned or refunded
        for a reason that GlobalComix determines is the result of your action or
        design, GlobalComix retains the right to deduct the value of that income
        from your pending unconfirmed income.
      </Text>
      <Text variant="legalContent">
        If we determine that we have erroneously paid you income for any reason,
        GlobalComix will deduct the value of that income from future payments.
        If future payments are unavailable for such action, GlobalComix may
        require you to return the value of the erroneous payment.
      </Text>
      <Text variant="legalContent">
        If we are unable to provide you with payment because you provided us
        with incorrect PayPal account information or if you supplied a PayPal
        account for a country which we cannot send payment (per PayPal dictated
        restrictions), we will attempt to contact you to obtain correct or valid
        PayPal account information. If we are unable to obtain corrected
        information within 90 days of our first attempt to remit payment, you
        forfeit all rights to that payment and any future payments generated
        until you provide us with correct information.
      </Text>
      <Text variant="legalContent">
        We are not liable for any funds removed from any account by any valid
        member of your team. We are not responsible or liable for the
        distribution of funds to various members of any team at any time.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Tax Information</SectionTitle>
      <Text variant="legalContent">
        It is your responsibility to determine what, if any, taxes apply to the
        payments you receive from us, and it is your responsibility to collect,
        report and remit the correct tax to the appropriate tax authority. We
        are not responsible for determining whether taxes apply to the
        transactions made via the Services, or for collecting, reporting or
        remitting any taxes arising from any payments made to you for content
        access sold through GlobalComix.
      </Text>
      <Text variant="legalContent">
        In accordance with relevant law, GlobalComix and/or the GlobalComix
        payment processors may report accounts with gross payments over $20,000
        and over 200 separate payments in a calendar year to tax authorities.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Personal Data</SectionTitle>
      <Text variant="legalContent">
        We will own any and all personal data processed via the Service, which
        data processing will be subject to the GlobalComix privacy statement. We
        are under no obligation to share any customer data with you.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Confidentiality</SectionTitle>
      <Text variant="legalContent">
        We acknowledge that the source files of your designs must be considered
        as proprietary and confidential information. We will therefore hold your
        source files confidential and not disclose your source files to any
        third party unless (i) we are required to as part of a lawful request,
        at which point such disclosure will be handled in accordance with the
        GlobalComix Privacy Statement; (ii) the disclosure is related to the
        purposes set out the “Access and Disclosure” section of the GlobalComix
        Privacy Statement; or (iii) you grant us explicit permission to do so.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Your Intellectual Property Rights
      </SectionTitle>
      <Text variant="legalContent">
        In addition to the grants outlined in the Intellectual Property Rights
        of Designs section of the GlobalComix Terms and Conditions, you grant us
        the right to use images and prints of the designs that you have uploaded
        for the marketing and promotion of our company and/or its services, e.g.
        in flyers, brochures, websites and mailings. This grant includes our
        right to sublicense the right to third parties (i.e. a third party
        printing a brochure) in order to achieve the marketing and promotional
        purposes. By removing the relevant design from your publisher profile
        you terminate all the licenses granted to us under these Terms of
        Service except for the license to use images of your designs for
        marketing and promotional purposes for the period that we reasonably
        need to phase out any existing marketing and promotion materials.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Our Intellectual Property Rights
      </SectionTitle>
      <Text variant="legalContent">
        Copyright and all other proprietary rights in the Service (including but
        not limited to software, audio, video, text and photographs and
        excluding all your content) rests with GlobalComix and its affiliated
        companies or its licensors. All rights in the content not expressly
        granted herein are reserved. Accordingly, you agree that you will not
        copy, reproduce, alter, modify, or create derivative works from the
        Service. You also agree that you will not use any robot, spider, other
        automated device, or manual process to monitor or copy any content from
        the Service. Our rights include rights to (i) the Service developed and
        provided by us; and (ii) all software associated with the Service.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Product Warranty and Product Liability
      </SectionTitle>
      <Text variant="legalContent">
        In addition to the statements incorporated by reference in the Warranty;
        Disclaimer section of the GlobalComix Terms and Conditions, the
        following terms apply to designs uploaded to your publisher portal. As
        the owner of a design uploaded to GlobalComix, you acknowledge that the
        buyers of access to your content may be entitled to certain warranty
        rights under the legislation of the countries where they live.
        Notwithstanding the fact that the sales agreement for your content shall
        be between us and such buyers, you will be solely responsible for the
        fulfillment of such warranty rights.
      </Text>
      <Text variant="legalContent">
        Furthermore, you maintain sole legal responsibility for the design
        specifications and performance of any content uploaded to GlobalComix.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Disclaimer / Limitation of Liability
      </SectionTitle>
      <Text variant="legalContent">
        These Terms of Service set out the full extent of our obligations and
        liabilities in respect of the Service. SAVE AS SET OUT IN THIS AGREEMENT
        AND IN THE GLOBALCOMIX TERMS AND CONDITIONS, THERE ARE NO WARRANTIES,
        CONDITIONS OR OTHER TERMS THAT ARE BINDING ON US REGARDING THE SUPPLY OF
        SERVICE EXCEPT AS EXPRESSLY STATED IN THESE TERMS OF SERVICE. ANY
        WARRANTY, CONDITION OR OTHER TERM ARISING OUT OF OR IN CONNECTION WITH
        THE SERVICE WHICH MIGHT OTHERWISE BE IMPLIED INTO OR INCORPORATED INTO
        THESE TERMS OF SERVICE BY STATUTE, COMMON LAW, LAWS APPLICABLE IN THE
        COUNTRY WHERE YOU ARE LOCATED (INCLUDING WITHOUT LIMITATION ANY IMPLIED
        TERM AS TO QUALITY, FITNESS FOR PURPOSE, REASONABLE CARE AND SKILL) ARE
        HEREBY EXPRESSLY EXCLUDED.
      </Text>
      <Text variant="legalContent">
        Nothing in these Terms of Service shall limit or exclude our liability
        (i) for death or personal injury caused by our gross negligence or (ii)
        for fraud or (iii) any breach of the obligations implied by applicable
        compulsory national laws as to title or (iv) any liability which cannot
        be excluded by law.
      </Text>
      <Text variant="legalContent">
        WE WILL NOT BE LIABLE UNDER THESE TERMS OF SERVICE FOR ANY LOSS OF
        INCOME, LOSS OF PROFITS, LOSS OF CONTRACTS, LOSS OF DATA OR FOR ANY
        INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE OR CONSEQUENTIAL LOSS
        OR DAMAGE OF ANY KIND HOWSOEVER ARISING AND WHETHER CAUSED BY TORT
        (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE. OUR MAXIMUM
        AGGREGATE LIABILITY UNDER THESE TERMS OF SERVICE WHETHER IN TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL IN NO CIRCUMSTANCES EXCEED THE
        FEE RECEIVED FROM YOU BY US FOR THE USE OF THE SERVICES.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Cancellation and Termination
      </SectionTitle>
      <Text variant="legalContent">
        You may cancel your use of the Service and/or terminate these Terms of
        Service with or without cause at any time by providing notice to us. We
        may at any time and for any reason terminate the Service, terminate
        these Terms of Service, or suspend or terminate your account. In the
        event of termination, your account will be disabled and you may not be
        granted access to your account or any files or other content contained
        in your account although residual copies of information may remain in
        our system.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Applicable Law</SectionTitle>
      <Text variant="legalContent">
        These Terms of Service shall be governed by the laws of New York, USA
        without regard to the conflicts of law principles thereof that would
        apply the law of any jurisdiction other than New York, USA. Any and all
        disputes arising from these Terms of Service, including disputes
        relating to the validity thereof, and any disputes related to the use of
        the Service, shall be brought in the federal and state courts located
        New York County, New York, USA.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Changes to These Terms
      </SectionTitle>
      <Text variant="legalContent">
        We reserve the right to change these Terms of Service from time to time
        and will post any revisions on our Website and may also provide notice
        to you through the service or via email. Therefore we encourage you to
        check the Website often to read the latest version. All transactions are
        subject to the Terms of Service in effect at the time of the
        transaction.
      </Text>
    </>
  );
}
