import { Flex } from "@chakra-ui/react";
import { ReactionTypeEntity } from "../../../../services/gc/types/reactionTypeEntity";
import { useReactions } from "../../hooks/useReactions";
import { ReactionToggle } from "./ReactionToggle";

export function ReactionToggles() {
  const { reactionTypes, reactionTypesValues, toggleReaction } = useReactions();

  const getReactionToggle = (id: number) => {
    return (
      <ReactionToggle
        reactionType={
          // Casting because in practice this won't return undefined
          reactionTypes.find((type) => type.id === id) as ReactionTypeEntity
        }
        reactionSummary={reactionTypesValues[id]}
        toggleReaction={toggleReaction}
      />
    );
  };

  return (
    <Flex
      gap="8px"
      width="100%"
      maxWidth="400px"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      {getReactionToggle(6)}
      {getReactionToggle(8)}
      {getReactionToggle(1)}
      {getReactionToggle(2)}
      {getReactionToggle(3)}
      {getReactionToggle(7)}
      {getReactionToggle(4)}
      {getReactionToggle(5)}
    </Flex>
  );
}
