import { useLocation, useNavigate, useParams } from "react-router";
import { useCallback, useMemo } from "react";
import { buildReaderUrl } from "../../../utils/buildReaderUrl";
import { useReleaseFragmentsData } from "./hookstate/useReleaseFragmentsData";

export function useP2PNavigation() {
  const navigate = useNavigate();
  const { fragmentOrder } = useParams();
  const location = useLocation();
  const { activePageFragments } = useReleaseFragmentsData();

  const goToFragment = useCallback(
    (newFragmentOrder: number) => {
      navigate(
        buildReaderUrl(
          window.location.pathname,
          undefined,
          undefined,
          newFragmentOrder,
        ),
        {
          replace: true,
        },
      );
    },
    [location],
  );

  const hasNextFragment = useMemo(() => {
    return activePageFragments?.length > parseInt(fragmentOrder ?? "");
  }, [activePageFragments, fragmentOrder]);

  const hasPrevFragment = useMemo(() => {
    return parseInt(fragmentOrder ?? "") > 1;
  }, [fragmentOrder]);

  const goToPrevFragment = useCallback(() => {
    if (fragmentOrder && hasPrevFragment) {
      goToFragment(parseInt(fragmentOrder) - 1);
    }
  }, [hasPrevFragment, fragmentOrder]);

  const goToNextFragment = useCallback(() => {
    if (fragmentOrder && hasNextFragment) {
      goToFragment(parseInt(fragmentOrder) + 1);
    }
  }, [hasNextFragment, fragmentOrder]);

  return {
    activeFragmentIndex: parseInt(fragmentOrder ?? "") - 1,
    hasNextFragment,
    hasPrevFragment,
    goToNextFragment,
    goToPrevFragment,
    goToFragment,
  };
}
