import { Button, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { FooterBanner } from "../../../components/banners/FooterBanner";
import { useMutation } from "@tanstack/react-query";
import {
  postResendAccountVerificationEmail,
  RESEND_ACCOUNT_VERIFICATION_EMAIL_KEY,
} from "../api/emailVerificationApi";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import { t } from "i18next";

interface EmailVerificationBannerProps {
  emailAddress: string;
}

export function EmailVerificationBanner({
  emailAddress,
}: EmailVerificationBannerProps) {
  const { mutate, isPending } = useMutation({
    mutationKey: RESEND_ACCOUNT_VERIFICATION_EMAIL_KEY,
    mutationFn: () => postResendAccountVerificationEmail(),
  });

  const invalidateAccountMeQuery = useInvalidateQueries(ACCOUNT_INIT_QUERY_KEY);

  return (
    <FooterBanner
      text={
        <Trans
          i18nKey="screens.root.emailVerification"
          values={{
            email: emailAddress,
          }}
          components={{
            bold: <Text as="span" fontWeight="bold" />,
          }}
        />
      }
      actions={
        <>
          <Button
            variant="tertiary"
            size="sm"
            onClick={invalidateAccountMeQuery}
          >
            {t("screens.root.refresh")}
          </Button>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => mutate()}
            isLoading={isPending}
          >
            {t("screens.root.resendVerificationEmail")}
          </Button>
        </>
      }
    />
  );
}
