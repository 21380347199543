import { ImmutableArray, ImmutableObject } from "@hookstate/core";
import { useReaderIntersectionObserver } from "../../hooks/useReaderIntersectionObserver";
import { Page } from "../../../../types";
import { Flex } from "@chakra-ui/react";
import { VerticalImage } from "./VerticalImage";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import { EndInterstitial } from "../interstitals/EndInterstitial";
import { READER_PADDING_PX } from "../../constants";
import { useVerticalMargin } from "../../hooks/useVerticalMargin";
import { ReaderPaywall } from "../ReaderPaywall";
import { useIsOverlayOpen } from "../../hooks/hookstate/useIsOverlayOpen";
import { useEffect, useMemo, useRef, useState } from "react";
import { rootComponentsStore } from "../../../../components/layouts/RootLayout";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";
import useResizeObserver from "@react-hook/resize-observer";
import { useImageSizing } from "../../hooks/useImageSizing";
import { useReaderNavigation } from "../../hooks/useReaderNavigation";

interface VerticalReaderProps {
  comicIsVertical: boolean;
  releasePagesData?: ImmutableArray<Page>;
}
export const VerticalReader = ({
  comicIsVertical,
  releasePagesData,
}: VerticalReaderProps) => {
  useTrackPageView();
  const { hasVerticalMargin } = useVerticalMargin();
  const { toggleIsOverlayOpen } = useIsOverlayOpen();
  const { observerRef, observer, lastObservedPage } =
    useReaderIntersectionObserver();
  const { pageSizing, getImageSize } = useImageSizing(comicIsVertical);
  const { activePage } = useReaderNavigation();

  const readablePages = useMemo(() => {
    const freePages = releasePagesData?.filter(
      (page) => !page.display_paid_access_block,
    );
    if (releasePagesData?.[freePages?.length ?? 0]) {
      freePages?.push(releasePagesData[freePages.length ?? 0]);
    }
    return freePages;
  }, [releasePagesData]);

  useEffect(() => {
    const openModal = rootComponentsStore.funnelModal.type.get();
    if (openModal === FUNNEL_TYPES.subscription) {
      setFunnelModalConfig();
    }
  }, []);

  useEffect(() => {
    if (lastObservedPage.current !== activePage) {
      document.getElementById(`vertical-page-${activePage}`)?.scrollIntoView();
    }
  }, [activePage, readablePages]);

  const [lastResize, setLastResize] = useState(Date.now());
  useResizeObserver(window.document.body, () => {
    setLastResize(Date.now());
  });

  if (!readablePages?.length) return null;
  return (
    <Flex
      ref={observerRef}
      onClick={toggleIsOverlayOpen}
      h="auto"
      w="full"
      margin="auto"
    >
      <Flex
        width="100%"
        overflow="hidden"
        flexDirection="column"
        gap={hasVerticalMargin && !comicIsVertical ? READER_PADDING_PX : 0}
      >
        {readablePages.map((item, i) => {
          return (
            <VerticalGridImage
              key={i}
              lastResize={lastResize}
              item={item}
              getImageSize={getImageSize}
              pageSizing={pageSizing}
              observer={observer}
              showEndInterstital={item.order === releasePagesData?.length}
              showPaywall={
                !!(
                  releasePagesData &&
                  releasePagesData?.length > readablePages.length &&
                  i === readablePages.length - 1
                )
              }
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

interface VerticalGridImageProps {
  item: Page;
  getImageSize: (page: Page) => { imageHeight: number; imageWidth: number };
  pageSizing: string;
  observer: IntersectionObserver | null;
  releasePagesData?: readonly ImmutableObject<Page>[];
  showEndInterstital: boolean;
  lastResize: number;
  showPaywall: boolean;
}
const VerticalGridImage = ({
  item,
  getImageSize,
  pageSizing,
  observer,
  showEndInterstital,
  lastResize,
  showPaywall,
}: VerticalGridImageProps) => {
  const imageRef = useRef(null);
  useEffect(() => {
    if (imageRef.current) observer?.observe(imageRef.current);
    return () => {
      if (imageRef.current) observer?.unobserve(imageRef.current);
    };
  }, [imageRef, observer]);

  const { imageHeight, imageWidth } = useMemo(() => {
    return getImageSize(item);
  }, [item, pageSizing, lastResize]);

  const index = item.order - 1;
  return (
    <>
      <Flex
        ref={imageRef}
        id={`vertical-page-${index}`}
        position="relative"
        flexDirection="column"
      >
        <Flex
          maxHeight={showPaywall ? "15dvh" : undefined}
          overflow="hidden"
          justifyContent="center"
        >
          <VerticalImage item={item} height={imageHeight} width={imageWidth} />
        </Flex>
        {showPaywall && (
          <Flex
            position="relative"
            marginTop="-20px"
            width="100%"
            justifyContent="center"
            bg="reader.slate.600"
            overflow="auto"
            borderRadius="16px"
          >
            <ReaderPaywall />
          </Flex>
        )}
      </Flex>
      {showEndInterstital && <EndInterstitial isVertical />}
    </>
  );
};
