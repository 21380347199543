import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { ComicFormatEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";
import { FormatIcon } from "../../../../components/icons/FormatIcon";

export const COMIC_FORMAT_ALL = "0";
export const COMIC_AUGMENTED_ENABLED = "augmented_enabled";

interface ComicFormatFilterProps {
  value: Immutable<string | undefined>;
  comicFormats: ComicFormatEntity[];
  onChange: (nextValue: string) => void;
}
export function ComicFormatFilter({
  value,
  comicFormats,
  onChange,
}: ComicFormatFilterProps) {
  const formatOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: COMIC_FORMAT_ALL },
      ...comicFormats.map((format) => ({
        label: format.display_name,
        value: "" + format.id,
      })),
      { label: "PanelFlow Enabled", value: COMIC_AUGMENTED_ENABLED },
    ],
    [],
  );

  return (
    <RadioFilter value={value} options={formatOptions} onChange={onChange} />
  );
}

interface ComicFormatFilterTitleProps {
  isTrigger: boolean;
  comicFormats: ComicFormatEntity[];
  open?: boolean;
  value?: string;
}
export const ComicFormatFilterTitle = forwardRef(
  (
    {
      isTrigger,
      comicFormats,
      open,
      value,
      ...props
    }: ComicFormatFilterTitleProps,
    ref,
  ) => {
    const comicFormat = comicFormats.find((format) => `${format.id}` == value);

    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<FormatIcon />}
          popoverRef={ref}
          text="Format"
          isFiltering={
            comicFormat?.display_name || value === COMIC_AUGMENTED_ENABLED
          }
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Format"
        filterValue={comicFormat?.display_name}
        open={open}
      />
    );
  },
);
