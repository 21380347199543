import {
  Text,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { cloneElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonCarousel } from "../../../../components/carousel/ButtonCarousel";
import { usePopoverOpenState } from "../../../../utils/usePopoverOpenState";

interface BrowseFilterPopoverProps {
  filterGroup: any[];
  invalidate: () => void;
}

export function BrowseFilterPopover({
  filterGroup,
  invalidate,
}: BrowseFilterPopoverProps) {
  const { ref, onToggle, isOpen, onClose } = usePopoverOpenState();
  const [title, filter, filterStore] = filterGroup;
  const [value, setValue] = useState(filterStore.get());
  const [t] = useTranslation();

  useEffect(() => {
    setValue(filterStore.get());
  }, [isOpen, filterGroup]);

  const onChange = (newValue: any) => {
    setValue(newValue);
  };

  const setFilterStore = async () => {
    await filterStore.set(value);
    onClose();
    invalidate();
  };

  const clearFilter = () => {
    let clearValue = undefined;
    if (typeof value === "string") {
      clearValue = "0";
    } else if (Array.isArray(value)) {
      clearValue = [];
    }
    setValue(clearValue);
  };

  return (
    <Popover variant="filter" gutter={4} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        {cloneElement(title, {
          style: { pointerEvents: isOpen ? "none" : "auto" },
          isActive: isOpen,
          onClick: onToggle,
        })}
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          ref={ref}
          variants={{
            enter: { opacity: 1, top: 0 },
            exit: { opacity: 0, top: -20 },
          }}
        >
          <PopoverBody>{cloneElement(filter, { value, onChange })}</PopoverBody>
          <PopoverFooter>
            <Button
              variant="iconOnly"
              role="group"
              size="thin"
              onClick={clearFilter}
            >
              <Text
                fontSize="xs"
                color="dune.700"
                fontWeight="500"
                lineHeight="150%"
                letterSpacing=".25px"
                transition="all .2s linear"
                _groupHover={{ color: "dune.100" }}
              >
                {typeof value === "string"
                  ? t("screens.browse.filters.clear")
                  : t("screens.browse.filters.clearAll")}
              </Text>
            </Button>
            <Button variant="primary" size="sm" onClick={setFilterStore}>
              <Text
                fontSize="12px"
                lineHeight="11px"
                letterSpacing=".75px"
                fontWeight="500"
              >
                {t("screens.browse.filters.apply")}
              </Text>
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

interface BrowseFiltersCarouselProps {
  filters: any[];
  invalidate: () => void;
}
export function BrowseFiltersCarousel({
  filters,
  invalidate,
}: BrowseFiltersCarouselProps) {
  return (
    <ButtonCarousel
      slidesToScroll={3}
      wrapperProps={{
        paddingLeft: 2,
        borderLeft: "1px solid",
        borderColor: "transparent.white.20",
      }}
    >
      {filters
        .filter((n) => !!n)
        .map((group, i) => {
          return (
            <Flex key={i} paddingLeft="8px">
              <BrowseFilterPopover
                filterGroup={group}
                invalidate={invalidate}
              />
            </Flex>
          );
        })}
    </ButtonCarousel>
  );
}
