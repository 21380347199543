import { useQueryClient } from "@tanstack/react-query";
import { Article, Artist, Comic, Release, Section } from "../types";
import { useAccountData } from "./useAccountData";

export const useEntityCache = () => {
  const queryClient = useQueryClient();
  const accountData = useAccountData();

  const comicFavorites = accountData?.comic_favorites ?? [];

  const saveComic = (section: Section, comic: Comic) => {
    if (
      !section.meta?.layout.display?.include_add_library_cta &&
      !section.meta?.layout.display?.include_facet
    ) {
      delete comic.library_status;
    }

    if (!section.meta?.layout.display?.include_match) {
      delete comic.comic_facets;
    }

    saveEntityToCache(comic);
  };

  const saveEntityToCache = (n: any) => {
    const cacheKey = `${n.entity_type}-${n.id}`;
    const cacheData = n;

    queryClient.setQueryData(
      [cacheKey],
      (oldCacheData: undefined | Comic | Artist | Release | Article) => {
        if (oldCacheData && oldCacheData.entity_type === "Comic") {
          // Save comic with a library status if it's in the account comic favorites
          if (!oldCacheData.library_status && comicFavorites.includes(n.id)) {
            cacheData.is_favorite = true;

            cacheData.library_status = {
              entity_type: "ComicFavorite",
              comic_id: n.id,
              user_id: accountData?.user?.id ?? 0,
              comic_user_reading_status_id: 1,
              disable_notifications: 0,
              disable_emails: 0,
            };
          }

          if (!n.artist && (oldCacheData as Comic).artist) delete n.artist;

          if (!cacheData.comic_langs?.length) {
            delete cacheData.comic_langs;
          }
        } else if (n.entity_type === "Artist") {
          if (accountData?.artist_favorites?.includes(n.id)) {
            cacheData.user_is_following = true;
          }
        }

        return {
          ...oldCacheData,
          ...cacheData,
        };
      },
    );
  };

  return {
    saveComic,
    saveEntityToCache,
  };
};
