import { Flex, useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FilterTagButton } from "../../components/buttons/FilterTag";
import { useBrowseFilterState } from "./hooks/useBrowseFiltersState.tsx";
import {
  BrowseFilters,
  browseFilterStore,
  FilterState,
} from "./components/filters/BrowseFilters.tsx";
import { BrowseFiltersModal } from "./components/filters/BrowseFiltersModal.tsx";
import { BrowseResultsGrid } from "./components/BrowseResultsGrid.tsx";
import { BrowseTabs, FloatingBrowseTabs } from "./components/BrowseTabs.tsx";
import {
  useIsSmallBreakpoint,
  useMobileBreakpoint,
} from "../../utils/useBreakpoints.ts";
import { useInvalidateQueries } from "../../services/axiosInstance.ts";
import {
  GET_ARTIST_LIST_QUERY_KEY,
  GET_COMIC_LIST_QUERY_KEY,
} from "./api/browseScreenApi.ts";
import { ImmutableArray, State, useHookstate } from "@hookstate/core";
import { t } from "i18next";
import { ScreenLayout } from "../../components/layouts/components/ScreenLayout.tsx";
import { ModeToggle } from "../../components/resultsGrid/ModeToggle.tsx";
import { LayoutStyle } from "../../types.ts";
import { SortMenus } from "../../components/sortMenus/SortMenus.tsx";
import { appStore } from "../../appStore.ts";
import { useScrollVisibility } from "../../hooks/useScrollVisibility.ts";
import { CollapsibleNavBar } from "../../components/navbar/CollapsibleNavBar.tsx";
import { useBrowseFiltersAPI } from "./hooks/useBrowseFiltersAPI.ts";
import { throttle } from "lodash-es";
import { useTitleOnNavigation } from "../../hooks/useTitleOnNavigation.ts";

export default function BrowseScreen() {
  const isSmall = useIsSmallBreakpoint();
  const isMobile = useMobileBreakpoint();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tab, setTab] = useState<0 | 1>(() => {
    const pathArr = window.location.pathname.split("/");
    return pathArr[1] === "creators" ? 1 : 0;
  });
  const [mode, setMode] = useState<LayoutStyle["mode"]>("grid");

  const [orders, setOrders] = useState<
    ImmutableArray<{
      id: string;
      name: string;
    }>
  >([]);

  const invalidate = useInvalidateQueries(
    tab === 0 ? GET_COMIC_LIST_QUERY_KEY : GET_ARTIST_LIST_QUERY_KEY,
  );

  let searchPlaceHolder = "";

  if (tab === 0) {
    searchPlaceHolder = t("screens.browse.searchPlaceholderComics");
  } else if (tab === 1) {
    searchPlaceHolder = t("screens.browse.searchPlaceholderCreators");
  }

  const clearAllFilters = useCallback(async () => {
    await Promise.all(
      browseFilterStore.keys.map(async (_key) => {
        const key: keyof FilterState = _key as keyof FilterState;

        if (key === "query") return;

        if (key === "comicFacets") {
          const currentValue: FilterState["comicFacets"] =
            browseFilterStore.comicFacets.get();
          if (currentValue) {
            Object.keys(currentValue).forEach((facet) => {
              browseFilterStore.comicFacets[facet].set([]);
            });
          }
        } else if (["listId", "contributor"].includes(key)) {
          (browseFilterStore[key] as State<FilterState[typeof key]>).set("");
        } else if (key !== "sortMenus") {
          const filter = browseFilterStore[key] as State<
            FilterState[typeof key]
          >;

          let newValue = typeof filter.get() === "object" ? [] : "0";
          filter.set(newValue);
        }
      }),
    );

    invalidate?.();
  }, [browseFilterStore, invalidate]);

  useEffect(() => {
    if (!isSmall && mode === "list") {
      setMode("grid");
    }
  }, [isSmall]);

  var appInitSortingOrders = useHookstate(
    appStore.init.comic_browse_sort_orders,
  ).get({
    noproxy: true,
  });

  const relevanceOrder = [{ id: "relevance", name: "Relevance" }];

  const { handleScroll, isHidden } = useScrollVisibility();
  const gridAdjustmentMobile = isHidden ? "0px" : "200px";

  const {
    urlSettled,
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useBrowseFiltersAPI(isOpen, tab);

  const searchQuery = useHookstate(browseFilterStore.query).get();
  useEffect(() => {
    if (!urlSettled) return;
    updateSortOrders();
    const timeout = setTimeout(() => {
      invalidate();
    }, 100);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const [initTerm, setInitTerm] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (urlSettled) {
      updateSortOrders();
      setInitTerm(browseFilterStore.query.get());
    }
  }, [urlSettled]);

  const throttleSearch = useCallback(
    throttle(async (term) => {
      if (!urlSettled) return;
      browseFilterStore.query.set(term);
    }, 1000),
    [urlSettled],
  );

  const updateSortOrders = useCallback(() => {
    const hasRelevanceOrder = orders.some((order) =>
      relevanceOrder.some((rel) => rel.id === order.id),
    );
    if (searchQuery) {
      // If there is no relevance order, we add it to the sorting orders
      if (!hasRelevanceOrder) {
        setOrders(appInitSortingOrders.concat(relevanceOrder));
      }
    } else {
      setOrders(appInitSortingOrders);
    }
  }, [appInitSortingOrders, searchQuery]);

  return (
    <ScreenLayout
      isTitleHidden={isSmall && isHidden}
      pageTitle={<BrowsePageTitle tab={tab} />}
      initValue={initTerm}
      onSearch={throttleSearch}
      searchPlaceholder={searchPlaceHolder}
    >
      <Flex
        direction="column"
        gap={isSmall ? 0 : 6}
        width="full"
        sx={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        {!isSmall && (
          <Flex direction="row" gap={4} mr={isSmall ? 0 : 10}>
            <Flex gap={1.5}>
              <BrowseFiltersModal
                clearAll={clearAllFilters}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                type={tab}
              />
              <FilterTagButton
                alt={true}
                onClick={clearAllFilters}
                text="Clear all"
              />
            </Flex>
            <BrowseFilters invalidate={invalidate} frozen={isOpen} />
          </Flex>
        )}
        <CollapsibleNavBar isCollapsed={isHidden} shouldCollapse={isSmall}>
          <Flex
            justifyContent="space-between"
            pb={4}
            transition={"padding 0.3s"}
            px={isSmall ? 4 : 0}
            mr={isSmall ? undefined : 10}
            borderBottom="1px solid"
            borderColor="transparent.white.10"
          >
            {!isSmall && <BrowseTabs active={tab} setActive={setTab} />}
            <SortMenus
              invalidate={invalidate}
              filterState={browseFilterStore}
              sortOrders={orders}
            />
            {isMobile && <ModeToggle mode={mode} setMode={setMode} />}
          </Flex>
        </CollapsibleNavBar>
        <BrowseResultsGrid
          tab={tab}
          mode={mode}
          onScroll={handleScroll}
          gridHeightAdjustment={isSmall ? gridAdjustmentMobile : undefined}
          data={data}
          isLoading={isLoading}
          urlSettled={urlSettled}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
        {isSmall && (
          <Flex
            position="fixed"
            width="100%"
            justifyContent="center"
            left={0}
            bottom="20px"
            gap="8px"
          >
            <FloatingBrowseTabs active={tab} setActive={setTab} />
            <BrowseFiltersModal
              clearAll={clearAllFilters}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              type={tab}
              small
            />
          </Flex>
        )}
      </Flex>
    </ScreenLayout>
  );
}

interface BrowsePageTitleProps {
  tab: 0 | 1;
}

function BrowsePageTitle({ tab }: BrowsePageTitleProps) {
  const { urlSettled, filterMaps, getSlugFilters } = useBrowseFilterState(tab);

  const title = determineBrowsePageTitle(
    tab,
    urlSettled,
    filterMaps,
    getSlugFilters,
  );

  useTitleOnNavigation(title);

  return title;
}

function determineBrowsePageTitle(
  tab: number,
  urlSettled: boolean,
  filterMaps: any,
  getSlugFilters: Function,
): string | null {
  const {
    lang: language,
    type,
    genre: category,
  } = getSlugFilters(filterMaps) || {};

  if (tab === 1) {
    return t("screens.browse.creators_title");
  }

  if (!filterMaps) return null;

  const props = {
    language,
    category,
    type,
  };

  if ((!language && !category && !type) || !urlSettled) {
    return t("screens.browse.browse_title", props);
  } else if (language && category && type) {
    return t("screens.browse.browse_title_type_category_language", props);
  } else if (category && type) {
    return t("screens.browse.browse_title_type_category", props);
  } else if (type && language) {
    return t("screens.browse.browse_title_type_language", props);
  } else if (category && language) {
    return t("screens.browse.browse_title_category_language", props);
  } else if (language) {
    return t("screens.browse.browse_title_language", props);
  } else if (category) {
    return t("screens.browse.browse_title_category", props);
  } else if (type) {
    return t("screens.browse.browse_title_type", props);
  }

  return null;
}
