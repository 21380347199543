import { Box, Flex, Divider, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { ComicDetailsHeader } from "./ComicDetailsHeader";
import { ComicDescription } from "./ComicDescription";
import { ComicDetailsTabs } from "./ComicDetailsTabs";
import { ArtistSection } from "./ArtistSection";
import { Stats } from "../../../screens/reader/components/shared/Stats";
import { useSlideInMenu } from "../hooks/useSlideInMenu";
import { getAudienceShort } from "../../../screens/reader/utils/comicFacetGroups";
import { ComicDetailsBottomBar } from "./ComicDetailsBottomBar";
import { Comic } from "../../../types";
import { ShowMoreSection } from "../../containers/ShowMoreSection";
import { useQuery } from "@tanstack/react-query";
import { formatLargeNumber } from "../../../utils/formatLargeNumber";
import { ComicLabels } from "./ComicLabels";
import { CrowdfundingBanner } from "./CrowdfundingBanner";

interface ComicDetailsProps {}
export function ComicDetails({}: ComicDetailsProps) {
  const { comicCacheKey, isFetched } = useSlideInMenu(true);
  const { data: comicData } = useQuery<Comic>({
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  if (!comicData || !isFetched) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        position="relative"
        p="24px"
        height="100%"
        width="100%"
        flexDirection="column"
        gap="24px"
        overflowY="auto"
        paddingBottom="96px"
        paddingTop="80px"
      >
        {comicData.crowdfunding_campaign ? (
          <CrowdfundingBanner
            crowdfundingCampaign={comicData.crowdfunding_campaign}
          />
        ) : null}
        <ComicDetailsHeader comicData={comicData} />
        <Stats
          stats={[
            {
              value: formatLargeNumber(comicData?.total_pageviews ?? 0),
              text: t("components.slideInMenu.comicDetailsMenu.views"),
            },
            {
              value: comicData?.total_favorites,
              text: t("components.slideInMenu.comicDetailsMenu.shelves"),
            },
            {
              value: comicData?.total_releases,
              text: t("components.slideInMenu.comicDetailsMenu.releases"),
            },
            {
              value: getAudienceShort(comicData?.comic_facets),
              text: t("components.slideInMenu.comicDetailsMenu.audience"),
            },
          ]}
        />
        <ComicLabels comicData={comicData as Comic} />
        {comicData?.artist && (
          <>
            <Divider />
            <ArtistSection artist={comicData?.artist} />
          </>
        )}
        <Divider />
        <ShowMoreSection collapsedHeight={150} expandedMarginBottom="24px">
          <ComicDescription comicData={comicData} />
        </ShowMoreSection>
        <ComicDetailsTabs comicData={comicData} />
        <Box height="96px" />
      </Flex>
      <ComicDetailsBottomBar comicData={comicData as Comic} />
    </>
  );
}
