import { apiClient } from "../../../services/axiosInstance";
import {
  Account,
  ServerResponse,
  ApplicationUserAccessToken,
  AttributionParams,
} from "../../../types";
import sha256 from "crypto-js/sha256";
import { rootComponentsStore } from "../../layouts/RootLayout";

export function getAccountMe(params?: AttributionParams) {
  return apiClient.get<ServerResponse<Account>>("/v1/account/me", {
    params: {
      ...params,
    },
  });
}

export const GET_ACCOUNT_ME_QUERY_KEY = ["account-me"];

export const LOGIN_EMAIL_PASSWORD_KEY = ["login-email-password"];

export function loginWithEmailAndPassword(email: string, password: string) {
  const hashedPassword = sha256(password + "differenthash");

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/request-token",
    { meta: {}, payload: { email, password_hash: hashedPassword.toString() } },
  );
}

export const LOGIN_WITH_GOOGLE_KEY = ["login-with-google"];

export async function loginWithGoogle(token: string) {
  const trackingQueryParams =
    rootComponentsStore.eventTrackingQueryParams.get();

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/google-sso-login" +
      (trackingQueryParams ? `?${trackingQueryParams}` : ""),
    {
      meta: {},
      payload: {
        token,
      },
    },
  );
}

export const LOGIN_WITH_FACEBOOK_KEY = ["login-with-facebook"];

export async function loginWithFacebook(userId: string, token: string) {
  const trackingQueryParams =
    rootComponentsStore.eventTrackingQueryParams.get();

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/facebook-sso-login" +
      (trackingQueryParams ? `?${trackingQueryParams}` : ""),
    {
      meta: {},
      payload: {
        id: userId,
        token,
      },
    },
  );
}

export const LOGIN_WITH_APPLE_KEY = ["login-with-apple"];

export async function loginWithApple(code: string) {
  const trackingQueryParams =
    rootComponentsStore.eventTrackingQueryParams.get();

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/apple-sso-login" +
      (trackingQueryParams ? `?${trackingQueryParams}` : ""),
    {
      meta: {},
      payload: {
        code,
      },
    },
  );
}

export const SIGN_UP_WITH_EMAIL_PASSWORD = ["signup-email-password"];

export async function signupWithEmailAndPassword(
  email: string,
  password: string,
) {
  const hashedPassword = sha256(password + "differenthash");
  const trackingQueryParams =
    rootComponentsStore.eventTrackingQueryParams.get();

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/register-email" +
      (trackingQueryParams ? `?${trackingQueryParams}` : ""),
    {
      meta: {},
      payload: {
        email,
        password_hash: hashedPassword.toString(),
      },
    },
  );
}

export function logout() {
  // @ts-expect-error types are out of date
  if (window.mixpanel?.stop_session_recording) {
    // @ts-expect-error types are out of date
    window.mixpanel.stop_session_recording();
  }
  window.mixpanel?.reset();

  return apiClient.post<ServerResponse<ApplicationUserAccessToken>>(
    "/v1/auth/logout",
    { meta: {}, payload: {} },
  );
}
