import { useMutation } from "@tanstack/react-query";
import { LOGIN_WITH_APPLE_KEY, loginWithApple } from "../api/authApi";
import AppleSignin from "react-apple-signin-auth";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { SsoButton } from "../../buttons/SsoButton";
import { AppleIcon } from "../../icons/AppleIcon";

interface AppleSsoButtonProps {
  onSuccess: () => void;
  onError: (error: any) => void;
  isSmall?: boolean;
}

export function AppleSsoButton({
  onSuccess,
  onError,
  isSmall,
}: AppleSsoButtonProps) {
  const toast = useToast();
  const { t } = useTranslation();

  const appleMutation = useMutation({
    mutationKey: LOGIN_WITH_APPLE_KEY,
    mutationFn: ({ code }: { code: string }) => loginWithApple(code),
    onSuccess: (_) => onSuccess(),
    onError: (error) => onError(error),
  });

  return (
    <AppleSignin
      authOptions={{
        clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
        redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
        scope: "email name",
        usePopup: true,
      }}
      onSuccess={(response: any) => {
        const { authorization } = response;
        appleMutation.mutate({ code: authorization.code });
      }}
      onError={(error: any) => {
        if (error.error !== "popup_closed_by_user") {
          toast({
            title: t("components.auth.errorAuthenticatingWithApple"),
            status: "error",
          });
        }
      }}
      uiType="dark"
      render={(props: any) => (
        <SsoButton
          {...props}
          isSmall={isSmall}
          icon={<AppleIcon boxSize="24px" />}
          text={t("components.auth.continueWithApple")}
          isLoading={appleMutation.isPending}
        />
      )}
    />
  );
}
