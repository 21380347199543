import { useBreakpoint } from "@chakra-ui/react";

export const useMobileBreakpoint = () => {
  const breakpoint = useBreakpoint({ ssr: false });
  return ["base", "xs", "sm"].includes(breakpoint);
};

export const useIsLargeBreakpoint = () => {
  const breakpoint = useBreakpoint({ ssr: false });
  return ["lg", "xl", "2xl", "3xl", "4xl"].includes(breakpoint);
};

export const useIsMediumBreakpoint = () => {
  const breakpoint = useBreakpoint({ ssr: false });
  return ["base", "xs", "sm", "md", "lg"].includes(breakpoint);
};

export const useIsSmallBreakpoint = () => {
  const breakpoint = useBreakpoint({ ssr: false });
  return !["lg", "xl", "2xl", "3xl", "4xl"].includes(breakpoint);
};

export function useIsSmallLandscape() {
  const isMobile = useMobileBreakpoint();
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;

  return isMobile && isLandscape;
}

export function useIsLandscape() {
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  return isLandscape;
}
