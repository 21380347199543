import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(progressAnatomy.keys);

const releaseProgress = definePartsStyle({
  filledTrack: {
    background: `
      linear-gradient(
        90deg, 
        var(--chakra-colors-blaze-700) 0%, 
        var(--chakra-colors-blaze-400) 100%
      )
    `,
  },
});

export const progressTheme = defineMultiStyleConfig({
  variants: {
    releaseProgress,
  },
});
