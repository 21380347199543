import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { ComicUserReadingStatusEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { browseFilterStore } from "./BrowseFilters";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";
import { COMIC_USER_READING_STATUS_ALL } from "../../../../components/readingStatusButton/ReadingStatusButton";

interface ComicUserReadingStatusFilterProps {
  value?: string;
  comicUserReadingStatus: ComicUserReadingStatusEntity[];
  includeAll?: boolean;
  onChange?: (nextValue: string) => void;
}
export function ComicUserReadingStatusFilter({
  value,
  comicUserReadingStatus,
  includeAll = true,
  onChange,
}: ComicUserReadingStatusFilterProps) {
  const categoryOptions: Option<string>[] = useMemo(() => {
    const options = [
      ...comicUserReadingStatus.map((type) => ({
        label: type.display_name,
        value: "" + type.id,
      })),
    ];
    if (includeAll) {
      options.unshift({ label: "All", value: COMIC_USER_READING_STATUS_ALL });
    }
    return options;
  }, [comicUserReadingStatus]);

  return (
    <RadioFilter
      value={value}
      options={categoryOptions}
      onChange={onChange ? onChange : () => {}}
    />
  );
}

interface ComicUserReadingStatusFilterTitleProps {
  isTrigger: boolean;
  comicUserReadingStatuses: ComicUserReadingStatusEntity[];
  open: boolean;
}
export const ComicUserReadingStatusFilterTitle = forwardRef(
  (
    {
      isTrigger,
      comicUserReadingStatuses,
      open,
      ...props
    }: ComicUserReadingStatusFilterTitleProps,
    ref,
  ) => {
    const comicUserReadingStatusStore =
      browseFilterStore.comicUserReadingStatus.get();
    const comicUserReadingStatus = comicUserReadingStatuses.find(
      (status) => `${status.id}` == comicUserReadingStatusStore,
    );

    if (isTrigger) {
      return (
        <FilterTagButton
          popoverRef={ref}
          text="Reading Status"
          isFiltering={comicUserReadingStatus?.display_name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Reading Status"
        filterValue={comicUserReadingStatus?.display_name}
        open={open}
      />
    );
  },
);
