import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUserData } from "../../../hooks/useUserData";
import { FUNNEL_TYPES, FUNNEL_TYPES_TYPES } from "../utils/contants";
import { t } from "i18next";
import { AuthenticationHeader } from "../../auth/components/AuthenticationHeader";
import {
  LoginModalBody,
  LoginModalFooter,
} from "../../auth/components/LoginModal";
import {
  SignUpModalBody,
  SignUpModalFooter,
} from "../../auth/components/SignUpModal";
import {
  SignUpWithEmailPasswordModalBody,
  SignUpWithEmailPasswordModalFooter,
} from "../../auth/components/SignUpWithEmailPasswordModal";
import { SubscriptionModalBody } from "../../payments/SubscriptionModal";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { DonationModalHeader } from "../../payments/components/DonationModalHeader";
import { DonationModalBody } from "../../payments/components/DonationModalBody";
import { ImmutableArray, ImmutableObject } from "@hookstate/core";
import { PdfModalHeader } from "../../payments/components/PdfModalHeader";
import { PdfModalBody } from "../../payments/components/PdfModalBody";
import { PdfModalFooter } from "../../payments/components/PdfModalFooter";
import { Account, Artist, Release } from "../../../types";
import { DownloadPdfModalBody } from "../../payments/components/DownloadPdfModalBody";
import { ReadingStatusModalBody } from "../../readingStatusButton/components/ReadingStatusModalBody";
import { ReadingStatusModalHeader } from "../../readingStatusButton/components/ReadingStatusModalHeader";
import { DonationConfirmationModalBody } from "../../payments/components/DonationConfirmationModalBody";
import { FunnelContextArea, FunnelNavigationContext } from "../types";
import {
  EventTypes,
  publishViewedRegistrationEvent,
  publishViewedSubscriptionOfferEvent,
} from "../../../screens/root/api/eventsApi";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { OnboardingModalBody } from "../../onboarding/OnboardingModalBody";
import { motion } from "framer-motion";
import { SIGN_UP_FUNNEL_VALUE_PROPS } from "../../auth/constants";
import { useAccountData } from "../../../hooks/useAccountData";
import { PurchaseReleaseModalHeader } from "../../payments/components/PurchaseReleaseModalHeader";
import { PurchaseReleaseModalBody } from "../../payments/components/PurchaseReleaseModalBody";
import { PurchaseReleaseModalFooter } from "../../payments/components/PurchaseReleaseModalFooter";
import { ONE_DAY } from "../../../utils/time";
import { PrePaywallModalBody } from "../../payments/components/PrePaywallModalBody";

export type UseFunnelOptionsTypes = {
  donationArtistId?: number;
  donationArtist?: ImmutableObject<Artist>;
  valueProps?: ImmutableArray<string>;
  welcomeText?: string;
  purchaseRelease?: ImmutableObject<Release>;
  locked?: boolean;
  trapFocus?: boolean;
  onAuthenticated?: () => void;
  skipAuthentication?: boolean;
  cacheKey?: string;
  isModal?: boolean;
  onClose?: () => void;
  navigationContext?: FunnelNavigationContext;
  modalContext?: string | FunnelContextArea;
  inPaywall?: boolean;
};

export type SetFunnelTypes = ({
  type,
  options,
}: {
  type?: FUNNEL_TYPES_TYPES;
  options?: UseFunnelOptionsTypes;
}) => void;

export function useFunnel(
  onCloseFunnel: () => void,
  setFunnelConfig: SetFunnelTypes,
  openFunnelType?: FUNNEL_TYPES_TYPES,
  options?: UseFunnelOptionsTypes,
) {
  // Used to check if the user is waiting for auth
  const waitingForAuth = useRef(false);

  // Used to save the config needed after auth
  const postAuthConfig = useRef<{
    type: FUNNEL_TYPES_TYPES;
    options?: UseFunnelOptionsTypes;
  }>();

  const accountData = useAccountData();
  const { userData } = useUserData();

  const {
    donationArtistId: artistId,
    donationArtist,
    valueProps,
    welcomeText,
    purchaseRelease: release,
    locked = false,
    skipAuthentication,
    cacheKey,
    isModal,
    navigationContext,
    modalContext,
  } = options || {};

  const inAuthFunnel =
    openFunnelType === FUNNEL_TYPES.login ||
    openFunnelType === FUNNEL_TYPES.signup ||
    openFunnelType === FUNNEL_TYPES.signupEmailPassword;

  // If the user is not logged in, show the login/signup modal
  // and save the config needed after auth
  useEffect(() => {
    if (!skipAuthentication && openFunnelType && !inAuthFunnel && !userData) {
      postAuthConfig.current = {
        type: openFunnelType,
        options,
      };

      setFunnelConfig({ type: FUNNEL_TYPES.signup, options });
    }
  }, [openFunnelType]);

  // Handle the closing of a funnel
  // If the funnel is locked, do not close
  const onClose = () => {
    if (!locked) onCloseFunnel();
    delete postAuthConfig.current;
    options?.onClose?.();
  };

  // Handle the successful completion of a funnel
  // Dont close the funnel if there is a saved config
  // to switch to
  const onSuccess = () => {
    if (!userData && !options?.skipAuthentication) {
      // Auth is async, so we use this flag to mark that
      // we are expecting the user data to change to a logged in user
      waitingForAuth.current = true;
    } else {
      if (!postAuthConfig.current) onCloseFunnel();
      options?.onAuthenticated?.();
    }
  };

  // Set the funnel type and pass the current options
  // to the new funnel type
  const setFunnelType = useCallback(
    (type: FUNNEL_TYPES_TYPES) => {
      setFunnelConfig({ type, options });
    },
    [options],
  );

  const sendEvent = useCallback(
    (eventType: EventTypes) => {
      if (navigationContext) {
        switch (eventType) {
          case EventTypes.viewed_subscription_offer:
            publishViewedSubscriptionOfferEvent(navigationContext);
            break;
          case EventTypes.viewed_registration:
            publishViewedRegistrationEvent(navigationContext);
            break;
        }
      }
    },
    [navigationContext],
  );

  const getSignUpValueProps = useCallback(() => {
    const contextArea =
      typeof modalContext === "string"
        ? (modalContext as FunnelContextArea)
        : undefined;
    return getFunnelTextForContext(accountData, contextArea);
  }, [modalContext]);

  const isGoldPage = window.location.pathname.startsWith("/gold");
  const shouldShowOnboardingAfterAuth = useCallback(() => {
    return (
      // Show onboarding if modalContext matches one of these areas
      [
        isGoldPage || options?.inPaywall ? undefined : FunnelContextArea.navBar,
        FunnelContextArea.startNextReleaseButton,
      ].includes(options?.modalContext as FunnelContextArea)
    );
  }, [options?.modalContext, options?.inPaywall]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => setIsOpen(openFunnelType !== undefined), [openFunnelType]);
  useEffect(() => {
    if (isOpen && openFunnelType) {
      switch (openFunnelType) {
        case FUNNEL_TYPES.login:
        case FUNNEL_TYPES.signupEmailPassword:
        case FUNNEL_TYPES.signup:
          sendEvent(EventTypes.viewed_registration);
          break;
      }
    }
  }, [isOpen]);

  const toSnakeCase = (value: string): string =>
    value.replace(/([A-Z])/g, "_$1").toLowerCase();

  useEffect(() => {
    if (navigationContext) {
      const queryParams = new URLSearchParams();
      Object.entries(navigationContext).forEach(([key, value]) => {
        if (key === "data" && typeof value === "object" && value !== null) {
          Object.entries(value).forEach(([dataKey, dataValue]) => {
            queryParams.append(toSnakeCase(dataKey), dataValue as string);
          });
        } else {
          queryParams.append(toSnakeCase(key), value);
        }
      });

      if (queryParams.size > 0) {
        rootComponentsStore.eventTrackingQueryParams.set(
          queryParams.toString(),
        );
      }
    } else {
      rootComponentsStore.eventTrackingQueryParams.set(undefined);
    }
  }, [navigationContext]);

  const [userChanged, setUserChanged] = useState(0);
  useEffect(() => {
    const showOnboardingAfterAuth = shouldShowOnboardingAfterAuth();

    if (!options?.skipAuthentication) {
      if (waitingForAuth.current) {
        // Open the funnel after auth if the user has not onboarded
        // and the user is not in the reader.
        if (userData && openFunnelType && showOnboardingAfterAuth) {
          const onboarding = userData?.onboarding;
          const lastOnboardingAttempt = parseInt(
            localStorage.getItem("lastOnboardingTime") ?? "0",
          );
          const onboardingSkipped = userData?.onboarding === "skipped";

          const canShowOnboarding =
            !onboarding ||
            (onboardingSkipped && Date.now() - lastOnboardingAttempt > ONE_DAY);

          if (canShowOnboarding) {
            setFunnelConfig({
              type: FUNNEL_TYPES.onboarding,
              options: {
                ...options,
                trapFocus: true,
              },
            });
            return;
          }
        }

        waitingForAuth.current = false;
        if (postAuthConfig.current) {
          setFunnelConfig(postAuthConfig.current);
          delete postAuthConfig.current;
        } else {
          if (!postAuthConfig.current) {
            onCloseFunnel();
          }
          options?.onAuthenticated?.();
        }
      }

      // Stops the funnel from recomputing on auth when we are
      // handling auth elsewhere
      setUserChanged((prev) => prev + 1);
      return;
    }
  }, [accountData?.user?.id, options?.skipAuthentication]);

  return useMemo(() => {
    const funnelProps = {
      onClose,
      onSuccess,
      setFunnelType,
      isModal,
      sendEvent,
    };
    const {
      login,
      signupEmailPassword,
      subscription,
      donate,
      donateConfirmation,
      pdf,
      signup,
    } = FUNNEL_TYPES;

    const isStartNextReleaseContext =
      modalContext === FunnelContextArea.startNextReleaseButton;
    const isAccountCreationCTATestEnabled =
      !!accountData?.features["create_account_cta"] &&
      isStartNextReleaseContext;

    let funnelHeader;
    let funnelBody;
    let funnelFooter;

    if (!userData && !skipAuthentication) {
      let headerText = "";
      if (openFunnelType === login) {
        headerText = t("components.auth.login");
        funnelBody = <LoginModalBody {...funnelProps} />;
        funnelFooter = <LoginModalFooter />;
      } else if (openFunnelType === signupEmailPassword) {
        headerText = t("components.auth.signUp");
        funnelBody = <SignUpWithEmailPasswordModalBody {...funnelProps} />;
        funnelFooter = <SignUpWithEmailPasswordModalFooter {...funnelProps} />;
      } else if (openFunnelType === signup) {
        headerText = t("components.auth.signUp");

        const { signUpValueProps, signUpWelcomeText, signUpHeader } =
          getSignUpValueProps();

        headerText = signUpHeader ? signUpHeader : headerText;
        funnelBody = (
          <SignUpModalBody
            valueProps={
              isAccountCreationCTATestEnabled
                ? []
                : (valueProps ?? signUpValueProps ?? [])
            }
            welcomeText={welcomeText ?? signUpWelcomeText}
            triggeredByStartNextRelease={isStartNextReleaseContext}
            showGraphic={isAccountCreationCTATestEnabled}
            {...funnelProps}
          />
        );
        funnelFooter = <SignUpModalFooter {...funnelProps} />;
      }

      funnelHeader = (
        <AuthenticationHeader
          text={isStartNextReleaseContext ? undefined : headerText}
          locked={locked}
          {...funnelProps}
        />
      );
    } else {
      if (openFunnelType === subscription) {
        funnelBody = (
          <SubscriptionModalBody {...funnelProps} context={modalContext} />
        );
      } else if (openFunnelType === donate) {
        funnelHeader = <DonationModalHeader {...funnelProps} />;
        funnelBody = <DonationModalBody artistId={artistId} {...funnelProps} />;
      } else if (openFunnelType === donateConfirmation) {
        funnelHeader = <DonationModalHeader {...funnelProps} />;
        funnelBody = (
          <DonationConfirmationModalBody
            artist={donationArtist}
            {...funnelProps}
          />
        );
      } else if (openFunnelType === pdf) {
        funnelHeader = <PdfModalHeader {...funnelProps} />;
        funnelBody = <PdfModalBody release={release} {...funnelProps} />;
        funnelFooter = <PdfModalFooter release={release} {...funnelProps} />;
      } else if (openFunnelType === FUNNEL_TYPES.downloadPdf) {
        funnelHeader = <PdfModalHeader {...funnelProps} />;
        funnelBody = (
          <DownloadPdfModalBody release={release} {...funnelProps} />
        );
      } else if (openFunnelType === FUNNEL_TYPES.addToLibrary) {
        funnelHeader = (
          <ReadingStatusModalHeader cacheKey={cacheKey} {...funnelProps} />
        );
        funnelBody = (
          <ReadingStatusModalBody cacheKey={cacheKey} {...funnelProps} />
        );
      } else if (openFunnelType === FUNNEL_TYPES.onboarding) {
        funnelBody = <OnboardingModalBody {...funnelProps} />;
      } else if (openFunnelType === FUNNEL_TYPES.purchaseRelease) {
        funnelHeader = <PurchaseReleaseModalHeader {...funnelProps} />;
        funnelBody = (
          <PurchaseReleaseModalBody release={release} {...funnelProps} />
        );
        if (release) {
          funnelFooter = (
            <PurchaseReleaseModalFooter release={release} {...funnelProps} />
          );
        }
      } else if (openFunnelType === FUNNEL_TYPES.prePaywall) {
        if (release) {
          funnelBody = (
            <PrePaywallModalBody
              release={release as Release}
              {...funnelProps}
            />
          );
        }
      }
    }

    return {
      funnelHeader: funnelHeader,
      funnelBody: funnelBody ? (
        <Box
          key={openFunnelType}
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition=".5s linear"
          width="100%"
          bg="transparent"
        >
          {funnelBody}
        </Box>
      ) : (
        <Flex width="100%" justifyContent="center">
          <Spinner color="blaze.blaze" />
        </Flex>
      ),
      funnelFooter,
      onClose,
    };
  }, [locked, openFunnelType, userChanged, options?.onClose]);
}

const getFunnelTextForContext = (
  accountData: Account | undefined,
  contextArea?: FunnelContextArea,
) => {
  // For now value props are equal for all contexts
  const valueProps = SIGN_UP_FUNNEL_VALUE_PROPS.map((prop) =>
    t(prop, { defaultValue: "" }),
  ) as string[];

  let welcomeText;
  let header;
  switch (contextArea) {
    case FunnelContextArea.navBar:
      welcomeText = t("components.auth.welcomeText.createFreeAccount");
      break;
    case FunnelContextArea.followArtist:
      welcomeText = t("components.auth.welcomeText.getNotified");
      break;
    case FunnelContextArea.addToLibrary:
      welcomeText = t("components.auth.welcomeText.readTrackAndDiscover");
      break;
    case FunnelContextArea.donate:
      welcomeText = t("components.auth.welcomeText.stayConnected");
      break;
    case FunnelContextArea.startNextReleaseButton:
      header = t("components.auth.signUpHeader.saveYourReadingProgress");

      const isAccountCreationCTATestEnabled =
        !!accountData?.features["create_account_cta"];

      welcomeText = isAccountCreationCTATestEnabled
        ? t("components.auth.welcomeText.saveReadingProgressAndJoinCommunity")
        : t("components.auth.welcomeText.saveReadingProgressAndExplore");
      break;
  }

  return {
    signUpValueProps: valueProps,
    signUpWelcomeText: welcomeText,
    signUpHeader: header,
  };
};
