import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Comic } from "../../../types";
import { postToggleFavorite } from "../../../api/comics";
import { useCallback } from "react";
import { useUserData } from "../../../hooks/useUserData";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea } from "../../funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";

export function useToggleComicFavorite(cacheKey: string) {
  const toast = useToast();
  const { userData } = useUserData();
  const queryClient = useQueryClient();
  const { data } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { mutate } = useMutation({
    mutationFn: () => {
      return postToggleFavorite(data?.slug ?? "", !data?.is_favorite);
    },
    onMutate: async () => {
      await queryClient.setQueryData([cacheKey], {
        ...data,
        is_favorite: !data?.is_favorite,
        library_status: data?.is_favorite
          ? null
          : {
              entity_type: "ComicFavorite",
              comic_id: data?.id ?? 0,
              user_id: userData?.id ?? 0,
              comic_user_reading_status_id: 1,
              disable_notifications: 0,
              disable_emails: 0,
            },
      });
      toast({
        status: "success",
        title: `Comic  ${data?.is_favorite ? "unfavorite" : "favorite"}`,
      });
    },
    onError: (_, __, ___) => {
      toast({
        title: `Unable to ${data?.is_favorite ? "unfavorite" : "favorite"} comic`,
        status: "error",
      });
    },
  });

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.addToLibrary,
    comic: data,
  });
  const handleToggleFavorite = useCallback(() => {
    if (userData) {
      mutate();
    } else {
      setFunnelModalConfig({
        options: {
          onAuthenticated: () => mutate(),
          modalContext: FunnelContextArea.addToLibrary,
          navigationContext,
        },
        type: FUNNEL_TYPES.signup,
      });
    }
  }, [data]);

  return handleToggleFavorite;
}
