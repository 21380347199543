import React, { useEffect, useState } from "react";

export function useIsVisible(
  ref: React.RefObject<HTMLElement>,
  minVisiblePercentage = 0,
) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      {
        threshold: minVisiblePercentage,
      },
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
