import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ScreenLayout } from "../../components/layouts/components/ScreenLayout";
import { Comic, LayoutStyle, ServerListResponse } from "../../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { ModeToggle } from "../../components/resultsGrid/ModeToggle";
import { useInvalidateQueries } from "../../services/axiosInstance";
import { libraryFilterStore } from "./components/LibraryFilters";
import { SortMenus } from "../../components/sortMenus/SortMenus";
import { GET_LIBRARY_LIST_QUERY_KEY } from "./api/libraryScreenApi";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../appStore";
import { useLibraryAPI } from "./hooks/useLibraryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ResultsGrid } from "../../components/resultsGrid/ResultsGrid";
import { libraryResultsCard } from "../../components/resultsGrid/ComicsResultsCard";
import {
  DEFAULT_READING_STATUS_TYPE,
  ReadingStatusCarousel,
} from "./components/LibraryFilterCarousel";
import { throttle } from "lodash-es";
import { useScrollVisibility } from "../../hooks/useScrollVisibility";
import { CollapsibleNavBar } from "../../components/navbar/CollapsibleNavBar";
import { useNavigate } from "react-router";
import { useAccountData } from "../../hooks/useAccountData";
import { useTitleOnNavigation } from "../../hooks/useTitleOnNavigation";

export default function LibraryScreen() {
  const [t] = useTranslation();
  const isSmall = useIsSmallBreakpoint();

  useTitleOnNavigation(t("screens.library.manageLibrary"));

  const navigate = useNavigate();
  const accountData = useAccountData();
  useEffect(() => {
    if (accountData && !accountData.user) navigate("/");
  }, [accountData]);

  const [mode, setMode] = useState<LayoutStyle["mode"]>("grid");
  useEffect(() => {
    if (!isSmall && mode === "list") {
      setMode("grid");
    }
  }, [isSmall]);

  const orders = useHookstate(appStore.init.comic_library_sort_orders).get({
    noproxy: true,
  });

  const {
    urlSettled,
    countData,
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useLibraryAPI();

  const queryClient = useQueryClient();
  const allPages = useMemo(() => {
    data?.pages.forEach((page) => {
      page.data.payload.results.forEach((n) => {
        const cacheKey = `${n.entity_type}-${n.id}`;
        queryClient.setQueryData([cacheKey], n);
      });
    });

    const allPages = (data?.pages ?? []) as AxiosResponse<
      ServerListResponse<Comic>
    >[];
    return allPages;
  }, [data]);

  const readingStatusCounts = useMemo(() => {
    const readingStatusCounts: { [key: string]: number } = {};
    countData?.data.payload.results.forEach((status) => {
      readingStatusCounts[
        status.comic_user_reading_status_id ?? DEFAULT_READING_STATUS_TYPE
      ] = status.count;
    });
    return readingStatusCounts;
  }, [countData]);

  const invalidate = useInvalidateQueries(GET_LIBRARY_LIST_QUERY_KEY);
  const searchQuery = useHookstate(libraryFilterStore.query).get();
  useEffect(() => {
    if (!urlSettled) return;
    const timeout = setTimeout(() => {
      invalidate();
    }, 100);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const [initTerm, setInitTerm] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (urlSettled) {
      setInitTerm(libraryFilterStore.query.get());
    }
  }, [urlSettled]);

  const throttleSearch = useCallback(
    throttle(async (term) => {
      if (!urlSettled) return;
      libraryFilterStore.query.set(term);
    }, 300),
    [urlSettled],
  );

  const { handleScroll, isHidden } = useScrollVisibility();

  const gridAdjustmentMobile = isHidden ? "0px" : "264px";

  return (
    <ScreenLayout
      pageTitle={t("screens.library.title")}
      searchPlaceholder={t("screens.library.searchPlaceholder")}
      isTitleHidden={isSmall && isHidden}
      initValue={initTerm}
      onSearch={throttleSearch}
    >
      <Flex gap={isSmall ? 0 : 7} direction="column" width="full">
        <CollapsibleNavBar isCollapsed={isHidden} shouldCollapse={isSmall}>
          {isSmall && (
            <ReadingStatusCarousel
              readingStatusCounts={readingStatusCounts}
              invalidate={invalidate}
            />
          )}
        </CollapsibleNavBar>

        <CollapsibleNavBar isCollapsed={isHidden} shouldCollapse={isSmall}>
          <Flex
            justifyContent="space-between"
            pt={isSmall ? 6 : 2}
            pb={6}
            pl={isSmall ? 4 : 0}
            pr={isSmall ? 4 : 6}
            borderBottom="1px solid"
            borderColor="transparent.white.10"
          >
            {!isSmall && (
              <ReadingStatusCarousel
                readingStatusCounts={readingStatusCounts}
                invalidate={invalidate}
              />
            )}
            <SortMenus
              invalidate={invalidate}
              filterState={libraryFilterStore}
              sortOrders={orders}
            />
            {isSmall && <ModeToggle mode={mode} setMode={setMode} />}
          </Flex>
        </CollapsibleNavBar>
        <ResultsGrid
          gridHeightAdjustment={isSmall ? gridAdjustmentMobile : "250px"}
          onScroll={handleScroll}
          layout={libraryResultsCard(mode)}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          allPages={allPages}
        />
      </Flex>
    </ScreenLayout>
  );
}
