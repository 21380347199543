import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { t } from "i18next";
import { PaymentValueProps } from "./PaymentValueProps";
import { Release } from "../../../types";
import { ImmutableObject } from "@hookstate/core";
import { FunnelComponentProps } from "../../funnel/types";
import { ReleasePurchaseData } from "./ReleasePurchaseData";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { useUserData } from "../../../hooks/useUserData";

interface PurchaseReleaseModalBodyProps {
  release?: ImmutableObject<Release>;
}

export function PurchaseReleaseModalBody({
  release,
}: FunnelComponentProps & PurchaseReleaseModalBodyProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const { userData } = useUserData();
  const isGoldUser = userData?.gold ?? false;

  if (!release) return null;

  const pricePoint = isGoldUser
    ? (release.ppb_gold_price_point ?? release.ppb_price_point)
    : release.ppb_price_point;
  const price = (pricePoint ?? 1) / 100;

  return (
    <Flex flexDirection="column" gap="24px" width={"100%"}>
      <Flex alignSelf="start" flexDirection="row" width="100%">
        <Image
          borderRadius="10px"
          height={isSmallBreakpoint ? "139px" : "215px"}
          aspectRatio={0.645}
          src={release.thumbnail_url}
          alt="Release thumbnail"
          mr={6}
        />
        <Flex flexDirection="column" width="100%">
          <ReleasePurchaseData
            pagesTextSuffix={t("components.payments.purchaseRelease.pages")}
            release={release}
          />
          <Text variant="modalTitle" mt="20px" fontWeight={600} mr={2}>
            {`$${price}`}
          </Text>
        </Flex>
      </Flex>
      <Box width="100%" height="1px" background="transparent.white.10" />
      <PaymentValueProps
        valueProps={[
          [
            t("components.payments.pdf.unrestrictedAccess"),
            t("components.payments.pdf.toOnlineReading"),
          ],
          [
            t("components.payments.pdf.unlimitedDownloadAvailability"),
            t("components.payments.pdf.ownerDeletesBooks"),
          ],
        ]}
        alignItems="start"
        showSeparators={false}
      />
    </Flex>
  );
}
