import { ReactElement } from "react";
import { ReleaseContentCardProps } from "../../types";
import { ReleaseGridCard } from "./ReleaseGridCard";
import { ReleaseHorizontalCard } from "./ReleaseHorizontalCard";
import { ReleaseListCard } from "./ReleaseListCard";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext";
import { Link } from "@chakra-ui/react";
import { useSlideInMenu } from "../slideInMenu/hooks/useSlideInMenu";
import { useAccountData } from "../../hooks/useAccountData";
import { getCTAUrl } from "../../utils/getCTA";

export function ReleaseCard(props: ReleaseContentCardProps) {
  const mode = props.layout.style.mode;
  const cards: { [key: string]: ReactElement } = {
    grid: <ReleaseGridCard {...props} />,
    horizontal: <ReleaseHorizontalCard {...props} />,
    list: <ReleaseListCard {...props} />,
  };

  const accountData = useAccountData();
  const { openReleaseInfoMenu } = useSlideInMenu();
  const { goToCTAUrl, navigationContext } = useNavigateToReaderWithContext({
    releaseToNavigate: props.content,
    cardPosition: props.index,
    sectionPosition: props.sectionIndex,
    section: props.section,
  });

  if (cards[mode]) {
    return (
      <Link
        id={`release-${props.content.id}`}
        draggable={false}
        href={getCTAUrl(props.content)}
        onClick={(e) => {
          e.preventDefault();
          if (props.dontOpenInfo) return;
          if (accountData?.features["ppb"]) {
            openReleaseInfoMenu(
              props?.content.key ?? "",
              props?.content.comic.slug,
              navigationContext,
            );
          } else {
            goToCTAUrl();
          }
        }}
      >
        {cards[mode]}
      </Link>
    );
  }

  console.warn("Unable to determine layout mode for ReleaseCard");
  return null;
}
