import { useBreakpoint, Flex, Spinner, SimpleGrid } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { useMemo, CSSProperties, useCallback } from "react";
import { BrowseEmptyState } from "../../screens/browse/components/EmptyState";
import {
  LayoutStyle,
  DisplayFlags,
  ServerListResponse,
  Comic,
  Artist,
  Article,
  Release,
} from "../../types";
import { ContentCard } from "../cards/ContentCard";
import { sizeEstimator } from "../cards/hooks/useGridCardDimensions";
import { VirtualGrid } from "../grid/VirtualGrid";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

interface ResultsGridProps {
  gridHeightAdjustment: CSSProperties["height"];
  layout: { style: LayoutStyle; display: DisplayFlags };
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  hasNextPage: boolean;
  allPages: AxiosResponse<
    ServerListResponse<Comic | Artist | Article | Release>
  >[];
  columnPadding?: number;
  onScroll?: (scrollableRef: HTMLDivElement) => void;
}
export function ResultsGrid({
  gridHeightAdjustment,
  layout,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  allPages,
  columnPadding = 16,
  onScroll,
}: ResultsGridProps) {
  const breakpoint = useBreakpoint({ ssr: false });
  const dimensions = useMemo(() => {
    return sizeEstimator(
      layout.style.context,
      layout.style.mode,
      layout.style.size,
    );
  }, [layout]);

  const isSmallScreen = useIsSmallBreakpoint();

  const columnCount = useMemo(() => {
    if (layout.style.mode === "list") return 1;
    switch (breakpoint) {
      case "xs":
      case "sm":
        return 2;
      case "md":
      case "lg":
      case "xl":
        return 4;
      case "2xl":
        return 6;
      default:
        return 8;
    }
  }, [breakpoint, dimensions]);

  const { totalPages, showEmptyState } = useMemo(() => {
    return {
      totalPages: isLoading ? 0 : allPages.length,
      showEmptyState: !isLoading && !allPages?.[0]?.data.payload.results.length,
    };
  }, [allPages, isLoading]);

  const getHeight = useCallback(
    (
      page: AxiosResponse<
        ServerListResponse<Comic | Artist | Article | Release>
      >,
    ) => {
      const perPage = page?.data.payload.pagination.per_page ?? 24;
      return (perPage / columnCount) * (dimensions.height + columnPadding);
    },
    [dimensions, columnCount],
  );

  return (
    <Flex direction="column" gap={2} grow={1} width="100%">
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          grow={1}
          pt={isSmallScreen ? "16px" : undefined}
        >
          <Spinner color="blaze.blaze" />
        </Flex>
      ) : null}
      {showEmptyState ? <BrowseEmptyState /> : null}
      {!showEmptyState && allPages.length ? (
        <VirtualGrid
          onScroll={onScroll}
          data={allPages}
          overscan={1}
          scrollContainerWidth="100%"
          // Should be calculated to ensure the height contains each page, and
          // accounts for the header; 100% would cause the container to grow
          // with each resolved page. Some fixed value wouldn't be correct on
          // all screens
          scrollContainerHeight={`calc(100vh - ${gridHeightAdjustment})`}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          onFetchNextPage={fetchNextPage}
          renderItem={(page) => {
            const results = page?.data.payload.results ?? [];
            return (
              <SimpleGrid columns={columnCount} rowGap={columnPadding + "px"}>
                {[
                  ...results,
                  ...Array(24 - results.length).fill(undefined),
                ].map((item, i) => (
                  <Flex
                    key={i}
                    width="100%"
                    height="100%"
                    justifyContent="center"
                  >
                    <Flex
                      width={dimensions.width + "px"}
                      height={dimensions.height + "px"}
                    >
                      {item ? (
                        <ContentCard content={item} layout={layout} index={i} />
                      ) : null}
                    </Flex>
                  </Flex>
                ))}
              </SimpleGrid>
            );
          }}
          totalCount={totalPages}
          direction="vertical"
          measureElement={(el) => {
            const index = el.getAttribute("data-index") ?? -1;
            const page = allPages[index as number];
            return getHeight(page);
          }}
          estimateSize={(page) => getHeight(allPages?.[page])}
        />
      ) : null}
    </Flex>
  );
}
