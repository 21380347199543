import { Flex, Text } from "@chakra-ui/react";

export function BrowseEmptyState() {
  return (
    <Flex width="100%" justifyContent="center" p="24px">
      <Text
        fontFamily="Bricolage Grotesque"
        fontSize="20px"
        letterSpacing=".5px"
        fontWeight="700"
        color="dune.dune"
      >
        No results found
      </Text>
    </Flex>
  );
}
