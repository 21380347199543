import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { Comic, Release } from "../../../types";
import { ShareButton } from "../../buttons/share/ShareButton";
import { ComicReadingStyleBadge } from "../../cards/components/ComicReadingStyleBadge";
import { GCStaffPickAndExclusiveBadge } from "../../cards/components/GcStaffPickAndExclusiveBadge";
import { GCVerticalsAndOriginalsBadge } from "../../cards/components/GcVerticalsAndOriginalsBadge";
import { EyeIcon } from "../../icons/EyeIcon";
import { CalendarIcon } from "../../icons/CalendarIcon";
import { formatLargeNumber } from "../../../utils/formatLargeNumber";
import { getReleaseTypeName } from "../../../utils/getReleaseTypeName";

export function ReleaseInfoHeader({
  comic,
  release,
}: {
  comic: Comic;
  release: Release;
}) {
  return (
    <Flex flexDirection="column" gap="12px">
      <Flex flexDirection="column">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Text variant="releaseInfoHeaderSuperscript">
            {getReleaseTypeName(release)} #{release.chapter}
          </Text>
          <ShareButton
            title={t("components.slideInMenu.releaseInfoMenu.share")}
            shareUrl={release.share_url}
            color="dune.dune"
          />
        </Flex>
        <Text variant="bodyLargeBold">{release.title}</Text>
        <Text variant="bodySmallBold">{comic.artist.roman_name}</Text>
      </Flex>
      <Flex gap="12px">
        <Flex gap="5px" alignItems="center">
          <CalendarIcon boxSize="16px" color="electric_pink.300" />
          <Text variant="bodyExtraSmallMedium">
            {release.nice_published_time}
          </Text>
        </Flex>
        <Flex gap="5px" alignItems="center">
          <EyeIcon boxSize="16px" color="blue.300" />
          <Text variant="bodyExtraSmallMedium">
            {formatLargeNumber(release.total_pageviews)}
          </Text>
        </Flex>
      </Flex>
      <Flex gap="8px">
        <GCVerticalsAndOriginalsBadge comicData={comic} isLarge />
        <ComicReadingStyleBadge
          release={release}
          comic={comic}
          fontSize="10px"
          letterSpacing=".25px"
        />
        <GCStaffPickAndExclusiveBadge comicData={comic} isLarge />
      </Flex>
    </Flex>
  );
}
