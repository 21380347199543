import { useCheckbox, Flex, Box, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect } from "react";
import { CheckboxCheckedIcon } from "../../icons/CheckboxCheckedIcon";
import { CheckboxUncheckedIcon } from "../../icons/CheckboxUncheckedIcon";

interface DonationRecurrentCheckboxProps {
  onChange: (isChecked: boolean) => void;
}

export function DonationRecurrentCheckbox({
  onChange,
}: DonationRecurrentCheckboxProps) {
  const { state, getInputProps } = useCheckbox();
  const input = getInputProps();

  useEffect(() => {
    onChange(state.isChecked);
  }, [state.isChecked]);

  return (
    <Flex flexDirection="row">
      <Box as="label" cursor="pointer" role="group">
        <input {...input} hidden />

        {state.isChecked ? (
          <CheckboxCheckedIcon
            color="success.500"
            w={5}
            h={5}
            fontWeight={600}
          />
        ) : (
          <CheckboxUncheckedIcon
            _groupHover={{ color: "transparent.white.80" }}
            color="transparent.white.60"
            w={5}
            h={5}
          />
        )}
      </Box>
      <Flex flexDirection="column" ml={4} alignItems="start">
        <Text variant="modalBody" mb="4px">
          {t("components.payments.donate.repeatMonthly")}
        </Text>
        <Text variant="modalDescription">
          {t("components.payments.donate.becomeASuperfan")}
        </Text>
      </Flex>
    </Flex>
  );
}
