import { useQueryClient } from "@tanstack/react-query";
import Axios from "axios";

let Authorization;
try {
  Authorization = `Bearer ${localStorage.getItem("gc_authentication_token")}`;
} catch (error) {
  console.error("Local storage not available");
}
export const apiClient = Axios.create({
  baseURL: import.meta.env.VITE_API_URL || window.gc.global.api_url,
  headers: {
    "X-Gc-Client": import.meta.env.VITE_API_KEY || window.gc.global.api_key,
    "X-Gc-Identmode": "cookie",
    Authorization,
  },
  withCredentials: true,
});

apiClient.interceptors.request.use(
  (config) => {
    // @ts-expect-error types out of date
    const session = window.mixpanel?.get_session_recording_properties();
    const $replayId = session?.$mp_replay_id;
    if ($replayId) {
      config.headers["mp-replay-id"] = $replayId;
    }
    return config;
  },
  null,
  { synchronous: true },
);

export function useInvalidateQueries(
  ...keys: (string | number | undefined)[][]
): () => void {
  const queryClient = useQueryClient();

  return () => {
    keys.forEach((queryKey) => {
      queryClient.invalidateQueries({ queryKey });
    });
  };
}
