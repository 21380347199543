import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
} from "@chakra-ui/react";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { isArray } from "lodash-es";

interface ButtonCarouselProps {
  big?: boolean;
  children: React.ReactNode;
  wrapperProps?: FlexProps;
  containerProps?: FlexProps;
  includeButtons?: boolean;
  includeSpacer?: boolean;
  width?: BoxProps["width"];
  slidesToScroll?: number;
  showScrollbar?: boolean;
  onlyFade?: boolean;
  dontFadeButtons?: boolean;
  activeSlideIndex?: number;
}

export function ButtonCarousel({
  big = false,
  children,
  wrapperProps,
  containerProps,
  includeButtons = true,
  includeSpacer = false,
  width = "calc(100% - 190px)",
  slidesToScroll = 5,
  showScrollbar = false,
  onlyFade = false,
  dontFadeButtons = false,
  activeSlideIndex,
}: ButtonCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll,
    align: "start",
    skipSnaps: true,
  });
  const isSmall = useIsSmallBreakpoint();

  const { prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(emblaApi);

  const handlePrevButtonClick = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const handleNextButtonClick = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const mask = `
      linear-gradient(
        to right, 
        ${prevBtnDisabled ? "black" : "transparent"} 0%, 
        black 13%,
        black 87%,
        ${nextBtnDisabled ? "black" : "transparent"} 100% 
      )
    `;

  useEffect(() => {
    if (emblaApi && activeSlideIndex !== undefined) {
      emblaApi.scrollTo(activeSlideIndex);
    }
  }, [emblaApi, activeSlideIndex]);

  if (!isArray(children) || children.length === 0) return null;
  return (
    <Box
      alignItems="center"
      position="relative"
      width={width}
      aria-roledescription="carousel"
      {...wrapperProps}
    >
      <Box
        ref={showScrollbar ? undefined : emblaRef}
        overflow="hidden"
        sx={
          (prevBtnDisabled && nextBtnDisabled) || !includeButtons
            ? {}
            : { mask }
        }
      >
        <Flex
          overflow={showScrollbar ? "auto" : undefined}
          {...containerProps}
          // This shouldn't be overridden
          sx={
            showScrollbar
              ? undefined
              : {
                  backfaceVisibility: "hidden",
                  touchAction: "pan-y pinch-zoom",
                }
          }
        >
          {children}
          {includeSpacer && <Box minWidth={4} />}
        </Flex>
      </Box>
      {onlyFade || includeButtons ? (
        <>
          <PrevButton
            big={big}
            onClick={handlePrevButtonClick}
            isDisabled={isSmall || prevBtnDisabled}
            showButtons={!onlyFade}
            dontFadeButtons={dontFadeButtons}
          />
          <NextButton
            big={big}
            onClick={handleNextButtonClick}
            isDisabled={isSmall || nextBtnDisabled}
            showButtons={!onlyFade}
            dontFadeButtons={dontFadeButtons}
          />
        </>
      ) : null}
    </Box>
  );
}

export function PrevButton(
  props: ButtonProps & {
    big?: boolean;
    showButtons?: boolean;
    alwaysShowButtons?: boolean;
    dontFadeButtons?: boolean;
  },
) {
  const { big, showButtons, dontFadeButtons, ...rest } = props;
  return (
    <Flex
      position="absolute"
      role="group"
      left={big ? "20px" : 0}
      top={0}
      alignItems="center"
      bg={
        big
          ? "transparent"
          : "linear-gradient(90deg, var(--chakra-colors-charcoal-charcoal) 0%, var(--chakra-colors-charcoal-charcoal) 72.5%, rgba(20, 21, 23, 0.00) 100%)"
      }
      opacity={props.isDisabled ? 0 : 1}
      pointerEvents={props.isDisabled ? "none" : "all"}
      height="100%"
    >
      {showButtons && (
        <Button
          transition="all .2s linear"
          variant="iconOnly"
          p={0}
          color={!dontFadeButtons && big ? "transparent" : "dune.700"}
          _groupHover={{ color: "dune.100" }}
          {...rest}
          aria-label="Previous Button"
        >
          <ArrowLeftIcon w={big ? 10 : 6} h={big ? 10 : 6} />
        </Button>
      )}
    </Flex>
  );
}

export function NextButton(
  props: ButtonProps & {
    big?: boolean;
    showButtons?: boolean;
    dontFadeButtons?: boolean;
  },
) {
  const { big, showButtons, dontFadeButtons, ...rest } = props;
  return (
    <Flex
      position="absolute"
      role="group"
      top={0}
      right={big ? "20px" : 0}
      alignItems="center"
      bg={
        big
          ? "transparent"
          : "linear-gradient(270deg, var(--chakra-colors-charcoal-charcoal) 0%, var(--chakra-colors-charcoal-charcoal) 72.5%, rgba(20, 21, 23, 0.00) 100%)"
      }
      opacity={props.isDisabled ? 0 : 1}
      pointerEvents={props.isDisabled ? "none" : "all"}
      height="100%"
    >
      {showButtons && (
        <Button
          transition="all .2s linear"
          variant="iconOnly"
          color={!dontFadeButtons && big ? "transparent" : "dune.700"}
          _groupHover={{ color: "dune.100" }}
          {...rest}
          aria-label="Next Button"
        >
          <ArrowRightIcon w={big ? 10 : 6} h={big ? 10 : 6} />
        </Button>
      )}
    </Flex>
  );
}

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};
export const usePrevNextButtons = (
  emblaApi?: EmblaCarouselType,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};
