import { useState, useEffect, useRef, useCallback } from "react";
import { Box, Flex } from "@chakra-ui/react";
import {
  EmblaOptionsType,
  EmblaEventType,
  EmblaCarouselType,
} from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import { useIsLargeBreakpoint } from "../../utils/useBreakpoints.ts";
import PausePlayButton from "./EmblaCarouselPausePlayButton";
import CarouselSlideCounter from "./CarouselSlideCounter.tsx";

interface EmblaCarouselProps {
  slides: (emblaApi: EmblaCarouselType | undefined) => JSX.Element[];
  slideType: "hero" | "featureRow" | "featureRoadblock";
  options?: EmblaOptionsType;
  onSlideChange?: (index: number) => void;
}

const EmblaCarousel: React.FC<EmblaCarouselProps> = ({
  slides,
  slideType,
  options,
  onSlideChange,
}) => {
  const isLarge = useIsLargeBreakpoint();
  // autoplay is off for small screen sizes
  const [isPlaying, setIsPlaying] = useState(isLarge);
  const autoplayOptions = {
    delay: 5000,
    stopOnInteraction: false,
    stopOnFocusIn: false,
  };

  const isHeroCarousel = slideType === "hero";
  const isFeatureRoadblockCarousel = slideType === "featureRoadblock";

  const autoplay = useRef(Autoplay(autoplayOptions));
  const [emblaRef, emblaApi] = useEmblaCarousel(
    options,
    isLarge && !isFeatureRoadblockCarousel ? [autoplay.current] : [],
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const handleAutoplayPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handleAutoplayStop = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const updateCurrentSlide = () => {
      const newIndex = emblaApi ? emblaApi.selectedScrollSnap() : 0;
      setCurrentSlide(newIndex);
      if (onSlideChange) {
        onSlideChange(newIndex);
      }
      autoplay.current.reset();
    };

    if (emblaApi) {
      emblaApi.on("select", updateCurrentSlide);
      updateCurrentSlide();

      emblaApi.on("autoplay:play" as EmblaEventType, handleAutoplayPlay);
      emblaApi.on("autoplay:stop" as EmblaEventType, handleAutoplayStop);

      setIsPlaying(autoplay.current.isPlaying());
    }

    return () => {
      if (emblaApi) {
        emblaApi.off("select", updateCurrentSlide);
        emblaApi.off("autoplay:play" as EmblaEventType, handleAutoplayPlay);
        emblaApi.off("autoplay:stop" as EmblaEventType, handleAutoplayStop);
      }
    };
  }, [
    emblaApi,
    onSlideChange,
    handleAutoplayPlay,
    handleAutoplayStop,
    isLarge,
  ]);

  const handlePrevButtonClick = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      autoplay.current.reset();
    }
  };

  const handleNextButtonClick = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
      autoplay.current.reset();
    }
  };

  const handlePausePlayClick = () => {
    if (autoplay.current.isPlaying()) {
      autoplay.current.stop();
    } else {
      autoplay.current.play();
    }
    setIsPlaying(autoplay.current.isPlaying());
  };

  const { prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(emblaApi);

  const isFeatureCardCarousel = slideType === "featureRow";
  const slideElements = slides(emblaApi);
  const isLastSlide = currentSlide >= slideElements.length - 1;

  const showOnRoadblock =
    isFeatureRoadblockCarousel && slideElements.length > 1;

  return (
    <Box w="100%" margin="auto">
      <Box
        ref={(node) => {
          emblaRef(node);
          scrollContainerRef.current = node;
        }}
        overflow="hidden"
        w="100%"
        role="region"
        aria-roledescription="carousel"
        aria-label="Highlighted comics and creators"
        aria-live={isPlaying ? "off" : "polite"}
        id="carousel-container"
        sx={{
          /// Add rounded borders to carousel when it is a hero carousel
          borderTopLeftRadius: slideType === "hero" ? "20px" : undefined,
          borderBottomLeftRadius: slideType === "hero" ? "20px" : undefined,
        }}
      >
        <Flex
          role="list"
          gap={isFeatureRoadblockCarousel ? 12 : undefined}
          sx={{
            backfaceVisibility: "hidden",
            touchAction: "pan-y pinch-zoom",
          }}
        >
          {slideElements.map((slide, index) => (
            <Box
              key={index}
              flex={isFeatureRoadblockCarousel ? "0 0 100%" : "0 0 50%"}
              mr={
                isFeatureCardCarousel || isFeatureRoadblockCarousel
                  ? "24px"
                  : {
                      base: "12px",
                      sm: "16px",
                      md: "22px",
                      "3xl": "31px",
                      "4xl": "46px",
                    }
              }
              role="listitem"
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${slideElements.length}`}
              tabIndex={currentSlide === index ? 0 : -1}
            >
              {slide}
            </Box>
          ))}
          {isFeatureCardCarousel ||
            (isFeatureRoadblockCarousel && <Box minWidth={4} />)}
        </Flex>
      </Box>
      {isFeatureCardCarousel && (
        <Flex justifyContent="center" mt="26px" fontSize="10px">
          <CarouselSlideCounter
            currentSlide={currentSlide}
            slidesCount={slideElements.length}
          />
        </Flex>
      )}
      {(showOnRoadblock || (!isFeatureRoadblockCarousel && isLarge)) && (
        <Flex
          justifyContent={isFeatureRoadblockCarousel ? "center" : "start"}
          gap={{ base: "15px", "2xl": "16px", "3xl": "20px", "4xl": "30px" }}
          my={{
            base: "22px",
            "2xl": "24px",
            "3xl": "31px",
            "4xl": "46px",
          }}
          fontSize={{
            base: "9px",
            "2xl": "10px",
            "3xl": "13px",
            "4xl": "19px",
          }}
          alignItems="center"
        >
          <PrevButton
            onClick={handlePrevButtonClick}
            disabled={!isHeroCarousel && prevBtnDisabled}
            aria-label="Previous Slide"
            aria-controls="carousel-container"
          />
          <CarouselSlideCounter
            currentSlide={currentSlide}
            slidesCount={slideElements.length}
          />
          <NextButton
            onClick={handleNextButtonClick}
            disabled={!isHeroCarousel && (nextBtnDisabled || isLastSlide)}
            aria-label="Next Slide"
            aria-controls="carousel-container"
          />

          {!isFeatureRoadblockCarousel && (
            <PausePlayButton
              aria-label={
                isPlaying ? "Pause Auto-Rotation" : "Play Auto-Rotation"
              }
              aria-controls="carousel-container"
              isPlaying={isPlaying}
              onClick={handlePausePlayClick}
            />
          )}
        </Flex>
      )}
    </Box>
  );
};

export default EmblaCarousel;
