import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { Comic } from "../../../../types";

export function useComicData() {
  const comicData = useHookstate(readerDataStore.comicData).get({
    noproxy: true,
  });

  const setComicData = (data: Comic) => {
    readerDataStore.comicData.set(data);
  };

  return { comicData, setComicData };
}
