import { useCallback } from "react";
import { Page } from "../../../types";
import { useMobileBreakpoint } from "../../../utils/useBreakpoints";
import { PAGE_SIZING_TYPES, READER_PADDING } from "../constants";
import { usePageSize } from "./usePageSize";
import { useP2PSettings } from "./useP2PSettings";
import { useReleaseFragmentsData } from "./hookstate/useReleaseFragmentsData";

export function useImageSizing(
  comicIsVertical: boolean = false,
  isDoubleReading: boolean = false,
) {
  const { pageSizing } = usePageSize();
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { activePageFragments } = useReleaseFragmentsData();
  const isMobile = useMobileBreakpoint();

  const imagePadding = READER_PADDING * (isMobile ? 0 : 2);

  const getImageSize = useCallback(
    (page: Page | null | undefined) => {
      let size = { imageHeight: 0, imageWidth: 0 };
      if (!page) return size;

      const target = document.getElementById("readerContainer");
      const targetDimensions = target?.getBoundingClientRect();
      const innerWidth = targetDimensions
        ? targetDimensions.width - imagePadding
        : 0;
      const innerHeight = targetDimensions ? targetDimensions.height : 0;

      const [pageHeight, pageWidth] = getImageDimensions(page);
      const xAspect = page.aspect_x;
      const yAspect = 1 / xAspect;

      const p2p = p2pEnabledAndAugmented && activePageFragments.length;
      const ftw = pageSizing === PAGE_SIZING_TYPES.width;
      const autoSize = pageSizing === PAGE_SIZING_TYPES.auto;

      if (comicIsVertical || ftw || p2p) {
        // calculateAspectRatioFit determines the largest possible image
        // based on a max height.
        let imageHeight = Math.round(innerWidth * yAspect);
        if (!p2p && isDoubleReading) {
          imageHeight = Math.round(imageHeight / 2);
        }

        if (comicIsVertical) {
          if (window.innerWidth > 800 && imageHeight * xAspect > 800) {
            imageHeight = Math.round(800 * yAspect);
          } else if (window.innerWidth < 800 && imageHeight * xAspect > 800) {
            imageHeight = Math.round(window.innerWidth * yAspect);
          }
        }

        return {
          imageHeight,
          imageWidth: Math.round(imageHeight * xAspect),
        };
      } else {
        const doubleMaxWidth = Math.round((innerWidth + imagePadding * 2) / 2);

        let maxWidth = isDoubleReading ? doubleMaxWidth : innerWidth;

        return calculateAspectRatioFit(
          {
            imageWidth: pageHeight,
            imageHeight: pageWidth,
          },
          maxWidth,
          autoSize && !isDoubleReading ? pageHeight : innerHeight,
        );
      }
    },
    [
      pageSizing,
      comicIsVertical,
      isMobile,
      activePageFragments,
      p2pEnabledAndAugmented,
    ],
  );

  const getImageDimensions = useCallback(
    (page: Page) => {
      if (isMobile) {
        return [page.mobile_width, page.mobile_height];
      }
      return [page.desktop_width, page.desktop_height];
    },
    [isMobile],
  );

  const getImageSrc = (page: Page | null) => {
    if (!page) return "";
    if (isMobile) {
      return page.mobile_image_url;
    }
    return page.desktop_image_url;
  };

  // Figure out the tallest possible image we can fit in the window
  const calculateAspectRatioFit = (
    imageSize: {
      imageWidth: number;
      imageHeight: number;
    },
    innerWidth: number,
    innerHeight: number,
  ) => {
    const { imageWidth: srcWidth, imageHeight: srcHeight } = imageSize;

    const maxWidth = innerWidth - imagePadding;
    const maxHeight = innerHeight - imagePadding;
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return {
      imageWidth: Math.round(srcWidth * ratio),
      imageHeight: Math.round(srcHeight * ratio),
    };
  };

  return {
    pageSizing,
    getImageSize,
    getImageDimensions,
    getImageSrc,
  };
}
