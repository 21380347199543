export const slideInMenu = {
  comicDetailsMenu: {
    comicDetails: "Series info",
    free: "free",
    nsfw: "nsfw",
    views: "views",
    shelves: "shelves",
    releases: "Releases",
    audience: "audience",
    description: "Description",
    show_more_details: "Show more details",
    hide_details: "Hide details",
    contributors: "Contributors",
    related: "Related",
    continueReading: "Continue reading",
    startReading: "Start reading",
    editComic: "Edit series",
    crowdfundingCta:
      "Help raise <bold> ${{amount}} </bold> for this title on {{service}}",
  },
  releaseInfoMenu: {
    releaseInfo: "Release info",
    issue: "Issue",
    share: "Share",
    page: "Page",
    editRelease: "Edit release",
    continueReading: "Continue reading",
    startReading: "Start reading",
    preview: "Preview",
    freeWithGold: "Free with Gold",
    buy: "Buy",
    buyWithGold: "Buy {{price}} with Gold",
    getWithAGoldMembership:
      "Get {{discount}}% off with a GlobalComix Gold membership.",
    youSavedAsAGoldMember: "You saved {{discount}}% as a Gold member!",
    cover: "Cover",
  },
};
