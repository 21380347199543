import { Flex } from "@chakra-ui/react";
import { PAGE_LAYOUT_TYPES } from "../../constants";
import { SettingButton } from "./SettingButton";
import { usePageLayout } from "../../hooks/usePageLayout";
import { SingleLayoutIcon } from "../../../../components/icons/reader/SingleLayoutIcon";
import { DoubleLayoutIcon } from "../../../../components/icons/reader/DoubleLayoutIcon";
import { VerticalLayoutIcon } from "../../../../components/icons/reader/VerticalLayoutIcon";
import { AutoLayoutIcon } from "../../../../components/icons/reader/AutoLayoutIcon";
import { t } from "i18next";
import { SectionHeader } from "../shared/SectionHeader";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { useReadingDirection } from "../../hooks/useReadingDirection";

export function PageLayoutSelector() {
  const { p2pEnabledAndAugmented } = useP2PSettings();
  const { comicIsVertical } = useReadingDirection();
  const { setPageLayout, pageLayout } = usePageLayout();

  return (
    <>
      <SectionHeader text={t("screens.reader.settingsMenu.page_layout")} />
      <Flex gap="12px" justifyContent="center">
        <SettingButton
          isDisabled
          text={t("screens.reader.settingsMenu.auto")}
          isActive={PAGE_LAYOUT_TYPES.auto === pageLayout}
          onClick={() => setPageLayout(PAGE_LAYOUT_TYPES.auto)}
          size="75px"
          icon={<AutoLayoutIcon height="50px" width="50px" />}
        />
        <SettingButton
          text={t("screens.reader.settingsMenu.single_page")}
          isActive={!comicIsVertical && PAGE_LAYOUT_TYPES.single === pageLayout}
          onClick={() => setPageLayout(PAGE_LAYOUT_TYPES.single)}
          size="75px"
          icon={<SingleLayoutIcon height="50px" width="50px" />}
          isDisabled={p2pEnabledAndAugmented || comicIsVertical}
        />
        <SettingButton
          text={t("screens.reader.settingsMenu.double_page")}
          isActive={!comicIsVertical && PAGE_LAYOUT_TYPES.double === pageLayout}
          onClick={() => setPageLayout(PAGE_LAYOUT_TYPES.double)}
          size="75px"
          icon={<DoubleLayoutIcon height="50px" width="50px" />}
          isDisabled={p2pEnabledAndAugmented || comicIsVertical}
        />
        <SettingButton
          text={t("screens.reader.settingsMenu.vertical")}
          isActive={
            comicIsVertical || PAGE_LAYOUT_TYPES.vertical === pageLayout
          }
          onClick={() => setPageLayout(PAGE_LAYOUT_TYPES.vertical)}
          size="75px"
          icon={<VerticalLayoutIcon height="50px" width="50px" />}
          isDisabled={!!p2pEnabledAndAugmented}
        />
      </Flex>
    </>
  );
}
