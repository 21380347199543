import {
  FunnelContextArea,
  FunnelNavigationContext,
} from "../components/funnel/types";
import { Comic, Release } from "../types";
import { readerDataStore } from "../screens/reader/ReaderStateWrapper";
import { useMemo } from "react";
import { useHookstate } from "@hookstate/core";
import { rootComponentsStore } from "../components/layouts/RootLayout";

export function useGetNavigationContext({
  contextArea,
  release,
  comic,
  artistId,
}: {
  contextArea: FunnelContextArea;
  release?: Release;
  comic?: Comic;
  artistId?: number;
}): FunnelNavigationContext {
  let releaseData = useHookstate(readerDataStore.releaseData).get();
  let comicData = useHookstate(readerDataStore.comicData).get();
  const contextReferrer = rootComponentsStore.eventContextReferrer.get();

  return useMemo<FunnelNavigationContext>(() => {
    const currentRelease = release ?? releaseData;
    const currentComic =
      release?.comic ?? comic ?? releaseData?.comic ?? comicData;

    switch (contextArea) {
      case FunnelContextArea.followArtist:
      case FunnelContextArea.donate:
        return {
          contextArea,
          data: {
            artistId,
          },
        };
      case FunnelContextArea.addToLibrary:
        return {
          contextArea,
          data: {
            comicId: currentComic?.id,
            comicName: currentComic?.name,
          },
        };
      case FunnelContextArea.comment:
      case FunnelContextArea.reader:
      case FunnelContextArea.purchasePdf:
      case FunnelContextArea.reaction:
      case FunnelContextArea.startNextReleaseButton:
      case FunnelContextArea.releaseDetails:
      case FunnelContextArea.releasesList:
      case FunnelContextArea.ppbPurchase:
        return {
          contextArea,
          data: {
            comicId: currentComic?.id,
            comicName: currentComic?.name,
            releaseId: currentRelease?.id,
            releaseOrder: currentRelease?.order,
          },
        };
      case FunnelContextArea.settings:
      case FunnelContextArea.gold:
      case FunnelContextArea.navBar:
        return {
          contextArea,
          contextReferrer,
        };
      default: {
        return {
          contextArea,
          contextReferrer,
        };
      }
    }
  }, [contextArea, artistId, releaseData, comicData, release, comic]);
}
