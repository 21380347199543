import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { useLocation } from "react-router";
import { TermsOfUse } from "./components/TermsOfUse";
import { ButtonTabs } from "../../components/tabs/ButtonTabs";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { ContentPolicy } from "./components/ContentPolicy";
import { PublisherTerms } from "./components/PublisherTerms";
import { CollapsibleNavBar } from "../../components/navbar/CollapsibleNavBar";
import { useScrollVisibility } from "../../hooks/useScrollVisibility";
import React, { useEffect } from "react";

export function LegalScreen() {
  const location = useLocation();
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const shouldTake80Percent = useBreakpointValue({ base: false, md: true });
  const { handleScroll, isHidden } = useScrollVisibility();
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [location]);

  const onScroll = () => {
    if (!scrollableRef.current) return;
    handleScroll?.(scrollableRef.current);
  };

  return (
    <Flex height="100%" width="100%" alignItems="center" direction="column">
      <Flex
        flexDirection="column"
        width={shouldTake80Percent ? "80%" : "100%"}
        maxWidth="960px"
        height="100%"
      >
        <CollapsibleNavBar
          shouldCollapse={isSmallBreakpoint}
          isCollapsed={isHidden}
        >
          <ButtonTabs
            paddingTop={isSmallBreakpoint ? "16px" : "40px"}
            paddingBottom={isSmallBreakpoint ? "16px" : "20px"}
            marginLeft={isSmallBreakpoint ? "24px" : undefined}
            tabs={[
              {
                name: "Privacy Policy",
                link: "/privacy-policy",
                isActive: location.pathname === "/privacy-policy",
              },
              {
                name: "Terms of Use",
                link: "/terms-of-use",
                isActive: location.pathname === "/terms-of-use",
              },
              {
                name: "Content Policy",
                link: "/content-policy",
                isActive: location.pathname === "/content-policy",
              },
              {
                name: "Publisher Terms of Service",
                link: "/publisher-terms-of-service",
                isActive: location.pathname === "/publisher-terms-of-service",
              },
            ]}
          />
        </CollapsibleNavBar>
        <Flex
          height="100%"
          overflow="auto"
          direction="column"
          paddingX={isSmallBreakpoint ? "24px" : undefined}
          ref={scrollableRef}
          onScroll={onScroll}
          css={
            !isSmallBreakpoint && {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }
          }
        >
          {(() => {
            switch (location.pathname) {
              case "/privacy-policy":
                return <PrivacyPolicy />;
              case "/terms-of-use":
                return <TermsOfUse />;
              case "/content-policy":
                return <ContentPolicy />;
              case "/publisher-terms-of-service":
                return <PublisherTerms />;
              default:
                return null;
            }
          })()}
        </Flex>
      </Flex>
    </Flex>
  );
}
