import { Icon, IconProps } from "@chakra-ui/react";

export function CreatorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 14.7022 4.76096 17.11 6.72657 18.6668C8.08087 17.3277 9.94398 16.5 11.9998 16.5C14.0558 16.5 15.9189 17.3278 17.2733 18.667C19.239 17.1101 20.5 14.7023 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM12 20.5C10.3735 20.5 8.85369 20.0432 7.5619 19.2508C8.72402 18.1643 10.2841 17.5 11.9998 17.5C13.7157 17.5 15.2758 18.1643 16.4379 19.2509C15.1462 20.0432 13.6264 20.5 12 20.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM9.5 11C9.5 9.61929 10.6193 8.5 12 8.5C13.3807 8.5 14.5 9.61929 14.5 11C14.5 12.3807 13.3807 13.5 12 13.5C10.6193 13.5 9.5 12.3807 9.5 11ZM12 7.5C10.067 7.5 8.5 9.067 8.5 11C8.5 12.933 10.067 14.5 12 14.5C13.933 14.5 15.5 12.933 15.5 11C15.5 9.067 13.933 7.5 12 7.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
