import {
  Flex,
  Box,
  Text,
  useBreakpointValue,
  Center,
  Divider,
} from "@chakra-ui/react";
import { GCChannel } from "../../../types";
import { StreamTabs } from "../../../components/tabs/StreamTabs";
import { CollapsibleNavBar } from "../../../components/navbar/CollapsibleNavBar";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

interface HeaderNavigationBarProps {
  showChannelName: boolean;
  activeChannel: GCChannel;
  activeStreamId?: string;
  isCollapsed?: boolean;
}

export function HeaderNavigationBar({
  showChannelName,
  activeChannel,
  activeStreamId,
  isCollapsed,
}: HeaderNavigationBarProps) {
  /// Determine if the navbar should wrap to a column layout on smaller screens, and if the navbar should
  /// be collapsed.
  const shouldWrap = useBreakpointValue({ base: true, md: false });
  const isSmallScreen = useIsSmallBreakpoint();

  const showStreams = activeChannel.streams.length > 1;

  return (
    <CollapsibleNavBar
      shouldCollapse={isSmallScreen}
      isCollapsed={isCollapsed}
      isWrapped={showChannelName && showStreams}
    >
      <Box
        pb={4}
        bgColor="charcoal.charcoal"
        ml={{ base: 4, lg: 0 }}
        pt={{ base: 4, lg: 8 }}
      >
        <Flex
          direction={!shouldWrap ? "row" : "column"}
          alignItems={!shouldWrap ? "center" : "flex-start"}
          gap={!shouldWrap ? undefined : 3}
        >
          {showChannelName && (
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              width="auto"
              flexShrink={0}
              flexWrap={"nowrap"}
            >
              <img
                src={activeChannel.config[0].icon} // Always retrieving dark mode icon
                alt="channel icon"
                style={{ width: "38px", height: "auto" }}
              />

              <Text
                variant={"navbar"}
                color="dune.dune"
                fontWeight={700}
                fontSize={{ base: "18px", md: "24px" }}
                align={"center"}
                px={{ base: 3, md: 4 }}
                whiteSpace={"nowrap"}
              >
                {activeChannel.display_name}
              </Text>
              {!shouldWrap && (
                <Center height="40px">
                  <Divider
                    height="40px"
                    orientation="vertical"
                    pl={2}
                    visibility={
                      activeChannel.streams.length > 1 ? "visible" : "hidden"
                    }
                  />
                </Center>
              )}
            </Flex>
          )}
          {showStreams && (
            <Box overflowX="auto" width="100%">
              <StreamTabs
                streams={activeChannel.streams}
                activeStreamId={activeStreamId}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </CollapsibleNavBar>
  );
}
