import { Text, Flex, Box, Link, Button } from "@chakra-ui/react";
import { LoginForm } from "./LoginForm";
import { SsoSection } from "./SsoSection";
import { LoginOptionsDivider } from "./LoginOptionsDivider";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import { t } from "i18next";
import { FunnelComponentProps } from "../../funnel/types";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";

export function LoginModalBody({
  onSuccess,
  setFunnelType,
  invalidate = true,
  loading,
  loadingText,
}: FunnelComponentProps & {
  invalidate?: boolean;
  loading?: boolean;
  loadingText?: string;
}) {
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateQueries = useInvalidateQueries(
    ACCOUNT_INIT_QUERY_KEY,
    releaseQueryKey as string[],
  );

  return (
    <Flex direction="column" width="100%">
      <LoginForm
        onClose={onSuccess}
        loading={loading}
        loadingText={loadingText}
      />
      <LoginOptionsDivider />
      <SsoSection
        hideEmail={true}
        setFunnelType={setFunnelType}
        onAuthenticationSuccess={() => {
          if (invalidate) invalidateQueries();
          onSuccess();
        }}
      />
      <Flex direction="column" paddingTop="16px">
        <LoginOptionsDivider />
        <Button
          variant="tertiary"
          width="full"
          size="lg"
          onClick={() => setFunnelType(FUNNEL_TYPES.signup)}
        >
          Create account
        </Button>
      </Flex>
      <Box textAlign="center" mt="24px">
        <Text fontSize="sm" color="neutral.400" fontWeight={500}>
          {t("components.auth.signInToAgree")}{" "}
          <Link
            onClick={() => window.open("/terms")}
            color="neutral.200"
            textDecoration="underline"
          >
            {t("components.auth.terms")}
          </Link>
        </Text>
      </Box>
    </Flex>
  );
}

export function LoginModalFooter() {
  return (
    <Text fontSize="14px" fontWeight={500} color={"neutral.300"} align="center">
      <Link
        textDecoration="underline"
        onClick={() => window.open("/auth/reset-password")}
      >
        {t("components.auth.forgotPassword")}
      </Link>
    </Text>
  );
}
