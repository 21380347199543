import { useHookstate } from "@hookstate/core";
import { readerComponentsStore } from "../ReaderStateWrapper";
import { useCallback } from "react";

export function useEndInterstitial() {
  const showEndInterstitial = useHookstate(
    readerComponentsStore.showEndInterstitial,
  ).get({ noproxy: true });

  const toggleShowEndInterstitial = useCallback(() => {
    readerComponentsStore.showEndInterstitial.set(!showEndInterstitial);
  }, [showEndInterstitial]);

  const setShowEndInterstitial = (value: boolean) => {
    readerComponentsStore.showEndInterstitial.set(value);
  };

  return {
    showEndInterstitial,
    toggleShowEndInterstitial,
    setShowEndInterstitial,
  };
}
