import { Icon, IconProps } from "@chakra-ui/react";

export function DownloadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="arrow">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
          fill="currentColor"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.5C12.2761 7.5 12.5 7.72386 12.5 8L12.5 14.7929L14.6464 12.6464C14.8417 12.4512 15.1583 12.4512 15.3536 12.6464C15.5488 12.8417 15.5488 13.1583 15.3536 13.3536L12.3536 16.3536C12.2598 16.4473 12.1326 16.5 12 16.5C11.8674 16.5 11.7402 16.4473 11.6464 16.3536L8.64645 13.3536C8.45118 13.1583 8.45118 12.8417 8.64645 12.6464C8.84171 12.4512 9.15829 12.4512 9.35355 12.6464L11.5 14.7929L11.5 8C11.5 7.72386 11.7239 7.5 12 7.5Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
