import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SearchField } from "../../fields/SearchField";
import {
  useIsSmallBreakpoint,
  useMobileBreakpoint,
} from "../../../utils/useBreakpoints";
import { CollapsibleNavBar } from "../../navbar/CollapsibleNavBar";

interface ScreenLayoutProps {
  children: React.ReactNode;
  pageTitle: React.ReactNode | string;
  searchPlaceholder?: string;
  onSearch?: (term: string) => void;
  initValue?: string;
  hideSearch?: boolean;
  isTitleHidden?: boolean;
}
export function ScreenLayout({
  children,
  pageTitle,
  searchPlaceholder,
  onSearch,
  initValue,
  hideSearch = false,
  isTitleHidden = false,
}: ScreenLayoutProps) {
  const [value, setValue] = useState("");
  const isMobile = useMobileBreakpoint();
  const isSmall = useIsSmallBreakpoint();

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    onSearch?.(value);
  }, [value]);

  return (
    <Flex
      gap={isSmall ? undefined : 2}
      direction="column"
      grow={1}
      width="100%"
      alignItems="start"
    >
      <CollapsibleNavBar isCollapsed={isTitleHidden} shouldCollapse={isSmall}>
        <Flex
          direction="row"
          justifyContent="space-between"
          borderBottom={isSmall ? undefined : "1px solid"}
          borderColor={"transparent.white.10"}
          pt={isTitleHidden ? 0 : isSmall ? 6 : 10}
          pl={isSmall ? 4 : 0}
          pr={isSmall ? 4 : 6}
          pb={isTitleHidden ? 0 : 4}
          width="full"
          marginRight={"16px"}
          transition={"padding 0.2s ease-out"}
        >
          {(!isMobile || hideSearch) && (
            <Text
              as="h1"
              fontSize={isSmall ? "24px" : "5xl"}
              lineHeight="45.6px"
              letterSpacing=".5px"
              fontWeight="700"
              fontFamily="Bricolage Grotesque"
              color="dune.dune"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {pageTitle}
            </Text>
          )}
          {!hideSearch && (
            <Flex justifyContent="flex-end" grow="1">
              <Box width={isMobile ? "full" : "250px"}>
                <SearchField
                  placeholder={searchPlaceholder}
                  onChange={setValue}
                  value={value}
                  onClear={() => {
                    setValue("");
                  }}
                  isOpen
                  clearOnBlur={false}
                />
              </Box>
            </Flex>
          )}
        </Flex>
      </CollapsibleNavBar>
      <Box
        border="1px solid"
        borderColor="transparent.white.20"
        borderTop={0}
      />
      {children}
    </Flex>
  );
}
