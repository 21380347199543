import { Icon, IconProps } from "@chakra-ui/react";

export function EmailIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C3.17185 4.5 2.50044 5.17113 2.5 5.99918C2.5 5.99945 2.5 5.99973 2.5 6V18C2.5 18.8284 3.17157 19.5 4 19.5H20C20.8284 19.5 21.5 18.8284 21.5 18V6.00671V6M3.5 7.00495V18C3.5 18.2761 3.72386 18.5 4 18.5H20C20.2761 18.5 20.5 18.2761 20.5 18V7.0409L12.5697 13.3903C12.391 13.5334 12.1381 13.5369 11.9556 13.3988L3.5 7.00495ZM3.54745 5.78712L12.2484 12.3665L20.4562 5.79496C20.3779 5.62107 20.2031 5.5 20 5.5H4C3.79999 5.5 3.62741 5.61744 3.54745 5.78712ZM21.5 5.99103C21.4951 5.16673 20.8254 4.5 20 4.5H4"
        fill="currentColor"
      />
    </Icon>
  );
}
