export const root = {
  viewAll: "View All",
  readAndPublishComicsOnline: "Read & Publish Comics Online | GlobalComix",
  channelsAtGlobalComix: " | Channels @ GlobalComix",
  emailVerification:
    "Click the activation link sent to <bold> {{email}} </bold> to enable all features. Check your spam inbox.",
  resendVerificationEmail: "Resend email",
  refresh: "Refresh",
  finishNow: "Finish now",
  finishOnboarding: "Finish setting up your interests",
};
