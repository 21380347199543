import {
  Button,
  Flex,
  Text,
  Heading,
  keyframes,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import { Artist, Comic, ContentSectionCustomCard, Section } from "../../types";
import { useIsLargeBreakpoint } from "../../utils/useBreakpoints.ts";
import { useToggleComicFollow } from "./hooks/useToggleComicFollow.ts";
import { useToggleArtistFollow } from "./hooks/useToggleArtistFollow.ts";
import { useNavigate } from "react-router";
import { CONTEXT_ENTITY_TYPES } from "./constants.ts";
import { ReadingStatusButton } from "../readingStatusButton/ReadingStatusButton.tsx";
import { useAccountData } from "../../hooks/useAccountData.ts";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext.ts";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

interface HeroCardContentProps {
  content: ContentSectionCustomCard;
  index?: number;
  sectionIndex?: number;
  section?: Section;
}

export function HeroCardContent({
  content,
  index,
  sectionIndex,
  section,
}: HeroCardContentProps) {
  const isLarge = useIsLargeBreakpoint();
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [currentContent, setCurrentContent] = useState(content);
  const { t } = useTranslation();

  useEffect(() => {
    if (content.id !== currentContent.id) {
      setIsFadingOut(true);
      const timer = setTimeout(() => {
        setCurrentContent(content);
        setIsFadingOut(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [content, currentContent]);

  const customCardCacheKey = `${currentContent.entity_type}-${currentContent.id}`;
  const artistCacheKey = `${currentContent.artist?.entity_type}-${currentContent.artist?.id}`;
  const comicCacheKey = `${currentContent.comic?.entity_type}-${currentContent.comic?.id}`;

  const { data: customCardData } = useQuery<ContentSectionCustomCard>({
    queryKey: [customCardCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { data: artistData } = useQuery<Artist>({
    enabled: !!currentContent.artist,
    queryKey: [artistCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { data: comicData } = useQuery<Comic>({
    enabled: !!currentContent.comic,
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  // Prefer data cached in query client
  const cardDataToUse = customCardData ?? currentContent;
  const artistDataToUse = artistData ?? cardDataToUse.artist;
  const comicDataToUse = comicData ?? cardDataToUse.comic;

  const handleToggleArtistFollow = useToggleArtistFollow(artistCacheKey);
  const handleToggleComicFollow = useToggleComicFollow(
    comicCacheKey,
    true,
  ).handleToggleFollow;

  const isEntityTypeArtist =
    cardDataToUse.context_entity_type_id === CONTEXT_ENTITY_TYPES.artist;
  const isEntityTypeComic =
    cardDataToUse.context_entity_type_id === CONTEXT_ENTITY_TYPES.comic;

  const buttonState = useMemo(
    () =>
      getButtonState(
        cardDataToUse,
        artistDataToUse,
        comicDataToUse,
        isEntityTypeArtist,
        isEntityTypeComic,
        t,
      ),
    [cardDataToUse, comicDataToUse, artistDataToUse],
  );

  const navigate = useNavigate();
  const accountData = useAccountData();
  const { goToCTAUrl } = useNavigateToReaderWithContext(
    {
      comicToNavigate:
        isEntityTypeComic && comicDataToUse ? comicDataToUse : undefined,
      section: section,
      cardPosition: index,
      sectionPosition: sectionIndex,
    },
    [comicDataToUse],
  );

  const handleButtonClick = () => {
    if (cardDataToUse.cta_type === "notify") {
      if (isEntityTypeArtist && artistDataToUse) {
        handleToggleArtistFollow();
      } else if (isEntityTypeComic && comicDataToUse) {
        handleToggleComicFollow();
      }
    } else if (cardDataToUse.cta_type === "deeplink") {
      if (isEntityTypeArtist && artistDataToUse) {
        navigate(artistDataToUse.url);
      } else if (isEntityTypeComic && comicDataToUse) {
        goToCTAUrl();
      }
    } else if (cardDataToUse.cta_type === "url" && cardDataToUse.cta_url) {
      if (cardDataToUse.cta_url.includes("/stream")) {
        const channels = accountData?.home_tab_channels;
        const streams = channels?.flatMap((channel) => channel.streams);
        const urlChunks = cardDataToUse.cta_url?.split("/") ?? [];
        const urlStreamId = urlChunks[urlChunks.length - 1];
        const newActiveChannel = streams?.find((stream) => {
          return urlStreamId === stream.id;
        });

        navigate(`/channel/${newActiveChannel?.slug}`);
      } else {
        const linkUrl = new URL(cardDataToUse.cta_url || cardDataToUse.cta_url);
        window.location.href = linkUrl.pathname + linkUrl.search;
      }
    }
  };

  // Decrease the font size when display_name contains a long word
  const displayNameWords = cardDataToUse.display_name.split(" ");
  const hasLongWord = displayNameWords.some((word) => word.length >= 9);
  const displayNameFontSize = hasLongWord
    ? {
        base: "16px",
        sm: "20px",
        lg: "30px",
        "2xl": "33px",
        "3xl": "42px",
        "4xl": "63px",
      }
    : {
        base: "18px",
        sm: "24px",
        lg: "36px",
        "2xl": "40px",
        "3xl": "50px",
        "4xl": "72px",
      };

  const buttonSize = useBreakpointValue({
    base: "sm",
    "2xl": "md",
    "3xl": "lg",
  });

  return (
    <Flex
      direction={{ base: "row", lg: "column" }}
      ml={{
        base: "0",
        lg: "36px",
        "2xl": "40px",
        "3xl": "51px",
        "4xl": "76px",
      }}
      mr={{
        base: "0",
        lg: "29px",
        "2xl": "32px",
        "3xl": "41px",
        "4xl": "60px",
      }}
      mb={{
        base: "0",
        lg: "66px",
        "2xl": "72px",
        "3xl": "93px",
        "4xl": "138px",
      }}
      alignItems={{ base: "center", lg: "start" }}
      width={{
        base: "260px",
        sm: "336px",
        md: "632px",
        lg: "204px",
        "2xl": "224px",
        "3xl": "285px",
        "4xl": "424px",
      }}
      minH={{ base: "99px", lg: "auto" }}
      justifyContent={{ base: "space-between", lg: "end" }}
      animation={
        isFadingOut ? `${fadeOut} 0.3s linear` : `${fadeIn} 0.4s linear`
      }
    >
      {isLarge && (
        <Text
          fontSize={{
            base: "15px",
            "2xl": "16px",
            "3xl": "20px",
            "4xl": "32px",
          }}
          fontWeight={700}
          color="blaze.blaze"
          textAlign="left"
          w="100%"
          textTransform="uppercase"
          mb={{
            base: "22px",
            "2xl": "24px",
            "3xl": "31px",
            "4xl": "45px",
          }}
        >
          {cardDataToUse?.eyebrow}
        </Text>
      )}
      <Heading
        as="h2"
        fontSize={displayNameFontSize}
        fontWeight={700}
        lineHeight="100%"
        color="neutral.white"
        maxW={{ base: "55%", sm: "68%", lg: "100%" }}
        textAlign="left"
        mb={{
          base: "0",
          lg: "36px",
          "2xl": "40px",
          "3xl": "51px",
          "4xl": "75px",
        }}
        alignSelf={{ base: "center", lg: "flex-start" }}
      >
        {cardDataToUse.display_name}
      </Heading>
      {content.cta_type === "notify" && isEntityTypeComic && comicDataToUse ? (
        <ReadingStatusButton
          width="fit-content"
          cacheKey={`${comicDataToUse.entity_type}-${comicDataToUse.id}`}
          ctaButton={
            <Button
              variant="primary"
              isActive={!!comicDataToUse.library_status}
              alignSelf={{ base: "center", lg: "flex-start" }}
              size="md"
              minW={{
                base: "100px",
                xs: "105px",
                md: "130px",
                lg: "140px",
                xl: "162px",
                "3xl": "196px",
              }}
            >
              {comicDataToUse.library_status
                ? t("components.cards.customCard.buttonText.following")
                : t("components.cards.customCard.buttonText.follow")}
            </Button>
          }
        />
      ) : (
        buttonState.text && (
          <Button
            alignSelf={{ base: "center", lg: "flex-start" }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleButtonClick();
            }}
            minW={{
              base: "100px",
              xs: "105px",
              md: "130px",
              lg: "140px",
              xl: "162px",
              "3xl": "196px",
            }}
            variant={buttonState.variant}
            height="32px"
            size={buttonSize}
          >
            {buttonState.text}
          </Button>
        )
      )}
    </Flex>
  );
}

interface HeroCardButtonState {
  text: string;
  variant: "primary" | "secondary" | "tertiary";
}

function getButtonState(
  content: ContentSectionCustomCard,
  artist: Artist | null,
  comic: Comic | null,
  isEntityTypeArtist: boolean,
  isEntityTypeComic: boolean,
  t: any,
): HeroCardButtonState {
  const buttonState: HeroCardButtonState = {
    text: "",
    variant: "primary",
  };

  if (content.cta_type === "notify") {
    let isFollowing: boolean | undefined = false;

    if (isEntityTypeArtist) {
      isFollowing = artist?.user_is_following;
    } else if (isEntityTypeComic) {
      isFollowing = !!comic?.library_status;
    } else {
      return buttonState;
    }

    if (isFollowing) {
      buttonState.text = t("components.cards.customCard.buttonText.following");
      buttonState.variant = "tertiary";
    } else {
      buttonState.text = t("components.cards.customCard.buttonText.follow");
    }
  } else if (content.cta_type === "deeplink") {
    if (isEntityTypeArtist && artist) {
      buttonState.text = t(
        "components.cards.customCard.buttonText.viewProfile",
      );
    } else if (isEntityTypeComic && comic) {
      if (comic.user_read_status) {
        buttonState.text = t(
          "components.cards.customCard.buttonText.continueReading",
        );
      } else {
        buttonState.text = t(
          "components.cards.customCard.buttonText.startReading",
        );
      }
    }
  } else if (content.cta_type === "url" && content.cta_url) {
    buttonState.text = t("components.cards.customCard.buttonText.learnMore");
  }

  return buttonState;
}
