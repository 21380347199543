import { Icon, IconProps } from "@chakra-ui/react";

export function ShareIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6464 2.64645C11.8417 2.45118 12.1583 2.45118 12.3536 2.64645L15.3536 5.64645C15.5488 5.84171 15.5488 6.15829 15.3536 6.35355C15.1583 6.54882 14.8417 6.54882 14.6464 6.35355L12 3.70711L9.35355 6.35355C9.15829 6.54882 8.84171 6.54882 8.64645 6.35355C8.45118 6.15829 8.45118 5.84171 8.64645 5.64645L11.6464 2.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C12.2761 2.5 12.5 2.72386 12.5 3L12.5 13C12.5 13.2761 12.2761 13.5 12 13.5C11.7239 13.5 11.5 13.2761 11.5 13L11.5 3C11.5 2.72386 11.7239 2.5 12 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10.5C4.72386 10.5 4.5 10.7239 4.5 11V20C4.5 20.2761 4.72386 20.5 5 20.5H19C19.2761 20.5 19.5 20.2761 19.5 20V11C19.5 10.7239 19.2761 10.5 19 10.5H17C16.7239 10.5 16.5 10.2761 16.5 10C16.5 9.72386 16.7239 9.5 17 9.5H19C19.8284 9.5 20.5 10.1716 20.5 11V20C20.5 20.8284 19.8284 21.5 19 21.5H5C4.17157 21.5 3.5 20.8284 3.5 20V11C3.5 10.1716 4.17157 9.5 5 9.5H7C7.27614 9.5 7.5 9.72386 7.5 10C7.5 10.2761 7.27614 10.5 7 10.5H5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
