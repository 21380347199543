import { useLocation } from "react-router";
import { PreloadLink } from "../PreloadLink";
import { Link, Text } from "@chakra-ui/react";

export interface SideBarFooterItemProps {
  to: string;
  children: React.ReactNode;
  preloadLink?: boolean;
}

export function SideBarFooterItem({
  to,
  children,
  preloadLink,
}: SideBarFooterItemProps) {
  const location = useLocation();
  const isActive = location.pathname === to;
  const element = (
    <Text
      fontFamily="Roboto"
      fontSize="12px"
      lineHeight="166.6%"
      letterSpacing="0.5px"
      fontWeight="500"
      color={isActive ? "dune.700" : "dune.800"}
      _hover={{
        color: "dune.700",
      }}
    >
      {children}
    </Text>
  );

  return preloadLink ? (
    <PreloadLink to={to}>{element}</PreloadLink>
  ) : (
    <Link href={to}>{element}</Link>
  );
}
