import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { ArtistContentCardProps } from "../../types";
import { ContentCardActions } from "./components/ContentCardActions";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { CountryFlag } from "./components/CountryFlag";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";

export function ArtistListCard({
  content,
  layout: { display, style },
  onToggleFollow,
}: ArtistContentCardProps) {
  const {
    include_cover_image,
    include_name,
    include_total_favorites,
    include_total_releases,
    include_last_updated,
    include_follow,
    include_title,
    include_footer = true,
  } = display;
  const { size: layout_size } = style;
  const isSmall = layout_size === "s";

  const { width, height } = useGridCardDimensions(
    "creators",
    style.mode,
    layout_size,
  );

  return (
    <Flex width={width} justifyContent="flex-end">
      <Flex
        width={`${width - (isSmall ? 8 : 16)}px`}
        height={`${height}px`}
        direction="row"
        background="charcoal.900"
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        pr="1rem"
        borderRadius={8}
        py="8px"
      >
        <Flex
          direction="row"
          position="relative"
          alignItems="center"
          height="100%"
          gap={isSmall ? "8px" : "4px"}
        >
          <Box
            left={isSmall ? -2 : -4}
            height="100%"
            position="relative"
            aspectRatio={1}
          >
            {include_cover_image ? (
              <Image
                loading="lazy"
                src={content.avatar_url}
                height="100%"
                position="relative"
                borderRadius="20px"
                alt={`Cover image for ${content.roman_name}`}
              />
            ) : null}
          </Box>
          <Flex
            direction="column"
            w="full"
            gap={2}
            justifyContent="space-between"
            pr="1rem"
          >
            <Flex direction="column" paddingTop={isSmall ? 2 : 0} gap={1}>
              {include_name ? (
                <Text
                  variant={isSmall ? "cardSubtitle" : "cardHeader"}
                  width="150px"
                >
                  {content.roman_name}
                </Text>
              ) : null}
              {include_title ? (
                <Flex direction="row" alignItems="center" gap={2}>
                  {!isSmall ? (
                    <CountryFlag countryId={content.country_id} />
                  ) : null}
                  <Text
                    variant={isSmall ? "cardVitals" : "cardSubtitle"}
                    color="neutral.200"
                    noOfLines={1}
                  >
                    {content.type_display}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
            <Flex alignSelf="flex-start">
              {include_footer && !isSmall ? (
                <ContentCardFooter
                  total_favorites={content.total_favorites}
                  total_projects={content.total_projects}
                  total_releases={content.total_releases}
                  update_time={content.last_activity}
                  include_last_updated={include_last_updated}
                  include_total_favorites={include_total_favorites}
                  include_total_releases={include_total_releases}
                  include_total_projects={true}
                  layoutMode="list"
                  useAlternateFavoritesIcon
                />
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        <ContentCardActions
          showFollow={(!isSmall && include_follow) ?? false}
          onToggleFollow={onToggleFollow}
          isFollowing={content.user_is_following}
        />
      </Flex>
    </Flex>
  );
}
