import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { LOGIN_WITH_FACEBOOK_KEY, loginWithFacebook } from "../api/authApi";
import { SsoButton } from "../../buttons/SsoButton";
import { FacebookIcon } from "../../icons/FacebookIcon";
import FacebookLogin from "@greatsumini/react-facebook-login";

interface FacebookSsoButtonProps {
  onSuccess: () => void;
  onError: (error: any) => void;
  isSmall?: boolean;
}

export function FacebookSsoButton({
  onSuccess,
  onError,
  isSmall,
}: FacebookSsoButtonProps) {
  const toast = useToast();
  const { t } = useTranslation();

  const facebookMutation = useMutation({
    mutationKey: LOGIN_WITH_FACEBOOK_KEY,
    mutationFn: ({ userId, token }: { userId: string; token: string }) =>
      loginWithFacebook(userId, token),
    onSuccess: (_) => {
      onSuccess();
    },
    onError: (error) => onError(error),
  });

  return (
    <FacebookLogin
      appId={import.meta.env.VITE_FACEBOOK_APP_ID}
      autoLoad={false}
      onSuccess={(response) => {
        facebookMutation.mutate({
          userId: response.userID,
          token: response.accessToken,
        });
      }}
      onFail={(error) => {
        if (error.status !== "loginCancelled") {
          toast({
            title: t("components.auth.errorAuthenticatingWithFacebook"),
            status: "error",
          });
        }
      }}
      render={(renderProps) => (
        <SsoButton
          isSmall={isSmall}
          icon={<FacebookIcon boxSize="24px" />}
          text={t("components.auth.continueWithFacebook")}
          onClick={renderProps.onClick}
          isLoading={facebookMutation.isPending}
        />
      )}
    />
  );
}
