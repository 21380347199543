import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { ReaderOverlay } from "./components/overlay/ReaderOverlay";
import { ReaderReleasePages } from "./components/ReaderReleasePages";
import { useIsOverlayOpen } from "./hooks/hookstate/useIsOverlayOpen";
import { useSlideInMenu } from "../../components/slideInMenu/hooks/useSlideInMenu";
import { ActionOverlay } from "./components/horizontalReading/ActionOverlay";
import { hookstate, ImmutableObject, useHookstate } from "@hookstate/core";
import { readerDataStore } from "./ReaderStateWrapper";
import { Release } from "../../types";
import { CHAPTER_TYPES } from "./constants";
import { useTitleOnNavigation } from "../../hooks/useTitleOnNavigation";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { ReaderNavigationContext } from "./types";

export const readerNavigationContext = hookstate<ReaderNavigationContext>({});

export default function ReaderScreen() {
  const { openMenu, onCloseMenu } = useSlideInMenu();
  const thinScroll = useMobileBreakpoint();

  const releaseData = useHookstate(readerDataStore.releaseData).get({
    noproxy: true,
  });

  const documentTitle = constructDocumentTitle(releaseData);

  useTitleOnNavigation(documentTitle);

  return (
    <Flex
      id="readerContainer"
      alignItems="center"
      layerStyle="readerContainer"
      transition="left .2s linear"
      zIndex="auto"
      width="100%"
      minHeight="100dvh"
      minWidth="100%"
      overflow="auto"
      style={{ scrollbarWidth: thinScroll ? "thin" : "auto" }}
    >
      <ActionOverlay>
        <ReaderReleasePages />
      </ActionOverlay>
      <ReaderOverlayAndMenu onCloseMenu={onCloseMenu} openMenu={openMenu} />
    </Flex>
  );
}

function ReaderOverlayAndMenu({
  openMenu,
  onCloseMenu,
}: {
  openMenu?: string;
  onCloseMenu: () => void;
}) {
  const { isOverlayOpen, toggleIsOverlayOpen } = useIsOverlayOpen();

  useEffect(() => {
    onCloseMenu();
  }, [isOverlayOpen]);

  return (
    <ReaderOverlay
      isOpen={!!isOverlayOpen}
      onToggle={toggleIsOverlayOpen}
      openMenu={openMenu}
    />
  );
}

function constructDocumentTitle(
  release?: ImmutableObject<Release>,
): string | null {
  if (!release) return null;
  return `Read ${release.localized_comic_name} ${CHAPTER_TYPES[release.chapter_type]} ${release.chapter} in ${release.lang_display} Online | GlobalComix`;
}
