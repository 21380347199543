import { Flex, Text } from "@chakra-ui/react";
import { ButtonCarousel } from "../carousel/ButtonCarousel";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { PreloadLink } from "../navigation/PreloadLink";

export interface ButtonTab {
  name: string;
  link: string;
  isActive?: boolean;
}

interface ButtonTabs {
  tabs: ButtonTab[];
  paddingTop?: string;
  paddingBottom?: string;
  marginLeft?: string;
}

export function ButtonTabs({
  tabs,
  paddingTop,
  paddingBottom,
  marginLeft,
}: ButtonTabs) {
  const isMobile = useMobileBreakpoint();

  return (
    <Flex
      gap={2}
      top="0px"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {tabs.length ? (
        <ButtonCarousel
          width="100%"
          includeButtons={!isMobile} // No fading should show on mobile
          slidesToScroll={3}
          wrapperProps={{ gap: "16px" }}
        >
          {tabs.map(function (tab, index) {
            const currentMarginLeft =
              marginLeft && index === 0 ? marginLeft : "0px";
            return (
              <PreloadLink key={tab.name} to={tab.link}>
                <Flex
                  as="button"
                  key={tab.name}
                  transition="all .2s linear"
                  sx={{ "margin-left": currentMarginLeft }}
                  cursor="pointer"
                  layerStyle="channelTab"
                  direction="column"
                  py="8px"
                  px="16px"
                  height="40px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="16px"
                  bg={tab.isActive ? "blaze.600" : "transparent.white.10"}
                  color={"neutral.white"}
                  _hover={{
                    color: tab.isActive ? "neutral.white" : "dune.100",
                    background: tab.isActive
                      ? undefined
                      : "transparent.white.20",
                  }}
                  marginInline="4px"
                >
                  <Text
                    fontSize={{ base: "12px", md: "14px" }}
                    textAlign="center"
                  >
                    {tab.name}
                  </Text>
                </Flex>
              </PreloadLink>
            );
          })}
        </ButtonCarousel>
      ) : null}
    </Flex>
  );
}
