import { Icon, IconProps } from "@chakra-ui/react";

export function P2PIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        d="M5 6.01A.01.01 0 0 1 5.01 6h6.977a.01.01 0 0 1 .01.012l-.995 3.98a.01.01 0 0 1-.01.008H5.01A.01.01 0 0 1 5 9.99V6.01ZM15.998 6.008a.01.01 0 0 1 .01-.008h2.982a.01.01 0 0 1 .01.01v11.98a.01.01 0 0 1-.01.01h-5.977a.01.01 0 0 1-.01-.012l2.995-11.98ZM5 14.01a.01.01 0 0 1 .01-.01h4.977a.01.01 0 0 1 .01.012l-.995 3.98a.01.01 0 0 1-.01.008H5.01a.01.01 0 0 1-.01-.01v-3.98Z"
      />
    </Icon>
  );
}
