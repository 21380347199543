import {
  Text,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { t } from "i18next";

export function P2POverlayOpacitySlider() {
  const { p2pOverlayOpacity, setP2POverlayOpacity } = useP2PSettings();
  return (
    <Flex
      p="18px"
      gap="8px"
      alignItems="flex-start"
      background="transparent.black.30"
      borderRadius="8px"
      border="1px solid"
      borderColor="transparent.white.10"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" width="100%">
        <Text
          fontSize="14px"
          fontWeight="500"
          lineHeight="18.2px"
          color="neutral.200"
        >
          {t("screens.reader.settingsMenu.overlay_opacity")}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          lineHeight="18.2px"
          color="blaze.blaze"
        >
          {p2pOverlayOpacity}%
        </Text>
      </Flex>
      <Slider
        aria-label="P2P-opacity-slider"
        max={100}
        min={75}
        value={p2pOverlayOpacity}
        onChange={(value) => setP2POverlayOpacity(value)}
      >
        <SliderTrack background="neutral.200">
          <SliderFilledTrack background="blaze.blaze" />
        </SliderTrack>
        <SliderThumb
          boxSizing="content-box"
          background="blaze.blaze"
          border="6px solid"
          width="10px"
          height="10px"
          borderColor="neutral.200"
        />
      </Slider>
    </Flex>
  );
}
