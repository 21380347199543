import { useTimer } from "../screens/root/components/TimerContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../services/axiosInstance";
import { Section, ServerListResponse } from "../types";
import { sqlDateToTimestamp } from "../utils/time";
import { useAccountData } from "./useAccountData";
import { AxiosResponse } from "axios";

export interface ChatSectionType extends Section {
  isLive?: boolean;
  uuid: string;
  subtitle: string;
  chat_url: string;
  ably_channel: string;
  image_xl_url: string;
  image_url: string;
  image_medium_url: string;
  image_small_url: string;
  image_tiny_url: string;
}

const CHAT_CMS_KEY = "_internal.gc.chat.home.index";
const CHAT_QUERY_KEY = "QUERY_CHAT_CMS";
const CHAT_GET_KEY = "GET_CHAT_CMS";
const getChatCMS = () => {
  return apiClient.get<ServerListResponse<ChatSectionType>>(
    `/v1/cms/${CHAT_CMS_KEY}/sections`,
  );
};

export function useHydrateChatSchedule() {
  const accountData = useAccountData();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [CHAT_QUERY_KEY, accountData?.user?.id, accountData?.user?.gold],
    queryFn: async () => {
      const response = await getChatCMS();
      queryClient.setQueryData([CHAT_GET_KEY], response);
      return response;
    },
    networkMode: "offlineFirst",
  });
}

export function useChatSchedule() {
  useTimer();

  const { data, isLoading } = useQuery<
    AxiosResponse<ServerListResponse<ChatSectionType>>
  >({
    queryKey: [CHAT_GET_KEY],
    networkMode: "offlineFirst",
  });

  const sections = data?.data.payload.results;
  const events: ChatSectionType[] = [];
  const rooms: ChatSectionType[] = [];
  const futureEvents: ChatSectionType[] = [];
  const liveEvents: ChatSectionType[] = [];

  const now = Date.now();
  sections?.forEach((section) => {
    if (section.source_type === "chat-room") {
      rooms.push(section);
    } else if (section.source_type === "chat-event") {
      events.push(section);

      const eventStart = section.start_time
        ? sqlDateToTimestamp(section.start_time)
        : 0;
      const eventEnd = section.end_time
        ? sqlDateToTimestamp(section.end_time)
        : Infinity;
      if (now > eventStart && now < eventEnd) {
        liveEvents.push({ ...section, isLive: true });
      } else if (now < eventStart) {
        futureEvents.push(section);
      }
    }
  });

  return {
    isEventLive: liveEvents ? liveEvents.length > 0 : false,
    isLoading,
    liveEvents,
    futureEvents,
    events,
    rooms,
  };
}
