import { useHookstate } from "@hookstate/core";
import { useCallback, useMemo } from "react";
import { useIsSidebarOpen } from "../../../hooks/useIsSidebarOpen";
import { rootComponentsStore } from "../../layouts/RootLayout";
import {
  Artist,
  Comic,
  Release,
  ServerListResponse,
  ServerResponse,
  ServerSingleResponse,
} from "../../../types";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../services/axiosInstance";
import { useEntityCache } from "../../../hooks/useEntityCache";
import { ReaderNavigationContext } from "../../../screens/reader/types";
import { SLIDE_IN_MENUS } from "../../../screens/root/constants";

export function useSlideInMenu(fetchData = false) {
  const openMenu = useHookstate(rootComponentsStore.openSlideInMenu).get();
  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  const { saveEntityToCache } = useEntityCache();

  const onToggleSlideInMenu = useCallback(
    (
      menu: string,
      config?: {
        releaseUuid?: string;
        comicSlug?: string;
        artistId?: number;
        navigationContext?: ReaderNavigationContext;
      },
    ) => {
      if (openMenu === menu && inReader) {
        rootComponentsStore.openSlideInMenu.set(undefined);
        rootComponentsStore.slideInMenuConfig.set(undefined);
      } else {
        if (inReader) {
          setIsSidebarOpen(false);
        }
        rootComponentsStore.openSlideInMenu.set(menu);
        rootComponentsStore.slideInMenuConfig.set(config);
      }
    },
    [openMenu, inReader],
  );

  const onCloseMenu = useCallback(() => {
    rootComponentsStore.openSlideInMenu.set(undefined);
    rootComponentsStore.slideInMenuConfig.set(undefined);
  }, [isSidebarOpen]);

  const openReleaseInfoMenu = useCallback(
    (
      releaseUuid: string,
      comicSlug?: string,
      navigationContext?: ReaderNavigationContext,
    ) => {
      onToggleSlideInMenu(SLIDE_IN_MENUS.releaseInfo, {
        releaseUuid,
        comicSlug,
        navigationContext,
      });
    },
    [],
  );

  const openComicInfoMenu = useCallback(
    (
      comicSlug: string,
      releaseUuid?: string,
      navigationContext?: ReaderNavigationContext,
    ) => {
      onToggleSlideInMenu(SLIDE_IN_MENUS.comicDetails, {
        releaseUuid,
        comicSlug,
        navigationContext,
      });
    },
    [],
  );

  const { releaseData } = useReleaseData();
  const { artistId, comicSlug, releaseUuid, navigationContext } =
    useHookstate(rootComponentsStore.slideInMenuConfig).get() ?? {};

  const needToFetchComic = comicSlug && releaseData?.comic.slug !== comicSlug;
  const needToFetchArtist =
    artistId && releaseData?.comic.artist.id !== artistId;
  const shouldUseReaderData =
    inReader && !needToFetchComic && !needToFetchArtist;

  const { data: fetchedComicData, isLoading: isLoadingComic } = useQuery({
    enabled: !!(fetchData && needToFetchComic),
    queryKey: ["comic", comicSlug],
    queryFn: async () => {
      const response = await getComic(
        comicSlug ?? "placeholder-slug",
        navigationContext,
      );
      const comic = response?.data.payload.results;

      if (comic) {
        saveEntityToCache(comic);
        saveEntityToCache(comic.artist);
      }
      return response;
    },
  });

  const { data: fetchedArtistData, isLoading: isLoadingArtist } = useQuery({
    enabled: !!(fetchData && needToFetchArtist),
    queryKey: ["artist", artistId],
    queryFn: async () => {
      const response = await getArtist(artistId ?? 0);
      const artist = response?.data.payload.results;

      if (artist) {
        saveEntityToCache(artist);
      }
      return response;
    },
  });

  return useMemo(() => {
    const comicData =
      fetchedComicData?.data.payload.results ?? releaseData?.comic;
    const artistData =
      fetchedArtistData?.data.payload.results ?? releaseData?.comic.artist;
    return {
      openMenu,
      openComicInfoMenu,
      openReleaseInfoMenu,
      onToggleSlideInMenu,
      onCloseMenu,
      artistCacheKey: `${artistData?.entity_type}-${artistData?.id}`,
      comicCacheKey: `${comicData?.entity_type}-${comicData?.id}`,
      releaseCacheKey: `Release-${releaseData?.id}`,
      releaseUuid,
      comicSlug,
      isFetched: shouldUseReaderData
        ? true
        : !isLoadingComic && !isLoadingArtist,
    };
  }, [
    openMenu,
    openComicInfoMenu,
    openReleaseInfoMenu,
    onToggleSlideInMenu,
    onCloseMenu,
    releaseData,
    comicSlug,
    artistId,
    inReader,
    fetchedComicData,
    fetchedArtistData,
    releaseUuid,
  ]);
}

export function getRelease(
  releaseUuid: string,
  navigationContext?: ReaderNavigationContext,
) {
  const contextQueryParams = new URLSearchParams();

  if (navigationContext) {
    Object.entries(navigationContext).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        contextQueryParams.append(
          `context_${key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)}`,
          value.toString(),
        );
      }
    });
  }

  return apiClient.get<ServerResponse<Release>>(
    `/v1/readV2/${releaseUuid}?releaseDetails=1${contextQueryParams.size > 0 ? "&" + contextQueryParams : ""}`,
  );
}

export function getComicReleases(comicSlug: string) {
  return apiClient.get<ServerListResponse<Release>>(
    `/v1/comics/${comicSlug}/releases`,
  );
}

export function getComic(
  comicSlug: string,
  navigationContext?: ReaderNavigationContext,
) {
  const contextQueryParams = new URLSearchParams();

  if (navigationContext) {
    Object.entries(navigationContext).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        contextQueryParams.append(
          `context_${key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)}`,
          value.toString(),
        );
      }
    });
  }
  return apiClient.get<ServerSingleResponse<Comic>>(
    `/v1/read/${comicSlug}${contextQueryParams.size > 0 ? "?" + contextQueryParams : ""}`,
  );
}

export function getArtist(artistId: number) {
  return apiClient.get<ServerSingleResponse<Artist>>(`/v1/artists/${artistId}`);
}
