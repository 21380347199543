import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useNSFWInterstitial } from "../hooks/useNSFWInterstitial";
import { useMobileBreakpoint } from "../../../utils/useBreakpoints";
import { useNavigate } from "react-router";
import { WarningIcon } from "../../../components/icons/reader/WarningIcon";
import { t } from "i18next";

export function NSFWInterstitial() {
  const navigate = useNavigate();
  const { showNSFWInterstitial, disableNSFWInterstitial } =
    useNSFWInterstitial();
  const isMobile = useMobileBreakpoint();

  return (
    <Modal isOpen={!!showNSFWInterstitial} onClose={() => {}} isCentered>
      <ModalOverlay backdropFilter="blur(18px)" />
      <ModalContent
        backgroundColor="reader.slate.500_90"
        maxWidth="32rem"
        display="flex"
        gap="20px"
        flexDirection="column"
        p="40px"
        px={isMobile ? "64px" : "40px"}
        alignItems="center"
        borderRadius="24px"
        mx="32px"
      >
        <Flex
          alignItems="center"
          bg="reader.bg.warning.500"
          borderRadius="12px"
          p="8px"
        >
          <WarningIcon width="24px" height="24px" color="warning.500" />
        </Flex>
        <Text
          fontSize={isMobile ? "20px" : "24px"}
          fontWeight="700"
          lineHeight="32px"
        >
          {t("screens.reader.nsfwInterstitial.matureContentWarning")}
        </Text>
        <Text
          fontSize={isMobile ? "16px" : "20px"}
          fontWeight="600"
          lineHeight="24px"
          color="reader.neutral.200"
          textAlign="center"
        >
          {t("screens.reader.nsfwInterstitial.areYouSure")}
        </Text>
        <Button
          aria-label="Allow NSFW content"
          minWidth="16rem"
          height="48px"
          onClick={disableNSFWInterstitial}
          flex={1}
          py="12px"
          px="48px"
          borderRadius="24px"
          bgGradient="linear(to-r, reader.altblue.500, reader.altblue.700)"
          _hover={{}}
          textTransform="uppercase"
        >
          <Text
            fontSize="16px"
            fontWeight="600"
            lineHeight="24px"
            letterSpacing=".4px"
          >
            {t("screens.reader.nsfwInterstitial.iAccept")}
          </Text>
        </Button>
        <Button
          variant="iconOnly"
          aria-label="Go back"
          padding="0"
          onClick={() => navigate(-1)}
        >
          <Text
            textDecoration="underline"
            color="info.500"
            fontSize="18px"
            lineHeight="28px"
            fontWeight="600"
          >
            {t("screens.reader.nsfwInterstitial.takeMeBack")}
          </Text>
        </Button>
      </ModalContent>
    </Modal>
  );
}
