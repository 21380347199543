import { Flex, Text, Link } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useTimer } from "../../../screens/root/components/TimerContext";
import { CrowdfundingCampaignEntity } from "../../../types";
import { CaretRightIcon } from "../../icons/CaretRightIcon";
import { Trans } from "react-i18next";
import { LabeledCountdownTimer } from "../../time/LabeledCountdownTimer";

export function CrowdfundingBanner({
  crowdfundingCampaign,
}: {
  crowdfundingCampaign: CrowdfundingCampaignEntity;
}) {
  useTimer();
  const endDate = DateTime.fromSQL(crowdfundingCampaign.end_time, {
    zone: "utc",
  });

  const intGoal = Math.floor(parseInt(crowdfundingCampaign.goal_amount));
  const serviceName = crowdfundingCampaign.crowdfunding_service?.display_name;

  return (
    <Link href={crowdfundingCampaign.url} target="_blank">
      <Flex
        border="1px solid"
        borderColor="transparent.success.70"
        bg="transparent.success.05"
        borderRadius="8px"
        p="8px"
        pr="0px"
        alignItems="center"
        transition="background-color 0.4s ease"
        _hover={{
          bg: "transparent.success.20",
        }}
      >
        <Flex>
          <Text
            textAlign="center"
            fontSize="13px"
            lineHeight="16px"
            fontWeight="500"
            color="dune.700"
            mr="2px"
          >
            <Trans
              i18nKey="components.slideInMenu.comicDetailsMenu.crowdfundingCta"
              values={{
                amount: intGoal.toLocaleString("en-US"),
                service: serviceName,
              }}
              components={{
                bold: <Text as="span" fontWeight="700" color="dune.dune" />,
              }}
            />
          </Text>
        </Flex>
        <Flex>
          <LabeledCountdownTimer
            targetTimestamp={endDate.toMillis()}
            labelFontSize="15px"
            labelColor="dune.700"
            border={undefined}
            px="4px"
            py="6px"
            showAllLabels={false}
            gap="6px"
          />
        </Flex>

        <CaretRightIcon boxSize="28px" />
      </Flex>
    </Link>
  );
}
