import { Icon, IconProps } from "@chakra-ui/react";

export function PrintPagesIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.333 2.66699H9.99967C9.19967 2.66699 8.48634 3.02699 7.99967 3.58699C7.51301 3.02699 6.79967 2.66699 5.99967 2.66699H2.66634C1.93301 2.66699 1.33301 3.26699 1.33301 4.00033V10.667C1.33301 11.4003 1.93301 12.0003 2.66634 12.0003H5.59301C6.03967 12.0003 6.45301 12.2203 6.69967 12.5937L7.43967 13.7003C7.43967 13.7003 7.44634 13.707 7.45301 13.7137C7.47301 13.747 7.50634 13.7737 7.53301 13.8003C7.55301 13.8203 7.57301 13.847 7.59967 13.867C7.62634 13.887 7.65301 13.9003 7.68634 13.9137C7.71967 13.9337 7.75301 13.9537 7.78634 13.967C7.78634 13.967 7.79301 13.967 7.79967 13.9737C7.82634 13.9803 7.85301 13.9737 7.87967 13.987C7.91967 13.987 7.95301 14.0003 7.99301 14.0003C8.03301 14.0003 8.06634 13.9937 8.10634 13.987C8.13301 13.987 8.15967 13.987 8.18634 13.9737C8.18634 13.9737 8.19301 13.9737 8.19967 13.967C8.23301 13.9537 8.26634 13.9337 8.29967 13.9137C8.32634 13.9003 8.35967 13.887 8.38634 13.867C8.41301 13.847 8.43301 13.8203 8.45301 13.8003C8.47967 13.7737 8.50634 13.747 8.53301 13.7137C8.53301 13.7137 8.53967 13.707 8.54634 13.7003L9.28634 12.5937C9.53301 12.2203 9.95301 12.0003 10.393 12.0003H13.3197C14.053 12.0003 14.653 11.4003 14.653 10.667V4.00033C14.653 3.26699 14.053 2.66699 13.3197 2.66699H13.333ZM5.59301 10.667H2.66634V4.00033H5.99967C6.73301 4.00033 7.33301 4.60033 7.33301 5.33366V11.307C6.85301 10.9003 6.23967 10.667 5.59301 10.667V10.667ZM13.333 10.667H10.4063C9.75967 10.667 9.14634 10.9003 8.66634 11.307V5.33366C8.66634 4.60033 9.26634 4.00033 9.99967 4.00033H13.333V10.667Z"
        fill="currentColor"
      />
    </Icon>
  );
}
