import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { VerticalSquiggleSmallIcon } from "../icons/VerticalSquiggleSmallIcon";
import { CSSProperties } from "react";

interface SectionTitleProps extends FlexProps {
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  fontSize?: CSSProperties["fontSize"];
}

export function SectionTitle({
  children,
  subtitle,
  action,
  fontSize,
  ...containerProps
}: SectionTitleProps) {
  return (
    <Flex
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      {...containerProps}
    >
      <VerticalSquiggleSmallIcon color="blaze.blaze" height="24px" />
      <Flex width="100%" direction="column" ml="12px" mr="8px">
        <Text variant="title" fontSize={fontSize} fontWeight={700}>
          {children}
        </Text>
        {subtitle ? (
          <Text mt="4px" variant="subtitle">
            {subtitle}
          </Text>
        ) : null}
      </Flex>
      {action}
    </Flex>
  );
}
