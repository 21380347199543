import { useQuery } from "@tanstack/react-query";
import { Flex, Link, Spinner } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { Comic } from "../../../types";
import { getSimilarComicReleases } from "../../../screens/reader/api/readerScreenApi";
import { ComicListCard } from "../../cards/ComicListCard";
import { useToggleComicFollow } from "../../cards/hooks/useToggleComicFollow";
import { useEntityCache } from "../../../hooks/useEntityCache";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";
import {
  NavigationTrigger,
  ReaderNavigationContext,
} from "../../../screens/reader/types";
import { rootComponentsStore } from "../../layouts/RootLayout";

interface RelatedComicsListProps {
  comicData?: ImmutableObject<Comic>;
}
export function RelatedComicsList({ comicData }: RelatedComicsListProps) {
  const { saveEntityToCache } = useEntityCache();
  const navigationContext =
    rootComponentsStore.slideInMenuConfig.get()?.navigationContext;
  const { isLoading, data } = useQuery({
    enabled: !!comicData?.id,
    queryKey: ["relatedComics", comicData?.id],
    queryFn: async () => {
      const response = await getSimilarComicReleases(comicData?.id ?? 0);
      response?.data.payload.results.forEach(saveEntityToCache);
      return response;
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" width="100%">
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  return data?.data.payload.results.map((comic) => (
    <RelatedComicCard
      cacheKey={`${comic.entity_type}-${comic.id}`}
      navigationContext={navigationContext}
    />
  ));
}

function RelatedComicCard({
  cacheKey,
  contextComic,
  navigationContext,
}: {
  cacheKey: string;
  contextComic?: ImmutableObject<Comic>;
  navigationContext?: ReaderNavigationContext;
}) {
  const { data: comic } = useQuery<Comic>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const { handleToggleFollow } = useToggleComicFollow(cacheKey);
  const { goToCTAUrl } = useNavigateToReaderWithContext({
    ...navigationContext,
    trigger: NavigationTrigger.relatedListCard,
    comicToNavigate: comic,
    contextComicId: contextComic?.id,
  });

  if (!comic) {
    return null;
  }
  return (
    <Link
      key={comic.id}
      aria-label={`Read ${comic.name}`}
      onClick={goToCTAUrl}
      style={{ width: "100%" }}
    >
      <ComicListCard
        content={comic}
        fixedHeight={116}
        expanded
        onToggleFollow={handleToggleFollow}
        layout={{
          style: {
            context: "comics",
            mode: "grid",
            size: "c_1",
            rows: 1,
            corners_top: "right",
            corners_bottom: "right",
            section_is_inset: false,
          },
          display: {
            include_name: true,
            include_cover_image: true,
            include_add_library_cta: true,
            include_description: true,
            include_progress_meter: true,
          },
        }}
      />
    </Link>
  );
}
