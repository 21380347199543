import { Box } from "@chakra-ui/react";
import { LEFT_CURSOR_URL, RIGHT_CURSOR_URL } from "../horizontalReading/icons";
import { READING_DIRECTION_TYPES } from "../../constants";
import { useIsSmallBreakpoint } from "../../../../utils/useBreakpoints";
import { useReadingDirection } from "../../hooks/useReadingDirection";

interface NavigationClickBoxesProps {
  blockedNext: boolean;
  goNext: () => void;
  goPrevious: () => void;
  hasNext: boolean;
  hasPrev: boolean;
}
export function NavigationClickBoxes({
  blockedNext,
  goNext,
  goPrevious,
  hasNext,
  hasPrev,
}: NavigationClickBoxesProps) {
  const { readingDirection } = useReadingDirection();
  const isSmall = useIsSmallBreakpoint();

  const width = `${isSmall ? 25 : 33}%`;
  if (readingDirection === READING_DIRECTION_TYPES.leftToRight) {
    return (
      <>
        {hasPrev && (
          <Box
            layerStyle="readerNavBox"
            width={width}
            left={0}
            cursor={LEFT_CURSOR_URL}
            onClick={(e) => {
              e.stopPropagation();
              goPrevious();
            }}
          />
        )}
        {!blockedNext && hasNext && (
          <Box
            layerStyle="readerNavBox"
            width={width}
            right={0}
            cursor={RIGHT_CURSOR_URL}
            onClick={(e) => {
              e.stopPropagation();
              goNext();
            }}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        {!blockedNext && hasNext && (
          <Box
            layerStyle="readerNavBox"
            width={width}
            left={0}
            cursor={LEFT_CURSOR_URL}
            onClick={(e) => {
              e.stopPropagation();
              goNext();
            }}
          />
        )}
        {hasPrev && (
          <Box
            layerStyle="readerNavBox"
            width={width}
            right={0}
            cursor={RIGHT_CURSOR_URL}
            onClick={(e) => {
              e.stopPropagation();
              goPrevious();
            }}
          />
        )}
      </>
    );
  }
}
