import { DateTime } from "luxon";

export const ONE_DAY = 24 * 60 * 60 * 1000;
export function sqlDateToTimestamp(sqlDate: string) {
  const dateTime = DateTime.fromFormat(sqlDate, "yyyy-MM-dd HH:mm:ss", {
    zone: "utc",
  });
  return dateTime.toMillis();
}

export function timeAgoSqlDate(sqlDate: string) {
  const date = DateTime.fromSQL(sqlDate, { zone: "utc" });
  const relativeTime = date.toRelative();

  if (relativeTime === "0 seconds ago") {
    return "just now";
  }
  return relativeTime;
}

export function timeAgo(timestamp: number) {
  const timeLeft = calculateTimeDiff(timestamp);
  if (!timeLeft.minutes || timeLeft.minutes < 1) {
    return "just now";
  }

  const date = DateTime.fromMillis(timestamp);
  const relativeTime = date.toRelative();

  if (relativeTime === "0 seconds ago") {
    return "just now";
  }
  return relativeTime;
}

export function getRelativeTimeFromSqlDate(sqlDate: string) {
  return getRelativeTimeFromTimestamp(sqlDateToTimestamp(sqlDate));
}

export function getRelativeTimeFromTimestamp(timestamp: number) {
  const date = DateTime.fromMillis(timestamp);
  const now = DateTime.local();

  if (date.hasSame(now, "day")) {
    return `Today at ${date.toFormat("h:mma")}`;
  } else if (date.hasSame(now.plus({ days: 1 }), "day")) {
    return `Tomorrow at ${date.toFormat("h:mma")}`;
  } else {
    return date.toFormat("LLL d  •  h:mma");
  }
}

export function calculateTimeLeft(timestamp: number) {
  const now = DateTime.local();
  const targetTime = DateTime.fromMillis(timestamp);
  const diff = targetTime
    .diff(now, ["days", "hours", "minutes", "seconds"])
    .toObject();

  const formatNumber = (num: number) => {
    if (!num) return undefined;
    return String(Math.max(0, Math.floor(num ?? 0))).padStart(2, "0");
  };

  return {
    days: formatNumber(diff?.days ?? 0),
    hours: formatNumber(diff?.hours ?? 0),
    minutes: formatNumber(diff?.minutes ?? 0),
    seconds: formatNumber(diff?.seconds ?? 0),
  };
}

export function calculateTimeDiff(timestamp: number) {
  const now = DateTime.local();
  const targetTime = DateTime.fromMillis(timestamp);
  const diff = targetTime
    .diff(now, ["days", "hours", "minutes", "seconds"])
    .toObject();

  const formatNumber = (num: number) => {
    if (!num) return undefined;
    return Math.abs(Math.floor(num ?? 0));
  };

  return {
    days: formatNumber(diff?.days ?? 0),
    hours: formatNumber(diff?.hours ?? 0),
    minutes: formatNumber(diff?.minutes ?? 0),
    seconds: formatNumber(diff?.seconds ?? 0),
  };
}
