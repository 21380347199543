import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

export function OnboardingStepTitles({ activeStep }: { activeStep: number }) {
  const isSmall = useIsSmallBreakpoint();
  const stepTitles = [
    {
      title: t("components.onboarding.comicTypes.title"),
    },
    {
      title: t("components.onboarding.comicGenres.title"),
    },
    {
      title: t("components.onboarding.comicThemes.title"),
    },
    // {
    //   title: t("components.onboarding.followComics.title"),
    // },
    // {
    //   title: t("components.onboarding.followAritsts.title"),
    // },
  ];

  const title = stepTitles[activeStep]?.title;
  if (!title) {
    return null;
  }
  return (
    <Flex flexDirection="column" gap="16px" maxWidth={isSmall ? "100%" : "80%"}>
      {title && <Text variant="onboardingTitle">{title}</Text>}
    </Flex>
  );
}
