import { Icon, IconProps } from "@chakra-ui/react";

export function CalendarEmptyIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 12 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25C8.13807 1.25 8.25 1.36193 8.25 1.5V2.25H9.5C9.91421 2.25 10.25 2.58579 10.25 3V4.5V10C10.25 10.4142 9.91421 10.75 9.5 10.75H2.5C2.08579 10.75 1.75 10.4142 1.75 10V4.5V3C1.75 2.58579 2.08579 2.25 2.5 2.25H3.75V1.5C3.75 1.36193 3.86193 1.25 4 1.25C4.13807 1.25 4.25 1.36193 4.25 1.5V2.25H7.75V1.5C7.75 1.36193 7.86193 1.25 8 1.25ZM4 2.75H2.5C2.36193 2.75 2.25 2.86193 2.25 3V4.25H9.75V3C9.75 2.86193 9.63807 2.75 9.5 2.75H8H4ZM2.25 4.75H9.75V10C9.75 10.1381 9.63807 10.25 9.5 10.25H2.5C2.36193 10.25 2.25 10.1381 2.25 10V4.75Z"
        fill="currentColor"
      />
    </Icon>
  );
}
