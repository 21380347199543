import { Text, Flex, Switch, FormControl, FormLabel } from "@chakra-ui/react";
import { t } from "i18next";

interface LibraryToggleProps {
  disableEmails?: boolean;
  toggleDisableEmails: () => void;
  disableNotifications?: boolean;
  toggleDisableNotifications: () => void;
}
export function LibraryToggles({
  disableEmails,
  toggleDisableEmails,
  disableNotifications,
  toggleDisableNotifications,
}: LibraryToggleProps) {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      background="reader.slate.600"
      cursor="pointer"
    >
      <FormControl
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        border="1px solid"
        borderColor="reader.neutral.800"
        borderTopRadius="8px"
        display="flex"
      >
        <FormLabel
          cursor="pointer"
          htmlFor="notification-switch"
          display="flex"
          justifyContent="space-between"
          p={4}
          flex={1}
          m={0}
        >
          <Text fontWeight="600" fontSize="14px" lineHeight="20px">
            {t("screens.reader.addToMenu.send_notifications")}
          </Text>
          <Switch
            id="notification-switch"
            size="md"
            variant="readerSwitch"
            isChecked={!disableNotifications}
            onChange={toggleDisableNotifications}
          />
        </FormLabel>
      </FormControl>
      <FormControl
        width="100%"
        alignItems="center"
        borderBottom="1px solid"
        borderRight="1px solid"
        borderLeft="1px solid"
        borderColor="reader.neutral.800"
        borderBottomRadius="8px"
        display="flex"
      >
        <FormLabel
          cursor="pointer"
          htmlFor="email-switch"
          display="flex"
          justifyContent="space-between"
          p={4}
          flex={1}
          m={0}
        >
          <Text fontWeight="600" fontSize="14px" lineHeight="20px">
            {t("screens.reader.addToMenu.send_emails")}
          </Text>
          <Switch
            id="email-switch"
            size="md"
            variant="readerSwitch"
            isChecked={!disableEmails}
            onChange={toggleDisableEmails}
          />
        </FormLabel>
      </FormControl>
    </Flex>
  );
}
