import {
  Text,
  Box,
  Flex,
  Spinner,
  Link,
  useBreakpoint,
  Grid,
} from "@chakra-ui/react";
import useResizeObserver from "@react-hook/resize-observer";
import { memo, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { CUSTOM_CARD_TYPES } from "../../../components/cards/constants";
import { ContentCard } from "../../../components/cards/ContentCard";
import { HeroCardContainer } from "../../../components/cards/HeroCardContainer";
import { sizeEstimator } from "../../../components/cards/hooks/useGridCardDimensions";
import { ButtonCarousel } from "../../../components/carousel/ButtonCarousel";
import { VirtualGrid } from "../../../components/grid/VirtualGrid";
import { SectionTitle } from "../../../components/text/SectionTitle";
import {
  Article,
  Artist,
  Comic,
  ContentSectionCustomCard,
  CustomCardTypeValues,
  Release,
  Section,
} from "../../../types";
import {
  useIsMediumBreakpoint,
  useIsSmallBreakpoint,
} from "../../../utils/useBreakpoints";
import { ComicReleaseCalendarContainer } from "../../../components/cards/ComicReleaseCalendarContainer";
import { ArrowTraditionalIcon } from "../../../components/icons/ArrowTraditionalIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCardsSizing } from "../../reader/hooks/hookstate/useCalendarSizing";
import { FeatureCardContainer } from "../../../components/cards/FeatureCardContainer";
import { StreamLinksContainer } from "../../../components/cards/StreamLinkContainer";

interface ContentCardGridProps {
  data: (Section | undefined)[];
  isLoading: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage: boolean;
  onFetchNextPage?: () => void;
  onScroll?: (scrollableRef: HTMLDivElement) => void;
  navbarCollapsed?: boolean;
  positionOffset?: number;
}

export function ContentCardGrid({
  data,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onFetchNextPage,
  onScroll,
  positionOffset = 0,
}: ContentCardGridProps) {
  const isSmallScreen = useIsSmallBreakpoint();
  const isMediumScreen = useIsMediumBreakpoint();

  if (isLoading) {
    return (
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="center"
        grow={1}
      >
        <Spinner color="blaze.blaze" size="xl" />
      </Flex>
    );
  }

  return (
    <VirtualGrid
      onScroll={onScroll}
      overscan={1}
      pageBuffer={7}
      direction="vertical"
      scrollContainerHeight={`calc(100vh - ${isSmallScreen ? 0 : 151 + positionOffset}px)`}
      scrollContainerWidth="100%"
      measureElement={(el) => {
        const index = el.getAttribute("data-index") ?? -1;
        const item = data[index as number];

        const { mode, context, size } = item?.meta.layout.style ?? {};
        const isCustomCard = Object.values(CUSTOM_CARD_TYPES).includes(
          context as CustomCardTypeValues,
        );
        const isHeroCard = context === CUSTOM_CARD_TYPES.hero;

        const showComicReleaseCalendarVertical =
          mode === "list" && size === "r_list" && isMediumScreen;

        if (showComicReleaseCalendarVertical) {
          return (
            el.children[0].getBoundingClientRect().height +
            (isSmallScreen ? 48 : 64)
          );
        }
        const bottomPadding = isSmallScreen ? 48 : 64;

        if (mode === "grid" || mode === "horizontal") {
          // Hero card currently has horizontal mode set so it meets the condition
          if (isHeroCard) return el.children[0].getBoundingClientRect().height;

          if (el.children[0].children[1]) {
            return (
              el.children[0].getBoundingClientRect().height + bottomPadding
            );
          }
        }

        if (isCustomCard || mode === "calendar") {
          return el.children[0].getBoundingClientRect().height + bottomPadding;
        }

        return measureItemVertical(item);
      }}
      estimateSize={(index) => {
        const item = data[index];

        const { mode, context } = item?.meta.layout.style ?? {};
        const isCustomCard = Object.values(CUSTOM_CARD_TYPES).includes(
          context as CustomCardTypeValues,
        );
        const isHeroCard = context === CUSTOM_CARD_TYPES.hero;

        if (isCustomCard || mode === "calendar") {
          if (isHeroCard) {
            return 100;
          }
          return -1;
        }

        return measureItemVertical(item);
      }}
      totalCount={data.length}
      data={data}
      isFetchingNextPage={isFetchingNextPage}
      onFetchNextPage={onFetchNextPage}
      hasNextPage={hasNextPage}
      renderItem={(item, index) => {
        if (item) return <ContentRow item={item} sectionIndex={index} />;
      }}
    />
  );
}

function getSubItems(
  section: Section,
): (Comic | Artist | Article | Release | ContentSectionCustomCard)[] {
  switch (section.meta.layout.style.context) {
    case "comics":
      return section.comics;
    case "creators":
      return section.artists;
    case "news":
      return section.articles;
    case "releases":
      return section.releases;
    case CUSTOM_CARD_TYPES.hero:
    case CUSTOM_CARD_TYPES.featuredCarousel:
    case CUSTOM_CARD_TYPES.featuredSeriesRoadblock:
    case CUSTOM_CARD_TYPES.streamLinks:
      return section.cards;
  }

  return [];
}

function measureItemVertical(item: Section | undefined): number {
  if (!item) {
    return 0;
  }

  const { context, mode, size } = item.meta.layout.style;
  const dimensions = sizeEstimator(context, mode, size);

  const rowPadding = 56;
  const sectionTitleHeight = item.description ? 88 : 56;

  return dimensions.height + rowPadding + sectionTitleHeight;
}

function measureItemHorizontal(item: Section | undefined): number {
  if (!item) {
    return 0;
  }

  const { context, mode, size } = item.meta.layout.style;
  const dimensions = sizeEstimator(context, mode, size);

  return dimensions.width;
}

const ContentRow = memo(
  ({ item, sectionIndex }: { item: Section; sectionIndex?: number }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollByCount, setScrollByCount] = useState(1);
    const isSmallScreen = useIsSmallBreakpoint();
    const navigate = useNavigate();
    const breakpoint = useBreakpoint({ ssr: false });
    const isMediumScreen = useIsMediumBreakpoint();

    const columnCount = useMemo(() => {
      if (["xs", "sm"].includes(breakpoint)) return 1;
      else if (["md", "lg"].includes(breakpoint)) return 2;
      else if (["xl", "2xl"].includes(breakpoint)) return 3;
      else return 3;
    }, [breakpoint]);

    const [selectors] = useDeviceSelectors(window.navigator.userAgent);
    const { t } = useTranslation();

    const parentItem = item;
    const itemWidth = useMemo(
      () => measureItemHorizontal(parentItem),
      [parentItem],
    );

    const setCalendarScale = useCardsSizing();

    const { isMobile, isTablet } = selectors;
    const renderSmallScreenRow = isMobile || isTablet || isSmallScreen;

    const subGridData = useMemo(() => getSubItems(item), [item]);

    const { context, mode, size } = parentItem.meta.layout.style;
    const { display } = parentItem.meta.layout;
    const isHeroCard = context === CUSTOM_CARD_TYPES.hero;
    const isFeatureCard =
      context === CUSTOM_CARD_TYPES.featuredCarousel ||
      context === CUSTOM_CARD_TYPES.featuredSeriesRoadblock;

    const isRoadblock = context === CUSTOM_CARD_TYPES.featuredSeriesRoadblock;

    const isStreamLinkSection = context === CUSTOM_CARD_TYPES.streamLinks;

    const isContentCalendarSection = mode === "calendar";

    const isComicReleaseListSection = mode === "list" && size === "r_list";

    useResizeObserver(containerRef, (entry) => {
      const numItemsVisible = entry.contentRect.width / (itemWidth + 20);
      setScrollByCount(Math.floor(numItemsVisible));

      if (isContentCalendarSection || isComicReleaseListSection) {
        setCalendarScale(entry.contentRect.width, itemWidth);
      }
    });

    if (isContentCalendarSection) {
      return (
        <Box
          ref={containerRef}
          pl={renderSmallScreenRow ? 4 : undefined}
          mb={14}
          userSelect="none"
        >
          <ComicReleaseCalendarContainer
            data={subGridData as Comic[]}
            layout={parentItem.meta.layout}
            section={item}
            sectionIndex={sectionIndex}
          />
        </Box>
      );
    }

    if (isStreamLinkSection) {
      return isSmallScreen ? (
        <Box pl={renderSmallScreenRow ? 4 : undefined}>
          <SectionTitle
            mb={renderSmallScreenRow ? 6 : 8}
            subtitle={item.description}
          >
            {item.display_name}
          </SectionTitle>

          <StreamLinksContainer
            data={subGridData as ContentSectionCustomCard[]}
          />
        </Box>
      ) : (
        <Box />
      );
    }

    if (isHeroCard) {
      return (
        <HeroCardContainer
          data={subGridData as ContentSectionCustomCard[]}
          section={item}
          sectionIndex={sectionIndex}
        />
      );
    } else if (isFeatureCard) {
      return (
        <Box
          backgroundColor={isSmallScreen ? "charcoal.900" : "transparent"}
          height="fit-content"
          userSelect="none"
        >
          {isSmallScreen ? (
            <Box
              borderBottomRightRadius="30px"
              height="30px"
              mb={{ base: "32px", md: "40px" }}
              backgroundColor="charcoal.charcoal"
            />
          ) : null}
          <Box pl={renderSmallScreenRow ? 4 : undefined}>
            <SectionTitle
              mb={renderSmallScreenRow ? 6 : 8}
              subtitle={item.description}
            >
              {item.display_name}
            </SectionTitle>

            <FeatureCardContainer
              data={subGridData as ContentSectionCustomCard[]}
              scrollByCount={scrollByCount}
              isRoadblock={isRoadblock}
              section={item}
              sectionIndex={sectionIndex}
            />
          </Box>
          <Box
            borderTopRightRadius="30px"
            height={{ base: "30px", lg: "0px" }}
            mt={{
              base: "32px",
              md: "50px",
              lg: "0px",
            }}
            boxShadow={
              isSmallScreen
                ? "1px -1px 1px 0px var(--chakra-colors-transparent-white-20)"
                : "none"
            }
            backgroundColor={
              isSmallScreen ? "charcoal.charcoal" : "transparent"
            }
          />
        </Box>
      );
    }

    const showComicReleaseListSectionAsGrid =
      isComicReleaseListSection && isMediumScreen;

    /// Vertical cards have a hover animation that adds a margin on top, so section title margin needs to be adjusted
    const isVerticalGridCard =
      ((mode === "grid" || mode === "horizontal") && size === "m") ||
      size === "l";

    const sectionTitleMargin =
      (renderSmallScreenRow ? 6 : 8) - (isVerticalGridCard ? 2 : 0);

    const subGridItems = subGridData.map((item, index) => (
      <Box
        data-context={context}
        data-contentsize={size}
        data-mode={mode}
        data-includes={JSON.stringify(display)}
        key={item.id}
      >
        <ContentCard
          content={item}
          layout={parentItem.meta.layout}
          section={parentItem}
          index={index}
          sectionIndex={sectionIndex}
        />
      </Box>
    ));

    const linkUrl = new URL(parentItem.results_url);
    return (
      <Box
        ref={containerRef}
        pl={renderSmallScreenRow ? 4 : undefined}
        userSelect="none"
      >
        <SectionTitle
          mb={sectionTitleMargin}
          subtitle={item.description}
          action={
            display?.include_view_all ? (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(linkUrl.pathname + linkUrl.search);
                }}
                variant="brand"
                mr="16px"
                mt="2px"
                color="dune.700"
                fontSize={renderSmallScreenRow ? "12px" : "14px"}
                fontFamily="Roboto"
                fontWeight="500"
                letterSpacing="0.5"
                lineHeight={renderSmallScreenRow ? "14.06px" : "16.41px"}
              >
                <Flex direction="row" alignItems="center">
                  <Text whiteSpace="nowrap">{t("screens.root.viewAll")}</Text>
                  <ArrowTraditionalIcon
                    boxSize={renderSmallScreenRow ? "16px" : "24px"}
                    ml="4px"
                  />
                </Flex>
              </Link>
            ) : null
          }
        >
          {item.display_name}
        </SectionTitle>
        <Box position="relative" role="group">
          {showComicReleaseListSectionAsGrid ? (
            <Grid
              templateColumns={`repeat(${columnCount}, 1fr)`}
              gap={4}
              autoRows="1fr"
              justifyContent="center"
            >
              {subGridItems}
            </Grid>
          ) : (
            <ButtonCarousel
              width="100%"
              slidesToScroll={scrollByCount}
              containerProps={{ gap: renderSmallScreenRow ? 1.5 : 6 }}
              big
              includeSpacer={renderSmallScreenRow}
              showScrollbar={renderSmallScreenRow}
            >
              {subGridItems}
            </ButtonCarousel>
          )}
        </Box>
      </Box>
    );
  },
);
