import {
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  ButtonProps,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { State, useHookstate } from "@hookstate/core";
import { useMemo, forwardRef } from "react";
import { usePopoverOpenState } from "../../utils/usePopoverOpenState";
import { RadioFilter } from "../filters/RadioFilter";
import { FilterState as ComicReleasesFilters } from "../slideInMenu/comicDetails/LanguagesFilters";
import { appStore } from "../../appStore";
import { InitResponsePayloadResults } from "../../services/gc/types";
import { TextIcon } from "../icons/TextIcon";

export interface SortLanguageMenuProps {
  initialLanguageId: string;
  comicLanguageIds: string[];
  invalidate: () => void;
  filterState: string | State<ComicReleasesFilters>;
  onChange?: (value: string) => void;
}
export function SortLanguageMenu({
  invalidate,
  filterState,
  comicLanguageIds,
  onChange,
}: SortLanguageMenuProps) {
  const { ref, onToggle, isOpen, onClose } = usePopoverOpenState();
  const state = useHookstate(appStore);
  const { languages } = state.init.get() as InitResponsePayloadResults;
  const value =
    typeof filterState === "string"
      ? filterState
      : useHookstate(filterState.language).get();
  const comicLanguages = languages.filter((lang) =>
    comicLanguageIds.includes(lang.id),
  );

  const options = useMemo(
    () =>
      comicLanguages
        ? comicLanguages.map(({ id, name }) => {
            return {
              value: id,
              label: name,
            };
          })
        : [],
    [comicLanguages],
  );

  const enabled = value !== undefined;

  const activeOption = options.filter((option) => option.value == value);

  if (options.length === 0) return null;

  return (
    <Popover variant="filterSmall" gutter={4} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <SortLanguageTrigger
          isDisabled={!enabled}
          isActive={isOpen}
          style={{ pointerEvents: isOpen ? "none" : "auto" }}
          value={activeOption.length ? activeOption[0].label : "Language"}
          onMouseUp={onToggle}
        />
      </PopoverTrigger>
      <Portal>
        <Box position="relative" zIndex="1402">
          <PopoverContent
            ref={ref}
            variants={{
              enter: { opacity: 1, top: 0 },
              exit: { opacity: 0, top: -20 },
            }}
          >
            <PopoverBody>
              <RadioFilter
                value={value}
                options={options}
                onChange={(newLanguage) => {
                  if (typeof filterState === "string") {
                    onChange?.(newLanguage);
                  } else {
                    onChange
                      ? onChange(newLanguage)
                      : filterState.language.set(newLanguage);
                  }
                  onClose();
                  invalidate();
                }}
                useCheck
              />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

interface SortLanguageTriggerProps extends ButtonProps {}
const SortLanguageTrigger = forwardRef<
  HTMLButtonElement,
  SortLanguageTriggerProps
>(({ ...props }, ref) => {
  return (
    <Button
      variant="sortMenu"
      size="md"
      height="36px"
      ref={ref}
      isActive={props.isActive}
      gap="8px"
      {...props}
    >
      <Text variant="filterTag">{props.value}</Text>
      <TextIcon w={4.5} h={4.5} color="dune.100" />
    </Button>
  );
});
