import { Icon, IconProps } from "@chakra-ui/react";

export function PauseIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect x="7" y="4" width="1" height="16" rx="0.5" fill="currentColor" />
      <rect x="16" y="4" width="1" height="16" rx="0.5" fill="currentColor" />
    </Icon>
  );
}
