import ReactPixel from "react-facebook-pixel";

export function sendPurchaseEvent({
  value,
  currency,
}: {
  value: number;
  currency: string;
}) {
  if (process.env.NODE_ENV === "production") {
    ReactPixel.track("Subscribe", {
      value,
      currency,
    });
  }
}
