import { throttle } from "lodash-es";
import { useEffect } from "react";
import isTouchDevice from "../utils/isTouchDevice";
import { readerComponentsStore } from "../screens/reader/ReaderStateWrapper";

export function useViewPortScale() {
  const isTouch = isTouchDevice();
  const handleZoom = throttle(() => {
    if (window.visualViewport) {
      readerComponentsStore.vpScale.set(window.visualViewport.scale);
    }
  }, 200);

  useEffect(() => {
    if (isTouch) {
      if (window.visualViewport) {
        // Keep track of screen zoom
        window.visualViewport.addEventListener("resize", handleZoom);
      }
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", handleZoom);
      }
    };
  }, []);
}
