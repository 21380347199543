import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageParam, getPreviousPageParam } from "../../../utils/api";
import { GET_SECTIONS_QUERY_KEY, getSections } from "../api/rootScreenApi";
import { ContentCardGrid } from "./ContentCardGrid";
import { memo, useEffect, useMemo, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";
import { sizeMap } from "../../../components/cards/hooks/useGridCardDimensions";
import { useEntityCache } from "../../../hooks/useEntityCache";
import { Comic, ContentSectionCustomCard } from "../../../types";
import { useAccountData } from "../../../hooks/useAccountData";

export const CMSFeed = memo(
  ({
    activeStreamId,
    onScroll,
    showingStreamTab,
  }: {
    activeStreamId?: string;
    onScroll?: (scrollableRef: HTMLDivElement) => void;
    showingStreamTab?: boolean;
  }) => {
    const accountData = useAccountData();
    const { saveEntityToCache, saveComic } = useEntityCache();

    const abortController = useRef<AbortController>();
    useEffect(() => {
      abortController.current?.abort();
    }, [activeStreamId, accountData?.user?.id]);

    const {
      data,
      isLoading,
      isFetching,
      error,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
    } = useInfiniteQuery({
      enabled: !!accountData && !!activeStreamId,
      queryKey: [GET_SECTIONS_QUERY_KEY, accountData?.user?.id, activeStreamId],
      networkMode: "offlineFirst",
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      queryFn: async ({ pageParam, queryKey }) => {
        abortController.current = new AbortController();
        const activeStreamId = queryKey[2];
        if (!activeStreamId) null;
        const sections = await getSections(
          activeStreamId as string,
          pageParam,
          abortController.current.signal,
        );

        sections.data.payload.results = sections.data.payload.results.filter(
          (section) => {
            [
              ...(section.articles ?? []),
              ...(section.artists ?? []),
              ...(section.cards ?? []),
              ...(section.comics ?? []),
              ...(section.releases ?? []),
            ].forEach((n) => {
              if (section.meta.layout.style.context === "comics") {
                saveComic(section, n as Comic);
              } else {
                saveEntityToCache(n);
              }

              if (n.entity_type === "ContentSectionCustomCard") {
                const comic = (n as ContentSectionCustomCard).comic;
                if (comic) {
                  saveComic(section, comic);
                }
                const artist = (n as ContentSectionCustomCard).artist;
                if (artist) {
                  saveEntityToCache(artist);
                }
              }
            });

            if (!section) return false;
            const { context, mode } = section.meta.layout.style;
            const dimension = sizeMap[context]?.[mode];
            return dimension;
          },
        );

        return sections;
      },
      initialPageParam: 1,
      getNextPageParam,
      getPreviousPageParam,
    });

    if (error && error.name !== "CanceledError") {
      return (
        <Box width="100%">
          <Text>Something unexpected happened</Text>
        </Box>
      );
    }

    const allRows = useMemo(
      () => data?.pages.flatMap((page) => page.data.payload.results) ?? [],
      [data],
    );

    return (
      <ContentCardGrid
        key={activeStreamId}
        positionOffset={!showingStreamTab ? -85 : undefined}
        data={allRows}
        isLoading={isLoading || (isFetching && !allRows.length)}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onFetchNextPage={fetchNextPage}
        onScroll={onScroll}
      />
    );
  },
);
