import { ImmutableObject } from "@hookstate/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { ReleaseCommentEntity, ServerListResponse } from "../../../types";
import { AxiosResponse } from "axios";
import { useUserData } from "../../../hooks/useUserData";
import {
  GET_RELEASE_COMMMENTS,
  postReleaseComment,
} from "../api/readerScreenApi";
import { useReleaseCommentsData } from "./hookstate/useRelatedReleases";
import { useReleaseData } from "./hookstate/useReleaseData";

export function useComments() {
  const queryClient = useQueryClient();
  const { userData } = useUserData();

  const { releaseData } = useReleaseData();
  const { releaseCommentsData } = useReleaseCommentsData();

  const [newCommentValue, setNewCommentValue] = useState<string>();
  const [parentComment, setParentComment] = useState<
    ImmutableObject<ReleaseCommentEntity> | undefined
  >();

  useEffect(() => {
    if (parentComment && !newCommentValue) {
      setParentComment(undefined);
    }
  }, [newCommentValue]);

  useEffect(() => {
    if (parentComment) {
      setNewCommentValue(`@${parentComment.author.name}: `);
    } else {
      setNewCommentValue("");
    }
  }, [parentComment]);

  const QUERY_KEY = useMemo(() => {
    return [...GET_RELEASE_COMMMENTS, releaseData?.id ?? 0];
  }, [releaseData]);

  const { mutate } = useMutation({
    mutationFn: () =>
      postReleaseComment(releaseData?.id ?? 0, {
        body: newCommentValue ?? "",
        parent_id: parentComment?.id,
      }),
    onMutate: () => {
      const prevState = queryClient.getQueryData(QUERY_KEY) as AxiosResponse<
        ServerListResponse<ReleaseCommentEntity>
      >;
      const prevReleaseCommentsData = prevState.data.payload.results;
      const author = userData;

      const newState = {
        ...prevState,
        data: {
          payload: {
            results: prevReleaseCommentsData,
          },
        },
      };

      if (author && releaseData) {
        const newReleaseComment: ReleaseCommentEntity = {
          author,
          body: newCommentValue ?? "",
          parsed_body: `${newCommentValue ?? ""}<br />\n`,
          time_ago: "just now",
          post_date: new Date().toISOString().slice(0, 19).replace("T", " "),
          replies: [],
          level: parentComment ? 2 : 1,
          parent_id: parentComment?.author_id,

          artist_id: 0,
          author_id: 0,
          author_is_context_owner: true,
          can_edit: true,
          can_moderate: true,
          comment_type: "comics-releases",
          delete_url: "none",
          edit_url: "none",
          edit_time_ago: null,
          edit_date: null,
          edit_user_id: null,
          entity_type: "ReleaseComment",
          id: Date.now(),
          is_deleted: 0,
          pos: null,
          total_comments: 0,
          page_id: null,
          object_id: 0,
          url: "none",
        };

        if (parentComment) {
          const parentIndex = prevReleaseCommentsData.findIndex(
            (comment: ReleaseCommentEntity) => {
              return comment.id === parentComment.id;
            },
          );
          const newReleaseComments = [...prevReleaseCommentsData];
          if (parentIndex > -1) {
            newReleaseComments[parentIndex].replies = [
              ...newReleaseComments[parentIndex].replies,
              newReleaseComment,
            ];
          }
          newState.data.payload.results = newReleaseComments;
        } else {
          newState.data.payload.results = [
            ...prevReleaseCommentsData,
            newReleaseComment,
          ];
        }

        queryClient.setQueryData(QUERY_KEY, newState);
      }

      return prevState;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(QUERY_KEY, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY });
      setNewCommentValue("");
      setParentComment(undefined);
    },
  });

  return {
    newCommentValue,
    setNewCommentValue,
    releaseCommentsData,
    postReleaseComment: mutate,
    parentComment,
    setParentComment,
  };
}
