import { ListItem, Text, UnorderedList, Divider } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { SectionTitle } from "../../../components/text/SectionTitle";
import { LegalHeader } from "./LegalHeader";
import { LegalLink } from "./LegalLink";

export function PrivacyPolicy() {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <>
      <LegalHeader title="Privacy Policy" />
      <Text variant="legalContent">
        GlobalComix (together with its subsidiaries, "GlobalComix" or "we")
        considers the protection of personal privacy to be one of our most
        important values. This privacy policy ("Policy") gives you specific
        information on how we protect your privacy, how we treat personal
        information, and the choices you have.
      </Text>
      <Text variant="legalContent">
        This Policy applies to all web sites and services owned or provided by
        GlobalComix and its subsidiaries, which we refer to here collectively as
        "GlobalComix Sites". GlobalComix Sites include, but are not limited to,
        GlobalComix, as well as other GlobalComix-branded services. From time to
        time we may add new sites, domain names or services to those listed
        above. Please note that this Policy applies only to sites owned and
        services maintained by GlobalComix or its corporate subsidiaries and not
        to web sites or services provided by other companies or organizations to
        which we link.
      </Text>
      <Text variant="legalContent">
        As a condition of using the GlobalComix Sites, you agree to be bound by
        the terms of this Policy. Additional terms and conditions which govern
        your use of the GlobalComix Sites are contained in our User Agreement,
        and, as applicable, in certain other service-specific license agreements
        or terms of use, which you will be notified of at the time of your
        registration for the applicable services.
      </Text>
      <Text variant="legalContent" mb={isSmallBreakpoint ? "24px" : "40px"}>
        The Policy is accessible through the GlobalComix Sites in several ways,
        including from a link entitled "Privacy" at the bottom of the
        GlobalComix home page. You acknowledge that this Policy may change over
        time. We reserve the right, in our sole discretion, to revise this
        Policy at any time. If we decide to change our Policy, we will post the
        revised policy on the GlobalComix Sites. Any changes are effective
        immediately upon posting to the GlobalComix Sites. As we may make
        changes at any time without notifying you, we suggest that you
        periodically consult this Policy. Please note that our rights to use
        your personally identifiable information will be based on the privacy
        policy in effect at the time the information is collected. Your
        continued use of the GlobalComix Sites constitutes your agreement to all
        such terms and conditions of the Policy.
      </Text>
      <Divider
        height="1px"
        width="100%"
        bg="transparent.white.20"
        mb={isSmallBreakpoint ? "24px" : "40px"}
      />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Information Collection, Storage and Use
      </SectionTitle>
      <Text variant="legalContent">
        GlobalComix (together with its subsidiaries, "GlobalComix" or "we")
        considers the protection of personal privacy to be one of our most
        important values. This privacy policy ("Policy") gives you specific
        information on how we protect your privacy, how we treat personal
        information, and the choices you have.
      </Text>
      <Text variant="legalContent">
        We may gather navigational, clickstream data that indicates the areas of
        the GlobalComix Sites you visit or access. This navigational data does
        not identify you as an individual user (i.e., based on your PII). We may
        enter into agreements with outside companies that possess the technology
        that allows GlobalComix to customize the advertising and marketing
        messages you receive on the GlobalComix Sites. Your non-PII and click
        stream data about your activities on the GlobalComix Sites may be shared
        with these companies so this customization can be accomplished. These
        companies have agreed not to share your information with any third party
        or use it for any other purpose. Anonymous click stream and demographic
        information may also be shared with GlobalComix's advertisers and
        business partners. We also use and share navigational data in aggregate,
        non-personal form to understand how our users as a group use the
        GlobalComix Sites.
      </Text>
      <Text variant="legalContent">
        Please keep in mind that whenever you voluntarily disclose personal
        information in public areas of a GlobalComix Site, such as the member
        home pages and message boards, your information can be viewed and
        possibly used by others. We advise users to be especially careful in
        such public areas about disclosing Personal Information, such as your
        full name, home address, or phone number.
      </Text>
      <Text variant="legalContent">
        Below are more details about the Personal Information we, or third
        parties may gather from you and how we may use it.
      </Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            <Text variant="legalContent">
              Membership Information. When you register for membership on a
              GlobalComix Site, we will ask you for certain minimum required
              information, such as your name, birth date, gender, email address
              and zip code. We may also ask for additional information about you
              and your interests. We do this so we can better understand your
              preferences and tailor the GlobalComix Sites to suit you better.
              The more information you volunteer, the more we can tailor the
              GlobalComix Site and promotions to your interests. Additionally,
              third-party service providers may require you to assent to their
              privacy party as a condition to receiving their service.
            </Text>
          </Text>
        </ListItem>
        <UnorderedList
          variant={"legal"}
          mb={isSmallBreakpoint ? "24px" : "40px"}
          marginLeft="24px"
        >
          <ListItem>
            <Text variant="legalContent">
              Service Providers. We offer our members other services provided by
              third parties such as email and userpages, among others. When you
              register for membership on a GlobalComix Site or activate a
              related service by clicking on a particular service link (i.e.
              email) on an GlobalComix Site, you may be automatically registered
              to receive the services we offer and some of your Personal
              Information may be transferred from GlobalComix to those
              third-party service providers. By becoming a member, you authorize
              us and our third-party service providers to send you email updates
              and information about promotions and special offers. We take care
              to require that our third-party service providers only use our
              member's data as necessary to provide the particular service and
              to give you updates on that service provider's products and
              services.
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Comics Translators. We provide comics Translators with certain
              hosted technology and software that enables various comics related
              functionalities, such as an online archive to store your work. If
              you are not already a registered user of the GlobalComix Sites you
              may be asked to provide Personal Information in connection with
              your use of such services. We share Personal Information with
              comics owners/publishers that offer such services to enable those
              entities to operate the services you have elected to receive.
              comics owners/publishers may also use Personal Information for
              other business purposes, provided that such use shall be in
              compliance with the terms of this Policy, except that a comics
              owner/publisher (a) will not contact you or otherwise use or
              transfer your Personal Information, unless you have expressly
              consented. Upon giving such consent your Personal Information will
              be subject to the comics owner's/publisher's privacy policy.
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Co-registration with Other Sites. As a member, we offer you the
              ability to co-register with select third-party partner sites. In
              some instances where GlobalComix provides co-registration, the
              co-registration partners allow you to opt-out of their membership.
              If you choose to co-register with our partners and accept their
              terms of service, then you are automatically registered as a
              member of their select web sites and your Personal Information is
              transferred to those organizations. As a result, you may receive
              information from one of these web sites as any of their members
              would. We advise you to review the privacy and data collection
              policies of the third-party partner sites for further information.
              In order to cancel your membership with any of these partners you
              will need to go directly to the partner's site.
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Advertisers. GlobalComix may send you emails on behalf of our
              advertisers. If you opt to receive information from our
              advertisers they may use your Personal Information to send you
              advertisements or other notices, via email or postal mail, and it
              is possible that your information may be shared with others.
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="legalContent">
              Other Parties. We occasionally provide third parties with Personal
              Information limited only to your E-mail address in order to send
              you promotional mailings.
            </Text>
          </ListItem>
        </UnorderedList>
        <ListItem>
          <Text variant="legalContent">
            Email Updates to Members. If you so elect at registration, we may
            periodically send you promotional email about products or services
            offered by the GlobalComix Sites, our partners and advertisers. If
            you want to update your email address or stop receiving these types
            of promotional emails, please follow the procedures to unsubscribe
            at the bottom of any email we send you.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Promotions. We may ask you for Personal Information at other times,
            including, but not limited to, when you enter sweepstakes, contests
            or promotions sponsored by GlobalComix or a third party. If you
            choose to enter sweepstakes, contests or promotions sponsored by a
            third party, the Personal Information you provide will be subject to
            the privacy policy of the third party sponsor. We encourage you to
            check the applicable third party policy before providing any
            Personal Information.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Surveys and User Research. GlobalComix conducts email and
            web-delivered surveys from time to time, as well as instant polls,
            to gather information about our users. Taking these surveys and
            polls is entirely optional. You have no obligation to respond to
            them. We share only the aggregate results of these surveys, not
            Personal Information, with our advertisers and partners to help them
            better understand our services and monitor the reach of their
            advertising or other offerings on our service. We also use some of
            the Personal Information that you provide in these surveys to help
            us understand your interests and to make the GlobalComix Sites more
            useful to you.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Use of Cookies. On the Internet, a "cookie" is a piece of
            information (a file) that a Web site transfers to a user's computer
            for record-keeping purposes. The use of cookies is common on the
            Internet. The process we use to serve advertisements and to track
            use of the GlobalComix Sites involves cookies. We use cookies to
            recognize users who have already registered for a GlobalComix Site,
            allowing those users to speed up their future activities on the
            GlobalComix Sites. Cookies help GlobalComix and our advertising
            service (discussed below) track the frequency and placement of
            advertisements. Cookies also enable us to measure aggregate (total)
            usage and traffic to the GlobalComix Sites and to specific
            offerings. This aids us in producing content that best meets our
            users' preferences. In order to implement new services, we may use
            other types of cookies as well. We also may use cookies to
            understand better what parts of the GlobalComix Sites you like best.
            We may use that information to display advertisements that we think
            will be of particular interest to you. You may configure your
            browser to reject cookies, but this may interfere with some
            functionality of the GlobalComix Sites. To find out more about
            changing your browser to reject cookies, we suggest the EPIC Cookies
            Page{" "}
            <LegalLink href="https://www.epic.org/privacy/internet/cookies">
              www.epic.org/privacy/internet/cookies
            </LegalLink>
            . Certain pages of the GlobalComix Sites may contain electronic
            images (called a "single-pixel GIF" or "web beacon") that allow a
            web site to count users who have visited that page. GlobalComix
            occasionally uses web beacons on the GlobalComix Sites in order to
            count users and to recognize registered users by accessing the
            GlobalComix cookies.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Purchase of Products or Services. When you purchase products or
            services from or through the GlobalComix Sites we may require
            Personal Information such as name, address, telephone number and
            billing information, including credit card, debit card or bank
            account data. GlobalComix will not disclose your Personal
            Information, including billing information, except to the extent
            necessary to complete your transaction, for example during an online
            purchase or where needed to deliver a product or service you
            ordered.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Software Updates; Interactive Material. GlobalComix automatically
            updates software used in conjunction with certain of the services
            available on the GlobalComix Sites. In order to update this
            software, GlobalComix collects a user's current version information
            from the user's computer. This information is not archived and is
            only used to determine which software requires updating. GlobalComix
            may record audio conversations or copy text chat or video images
            from chat rooms on the GlobalComix Sites and may disclose such
            material for use in connection with promoting the GlobalComix Sites.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Sites You Visit. As you access our Partner Sites and browse around
            the GlobalComix Sites, GlobalComix may store information about which
            pages and which Partner Sites you visit and other information
            presented in your browser's URL address line. GlobalComix will use
            this information to help determine what our users are most
            interested in.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Sharing Non-Personal Information. GlobalComix may share information
            that is not Personal Information -- meaning non-personal, aggregate,
            or summary, information regarding our users -- with other third
            parties not mentioned in this Policy. This kind of information does
            not identify you individually.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Future Business Transactions. As we continue to develop our
            business, we might sell certain of our assets. In such transactions,
            user information, including personally identifiable information,
            Personal Information, generally is one of the transferred business
            assets. By submitting your personal information on one of the
            GlobalComix Sites, you agree that in the event of a merger,
            acquisition, reorganization, sale of all or substantially all of its
            assets, or the sale of an individual website owned by GlobalComix,
            GlobalComix may transfer your Personal Information to a third party
            as a part of such merger, acquisition, reorganization, sale, or
            other future business transaction.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Legal Authorities. We may also disclose Personal Information in
            cases when we reasonably believe that it is necessary to identify
            someone who may be violating GlobalComix policies or GlobalComix
            Sites" User Agreements or other terms of use, or who may be a threat
            to GlobalComix or third party rights, property, or personal safety.
            The GlobalComix Sites may disclose Personal Information to comply
            with valid legal process such as a duly issued search warrant,
            subpoena or court order.
          </Text>
        </ListItem>
      </UnorderedList>
      <Divider
        height="1px"
        width="100%"
        bg="transparent.white.20"
        mb={isSmallBreakpoint ? "24px" : "40px"}
      />
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Information Storage and Security
      </SectionTitle>
      <Text variant="legalContent">
        Your Personal Information is stored on GlobalComix computer servers (or
        those of its service providers) located in the United States. We protect
        your Personal Information with technical, administrative and physical
        safeguards to protect against loss, unauthorized access, destruction,
        misuse, modification and improper disclosure. No computer system or
        information can ever be fully protected against every possible hazard.
        But GlobalComix is committed to providing reasonable and appropriate
        security controls to protect our GlobalComix Sites and all Personal
        Information against foreseeable hazards.
      </Text>
      <Text variant="legalContent">
        Notwithstanding these security measures, please be aware that when you
        submit Personal Information to the GlobalComix Sites over the Internet,
        the information may travel over many systems that are not under the
        GlobalComix's control. We take the protection of user data very
        seriously and to that end take reasonable safeguards to prevent
        interception of any Personal Information. In the case of shopping pages,
        for example, the credit card information you submit is protected by
        secure servers.
      </Text>
      <Text variant="legalContent">
        We use third-party advertising companies to serve ads when you visit our
        Web site. These companies may use aggregated information (not including
        your name, address, email address or telephone number) about your visits
        to this and other Web sites in order to provide advertisements about
        goods and services of interest to you. If you would like more
        information about this practice and to know your choices about not
        having this information used by these companies,{" "}
        {
          <LegalLink href="http://www.networkadvertising.org/managing/opt_out.asp">
            click here.
          </LegalLink>
        }
      </Text>
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Accessing, Correcting and Updating Your Information
      </SectionTitle>
      <Text variant="legalContent">
        You have the ability to review, change and correct the personally
        identifiable information you provide us. If you are a registered user,
        you can access, correct or update your account or other Personal
        Information such as your city or interests, by clicking the "Control
        panel" link on the GlobalComix Site on which you are registered. If you
        are a registered user, you may also request that we remove from our
        databases the information you provided when you registered with a
        GlobalComix Site by e-mailing us at{" "}
        <LegalLink href="mailto:contact@globalcomix.com">
          contact@globalcomix.com
        </LegalLink>{" "}
        If you are not a registered user, but have provided personally
        identifiable information to us, you may make changes to your personally
        identifiable information by e-mailing us at{" "}
        <LegalLink href="mailto:contact@globalcomix.com">
          contact@globalcomix.com
        </LegalLink>
        .
      </Text>
      <Text variant="legalContent">
        If you have any other questions, comments or complaints about this
        Policy, write to us at:{" "}
        <LegalLink href="mailto:contact@globalcomix.com">
          contact@globalcomix.com
        </LegalLink>
        .
      </Text>
      <SectionTitle mb={isSmallBreakpoint ? "24px" : "40px"}>
        Unsubscribe
      </SectionTitle>
      <Text variant="legalContent">
        When you register or enter one of the GlobalComix Site, you may opt out
        of receiving future e-mail communications from us. You may change your
        e-mail preferences at any time by revising your profile (as explained
        above), clicking the "unsubscribe" link at the bottom of each e-mail you
        receive from us and following the instructions provided or, if you do
        not have a profile, by e-mailing us at{" "}
        <LegalLink href="mailto:contact@globalcomix.com">
          contact@globalcomix.com
        </LegalLink>{" "}
        . Below are specific instructions to unsubscribe from newsletters,
        e-mail updates and special offers, and other services.
      </Text>
      <Text variant="legalContent">
        Email updates and special offers: You may unsubscribe to email
        announcements by following the instructions at the bottom of each email.
      </Text>
      <Text variant="legalContent">
        Services: You may unsubscribe to any service for which you have
        previously elected by following the instructions included in the
        GlobalComix User Agreement.
      </Text>
    </>
  );
}
