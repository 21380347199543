import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { calculateTimeLeft } from "../../utils/time";
import { useTimer } from "../../screens/root/components/TimerContext";

interface LabeledCountdownTimerProps extends BoxProps {
  targetTimestamp: number;
  labelFontSize?: string;
  labelColor?: string;
  showAllLabels?: boolean;
}

export function LabeledCountdownTimer({
  targetTimestamp,
  labelFontSize,
  labelColor,
  showAllLabels = true,
  ...boxProps
}: LabeledCountdownTimerProps) {
  useTimer();
  const isSmallScreen = useIsSmallBreakpoint();

  const countdownTime = calculateTimeLeft(targetTimestamp);

  return (
    <Flex
      borderRadius="16px"
      border="2px solid"
      borderColor="transparent.white.20"
      px={isSmallScreen ? "16px" : "24px"}
      py={isSmallScreen ? "12px" : "16px"}
      alignItems="center"
      justifyContent="center"
      gap={isSmallScreen ? 2 : 4}
      {...boxProps}
    >
      <TimeBox
        label="d"
        value={countdownTime.days}
        fontSize={labelFontSize}
        labelColor={labelColor}
      />
      <Box width="1px" height="100%" bg="transparent.white.20" py="2px" />
      <TimeBox
        label="h"
        value={countdownTime.hours}
        fontSize={labelFontSize}
        labelColor={labelColor}
      />
      <Box width="1px" height="100%" bg="transparent.white.20" py="2px" />

      <TimeBox
        label="m"
        value={countdownTime.minutes}
        fontSize={labelFontSize}
        labelColor={labelColor}
      />
      {showAllLabels && (
        <>
          <Box width="1px" height="100%" bg="transparent.white.20" py="2px" />
          <TimeBox
            label="s"
            value={countdownTime.seconds}
            fontSize={labelFontSize}
            labelColor={labelColor}
          />
        </>
      )}
    </Flex>
  );
}

interface TimeBoxProps extends BoxProps {
  label: string;
  labelColor?: string;
  value: string | undefined;
}

function TimeBox({ label, value, labelColor, ...boxProps }: TimeBoxProps) {
  const isSmallScreen = useIsSmallBreakpoint();

  // Convert the string to a number to remove any leading zeros, then convert it back to a string.
  const formattedValue = value ? Number(value).toString() : "0";

  const defaultFontSize = isSmallScreen ? "20px" : "24px";

  return (
    <Box
      textAlign="center"
      fontWeight="400"
      {...boxProps}
      fontSize={boxProps.fontSize ? boxProps.fontSize : defaultFontSize}
    >
      <Text
        variant="monospaceTime"
        as="span"
        fontSize="inherit"
        fontWeight="inherit"
        color="dune.dune"
        mr="2px"
      >
        {formattedValue}
      </Text>
      <Text
        as="span"
        variant="monospaceTime"
        fontSize="inherit"
        fontWeight="inherit"
        color={labelColor ?? "dune.800"}
      >
        {label}
      </Text>
    </Box>
  );
}
