import { hookstate } from "@hookstate/core";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../components/sortMenus/SortOrderMenu";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../components/sortMenus/SortDurationMenu";
import { DEFAULT_READING_STATUS_TYPE } from "./LibraryFilterCarousel";

export type FilterState = {
  query?: string;
  comicReadingStatus?: string;
  sortMenus: {
    sortOrder?: string;
    sortDuration?: string;
  };
};

const DEFAULT_FILTER_STATE = {
  comicReadingStatus: DEFAULT_READING_STATUS_TYPE,
  sortMenus: {
    sortOrder: DEFAULT_SORT_ORDER_TYPE,
    sortDuration: DEFAULT_SORT_DURATION_TYPE,
  },
};

export const libraryFilterStore = hookstate<FilterState>(DEFAULT_FILTER_STATE);
