import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FollowIcon } from "../../../../components/icons/FollowIcon";
import { FollowingIcon } from "../../../../components/icons/FollowingIcon";
import { useComicNavigation } from "../../hooks/useComicNavigation";
import { Stats } from "../shared/Stats";
import { useComicData } from "../../hooks/hookstate/useComicData";
import { useEndInterstitial } from "../../hooks/useEndInterstital";
import { useToggleArtistFollow } from "../../../../components/cards/hooks/useToggleArtistFollow";
import { useQuery } from "@tanstack/react-query";
import { Artist, Comic } from "../../../../types";
import { useUserData } from "../../../../hooks/useUserData";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";
import useAccountCreationCTA from "../../hooks/useAccountCreationCTA";
import { FunnelContextArea } from "../../../../components/funnel/types";

interface EndOfReleaseCTAProps {}
export function EndOfReleaseCTA({}: EndOfReleaseCTAProps) {
  const { comicData } = useComicData();
  const { userData } = useUserData();

  const artistCacheKey = `${comicData?.artist.entity_type}-${comicData?.artist.id}`;
  const handleToggleArtistFollow = useToggleArtistFollow(artistCacheKey);

  const { data: artistData } = useQuery<Artist>({
    queryKey: [artistCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.followArtist,
    artistId: comicData?.artist.id,
    comic: comicData as Comic,
  });

  const userIsFollowing = artistData?.user_is_following;
  return (
    <>
      <Flex
        width="400px"
        gap="16px"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="100%"
        backgroundColor="inherit"
      >
        <Flex gap="16px">
          <Image
            width="64px"
            height="64px"
            borderRadius="12px"
            src={comicData?.artist.avatar_url}
            alt={`Thumbnail for artist ${comicData?.artist?.roman_name}`}
          />
          <Flex flexDirection="column">
            <Text fontSize="16px" fontWeight="bold" lineHeight="24px">
              {comicData?.artist?.roman_name}
            </Text>
            <Text
              fontSize="12px"
              fontWeight="bold"
              lineHeight="24px"
              color="reader.blue.200"
            >
              {comicData?.artist.artist_type.name}
            </Text>
          </Flex>
        </Flex>
        <Button
          variant="iconOnly"
          aria-label={userIsFollowing ? "Unfollow" : "Follow"}
          size="thin"
          py="6px"
          px="16px"
          bg="reader.altblue.500"
          bgGradient="linear(to-l, reader.altblue.500, reader.altblue.700)"
          borderRadius="9999px"
          gap="12px"
          onClick={(e) => {
            e.stopPropagation();
            if (userData) {
              handleToggleArtistFollow();
            } else {
              setFunnelModalConfig({
                options: {
                  onAuthenticated: handleToggleArtistFollow,
                  navigationContext,
                },
                type: FUNNEL_TYPES.signup,
              });
            }
          }}
        >
          {userIsFollowing ? (
            <FollowingIcon color="blaze.400" width="18px" height="18px" />
          ) : (
            <FollowIcon width="18px" height="18px" />
          )}
          <Text fontSize="12px" fontWeight="600px" lineHeight="16px">
            {userIsFollowing
              ? t("screens.reader.interstitial.unfollow")
              : t("screens.reader.interstitial.follow")}
          </Text>
        </Button>
      </Flex>
      <Stats
        stats={[
          {
            value: comicData?.artist.total_favorites,
            text: t("screens.reader.interstitial.followers"),
          },
          {
            value: comicData?.artist.total_projects,
            text: t("screens.reader.interstitial.comics"),
          },
          {
            value: comicData?.artist.total_releases,
            text: t("screens.reader.interstitial.releases"),
          },
        ]}
      />
      <StartNextRelease />
    </>
  );
}

interface StartNextReleaseProps {
  isBig?: boolean;
}
export function StartNextRelease({ isBig }: StartNextReleaseProps) {
  const { goToNextRelease } = useComicNavigation();
  const { toggleShowEndInterstitial } = useEndInterstitial();

  const startNextRelease = () => {
    goToNextRelease();
    toggleShowEndInterstitial();
  };

  const { triggerAccountCreationCTA } = useAccountCreationCTA({
    onCompleteOrDismiss: startNextRelease,
  });

  return (
    <Button
      variant="iconOnly"
      aria-label="Start reading next release"
      size="thin"
      bgGradient="linear(to-l, blaze.600, blaze.700)"
      borderRadius={isBig ? "20px" : "16px"}
      py={isBig ? "16px" : "8px"}
      px="24px"
      maxWidth={isBig ? "450px" : "none"}
      width={isBig ? "100%" : "auto"}
      onClick={triggerAccountCreationCTA}
    >
      {isBig ? (
        <Text fontSize="12px" fontWeight="600" lineHeight="16px">
          {t("screens.reader.interstitial.start_reading")} &nbsp;&nbsp;&#10095;
        </Text>
      ) : (
        <Text fontSize="12px" fontWeight="600" lineHeight="16px">
          {t("screens.reader.interstitial.start_reading")}
        </Text>
      )}
    </Button>
  );
}
