import { BoxProps, Flex, Text } from "@chakra-ui/react";

export interface TagBadgeProps extends BoxProps {
  icon?: React.ReactNode;
  text: string;
  background?: string;
  contentColor?: string;
  isLarge?: boolean;
}

export function TagBadge({
  icon,
  text,
  background,
  contentColor,
  isLarge,
  ...props
}: TagBadgeProps) {
  return (
    <Flex
      width="fit-content"
      gap="5px"
      alignItems="center"
      background={background ?? "charcoal.900"}
      borderRadius="4px"
      p="4px 8px"
      color={contentColor ?? "neutral.200"}
      {...props}
    >
      {icon}
      <Text
        textTransform="uppercase"
        fontSize={isLarge ? "10px" : "8px"}
        fontWeight="700"
        lineHeight="150%"
        letterSpacing={isLarge ? ".25px" : undefined}
      >
        {text}
      </Text>
    </Flex>
  );
}
