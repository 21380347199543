import { Flex, Text } from "@chakra-ui/react";

interface SlideCounterProps {
  currentSlide: number;
  slidesCount: number;
}

const SlideCounter: React.FC<SlideCounterProps> = ({
  currentSlide,
  slidesCount,
}) => {
  return (
    <Flex
      fontWeight={400}
      direction="row"
      justify="center"
      align="center"
      width="8ch"
      color="neutral.white"
    >
      <Text
        width={slidesCount > 9 ? "2ch" : "1ch"}
        ml={slidesCount > 9 ? "-4px" : undefined}
        textAlign="right"
        color="blaze.blaze"
      >
        {currentSlide + 1}
      </Text>
      <Text mx={2}>/</Text>
      <Text>{slidesCount}</Text>
    </Flex>
  );
};

export default SlideCounter;
