import { Article, Artist, Comic, ReadStatus, Release } from "../types";
import { buildReaderUrl } from "./buildReaderUrl";

const ctaFunctions: {
  [key: string]: (content: any) => string;
} = {
  Comic: getSeriesCTA,
  Release: getReleaseCTA,
  Artist: getArtistCTA,
  News: getNewsCTA,
};
export function getCTAUrl(content: Comic | Release | Artist | Article) {
  return ctaFunctions[content.entity_type](content);
}

function getSeriesCTA(content: Comic) {
  let readUrl = content.read_url;
  if (content.hasOwnProperty("first_release_uuid")) {
    return "/read/" + content.first_release_uuid + "/1";
  }
  return getReadURL(readUrl, content.user_read_status);
}

function getReleaseCTA(content: Release) {
  if (content.read_url) {
    return getReadURL(content.read_url, content.user_read_status);
  }

  return (
    "/read/" +
    content.key +
    `/${content.user_read_status?.last_page_read ?? 1} `
  );
}

function getArtistCTA(content: Artist) {
  const url = new URL(content.url);
  return url.pathname;
}

function getNewsCTA(content: Article) {
  const url = new URL(content.url);
  return url.pathname;
}

function getReadURL(readUrl: string, userReadStatus: ReadStatus | null) {
  const url = new URL(readUrl);

  return buildReaderUrl(
    url.pathname,
    userReadStatus?.uuid,
    userReadStatus?.last_page_read,
  );
}
