import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { ListIcon } from "../../../../components/icons/ListIcon";
import { ComicCategoryEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const COMIC_CATEGORY_ALL = "0";

interface ComicCategoryTypeFilterProps {
  value: Immutable<string | undefined>;
  comicCategories: ComicCategoryEntity[];
  onChange: (nextValue: string) => void;
}
export function ComicCategoryTypeFilter({
  value,
  comicCategories,
  onChange,
}: ComicCategoryTypeFilterProps) {
  const categoryOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: COMIC_CATEGORY_ALL },
      ...comicCategories.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );
  return (
    <RadioFilter value={value} options={categoryOptions} onChange={onChange} />
  );
}

interface ComicCategoryTypeFilterTitleProps {
  comicCategories: ComicCategoryEntity[];
  isTrigger: boolean;
  open?: boolean;
  value?: string;
}
export const ComicCategoryFilterTitle = forwardRef(
  (
    {
      isTrigger,
      comicCategories,
      open,
      value,
      ...props
    }: ComicCategoryTypeFilterTitleProps,
    ref,
  ) => {
    const comicCategory = comicCategories.find(
      (category) => `${category.id}` == value,
    );

    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<ListIcon />}
          popoverRef={ref}
          text="Genre"
          isFiltering={comicCategory?.name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle text="Genre" filterValue={comicCategory?.name} open={open} />
    );
  },
);
