import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getNextPageParam, getPreviousPageParam } from "../../../utils/api";
import {
  GET_LIBRARY_COUNT_QUERY_KEY,
  GET_LIBRARY_LIST_QUERY_KEY,
  getLibraryCount,
  getLibraryList,
} from "../api/libraryScreenApi";
import { useLibraryFilterState } from "./useLibraryFiltersState";
import { useUserData } from "../../../hooks/useUserData";

export function useLibraryAPI() {
  const { filters, urlSettled } = useLibraryFilterState();
  const { userData } = useUserData();

  const { data: countData } = useQuery({
    queryFn: () => {
      return getLibraryCount();
    },
    queryKey: [...GET_LIBRARY_COUNT_QUERY_KEY, userData?.id],
    refetchOnWindowFocus: false,
  });

  const {
    isLoading,
    data,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    enabled: urlSettled,
    queryFn: (ctx) => {
      const paginatedFilters = new URLSearchParams(filters);
      paginatedFilters.set("p", "" + ctx.pageParam);
      return getLibraryList(paginatedFilters);
    },
    queryKey: [...GET_LIBRARY_LIST_QUERY_KEY, userData?.id],
    initialPageParam: 1,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
  });

  return {
    urlSettled,
    countData,
    isLoading,
    data,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    showEmptyState:
      !isLoading && (!data?.pages?.[0].data.payload.results.length || error),
  };
}
