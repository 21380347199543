import { Box, BoxProps, Circle, Flex, Icon, Image } from "@chakra-ui/react";

interface AvatarProps extends BoxProps {
  size?: "sm" | "md" | "lg";
  interactive?: boolean;
  active?: boolean;
  imageSrc?: string;
  username?: string;
  isOnline?: boolean;
  isGold?: boolean;
}

export function Avatar({
  size = "md",
  interactive,
  active,
  imageSrc = `${window.gc.global.assets_url}/static/images/avatars/no-avatar.png`,
  username = "",
  isOnline = false,
  isGold,
  ...boxProps
}: AvatarProps) {
  const avatarSizes = {
    sm: 24,
    md: 32,
    lg: 40,
  };

  const avatarRingSizes = {
    sm: 32,
    md: 40,
    lg: 48,
  };

  const avatarSize = avatarSizes[size] + "px";

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <Flex
        position="absolute"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        pointerEvents="none"
      >
        <Image
          loading="lazy"
          src={imageSrc}
          alt={`Avatar image for ${username}`}
          boxSize={avatarSize}
          borderRadius="50%"
          pointerEvents="none"
        />
      </Flex>
      <AvatarRing
        size={avatarRingSizes[size]}
        grey={!isGold}
        interactive={interactive}
        active={active}
      />
      {isOnline && (
        <Box position="absolute" top="0" right="0">
          <Circle size="12px" bg="charcoal.charcoal">
            <Circle size="10px" bg="success.500" />
          </Circle>
        </Box>
      )}
    </Flex>
  );
}

interface AvatarRingProps {
  size: number;
  grey?: boolean;
  interactive?: boolean;
  active?: boolean;
}

function AvatarRing({ size, grey, interactive, active }: AvatarRingProps) {
  const isSmall = size === 32;
  return (
    <Icon
      width={size + "px"}
      height={size + "px"}
      strokeWidth={active ? "4" : isSmall ? "1.5" : "2"}
      transform={active ? "scale(1.1)" : "scale(1)"}
      _hover={
        interactive
          ? {
              transform: "scale(1.1)",
              strokeWidth: "4",
            }
          : {}
      }
      transition="all .2s linear"
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - (!isSmall ? 2 : 4)}
        stroke={grey ? "url(#ring-grey)" : "url(#ring-blaze)"}
        strokeWidth="inherit"
        paintOrder="stroke"
      />

      <defs>
        <linearGradient
          id="ring-grey"
          x1={size - 2}
          y1="2"
          x2="2"
          y2={size - 2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1A1A2" />
          <stop offset="1" stopColor="#434445" />
        </linearGradient>
        <linearGradient
          id="ring-blaze"
          x1={size - 2}
          y1="2"
          x2="2"
          y2={size - 2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5A2C" />
          <stop offset="1" stopColor="#6E1800" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
