import { Box, Link as ChakraLink, Flex, Image, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { ArticleContentCardProps } from "../../types";
import { ChatIcon } from "../icons/ChatIcon";
import { ContentCardBackdrop } from "./components/ContentCardBackdrop";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { formatLargeNumber } from "../../utils/formatLargeNumber";

export function NewsListCard({
  content,
  layout: { display, style },
  fullWidth,
}: ArticleContentCardProps) {
  const layout_size = style.size;
  const { height, width } = useGridCardDimensions(
    "news",
    style.mode,
    layout_size,
  );

  switch (layout_size) {
    case "s":
      return (
        <SmallNewsListCard
          fullWidth={fullWidth}
          content={content}
          layout={{
            display,
            style,
          }}
          width={width}
          height={height}
        />
      );
    case "xl":
      return (
        <ExtraLargeNewsListCard
          content={content}
          layout={{
            display,
            style,
          }}
          width={width}
          height={height}
        />
      );
    default:
      console.warn("Unsupported card size", layout_size);
      return null;
  }
}

function ExtraLargeNewsListCard({
  content,
  layout: { display },
  width,
  height,
}: ArticleContentCardProps & {
  width: number;
  height: number;
}) {
  const {
    include_cover_image,
    include_posted_time,
    include_title,
    include_total_comments,
    include_author,
  } = display;

  const hasFooterData = include_total_comments || include_author;

  return (
    <Flex
      width={`${width}px`}
      maxWidth={`${width}px`}
      height={`${height - (!hasFooterData ? 40 : 0)}px`}
      position="relative"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
    >
      <ContentCardBackdrop
        position="absolute"
        width="100%"
        bottom={0}
        h="calc(100% - 92px)"
        left={0}
      />
      <Flex
        height="100%"
        direction="column"
        width="100%"
        position="relative"
        px={4}
        pb={4}
        gap={2}
      >
        {include_cover_image ? (
          <Image
            loading="lazy"
            src={content.preview_image_url}
            width="100%"
            aspectRatio={1.77}
            position="relative"
            borderRadius={5}
            alt={`Cover image for ${content.title}`}
            outline="1px solid"
            outlineColor="transparent.white.10"
            outlineOffset="-1px"
          />
        ) : null}
        <Flex
          direction="column"
          flex={1}
          gap={2}
          justifyContent="space-between"
        >
          <Flex direction="column" gap={2}>
            <Flex gap={2}>
              {include_posted_time ? (
                <Text variant="cardVitals" color="neutral.300">
                  {DateTime.fromSQL(content.published_time).toLocaleString()}
                </Text>
              ) : null}

              <Text variant="cardVitals" color="blue.200">
                <ChakraLink
                  href={content.domain}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {content.domain}
                </ChakraLink>
              </Text>
            </Flex>

            {include_title ? (
              <Text
                variant="cardLargeHeader"
                noOfLines={3}
                className="multiline-clamp"
                whiteSpace={"pre-wrap"}
              >
                {content.title}
              </Text>
            ) : null}
          </Flex>
          {(include_total_comments || include_author) && (
            <Flex direction="column" width="100%" gap={2}>
              <Box h="1px" bgColor="neutral.800" />
              <Flex direction="row" gap={3} justifyContent="space-between">
                <Flex direction="row" gap={4} alignItems="center">
                  {include_author && (
                    <Flex direction="row" gap={2} alignItems="center">
                      <Image
                        loading="lazy"
                        src={content.author.avatar_url}
                        w={6}
                        h={6}
                        borderRadius="24px"
                        border="1px solid"
                        borderColor="blaze.blaze"
                        alt={`Image for ${content.author.name}`}
                      />
                      <Text variant="cardVitals" color="neutral.300">
                        {content.author.name}
                      </Text>
                    </Flex>
                  )}
                  {include_total_comments ? (
                    <Flex direction="row" gap={1} alignItems="center">
                      <ChatIcon w={4.5} h={4.5} color="blaze.300" />
                      <Text variant="cardVitals" color="neutral.200">
                        {formatLargeNumber(content.total_comments)}
                      </Text>
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

function SmallNewsListCard({
  content,
  layout: {
    display,
    style: { mode },
  },
  width,
  height,
  fullWidth = false,
}: ArticleContentCardProps & {
  width: number;
  height: number;
  fullWidth?: boolean;
}) {
  const { include_cover_image, include_posted_time, include_title } = display;
  return (
    <Flex
      width={fullWidth ? "100%" : `${width}px`}
      maxWidth={fullWidth ? "100%" : `${width}px`}
      height={`${height}px`}
      direction="row"
      position="relative"
      alignItems="center"
      cursor="pointer"
    >
      <ContentCardBackdrop
        position="absolute"
        right={0}
        top={0}
        width="calc(100% - 20px)"
      />
      <Flex direction="row" width="100%" justifyContent="space-between">
        <Flex
          direction="row"
          position="relative"
          gap={4}
          alignItems={"center"}
          w="full"
        >
          <Box position="relative">
            {include_cover_image ? (
              <Image
                loading="lazy"
                src={content.preview_image_url}
                height="78px"
                w="137px"
                minWidth="137px"
                position="relative"
                borderRadius={5}
                alt={`Cover image for ${content.title}`}
                outline="1px solid"
                outlineColor="transparent.white.10"
                outlineOffset="-1px"
              />
            ) : null}
          </Box>
          <Flex gap={2} direction="column">
            <Flex
              direction={mode === "horizontal" ? "column" : "row"}
              gap={mode === "horizontal" ? 0 : 2}
            >
              {include_posted_time ? (
                <Text variant="cardVitals" color="neutral.300">
                  {DateTime.fromSQL(content.published_time).toLocaleString()}
                </Text>
              ) : null}
              <Text variant="cardVitals" color="blue.200">
                <ChakraLink
                  href={content.domain}
                  onClick={(e) => e.preventDefault()}
                >
                  {content.domain}
                </ChakraLink>
              </Text>
            </Flex>

            {include_title ? (
              <Text
                variant="cardSmallHeader"
                noOfLines={2}
                className="multiline-clamp"
                whiteSpace={"pre-wrap"}
              >
                {content.title}
              </Text>
            ) : null}
            <Text variant="cardVitals" color="neutral.300">
              {content.author.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
