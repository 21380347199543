import { Box, Fade, Image, Text } from "@chakra-ui/react";
import { Page } from "../../../../types";
import { t } from "i18next";

interface PageThumbnailProps {
  page: Page;
  blurIfPaid?: boolean;
  goToPage: (page: number) => void;
  activePage: number;
}
export function PageThumbnail({
  page,
  blurIfPaid = false,
  goToPage,
  activePage,
}: PageThumbnailProps) {
  return (
    <Box
      as="button"
      width="45%"
      marginBottom="24px"
      flexDirection="column"
      alignItems="center"
      cursor="pointer"
      position="relative"
      onClick={() => goToPage(page.order)}
      aria-label={`Go to page ${page.order}`}
    >
      <Fade in={true}>
        <Image
          filter={
            blurIfPaid && page.display_paid_access_block ? "blur(5px)" : "none"
          }
          loading="lazy"
          outline="5px solid"
          outlineColor={page.order === activePage + 1 ? "blaze.blaze" : "white"}
          outlineOffset="-1px"
          borderRadius="8px"
          src={page.thumbnail_url}
          alt={`Thumbnail for comic release page ${page.order}`}
        />
      </Fade>
      <Text mt="8px" fontSize="14px" fontWeight="500" lineHeight="20px">
        {`${t("screens.reader.allPagesMenu.page")} ${page.order}`}
      </Text>
      {!page.is_page_paid && (
        <Box
          position="absolute"
          top="4px"
          right="4px"
          background="transparent.black.70"
          py="4px"
          px="12px"
          border="1px solid"
          borderColor="transparent.white.40"
          borderRadius="8px"
          borderTopLeftRadius="0px"
        >
          <Text
            fontSize="10px"
            fontWeight="700px"
            lineHeight="15px"
            letterSpacing=".25px"
            textTransform="uppercase"
          >
            {t("screens.reader.allPagesMenu.free")}
          </Text>
        </Box>
      )}
    </Box>
  );
}
