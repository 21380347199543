import { Link } from "@chakra-ui/react";

export interface LegalLinkProps {
  href: string;
  children: React.ReactNode;
  id?: string;
}
export function LegalLink({ children, href, id }: LegalLinkProps) {
  return (
    <Link variant="brand" href={href} id={id} textDecoration="underline">
      {children}
    </Link>
  );
}
