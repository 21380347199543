/**
 * Truncate string
 * @param str {string}
 * @param length {number}
 * @param threshold {number?}
 * @returns {string}
 */
export default function truncateString(
  str: string,
  length: number,
  threshold?: number,
) {
  const thresholdLength = threshold || length;

  if (str.length <= thresholdLength) {
    return str;
  }

  return `${str.substring(0, length)}…`;
}
