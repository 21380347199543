import { Icon, IconProps } from "@chakra-ui/react";

export function PagesIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C3.17 4.5 2.5 5.17 2.5 6V16C2.5 16.83 3.17 17.5 4 17.5H8.39C9.23 17.5 10.01 17.92 10.47 18.61L11.58 20.27C11.61 20.32 11.65 20.36 11.7 20.39C11.74 20.42 11.79 20.44 11.84 20.46C11.84 20.46 11.86 20.46 11.87 20.46C11.96 20.48 12.05 20.48 12.15 20.46C12.15 20.46 12.15 20.46 12.16 20.46C12.21 20.44 12.26 20.42 12.3 20.39C12.34 20.36 12.38 20.32 12.42 20.27L13.53 18.61C13.99 17.91 14.77 17.5 15.61 17.5H20C20.83 17.5 21.5 16.83 21.5 16V6C21.5 5.17 20.83 4.5 20 4.5H15C13.73 4.5 12.61 5.18 12 6.2C11.39 5.18 10.27 4.5 9 4.5H4ZM11.5 8C11.5 6.62 10.38 5.5 9 5.5H4C3.72 5.5 3.5 5.72 3.5 6V16C3.5 16.28 3.72 16.5 4 16.5H8.39C9.56 16.5 10.65 17.08 11.3 18.06L11.49 18.35V8H11.5ZM12.5 18.35L12.69 18.06C13.34 17.09 14.43 16.5 15.6 16.5H19.99C20.27 16.5 20.49 16.28 20.49 16V6C20.49 5.72 20.27 5.5 19.99 5.5H14.99C13.61 5.5 12.49 6.62 12.49 8V18.35H12.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
