import { useTranslation } from "react-i18next";
import { Box, Button, Flex, useToast, Text } from "@chakra-ui/react";
import axios from "axios";
import { ServerResponse } from "../../../types";
import { GoogleSsoButton } from "./GoogleSsoButton";
import { AppleSsoButton } from "./AppleSsoButton";
import { FacebookSsoButton } from "./FacebookSsoButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FUNNEL_TYPES, FUNNEL_TYPES_TYPES } from "../../funnel/utils/contants";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { EmailIcon } from "../../icons/EmailIcon";

interface SsoSectionProps {
  hideEmail?: boolean;
  onAuthenticationSuccess: () => void;
  setFunnelType: (type: FUNNEL_TYPES_TYPES) => void;
  onClose?: () => void;
  triggeredByStartNextRelease?: boolean;
  isAccountCreationCTATestEnabled?: boolean;
}

export function SsoSection({
  hideEmail,
  onAuthenticationSuccess,
  setFunnelType,
  onClose,
  triggeredByStartNextRelease,
  isAccountCreationCTATestEnabled = false,
}: SsoSectionProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const handleAuthError = (error: any) => {
    if (axios.isAxiosError<ServerResponse<unknown>>(error)) {
      const errorData = error.response?.data;
      const fieldsWithErrors = errorData?.payload.form_fields?.filter(
        (field) => field.error !== null,
      );

      if (fieldsWithErrors) {
        toast({
          title: fieldsWithErrors.pop()?.error,
          status: "error",
        });
        return;
      }
      toast({
        title: t("components.auth.loginFailed"),
        status: "error",
      });
    }
  };

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <Flex direction="column" gap="16px">
        {!hideEmail && (
          <>
            <Flex
              gap="24px"
              flexDirection={isSmallBreakpoint ? "column" : "row"}
            >
              {isAccountCreationCTATestEnabled ? (
                <>
                  <ContinueWithEmailButton
                    triggeredByStartNextRelease={triggeredByStartNextRelease}
                    setFunnelType={setFunnelType}
                  />
                  {triggeredByStartNextRelease && (
                    <NotNowButton onClose={onClose} />
                  )}
                </>
              ) : (
                <>
                  {triggeredByStartNextRelease && (
                    <NotNowButton onClose={onClose} />
                  )}
                  <ContinueWithEmailButton
                    triggeredByStartNextRelease={triggeredByStartNextRelease}
                    setFunnelType={setFunnelType}
                  />
                </>
              )}
            </Flex>

            <Flex align="center" position="relative" gap="24px">
              <Box flex="1" h="1px" bg="whiteAlpha.100" />
              <Text fontSize="14px" color="neutral.300">
                {t("components.auth.orContinueWith")}
              </Text>
              <Box flex="1" h="1px" bg="whiteAlpha.100" />
            </Flex>
          </>
        )}
        <Flex gap="12px">
          <GoogleSsoButton
            onSuccess={onAuthenticationSuccess}
            onError={handleAuthError}
            isSmall={isSmallBreakpoint}
          />
          <AppleSsoButton
            onSuccess={onAuthenticationSuccess}
            onError={handleAuthError}
            isSmall={isSmallBreakpoint}
          />
          <FacebookSsoButton
            onSuccess={onAuthenticationSuccess}
            onError={handleAuthError}
            isSmall={isSmallBreakpoint}
          />
        </Flex>
      </Flex>
    </GoogleOAuthProvider>
  );
}

function ContinueWithEmailButton({
  triggeredByStartNextRelease,
  setFunnelType,
}: {
  triggeredByStartNextRelease?: boolean;
  setFunnelType: (type: FUNNEL_TYPES_TYPES) => void;
}) {
  const { t } = useTranslation();

  return (
    <Button
      variant="primary"
      id="btnContinueWithEmail"
      size="lg"
      leftIcon={
        triggeredByStartNextRelease ? undefined : <EmailIcon boxSize="24px" />
      }
      width="full"
      onClick={() => {
        setFunnelType(FUNNEL_TYPES.signupEmailPassword);
      }}
    >
      {triggeredByStartNextRelease
        ? t("components.auth.createAFreeAccount")
        : t("components.auth.continueWithEmail")}
    </Button>
  );
}

function NotNowButton({ onClose }: { onClose?: () => void }) {
  const { t } = useTranslation();

  return (
    <Button variant="tertiary" size="lg" width="full" onClick={onClose}>
      {t("components.auth.notNow")}
    </Button>
  );
}
