import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const paymentRadio = definePartsStyle({
  container: {
    transition: "all .1s linear",
    padding: "12px 16px",
    bg: "transparent.white.10",
    borderRadius: "16px",
    border: "1px solid transparent",
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Roboto",
    color: "dune.600",
    lineHeight: "18.2px",
    width: "100%",
  },
  control: {
    transition: "all .1s linear",
    border: "1px solid transparent",
    _checked: {
      color: "blaze.blaze",
      bg: "transparent",
      borderColor: "blaze.blaze",
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  variants: {
    paymentRadio,
  },
});
