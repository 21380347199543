import { Button, Icon } from "@chakra-ui/react";
import { PauseIcon } from "../icons/PauseIcon";
import { PlayIcon } from "../icons/PlayIcon";

type PausePlayButtonProps = {
  isPlaying: boolean;
  onClick: () => void;
};

const buttonSize = {
  base: "22px",
  "2xl": "24px",
  "3xl": "31px",
  "4xl": "46px",
};

const PausePlayButton: React.FC<PausePlayButtonProps> = ({
  isPlaying,
  onClick,
}) => {
  return (
    <Button
      variant="hoverBlazeIcon"
      minW="auto"
      w={buttonSize}
      h={buttonSize}
      p={0}
      onClick={onClick}
      aria-label={isPlaying ? "Pause autoplay" : "Play autoplay"}
    >
      <Icon
        as={isPlaying ? PauseIcon : PlayIcon}
        w="100%"
        h="100%"
        color="neutral.300"
      />
    </Button>
  );
};

export default PausePlayButton;
