import { Icon, IconProps } from "@chakra-ui/react";

export function PagesThickIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H15C13.8 4 12.73 4.54 12 5.38C11.27 4.54 10.2 4 9 4H4C2.9 4 2 4.9 2 6V16C2 17.1 2.9 18 4 18H8.39C9.06 18 9.68 18.33 10.05 18.89L11.16 20.55C11.16 20.55 11.17 20.56 11.18 20.57C11.21 20.62 11.26 20.66 11.3 20.7C11.33 20.73 11.36 20.77 11.4 20.8C11.44 20.83 11.48 20.85 11.53 20.87C11.58 20.9 11.63 20.93 11.68 20.95C11.68 20.95 11.69 20.95 11.7 20.96C11.74 20.97 11.78 20.96 11.82 20.98C11.88 20.98 11.93 21 11.99 21C12.05 21 12.1 20.99 12.16 20.98C12.2 20.98 12.24 20.98 12.28 20.96C12.28 20.96 12.29 20.96 12.3 20.95C12.35 20.93 12.4 20.9 12.45 20.87C12.49 20.85 12.54 20.83 12.58 20.8C12.62 20.77 12.65 20.73 12.68 20.7C12.72 20.66 12.76 20.62 12.8 20.57C12.8 20.57 12.81 20.56 12.82 20.55L13.93 18.89C14.3 18.33 14.93 18 15.59 18H19.98C21.08 18 21.98 17.1 21.98 16V6C21.98 4.9 21.08 4 19.98 4H20ZM8.39 16H4V6H9C10.1 6 11 6.9 11 8V16.96C10.28 16.35 9.36 16 8.39 16ZM20 16H15.61C14.64 16 13.72 16.35 13 16.96V8C13 6.9 13.9 6 15 6H20V16Z"
        fill="currentColor"
      />
    </Icon>
  );
}
