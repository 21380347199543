import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ComicContentCardProps } from "../../types";
import { useGridCardDimensions } from "./hooks/useGridCardDimensions";
import { EyeIcon } from "../icons/EyeIcon";
import { Link } from "react-router-dom";
import { EyeBlindIcon } from "../icons/EyeBlindIcon";
import { ComicCardLeavingSoonBadge } from "./components/LeavingSoonBadge";
import { useHookstate } from "@hookstate/core";
import { cardLayoutStore } from "../../appStore";
import { useIsMediumBreakpoint } from "../../utils/useBreakpoints";

export function ComicReleasesListCard({
  content,
  layout: { display, style },
}: ComicContentCardProps) {
  const layout_size = style.size;
  const { width, height } = useGridCardDimensions(
    "comics",
    style.mode,
    layout_size,
  );

  const {
    include_name,
    include_owner_name,
    include_description,
    include_cover_image,
  } = display;

  const isMediumScreen = useIsMediumBreakpoint();

  const last3Releases = content.releases ?? [];

  const leaving_soon_date = content.leaving_soon_date;

  const comicsCardLayoutStore = useHookstate(cardLayoutStore.comics).get();

  const { scaleX } = comicsCardLayoutStore.calendar_c_1 ?? {};

  var widthMultiplier = 1;

  if (style.mode === "list" && layout_size === "r_list" && isMediumScreen) {
    widthMultiplier = scaleX ?? 1;
  }

  const multipliedWidth = width * widthMultiplier;

  return (
    <Flex width={multipliedWidth} justifyContent="flex-end">
      <Flex
        borderLeftRadius={8}
        borderRightRadius={8}
        background="charcoal.900"
        width={multipliedWidth - 16}
        height={height}
        direction="row"
        position="relative"
      >
        <Flex direction="column" justifyContent="center">
          <Box position="relative" left={-4}>
            {include_cover_image ? (
              <Image
                loading="lazy"
                src={content.image_url}
                w="99px"
                minWidth="99px"
                borderRadius={5}
                alt={`Cover image for ${content.name}`}
              />
            ) : null}

            {leaving_soon_date && (
              <ComicCardLeavingSoonBadge
                leavingSoonDate={leaving_soon_date}
                borderRadius={"5px"}
              />
            )}
          </Box>
        </Flex>
        <Flex
          py="1rem"
          direction="column"
          width="calc(100% - 99px - 1rem)"
          position="relative"
          gap=".75rem"
        >
          <Flex direction="column" overflow="hidden">
            {include_name ? (
              <Text variant="cardHeader">{content.name}</Text>
            ) : null}
            {include_owner_name ? (
              <Text textColor="neutral.200" variant="cardSubtitle">
                {content.artist?.roman_name}
              </Text>
            ) : null}
            {include_description ? (
              <Text
                variant="cardVitals"
                textColor="neutral.300"
                noOfLines={3}
                my={1}
                className="multiline-clamp"
              >
                {content.description}
              </Text>
            ) : null}
          </Flex>
          {content.releases && (
            <Flex position="relative" flexDirection="column" gap=".25rem">
              {last3Releases.map((release) => {
                return (
                  <Link
                    key={release.id}
                    to={release.read_url}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Flex
                      width="100%"
                      borderRadius="6.25rem"
                      p=".5rem"
                      background="transparent.white.05"
                      alignItems="center"
                      overflow="hidden"
                      gap=".25rem"
                    >
                      {(content.user_read_status?.order ?? -1) >=
                      release.order ? (
                        <EyeIcon
                          height=".75rem"
                          width=".75rem"
                          color="success.400"
                        />
                      ) : (
                        <EyeBlindIcon
                          height=".75rem"
                          width=".75rem"
                          color="warning.300"
                        />
                      )}
                      <Text variant="cardVitals" color="neutral.200">
                        Episode {release.order} - {release.title}
                      </Text>
                      <Flex flex={1} justifyContent="flex-end">
                        <Text variant="cardVitals" color="neutral.400">
                          {release.time_ago}
                        </Text>
                      </Flex>
                    </Flex>
                  </Link>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
