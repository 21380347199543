import { Icon, IconProps } from "@chakra-ui/react";

export const GCSmallLogoIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8927 7.9734C14.1298 7.9734 15.2834 8.5278 16.0541 9.49507L17.897 8.04014C16.6752 6.50747 14.8514 5.6282 12.8934 5.6282C9.36685 5.6282 6.49731 8.49774 6.49731 12.0243C6.49731 15.5509 9.36685 18.4205 12.8934 18.4205C16.42 18.4205 19.2896 15.5509 19.2896 12.0236L19.2918 11.2756L13.3679 11.2734L13.3657 13.7411L16.5623 13.744L16.5513 13.7675C15.8825 15.1696 14.4466 16.0753 12.8934 16.0753C10.6597 16.0753 8.84251 14.2581 8.84251 12.0243C8.84251 9.7906 10.6597 7.9734 12.8934 7.9734H12.8927Z"
      fill="currentColor"
    />
    <path
      d="M12.9167 20.6548C8.14495 20.6548 4.26195 16.7725 4.26195 12C4.26195 7.22747 8.14422 3.3452 12.9167 3.3452C15.5707 3.3452 18.0406 4.5376 19.6913 6.6166L21.5341 5.16167C19.4324 2.51727 16.2915 1 12.9167 1C6.85135 1 1.91675 5.9346 1.91675 12C1.91675 18.0654 6.85135 23 12.9167 23C16.2915 23 19.4324 21.4827 21.5341 18.8405L19.6942 17.3827C18.0405 19.4624 15.5707 20.6548 12.9167 20.6548Z"
      fill="currentColor"
    />
  </Icon>
);
