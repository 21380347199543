import { Icon, IconProps } from "@chakra-ui/react";

export function FormatIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C12.2761 2.5 12.5 2.72386 12.5 3L12.5 21C12.5 21.2761 12.2761 21.5 12 21.5C11.7239 21.5 11.5 21.2761 11.5 21L11.5 3C11.5 2.72386 11.7239 2.5 12 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64645 17.6464C8.84171 17.4512 9.15829 17.4512 9.35355 17.6464L12 20.2929L14.6464 17.6464C14.8417 17.4512 15.1583 17.4512 15.3536 17.6464C15.5488 17.8417 15.5488 18.1583 15.3536 18.3536L12.3536 21.3536C12.1583 21.5488 11.8417 21.5488 11.6464 21.3536L8.64645 18.3536C8.45118 18.1583 8.45118 17.8417 8.64645 17.6464Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12C2.5 11.7239 2.72386 11.5 3 11.5H21C21.2761 11.5 21.5 11.7239 21.5 12C21.5 12.2761 21.2761 12.5 21 12.5H3C2.72386 12.5 2.5 12.2761 2.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6464 8.64645C17.8417 8.45118 18.1583 8.45118 18.3536 8.64645L21.3536 11.6464C21.5488 11.8417 21.5488 12.1583 21.3536 12.3536L18.3536 15.3536C18.1583 15.5488 17.8417 15.5488 17.6464 15.3536C17.4512 15.1583 17.4512 14.8417 17.6464 14.6464L20.2929 12L17.6464 9.35355C17.4512 9.15829 17.4512 8.84171 17.6464 8.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35355 8.64645C6.54882 8.84171 6.54882 9.15829 6.35355 9.35355L3.70711 12L6.35355 14.6464C6.54881 14.8417 6.54881 15.1583 6.35355 15.3536C6.15829 15.5488 5.84171 15.5488 5.64645 15.3536L2.64645 12.3536C2.45118 12.1583 2.45118 11.8417 2.64645 11.6464L5.64645 8.64645C5.84171 8.45118 6.15829 8.45118 6.35355 8.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6464 2.64645C11.8417 2.45118 12.1583 2.45118 12.3536 2.64645L15.3536 5.64645C15.5488 5.84171 15.5488 6.15829 15.3536 6.35355C15.1583 6.54882 14.8417 6.54882 14.6464 6.35355L12 3.70711L9.35355 6.35355C9.15829 6.54882 8.84171 6.54882 8.64645 6.35355C8.45118 6.15829 8.45118 5.84171 8.64645 5.64645L11.6464 2.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
