import { useEffect } from "react";
import { useP2PNavigation } from "./useP2PNavigation";
import { useP2PSettings } from "./useP2PSettings";
import { useComicNavigation } from "./useComicNavigation";
import { useReadingDirection } from "./useReadingDirection";
import { READING_DIRECTION_TYPES } from "../constants";
import { useHorizontalReader } from "./useHorizontalReader";
import { useHookstate } from "@hookstate/core";
import { rootComponentsStore } from "../../../components/layouts/RootLayout";
import { useReleasePagesData } from "./hookstate/useReleasePagesData";
import useAccountCreationCTA from "./useAccountCreationCTA";
import { useReleaseData } from "./hookstate/useReleaseData";
import { usePageLayout } from "./usePageLayout";

export function useNavigationActions(keyboardEvents = false) {
  const { releasePagesData } = useReleasePagesData();
  const { readingDirection } = useReadingDirection();
  const { pageLayout } = usePageLayout();
  const { releaseData } = useReleaseData();
  const {
    activeRelease,
    hasPrevRelease,
    goToPrevRelease,
    hasNextRelease,
    goToNextRelease,
  } = useComicNavigation();
  const {
    activePage,
    onFirstPage,
    showEndInterstitial,
    setShowEndInterstitial,
    goToNextPage,
    goToPrevPage,
    showPaywall,
  } = useHorizontalReader(readingDirection);
  const {
    hasNextFragment,
    hasPrevFragment,
    goToNextFragment,
    goToPrevFragment,
    activeFragmentIndex,
  } = useP2PNavigation();
  const { p2pEnabledAndAugmented } = useP2PSettings();

  const { triggerAccountCreationCTA: goNextReleaseWithSignUpCTA } =
    useAccountCreationCTA({
      onCompleteOrDismiss: goToNextRelease,
      releaseData: releaseData,
    });

  const openModal = useHookstate(rootComponentsStore.funnelModal.type).get();

  const hasNext = !showEndInterstitial || hasNextRelease || hasNextFragment;

  const hasPrev = !onFirstPage || hasPrevRelease || hasPrevFragment;

  const handleKeypress = (e: KeyboardEvent) => {
    if (openModal || document.activeElement !== document.body) return;

    const scrollAmount = 40;
    const readerContainer = document.getElementById("readerContainer");
    switch (e.key) {
      case "ArrowDown":
      case "Down":
        e.preventDefault();
        readerContainer?.scrollBy(0, scrollAmount);
        break;
      case "ArrowUp":
      case "Up":
        e.preventDefault();
        readerContainer?.scrollBy(0, -scrollAmount);
        break;

      default:
    }
  };
  const handleKeyup = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowRight":
      case "Right":
        if (openModal) return;
        e.preventDefault();
        if (readingDirection === READING_DIRECTION_TYPES.leftToRight) {
          if (hasNext) goNext();
        } else {
          if (hasPrev) goPrevious();
        }
        break;

      case "ArrowLeft":
      case "Left":
        e.preventDefault();
        if (readingDirection === READING_DIRECTION_TYPES.rightToLeft) {
          if (hasNext) goNext();
        } else {
          if (hasPrev) goPrevious();
        }
        break;

      default:
    }
  };

  useEffect(() => {
    if (keyboardEvents) {
      document.addEventListener("keyup", handleKeyup);
      document.addEventListener("keydown", handleKeypress);
      return () => {
        document.removeEventListener("keyup", handleKeyup);
        document.removeEventListener("keydown", handleKeypress);
      };
    }
  }, [
    keyboardEvents,
    showEndInterstitial,
    activePage,
    activeRelease,
    readingDirection,
    hasNext,
    hasPrev,
    activeFragmentIndex,
    showPaywall,
    openModal,
    releasePagesData,
    pageLayout,
  ]);

  const goNext = () => {
    if (showPaywall) return;
    if (hasNextFragment && p2pEnabledAndAugmented) {
      goToNextFragment();
    } else {
      showEndInterstitial && hasNextRelease
        ? goNextReleaseWithSignUpCTA(true)
        : goToNextPage(true);
    }
  };

  const goPrevious = () => {
    if (showEndInterstitial) {
      setShowEndInterstitial(false);
      return;
    }
    if (hasPrevFragment && p2pEnabledAndAugmented) {
      goToPrevFragment();
    } else {
      onFirstPage && hasPrevRelease
        ? goToPrevRelease(false)
        : goToPrevPage(p2pEnabledAndAugmented ? true : false);
    }
  };

  return {
    goNext,
    goPrevious,
    hasNext,
    hasPrev,
  };
}
