import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { Artist } from "../../../types";
import { useToggleArtistFollow } from "../../cards/hooks/useToggleArtistFollow";
import { FollowIcon } from "../../icons/FollowIcon";
import { CalendarEmptyIcon } from "../../icons/CalendarEmptyIcon";
import { useQuery } from "@tanstack/react-query";
import { timeAgoSqlDate } from "../../../utils/time";
import { useSlideInMenu } from "../hooks/useSlideInMenu";
import { SLIDE_IN_MENUS } from "../../../screens/root/constants";
import { formatLargeNumber } from "../../../utils/formatLargeNumber";

export interface ArtistSectionProps {
  artist: ImmutableObject<Artist>;
}

export function ArtistSection({ artist }: ArtistSectionProps) {
  const { onToggleSlideInMenu } = useSlideInMenu();

  const cacheKey = `${artist?.entity_type}-${artist?.id}`;
  const { data: artistData } = useQuery<Artist>({
    queryKey: [cacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const handleToggleArtistFollow = useToggleArtistFollow(cacheKey);

  if (!artistData) {
    return null;
  }

  return (
    <Flex flexDirection="row" gap="16px" width="100%" alignItems="center">
      <Image
        src={artistData?.avatar_small_url}
        boxSize="48px"
        borderRadius="14px"
        alt={`Avatar image for ${artistData?.roman_name}`}
      />
      <Flex flexDirection="column" gap="4px" width="100%">
        <Box
          aria-label={`See ${artistData.roman_name}`}
          onClick={() =>
            onToggleSlideInMenu(SLIDE_IN_MENUS.publisher, {
              artistId: artistData.id,
            })
          }
          style={{ width: "100%", cursor: "pointer" }}
        >
          <Text
            fontFamily="Roboto"
            fontWeight="700"
            fontSize="16px"
            color="neutral.200"
            lineHeight="130%"
          >
            {artistData?.roman_name}
          </Text>
        </Box>
        <Flex flexDirection="row" gap="12px" alignItems="center">
          <Flex flexDirection="row" gap="4px" alignItems="center">
            <FollowIcon color="ice.200" boxSize="12px" />
            <Text
              fontFamily="Roboto"
              fontWeight="700"
              fontSize="10px"
              letterSpacing="0.25px"
              color="neutral.200"
              lineHeight="150%"
            >
              {formatLargeNumber(artistData?.total_favorites)}
            </Text>
          </Flex>
          <Flex flexDirection="row" gap="4px" alignItems="center">
            <CalendarEmptyIcon color="fresh.300" boxSize="12px" />
            <Text
              fontFamily="Roboto"
              fontWeight="700"
              fontSize="10px"
              letterSpacing="0.25px"
              color="neutral.200"
              lineHeight="150%"
            >
              {timeAgoSqlDate(artistData?.last_activity)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleToggleArtistFollow();
        }}
        variant={artistData?.user_is_following ? "tertiary" : "secondary"}
        h="26px"
        width={artistData?.user_is_following ? "128px" : "100px"}
      >
        {artistData?.user_is_following ? "Following" : "Follow"}
      </Button>
    </Flex>
  );
}
