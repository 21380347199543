import { Button, Flex } from "@chakra-ui/react";
import { ChatIcon } from "../../../../components/icons/ChatIcon";
import { BookmarkIcon } from "../../../../components/icons/BookmarkIcon";
import { useSlideInMenu } from "../../../../components/slideInMenu/hooks/useSlideInMenu";
import { BookmarkCheckedIcon } from "../../../../components/icons/reader/BookmarkCheckedIcon";
import { useComicData } from "../../hooks/hookstate/useComicData";
import { ShareIcon } from "../../../../components/icons/ShareIcon";
import { useShareRelease } from "../../hooks/useShareRelease";
import { useIsOverlayOpen } from "../../hooks/hookstate/useIsOverlayOpen";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function PanelActions() {
  const { comicData } = useComicData();
  const { onToggleSlideInMenu } = useSlideInMenu();
  const { shareRelease } = useShareRelease();
  const { toggleIsOverlayOpen } = useIsOverlayOpen();

  const openMenu = (menu: string) => {
    toggleIsOverlayOpen();
    setTimeout(() => onToggleSlideInMenu(menu), 0);
  };

  return (
    <Flex width="100%" justifyContent="center">
      <Flex
        gap="16px"
        background="transparent.black.30"
        p="14px"
        borderRadius="9999px"
      >
        <Button
          variant="iconOnly"
          aria-label="Open commments menu"
          p={0}
          bg="reader.slate.500"
          height="56px"
          width="56px"
          borderRadius="9999px"
          onClick={(e) => {
            e.stopPropagation();
            openMenu(SLIDE_IN_MENUS.comments);
          }}
        >
          <ChatIcon height="28px" width="28px" />
        </Button>
        <Button
          variant="iconOnly"
          aria-label="Open manage library menu"
          p={0}
          bg="reader.slate.500"
          height="56px"
          width="56px"
          borderRadius="9999px"
          onClick={(e) => {
            e.stopPropagation();
            openMenu(SLIDE_IN_MENUS.addTo);
          }}
        >
          {!!comicData?.library_status ? (
            <BookmarkCheckedIcon
              height="28px"
              width="28px"
              color="blaze.blaze"
            />
          ) : (
            <BookmarkIcon height="28px" width="28px" />
          )}
        </Button>
        <Button
          variant="iconOnly"
          aria-label="Share release"
          p={0}
          bg="reader.slate.500"
          height="56px"
          width="56px"
          borderRadius="9999px"
          onClick={shareRelease}
        >
          <ShareIcon height="28px" width="28px" />
        </Button>
      </Flex>
    </Flex>
  );
}
