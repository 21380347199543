import { ButtonProps, Button } from "@chakra-ui/react";
import { SendIcon } from "../icons/SendIcon";

export function SendButton({ onClick }: ButtonProps) {
  return (
    <Button
      variant="iconOnly"
      aria-label="Post comment"
      height="48px"
      width="80px"
      borderRadius="32px"
      border="1px solid"
      borderColor="transparent.white.40"
      onClick={onClick}
    >
      <SendIcon color="white" width="18px" height="18px" />
    </Button>
  );
}
