import { Flex, FlexProps } from "@chakra-ui/react";
import { ChatLog } from "./components/ChatLog";
import { ChatSubmitForm } from "./components/ChatSubmitForm";
import { useChat } from "./hooks/useChat";
import { useHookstate } from "@hookstate/core";
import {
  ChatClientProvider,
  ChatRoomProvider,
  RoomOptionsDefaults,
} from "@ably/chat";
import { memo } from "react";
import { ChatHeader } from "./components/ChatHeader";
import { ChatEventCountdown } from "./components/ChatEventCountdown";
import { sqlDateToTimestamp } from "../../utils/time";
import { ablyStore } from "../../screens/globalChat/ChatScreen";

type ChatProps = {
  title?: string;
  icon?: string | JSX.Element;
  button?: JSX.Element;
  eventStartTime?: string;
} & FlexProps;

export function ChatContent({
  title,
  icon,
  button,
  eventStartTime = "",
  ...containerProps
}: ChatProps) {
  const {
    isLoaded,
    messageArray,
    members,
    roomStatus,
    handleDeleteMessage,
    handleToggleReaction,
    handleSendMessage,
  } = useChat();

  const eventStartTimestamp = sqlDateToTimestamp(eventStartTime);
  return (
    <Flex flexDirection="column" {...containerProps} flexGrow={1} minWidth={0}>
      <ChatHeader title={title} members={members} icon={icon} button={button} />
      {eventStartTime && eventStartTimestamp > Date.now() ? (
        <ChatEventCountdown
          title={title}
          icon={icon}
          startTime={eventStartTimestamp ?? Infinity}
        />
      ) : null}
      <>
        {eventStartTime && eventStartTimestamp > Date.now() ? null : (
          <ChatLog
            messages={messageArray}
            members={members}
            isLoaded={isLoaded}
            isAttached={roomStatus === "attached"}
            deleteMessage={handleDeleteMessage}
            toggleReaction={handleToggleReaction}
          />
        )}
        <ChatSubmitForm
          send={handleSendMessage}
          disabled={!!(eventStartTime && eventStartTimestamp > Date.now())}
        />
      </>
    </Flex>
  );
}

export const Chat = memo(
  ({ channelId, ...restProps }: ChatProps & { channelId: string }) => {
    const chatClient = useHookstate(ablyStore.chatClient).get({
      noproxy: true,
    });

    if (!chatClient) {
      return null;
    }

    return (
      <ChatClientProvider client={chatClient}>
        <ChatRoomProvider id={channelId} attach options={RoomOptionsDefaults}>
          <ChatContent {...restProps} />
        </ChatRoomProvider>
      </ChatClientProvider>
    );
  },
);
