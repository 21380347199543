import { Icon, IconProps } from "@chakra-ui/react";

export function GCBigLogoIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 136 22">
      <path
        d="M132.368 13.0399L135.959 7.77551H133.388L131.002 11.4283L128.572 7.77551H125.899L129.471 12.9794L125.858 18.1818H128.45L130.858 14.591L133.307 18.1818H136L132.368 13.0399Z"
        fill="currentColor"
      />
      <path
        d="M124.46 7.77551H122.135V18.1818H124.46V7.77551Z"
        fill="currentColor"
      />
      <path
        d="M123.297 3.43079C122.483 3.43079 121.822 4.09197 121.822 4.90664C121.822 5.72131 122.483 6.3825 123.297 6.3825C124.112 6.3825 124.773 5.72131 124.773 4.90664C124.773 4.09197 124.112 3.43079 123.297 3.43079Z"
        fill="currentColor"
      />
      <path
        d="M116.523 7.53053C115.074 7.53053 113.809 8.24484 113.136 9.20415C112.463 8.04117 111.259 7.53053 110.096 7.53053C108.871 7.53053 107.749 8.10168 107.157 8.999V7.77404H104.913V18.1803H107.239V11.936C107.239 10.4262 108.279 9.63067 109.402 9.63067C110.525 9.63067 111.402 10.3037 111.402 11.8961V18.1803H113.728V11.936C113.728 10.4262 114.748 9.63067 115.871 9.63067C116.994 9.63067 117.871 10.3037 117.871 11.8961V18.1803H120.197V11.4873C120.197 8.67136 118.34 7.52905 116.523 7.52905V7.53053Z"
        fill="currentColor"
      />
      <path
        d="M98.1593 7.53076C95.0984 7.53076 93.0381 9.75487 93.0381 12.9796C93.0381 16.2044 95.0984 18.4285 98.1593 18.4285C101.22 18.4285 103.281 16.2044 103.281 12.9796C103.281 9.75487 101.219 7.53076 98.1593 7.53076ZM98.1593 16.3667C96.4458 16.3667 95.4039 14.9794 95.4039 12.9796C95.4039 10.9798 96.4444 9.59253 98.1593 9.59253C99.8742 9.59253 100.915 10.9798 100.915 12.9796C100.915 14.9794 99.8742 16.3667 98.1593 16.3667Z"
        fill="currentColor"
      />
      <path
        d="M85.8788 16.2028C83.3684 16.2028 81.7774 14.0392 81.7774 10.9989C81.7774 7.95864 83.4097 5.79504 85.8788 5.79504C87.9804 5.79504 89.0829 7.03918 89.4902 8.50913H91.918C91.5092 5.8157 89.4695 3.57092 85.8773 3.57092C81.9796 3.57092 79.2861 6.63184 79.2861 10.9989C79.2861 15.3659 82.0607 18.4269 85.8773 18.4269C89.6939 18.4269 91.7128 15.6921 91.9578 13.468H89.5094C89.2851 14.6723 88.1221 16.2028 85.8773 16.2028H85.8788Z"
        fill="currentColor"
      />
      <path
        d="M77.6934 3.34814H75.3674V18.1834H77.6934V3.34814Z"
        fill="currentColor"
      />
      <path
        d="M69.1839 7.53066C66.6336 7.53066 65.143 8.95929 64.8375 10.6521H67.041C67.2653 9.99977 67.8984 9.4478 69.1027 9.4478C70.307 9.4478 71.1438 10.0603 71.1438 11.2838V11.8756H68.6142C66.0226 11.8756 64.4316 13.1005 64.4316 15.1608C64.4316 17.2211 66.0226 18.4254 68.0431 18.4254C69.389 18.4254 70.5328 17.9148 71.2678 16.9968V18.1804H73.4314V11.3236C73.4314 8.89583 71.7371 7.52771 69.1868 7.52771L69.1839 7.53066ZM71.1424 14.265C71.1424 15.673 69.9381 16.5304 68.5508 16.5304C67.5103 16.5304 66.7753 16.0198 66.7753 15.0811C66.7753 14.1425 67.5914 13.6318 68.7751 13.6318H71.1424V14.265Z"
        fill="currentColor"
      />
      <path
        d="M58.6106 7.53072C57.0388 7.53072 55.9984 8.22437 55.4685 8.99919V3.34814H53.1426V18.1834H55.3874V16.898C55.8567 17.7141 56.9385 18.4284 58.5501 18.4284C61.0192 18.4284 63.2227 16.5511 63.2227 12.9796C63.2227 9.408 61.0399 7.53072 58.6106 7.53072ZM58.162 16.3667C56.509 16.3667 55.4065 15.0605 55.4065 13.0209V12.9397C55.4065 10.8986 56.509 9.59396 58.162 9.59396C59.8149 9.59396 60.8761 10.9606 60.8761 12.981C60.8761 15.0015 59.7943 16.3681 58.162 16.3681V16.3667Z"
        fill="currentColor"
      />
      <path
        d="M46.587 7.53076C43.5261 7.53076 41.4658 9.75487 41.4658 12.9796C41.4658 16.2044 43.5261 18.4285 46.587 18.4285C49.648 18.4285 51.7082 16.2044 51.7082 12.9796C51.7082 9.75487 49.648 7.53076 46.587 7.53076ZM46.587 16.3667C44.8736 16.3667 43.8316 14.9794 43.8316 12.9796C43.8316 10.9798 44.8721 9.59253 46.587 9.59253C48.302 9.59253 49.3425 10.9798 49.3425 12.9796C49.3425 14.9794 48.302 16.3667 46.587 16.3667Z"
        fill="currentColor"
      />
      <path
        d="M40.0152 3.34814H37.6892V18.1834H40.0152V3.34814Z"
        fill="currentColor"
      />
      <path
        d="M29.4776 12.612H33.396V12.6932C33.396 14.7343 32.0486 16.2441 29.7226 16.2441C27.2742 16.2441 25.478 14.3063 25.478 11.0004C25.478 7.69444 27.1723 5.7773 29.7019 5.7773C31.6604 5.7773 32.7629 6.83844 33.2115 8.28773H35.7013C35.1906 5.71679 33.089 3.57385 29.6813 3.57385C25.6404 3.57385 22.9883 6.6761 22.9883 11.0018C22.9883 15.3276 25.8042 18.4298 29.6606 18.4298C33.517 18.4298 35.8223 15.7364 35.8223 12.125V10.5738H29.4761V12.6149L29.4776 12.612Z"
        fill="currentColor"
      />
      <path
        d="M10.8918 7.00357C12.1198 7.00357 13.265 7.55406 14.0295 8.51337L15.8581 7.06998C14.6449 5.54838 12.8355 4.67615 10.8918 4.67615C7.39111 4.67615 4.54419 7.52307 4.54419 11.0238C4.54419 14.5245 7.39111 17.3714 10.8918 17.3714C14.3926 17.3714 17.2395 14.523 17.2395 11.0238L17.2424 10.2814L11.3626 10.2785L11.3597 12.7269L14.5328 12.7299L14.521 12.7535C13.8568 14.1452 12.4326 15.044 10.8918 15.044C8.67511 15.044 6.87161 13.2405 6.87161 11.0238C6.87161 8.80706 8.67511 7.00357 10.8918 7.00357Z"
        fill="currentColor"
      />
      <path
        d="M10.9169 19.588C6.18088 19.588 2.32742 15.7346 2.32742 10.9985C2.32742 6.26253 6.18088 2.41055 10.9169 2.41055C13.5498 2.41055 16.0027 3.59419 17.6394 5.65743L19.468 4.21405C17.3826 1.5885 14.2656 0.0831299 10.9169 0.0831299C4.89688 0.0831299 0 4.98001 0 11C0 17.02 4.89688 21.9169 10.9169 21.9169C14.2656 21.9169 17.3826 20.4115 19.4695 17.789L17.6438 16.3426C16.0027 18.4059 13.5513 19.5895 10.9184 19.5895L10.9169 19.588Z"
        fill="currentColor"
      />
    </Icon>
  );
}
