import { Flex, Text } from "@chakra-ui/react";
import { DownloadOurApp } from "../../components/downloadOurApp/DownloadOurApp";
import { t } from "i18next";
import { SquiggleLargeIcon } from "../../components/icons/SquiggleLargeIcon";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

export function RedemptionSuccessScreen() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  return (
    <Flex
      maxWidth="960px"
      margin="0 auto"
      flexDirection="column"
      width="100%"
      textAlign="center"
      alignItems="center"
      pt={isSmallBreakpoint ? "32px" : "72px"}
      pr={isSmallBreakpoint ? "0px" : "32px"}
      overflowY="auto"
    >
      <Text
        color="dune.dune"
        fontFamily="Bricolage Grotesque"
        fontSize="64px"
        fontWeight="600"
        lineHeight="130%"
      >
        {t("screens.redemptionSuccess.congrats")}
      </Text>
      <Text
        color="dune.dune"
        fontFamily="Bricolage Grotesque"
        fontSize="48px"
        fontWeight="600"
        lineHeight="130%"
        mb="23px"
      >
        {t("screens.redemptionSuccess.redeemed")}
      </Text>
      <SquiggleLargeIcon width="242px" height="10px" color="blaze.blaze" />
      <Text
        mt="32px"
        color="dune.100"
        fontSize="24px"
        fontWeight="500px"
        lineHeight="170%"
        mb="64px"
      >
        {t("screens.redemptionSuccess.enjoyComics")}
      </Text>
      <DownloadOurApp />
    </Flex>
  );
}
