import { Text, Button, Flex } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { ReleaseCommentEntity } from "../../../../types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { t } from "i18next";
import truncateString from "../../../../utils/truncateString";

interface CommentBodyProps {
  comment: ImmutableObject<ReleaseCommentEntity>;
  replyTo: Dispatch<
    SetStateAction<ImmutableObject<ReleaseCommentEntity> | undefined>
  >;
}
export function CommentBody({ comment, replyTo }: CommentBodyProps) {
  const parsedBody = truncateString(comment.parsed_body, 225).trim();
  const canSeeMore = parsedBody.length < comment.parsed_body.trim().length;

  const [seeMore, setSeeMore] = useState(false);
  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };
  useEffect(() => {
    const seeMoreButton = document.getElementById(`see-more-${comment.id}`);
    if (seeMoreButton) {
      seeMoreButton.addEventListener("click", handleSeeMore);

      return () => {
        seeMoreButton.removeEventListener("click", handleSeeMore);
      };
    }
  }, [seeMore]);

  const seeMoreButton = `
    <span 
      style="
        color: var(--chakra-colors-neutral-200);
        cursor: pointer;
      " 
      id="see-more-${comment.id}"
    >
      ${
        seeMore
          ? t("screens.reader.commentsMenu.seeLess")
          : t("screens.reader.commentsMenu.seeMore")
      }
    </span>
  `;
  return (
    <Flex pl="44px" flexDirection="column">
      <Text
        color="neutral.400"
        fontSize="14px"
        fontWeight="500"
        lineHeight="21px"
        dangerouslySetInnerHTML={{
          __html:
            (seeMore ? comment.parsed_body : parsedBody) +
            (canSeeMore ? seeMoreButton : ""),
        }}
      />
      <Button
        variant="iconOnly"
        aria-label="Reply to comment"
        justifyContent="flex-start"
        color="blaze.300"
        fontSize="14px"
        fontWeight="400"
        lineHeight="18.2px"
        onClick={() => replyTo(comment)}
      >
        {t("screens.reader.commentsMenu.reply")}
      </Button>
    </Flex>
  );
}
