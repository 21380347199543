import { useIsOverlayOpen } from "../../hooks/hookstate/useIsOverlayOpen";
import { Flex } from "@chakra-ui/react";
import { NavigationClickBoxes } from "../shared/NavigationClickBoxes";
import { useEndInterstitial } from "../../hooks/useEndInterstital";
import { useReadingDirection } from "../../hooks/useReadingDirection";
import { useNavigationActions } from "../../hooks/useNavigationActions";

export function ActionOverlay({ children }: { children?: React.ReactNode }) {
  const { toggleIsOverlayOpen } = useIsOverlayOpen();
  const { showEndInterstitial } = useEndInterstitial();
  const { readingDirection } = useReadingDirection();

  const { goNext, goPrevious, hasNext, hasPrev } = useNavigationActions(true);

  return (
    <Flex
      onClick={toggleIsOverlayOpen}
      id="ActionOverlay"
      position="relative"
      margin="auto"
      w="100%"
      h="fit-content"
      minHeight="100%"
      bottom={0}
      left={0}
      zIndex={2}
      style={{ touchAction: "pan-x pan-y pinch-zoom" }}
      pointerEvents={showEndInterstitial ? "none" : "all"}
    >
      {children}
      {readingDirection !== "v" && (
        <NavigationClickBoxes
          blockedNext={false}
          goNext={goNext}
          hasNext={hasNext}
          goPrevious={goPrevious}
          hasPrev={hasPrev}
        />
      )}
    </Flex>
  );
}
