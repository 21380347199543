import { useToast } from "@chakra-ui/react";
import { SsoButton } from "../../buttons/SsoButton";
import { GoogleIcon } from "../../icons/GoogleIcon";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { LOGIN_WITH_GOOGLE_KEY, loginWithGoogle } from "../api/authApi";
import { useGoogleLogin } from "@react-oauth/google";

interface GoogleSsoButtonProps {
  onSuccess: () => void;
  onError: (error: any) => void;
  isSmall?: boolean;
}

export function GoogleSsoButton({
  onSuccess,
  onError,
  isSmall = false,
}: GoogleSsoButtonProps) {
  const toast = useToast();
  const { t } = useTranslation();

  const googleMutation = useMutation({
    mutationKey: LOGIN_WITH_GOOGLE_KEY,
    mutationFn: ({ token }: { token: string }) => loginWithGoogle(token),
    onSuccess: (_) => {
      onSuccess();
    },
    onError: (error) => onError(error),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleMutation.mutate({
        token: tokenResponse.code,
      });
    },
    flow: "auth-code",
  });

  return (
    <SsoButton
      isSmall={isSmall}
      icon={<GoogleIcon boxSize="24px" />}
      text={t("components.auth.continueWithGoogle")}
      onClick={() => {
        try {
          googleLogin();
        } catch (e) {
          toast({
            title: t("components.auth.errorAuthenticatingWithGoogle"),
            status: "error",
          });
        }
      }}
      isLoading={googleMutation.isPending}
    />
  );
}
