import { Icon, IconProps } from "@chakra-ui/react";

export function ArrowTraditionalIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path
        d="M19.46 12.19C19.51 12.07 19.51 11.93 19.46 11.81C19.43 11.75 19.4 11.69 19.35 11.65L13.35 5.65C13.15 5.45 12.84 5.45 12.64 5.65C12.44 5.85 12.44 6.16 12.64 6.36L17.79 11.51H5C4.72 11.51 4.5 11.73 4.5 12.01C4.5 12.29 4.72 12.51 5 12.51H17.79L12.64 17.66C12.44 17.86 12.44 18.17 12.64 18.37C12.74 18.47 12.87 18.52 12.99 18.52C13.11 18.52 13.25 18.47 13.34 18.37L19.34 12.37C19.34 12.37 19.42 12.27 19.45 12.21L19.46 12.19Z"
        fill="currentColor"
      />
    </Icon>
  );
}
