import { Icon, IconProps } from "@chakra-ui/react";

export function HeartIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7495 6.26259C14.4613 4.12282 17.653 3.94605 19.5907 5.88369C21.3261 7.61906 21.3925 10.4115 19.7416 12.2274L12.3699 20.3363C12.2751 20.4406 12.1408 20.5 11.9999 20.5C11.859 20.5 11.7247 20.4406 11.6299 20.3363L4.25817 12.2274C2.60731 10.4115 2.67375 7.61906 4.40912 5.88369C6.34676 3.94605 9.53847 4.12282 11.2503 6.26259L11.9999 7.19962L12.7495 6.26259ZM18.8836 6.5908C17.3674 5.0746 14.8699 5.21292 13.5304 6.88728L12.3903 8.31236C12.2955 8.43097 12.1518 8.50001 11.9999 8.50001C11.848 8.50001 11.7044 8.43097 11.6095 8.31236L10.4694 6.88728C9.12992 5.21292 6.63242 5.0746 5.11623 6.5908C3.75831 7.94872 3.70631 10.1338 4.99811 11.5547L11.9999 19.2567L19.0017 11.5547C20.2935 10.1338 20.2415 7.94871 18.8836 6.5908Z"
        fill="currentColor"
      />
    </Icon>
  );
}
