import { ReactElement } from "react";
import { ArticleContentCardProps } from "../../types";
import { getCTAUrl } from "../../utils/getCTA";
import { NewsGridCard } from "./NewsGridCard";
import { NewsHorizontalCard } from "./NewsHorizontalCard";
import { NewsListCard } from "./NewsListCard";
import { Link } from "@chakra-ui/react";

export function NewsCard(props: ArticleContentCardProps) {
  const mode = props.layout.style.mode;
  let cards: { [key: string]: ReactElement } = {
    grid: <NewsGridCard {...props} />,
    horizontal: <NewsHorizontalCard {...props} />,
    list: <NewsListCard {...props} />,
  };

  if (cards[mode]) {
    return (
      <Link draggable={false} href={getCTAUrl(props.content)}>
        {cards[mode]}
      </Link>
    );
  }

  console.warn("Unable to determine layout mode for NewsCard");
  return null;
}
