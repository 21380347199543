import { Icon, IconProps } from "@chakra-ui/react";

export function ArrowLeftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3536 8.64645C11.5488 8.84171 11.5488 9.15829 11.3536 9.35355L9.20711 11.5L16 11.5C16.2761 11.5 16.5 11.7239 16.5 12C16.5 12.2761 16.2761 12.5 16 12.5L9.20711 12.5L11.3536 14.6464C11.5488 14.8417 11.5488 15.1583 11.3536 15.3536C11.1583 15.5488 10.8417 15.5488 10.6464 15.3536L7.64645 12.3536C7.45118 12.1583 7.45118 11.8417 7.64645 11.6464L10.6464 8.64645C10.8417 8.45118 11.1583 8.45118 11.3536 8.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
