import { useMemo } from "react";
import { GCChannel } from "../types";
import { useAccountData } from "./useAccountData";
import { useParams } from "react-router";

export function useCurrentCMSStream(area?: string) {
  const accountData = useAccountData();

  const { channel, slug } = useParams();

  const homeTabChannels = accountData?.home_tab_channels ?? [];

  const isOriginalsChannel = channel === "originals";
  const originalChannel = accountData?.originals_channel;

  // Maps slugs to their channels
  const slugsMap = useMemo(() => {
    if (accountData) {
      const map: Record<string, GCChannel> = {};
      homeTabChannels.forEach((channel) => {
        channel.streams.forEach((s) => {
          map[s.slug] = channel;
        });
      });
      return map;
    }

    return undefined;
  }, [accountData]);

  const homeChannel = useMemo(() => {
    return homeTabChannels.find((c) => c.is_primary);
  }, [accountData]);

  if (area !== "home" && area !== "gc-originals") return;

  /// Whenever the active channel changes, we want to set the active stream.
  /// In can be set the slug defined in the URL or the default first stream for the channel
  const findActiveStream = (channel: GCChannel) => {
    return channel.streams.find(function (stream) {
      return (
        (slug && stream.slug?.endsWith(slug)) ||
        (!slug && stream.id.endsWith("index")) ||
        (isOriginalsChannel && stream.id.endsWith("index"))
      );
    });
  };

  /// User navigates to a channel, we need to set the active channel to the one they navigated to

  if (!slugsMap) return;

  if (!channel && !slug) {
    if (homeChannel) return findActiveStream(homeChannel);
    return;
  }

  if (isOriginalsChannel && originalChannel) {
    return findActiveStream(originalChannel);
  } else {
    const newActiveChannel = channel
      ? (slugsMap[`${channel}/${slug}`] ?? slugsMap[channel])
      : undefined;

    if (newActiveChannel) {
      return findActiveStream(newActiveChannel);
    } else {
      if (homeChannel) return findActiveStream(homeChannel);
    }
  }
}
