import { Box, BoxProps } from "@chakra-ui/react";
import { upperFirst } from "lodash-es";

export type BorderCorner =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

interface SharpCornerBoxProps extends BoxProps {
  children: React.ReactNode;
  corners?: BorderCorner[];
}

export function SharpCornerBox({
  children,
  corners = ["topLeft"],
  ...boxProps
}: SharpCornerBoxProps) {
  const borderStyle = corners.reduce((acc, corner) => {
    const borderStyleProperty = `border${upperFirst(corner)}Radius`;
    const borderCorner = { [borderStyleProperty]: 0 };
    return {
      ...acc,
      ...borderCorner,
    };
  }, {});

  return (
    <Box
      borderWidth={1}
      borderStyle="solid"
      borderColor="transparent"
      borderRadius={10}
      paddingX={2}
      paddingY={1}
      w="fit-content"
      backgroundColor="transparent.black.80"
      style={{
        backdropFilter: "blur(2.5px)",
        ...borderStyle,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}
