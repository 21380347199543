import { Box, Flex, Text } from "@chakra-ui/react";
import { Comic } from "../../../types";
import { appStore } from "../../../appStore";
import { useHookstate } from "@hookstate/core";
import { ButtonCarousel } from "../../carousel/ButtonCarousel";

export interface ComicLabelsProps {
  comicData: Comic;
}

const THEME_FACET_GROUP_ID = 2;

export function ComicLabels({ comicData }: ComicLabelsProps) {
  const labels = [comicData.type_display, comicData.category_name];
  var appInit = useHookstate(appStore.init).get({
    noproxy: true,
  });

  const themeOptions = comicData.comic_facets
    ?.filter((facets) => facets.comic_facet_group_id === THEME_FACET_GROUP_ID)
    .map((facet) => facet.comic_facet_group_option_id);

  const themeLabels = appInit.comic_facet_groups
    .find((group) => group.id === THEME_FACET_GROUP_ID)
    ?.comic_facet_group_options.filter((option) =>
      themeOptions?.includes(option.id),
    )
    .map((option) => option.display_name);

  labels.push(...(themeLabels ?? []));

  return (
    <ButtonCarousel
      containerProps={{ whiteSpace: "nowrap" }}
      width="100%"
      onlyFade
    >
      {labels.map((label) => (
        <Box
          key={label}
          bg="transparent.white.10"
          borderRadius="full"
          py="8px"
          px="12px"
          width="fit-content"
          mr="6px"
          userSelect="none"
        >
          <Text
            fontFamily="Roboto"
            textAlign="center"
            fontSize="12px"
            lineHeight="14.06px"
            fontWeight="500"
            color="dune.700"
          >
            {label}
          </Text>
        </Box>
      ))}
    </ButtonCarousel>
  );

  return (
    <Flex
      gap="6px"
      width="100%"
      flexDirection="row"
      overflowX="auto"
      whiteSpace="nowrap"
    >
      {labels.map((label) => (
        <Box
          key={label}
          bg="transparent.white.10"
          borderRadius="full"
          py="8px"
          px="12px"
          width="fit-content"
        >
          <Text
            fontFamily="Roboto"
            textAlign="center"
            fontSize="12px"
            lineHeight="14.06px"
            fontWeight="500"
            color="dune.700"
          >
            {label}
          </Text>
        </Box>
      ))}
    </Flex>
  );
}
