import { ReleaseContentCardProps } from "../../types";
import { ReleaseGridCard } from "./ReleaseGridCard";
import { ReleaseListCard } from "./ReleaseListCard";

export function ReleaseHorizontalCard(props: ReleaseContentCardProps) {
  switch (props.layout.style.size) {
    case "s":
    case "m":
    case "l":
      return <ReleaseGridCard {...props} />;
    case "c_1":
    case "r_list":
      return <ReleaseListCard {...props} />;
    default:
      console.warn(
        "Unexpected horizontal release card size",
        props.layout.style.size,
      );
      break;
  }

  return <ReleaseGridCard {...props} />;
}
