import { Flex } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../../appStore";
import { FilterTagButton } from "../../../components/buttons/FilterTag";
import { ButtonCarousel } from "../../../components/carousel/ButtonCarousel";
import { libraryFilterStore } from "./LibraryFilters";

export const DEFAULT_READING_STATUS_TYPE = "0";
interface ReadingStatusCarouselProps {
  readingStatusCounts: { [key: string]: number };
  invalidate: () => void;
}
export function ReadingStatusCarousel({
  readingStatusCounts,
  invalidate,
}: ReadingStatusCarouselProps) {
  const comicReadingStatus = useHookstate(
    libraryFilterStore.comicReadingStatus,
  ).get();
  const readingStatuses = useHookstate(
    appStore.init.comic_user_reading_statuses,
  ).get({
    noproxy: true,
  });

  return (
    <ButtonCarousel
      width="100%"
      wrapperProps={{
        paddingLeft: 2,
        paddingRight: 6,
        position: "relative",
        overflowX: "auto",
      }}
    >
      <Flex paddingLeft="8px">
        <FilterTagButton
          text="All"
          isFiltering
          isActive={comicReadingStatus === DEFAULT_READING_STATUS_TYPE}
          activeFilters={readingStatusCounts[DEFAULT_READING_STATUS_TYPE] ?? 0}
          onClick={async () => {
            if (comicReadingStatus !== DEFAULT_READING_STATUS_TYPE) {
              await libraryFilterStore.comicReadingStatus.set(
                DEFAULT_READING_STATUS_TYPE,
              );
            }
            invalidate();
          }}
        />
      </Flex>
      {readingStatuses?.map(({ display_name, id }, i) => {
        const stringId = id + "";
        return (
          <Flex key={i} paddingLeft="8px">
            <FilterTagButton
              text={display_name}
              isFiltering
              isActive={comicReadingStatus === stringId}
              activeFilters={readingStatusCounts[stringId] ?? 0}
              onClick={async () => {
                await (comicReadingStatus === stringId
                  ? libraryFilterStore.comicReadingStatus.set(
                      DEFAULT_READING_STATUS_TYPE,
                    )
                  : libraryFilterStore.comicReadingStatus.set(stringId));
                invalidate();
              }}
            />
          </Flex>
        );
      })}
    </ButtonCarousel>
  );
}
