import { Icon, IconProps } from "@chakra-ui/react";

export function GenreIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49991 12C4.49991 7.85786 7.85777 4.5 11.9999 4.5C15.5261 4.5 18.484 6.93353 19.2856 10.2128C17.9695 11.7772 15.8647 13.5009 13.2764 14.9953C11.0601 16.2749 8.87557 17.1633 7.02968 17.6168C5.47804 16.2427 4.49991 14.2356 4.49991 12ZM5.83918 17.8563C4.78529 16.7479 4.02737 15.3555 3.6921 13.8057C3.16406 14.4456 2.7784 15.0473 2.54621 15.5837C2.22095 16.3353 2.23245 16.8667 2.4331 17.2142C2.60897 17.5188 2.97726 17.7678 3.61992 17.8891C4.20072 17.9987 4.95159 17.9915 5.83918 17.8563ZM3.5141 12.4952C2.65122 13.4186 1.99643 14.3364 1.62848 15.1865C1.24707 16.0678 1.13741 16.97 1.56708 17.7142C1.9435 18.3662 2.63449 18.7208 3.43448 18.8717C4.23659 19.0231 5.21518 18.9853 6.29146 18.7952C6.44868 18.7674 6.60858 18.7363 6.77097 18.7018C8.21302 19.8286 10.0281 20.5 11.9999 20.5C16.6943 20.5 20.4999 16.6944 20.4999 12C20.4999 11.478 20.4529 10.967 20.3628 10.471C20.913 9.76239 21.3248 9.06523 21.5544 8.41019C21.8236 7.64189 21.8621 6.8662 21.4857 6.21423C21.1185 5.57826 20.4515 5.22511 19.6773 5.06828C18.9131 4.91348 17.9848 4.93522 16.9637 5.09914C15.5671 4.09282 13.8527 3.5 11.9999 3.5C7.30549 3.5 3.49991 7.30558 3.49991 12C3.49991 12.1662 3.50468 12.3314 3.5141 12.4952ZM18.0034 5.98268C18.9053 6.88253 19.6061 7.98385 20.0326 9.21339C20.296 8.80663 20.4892 8.4259 20.6107 8.07946C20.827 7.46226 20.7955 7.01884 20.6196 6.71423C20.448 6.41696 20.0936 6.17293 19.4788 6.04837C19.0708 5.96573 18.5751 5.94231 18.0034 5.98268ZM19.483 11.4924C19.4942 11.6602 19.4999 11.8294 19.4999 12C19.4999 16.1421 16.142 19.5 11.9999 19.5C10.5505 19.5 9.19708 19.0888 8.04999 18.3768C9.81943 17.8575 11.796 17.0047 13.7764 15.8613C16.104 14.5175 18.0812 12.9775 19.483 11.4924Z"
        fill="currentColor"
      />
    </Icon>
  );
}
