import {
  Text,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  ButtonProps,
  Button,
  Box,
} from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useMemo, forwardRef, useEffect } from "react";
import { usePopoverOpenState } from "../../utils/usePopoverOpenState";
import { RadioFilter } from "../filters/RadioFilter";
import { OrderIcon } from "../icons/OrderIcon";
import { SortMenusProps } from "./SortMenus";

export const DEFAULT_SORT_ORDER_TYPE = "popular";
export function SortOrderMenu({
  invalidate,
  filterState,
  sortOrders,
}: SortMenusProps) {
  const { ref, onToggle, isOpen, onClose } = usePopoverOpenState();
  const value = useHookstate(filterState.sortMenus.sortOrder).get();

  const options = useMemo(
    () =>
      sortOrders
        ? sortOrders.map(({ id, name }) => {
            return {
              value: "" + id,
              label: name,
            };
          })
        : [],
    [sortOrders],
  );

  const relevanceOption = options.find(
    (option) => option.label.toLowerCase() === "relevance",
  );

  useEffect(() => {
    const relevanceInOptions = options.some(
      (option) => option.label.toLowerCase() === "relevance",
    );
    if (relevanceInOptions) {
      if (relevanceOption && value !== relevanceOption.value) {
        filterState.sortMenus.sortOrder.set(relevanceOption.value);
      }
    } else if (value === "relevance") {
      filterState.sortMenus.sortOrder.set(DEFAULT_SORT_ORDER_TYPE);
    }
  }, [sortOrders]);

  var activeOption = options.filter((option) => option.value == value);

  return (
    <Popover variant="filterSmall" gutter={4} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <SortOrderTrigger
          isActive={isOpen}
          style={{ pointerEvents: isOpen ? "none" : "auto" }}
          value={activeOption.length ? activeOption[0].label : "Popular"}
          onMouseUp={onToggle}
        />
      </PopoverTrigger>
      <Portal>
        <Box position="relative" zIndex="1402">
          <PopoverContent
            ref={ref}
            variants={{
              enter: { opacity: 1, top: 0 },
              exit: { opacity: 0, top: -20 },
            }}
          >
            <PopoverBody>
              <RadioFilter
                value={value}
                options={options}
                onChange={async (newOrder) => {
                  await filterState.sortMenus.sortOrder.set(newOrder);
                  onClose();
                  invalidate();
                }}
                useCheck
              />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

interface SortOrderTriggerProps extends ButtonProps {}
const SortOrderTrigger = forwardRef<HTMLButtonElement, SortOrderTriggerProps>(
  ({ ...props }, ref) => {
    return (
      <Button
        variant="sortMenu"
        size="md"
        height="36px"
        ref={ref}
        isActive={props.isActive}
        gap="8px"
        {...props}
      >
        <Text variant="filterTag">{props.value}</Text>
        <OrderIcon w={4.5} h={4.5} color="dune.100" />
      </Button>
    );
  },
);
