import { StripePaymentConfigTypes } from "../components/payments/components/StripePayment";
import { apiClient } from "../services/axiosInstance";
import { ServerSingleResponse } from "../types";

export function postToggleFollow(artistId: number, unfollow: boolean = false) {
  return apiClient.post<ServerSingleResponse<boolean>>(
    `/v1/artists/${artistId}/toggle-follow`,
    { meta: [], payload: { intent: unfollow ? "unfollow" : "follow" } },
  );
}

type DonationPayload = StripePaymentConfigTypes & {
  netAmount: number;
  isRecurring: boolean;
  note?: string;
};
export function postArtistDonation(artistId: number, payload: DonationPayload) {
  return apiClient.post<ServerSingleResponse<any>>(
    `/v1/artists/${artistId}/donate`,
    { meta: [], payload },
  );
}
