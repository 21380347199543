import { Flex, Text, Box } from "@chakra-ui/react";
import { t } from "i18next";

interface DonationGoalProgressBarProps {
  donationGoal: string;
  amountDonated: number;
}

export function DonationGoalProgressBar({
  donationGoal,
  amountDonated,
}: DonationGoalProgressBarProps) {
  const parsedDonationGoal = parseFloat(donationGoal);

  /// Donation amount should not exceed the donation goal
  amountDonated = Math.min(parsedDonationGoal, amountDonated);

  return (
    <Flex alignContent="flex-end" flexDirection="column">
      <Text variant="modalBody" color="dune.700" pb={3}>
        {`${t("components.payments.donate.monthlyGoal")} = $${donationGoal}`}
      </Text>
      <ProgressBar
        progress={(amountDonated / parsedDonationGoal) * 100}
        textValue={`$${amountDonated.toFixed(2)}`}
      />
    </Flex>
  );
}

const ProgressBar = ({
  progress,
  textValue,
}: {
  progress: number;
  textValue: string;
}) => {
  const hasZeroProgress = progress === 0;
  const isLowProgress = progress < 10;
  return (
    <Box width="100%" maxW="600px">
      <Box
        height="24px"
        backgroundColor="transparent.white.10"
        borderRadius="8px"
        overflow="hidden"
        position="relative"
      >
        <Box
          width={`${progress}%`}
          height="100%"
          bgGradient={
            hasZeroProgress
              ? undefined
              : "linear(to-r, success.700, success.800)"
          }
          borderRadius="inherit"
          textAlign="right"
          position="relative"
        >
          <Text
            padding="5px"
            color="dune.100"
            variant="modalBody"
            position="absolute"
            right={isLowProgress ? undefined : "5px"}
            top="50%"
            transform="translateY(-50%)"
          >
            {textValue}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
