import { browse } from "./browse/i18n";
import { downloads } from "./downloads/i18n";
import { gold } from "./gold/i18n";
import { inbox } from "./inbox/components/i18n";
import { library } from "./library/i18n";
import { news } from "./news/i18n";
import { gift } from "./gift/i18n";
import { reader } from "./reader/i18n";
import { root } from "./root/i18n";
import { artist } from "./artist/i18n";
import { redemptionSuccess } from "./redemptionSuccess/i18n";

// Roll up screen language files to create a "screens.{screen}" path
export const screens = {
  browse,
  downloads,
  news,
  inbox,
  reader,
  library,
  gold,
  gift,
  root,
  artist,
  redemptionSuccess,
};
