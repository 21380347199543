import { Icon, IconProps } from "@chakra-ui/react";

export function BookIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g id="book">
        <path
          id="book_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 5C4.5 3.61929 5.61929 2.5 7 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V17C19.5 17.6531 19.0826 18.2087 18.5 18.4146V20C18.5 20.8284 17.8284 21.5 17 21.5H5.75C5.05964 21.5 4.5 20.9404 4.5 20.25V5ZM5.5 20.25C5.5 20.3881 5.61193 20.5 5.75 20.5H17C17.2761 20.5 17.5 20.2761 17.5 20V18.5H7.25C6.2835 18.5 5.5 19.2835 5.5 20.25ZM5.5 18.1286V5C5.5 4.17157 6.17157 3.5 7 3.5H18C18.2761 3.5 18.5 3.72386 18.5 4V17C18.5 17.2761 18.2761 17.5 18 17.5H7.25C6.58519 17.5 5.97547 17.7359 5.5 18.1286Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
