import { useHookstate } from "@hookstate/core";
import { useState, useEffect } from "react";
import { readerDataStore, readerSettingsStore } from "../ReaderStateWrapper";
import { useP2PSettings } from "./useP2PSettings";
import * as Sentry from "@sentry/react";

export function useReaderLoaded() {
  const loaded = useHookstate(readerDataStore.loaded).get();
  const [timedOut, setTimedOut] = useState(false);
  useEffect(() => {
    if (!loaded) {
      const timeout = setTimeout(() => {
        Sentry.captureException(new Error("Reader failed to load"), {
          extra: { ...window.location },
        });
        setTimedOut(true);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loaded]);

  const { p2pEnabled } = useP2PSettings();
  const releaseData = useHookstate(readerDataStore.releaseData).get({
    noproxy: true,
  });
  useEffect(() => {
    if (releaseData) {
      if (!releaseData?.can_edit && !releaseData?.is_augmented) {
        readerSettingsStore.p2pEnabled.set(false);
      }
    }
  }, [p2pEnabled, releaseData]);

  return { loaded, timedOut };
}
