import {
  Flex,
  Text,
  ResponsiveValue,
  TextProps,
  BoxProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Comic, Release } from "../../../types";
import { PanelToPanelIcon } from "../../icons/PanelToPanelIcon";
import { VerticalScrollIcon } from "../../icons/VerticalScrollIcon";
import { BookIcon } from "../../icons/BookIcon";

const iconStyles = {
  w: "12px",
  h: "12px",
};

const textStyles: TextProps = {
  textTransform: "uppercase" as ResponsiveValue<"uppercase">,
  fontSize: "8px",
  fontWeight: 700,
  lineHeight: "150%",
};

interface ComicReadingStyleBadgeProps extends BoxProps {
  comic: Comic;
  release?: Release;
}

export function ComicReadingStyleBadge({
  comic,
  release,
  fontSize,
  letterSpacing,
  ...props
}: ComicReadingStyleBadgeProps) {
  const [t] = useTranslation();

  const updatedTextStyles = fontSize
    ? {
        ...textStyles,
        letterSpacing,
        fontSize,
      }
    : textStyles;

  const isAugmented = release ? release.is_augmented : comic.is_augmented === 1;
  const isVertical = comic.comic_format_id === 2;

  const renderContent = (): JSX.Element => {
    if (isAugmented) {
      return (
        <>
          <PanelToPanelIcon {...iconStyles} />
          <Text as="span" {...updatedTextStyles}>
            {t("components.cards.customCard.comicReadingStyle.panelToPanel")}
          </Text>
        </>
      );
    } else if (isVertical) {
      return (
        <>
          <VerticalScrollIcon {...iconStyles} />
          <Text as="span" {...updatedTextStyles}>
            {t("components.cards.customCard.comicReadingStyle.verticalScroll")}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <BookIcon {...iconStyles} />
          <Text as="span" {...updatedTextStyles}>
            {t("components.cards.customCard.comicReadingStyle.printLayout")}
          </Text>
        </>
      );
    }
  };

  return (
    <Flex
      gap="5px"
      alignItems="center"
      background="charcoal.900"
      border="1px solid"
      borderColor={isAugmented ? "blaze.blaze" : "charcoal.900"}
      borderRadius="4px"
      p="4px 8px"
      color="neutral.200"
      width="fit-content"
      {...props}
    >
      {renderContent()}
    </Flex>
  );
}
