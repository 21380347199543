import { useQuery } from "@tanstack/react-query";
import {
  GET_COMIC_RELEASES_QUERY_KEY,
  GET_RELEASE_FRAGMENTS_QUERY_KEY,
  getComicReleases,
  getRelease,
  getReleaseFragments,
} from "../api/readerScreenApi";
import { useEffect, useMemo } from "react";
import { readerDataStore } from "../ReaderStateWrapper";
import { useGaTracking } from "../../../hooks/useGaTracking";
import { readerComponentsStore } from "../ReaderStateWrapper";
import { useReleaseDataQueryKey } from "./useReleaseDataQueryKey";
import { AxiosError } from "axios";
import { useHookstate } from "@hookstate/core";
import { appStore } from "../../../appStore";
import { useEntityCache } from "../../../hooks/useEntityCache";
import { languagesFilterStore } from "../../../components/slideInMenu/comicDetails/LanguagesFilters";
import { readerNavigationContext } from "../ReaderScreen";

export function useHydrateReaderData(
  uuid: string,
) {
  const initData = useHookstate(appStore.init).get();
  const navigationContext = readerNavigationContext.get();

  const { addThirdPartyGaId } = useGaTracking();
  const releaseDataQueryKey = useReleaseDataQueryKey();

  const { data: releaseData, error } = useQuery({
    enabled: !!(uuid) && !!initData.app_urls,
    queryKey: releaseDataQueryKey,
    queryFn: async () => {
      const responseData = await getRelease(
        uuid,
        navigationContext,
      );

      const releaseData = responseData?.data.payload.results;
      if (releaseData.comic.artist.ga_id) {
        addThirdPartyGaId(
          releaseData.comic.artist.name,
          releaseData.comic.artist.ga_id,
        );
      }

      return responseData;
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (error) {
      readerDataStore.responseError.set(error as AxiosError);
      readerComponentsStore.isOverlayOpen.set(true);
    }
  }, [error]);

  const { saveEntityToCache } = useEntityCache();
  useEffect(() => {
    const releaseDataResults = releaseData?.data.payload.results;
    if (releaseDataResults) {
      readerDataStore.merge({
        releaseData: releaseDataResults,
        releasePagesData: releaseDataResults.page_objects,
        comicData: releaseDataResults.comic,
      });

      saveEntityToCache(releaseDataResults);
      saveEntityToCache(releaseDataResults.comic);
      saveEntityToCache(releaseDataResults.comic.artist);
    }
  }, [releaseData]);

  const comicId = releaseData?.data.payload.results.comic.id ?? 0;
  const langId = releaseData?.data.payload.results.lang_id ?? 'en';
  const { language } = useHookstate(languagesFilterStore).get();
  const { data: comicReleasesData } = useQuery({
    enabled: !!(comicId && langId),
    queryKey: [...GET_COMIC_RELEASES_QUERY_KEY, comicId, language ?? langId],
    queryFn: () => {
      return getComicReleases(comicId, language ?? langId ?? "");
    },
    refetchOnWindowFocus: false,
  });

  const comicReleasesDataSorted = useMemo(
    () =>
      comicReleasesData?.data.payload.results.sort((a, b) => a.order - b.order),
    [comicReleasesData],
  );

  useEffect(() => {
    if (comicReleasesDataSorted) {
      readerDataStore.comicReleasesData.set(comicReleasesDataSorted);
    }
  }, [comicReleasesDataSorted]);

  const releaseId = releaseData?.data.payload.results.id ?? 0;
  const { data: releaseFragmentsData } = useQuery({
    enabled: !!releaseId,
    queryKey: [...GET_RELEASE_FRAGMENTS_QUERY_KEY, releaseId],
    queryFn: () => {
      return getReleaseFragments(releaseId);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const releaseFragmentsDataResults =
      releaseFragmentsData?.data.payload.results;
    if (releaseFragmentsDataResults) {
      readerDataStore.releaseFragmentsData.set(releaseFragmentsDataResults);
      readerDataStore.loaded.set(true);
    }
  }, [releaseFragmentsData]);
}
