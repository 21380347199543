import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const brandSwitch = definePartsStyle({
  thumb: {
    backgroundColor: "transparent.white.60",
    _checked: {
      backgroundColor: "blaze.300",
    },
  },
  track: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "transparent.white.60",
    _checked: {
      border: "2px solid",
      borderColor: "blaze.300",
    },
  },
});

const readerSwitch = definePartsStyle({
  thumb: {
    _checked: {
      backgroundColor: "blaze.blaze",
    },
  },
  track: {
    _checked: {
      backgroundColor: "blaze.700",
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: {
    brandSwitch,
    readerSwitch,
  },
});
