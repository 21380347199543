import { AxiosResponse } from "axios";
import {
  ServerListResponse,
  Comic,
  Artist,
  Article,
  Release,
  LayoutStyle,
} from "../../../types";
import { useMemo } from "react";
import { InfiniteData } from "@tanstack/react-query";
import { ResultsGrid } from "../../../components/resultsGrid/ResultsGrid";
import { artistResultsCard } from "../../../components/resultsGrid/ArtistsResultsCard";
import { comicsResultsCard } from "../../../components/resultsGrid/ComicsResultsCard";
import { useEntityCache } from "../../../hooks/useEntityCache";

interface BrowseResultsGridProps {
  tab: 0 | 1;
  mode: LayoutStyle["mode"];
  onScroll?: (scrollableRef: HTMLDivElement) => void;
  gridHeightAdjustment?: string;
  data:
    | InfiniteData<AxiosResponse<ServerListResponse<Comic>, any>, unknown>
    | InfiniteData<AxiosResponse<ServerListResponse<Artist>, any>, unknown>
    | undefined;
  urlSettled: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}
export function BrowseResultsGrid({
  tab,
  mode,
  onScroll,
  gridHeightAdjustment,
  data,
  urlSettled,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: BrowseResultsGridProps) {
  const { saveEntityToCache } = useEntityCache();

  const allPages = useMemo(() => {
    data?.pages.forEach((page) => {
      page.data.payload.results.forEach((n) => saveEntityToCache(n));
    });

    return (data?.pages ?? []) as AxiosResponse<
      ServerListResponse<Comic | Artist | Article | Release>
    >[];
  }, [data]);

  return (
    <ResultsGrid
      key={`${mode}-${tab}`}
      gridHeightAdjustment={gridHeightAdjustment ?? "325px"}
      layout={tab === 1 ? artistResultsCard(mode) : comicsResultsCard(mode)}
      isLoading={!urlSettled || isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      allPages={allPages}
      onScroll={onScroll}
    />
  );
}
