import { Icon, IconProps } from "@chakra-ui/react";

export function CaretRightLargeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64645 4.64645C8.84171 4.45118 9.15829 4.45118 9.35355 4.64645L16.3536 11.6464C16.5488 11.8417 16.5488 12.1583 16.3536 12.3536L9.35355 19.3536C9.15829 19.5488 8.84171 19.5488 8.64645 19.3536C8.45118 19.1583 8.45118 18.8417 8.64645 18.6464L15.2929 12L8.64645 5.35355C8.45118 5.15829 8.45118 4.84171 8.64645 4.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
