import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { Fragment } from "../../../../types";
import { useMemo } from "react";
import { useParams } from "react-router";

export function useReleaseFragmentsData() {
  const releaseData = useHookstate(readerDataStore.releaseData).get({
    noproxy: true,
  });
  const releaseFragmentsData = useHookstate(
    readerDataStore.releaseFragmentsData,
  ).get({
    noproxy: true,
  });

  const setReleaseFragmentsData = (data: Fragment[]) => {
    readerDataStore.releaseFragmentsData.set(data);
  };

  const releaseFragmentsByPage = useMemo(() => {
    const fragmentsByPage: {
      [key: Fragment["page_id"]]: {
        fragments: Fragment[];
        page_order?: number;
      };
    } = {};
    releaseFragmentsData?.forEach((fragment) => {
      if (!fragmentsByPage[fragment.page_id]) {
        fragmentsByPage[fragment.page_id] = { fragments: [fragment] };
      } else {
        fragmentsByPage[fragment.page_id].fragments.push(fragment);
      }
    });

    releaseData?.page_objects.forEach((page) => {
      if (fragmentsByPage[page.id]) {
        fragmentsByPage[page.id].page_order = page.order;
      } else {
        fragmentsByPage[page.id] = { fragments: [], page_order: page.order };
      }
    });
    return fragmentsByPage;
  }, [releaseData, releaseFragmentsData]);

  const { pageOrder, fragmentOrder } = useParams();
  const activePageFragments = useMemo(() => {
    return (
      Object.values(releaseFragmentsByPage).sort(
        (a, b) => (a.page_order ?? 0) - (b.page_order ?? 0),
      )[parseInt(pageOrder ?? "1") - 1]?.fragments ?? []
    );
  }, [releaseFragmentsByPage, pageOrder]);

  const activeFragmentIndex = parseInt(fragmentOrder ?? "1") - 1;
  const activeFragment = useMemo(() => {
    return activePageFragments[activeFragmentIndex];
  }, [activePageFragments]);

  return {
    releaseFragmentsData,
    setReleaseFragmentsData,
    activePageFragments,
    releaseFragmentsByPage,
    activeFragmentIndex,
    activeFragment,
  };
}
