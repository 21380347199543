import { Text, Box, Flex, Link, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { TextFieldGroup } from "../../fields/TextFieldGroup";
import {
  SIGN_UP_WITH_EMAIL_PASSWORD,
  signupWithEmailAndPassword,
} from "../api/authApi";
import { useMutation } from "@tanstack/react-query";
import { ServerResponse } from "../../../types";
import axios from "axios";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../../appStore";
import { t } from "i18next";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { FunnelComponentProps } from "../../funnel/types";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";

export function SignUpWithEmailPasswordModalFooter({
  setFunnelType,
}: FunnelComponentProps) {
  return (
    <Text fontSize="sm" color="neutral.400" align="center">
      {t("components.auth.alreadyHaveAnAccount")}{" "}
      <Link
        color="neutral.200"
        textDecoration="underline"
        onClick={() => {
          setFunnelType(FUNNEL_TYPES.login);
        }}
      >
        {t("components.auth.login")}
      </Link>
    </Text>
  );
}

export function SignUpWithEmailPasswordModalBody({
  onSuccess,
  invalidate = true,
  loading = false,
  loadingText,
}: FunnelComponentProps & {
  invalidate?: boolean;
  loading?: boolean;
  loadingText?: string;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const toast = useToast();
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateQueries = useInvalidateQueries(
    ACCOUNT_INIT_QUERY_KEY,
    releaseQueryKey as string[],
  );

  const { mutate, isPending } = useMutation({
    mutationKey: SIGN_UP_WITH_EMAIL_PASSWORD,
    mutationFn: () => signupWithEmailAndPassword(email, password),
    onSuccess: (_) => {
      if (invalidate) invalidateQueries();
      onSuccess();
    },
    onError: (error) => {
      if (axios.isAxiosError<ServerResponse<unknown>>(error)) {
        const errorData = error.response?.data;
        const emailFormField = errorData?.payload.form_fields?.find(
          (formField) => formField.name === "email",
        );

        const passwordField = errorData?.payload.form_fields?.find(
          (formField) => formField.name === "password_hash",
        );

        const usernameField = errorData?.payload.form_fields?.find(
          (formField) => formField.name === "username",
        );

        setEmailError(emailFormField?.error || "");
        setPasswordError(passwordField?.error || "");

        if (
          !emailFormField?.error &&
          !passwordField?.error &&
          !usernameField?.error
        ) {
          toast({
            title: t("components.auth.loginFailed"),
            status: "error",
          });
        }
      }
    },
  });

  const validatePassword = () => {
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*?.]/;
    let isValid = true;

    if (password.length < 8) {
      setPasswordError(t("components.auth.passwordMustBeAtLeast8Characters"));
      isValid = false;
    } else if (!numberRegex.test(password)) {
      setPasswordError(t("components.auth.passwordMustContainNumbers"));
      isValid = false;
    } else if (!specialCharRegex.test(password)) {
      setPasswordError(t("components.auth.passwordMustContainOneSymbol"));
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };

  const validateEmail = () => {
    let isValidEmail = true;
    if (!email) {
      setEmailError(t("components.auth.emailIsRequired"));
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(email);
      if (!isValidEmail) {
        setEmailError(t("components.auth.invalidEmailFormat"));
      } else {
        setEmailError("");
      }
    }

    return isValidEmail;
  };

  const handleSignUp = () => {
    const isValidEmail = validateEmail();

    const isValidPassword = validatePassword();

    if (isValidEmail && isValidPassword) {
      mutate();
    }
  };

  return (
    <Flex
      direction="column"
      width="100%"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSignUp();
        }
      }}
    >
      <TextFieldGroup
        textFields={[
          {
            id: "inputSignupEmail",
            placeholder: t("components.auth.enterYourEmail"),
            type: "text",
            onChange: setEmail,
            error: emailError,
            autoFocus: true,
          },
          {
            id: "inputSignupPassword",
            placeholder: t("components.auth.createAPassword"),
            type: "password",
            onChange: setPassword,
            error: passwordError,
          },
        ]}
      />
      <Box height="24px" />
      <Button
        id="btnSignupSubmit"
        variant="primary"
        width="full"
        size="lg"
        onClick={handleSignUp}
        isLoading={loading || isPending}
        loadingText={loadingText}
      >
        {t("components.auth.createAccount")}
      </Button>
      <Box textAlign="center" mt="24px">
        <Text fontSize="sm" color="neutral.400">
          {t("components.auth.signInToAgree")}{" "}
          <Link color="neutral.200" textDecoration="underline">
            {t("components.auth.terms")}
          </Link>
        </Text>
      </Box>
    </Flex>
  );
}
