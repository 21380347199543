import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const navbar = definePartsStyle({
  header: {
    borderBottom: "none",
    position: "sticky",
    top: 0,
    background: "neutral.black",
    px: 0,
    pt: "24px",
    _after: {
      content: '""',
      display: "block",
      position: "absolute",
      top: "57px", // positioning the gradient at the correct spot below the header
      left: 0,
      width: "100%",
      height: "32px",
      background:
        "linear-gradient(to bottom, var(--chakra-colors-neutral-black), transparent)",
    },
  },
  body: {
    px: 0,
    pt: 8,
  },
  content: {
    background: "neutral.black",
    borderColor: "transparent.white.10",
    boxShadow: "0px 4px 24px 0px #00000066",
    borderRadius: "0 0 0 24px",
    gap: "0",
    borderRight: "none",
    borderTop: "none",
    px: 6,
    pt: 0,
    pb: 10,
    width: "430px",
    backdropFilter: "blur(18px)",
    overflow: "auto",
    position: "absolute",
    focus: "none",
  },
  popper: {
    zIndex: 1,
    right: "0px !important",
    top: "0px !important",
    height: "calc(100vh - 66px)", // 66px = height of header
  },
});

const filter = definePartsStyle({
  content: {
    background: "transparent.black.90",
    borderColor: "transparent.white.10",
    boxShadow: "0px 4px 24px 0px var(--chakra-colors-transparent-black-60)",
    borderRadius: "16px",
    width: "250px",
    maxHeight: "330px",
    backdropFilter: "blur(18px)",
    overflow: "hidden",
  },
  popper: {
    zIndex: 1,
  },
  body: {
    py: 4,
    px: 6,
    overflow: "auto",
  },
  footer: {
    borderTop: "1px solid",
    borderColor: "transparent.white.20",
    background: "tranparent.black.20",
    display: "flex",
    w: "full",
    justifyContent: "space-between",
    alignItems: "center",
    py: "16px",
    px: "24px",
  },
});

const filterSmall = definePartsStyle({
  ...filter,
  content: {
    ...filter.content,
    width: "200px",
  },
});

const searchResults = definePartsStyle({
  content: {
    background: "transparent.black.90",
    borderColor: "transparent.white.10",
    boxShadow: "0px 4px 24px 0px var(--chakra-colors-transparent-black-60)",
    borderRadius: "0 0 24px 24px",
    gap: "32px",
    borderTop: "none",
    padding: 0,
    backdropFilter: "blur(18px)",
    transition: "all .2s linear",
    overflow: "auto",
  },
  popper: {
    zIndex: 10,
  },
});

export const popoverTheme = defineMultiStyleConfig({
  variants: {
    navbar,
    filter,
    filterSmall,
    searchResults,
  },
});
