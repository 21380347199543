import { Icon, IconProps } from "@chakra-ui/react";

export function FollowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70837 4.66699C3.70837 3.53941 4.62246 2.62533 5.75004 2.62533C6.87762 2.62533 7.79171 3.53941 7.79171 4.66699C7.79171 5.79457 6.87762 6.70866 5.75004 6.70866C4.62246 6.70866 3.70837 5.79457 3.70837 4.66699ZM5.75004 2.04199C4.30029 2.04199 3.12504 3.21724 3.12504 4.66699C3.12504 6.11674 4.30029 7.29199 5.75004 7.29199C7.19979 7.29199 8.37504 6.11674 8.37504 4.66699C8.37504 3.21724 7.19979 2.04199 5.75004 2.04199ZM11.875 5.83366C11.875 5.67258 11.7445 5.54199 11.5834 5.54199C11.4223 5.54199 11.2917 5.67258 11.2917 5.83366V7.29199H9.83337C9.67229 7.29199 9.54171 7.42258 9.54171 7.58366C9.54171 7.74474 9.67229 7.87533 9.83337 7.87533H11.2917V9.33366C11.2917 9.49474 11.4223 9.62533 11.5834 9.62533C11.7445 9.62533 11.875 9.49474 11.875 9.33366V7.87533H13.3334C13.4945 7.87533 13.625 7.74474 13.625 7.58366C13.625 7.42258 13.4945 7.29199 13.3334 7.29199H11.875V5.83366ZM2.54171 11.0837C2.54171 10.5672 2.85572 10.0639 3.43695 9.67642C4.01615 9.2903 4.83309 9.04199 5.75004 9.04199C6.66699 9.04199 7.48394 9.2903 8.06313 9.67642C8.64436 10.0639 8.95837 10.5672 8.95837 11.0837C8.95837 11.2447 9.08896 11.3753 9.25004 11.3753C9.41112 11.3753 9.54171 11.2447 9.54171 11.0837C9.54171 10.3115 9.07222 9.64808 8.3867 9.19106C7.69914 8.73269 6.76609 8.45866 5.75004 8.45866C4.73399 8.45866 3.80094 8.73269 3.11338 9.19106C2.42786 9.64808 1.95837 10.3115 1.95837 11.0837C1.95837 11.2447 2.08896 11.3753 2.25004 11.3753C2.41112 11.3753 2.54171 11.2447 2.54171 11.0837Z"
        fill="currentColor"
      />
    </Icon>
  );
}
