import { Icon, IconProps } from "@chakra-ui/react";

export function MediaArrowLeftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2929 19.7071C17.6834 20.0976 18.3166 20.0976 18.7071 19.7071C19.0976 19.3166 19.0976 18.6834 18.7071 18.2929L12.4142 12L18.7071 5.7071C19.0976 5.3166 19.0976 4.6834 18.7071 4.2929C18.3166 3.9024 17.6834 3.9024 17.2929 4.2929L10.2929 11.2929C9.9024 11.6834 9.9024 12.3166 10.2929 12.7071L17.2929 19.7071ZM5 19C5 19.5523 5.4477 20 6 20C6.5523 20 7 19.5523 7 19L7 5C7 4.4477 6.5523 4 6 4C5.4477 4 5 4.4477 5 5L5 19Z"
        fill="currentColor"
      />
    </Icon>
  );
}
