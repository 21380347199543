// https://www.robinwieruch.de/react-hook-scrollbar-width/
import { useRef } from "react";

export function useScrollbarWidth() {
  const DEFAULT_WIDTH = -1;
  const widthRef = useRef(DEFAULT_WIDTH);

  if (widthRef.current !== DEFAULT_WIDTH) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  // @ts-ignore
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
}
