import { Icon, IconProps } from "@chakra-ui/react";

export function ArrowRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6464 8.64645C12.8417 8.45118 13.1583 8.45118 13.3536 8.64645L16.3536 11.6464C16.4473 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.4473 12.2598 16.3536 12.3536L13.3536 15.3536C13.1583 15.5488 12.8417 15.5488 12.6464 15.3536C12.4512 15.1583 12.4512 14.8417 12.6464 14.6464L14.7929 12.5L8 12.5C7.72386 12.5 7.5 12.2761 7.5 12C7.5 11.7239 7.72386 11.5 8 11.5L14.7929 11.5L12.6464 9.35355C12.4512 9.15829 12.4512 8.84171 12.6464 8.64645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
