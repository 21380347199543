import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import { Range } from "../../@types/util";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { ProgressBar } from "../loading/ProgressBar";
import { DisplayFlags, Release } from "../../types";
import { ReleaseCardMenu } from "./ReleaseCardMenu";
import { useTranslation } from "react-i18next";
import { ReleasePriceLabel } from "./ReleasePriceLabel";
import { useSlideInMenu } from "../slideInMenu/hooks/useSlideInMenu";
import {
  NavigationTrigger,
  ReaderNavigationContext,
} from "../../screens/reader/types";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext";
import { useUserData } from "../../hooks/useUserData";
import { setFunnelModalConfig } from "../funnel/FunnelModal";
import { FUNNEL_TYPES } from "../funnel/utils/contants";
import { getReleaseTypeName } from "../../utils/getReleaseTypeName";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";
import { FunnelContextArea, FunnelNavigationContext } from "../funnel/types";

export interface UnlockableReleaseCardProps {
  fullWidth?: boolean;
  onToggleFollow?: () => void;
  position?: number;
  activeBorder?: boolean;
  release: Release;
  display: DisplayFlags;
  navigationContext?: ReaderNavigationContext;
}

export function UnlockableReleaseCard({
  release,
  display,
  activeBorder,
  navigationContext,
}: UnlockableReleaseCardProps) {
  const { userData } = useUserData();
  const userIsGold = userData?.gold;

  const isLocked =
    !release.is_free &&
    !release.release_user_purchase &&
    (!userIsGold || release.premium_only);
  const { openReleaseInfoMenu } = useSlideInMenu();
  const { goToCTAUrl } = useNavigateToReaderWithContext({
    ...navigationContext,
    releaseToNavigate: release,
    trigger: isLocked
      ? NavigationTrigger.previewReleaseButtonReleaseContextual
      : release.user_read_status
        ? NavigationTrigger.continueReadingButtonReleaseContextual
        : NavigationTrigger.startReadingButtonReleaseContextual,
  });
  const funnelContext = useGetNavigationContext({
    contextArea: FunnelContextArea.releaseDetails,
    release,
  });

  return (
    <Flex
      borderRadius="8px"
      background="charcoal.900"
      direction="row"
      border={activeBorder ? "1px solid" : ""}
      borderColor={activeBorder ? "blaze.300" : ""}
      width="100%"
      padding="8px"
      height="139px"
    >
      {release.thumbnail_url ? (
        <Box
          height="100px"
          w="65px"
          minWidth="65px"
          marginRight="16px"
          position="relative"
        >
          <Image
            src={release.thumbnail_url}
            border="1px solid"
            borderColor="transparent.white.10"
            borderRadius={10}
            fit="cover"
            height="100px"
            width="65px"
            minWidth="65px"
          />
          {isLocked ? (
            <UnlockOverlay
              comicSlug={release.comic.slug}
              releaseUuid={release.key ?? ""}
              navigationContext={navigationContext}
              funnelContext={funnelContext}
              displayPaywall={
                !userIsGold &&
                !release.premium_only &&
                !release.ppb_price_point &&
                !release.ppb_price_point
              }
            />
          ) : null}
        </Box>
      ) : null}
      <Flex
        direction="column"
        justifyContent="center"
        width="100%"
        overflow="auto"
      >
        <Flex direction="row" width="100%" height="auto" alignItems="center">
          <Flex width="100%" direction="column" overflow="hidden">
            <Flex
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                textColor="charcoal.200"
                fontSize="12px"
                lineHeight="150%"
                fontWeight="500"
                fontFamily="Roboto"
                variant="cardSubtitle"
              >
                {getReleaseTypeName(release)} #{release.order}
              </Text>
              <ReleaseCardMenu
                onPreviewRelease={isLocked ? goToCTAUrl : undefined}
                shareUrl={release.share_url}
                onStartReading={
                  !isLocked && !release.user_read_status
                    ? goToCTAUrl
                    : undefined
                }
                onContinueReading={
                  !isLocked && release.user_read_status ? goToCTAUrl : undefined
                }
                onReleaseDetails={() =>
                  openReleaseInfoMenu(
                    release.key ?? "",
                    release.comic.slug,
                    navigationContext,
                  )
                }
              />
            </Flex>
            {release.title && (
              <Text
                variant="cardHeader"
                fontSize="14px"
                paddingBottom="4px"
                sx={{
                  display: "-webkit-box !important",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  maxHeight: "36.4px",
                }}
              >
                {release.title}
              </Text>
            )}
            <ReleasePriceLabel release={release} />
          </Flex>
        </Flex>
        <Box height="full" width="full" />
        {display.include_progress_meter ? (
          <Box pr={4} pb="8px" pt={1}>
            <ProgressBar
              color1="blaze.blaze"
              color2="blaze.300"
              value={
                release.user_read_status?.read_progress_percentage as Range<
                  0,
                  101
                >
              }
            />
          </Box>
        ) : null}
        <ContentCardFooter
          total_pageviews={release.total_pageviews}
          update_time={release.update_time}
          page_count={release.page_count}
          include_total_views={display.include_total_views}
          include_last_updated={display.include_last_updated}
          include_total_pages={display.include_total_pages}
          layoutMode="list"
        />
      </Flex>
    </Flex>
  );
}

function UnlockOverlay({
  comicSlug,
  releaseUuid,
  displayPaywall,
  navigationContext,
  funnelContext,
}: {
  comicSlug: string;
  releaseUuid: string;
  displayPaywall: boolean;
  navigationContext?: ReaderNavigationContext;
  funnelContext?: FunnelNavigationContext;
}) {
  const { t } = useTranslation();
  const { openReleaseInfoMenu } = useSlideInMenu();

  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (displayPaywall) {
          setFunnelModalConfig({
            type: FUNNEL_TYPES.subscription,
            options: {
              locked: false,
              modalContext: "modal",
              navigationContext: funnelContext,
            },
          });
        } else {
          openReleaseInfoMenu(releaseUuid, comicSlug, navigationContext);
        }
      }}
    >
      <Box
        height="100%"
        width="100%"
        background="transparent.black.70"
        position="absolute"
        top="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          border="1px solid"
          borderColor="transparent.white.80"
          alignSelf="center"
          width="fit-content"
          height="fit-content"
          borderRadius="9px"
        >
          <Text
            padding="4px 8px"
            color="transparent.white.80"
            fontSize="10px"
            fontWeight="700"
            lineHeight="150%"
            letterSpacing="0.25px"
            textAlign="center"
          >
            {t("components.cards.unlockReleaseCard.unlock")}
          </Text>
        </Box>
      </Box>
    </Link>
  );
}
