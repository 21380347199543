import { BoxProps, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Comic, DaysOfWeekKeys } from "../../../types";
import { ComicFrequencyStatusIcon } from "../../icons/ComicFrequencyStatusIcon";

interface ComicFrequencyStatusBadgeProps extends BoxProps {
  comic: Comic;
}

export function ComicFrequencyStatusBadge({
  comic,
  ...props
}: ComicFrequencyStatusBadgeProps) {
  const updateFrequencyText = getUpdateFrequencyText(comic);

  return updateFrequencyText || comic.status_name ? (
    <Flex gap="5px" alignItems="center">
      <ComicFrequencyStatusIcon h="8px" w="8px" />
      <Text
        as="span"
        mt="2px"
        textTransform="uppercase"
        fontSize="8px"
        fontWeight={700}
        lineHeight="150%"
        color="neutral.200"
        {...props}
      >
        {updateFrequencyText || comic.status_name}
      </Text>
    </Flex>
  ) : null;
}

function getUpdateFrequencyText(comic: Comic): string | null {
  const { t } = useTranslation();
  const comicFrequency =
    comic.comic_update_frequency ||
    comic.comic_langs?.[0]?.comic_update_frequency;

  if (!comicFrequency) return null;

  let frequency: string | null = null;
  switch (comicFrequency) {
    case 7:
      frequency = t("components.cards.customCard.comicUpdateFrequency.weekly");
      break;
    case 14:
      frequency = t(
        "components.cards.customCard.comicUpdateFrequency.biWeekly",
      );
      break;
    case 31:
      frequency = t("components.cards.customCard.comicUpdateFrequency.monthly");
      break;
    case 62:
      frequency = t(
        "components.cards.customCard.comicUpdateFrequency.biMonthly",
      );
      break;
    case 93:
      frequency = t(
        "components.cards.customCard.comicUpdateFrequency.quarterly",
      );
      break;
    case 186:
      frequency = t(
        "components.cards.customCard.comicUpdateFrequency.twiceAYear",
      );
      break;
    case 365:
      frequency = t("components.cards.customCard.comicUpdateFrequency.yearly");
      break;
    default:
      console.warn(
        "Unexpected value for comic_update_frequency: ",
        comic.comic_update_frequency,
      );
      return null;
  }

  if (comic.comic_update_frequency === 31 && comic.comic_update_period === 31) {
    return t(
      "components.cards.customCard.comicUpdateFrequency.updatedLastMonth",
    );
  }

  let period = "";
  const updatePeriod = comic.comic_update_period;
  if (updatePeriod != null && updatePeriod >= 1 && updatePeriod <= 7) {
    const dayName = t(
      `components.cards.customCard.comicUpdateFrequency.daysOfWeek.${updatePeriod}` as `components.cards.customCard.comicUpdateFrequency.daysOfWeek.${DaysOfWeekKeys}`,
    );
    period = ` ${t("components.cards.customCard.comicUpdateFrequency.on", { day: dayName })}`;
  }

  return `${t("components.cards.customCard.comicUpdateFrequency.updates")} ${frequency}${period}`;
}
