import { LegalHeader } from "./LegalHeader";
import { ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { LegalLink } from "./LegalLink";
import { ContentDivider } from "./ContentDivider";
import { SectionTitle } from "../../../components/text/SectionTitle";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function ContentPolicy() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const marginBetweenSections = isSmallBreakpoint ? "24px" : "40px";
  const baseUrl = window.location.origin;

  return (
    <>
      <LegalHeader title="GlobalComix Content Policy and Takedown Notice Procedure" />
      <Text variant="legalContent">
        Our goal is to let the GlobalComix community be as free as possible in
        sharing their creativity with others. We want to be an inspiring place,
        and a free flow of ideas, designs, and conversation is an essential
        element of this. So is striving to be nice, civil and helpful. In light
        of that, we have a few common sense rules that we ask you to keep in
        mind. These especially relate to copyrights and keeping GlobalComix
        appropriate for a general audience.
      </Text>
      <Text variant="legalContent">
        Users of the GlobalComix Service are responsible for making sure their
        content complies with this Content Policy.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Content Policy</SectionTitle>
      <Text variant="legalContent">
        As specified in our{" "}
        <LegalLink href="/terms-of-use">Terms of Use</LegalLink>, we expect that
        the creator of a comic hold the copyrights to the content. Hence, please
        only upload your own original work, work that is freely available
        through a Creative Commons license, or work that does not infringe on
        the rights of another copyright holder. Users who repeatedly violate the
        rights of others are subject to the GlobalComix repeat infringer policy
        and may have their access to GlobalComix revoked.
      </Text>
      <Text variant="legalContent">
        Be nice! This sounds simple but lots of mistakes happen when people
        ignore this simple rule. We would like everyone to create whatever they
        like, however, we do take copyright seriously and are legally obliged to
        respond to any copyright infringement notices we receive.
      </Text>
      <Text variant="legalContent">
        Please do not infringe other people's intellectual property rights.
        (Intellectual property rights means copyright, patent, registered
        design, design right, trade mark or trade secret.)
      </Text>
      <Text variant="legalContent">
        We live in a culture of re-mix, re-use, and mashup but a good rule of
        thumb here is that if a person does not clearly give you the right to
        use their art, designs, logo, text, slogan etc. then you do not have the
        right to use it. If you do obtain work through a permissive license,
        please comply with the terms of that license. For example, if you are
        relying on a Creative Commons attribution license, please always give
        the originator of the material credit for their work. Please familiarize
        yourself with the{" "}
        <LegalLink href="https://fairuse.stanford.edu/overview/fair-use/">
          details of fair use
        </LegalLink>{" "}
        if you are planning to rely on fair use to make of works created by
        others.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Content</SectionTitle>
      <Text variant="legalContent">
        GlobalComix is a home for all kinds of personal expression, and we try
        to be a home for a number of different communities. However, the range
        of expression allowed on GlobalComix does have limits. We also recognize
        that not all content is welcomed by all communities.
      </Text>
      <Text variant="legalContent">
        Our content policy serves at least two purposes. First, it attempts to
        clarify the types of work that we will not allow on our platform.
        Second, it attempts to balance the abilities of our community to express
        itself with the interests of members of the GlobalComix community who
        would prefer to avoid art that may not be appropriate for all audiences.
      </Text>
      <Text variant="legalContent">
        While all of our guidelines are designed to be comprehensive, we do
        reserve the right to refuse to host comics and art even if they are not
        addressed by these guidelines.
      </Text>
      <Text variant="legalContent">
        If you are unsure if a comic may violate any feature of our content
        policy, we invite you to contact our Content Violation Team at{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          content-violation@globalcomix.com
        </LegalLink>
        . You do not need to send in a compilation of your work — it can be a
        work in process or even just an idea. That email will open up a
        discussion designed to give you a decision from us on how the art will
        sit with our content policy. In the event that we can't give you a final
        answer because the idea isn't quite finished, we'll give you the
        guidelines that we will use to evaluate it when it is done.
      </Text>
      <Text variant="legalContent">
        Finally, if you have a content-related concern about a specific comic or
        story that someone else has posted on GlobalComix, we encourage you to
        report it directly to{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          content-violation@globalcomix.com
        </LegalLink>
        .
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>Paid Content</SectionTitle>
      <Text variant="legalContent">
        GlobalComix enables creators to publish and optionally monetize their
        creative stories on our platform. The act of requiring payment to access
        content comes with some requirements for the quality and type of content
        itself.
      </Text>
      <Text variant="legalContent">
        Our paid content policy aims to serve both our readers and creators in a
        fair way such that neither party feels they have been charged or
        restricted incorrectly. If you are unsure whether or not your comic
        content violates the following terms, please{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          contact us
        </LegalLink>{" "}
        and ask.
      </Text>
      <Text variant="legalContent">Paid Content Criteria</Text>
      <UnorderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            All paid content MUST abide by all parts of this content policy.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Paid access releases can NOT contain "empty" or "blank" pages with
            the intention of earning more without providing any value or art.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Paid access releases can NOT contain more than one (1) pages with
            advertisements for other releases/comics/issues.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Paid access releases can NOT contain pages that promote or ask
            readers to pay the creator outside of GlobalComix for access to
            content hosted on GlobalComix.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Paid access releases can NOT contain more than 1 separate credits
            pages listing contributors and collaborators.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Paid access releases can NOT contain content that falls under the
            Creative Commons license.
          </Text>
        </ListItem>
      </UnorderedList>
      <Text variant="legalContent">
        If we find, or are alerted to, instances where paid content violates
        these rules, we may take any or multiple of the following actions:
      </Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">Remove/delete blank pages.</Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Force set pages to "Always Free" where their removal would create
            problems with the continuity of the narrative.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Refund the reader for their page credits, and remove the offending
            pages value from any pending income the creator has.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            For repeat-offenders, we reserve the right to completely disable the
            ability to require paid access to all their content, or, disable and
            remove their content and profile, or permanently ban the offender
            from using our service, or any combination of the prior.
          </Text>
        </ListItem>
      </OrderedList>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Things That are Prohibited on GlobalComix
      </SectionTitle>
      <Text variant="legalContent">
        Prohibited content on GlobalComix generally fall into two categories:
        hate-speech and obscenity. While no list can be comprehensive, the
        following do's and don't should help you understand our rules.
      </Text>
      <Text variant="legalContent">Hate-speech Do's</Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            The only time articulation or display of hate-speech related content
            is allowed is in context of accurate representation of historic
            events. All other uses are prohibited.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            An example of this would be a story that covers German soldiers in
            World War II where they give their well known "Sieg Heil" salute.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">Hate-speech Don'ts</Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            GlobalComix does not allow comics that promote or support
            hate-speech towards any group of people, ethnicity, race, religion,
            color, or background. Simply put, we won't tolerate it.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Fictional stories outside of historical context that promote
            hate-speech and bigotry are not accepted. Don't post this.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">Obscenity Do's</Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            GlobalComix does give creators who run afoul of the obscenity policy
            a chance to explain their reasoning on artistic and/or expressive
            grounds.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix does strive to be transparent about how it interprets
            art and expression.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">Obscenity Don'ts</Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art that embraces sexual violence.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art in which the premise is pornographic
            or depicts graphic sex.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art that focuses on genitalia without a
            larger context or commentary.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art designed to denigrate living beings.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art that represents or endorse hate
            speech.
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            GlobalComix doesn't accept art that depicts minors in a sexual
            manner.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">What if I have questions?</Text>
      <Text variant="legalContent">
        These are general rules that may be hard to apply to any specific
        situation. We encourage you to reach out to{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          content-violation@globalcomix.com
        </LegalLink>{" "}
        if you are unclear how these rules might apply to you.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Commenting and Posting on the Forum
      </SectionTitle>
      <Text variant="legalContent">
        We reserve the right to remove comments and posts we deem inappropriate
        for our site. In cases of repeated misconduct, GlobalComix reserves the
        right to close a user account and/or ban a user completely. Racism,
        prejudice, or hate speech in any shape or form will not ever be
        tolerated by us. The rules governing behavior on the forum can be found{" "}
        <LegalLink
          href={baseUrl + "/forums/threads/2/forum-rules-and-guidelines"}
        >
          here
        </LegalLink>
        .
      </Text>
      <Text variant="legalContent">
        When commenting on someone's comics please try to be kind and
        constructive. Hours and hours of work could have gone into a design. A
        good comment will honor that effort. A good comment should make a person
        smile, encourage them, allow them to learn something or allow them to
        improve their work.
      </Text>
      <Text variant="legalContent">
        We know that everyone has a bad day once in a while. Some of us might be
        tempted to take this out on others. This is childish, grow up. We take a
        dim view of trolls and negativity in general. This is not to say that
        GlobalComix should be a place where people only say, "you are awesome",
        "no you are awesomer", "no you are awesomest" to each other. There
        should be room for criticism but it should always be constructive.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Notice and Takedown
      </SectionTitle>
      <Text variant="legalContent">
        In order to cooperate with and protect intellectual property rights
        owners, we implemented the following notice take down system. In case
        you find content on the GlobalComix website which you think is
        inappropriate, might infringe your intellectual property right, or does
        not comply with our content policy, please{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          notify us
        </LegalLink>
        , making sure to include the information listed below .
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Procedure for Reporting Copyright Infringement
      </SectionTitle>
      <Text variant="legalContent">
        If you believe that material or content residing on or accessible
        through the GlobalComix Website infringes a copyright, we encourage you
        to first contact the uploader directly. Many disputes have and can be
        resolved through friendly conversations between creators. While strongly
        recommended, we do not require you to contact the uploader prior to
        submitting this takedown request and will honor requests that were not
        preceded by an attempt to contact the uploader.
      </Text>
      <Text variant="legalContent">
        If contacting the uploader fails to resolve your concern, or if you
        prefer not to contact the uploader, please send a notice of copyright
        infringement containing the following information to the Designated
        Agent listed below. Upon receipt of the notice, we will work
        expeditiously to disable access to the content on our site. We cannot
        comply with a notice unless it contains all of the information below.
      </Text>
      <Text variant="legalContent">
        Filing a takedown notice against a comic is a formal legal action that
        can have consequences both for the person filing the notice and the
        person receiving the notice. We strongly encourage you to research the
        law and/or consult an attorney to fully and accurately understand your
        rights before filing a formal takedown request.
      </Text>
      <Text variant="legalContent">
        Pursuant to{" "}
        <LegalLink href="https://www.copyright.gov/title17/92chap5.html#512">
          Section 512 (c)(3)(A) of the Digital Millennium Copyright Act
        </LegalLink>
        , a takedown request must include all of the following:
      </Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of the copyright that has been allegedly
            infringed; Identification of works or materials being infringed;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Identification of the material that is claimed to be infringing
            including information regarding the location of the infringing
            materials that the copyright owner seeks to have removed, with
            sufficient detail so that GlobalComix is capable of finding and
            verifying its existence (in most cases this will be the URL of the
            product page);
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Contact information about the notifier including address, telephone
            number and, if available, e-mail address;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            A statement that the notifier has a good faith belief that the
            material is not authorized by the copyright owner, its agent, or the
            law; and
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            A statement made under penalty of perjury that the information
            provided in the notification is accurate and the notifying party is
            authorized to make the complaint on behalf of the copyright owner.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">
        Please contact the Designated Agent to Receive Notification of Claimed
        Infringement for Company:
      </Text>
      <Text variant="legalContent">
        Contact DMCA Agent by email{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          content-violation@globalcomix.com
        </LegalLink>
        .
      </Text>
      <Text variant="legalContent">
        When removing content from the site, GlobalComix will make reasonable
        attempts to inform the user of the notice for removal, the reason for
        the removal, and may provide the user with a copy of the notice with the
        notifying party's contact information redacted. GlobalComix will
        endeavor to remove the content in a timely manner. We take our users'
        privacy seriously and will not give out a user's details unless legally
        obliged to do so with a court order.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Removal of Allegedly Infringing Content
      </SectionTitle>
      <Text variant="legalContent">
        Once proper notification of copyright infringement is received by the
        Designated Agent, GlobalComix may remove or disable access to such
        content. If GlobalComix removes or disables access to content in
        response to an infringement notice, GlobalComix will make reasonable
        attempts to notify the user that GlobalComix has removed or disabled
        access to the content.
      </Text>
      <ContentDivider />
      <SectionTitle mb={marginBetweenSections}>
        Procedure to Supply a Copyright Counter-Notice to the Designated Agent:
      </SectionTitle>
      <Text variant="legalContent">
        We will notify you if one of your comics has been taken down as a result
        of a takedown request. If you feel that the takedown request was
        improper, you can submit a counter-notice requesting that the comic be
        returned to the site.
      </Text>
      <Text variant="legalContent">
        As with the original takedown notice, filing a counter notice is a
        formal legal action. It can have consequences both for the person filing
        the notice and the person receiving the notice. We strongly encourage
        you to do your research and/or consult an attorney to fully and
        accurately understand your rights before filing a formal counter notice
        request.
      </Text>
      <Text variant="legalContent">
        If you believe that your content does not infringe any copyrights, you
        must send a counter-notice, pursuant to Sections 512(g)(2) and (3) of
        the Digital Millennium Copyright Act. Please do not re-list or
        reactivate the content yourself.
      </Text>
      <Text variant="legalContent">
        The counter-notice must contain all of the following information listed
        below.
      </Text>
      <OrderedList marginLeft="24px">
        <ListItem>
          <Text variant="legalContent">
            Your physical or electronic signature;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Identification of the content that has been removed or to which
            access has been disabled and the location at which the content
            appeared before it was removed or disabled;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            A statement, under penalty of perjury, that the you have a good
            faith belief that the content was removed or disabled as a result of
            mistake or misidentification of the content;
          </Text>
        </ListItem>
        <ListItem>
          <Text variant="legalContent">
            Your name, address, telephone number, and, if available, e-mail
            address and a statement that you consent to the jurisdiction of the
            Federal Court for the judicial district in which your address is
            located, or if your address is located outside the United States,
            for any judicial district in which GlobalComix is located, and that
            you will accept service of process from the person who provided
            notification under{" "}
            <LegalLink href="https://www.law.cornell.edu/uscode/text/17/512">
              Section 512 (c)(1)(C) of the Digital Millennium Copyright Act
            </LegalLink>{" "}
            or an agent of such person.
          </Text>
        </ListItem>
      </OrderedList>
      <Text variant="legalContent">
        If a counter-notice is received by the Designated Agent, GlobalComix may
        send a copy of the counter-notice to the original complaining party
        informing that person that GlobalComix may replace the removed content
        or cease disabling it in 10 business days. Unless the copyright or
        intellectual property owner files an action seeking a court order
        against the user, the removed content may be replaced or access to it
        restored in 10 to 14 business days after receipt of the counter-notice,
        at GlobalComix’ discretion.
      </Text>
      <Text variant="legalContent">
        Please contact GlobalComix’ Designated Agent to submit Notifications of
        Claimed Infringement and Copyright Counter-Notices at the following
        address:
      </Text>
      <Text variant="legalContent">
        Contact DMCA Agent by email{" "}
        <LegalLink href="mailto:content-violation@globalcomix.com">
          content-violation@globalcomix.com
        </LegalLink>
        .
      </Text>
      <Text variant="legalContent">
        If you have any other questions please feel free to{" "}
        <LegalLink href={baseUrl + "/support/contact"}>
          send us a message
        </LegalLink>
        .
      </Text>
    </>
  );
}
