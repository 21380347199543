import { Text, Flex, Divider, Box } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { t } from "i18next";
import { Comic, ComicContributorEntity, Release } from "../../../types";
import { SectionTitle } from "../../text/SectionTitle";
import { getFacetsByType } from "../../../screens/reader/utils/comicFacetGroups";
import { Tag } from "../../tags/Tag";

interface ComicDescriptionProps {
  comicData: Comic | undefined;
  releaseData?: ImmutableObject<Release> | undefined;
}
export function ComicDescription({ comicData }: ComicDescriptionProps) {
  return (
    <Flex flexDirection="column" gap="24px">
      <SectionTitle fontSize="18px">
        {t("components.slideInMenu.comicDetailsMenu.description")}
      </SectionTitle>
      <Text
        fontFamily="Roboto"
        fontSize="14px"
        fontWeight="400"
        lineHeight="22.4px"
        color="neutral.300"
      >
        {comicData?.description}
      </Text>
      <Divider />
      <ComicDetailsInfoFacets comicData={comicData} />
      {!!comicData?.contributors.length && (
        <ComicDetailsContributors comicData={comicData} />
      )}
    </Flex>
  );
}

export interface ComicDetailsContributorsProps {
  comicData?: Comic;
}
export function ComicDetailsContributors({
  comicData,
}: ComicDetailsContributorsProps) {
  return (
    <>
      <Divider />
      <SectionTitle fontSize="18px">
        {t("components.slideInMenu.comicDetailsMenu.contributors")}{" "}
      </SectionTitle>
      <ComicContributorList comicData={comicData} />
    </>
  );
}

export function ComicContributorList({
  comicData,
  byRole,
}: ComicDetailsContributorsProps & { byRole?: boolean }) {
  const contributors = comicData?.contributors.reduce(
    (map, contributor) => {
      const role = contributor.comic_contributor_type.name;
      if (!map[role]) {
        map[role] = [];
      }
      map[role].push(contributor);
      return map;
    },
    {} as Record<string, ComicContributorEntity[]>,
  );

  if (byRole) {
    return (
      <Flex flexDirection="column" gap="8px">
        {Object.keys(contributors ?? {}).map((role) => {
          const contributorList = contributors?.[role];
          if (!contributorList) return null;
          return (
            <Flex key={role} flexDirection="column" gap="6px">
              <Text
                fontFamily="Roboto"
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                color="neutral.200"
              >
                {role}
              </Text>
              <Flex flexDirection="column" gap="6px">
                {contributorList?.map((contributor) => (
                  <Flex key={contributor.id} gap="12px" alignItems="center">
                    <Box
                      bg="transparent.white.10"
                      borderRadius="full"
                      py="8px"
                      px="12px"
                    >
                      <Text
                        fontFamily="Roboto"
                        fontSize="12px"
                        fontWeight="500"
                        lineHeight="14.06px"
                        letterSpacing="0.5px"
                        color="dune.700"
                      >
                        {contributor.name}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" gap="6px">
      {comicData?.contributors.map((contributor) => (
        <Flex key={contributor.id} gap="12px" alignItems="center">
          <Box bg="transparent.white.10" borderRadius="full" py="8px" px="12px">
            <Text
              fontFamily="Roboto"
              fontSize="12px"
              fontWeight="500"
              lineHeight="14.06px"
              letterSpacing="0.5px"
              color="dune.700"
            >
              {contributor.name}
            </Text>
          </Box>
          <Text
            fontFamily="Roboto"
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            color="neutral.200"
          >
            {contributor.comic_contributor_type.name}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}

export interface ComicDetailsInfoFacetsProps {
  comicData?: ImmutableObject<Comic>;
}
export function ComicDetailsInfoFacets({
  comicData,
}: ComicDetailsInfoFacetsProps) {
  const activeFacetGroupMap = getFacetsByType(comicData?.comic_facets);
  return Object.values(activeFacetGroupMap).map(({ facetGroup, facets }) => {
    return (
      <Flex key={facetGroup.id} flexDirection="column" width="100%" gap="16px">
        <Text
          fontFamily="Roboto"
          fontSize="16px"
          fontWeight="500"
          lineHeight="20.8px"
          color="neutral.300"
        >
          {facetGroup.display_name}
        </Text>
        <Flex gap="6px" flexWrap="wrap">
          {facets.map((facet) => (
            <Tag key={facet.id} text={facet.display_name} />
          ))}
        </Flex>
      </Flex>
    );
  });
}
