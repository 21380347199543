import { ReactElement } from "react";
import { ComicContentCardProps } from "../../types";
import { ComicGridCard } from "./ComicGridCard";
import { ComicHorizontalCard } from "./ComicHorizontalCard";
import { ComicListCard } from "./ComicListCard";
import { Link } from "@chakra-ui/react";
import { useSlideInMenu } from "../slideInMenu/hooks/useSlideInMenu";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext";
import { getCTAUrl } from "../../utils/getCTA";

export function ComicCard(
  props: ComicContentCardProps & {
    dontOpenComicDetails?: boolean;
  },
) {
  const { openComicInfoMenu } = useSlideInMenu();
  const mode = props.layout.style.mode;

  const cards: { [key: string]: ReactElement } = {
    grid: <ComicGridCard {...props} />,
    horizontal: <ComicHorizontalCard {...props} />,
    list: <ComicListCard {...props} />,
    calendar: <ComicListCard {...props} />,
  };

  const { goToCTAUrl, navigationContext } = useNavigateToReaderWithContext({
    comicToNavigate: props.content,
    cardPosition: props.index,
    sectionPosition: props.sectionIndex,
    section: props.section,
  });

  if (cards[mode]) {
    if (mode === "calendar") {
      return cards[mode];
    }

    return (
      <Link
        id={`comic-${props.content.id}`}
        href={getCTAUrl(props.content)}
        draggable={false}
        onClick={(e) => {
          e.preventDefault();
          if (
            props.section?.source_type === "continue-reading" ||
            props.dontOpenComicDetails
          ) {
            goToCTAUrl();
          } else {
            openComicInfoMenu(
              props?.content.slug,
              undefined,
              navigationContext,
            );
          }
        }}
      >
        {cards[mode]}
      </Link>
    );
  }

  console.warn("Unable to determine layout mode for ComicCard");
  return null;
}
