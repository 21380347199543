import { useDrag } from "@use-gesture/react";
import { useReadingDirection } from "./useReadingDirection";
import { useNavigationActions } from "./useNavigationActions";
import { READING_DIRECTION_TYPES } from "../constants";
import { useEffect, useState } from "react";
import { useSpring } from "@react-spring/web";
import { useHookstate } from "@hookstate/core";
import { readerComponentsStore } from "../ReaderStateWrapper";
import { Page } from "../../../types";

const SCALE_THRESHOLD = 1.1;

export function useGestureControls(
  directionMoved: string | null,
  releasePageData: Page | null,
) {
  const vpScale = useHookstate(readerComponentsStore.vpScale).get() ?? 1;

  const { readingDirection } = useReadingDirection();
  const { hasNext, hasPrev, goNext, goPrevious } = useNavigationActions();
  const [{ x, opacity }, api] = useSpring(() => ({ x: 0, opacity: 1 }));

  const swipeThreshold = Math.min(window.innerWidth / 3.3, 200);
  const rd = readingDirection === READING_DIRECTION_TYPES.leftToRight ? 1 : -1;
  const directionMovedRd = readingDirection !== directionMoved ? 1 : -1;

  const [target, setTarget] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!target) {
      setTarget(document.getElementById("ActionOverlay") as HTMLDivElement);
    }
  }, []);

  useEffect(() => {
    if (!directionMoved) return;
    api.set({ x: 75 * directionMovedRd, opacity: 0.5 });
    api.start({ x: 0, opacity: 1 });
  }, [releasePageData, directionMovedRd]);

  useDrag(
    (state) => {
      const {
        active,
        down,
        direction: [dx],
        movement: [mx],
        cancel,
      } = state;

      // Invert values when reading from right to left
      const dxRd = dx * rd;
      const mxRd = mx * rd;

      if (
        (hasNext && hasPrev) ||
        (hasNext && mxRd < 0) ||
        (hasPrev && mxRd > 0)
      ) {
        if (active && Math.abs(mx) > swipeThreshold) {
          dxRd < 0 ? goNext() : goPrevious();
          api.set({
            x: Math.abs(mx) * 1.5 * -dx,
            opacity: 0.5,
          });
          cancel();
        }
      }

      if (hasPrev && mxRd > 0) {
        if (active && Math.abs(mx) > swipeThreshold) {
          goPrevious();
          cancel();
        }
      }

      if (hasNext && mxRd < 0) {
        if (active && Math.abs(mx) > swipeThreshold) {
          goNext();
          cancel();
        }
      }

      const springProps = {
        x: down && active ? mx : 0,
        opacity: down && active ? 1 - Math.abs(mx) / 250 : 1,
      };
      api.start(springProps);
    },
    {
      target: target,
      enabled: vpScale < SCALE_THRESHOLD,
      preventScrollAxis: "y",
      from: () => [x.get(), 0],
      rubberband: 0.8,
      bounds: {
        left: target ? -(target.clientWidth / 4.5) : -75,
        right: target ? target.clientWidth / 4.5 : 75,
      },
    },
  );

  return { x, opacity };
}
