const filterList = {
  display: "flex",
  w: "full",
  py: "8px",
  justifyContent: "space-between",
  color: "dune.100",
};

const readerContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "black",
  zIndex: "auto",
};

const readerNavBox = {
  position: "absolute",
  height: "100%",
  bottom: 0,
  background: "transparent",
  pointerEvents: "all",
  zIndex: 2,
};

const channelTab = {
  color: "white",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "130%",
  whiteSpace: "nowrap",
};
export const layerStyles = {
  filterList,
  readerContainer,
  readerNavBox,
  channelTab,
};
