import { apiClient } from "../../../services/axiosInstance";
import {
  ConversationEntity,
  ServerListResponse,
  ServerResponse,
} from "../../../types";

export function postMarkAllSeen() {
  return apiClient.post(`/v1/account/conversations/mark-all-seen`);
}

type UnseenConversationsResponse = {
  unseen_conversations_count: number;
};

export function getUnseenCount() {
  return apiClient.get<ServerResponse<UnseenConversationsResponse>>(
    `/v1/account/conversations/unseen-count`,
  );
}

export const GET_UNSEEN_COUNT_QUERY_KEY = ["conversations-unseen-count"];

export function getList() {
  return apiClient.get<ServerListResponse<ConversationEntity>>(
    `/v1/account/conversations`,
  );
}

export const GET_LIST_QUERY_KEY = ["conversations-list"];

export function postMarkAsSeen(conversationThreadId: number) {
  return apiClient.post(`/v1/account/conversations/mark-seen`, {
    meta: {},
    payload: {
      conversation_thread_id: conversationThreadId,
    },
  });
}
