import { Icon, IconProps } from "@chakra-ui/react";

export function ChatIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C3.17157 4.5 2.5 5.17157 2.5 6V18.9194C2.5 20.1771 3.9549 20.8764 4.93704 20.0907L8.03843 17.6096C8.12709 17.5386 8.23725 17.5 8.35078 17.5H20C20.8284 17.5 21.5 16.8284 21.5 16V6C21.5 5.17157 20.8284 4.5 20 4.5H4ZM3.5 6C3.5 5.72386 3.72386 5.5 4 5.5H20C20.2761 5.5 20.5 5.72386 20.5 6V16C20.5 16.2761 20.2761 16.5 20 16.5H8.35078C8.01017 16.5 7.67971 16.6159 7.41374 16.8287L4.31235 19.3098C3.98497 19.5717 3.5 19.3386 3.5 18.9194V6ZM16 10C15.4477 10 15 10.4477 15 11V11.002C15 11.5543 15.4477 12.002 16 12.002H16.002C16.5543 12.002 17.002 11.5543 17.002 11.002V11C17.002 10.4477 16.5543 10 16.002 10H16ZM11 11C11 10.4477 11.4477 10 12 10H12.002C12.5543 10 13.002 10.4477 13.002 11V11.002C13.002 11.5543 12.5543 12.002 12.002 12.002H12C11.4477 12.002 11 11.5543 11 11.002V11ZM8 10C7.44772 10 7 10.4477 7 11V11.002C7 11.5543 7.44772 12.002 8 12.002H8.002C8.55428 12.002 9.002 11.5543 9.002 11.002V11C9.002 10.4477 8.55428 10 8.002 10H8Z"
        fill="currentColor"
      />
    </Icon>
  );
}
