import { Icon, IconProps } from "@chakra-ui/react";

export function BookmarkCheckedIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.5C6.17157 3.5 5.5 4.17157 5.5 5V20.1315C5.5 21.3295 6.83522 22.0441 7.83205 21.3796L11.7227 18.7858C11.8906 18.6739 12.1094 18.6739 12.2774 18.7858L16.1679 21.3796C17.1648 22.0441 18.5 21.3295 18.5 20.1315V5C18.5 4.17157 17.8284 3.5 17 3.5H7ZM6.5 5C6.5 4.72386 6.72386 4.5 7 4.5H17C17.2761 4.5 17.5 4.72386 17.5 5V20.1315C17.5 20.5308 17.0549 20.769 16.7227 20.5475L12.832 17.9538C12.3282 17.6179 11.6718 17.6179 11.1679 17.9538L7.27735 20.5475C6.94507 20.769 6.5 20.5308 6.5 20.1315V5ZM15.3536 9.35355C15.5488 9.15829 15.5488 8.84171 15.3536 8.64645C15.1583 8.45118 14.8417 8.45118 14.6464 8.64645L11 12.2929L9.35355 10.6464C9.15829 10.4512 8.84171 10.4512 8.64645 10.6464C8.45118 10.8417 8.45118 11.1583 8.64645 11.3536L10.6464 13.3536C10.8417 13.5488 11.1583 13.5488 11.3536 13.3536L15.3536 9.35355Z"
        fill="currentColor"
      />
    </Icon>
  );
}
