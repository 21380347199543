import { Text, Image, Flex, Link, Box } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { Comic } from "../../../types";
import { ComicFrequencyStatusBadge } from "../../cards/components/ComicFrequencyStatusBadge";
import { FreeRibbon } from "../../cards/components/FreeRibbon";
import { ComicReadingStyleBadge } from "../../cards/components/ComicReadingStyleBadge";
import { GCVerticalsAndOriginalsBadge } from "../../cards/components/GcVerticalsAndOriginalsBadge";
import { GCStaffPickAndExclusiveBadge } from "../../cards/components/GcStaffPickAndExclusiveBadge";

interface ComicDetailsHeaderProps {
  comicData: ImmutableObject<Comic> | undefined;
}
export function ComicDetailsHeader({ comicData }: ComicDetailsHeaderProps) {
  return (
    <Flex gap="16px">
      <Box>
        <Image
          borderRadius="10px"
          border="1px solid"
          borderColor="transparent.white.10"
          width="130px"
          alt={`Thumbnail for comic ${comicData?.name}`}
          src={comicData?.image_small_url}
        />
        {comicData?.is_free && (
          <FreeRibbon
            color="success.500"
            position="absolute"
            top={0}
            left="-1px"
          />
        )}
      </Box>
      <Flex flexDirection="column" gap="8px">
        <ComicFrequencyStatusBadge comic={comicData as Comic} />
        <Link
          aria-label={`Read ${comicData?.name}`}
          href={comicData?.url}
          pt="4px"
        >
          <Text
            fontSize="16px"
            fontWeight="700"
            lineHeight="20.8px"
            fontFamily="Roboto"
            color="neutral.200"
          >
            {comicData?.name}
          </Text>
        </Link>
        <Link
          aria-label={`View ${comicData?.artist?.roman_name}`}
          href={comicData?.artist?.url}
        >
          <Text
            fontFamily="Roboto"
            fontSize="12px"
            fontWeight="700"
            lineHeight="18px"
            color="neutral.400"
          >
            {comicData?.artist?.roman_name}
          </Text>
        </Link>
        <GCVerticalsAndOriginalsBadge comicData={comicData as Comic} />
        <ComicReadingStyleBadge comic={comicData as Comic} />
        <GCStaffPickAndExclusiveBadge comicData={comicData as Comic} />
        <Flex flexDirection="column" gap="8px"></Flex>
      </Flex>
    </Flex>
  );
}
