import { Icon, IconProps } from "@chakra-ui/react";

export function SquiggleLargeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 246 14" {...props}>
      <path
        id="Vector 12"
        d="M2 2H244L86.0434 11.755"
        stroke="currentColor"
        strokeWidth="3.00155"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
