import { ThemeOverride } from "@chakra-ui/react";

export const colors = {
  alt: {
    blue_gradient_top: "#4B6383",
    blue_gradient_bottom: "#35465D",
  },
  blaze: {
    "100": "#FFDED5",
    "200": "#FFBDAB",
    "300": "#FF9C80",
    "400": "#FF7B56",
    blaze: "#FF5A2C",
    "600": "#CC4823",
    "700": "#99361A",
    "800": "#662412",
    "900": "#331209",
    blaze_10: "#FF5A2C1A",
  },
  charcoal: {
    "100": "#D0D0D1",
    "200": "#A1A1A2",
    "300": "#727374",
    "400": "#434445",
    charcoal_00: "#14151700",
    charcoal: "#141517",
    charcoal_95: "#141517F2",
    "600": "#101112",
    "700": "#A1A1A2",
    "800": "#A1A1A2",
    "900": "#040405",
  },
  dune: {
    "100": "#FEFCFA",
    "200": "#FDF8F6",
    "300": "#FDF5F1",
    "400": "#FCF1ED",
    dune: "#FBEEE8",
    "600": "#E5D7D1",
    "700": "#BDB2AC",
    "800": "#7D7571",
    "900": "#514B48",
  },
  electric_pink: {
    "300": "#FC6EA4",
    "4100": "#FEB9D3",
    "4200": "#FD9BC1",
    "4300": "#FC4F8F",
    electric_pink: "#FB2576",
    "600": "#E4226B",
    "700": "#B21A54",
    "800": "#8A1441",
    "900": "#6A1033",
  },
  ice: {
    "100": "#C0DAFC",
    "200": "#9DC6FB",
    "300": "#71ACF9",
    "400": "#599EF8",
    ice: "#2D84F6",
    "600": "#2978E0",
    "700": "#205EAF",
    "800": "#194987",
    "900": "#133767",
  },
  fresh: {
    "100": "#C4E3BF",
    "200": "#A6D69E",
    "300": "#7EC374",
    "400": "#65B856",
    fresh: "#3CA52C",
    "600": "#389528",
    "700": "#2A751F",
    "800": "#235D19",
    "900": "#194512",
  },
  neutral: {
    "100": "#F5F5F5",
    "200": "#E5E5E5",
    "300": "#D4D4D4",
    "400": "#A3A3A3",
    "500": "#737373",
    "600": "#525252",
    "700": "#404040",
    "800": "#262626",
    "900": "#171717",
    white: "#FFFFFF",
    black: "#000000",
  },
  slate: {
    "100": "#BABCBF",
    "850": "#141517",
    "950": "#A1A1A2",
  },
  transparent: {
    "white.05": "rgba(255, 255, 255, 0.05)",
    "white.10": "rgba(255, 255, 255, 0.1)",
    "white.20": "rgba(255, 255, 255, 0.2)",
    "white.30": "rgba(255, 255, 255, 0.3)",
    "white.40": "rgba(255, 255, 255, 0.4)",
    "white.50": "rgba(255, 255, 255, 0.5)",
    "white.60": "rgba(255, 255, 255, 0.6)",
    "white.70": "rgba(255, 255, 255, 0.7)",
    "white.80": "rgba(255, 255, 255, 0.8)",
    "white.90": "rgba(255, 255, 255, 0.9)",
    "black.10": "rgba(0, 0, 0, 0.1)",
    "black.20": "rgba(0, 0, 0, 0.2)",
    "black.30": "rgba(0, 0, 0, 0.3)",
    "black.40": "rgba(0, 0, 0, 0.4)",
    "black.50": "rgba(0, 0, 0, 0.5)",
    "black.60": "rgba(0, 0, 0, 0.6)",
    "black.70": "rgba(0, 0, 0, 0.7)",
    "black.80": "rgba(0, 0, 0, 0.8)",
    "black.90": "rgba(0, 0, 0, 0.9)",
    "success.05": "rgba(0, 171, 101, 0.05)",
    "success.20": "rgba(0, 171, 101, 0.2)",
    "success.70": "rgba(0, 171, 101, 0.7)",
  },
  info: {
    "100": "#BFD9EA",
    "200": "#A1C6DF",
    "300": "#76ACD1",
    "400": "#5B9CC8",
    "500": "#3283BA",
    "600": "#2E77A9",
    "700": "#245D84",
    "800": "#1C4866",
    "900": "#15374E",
  },
  success: {
    "100": "#B0E5CF",
    "200": "#8AD8B8",
    "300": "#54C798",
    "400": "#33BC84",
    "500": "#00AB65",
    "600": "#009C5C",
    "700": "#007948",
    "800": "#005E38",
    "900": "#00482A",
  },
  warning: {
    "100": "#FEE8BD",
    "200": "#FEDD9D",
    "300": "#FDCD70",
    "400": "#FDC454",
    "500": "#FCB529",
    "600": "#E5A525",
    "700": "#B3811D",
    "800": "#8B6417",
    "900": "#6A4C11",
  },
  error: {
    "100": "#F2B0B0",
    "200": "#EB8A8A",
    "300": "#E25454",
    "400": "#DD3333",
    "500": "#D40000",
    "600": "#C10000",
    "700": "#970000",
    "800": "#750000",
    "900": "#590000",
  },
  reader: {
    slate: {
      "500": "#222831",
      "500_90": "#222831e6",
      "600": "#1f242d",
      "700": "#181c23",
      "800": "#13161b",
      "800_90": "#13161be6",
      "900_90": "#0e1115e6",
    },
    blue: {
      "200": "#8ebde9",
      "300": "#5b9fe0",
      link: "#3b8dd9",
    },
    altblue: {
      "500": "#4B6383",
      "700": "#35465D",
    },
    neutral: {
      "900_75": "#272727bf",
      "800": "#373D48",
      "600": "#5D6679",
      "700": "#373d48",
      "400": "#858D9D",
      "300": "#99A0AD",
      "200": "#b8bcc6",
      "100": "#D9D9D9",
      "50": "#F1F2F4",
      "50_40": "#f8f8f866",
    },
    icons: {
      lock: "#6F829C",
    },
    bg: {
      warning: {
        "500": "#fcb5291a",
      },
    },
  },
} as ThemeOverride["colors"];
