import { Text, Button, Flex } from "@chakra-ui/react";

interface SettingButtonProps {
  text: string;
  isDisabled?: boolean;
  isActive: boolean;
  onClick: () => void;
  size: number | string;
  icon: JSX.Element;
}
export function SettingButton({
  text,
  isDisabled,
  isActive,
  onClick,
  size,
  icon,
}: SettingButtonProps) {
  return (
    <Button
      variant="iconOnly"
      flexDirection="column"
      justifyContent="center"
      height="auto"
      gap="8px"
      isDisabled={isDisabled}
      onClick={onClick}
      opacity={!isDisabled ? 1 : 0.3}
    >
      <Flex
        borderRadius="8px"
        width={size}
        height={size}
        bg="charcoal.900"
        border="4px solid"
        borderColor={isActive ? "neutral.500" : "charcoal.900"}
        justifyContent="center"
        alignItems="center"
      >
        {icon}
      </Flex>
      <Text
        fontSize="10px"
        fontWeight="400"
        lineHeight="15px"
        textTransform="uppercase"
        letterSpacing=".25px"
        color="dune.200"
      >
        {text}
      </Text>
    </Button>
  );
}
