import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageParam, getPreviousPageParam } from "../../../utils/api";

import { useArtistComicsFiltersState } from "./useArtistComicsFiltersState";
import {
  GET_ARTIST_COMICS_QUERY_KEY,
  getArtistComics,
} from "../api/artistComicsApi";
import { useEntityCache } from "../../../hooks/useEntityCache";

export function useArtistComicsAPI(artistId: number) {
  const { filters } = useArtistComicsFiltersState();
  const { saveEntityToCache } = useEntityCache();

  const {
    isLoading: isLoadingArtistComicsData,
    data,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryFn: async (ctx) => {
      const pageParam = isRefetching ? 1 : ctx.pageParam;
      const paginatedFilters = new URLSearchParams(filters);
      paginatedFilters.set("p", "" + pageParam);
      const response = await getArtistComics(artistId, paginatedFilters);
      response.data.payload.results.forEach(saveEntityToCache);
      return response;
    },
    queryKey: [...GET_ARTIST_COMICS_QUERY_KEY, artistId],
    initialPageParam: 1,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isLoadingArtistComicsData,
    data,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    showEmptyState:
      !(isLoadingArtistComicsData || isRefetching) &&
      (!data?.pages?.[0].data.payload.results.length || error),
  };
}
