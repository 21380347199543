import { appStore } from "../appStore";
import { Release } from "../types";

export function getReleaseTypeName(release: Release) {
  const appInitData = appStore.init.get();

  const releaseType = appInitData.release_types.find(
    (releaseType) => releaseType.id === release.chapter_type,
  );

  return releaseType?.name ?? "Issue";
}
