import { Button, Flex, Text } from "@chakra-ui/react";
import { CSSProperties } from "react";

interface BrowseTabsProps {
  setActive: (index: 0 | 1) => void;
  active: number;
}

export function FloatingBrowseTabs({ setActive, active }: BrowseTabsProps) {
  return (
    <Flex
      gap={10}
      borderRadius="20px"
      background="transparent.black.70"
      backdropFilter="blur(18px)"
      border="1px solid"
      borderColor="transparent.white.10"
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      width="205px"
      height="48px"
      justifyContent="center"
      alignItems="center"
    >
      <BrowseTab
        text="Series"
        onClick={() => setActive(0)}
        isActive={active === 0}
        floating
        fontSize="16px"
        fontWeight={700}
        fontFamily="Bricolage Grotesque"
      />
      <BrowseTab
        text="Creators"
        onClick={() => setActive(1)}
        isActive={active === 1}
        floating
        fontSize="16px"
        fontWeight={700}
        fontFamily="Bricolage Grotesque"
      />
    </Flex>
  );
}

export function BrowseTabs({ setActive, active }: BrowseTabsProps) {
  return (
    <Flex>
      <BrowseTab
        text="Series"
        onClick={() => setActive(0)}
        isActive={active === 0}
        fontWeight={500}
      />
      <BrowseTab
        text="Creators"
        onClick={() => setActive(1)}
        isActive={active === 1}
        fontWeight={500}
      />
    </Flex>
  );
}

interface BrowseTabProps {
  text: string;
  isActive: boolean;
  floating?: boolean;
  onClick: () => void;
  fontSize?: CSSProperties["fontSize"];
  fontWeight?: CSSProperties["fontWeight"];
  fontFamily?: CSSProperties["fontFamily"];
}
function BrowseTab({
  text,
  isActive,
  onClick,
  floating,
  fontSize,
  fontWeight,
  fontFamily,
}: BrowseTabProps) {
  return (
    <Button
      variant={floating ? "browseTab" : undefined}
      key={text}
      transition="all .2s linear"
      cursor="pointer"
      onClick={onClick}
      px="36px"
      height="40px"
      alignItems="center"
      justifyContent="center"
      borderRadius="16px"
      bg={
        floating ? undefined : isActive ? "blaze.600" : "transparent.white.10"
      }
      color={"neutral.white"}
      _hover={{
        color: isActive ? "neutral.white" : "dune.100",
        background: isActive ? undefined : "transparent.white.20",
      }}
      marginInline="4px"
    >
      <Text
        transition="all .2s linear"
        fontFamily={fontFamily}
        fontSize={fontSize ?? "14px"}
        fontWeight={fontWeight}
        lineHeight="130%"
        color={floating ? (isActive ? "dune.100" : "dune.700") : "white"}
        _groupHover={{ color: "dune.100" }}
        marginBottom="2px"
      >
        {text}
      </Text>
    </Button>
  );
}
