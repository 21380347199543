import { useHookstate } from "@hookstate/core";
import { useMemo } from "react";
import { artistComicsFilterStore } from "../components/ArtistComicsFilters";
import { DEFAULT_SORT_DURATION_TYPE } from "../../../components/sortMenus/SortDurationMenu";
import { DEFAULT_SORT_ORDER_TYPE } from "../../../components/sortMenus/SortOrderMenu";

export function useArtistComicsFiltersState() {
  const filterState = useHookstate(artistComicsFilterStore);

  const filters = useMemo(() => {
    const filters = new URLSearchParams();
    const { sortMenus } = filterState.get();

    if (
      sortMenus.sortOrder &&
      sortMenus.sortOrder !== DEFAULT_SORT_ORDER_TYPE
    ) {
      filters.set("sort", sortMenus.sortOrder);
    }

    if (
      sortMenus.sortDuration &&
      sortMenus.sortDuration !== DEFAULT_SORT_DURATION_TYPE
    ) {
      filters.set("sd", sortMenus.sortDuration);
    }

    return filters;
  }, [filterState]);

  return {
    filters,
  };
}
