import { Box, Image, Spinner } from "@chakra-ui/react";
import { Page } from "../../../../types";
import { useImageSizing } from "../../hooks/useImageSizing";

interface VerticalImageProps {
  item: Page;
  height: number;
  width: number;
}
export function VerticalImage({ item, height, width }: VerticalImageProps) {
  const { getImageSrc } = useImageSizing();

  return (
    <Image
      loading="lazy"
      key={item?.id}
      src={getImageSrc(item)}
      alt={`Image for page ${item?.order}`}
      height={height + "px"}
      width={width + "px"}
      filter={
        item?.display_paid_access_block ? "blur(16px) grayscale(20%)" : "none"
      }
      fallback={
        <Box
          height={height + "px"}
          width={width + "px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="blaze.blaze" />
        </Box>
      }
    />
  );
}
