import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Article, Artist, Comic, Release } from "../../../../types";
import { SearchResponse } from "../../api/globalSearchApi";
import { useNavigate } from "react-router-dom";
import { getCTAUrl } from "../../../../utils/getCTA";
import { BrowseEmptyState } from "../../../../screens/browse/components/EmptyState";

type ResultDisplay = {
  image: string;
  title: string;
  url: string;
  type: string;
  entity: Artist | Article | Release | Comic;
};

export function SearchResults({
  results,
  searchTerm,
  onNavigate,
}: {
  results: SearchResponse;
  searchTerm: string;
  onNavigate?: () => void;
}) {
  // Join results together
  const combinedResults: ResultDisplay[] = [
    ...results.comics,
    ...results.releases,
    ...results.articles,
    ...results.artists,
  ].map((result: Article | Artist | Comic | Release) => {
    const image = (() => {
      switch (result.entity_type) {
        case "Artist":
          return result.avatar_url;
        case "Comic":
          return result.image_small_url;
        case "Release":
          return result.thumbnail_url;
        case "News":
          return result.preview_image_url;
      }
    })();
    const title = (() => {
      switch (result.entity_type) {
        case "Artist":
          return result.roman_name;
        case "Comic":
          return result.name;
        case "Release":
          return result.localized_name;
        case "News":
          return result.title;
      }
    })();
    const url = result.url;
    return {
      image,
      title,
      url,
      type: result.entity_type,
      entity: result,
    };
  });

  const navigate = useNavigate();
  return (
    <Flex direction="column" gap={2} maxHeight="100vh" overflowY="auto">
      {combinedResults.length === 0 && <BrowseEmptyState />}
      {combinedResults.map((result, i) => {
        return (
          <Box
            cursor="pointer"
            key={i}
            onClick={(e) => {
              if (result.type === "Artist") {
                e.preventDefault();
                window.location.href = getCTAUrl(result.entity);
              } else if (result.type === "Comic") {
                e.preventDefault();
                window.location.href = getCTAUrl(result.entity);
              } else {
                navigate(getCTAUrl(result.entity));
                onNavigate?.();
              }
            }}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Flex
              padding={2}
              direction="row"
              gap={2}
              height="100%"
              alignItems="center"
            >
              <Image
                loading="lazy"
                src={result.image}
                alt={result.title}
                h={10}
                w="auto"
              />
              {transformTitle(result.title, result.type, searchTerm)}
            </Flex>
            {i < combinedResults.length - 1 ? (
              <Box minHeight="1px" w="full" background="neutral.800" />
            ) : null}
          </Box>
        );
      })}
    </Flex>
  );
}

function transformTitle(
  title: string,
  type: string,
  termMatch: string,
): React.ReactNode {
  const matches = title.split(new RegExp(`(${termMatch})`, "gi"));

  return (
    <>
      <Text variant="navbar">
        {matches.map((match, i) => {
          const matcher = new RegExp(`(${termMatch})`, "gi");

          return (
            <Text
              key={match + "-" + i}
              as="span"
              fontWeight="bold"
              color={matcher.test(match) ? "dune.100" : "charcoal.200"}
            >
              {match}
            </Text>
          );
        })}
      </Text>
      <Flex
        alignItems="center"
        background="transparent.white.10"
        padding="5px 8px"
        borderRadius="45px"
        height="21px"
        whiteSpace="none"
      >
        <Text
          variant="navbar"
          color="dune.100"
          fontSize="sm"
          letterSpacing=".25px"
          whiteSpace="nowrap"
        >
          In {type}
          {type === "news" ? "" : "s"}
        </Text>
      </Flex>
    </>
  );
}
