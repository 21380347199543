import { useCallback } from "react";
import { appStore } from "../../../appStore";
import { Account } from "../../../types";
import { hookstate } from "@hookstate/core";
import { useAccountData } from "../../../hooks/useAccountData";

export const mixpanelStore = hookstate({ initialized: false });
export function useMixpanelRecorder() {
  const accountDataCache = useAccountData();

  const loadMixpanel = (accountData: Account) => {
    if (accountData?.features["mixpanel_session_recording"]) {
      if (!mixpanelStore.get().initialized) {
        window.loadMixpanel();
        const mixpanel_project_id = appStore.init.mixpanel_project_id.get();
        mixpanel.init(mixpanel_project_id, {
          // @ts-expect-error typings are not up to date
          record_min_ms: 5000,
          disable_persistence: true,
          record_block_selector: "",
          record_mask_text_selector: "",
        });
        mixpanelStore.initialized.set(true);
      }

      const userData = accountData?.user;
      if (userData) {
        window.mixpanel.identify(userData.id.toString());
      } else {
        // this sdk doesn't allow identifying as a $device:id guest, so we have to use register instead
        window.mixpanel.register({
          distinct_id: "$device:" + accountData.guest_id,
          $device_id: accountData.guest_id,
        });
      }
    }
  };

  const recordMixpanel = useCallback(
    async (accountData?: Account) => {
      accountData = accountData || accountDataCache;
      if (!accountData) return;
      if (!accountData.features["mixpanel_session_recording"]) return;

      // Wait for mixpanel to initialize if it hasn't
      if (!mixpanelStore.get().initialized) {
        loadMixpanel(accountData);
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      }

      try {
        // @ts-expect-error typings are not up to date
        window.mixpanel.start_session_recording();
      } catch (err) {
        /* no big deal */
      }
    },
    [accountDataCache],
  );

  return recordMixpanel;
}
