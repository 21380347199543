import { Text, Box, Flex } from "@chakra-ui/react";
import { SealCheckIcon } from "../../icons/SealCheckIcon";
import { ImmutableArray } from "@hookstate/core";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { CSSProperties } from "react";

type TextProp = string | [string, string];

interface PaymentValuePropsProps {
  valueProps: ImmutableArray<TextProp>;
  alignItems?: "start" | "center";
  showSeparators?: boolean;
  textColor?: CSSProperties["color"];
  iconColor?: CSSProperties["color"];
}

export function PaymentValueProps({
  valueProps,
  alignItems = "center",
  showSeparators = true,
  textColor = "dune.700",
  iconColor = "blaze.blaze",
}: PaymentValuePropsProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const iconSize = isSmallBreakpoint ? "24px" : "32px";
  return (
    <>
      {showSeparators && (
        <Box width="100%" height="1px" background="transparent.white.10" />
      )}
      <Flex
        flexDirection="column"
        gap="16px"
        alignItems={{ alignItems }}
        justifyContent="center"
      >
        {valueProps.map((prop, i) => {
          return (
            <Flex key={i} gap="16px" alignItems="center">
              <SealCheckIcon
                width={iconSize}
                height={iconSize}
                color={iconColor}
              />
              <Text
                variant="title"
                color={textColor}
                fontWeight={500}
                fontSize={isSmallBreakpoint ? "14px" : "20px"}
                lineHeight="130%"
                textAlign={alignItems}
              >
                {Array.isArray(prop) ? (
                  <>
                    <Text as="span" fontWeight="bold" color={"dune.dune"}>
                      {prop[0]}
                    </Text>{" "}
                    {prop[1]}
                  </>
                ) : (
                  prop
                )}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      {showSeparators && (
        <Box width="100%" height="1px" background="transparent.white.10" />
      )}
    </>
  );
}
