import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonProps, Text } from "@chakra-ui/react";

export function BackButton({
  text,
  onClick,
}: {
  text?: string;
} & ButtonProps) {
  return (
    <Box role="group">
      <Button
        variant="iconOnly"
        minWidth="none"
        role="button"
        aria-label="Back Arrow"
        gap="4px"
        alignItems="center"
        justifyContent="center"
        onClick={onClick}
      >
        <ArrowBackIcon
          w="16px"
          h="16px"
          color="dune.700"
          transition="all .1s linear"
          _groupHover={{ color: "blaze.blaze" }}
        />
        {text && (
          <Text
            fontSize="12px"
            fontWeight="500"
            color="dune.700"
            fontFamily="Bricolage Grotesque"
            _groupHover={{ color: "blaze.blaze" }}
            transition="all .1s linear"
            letterSpacing=".5px"
            verticalAlign="center"
            lineHeight="16px"
          >
            {text}
          </Text>
        )}
      </Button>
    </Box>
  );
}
