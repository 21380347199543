import { Icon, IconProps } from "@chakra-ui/react";

export function BookmarkIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5C5.5 4.17157 6.17157 3.5 7 3.5H17C17.8284 3.5 18.5 4.17157 18.5 5V19.1315C18.5 20.3295 17.1648 21.0441 16.1679 20.3796L12.2774 17.7858C12.1094 17.6739 11.8906 17.6739 11.7227 17.7858L7.83205 20.3796C6.83522 21.0441 5.5 20.3295 5.5 19.1315V5ZM7 4.5C6.72386 4.5 6.5 4.72386 6.5 5V19.1315C6.5 19.5308 6.94507 19.769 7.27735 19.5475L11.1679 16.9538C11.6718 16.6179 12.3282 16.6179 12.832 16.9538L16.7227 19.5475C17.0549 19.769 17.5 19.5308 17.5 19.1315V5C17.5 4.72386 17.2761 4.5 17 4.5H7Z"
        fill="currentColor"
      />
    </Icon>
  );
}
