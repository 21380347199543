import { Link } from "@chakra-ui/react";
import React from "react";

const urlRegex = /(https?:\/\/[^\s]+)/g;

interface AutolinkProps {
  text: string;
}

const Autolink: React.FC<AutolinkProps> = ({ text }) => {
  const parts = text.split(urlRegex);

  return (
    <>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            color="blaze.300"
            textDecoration="underline"
          >
            {part}
          </Link>
        ) : (
          part
        ),
      )}
    </>
  );
};

export default Autolink;
