import { Flex, Box, Image, Text, Spinner } from "@chakra-ui/react";
import { Artist, ServerResponse } from "../../../types";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../services/axiosInstance";
import { PaymentValueProps } from "./PaymentValueProps";
import { useEffect, useState } from "react";
import { StripePayment, StripePaymentConfigTypes } from "./StripePayment";
import { TextField } from "../../fields/TextField";
import { postArtistDonation } from "../../../api/artists";
import axios from "axios";
import { DonationRecurrentCheckbox } from "./DonationRecurrentCheckbox";
import { FunnelComponentProps } from "../../funnel/types";
import useValidatedAmount from "../../fields/hooks/useValidatedAmount";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { DonationGoalProgressBar } from "./DonationGoalProgressBar";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { usePaymentMutation } from "../hooks/usePaymentMutation";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";

interface DonationModalBodyProps {
  artistId?: number;
}

export function DonationModalBody({
  artistId,
  isModal,
}: FunnelComponentProps & DonationModalBodyProps) {
  const minDonation = 3;

  // We need to query artist in order to get donation data
  const { isLoading, data, error } = useQuery({
    queryKey: ["artist", artistId],
    enabled: !!artistId,
    queryFn: async () =>
      apiClient.get<ServerResponse<Artist>>(`/v1/artists/${artistId}`),
    refetchOnWindowFocus: false,
  });

  const initialValue = 10;

  const [amountToDonate, setAmountToDonate] = useState<number | undefined>(
    initialValue,
  );
  const [amountValidationError, setAmountValidationError] = useState<string>();
  const [isRecurrentDonation, setIsRecurrentDonation] = useState(false);
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const { value, setValue } = useValidatedAmount(
    setAmountToDonate,
    initialValue.toString(),
  );

  const mutationFn = (stripePaymentConfig: StripePaymentConfigTypes) => {
    return postArtistDonation(artistId ?? 0, {
      ...stripePaymentConfig,
      netAmount: amountToDonate ?? initialValue,
      isRecurring: isRecurrentDonation,
    });
  };

  const onSuccess = () => {
    setFunnelModalConfig({
      type: FUNNEL_TYPES.donateConfirmation,
      options: {
        donationArtist: data?.data.payload.results,
      },
    });
  };

  const onError = (error: Error) => {
    let errorMessage = t("components.payments.donate.donationDeclined");

    if (axios.isAxiosError<ServerResponse<string>>(error)) {
      errorMessage = error.response?.data?.payload.results || errorMessage;
    }
    return errorMessage;
  };

  const {
    mutate: donate,
    waiting,
    failedCard,
    cardValidationError,
  } = usePaymentMutation(mutationFn, onSuccess, onError);

  const isAboveMinimum = () => {
    if (
      !amountToDonate ||
      (typeof amountToDonate === "number" && amountToDonate < minDonation)
    ) {
      setAmountValidationError(t("components.payments.donate.minimumValue"));
    } else {
      setAmountValidationError(undefined);
    }
  };

  useEffect(() => {
    if (amountToDonate) {
      const timeout = setTimeout(() => {
        isAboveMinimum();
      }, 200);
      return () => clearTimeout(timeout);
    } else {
      isAboveMinimum();
    }
  }, [amountToDonate]);

  if (isLoading) {
    return (
      <Flex width="100%" justifyContent="center">
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  if (error || !data) {
    return null;
  }

  const {
    avatar_small_url,
    roman_name,
    monthly_donation_amount,
    monthly_donation_goal,
  } = data?.data.payload.results;

  const parsedDonationGoal = parseFloat(monthly_donation_goal);

  return (
    <Flex flexDirection="column" gap="24px" width={"100%"}>
      <Flex alignSelf="start" flexDirection="row" width="100%">
        <Image
          borderRadius="24px"
          boxSize={isSmallBreakpoint ? "90px" : "139px"}
          src={avatar_small_url}
          alt="Creator Avatar"
          mr={6}
        />
        <Flex
          flexDirection="column"
          justifyContent={isSmallBreakpoint ? "center" : "space-between"}
          width="100%"
        >
          <Box>
            <Text variant="modalBody">
              {t("components.payments.donate.creator")}
            </Text>
            <Text variant="modalTitle">{roman_name}</Text>
          </Box>

          {/* Only show donation goal slider if the goal is greater than 0  */}
          {parsedDonationGoal > 0 && !isSmallBreakpoint && (
            <DonationGoalProgressBar
              donationGoal={monthly_donation_goal}
              amountDonated={monthly_donation_amount}
            />
          )}
        </Flex>
        {/* Only show donation goal slider if the goal is greater than 0  */}
      </Flex>
      {parsedDonationGoal > 0 && isSmallBreakpoint && (
        <DonationGoalProgressBar
          donationGoal={monthly_donation_goal}
          amountDonated={monthly_donation_amount}
        />
      )}
      <Box width="100%" height="1px" background="transparent.white.10" />
      <PaymentValueProps
        valueProps={[
          t("components.payments.donate.helpFund"),
          t("components.payments.donate.supporterBadge"),
          t("components.payments.donate.earlyAccess"),
        ]}
        alignItems="start"
        showSeparators={false}
      />
      <Box width="100%" height="1px" background="transparent.white.10" />
      <DonationRecurrentCheckbox onChange={setIsRecurrentDonation} />
      <Box width="100%" height="1px" background="transparent.white.10" />
      <Box mb="-8px">
        <Text fontSize="14px" mb={4} color="dune.700">
          {t("components.payments.donate.amountToDonate", {
            minDonation: minDonation,
          })}
        </Text>

        <TextField
          type="text"
          readonly={waiting}
          value={value}
          height="48px"
          placeholder=""
          error={amountValidationError}
          onChange={setValue}
          onBlur={isAboveMinimum}
          inputMode="decimal"
          variant="outline"
          prefix="$"
        />
      </Box>
      <StripePayment
        waiting={waiting && !amountValidationError}
        completePurchase={(stripePaymentConfig) => donate(stripePaymentConfig)}
        isDisabled={!!(amountValidationError || !amountToDonate)}
        submitButtonText={`${t("components.payments.donate.donate")} `}
        responseError={cardValidationError}
        failedCard={failedCard}
        isModal={isModal}
      />
    </Flex>
  );
}
