import { useCallback, useMemo } from "react";
import { cardLayoutStore } from "../../../../appStore";
import { RELEASE_CALENDAR_MAX_WIDTH } from "../../../../components/cards/constants";
import { useScrollbarWidth } from "../../../../utils/scrollbarWidth";
import { useBreakpoint } from "@chakra-ui/react";

export function useCardsSizing() {
  const COLUMN_PADDING = 16;
  const scrollbarWidth = useScrollbarWidth();

  const breakpoint = useBreakpoint({ ssr: false });

  const columnCount = useMemo(() => {
    if (["xs", "sm"].includes(breakpoint)) return 1;
    else if (["md", "lg"].includes(breakpoint)) return 2;
    else if (["xl", "2xl"].includes(breakpoint)) return 3;
    else return 3;
  }, [breakpoint]);

  const setGridCardScale = useCallback((width: number, itemWidth: number) => {
    const adjustedWidth = Math.min(
      width - COLUMN_PADDING * columnCount - scrollbarWidth,
      RELEASE_CALENDAR_MAX_WIDTH,
    );

    const result = adjustedWidth / columnCount / itemWidth;

    cardLayoutStore.comics.calendar_c_1.scaleX.set(result);
    cardLayoutStore.comics.r_list.scaleX.set(result);
  }, []);

  return setGridCardScale;
}
