import useResizeObserver from "@react-hook/resize-observer";
import "scrollyfills";

import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";

export interface VirtualGridProps<T> {
  data: T[];
  totalCount: number;
  renderItem: (item: T, index?: number) => React.ReactNode;
  renderItems?: (items: T[]) => React.ReactNode;
  estimateSize?: (index: number) => number;
  measureElement?: (el: HTMLElement, instance: unknown) => number;
  scrollContainerHeight?: CSSProperties["height"];
  scrollContainerWidth?: CSSProperties["width"];
  direction?: "horizontal" | "vertical";
  itemHeight?: number;
  isFetchingNextPage?: boolean;
  onFetchNextPage?: () => void;
  overscan?: number;
  hasNextPage: boolean;
  initialIndex?: number;
  onMeasureScrollContainer?: (rect: DOMRect) => void;
  gap?: number;
  paddingStart?: number;
  paddingEnd?: number;
  onScroll?: (scrollableRef: HTMLDivElement) => void;
  pageBuffer?: number;
}

export function VirtualGrid<T>({
  renderItem,
  renderItems,
  data,
  totalCount,
  estimateSize = (_index: number) => 150,
  measureElement,
  scrollContainerHeight,
  scrollContainerWidth = 400,
  direction = "vertical",
  itemHeight,
  isFetchingNextPage,
  onFetchNextPage,
  overscan = 5,
  hasNextPage,
  initialIndex = 0,
  onMeasureScrollContainer,
  gap = 0,
  paddingStart = 0,
  paddingEnd = 0,
  onScroll,
  pageBuffer = 1,
}: VirtualGridProps<T>) {
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  useResizeObserver(scrollableRef, (entry) => {
    onMeasureScrollContainer?.(entry.contentRect);
  });

  const rowVirtualizer = useVirtualizer({
    count: totalCount,
    getScrollElement: () => scrollableRef.current,
    estimateSize,
    measureElement,
    horizontal: direction === "horizontal",
    overscan,
    gap,
    paddingStart,
    paddingEnd,
  });

  const isMobile = useMobileBreakpoint();

  const scrollContainerStyle = useMemo<CSSProperties>(() => {
    if (direction === "vertical") {
      return {
        height: scrollContainerHeight,
        width: scrollContainerWidth,
        overflowY: "auto",
        scrollbarWidth: isMobile ? "thin" : undefined,
      };
    }
    return {
      width: scrollContainerWidth,
      overflowX: "auto",
    };
  }, [scrollContainerWidth, scrollContainerHeight]);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (initialIndex === 0) setInitialized(true);
    if (!initialized && initialIndex) {
      rowVirtualizer.scrollToIndex(initialIndex, { align: "start" });
      setInitialized(true);
    }
  }, [initialIndex, initialized]);

  const shouldFetchNextPage = useMemo(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
    const lastIndex = lastItem ? lastItem.index : -1;
    if (lastIndex === -1) return false;
    return lastIndex + pageBuffer >= totalCount;
  }, [rowVirtualizer.getVirtualItems()]);

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage || !onFetchNextPage) {
      return;
    }

    if (shouldFetchNextPage) {
      onFetchNextPage();
    }
  }, [onFetchNextPage, isFetchingNextPage, hasNextPage, shouldFetchNextPage]);

  // Block pointer events while scrolling
  const isScrolling = rowVirtualizer.isScrolling; //useTrackScrolling(scrollableRef);

  const isVertical = direction === "vertical";

  const containerStyle: CSSProperties = {
    position: "relative",
    transform: "translate3d(0, 0, 0)",
  };

  if (isVertical) {
    containerStyle["height"] = `${rowVirtualizer.getTotalSize()}px`;
    containerStyle["width"] = `100%`;
    // Drop any horizontal overflow that could be caused by scale
    containerStyle["overflowX"] = `hidden`;
  } else {
    containerStyle["height"] = itemHeight ? `${itemHeight}px` : undefined;
    containerStyle["width"] = `${rowVirtualizer.getTotalSize()}px`;
  }

  const handleScroll = () => {
    if (!scrollableRef.current) return;
    onScroll?.(scrollableRef.current);
  };

  return (
    <Box position="relative" width="full">
      <Box
        ref={scrollableRef}
        style={scrollContainerStyle}
        className={isScrolling ? "block-pointer-events" : ""}
        onScroll={handleScroll}
      >
        <Box style={containerStyle}>
          {renderItems
            ? renderItems(data)
            : rowVirtualizer.getVirtualItems().map((virtualItem) => {
                const rowItem = data[virtualItem.index];

                const virtualItemStyle: CSSProperties = {
                  position: "absolute",
                  top: 0,
                  left: 0,
                };

                const itemSize = Math.floor(virtualItem.size);
                const itemStart = Math.floor(virtualItem.start);
                if (direction === "vertical") {
                  virtualItemStyle["width"] = "100%";
                  virtualItemStyle["height"] = `${itemSize}px`;
                  virtualItemStyle["transform"] = `translateY(${itemStart}px)`;
                } else {
                  virtualItemStyle["width"] = `${itemSize}px`;
                  virtualItemStyle["transform"] = `translateX(${itemStart}px)`;
                }

                return (
                  <Box
                    key={virtualItem.key}
                    data-index={virtualItem.index}
                    style={virtualItemStyle}
                    ref={rowVirtualizer.measureElement}
                    overflow="clip"
                  >
                    {renderItem(rowItem, virtualItem.index)}
                  </Box>
                );
              })}
        </Box>
        {isFetchingNextPage && (
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="center"
            grow={1}
            paddingBottom="16px"
          >
            <Spinner color="blaze.blaze" size="lg" />
          </Flex>
        )}
      </Box>
    </Box>
  );
}
