import { Icon, IconProps } from "@chakra-ui/react";

export function CloseCircleIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <g id="close_circle">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM8.64645 8.64645C8.84171 8.45118 9.15829 8.45118 9.35355 8.64645L12.0001 11.293L14.6463 8.64669C14.8416 8.45143 15.1582 8.45143 15.3534 8.64669C15.5487 8.84195 15.5487 9.15854 15.3534 9.3538L12.7072 12.0001L15.3534 14.6463C15.5487 14.8416 15.5487 15.1582 15.3534 15.3534C15.1582 15.5487 14.8416 15.5487 14.6463 15.3534L12.0001 12.7072L9.35355 15.3537C9.15829 15.5489 8.84171 15.5489 8.64645 15.3537C8.45118 15.1584 8.45118 14.8418 8.64645 14.6466L11.293 12.0001L8.64645 9.35355C8.45118 9.15829 8.45118 8.84171 8.64645 8.64645Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
