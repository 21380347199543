import { useRef } from "react";

/**
 * Attempts to cause an element to become focused using requestAnimationFrame,
 * and will self-terminate when the element gains focus or maximumFrames is
 * reached.
 */
export function useEnsureFocus(maximumFrames: number = 10) {
  const currentFrames = useRef(0);
  const ensureFocus = (el: HTMLElement) => {
    if (
      document.activeElement === el ||
      currentFrames.current >= maximumFrames
    ) {
      currentFrames.current = 0;
      return;
    }

    requestAnimationFrame(() => {
      currentFrames.current += 1;
      el.focus();
      ensureFocus(el);
    });
  };

  return ensureFocus;
}
