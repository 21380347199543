import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Fragment, ReactNode } from "react";
import { ArrowForwardIcon } from "../../icons/ArrowForwardIcon";

interface MenuItemProps {
  label: ReactNode;
  url?: string;
  noArrow?: boolean;
  underline?: boolean;
  onClick?: () => void;
}

export function MenuItem({
  label,
  url,
  noArrow,
  underline,
  onClick,
}: MenuItemProps) {
  return (
    <Fragment>
      <Link
        href={url}
        role="group"
        paddingRight="8px"
        _hover={{ paddingRight: "0px" }}
        transition="all .2s linear"
        onClick={onClick}
      >
        <Flex
          key={url}
          width="100%"
          pt="12px"
          color="dune.700"
          justifyContent="space-between"
          transition="inherit"
        >
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="130%"
            letterSpacing=".5px"
            fontFamily="Roboto"
            _groupHover={{ color: "dune.100" }}
            transition="inherit"
          >
            {label}
          </Text>
          {!noArrow && (
            <ArrowForwardIcon
              width="20px"
              height="20px"
              _groupHover={{ color: "blaze.blaze" }}
              transition="inherit"
            />
          )}
        </Flex>
      </Link>
      {underline ? (
        <Box minHeight="1px" w="full" background="neutral.800" mt="12px" />
      ) : null}
    </Fragment>
  );
}
