import { Icon, IconProps } from "@chakra-ui/react";

export function InfoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.08333C8.52314 4.08333 4.08329 8.52318 4.08329 14C4.08329 19.4768 8.52314 23.9167 14 23.9167C19.4768 23.9167 23.9166 19.4768 23.9166 14C23.9166 8.52318 19.4768 4.08333 14 4.08333ZM2.91663 14C2.91663 7.87885 7.8788 2.91667 14 2.91667C20.1211 2.91667 25.0833 7.87885 25.0833 14C25.0833 20.1212 20.1211 25.0833 14 25.0833C7.8788 25.0833 2.91663 20.1212 2.91663 14ZM14 12.25C14.3221 12.25 14.5833 12.5112 14.5833 12.8333V18.6667C14.5833 18.9888 14.3221 19.25 14 19.25C13.6778 19.25 13.4166 18.9888 13.4166 18.6667V12.8333C13.4166 12.5112 13.6778 12.25 14 12.25ZM13.9419 8.75C13.6197 8.75 13.3585 9.01117 13.3585 9.33333V9.45C13.3585 9.77217 13.6197 10.0333 13.9419 10.0333H14.0585C14.3807 10.0333 14.6419 9.77217 14.6419 9.45V9.33333C14.6419 9.01117 14.3807 8.75 14.0585 8.75H13.9419Z"
        fill="currentColor"
      />
    </Icon>
  );
}
