import { useHookstate } from "@hookstate/core";
import { readerSettingsStore } from "../ReaderStateWrapper";
import { useReleaseData } from "./hookstate/useReleaseData";

export function useNSFWInterstitial() {
  const { releaseData } = useReleaseData();

  const showNSFWInterstitial = useHookstate(
    readerSettingsStore.showNSFWInterstitial,
  ).get();

  const disableNSFWInterstitial = () => {
    readerSettingsStore.showNSFWInterstitial.set(false);
  };

  return {
    showNSFWInterstitial:
      (showNSFWInterstitial ?? true) && releaseData?.is_nsfw,
    disableNSFWInterstitial,
  };
}
