import { Flex } from "@chakra-ui/react";
import { ReactElement } from "react";
import { DisplayFlags, LayoutStyle } from "../../../types";
import { useGridCardDimensions } from "../hooks/useGridCardDimensions";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function GridCardWrapper({
  children,
  layout: {
    style: { context, mode, size },
  },
  fullWidth = false,
}: {
  layout: {
    style: LayoutStyle;
    display: DisplayFlags;
  };
  children: ReactElement | ReactElement[];
  fullWidth?: boolean;
}) {
  const { width, height } = useGridCardDimensions(context, mode, size);
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const isSmallCard = size === "m";
  return (
    <Flex
      maxHeight={`${height}px`}
      width={fullWidth ? "100%" : `${width}px`}
      maxW={fullWidth ? "100%" : `${width}px`}
      direction="column"
      position="relative"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      px={isSmallCard ? ".5rem" : mode === "grid" ? "1rem" : ".75rem"}
      gap=".5rem"
      _hover={{
        "&>.card-img-wrapper": { mt: 0, mb: isSmallBreakpoint ? "0px" : "4px" },
      }}
      sx={{
        "&>.card-img-wrapper": {
          position: "relative",
          mt: isSmallBreakpoint ? "0px" : "4px",
          mb: 0,
          transition: "all .2s linear",
          width: "100%",
          height: "auto",
        },
      }}
    >
      {children}
    </Flex>
  );
}
