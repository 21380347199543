import { Route } from "react-router-dom";
import { RedemptionSuccessScreen } from "./RedemptionSuccessScreen";

export const routes = (
  <Route
    index
    path="redemption-success/*"
    Component={RedemptionSuccessScreen}
  />
);
