export const news = {
  title: "News Screen",
  relatedNewsTitle: "Related News",
  reactionTitle: "What did you think?",
  errorTitle: "Something unexpected happened",
  reactions: {
    errorTogglingReaction: "Unable to toggle reaction",
    errorPostingComment: "Unable to post comment",
  },
};
