import { Icon, IconProps } from "@chakra-ui/react";

export function OrderIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64645 15.6464C4.84171 15.4512 5.15829 15.4512 5.35355 15.6464L8 18.2929L10.6464 15.6464C10.8417 15.4512 11.1583 15.4512 11.3536 15.6464C11.5488 15.8417 11.5488 16.1583 11.3536 16.3536L8.35355 19.3536C8.25978 19.4473 8.13261 19.5 8 19.5C7.86739 19.5 7.74021 19.4473 7.64645 19.3536L4.64645 16.3536C4.45118 16.1583 4.45118 15.8417 4.64645 15.6464Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6464 4.64645C15.8417 4.45118 16.1583 4.45118 16.3536 4.64645L19.3536 7.64645C19.5488 7.84171 19.5488 8.15829 19.3536 8.35355C19.1583 8.54882 18.8417 8.54882 18.6464 8.35355L16 5.70711L13.3536 8.35355C13.1583 8.54882 12.8417 8.54882 12.6464 8.35355C12.4512 8.15829 12.4512 7.84171 12.6464 7.64645L15.6464 4.64645Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.5C16.2761 4.5 16.5 4.72386 16.5 5L16.5 19C16.5 19.2761 16.2761 19.5 16 19.5C15.7239 19.5 15.5 19.2761 15.5 19L15.5 5C15.5 4.72386 15.7239 4.5 16 4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.5C8.27614 4.5 8.5 4.72386 8.5 5V19C8.5 19.2761 8.27614 19.5 8 19.5C7.72386 19.5 7.5 19.2761 7.5 19V5C7.5 4.72386 7.72386 4.5 8 4.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
