import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  GET_RELATED_NEWS_QUERY_KEY,
  getRelatedNews,
} from "../api/newsScreenApi";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { NewsCard } from "../../../components/cards/NewsCard";

interface RelatedNewsProps {
  articleId: number;
  showDividers?: boolean;
}

export function RelatedNews({
  articleId,
  showDividers = false,
}: RelatedNewsProps) {
  const isSmall = useIsSmallBreakpoint();

  const { isLoading, data, error } = useQuery({
    queryKey: [...GET_RELATED_NEWS_QUERY_KEY, articleId],
    queryFn: () => getRelatedNews(articleId ?? ""),
    enabled: !!articleId,
  });

  if (isLoading) {
    return <Spinner color="blaze.blaze" />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="32px">
      {data?.data.payload.results.map((related, i) => {
        return (
          <>
            <NewsCard
              key={related.id}
              content={related}
              fullWidth
              layout={{
                display: {
                  include_last_updated: true,
                  include_author: true,
                  include_cover_image: true,
                  include_title: true,
                  include_description: true,
                  include_domain: false,
                },
                style: {
                  context: "news",
                  mode: isSmall ? "list" : "grid",
                  size: isSmall ? "s" : "l",
                  corners_bottom: "left",
                  corners_top: "left",
                  rows: 3,
                  section_is_inset: false,
                },
              }}
            />
            {showDividers && i !== data?.data.payload.results.length - 1 ? (
              <Box
                key={related.id + "-divider"}
                width="100%"
                height="1px"
                bg="transparent.white.10"
              />
            ) : null}
          </>
        );
      })}
    </Flex>
  );
}
