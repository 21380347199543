import { Icon, IconProps } from "@chakra-ui/react";

export function HumanoidsLogo(props: IconProps) {
  return (
    <Icon viewBox="0 0 200 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_111)">
        <path
          d="M64.0762 11.4289C63.4168 11.4211 63.1362 11.6145 62.9569 12.2599C61.9934 15.7114 60.9801 19.1473 59.9886 22.5692C59.9746 22.5474 59.9154 22.4897 59.8966 22.4211C58.9893 18.9883 58.0758 15.5586 57.1919 12.1196C57.0593 11.6035 56.8489 11.418 56.3001 11.4305C54.6913 11.471 53.0793 11.4445 51.4689 11.4445C49.8882 11.4445 48.3058 11.4445 46.672 11.4445V29.7732H53.8385V20.0765C53.9336 20.3025 54.0054 20.4381 54.049 20.5816C54.8877 23.3877 55.7467 26.1876 56.5449 29.0046C56.7366 29.6796 57.0375 29.9041 57.7328 29.8589C58.7415 29.7919 59.7579 29.8464 60.7712 29.8402C61.4712 29.8355 62.3707 30.074 62.8166 29.7295C63.2936 29.36 63.3669 28.4449 63.5758 27.7559C64.3334 25.2616 65.0755 22.7625 65.8238 20.2651C65.8597 20.2682 65.894 20.2698 65.9298 20.2729V29.7841H73.5266V11.4804C73.232 11.4679 72.9778 11.4476 72.7253 11.4461C69.8428 11.4445 66.9587 11.4664 64.0762 11.4289Z"
          fill="currentColor"
        />
        <path
          d="M114.396 20.1762C114.293 20.1076 114.239 20.092 114.22 20.0578C112.786 17.4153 111.341 14.7776 109.934 12.1195C109.669 11.6191 109.376 11.4211 108.8 11.4305C106.516 11.4663 104.23 11.443 101.945 11.4461C101.695 11.4461 101.446 11.4726 101.207 11.4866V29.7997H108.048V20.9682C108.193 21.0867 108.246 21.11 108.268 21.1506C109.743 23.86 111.224 26.5664 112.68 29.2852C112.906 29.7061 113.169 29.8729 113.651 29.8542C114.61 29.8153 115.572 29.8433 116.532 29.8433C118.083 29.8433 119.636 29.8433 121.24 29.8433V11.4944H114.395V20.1778L114.396 20.1762Z"
          fill="currentColor"
        />
        <path
          d="M12.1178 17.8347H8.17516V11.4975H0V29.7732H8.22661V23.2271H12.1458V29.795H20.3272V11.4897H12.1193V17.8331L12.1178 17.8347Z"
          fill="currentColor"
        />
        <path
          d="M175.27 12.277C173.983 11.8483 172.595 11.5521 171.243 11.5115C167.457 11.4008 163.664 11.4554 159.874 11.4461C159.749 11.4461 159.625 11.4679 159.459 11.4835V29.7981C159.662 29.8152 159.813 29.8386 159.964 29.8386C163.391 29.8402 166.819 29.876 170.246 29.8199C171.431 29.7996 172.634 29.6484 173.789 29.385C176.998 28.6538 179.394 26.9031 180.232 23.5732C180.538 22.3587 180.65 21.0539 180.597 19.8005C180.441 16.1853 178.84 13.4665 175.27 12.277ZM172.062 22.9699C172.007 23.9255 171.343 24.5085 170.363 24.599C169.494 24.68 168.62 24.705 167.703 24.758V16.5329C168.626 16.5813 169.505 16.5688 170.366 16.6904C171.317 16.826 171.964 17.4823 172.032 18.4037C172.146 19.919 172.149 21.4514 172.062 22.9699Z"
          fill="currentColor"
        />
        <path
          d="M139.712 11.7657C137.458 11.0065 135.133 10.8865 132.787 11.0907C131.913 11.1671 131.032 11.3074 130.182 11.5288C126.018 12.6107 123.876 15.3311 123.519 19.9269C123.119 25.0854 125.763 28.7318 130.761 29.8091C131.997 30.0757 133.277 30.1349 134.499 30.2846C135.743 30.1427 136.942 30.085 138.108 29.8574C141.559 29.1824 144.231 27.4519 145.157 23.8773C145.539 22.3994 145.642 20.7843 145.527 19.2565C145.245 15.5415 143.307 12.9739 139.712 11.7657ZM136.967 23.4283C136.71 24.5803 135.975 25.0402 134.652 25.0543C133.161 25.0699 132.444 24.6754 132.15 23.4766C131.679 21.5622 131.676 19.6182 132.159 17.7053C132.454 16.5346 133.163 16.1402 134.6 16.1479C135.946 16.1557 136.689 16.5938 136.954 17.7256C137.171 18.6532 137.219 19.6182 137.344 20.5723C137.224 21.5357 137.176 22.5007 136.968 23.4298L136.967 23.4283Z"
          fill="currentColor"
        />
        <path
          d="M43.7724 11.51H35.6331C35.6331 14.6902 35.6424 17.805 35.6268 20.9198C35.6221 21.7492 35.5832 22.5832 35.4912 23.4064C35.4101 24.1344 35.1342 24.8219 34.3126 24.9669C32.367 25.3114 31.6078 24.758 31.4628 23.0151C31.3943 22.1873 31.374 21.3548 31.3693 20.5238C31.3584 17.7972 31.3662 15.0691 31.3631 12.3424C31.3631 12.0665 31.3397 11.7906 31.3241 11.4803H23.1926C23.1676 11.6503 23.1411 11.75 23.1411 11.8498C23.1521 15.5633 23.0944 19.2798 23.1988 22.9917C23.3002 26.6147 25.2457 28.9064 28.7674 29.7186C31.7715 30.4107 34.8052 30.3905 37.8094 29.7513C41.752 28.9126 43.618 26.8485 43.7989 22.8202C43.9641 19.1395 43.8534 15.4463 43.8581 11.7578C43.8581 11.6659 43.7957 11.5754 43.7708 11.5084L43.7724 11.51Z"
          fill="currentColor"
        />
        <path
          d="M92.7501 11.9481C92.5661 11.4571 92.2699 11.4399 91.8599 11.4399C88.8729 11.4493 85.886 11.4602 82.899 11.4306C82.3378 11.4243 82.0993 11.6099 81.9044 12.129C79.7827 17.7833 77.6313 23.4267 75.4893 29.0733C75.4036 29.3009 75.335 29.5347 75.2336 29.8403C77.7732 29.8403 80.188 29.8528 82.6013 29.8185C82.8117 29.8153 83.1204 29.5363 83.2077 29.3165C83.4727 28.6461 83.6504 27.9415 83.8749 27.2088C85.6942 27.2088 87.4855 27.2321 89.2736 27.1947C89.8364 27.1823 90.0796 27.3537 90.2386 27.8994C90.8045 29.8465 90.8263 29.8403 92.842 29.8403H99.4349C99.4006 29.6251 99.4021 29.5456 99.3756 29.4755C97.1635 23.6325 94.942 17.7942 92.7485 11.945L92.7501 11.9481ZM85.3326 22.5444C85.8642 20.7204 86.3817 18.9494 86.9508 16.9976C87.5057 18.9198 88.014 20.6877 88.5502 22.5444H85.3326Z"
          fill="currentColor"
        />
        <path
          d="M199.95 23.182C199.696 20.6003 198.284 19.0055 195.721 18.6298C194.293 18.4209 192.821 18.5129 191.37 18.4677C191.059 18.4583 190.743 18.4739 190.439 18.424C189.912 18.3367 189.591 18.0109 189.608 17.4637C189.625 16.9259 190.007 16.7092 190.49 16.6546C190.721 16.6281 190.958 16.6375 191.19 16.6375C193.502 16.6375 195.814 16.6375 198.128 16.6344C198.401 16.6344 198.675 16.611 198.938 16.5985V11.4976C194.798 11.4976 190.718 11.3885 186.648 11.5412C184.471 11.6223 182.803 12.8243 182.183 15.0458C181.95 15.8783 181.882 16.7871 181.905 17.657C181.98 20.4725 183.826 22.4461 186.641 22.6987C188.06 22.8265 189.494 22.7876 190.922 22.8141C191.563 22.825 192.112 23.0152 192.119 23.7385C192.127 24.5102 191.524 24.6022 190.905 24.6022C188.878 24.5991 186.852 24.6022 184.825 24.6022C184.08 24.6022 183.335 24.6022 182.555 24.6022V29.7514C186.839 29.7514 191.047 29.8652 195.244 29.7109C198.012 29.608 199.546 27.9898 199.927 25.2819C200.023 24.5944 200.019 23.8773 199.952 23.1835L199.95 23.182Z"
          fill="currentColor"
        />
        <path
          d="M148.093 29.8028H156.204V11.496H148.093V29.8028Z"
          fill="currentColor"
        />
        <path
          d="M132.015 9.95889H162.981C160.639 8.31419 158.441 6.76927 156.051 5.09027C161.589 3.69345 166.908 2.35119 172.226 1.01049C172.218 0.969954 172.208 0.927862 172.201 0.887329C163.34 1.49688 154.48 2.10487 145.619 2.71443C145.605 2.77055 145.591 2.82511 145.578 2.88123C147.728 4.14399 149.876 5.4083 152.026 6.67106C152.014 6.73809 152.001 6.80357 151.989 6.8706C145.296 7.90575 138.602 8.94089 132.015 9.95889Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_111">
          <rect
            width="200"
            height="29.3988"
            fill="currentColor"
            transform="translate(0 0.885742)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
