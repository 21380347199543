import { FlexProps, Flex } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useFragmentPosition } from "../../hooks/useFragmentPosition";
import { useImageSizing } from "../../hooks/useImageSizing";
import { BouncyImage } from "./BouncyImage";
import { P2POverlay } from "./P2POverlay";
import { ImmutableObject } from "@hookstate/core";
import { Page } from "../../../../types";
import { usePageSize } from "../../hooks/usePageSize";
import useResizeObserver from "@react-hook/resize-observer";
import { SpringValue } from "@react-spring/web";
import { useMobileBreakpoint } from "../../../../utils/useBreakpoints";

interface P2PReaderProps {
  releasePageData: ImmutableObject<Page> | null;
  swipeProps?: { opacity?: SpringValue<number>; x?: SpringValue<number> };
}
export function P2PReader({ releasePageData, swipeProps }: P2PReaderProps) {
  const {
    imageRef,
    transformStyle,
    width,
    height,
    activeFragment,
    activePageFragments,
  } = useFragmentPosition(releasePageData);

  const isMobile = useMobileBreakpoint();
  const { pageSizing } = usePageSize();
  const { getImageSize } = useImageSizing();

  const [lastResize, setLastResize] = useState(Date.now());
  useResizeObserver(window.document.body, () => {
    setLastResize(Date.now());
  });

  const { imageHeight, imageWidth } = useMemo(() => {
    return getImageSize(releasePageData);
  }, [
    getImageSize,
    releasePageData,
    activePageFragments,
    pageSizing,
    lastResize,
  ]);

  const containerHeight = `calc(100dvh - ${isMobile ? 24 : 48}px)`;
  const containerWidth = `calc(100dvw - ${isMobile ? 24 : 48}px)`;
  const p2pStyle: FlexProps = activeFragment
    ? {
        maxHeight: containerHeight,
        minHeight: containerHeight,
        width: containerWidth,
      }
    : {};
  const activeTransformStyle = activeFragment ? transformStyle : undefined;

  return (
    <Flex
      id="p2pReader"
      position="relative"
      alignItems="center"
      justifyContent="center"
      margin={0}
      {...p2pStyle}
    >
      <Flex
        justifyContent="center"
        style={{ ...activeTransformStyle }}
        position="relative"
        maxWidth="100%"
        margin="0"
      >
        <BouncyImage
          containerRef={imageRef}
          fullHeightReader={false}
          releasePageData={releasePageData}
          parentHeight={imageHeight}
          parentWidth={imageWidth}
          swipeProps={!!activeTransformStyle ? undefined : swipeProps}
        />
        <P2POverlay
          activeFragment={activeFragment}
          fragments={activePageFragments}
          width={width}
          height={height}
        />
      </Flex>
    </Flex>
  );
}
