import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { MessageReaction, UserChatMessageProps } from "../types";
import {
  reactionIdsToEmoji,
  ReactionsPopover,
} from "../../reactions/ReactionsPopover";
import { ReactionTypeEntity } from "../../../services/gc/types/reactionTypeEntity";
import { Message, PresenceMember } from "@ably/chat";
import { timeAgo } from "../../../utils/time";
import { Avatar } from "../../user/Avatar";
import { useTapHold } from "../../../hooks/useTapHold";
import { useIsMouseInput } from "../../../hooks/useIsMouseInput";
import Autolink from "../../navigation/AutoLink";
import { useHookstate } from "@hookstate/core";
import { useUserData } from "../../../hooks/useUserData";
import { ablyStore } from "../../../screens/globalChat/ChatScreen";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import truncateString from "../../../utils/truncateString";

export function ChatMessage({
  deleteMessage,
  toggleReaction,
  message,
  members,
}: {
  deleteMessage: (message: Message) => void;
  toggleReaction: (message: Message, data: ReactionTypeEntity) => void;
  message: Message;
  members: Record<string, PresenceMember>;
}) {
  const isSmall = useIsSmallBreakpoint();
  const isMouseInput = useIsMouseInput();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData } = useUserData();

  const tapHoldProps = useTapHold({
    onHold: onOpen,
    onBlur: onClose,
    holdDuration: 750,
  });

  // Ref to measure the container for positioning and touch detection
  const containerRef = useRef<HTMLDivElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<"top" | "bottom">(
    "bottom",
  );

  // Update popover position based on available space
  useEffect(() => {
    const updatePopoverPosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const spaceAbove = rect.top;
        const spaceBelow = window.innerHeight - rect.bottom;
        if (spaceBelow < 100 && spaceAbove > spaceBelow) {
          setPopoverPosition("top");
        } else {
          setPopoverPosition("bottom");
        }
      }
    };

    updatePopoverPosition();
    window.addEventListener("resize", updatePopoverPosition);
    return () => window.removeEventListener("resize", updatePopoverPosition);
  }, [isOpen]);

  // Listen for touchstart events on the document to detect touches outside the container.
  // This is used to close the popover when tapping outside the message because Safari does
  // not support onBlur callback.
  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        tapHoldProps.onBlur();
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    return () => document.removeEventListener("touchstart", handleTouchStart);
  }, [tapHoldProps]);

  const { clientId, createdAt: timestamp } = message;
  const reactions = message.metadata.reactions as Record<
    string,
    MessageReaction[]
  >;
  const memberData = members[clientId];
  const metadata = memberData?.data ?? message.metadata;
  const { username, isGold, avatar } = metadata as UserChatMessageProps;
  const formattedDate = timeAgo(timestamp.getTime());

  return (
    <Box ref={containerRef} w="full">
      <Button
        height="fit-content"
        position="relative"
        gap="12px"
        p="8px"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        _hover={{
          background: "transparent.white.05",
          borderRadius: "16px",
        }}
        _focus={{
          background: isOpen ? "transparent.white.10" : "transparent",
        }}
        role="group"
        bg="transparent"
        lineHeight="normal"
        letterSpacing="normal"
        {...(isMouseInput || !userData ? {} : tapHoldProps)}
      >
        <Avatar
          size="lg"
          isGold={isGold}
          username={username}
          imageSrc={avatar}
          isOnline={
            members[clientId] ? members[clientId]?.action !== "leave" : false
          }
        />

        <Flex flexDirection="column" flexGrow={1} minWidth={0}>
          <Flex alignItems="center" width="100%" gap={3}>
            <Text fontWeight="600" fontSize="16px" color="dune.dune">
              {isSmall ? truncateString(username ?? "", 20) : username}
            </Text>
            <Flex gap="8px">
              <Text fontWeight="500" fontSize="12px" color="dune.700">
                {formattedDate}
              </Text>
            </Flex>
          </Flex>
          <Text
            mb="8px"
            mt="4px"
            fontSize="15px"
            textAlign="left"
            whiteSpace="normal"
            fontWeight="400"
            lineHeight="150%"
          >
            <Autolink text={message.text} />
          </Text>
          <ReactionsRow
            reactions={reactions}
            toggleReaction={toggleReaction}
            message={message}
          />
        </Flex>

        <ReactionsPopover
          isOpen={isOpen}
          onToggle={onClose}
          deleteMessage={deleteMessage}
          toggleReaction={toggleReaction}
          message={message}
          position={popoverPosition}
        />
      </Button>
    </Box>
  );
}

export function ReactionsRow({
  reactions,
  toggleReaction,
  message,
}: {
  reactions: Record<string, MessageReaction[]>;
  toggleReaction: (message: Message, data: ReactionTypeEntity) => void;
  message: Message;
}) {
  const realtimeClient = useHookstate(ablyStore.realtimeClient).get();

  return (
    <Flex gap="8px">
      {Object.values(reactions ?? {}).map((reactionsOfType, i) => {
        if (reactionsOfType.length === 0) return null;
        const userHasReacted = reactionsOfType.some(
          (reaction) => reaction.clientId === realtimeClient?.auth.clientId,
        );
        return (
          <Button
            key={i}
            size="thin"
            onClick={() => toggleReaction(message, reactionsOfType[0].reaction)}
            fontSize="12px"
            fontWeight="400"
            p="4px 8px"
            borderRadius="100px"
            gap="4px"
            border="1px solid"
            borderColor={userHasReacted ? "blaze.300" : "transparent"}
          >
            {reactionIdsToEmoji[reactionsOfType[0].reaction.id]}
            <Text color="dune.dune">{reactionsOfType.length}</Text>
          </Button>
        );
      })}
    </Flex>
  );
}
