import { Icon, IconProps } from "@chakra-ui/react";

export function AutoLayoutIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 51 44" {...props}>
      <path
        d="M33.9083 0.895508H16.3157C14.1835 0.895508 12.4453 2.63337 12.4453 4.76523V39.241C12.4453 41.3728 14.1835 43.1107 16.3157 43.1107H33.9083C36.0405 43.1107 37.7786 41.3728 37.7786 39.241V4.76523C37.7786 2.63337 36.0405 0.895508 33.9083 0.895508ZM35.6675 39.241C35.6675 40.2119 34.8794 40.9999 33.9083 40.9999H16.3157C15.3446 40.9999 14.5564 40.2119 14.5564 39.241V4.76523C14.5564 3.79428 15.3446 3.00627 16.3157 3.00627H33.9083C34.8794 3.00627 35.6675 3.79428 35.6675 4.76523V39.241Z"
        fill="#B8BCC6"
      />
      <path
        d="M23.6673 24.667H20.334V28.0003"
        stroke="#B8BCC6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9451 23.9979C29.5713 24.9231 28.9455 25.7248 28.1387 26.312C27.332 26.8993 26.3767 27.2484 25.3814 27.3197C24.3861 27.391 23.3908 27.1818 22.5086 26.7156C21.6263 26.2495 20.8925 25.5452 20.3906 24.6828"
        stroke="#B8BCC6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.334 19.3333H29.6673V16"
        stroke="#B8BCC6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0547 20.0024C20.4285 19.0773 21.0543 18.2755 21.861 17.6883C22.6678 17.1011 23.6231 16.752 24.6183 16.6806C25.6136 16.6093 26.6089 16.8186 27.4912 17.2847C28.3734 17.7509 29.1072 18.4552 29.6091 19.3176"
        stroke="#B8BCC6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0551 11.1112L46.7881 11.1112C47.1821 11.1112 47.5551 11.266 47.8295 11.5404C48.104 11.8148 48.2588 12.1806 48.2588 12.5676L48.2588 31.6555C48.2588 32.0424 48.104 32.4013 47.8295 32.6827C47.5551 32.9571 47.1821 33.1119 46.7881 33.1119L43.0551 33.1119C42.471 33.1119 41.9995 33.5833 41.9995 34.1673C41.9995 34.7513 42.471 35.2227 43.0551 35.2227L46.7881 35.2227C47.7451 35.2227 48.6458 34.8498 49.3214 34.1813C49.997 33.5059 50.377 32.6123 50.377 31.6625L50.377 12.5606C50.377 11.6107 50.004 10.7102 49.3214 10.0417C48.6458 9.37334 47.7451 9.00044 46.7881 9.00044L43.0551 9.00044C42.471 9.00044 41.9995 9.47184 41.9995 10.0558C41.9995 10.6398 42.471 11.1112 43.0551 11.1112Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M7.32186 33.1115H3.58889C3.19481 33.1115 2.82185 32.9567 2.54741 32.6823C2.27297 32.4079 2.11815 32.042 2.11815 31.655L2.11815 12.5672C2.11815 12.1802 2.27297 11.8214 2.54741 11.5399C2.82185 11.2655 3.19481 11.1108 3.58889 11.1108H7.32186C7.90593 11.1108 8.37741 10.6394 8.37741 10.0554C8.37741 9.4714 7.90593 9 7.32186 9L3.58889 9C2.63185 9 1.73111 9.3729 1.05556 10.0413C0.38 10.7168 0 11.6103 0 12.5601L0 31.6621C0 32.6119 0.372963 33.5125 1.05556 34.1809C1.73111 34.8493 2.63185 35.2222 3.58889 35.2222H7.32186C7.90593 35.2222 8.37741 34.7508 8.37741 34.1668C8.37741 33.5829 7.90593 33.1115 7.32186 33.1115Z"
        fill="white"
        fillOpacity="0.2"
      />
    </Icon>
  );
}
