export const auth = {
  continueWithApple: "Continue with Apple",
  continueWithFacebook: "Continue with Facebook",
  continueWithGoogle: "Continue with Google",
  continueWithEmail: "Continue with Email",
  email: "Email",
  login: "Log in",
  password: "Password",
  signInToAgree: "By signing up you agree to the",
  terms: "terms",
  signUp: "Sign up",
  notNow: "Not now",
  orContinueWith: "or continue with",
  loginFailed: "Login failed, please try again",
  invalidEmailFormat: "Invalid email format",
  errorAuthenticatingWithApple:
    "Error authenticating with Apple, please try again",
  errorAuthenticatingWithFacebook:
    "Error authenticating with Facebook, please try again",
  errorAuthenticatingWithGoogle:
    "Error authenticating with Google, please try again",

  alreadyHaveAnAccount: "Already have an account?",
  enterYourEmail: "Enter your email",
  createAPassword: "Create a password",
  enterAUsername: "Enter a username",
  createAccount: "Create account",
  emailIsRequired: "Email is required",
  passwordIsRequired: "Password is required",
  usernameIsRequired: "Username is required",
  passwordMustBeAtLeast8Characters: "Password must be at least 8 characters",
  passwordMustContainNumbers: "Password must contain numbers",
  passwordMustContainOneSymbol: "Password must contain at least one symbol",
  forgotPassword: "Forgot password?",
  valueProps: {
    saveReadingHistory: "Save reading progress across all devices",
    getAlerts: "Get alerts for new series you follow.",
    getPowerfulRecommendations: "Powerful personal recommendations.",
  },
  welcomeText: {
    welcomeToGlobalComix: "Welcome to GlobalComix",
    createFreeAccount: "Create Free Account, Stay in the Loop",
    getNotified: "Get notified whenever {{artist}} updates",
    readTrackAndDiscover: "Read, Track, and Discover — All in One Place",
    stayConnected: "Stay Connected to the Stories You Love",
    joinUniverseOfReaders:
      "Join the Universe of Readers — Free Account, Infinite Stories",
    saveReadingProgressAndExplore:
      "Save your reading progress, explore endlessly — Sign up for free!",
    saveReadingProgressWithFreeAccount:
      "Save your reading progress with a free account",
    saveReadingProgressAndJoinCommunity:
      "Save your progress and join the community",
  },

  signUpHeader: {
    saveYourReadingProgress: "Save your reading progress?",
  },
  createAFreeAccount: "Create a free account",
};
