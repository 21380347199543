export const CUSTOM_CARD_TYPES = {
  hero: "custom-hero",
  featuredCarousel: "featured-series-row",
  featuredSeriesRoadblock: "featured-series-roadblock",
  streamLinks: "stream-links",
} as const;

export const CONTEXT_ENTITY_TYPES = {
  artist: 2,
  comic: 3,
  release: 4,
};

export const RELEASE_CALENDAR_MAX_WIDTH = 1920;
