import { Icon, IconProps } from "@chakra-ui/react";

export function SquiggleIconBox(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 70 6">
      <path
        d="M1 1H69L23.2951 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
