import { ImmutableObject } from "@hookstate/core";
import { Release } from "../../../../types";
import { useMobileBreakpoint } from "../../../../utils/useBreakpoints";
import { Button, Flex, Text } from "@chakra-ui/react";
import { DownloadIcon } from "../../../../components/icons/reader/DownloadIcon";
import { PrintPagesIcon } from "../../../../components/icons/reader/PrintPagesIcon";
import { DonateIcon } from "../../../../components/icons/reader/DonateIcon";
import React from "react";
import { t } from "i18next";
import { useUserData } from "../../../../hooks/useUserData";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";

interface CTAButtonsProps {
  releaseData: ImmutableObject<Release>;
}
export function CTAButtons({ releaseData }: CTAButtonsProps) {
  const { userData } = useUserData();
  const isMobile = useMobileBreakpoint();

  const userOwnsRelease =
    releaseData.release_user_purchase?.is_downloadable === 1;
  const userHasFreePromoCode = !!releaseData.access_token_download;

  const { display_price, is_discounted, display_price_discounted } =
    releaseData.price;

  const pdfNavigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.purchasePdf,
  });
  const donateNavigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.donate,
    artistId: releaseData.comic.artist.id,
  });

  const releaseIsForPDFSale = !!releaseData?.pdf_download_sale_available;
  return (
    <Flex
      flexDirection="column"
      paddingTop="48px"
      gap="12px"
      flex={1}
      justifyContent={isMobile ? "flex-end" : "flex-start"}
    >
      {releaseIsForPDFSale && !userHasFreePromoCode && !userOwnsRelease && (
        <CTAButton
          onClick={() => {
            setFunnelModalConfig({
              type: FUNNEL_TYPES.pdf,
              options: {
                purchaseRelease: releaseData,
                navigationContext: pdfNavigationContext,
              },
            });
          }}
          icon={<DownloadIcon />}
          text={`${t("screens.reader.overlay.buyPDF")} (${is_discounted ? display_price_discounted : display_price})`}
          type="primary"
        />
      )}
      {releaseIsForPDFSale && !userOwnsRelease && userHasFreePromoCode && (
        <CTAButton
          onClick={() => {
            window.open(
              `${releaseData.comic.url}/buy-release/${releaseData.id}`,
            );
          }}
          icon={<DownloadIcon />}
          text={t("screens.reader.overlay.downloadPDF")}
          type="primary"
        />
      )}
      {userOwnsRelease && (
        <CTAButton
          onClick={() => {
            window.open(`${userData?.edit_url}downloads`);
          }}
          icon={<DownloadIcon />}
          text={t("screens.reader.overlay.downloadPDF")}
          type="primary"
        />
      )}
      {!!releaseData.tracked_website_url && (
        <CTAButton
          onClick={() => window.open(releaseData?.tracked_website_url)}
          icon={<PrintPagesIcon />}
          text={t("screens.reader.overlay.buyPrintEdition")}
          type="primary"
        />
      )}
      {!!releaseData?.comic.artist.accept_donations && (
        <CTAButton
          icon={<DonateIcon />}
          text={t("screens.reader.overlay.donate")}
          type="primary"
          onClick={() => {
            setFunnelModalConfig({
              type: FUNNEL_TYPES.donate,
              options: {
                modalContext: FunnelContextArea.donate,
                donationArtistId: releaseData.comic.artist.id,
                navigationContext: donateNavigationContext,
              },
            });
          }}
        />
      )}
    </Flex>
  );
}

interface CTAButtonProps {
  onClick?: () => void;
  text: string;
  icon: JSX.Element;
  type: string;
}
function CTAButton({ onClick, icon, text, type }: CTAButtonProps) {
  const isMobile = useMobileBreakpoint();
  return (
    <Button
      size={isMobile ? "sm" : "md"}
      variant={type}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      {React.cloneElement(icon, { width: "18px", height: "18px" })}
      <Text
        fontSize={isMobile ? "10px" : "12px"}
        fontWeight="600"
        lineHeight={isMobile ? "10px" : "12px"}
        textTransform="uppercase"
      >
        {text}
      </Text>
    </Button>
  );
}
