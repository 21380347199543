import { Box, Text } from "@chakra-ui/react";
export function PaymentEyebrow({ text, alt }: { text: string; alt?: boolean }) {
  return (
    <Box
      borderRadius="100px"
      background={alt ? "transparent" : "blaze.blaze"}
      border={alt ? "1px solid" : "none"}
      borderColor={alt ? "white" : "transparent"}
      py="8px"
      px="16px"
      width="fit-content"
    >
      <Text
        fontSize="12px"
        fontWeight="700"
        lineHeight="11px"
        letterSpacing="1px"
        textTransform="uppercase"
      >
        {text}
      </Text>
    </Box>
  );
}
