import { useHookstate } from "@hookstate/core";
import { readerDataStore } from "../../ReaderStateWrapper";
import { Page } from "../../../../types";

export function useReleasePagesData() {
  const releasePagesData = useHookstate(readerDataStore.releasePagesData).get({
    noproxy: true,
  });

  const setReleasesPagesData = (data: Page[]) => {
    readerDataStore.releasePagesData.set(data);
  };

  return { releasePagesData, setReleasesPagesData };
}
