import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const filterList = definePartsStyle({
  root: {
    w: "full",
  },
  button: {
    w: "full",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    p: 0,
    _hover: {
      background: "transparent",
    },
    borderBottom: "1px solid",
    borderColor: "transparent.white.10",
  },
  panel: {
    pl: "40px",
    pr: 0,
  },
});

export const accordionTheme = defineMultiStyleConfig({
  variants: {
    filterList,
  },
});
