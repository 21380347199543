import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'container', 'logo', 'text'
]);

const featureCard = definePartsStyle({
  container: {
    padding: {
      base: "4px 12px",
    },
  },
  logo: {
    height: {
      base: "12px",
    },
    width: {
      base: "12px",
    },
  },
  text: {
    fontSize: {
      base: "8px",
    },
  },
});

const heroCard = definePartsStyle({
  container: {
    padding: {
      base: "5px 18px",
      xl: "6px 21px",
      "3xl": "6px 26px",
      "4xl": "8px 42px",
    },
  },
  logo: {
    height: {
      base: "14px",
      xl: "16px",
      "2xl": "18px",
      "3xl": "20px",
      "4xl": "22px",
    },
    width: {
      base: "14px",
      xl: "16px",
      "2xl": "18px",
      "3xl": "20px",
      "4xl": "22px",
    },
  },
  text: {
    fontSize: {
      base: "12px",
      xl: "15px",
      "2xl": "16px",
      "3xl": "18px",
      "4xl": "20px",
    },
  },
});

export const customCardFacetTheme = defineMultiStyleConfig({
  variants: {
    featureCard,
    heroCard,
  },
});
