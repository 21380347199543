import { Flex } from "@chakra-ui/react";
import { ButtonCarousel } from "../carousel/ButtonCarousel";
import { ComicInterestSelector } from "./ComicInterestSelector";
import {
  ComicCategoryEntity,
  ComicFacetGroupOptionEntity,
} from "../../services/gc/types";
import { useMemo } from "react";
import { ToggleInterestType } from "./types";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

const chunkArray = (
  arr: ComicFacetGroupOptionEntity[] | ComicCategoryEntity[],
  size: number,
): (ComicFacetGroupOptionEntity[] | ComicCategoryEntity[])[] => {
  return arr.reduce(
    (
      result: (ComicFacetGroupOptionEntity[] | ComicCategoryEntity[])[],
      item,
      index,
    ) => {
      const chunkIndex = Math.floor(index / size);
      if (!result[chunkIndex]) result[chunkIndex] = [];
      (
        result[chunkIndex] as (
          | ComicFacetGroupOptionEntity
          | ComicCategoryEntity
        )[]
      ).push(item);
      return result;
    },
    [],
  );
};

export function ComicInterestsCarousel({
  type,
  activeIds,
  toggleInterest,
  interests,
}: {
  type: "comic_types" | "comic_genres" | "comic_facets";
  interests: ComicCategoryEntity[] | ComicFacetGroupOptionEntity[];
  activeIds: number[];
  toggleInterest: ToggleInterestType;
}) {
  const isSmall = useIsSmallBreakpoint();

  const chunkedInterests = useMemo(() => {
    return chunkArray(interests, isSmall ? interests.length : 5);
  }, [activeIds]);

  return (
    <ButtonCarousel width="100%" big slidesToScroll={3} dontFadeButtons>
      {chunkedInterests.map((chunk, i) => (
        <Flex
          key={i}
          flexDirection="column"
          gap="12px"
          width={isSmall ? "100%" : undefined}
        >
          {chunk.map((interest) => {
            const name =
              "display_name" in interest
                ? interest.display_name
                : interest.name;
            return (
              <Flex
                key={interest.id}
                id={`btn${type === "comic_genres" ? "ComicGenre" : "ComicFacet"}-${interest.id}`}
              >
                <ComicInterestSelector
                  key={interest.id}
                  title={name}
                  fullWidth={isSmall}
                  isSelected={
                    activeIds.findIndex((id) => id === interest.id) > -1
                  }
                  onClick={() =>
                    toggleInterest({
                      type,
                      id: parseInt(interest.id + ""),
                    })
                  }
                />
              </Flex>
            );
          })}
        </Flex>
      ))}
    </ButtonCarousel>
  );
}
