import { apiClient } from "../../services/axiosInstance";
import { ServerSingleResponse } from "../../types";

export function toggleAccountInterests(
  type: "comic_types" | "comic_genres" | "comic_facets",
  id: number[],
) {
  return apiClient.post<ServerSingleResponse<boolean>>(
    `/v1/account/interests/${type.replace("_", "-")}/update`,
    { payload: { id }, meta: [] },
  );
}
