import { useHookstate } from "@hookstate/core";
import { initialLoadState } from "../appStore";
import { useEffect } from "react";

export function useTitleOnNavigation(title?: string | null) {
  const state = useHookstate(initialLoadState).get({
    noproxy: true,
  });

  useEffect(() => {
    if (state.isInitialLoad) {
      initialLoadState.isInitialLoad.set(false);
    } else {
      if (title) {
        document.title = title;
      }
    }
  }, [title]);
}
