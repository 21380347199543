import { Text, Flex } from "@chakra-ui/react";
import { t } from "i18next";
import { EndOfReleaseCTA } from "./EndOfReleaseCTA";
import { EndOfComicCTA } from "./EndOfComicCTA";
import { useReleaseData } from "../../hooks/hookstate/useReleaseData";

interface CallToActionProps {
  hasNextRelease: boolean | undefined;
}
export function EndCallToAction({ hasNextRelease = false }: CallToActionProps) {
  const { releaseData } = useReleaseData();

  return (
    <Flex
      width="100%"
      alignItems="center"
      pt="32px"
      pb="60px"
      px="24px"
      flexDirection="column"
      gap="24px"
      borderBottomRadius="12px"
      background="inherit"
      position="relative"
    >
      <Text
        fontWeight="600"
        fontSize="16px"
        textAlign="center"
        color="reader.neutral.400"
        lineHeight="24px"
        maxWidth="260px"
      >
        {hasNextRelease
          ? t("screens.reader.interstitial.follow_and_get_notified")
          : t("screens.reader.interstitial.plenty_more_to_explore")}
      </Text>
      {hasNextRelease ? (
        <EndOfReleaseCTA />
      ) : (
        <EndOfComicCTA releaseData={releaseData} />
      )}
    </Flex>
  );
}
