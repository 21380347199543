import { Icon, IconProps } from "@chakra-ui/react";

export function PriceTagIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8729 3.68153C12.5582 3.36675 12.1198 3.20805 11.6765 3.24835L5.19467 3.83761C4.47354 3.90317 3.90219 4.47452 3.83663 5.19565L3.24738 11.6775C3.20707 12.1208 3.36578 12.5591 3.68056 12.8739L11.1275 20.3209C11.7133 20.9067 12.6631 20.9067 13.2489 20.3209L20.3199 13.2498C20.9057 12.6641 20.9057 11.7143 20.3199 11.1285L12.8729 3.68153ZM11.767 4.24425C11.9148 4.23081 12.0609 4.28371 12.1658 4.38864L19.6128 11.8356C19.8081 12.0309 19.8081 12.3475 19.6128 12.5427L12.5418 19.6138C12.3465 19.8091 12.0299 19.8091 11.8346 19.6138L4.38766 12.1668C4.28274 12.0619 4.22984 11.9158 4.24327 11.768L4.83253 5.28618C4.85438 5.04581 5.04483 4.85536 5.28521 4.8335L11.767 4.24425ZM9.71297 8.30022C9.32244 7.90969 8.68928 7.90969 8.29875 8.30022C7.90823 8.69074 7.90823 9.32391 8.29875 9.71443C8.68928 10.105 9.32244 10.105 9.71297 9.71443C10.1035 9.32391 10.1035 8.69074 9.71297 8.30022Z"
        fill="currentColor"
      />
    </Icon>
  );
}
