import { Icon, IconProps } from "@chakra-ui/react";

export function DoubleLayoutIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 74 56" {...props}>
      <path
        d="M70 0H4C1.79 0 0 1.79 0 4V52C0 54.21 1.79 56 4 56H70C72.21 56 74 54.21 74 52V4C74 1.79 72.21 0 70 0ZM35.5 53H4C3.45 53 3 52.55 3 52V4C3 3.45 3.45 3 4 3H35.5V53ZM71 52C71 52.55 70.55 53 70 53H38.5V3H70C70.55 3 71 3.45 71 4V52Z"
        fill="#B8BCC6"
      />
    </Icon>
  );
}
