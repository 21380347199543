import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { forwardRef, Fragment, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import {
  useIsSmallBreakpoint,
  useMobileBreakpoint,
} from "../../../utils/useBreakpoints";
import { useEnsureFocus } from "../../../hooks/useEnsureFocus";
import { ChatIcon } from "../../icons/ChatIcon";
import {
  GET_LIST_QUERY_KEY,
  GET_UNSEEN_COUNT_QUERY_KEY,
  getList,
  getUnseenCount,
  postMarkAllSeen,
  postMarkAsSeen,
} from "../api/conversationsApi";
import { useIsOverlayOpen } from "../../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import { useIsSidebarOpen } from "../../../hooks/useIsSidebarOpen";
import { isMobile } from "react-device-detect";
import { NAVBAR_HEIGHT } from "../../../screens/root/constants";

export function ConversationMenu() {
  const [t] = useTranslation();
  const isMobileScreen = useMobileBreakpoint();
  const { isOverlayOpen: isReaderOverlayOpen } = useIsOverlayOpen();
  const popoverRef = useRef(null);
  const ensureFocus = useEnsureFocus();
  const { setIsSidebarOpen } = useIsSidebarOpen();
  const isSmall = useIsSmallBreakpoint();

  // When the popover appears, the converstions box should be focused.
  // However, the container is still animating and hidden,
  // so it can't be focused immediately. This code intercepts the
  // focus call and ensures the input gains focus once it is visible.
  const focusHandler = useRef({
    focus: () => {
      if (!popoverRef.current) {
        return;
      }
      ensureFocus(popoverRef.current);
    },
  });

  const invalidateConversationsUnseenCount = useInvalidateQueries(
    GET_UNSEEN_COUNT_QUERY_KEY,
  );

  const { mutate: triggerRequest } = useMutation({
    mutationFn: postMarkAllSeen,
    onSuccess: invalidateConversationsUnseenCount,
  });

  const handleMarkAllAsSeen = useCallback(
    () => triggerRequest(),
    [triggerRequest],
  );

  return (
    <Popover gutter={1} variant="navbar" initialFocusRef={focusHandler} isLazy>
      <PopoverTrigger>
        <ConversationTrigger
          onClick={() => {
            if (isSmall) {
              setIsSidebarOpen(false);
            }
            handleMarkAllAsSeen();
          }}
        />
      </PopoverTrigger>
      <Portal>
        <Box
          sx={{
            "@supports (-webkit-touch-callout: none)": {
              height: "-webkit-fill-available",
            },
          }}
          zIndex={isReaderOverlayOpen ? 1401 : 1}
          position="relative"
          w="full"
          h="full"
        >
          <PopoverContent
            ref={popoverRef}
            variants={{
              enter: { opacity: 1, top: 0, right: 0 },
              exit: { opacity: 0, top: -NAVBAR_HEIGHT, right: 0 },
            }}
            height={isMobileScreen ? "100%" : undefined}
            maxHeight={isMobileScreen ? "100%" : "90%"}
            width={isMobileScreen ? "100%" : undefined}
            borderRadius={isMobileScreen ? 0 : undefined}
          >
            <PopoverHeader>
              <Flex direction="row" justify="space-between">
                <Text fontSize="20px" lineHeight="26px" fontWeight="bold">
                  {t("components.navigation.conversations.title")}
                </Text>
                <Flex
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  gap={2}
                  color="neutral.300"
                  lineHeight="20px"
                  fontSize="sm"
                >
                  <Box>
                    <Link variant="brand" href="/my/inbox/new-conversation">
                      {t("components.navigation.conversations.newMessage")}
                    </Link>
                  </Box>
                </Flex>
              </Flex>
            </PopoverHeader>
            <PopoverBody>
              <ConversationList />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}

const ConversationTrigger = forwardRef<HTMLDivElement, ButtonProps>(
  ({ ...props }, ref) => {
    const { data } = useQuery({
      queryKey: GET_UNSEEN_COUNT_QUERY_KEY,
      queryFn: getUnseenCount,
      refetchOnWindowFocus: false,
    });

    const count = data?.data.payload.results.unseen_conversations_count ?? 0;

    return (
      <Flex height="full" ref={ref} alignItems="flex-end">
        <Tooltip
          variant="navbar"
          label="Messages"
          aria-label="messages-tooltip"
          isDisabled={!!props["aria-expanded"]}
        >
          <Button aria-label="Conversations Menu" variant="navbar" {...props}>
            {count > 0 && (
              <Box
                position="absolute"
                top="4px"
                right="21px"
                borderRadius="50%"
                width="4px"
                height="4px"
                background="blaze.blaze"
              />
            )}
            <ChatIcon w={6} h={6} color="neutral.white" />
          </Button>
        </Tooltip>
      </Flex>
    );
  },
);

function ConversationList() {
  const { isLoading, data } = useQuery({
    queryKey: GET_LIST_QUERY_KEY,
    queryFn: getList,
    refetchOnWindowFocus: false,
  });

  const results = data?.data.payload.results ?? [];

  const invalidateConversationList = useInvalidateQueries(GET_LIST_QUERY_KEY);

  const { mutate: markConversationAsSeen } = useMutation({
    mutationFn: postMarkAsSeen,
    onSuccess: invalidateConversationList,
  });

  return (
    <Flex
      direction="column"
      gap={4}
      overflow="auto"
      paddingBottom={isMobile ? "60px" : undefined}
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center">
          <Spinner color="blaze.blaze" size="md" />
        </Flex>
      ) : results.length ? (
        results.map((conversation, i) => (
          <Fragment key={`/my/inbox/${conversation.thread_id}`}>
            <Link
              onClick={() => markConversationAsSeen(conversation.thread_id)}
              href={`/my/inbox/${conversation.thread_id}`}
            >
              <Flex
                direction="row"
                gap={2}
                cursor="pointer"
                justifyContent="space-between"
              >
                <Flex direction="row" alignItems="center" gap={4}>
                  <Flex height="full" alignItems="flex-start" gap={4}>
                    <Flex height={8} alignItems="center">
                      <Box
                        background={
                          conversation.is_read ? "none" : "blaze.blaze"
                        }
                        height="10px"
                        width="10px"
                        borderRadius="50%"
                      ></Box>
                    </Flex>
                    <Flex
                      width={8}
                      height={8}
                      alignItems="center"
                      justifyContent="center"
                      border="2px solid"
                      borderColor="transparent.white.20"
                      borderRadius="50%"
                      background="linear-gradient(44.55deg, #000000 0.78%, #2A2E37 100%);"
                    >
                      <Image
                        loading="lazy"
                        borderRadius="50%"
                        width={8}
                        height={8}
                        src={conversation.last_author.avatar_tiny_url}
                        alt={`Avatar for ${conversation.last_author.name}`}
                      />
                    </Flex>
                  </Flex>
                  <Flex direction="column" gap={2}>
                    <Flex direction="row" alignItems="center" gap={2}>
                      <Text color="info.200" fontWeight="bold" fontSize="xs">
                        {conversation.last_author.name}
                      </Text>
                      <Text color="neutral.400" fontSize="xs">
                        |
                      </Text>
                      <Text color="neutral.400" fontSize="xs">
                        {conversation.last_post_time_ago}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text
                        color="neutral.300"
                        fontSize="sm"
                        fontWeight="bold"
                        lineHeight="18px"
                      >
                        {conversation.name}
                      </Text>
                      <Text
                        color="neutral.300"
                        fontSize="sm"
                        noOfLines={2}
                        lineHeight="18px"
                      >
                        {conversation.body}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Link>
            {i < results.length - 1 ? (
              <Box minHeight="1px" w="full" background="neutral.800" />
            ) : null}
          </Fragment>
        ))
      ) : (
        <ConversationsEmptyState />
      )}
    </Flex>
  );
}

function ConversationsEmptyState() {
  const [t] = useTranslation();
  return (
    <Flex direction="row" justifyContent="center">
      <Text color="neutral.300" fontSize="md" fontWeight="bold">
        {t("components.navigation.conversations.empty")}
      </Text>
    </Flex>
  );
}
