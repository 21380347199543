import { appStore } from "../../appStore";
import { useHookstate } from "@hookstate/core";
import { ToggleInterestType } from "./types";
import { ComicFacetGroupEntity } from "../../services/gc/types";
import { useMemo } from "react";
import { ComicInterestsCarousel } from "./ComicInterestsCarousel";

export function ComicThemesStep({
  activeIds,
  toggleInterest,
}: {
  activeIds: number[];
  toggleInterest: ToggleInterestType;
}) {
  const comicFacets = useHookstate(
    appStore.init.comic_facet_groups,
  ).get() as ComicFacetGroupEntity[];

  const comicThemes = useMemo(() => {
    return comicFacets.filter((facet) => facet.id === 2)[0]
      .comic_facet_group_options;
  }, [comicFacets]);

  return (
    <ComicInterestsCarousel
      type="comic_facets"
      activeIds={activeIds}
      toggleInterest={toggleInterest}
      interests={comicThemes}
    />
  );
}
