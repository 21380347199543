import { useEffect, useState } from "react";

export const useTapHold = ({
  onHold,
  onBlur,
  holdDuration = 1000,
}: {
  onHold: () => void;
  onBlur: () => void;
  holdDuration?: number;
}) => {
  const [isHolding, setIsHolding] = useState(false);

  useEffect(() => {
    if (isHolding) {
      const timeout = setTimeout(() => {
        onHold();
      }, holdDuration);
      return () => clearTimeout(timeout);
    }
  }, [isHolding, holdDuration, onHold]);

  const startHold = () => {
    setIsHolding(true);
  };

  const stopHold = () => {
    setIsHolding(false);
  };

  const handleBlur = () => {
    onBlur();
    setIsHolding(false);
  };

  return {
    onMouseDown: startHold,
    onMouseUp: stopHold,
    onMouseLeave: stopHold,
    onBlur: handleBlur,
    onTouchStart: startHold,
    onTouchEnd: stopHold,
    onTouchCancel: stopHold,
  };
};
