import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { Artist, LayoutStyle } from "../../types";
import { ShowMoreSection } from "../../components/containers/ShowMoreSection";
import { useArtistComicsAPI } from "./hooks/useArtistComicsAPI";
import { useState } from "react";
import { ModeToggle } from "../../components/resultsGrid/ModeToggle";
import { useInvalidateQueries } from "../../services/axiosInstance";
import { GET_ARTIST_COMICS_QUERY_KEY } from "./api/artistComicsApi";
import { SortMenus } from "../../components/sortMenus/SortMenus";
import { artistComicsFilterStore } from "./components/ArtistComicsFilters";
import { appStore } from "../../appStore";
import { useSlideInMenu } from "../../components/slideInMenu/hooks/useSlideInMenu";
import { useQuery } from "@tanstack/react-query";
import { ArtistProfileComicsList } from "./components/ArtistProfileComicsList";
import { ArtistProfileHeader } from "./components/ArtistProfileHeader";

interface ArtistProfileProps {}

export function ArtistProfile({}: ArtistProfileProps) {
  const { artistCacheKey } = useSlideInMenu(true);
  const { data: artist } = useQuery<Artist>({
    queryKey: [artistCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isRefetching,
  } = useArtistComicsAPI(artist?.id ?? 0);

  const orders = useHookstate(appStore.init.comic_library_sort_orders).get({
    noproxy: true,
  });

  const invalidate = useInvalidateQueries(GET_ARTIST_COMICS_QUERY_KEY);

  const [mode, setMode] = useState<LayoutStyle["mode"]>("list");

  if (!artist || isLoading || !data)
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        grow={1}
        pt="16px"
        height="100%"
      >
        <Spinner color="blaze.blaze" />
      </Flex>
    );

  return (
    <Flex
      flexDirection="column"
      pb="24px"
      gap="24px"
      pt="65px"
      overflowY="auto"
      height="100%"
      style={{ scrollbarWidth: "thin" }}
      onScroll={(e) => {
        if (!isFetchingNextPage && hasNextPage) {
          const is500pxAboveScrollBottom =
            e.currentTarget.scrollHeight -
              (e.currentTarget.scrollTop + e.currentTarget.clientHeight) <=
            500;

          if (is500pxAboveScrollBottom) {
            fetchNextPage();
          }
        }
      }}
    >
      <ArtistProfileHeader artist={artist} />
      <Flex flexDirection="column" px="24px" gap="24px">
        <Box h="1px" bg="whiteAlpha.200" />
        {artist.description && (
          <ShowMoreSection>
            <Text
              fontSize="14px"
              lineHeight="160%"
              fontWeight={400}
              color="neutral.300"
            >
              {artist.description}
            </Text>
          </ShowMoreSection>
        )}
        <Flex flexDirection="row" justifyContent="space-between">
          <SortMenus
            invalidate={invalidate}
            filterState={artistComicsFilterStore}
            sortOrders={orders}
          />
          <ModeToggle mode={mode} setMode={setMode} />
        </Flex>
        <ArtistProfileComicsList
          data={data}
          isFetchingNextPage={isFetchingNextPage}
          isRefetching={isRefetching}
          mode={mode}
        />
      </Flex>
    </Flex>
  );
}
