import {
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SystemStyleObject,
  Text,
} from "@chakra-ui/react";
import {
  CSSProperties,
  HTMLInputAutoCompleteAttribute,
  HTMLInputTypeAttribute,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { AttentionIcon } from "../icons/AttentionIcon";
import { EyeIcon } from "../icons/EyeIcon";
import { EyeBlindIcon } from "../icons/EyeBlindIcon";

export interface TextFieldProps {
  id?: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  borderRadius?: string;
  onChange?: (value: string) => void;
  error?: string;
  readonly?: boolean;
  onBlur?: (value: string) => void;
  value?: string | number | readonly string[] | undefined;
  height?: CSSProperties["height"];
  variant?: "filled" | "outline";
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  inputPadding?: string;
  autoComplete?: HTMLInputAutoCompleteAttribute | undefined;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  autoFocus?: boolean;
  _hover?: SystemStyleObject | undefined;
  _focusVisible?: SystemStyleObject | undefined;
  background?: string;
  isDisabled?: boolean;
}

export function TextField({
  id,
  type,
  placeholder,
  borderRadius,
  onChange,
  error,
  readonly = false,
  onBlur,
  value,
  height,
  inputMode,
  inputPadding,
  prefix,
  suffix,
  variant = "filled",
  autoFocus,
  autoComplete,
  _hover,
  _focusVisible,
  background,
  isDisabled,
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordField = type === "password";
  const { t } = useTranslation();

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <FormControl isInvalid={!!error}>
      <InputGroup>
        {prefix && (
          <InputLeftElement top="50%" transform="translateY(-50%)">
            <Text fontSize="18px" align={"center"}>
              {prefix}
            </Text>
          </InputLeftElement>
        )}
        <Input
          id={id}
          type={isPasswordField && showPassword ? "text" : type}
          placeholder={placeholder}
          variant={variant}
          textColor={"dune.100"}
          height={height}
          fontFamily="Roboto"
          fontSize="16px"
          value={value}
          fontWeight="500"
          lineHeight="130%"
          inputMode={inputMode}
          background={background ?? "transparent.white.10"}
          borderRadius={borderRadius || "16px"}
          boxShadow="none"
          focusBorderColor="transparent.white.10"
          outline="none"
          transition="all .2s linear"
          readOnly={readonly}
          autoComplete={autoComplete}
          paddingLeft={prefix ? (inputPadding ?? "32px") : undefined}
          paddingRight={suffix ? (inputPadding ?? "32px") : undefined}
          onChange={(e) => {
            onChange?.(e.target.value);
          }}
          _placeholder={{
            color: "dune.600",
          }}
          onBlur={(e) => onBlur?.(e.target.value)}
          _hover={
            _hover ?? {
              backgroundColor: "transparent.white.20",
            }
          }
          _focusVisible={
            _focusVisible ?? {
              outline: "none",
              backgroundColor: "transparent.white.20",
              _placeholder: {
                color: "transparent",
              },
            }
          }
          _invalid={{
            border: "1px solid",
            borderColor: "error.300",
            boxShadow: "0 0 5px 0 var(--chakra-colors-error-300)",
          }}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
        />
        {isPasswordField && (
          <InputRightElement>
            <IconButton
              aria-label={t(
                showPassword
                  ? "components.fields.textField.hidePassword"
                  : "components.fields.textField.showPassword",
              )}
              onClick={handleTogglePasswordVisibility}
              icon={
                showPassword ? (
                  <EyeIcon boxSize={"24px"} color={"dune.600"} />
                ) : (
                  <EyeBlindIcon boxSize={"24px"} color={"dune.600"} />
                )
              }
              background="inherit"
              _hover={{
                background: "inherit",
              }}
            />
          </InputRightElement>
        )}
        {!isPasswordField && error && (
          <InputRightElement top="50%" transform="translateY(-50%)">
            <AttentionIcon color={"error.300"} width="24px" height="24px" />
          </InputRightElement>
        )}
        {suffix && (
          <InputRightElement top="50%" transform="translateY(-50%)">
            {suffix}
          </InputRightElement>
        )}
      </InputGroup>
      {error ? (
        <FormErrorMessage
          fontFamily={"Roboto"}
          fontSize={"14px"}
          lineHeight={"130%"}
          color={"error.300"}
        >
          {error}
        </FormErrorMessage>
      ) : null}
    </FormControl>
  );
}
