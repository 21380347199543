export function formatLargeNumber(number: number) {
  if (number > 0) {
    if (number < 1000) {
      return number + ""; // sub 1000
    } else if (number < 1000000) {
      return Math.round(number / 1000) + "K"; // kilo
    } else if (number < 1000000000) {
      return Math.round(number / 1000000) + "M"; // mega
    } else {
      return Math.round(number / 1000000000) + "B"; // giga
    }
  } else if (number < 0) {
    if (number > -1000) {
      return number + ""; // sub 1000
    } else if (number > -1000000) {
      return Math.round(number / 1000) + "K"; // kilo
    } else if (number > -1000000000) {
      return Math.round(number / 1000000) + "M"; // mega
    } else {
      return Math.round(number / 1000000000) + "B"; // giga
    }
  } else {
    return number;
  }
}
