import { ComicContentCardProps } from "../../types";
import { ComicGridCard } from "./ComicGridCard";
import { ComicListCard } from "./ComicListCard";

export function ComicHorizontalCard(props: ComicContentCardProps) {
  switch (props.layout.style.size) {
    case "s":
    case "m":
    case "l":
      return <ComicGridCard {...props} />;
    case "c_1":
    case "r_list":
      return <ComicListCard {...props} />;
    default:
      console.warn(
        "Unexpected horizontal grid card size",
        props.layout.style.size,
      );
      break;
  }

  return <ComicListCard {...props} />;
}
