import { Text, Box, Button, Flex, Textarea } from "@chakra-ui/react";
import { ImmutableObject } from "@hookstate/core";
import { ReleaseCommentEntity } from "../../../../types";
import { CloseCircleIcon } from "../../../../components/icons/CloseCircleIcon";
import { useEffect, useRef } from "react";
import { useUserData } from "../../../../hooks/useUserData";
import { SendButton } from "../../../../components/buttons/SendButton";
import { SimpleLoginMenu } from "../../../../components/navigation/components/SimpleLoginMenu";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";

interface CommentFormProps {
  newCommentValue?: string;
  postReleaseComment: () => void;
  setNewCommentValue: (value?: string) => void;
  parentComment?: ImmutableObject<ReleaseCommentEntity> | undefined;
}
export function CommentForm({
  newCommentValue,
  setNewCommentValue,
  postReleaseComment,
  parentComment,
}: CommentFormProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { userData } = useUserData();
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.comment,
  });

  useEffect(() => {
    if (parentComment && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [parentComment]);

  return (
    <Box
      height="96px"
      display="flex"
      justifyContent="center"
      alignItems={userData ? "flex-start" : "center"}
      p="24px"
      position="absolute"
      width="100%"
      backdropFilter="blur(18px)"
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      bottom={0}
      right={0}
      flexDirection="column"
    >
      {parentComment && (
        <ParentComment
          parentComment={parentComment}
          setNewCommentValue={setNewCommentValue}
        />
      )}
      {userData ? (
        <Flex width="100%" gap="8px">
          <CommentTextArea
            newCommentValue={newCommentValue}
            setNewCommentValue={setNewCommentValue}
            textareaRef={textareaRef}
          />
          <SendButton onClick={postReleaseComment} />
        </Flex>
      ) : (
        <SimpleLoginMenu navigationContext={navigationContext} />
      )}
    </Box>
  );
}

export function ParentComment({
  parentComment,
  setNewCommentValue,
}: {
  setNewCommentValue: (value?: string) => void;
  parentComment: ImmutableObject<ReleaseCommentEntity>;
}) {
  return (
    <Flex alignItems="center" position="absolute" left="24px" top="-4px">
      <Button
        variant="iconOnly"
        aria-label="Cancel reply"
        onClick={() => setNewCommentValue("")}
        minWidth="none"
      >
        <CloseCircleIcon height="20px" width="20px" />
      </Button>
      <Text
        fontSize="12px"
        lineHeight="18.2px"
        fontWeight="500"
        color="dune.100"
      >
        &nbsp;Replying to&nbsp;{parentComment.author.name}
      </Text>
    </Flex>
  );
}

export function CommentTextArea({
  newCommentValue,
  setNewCommentValue,
  textareaRef,
}: {
  newCommentValue?: string;
  setNewCommentValue: (value?: string) => void;
  textareaRef?: React.Ref<HTMLTextAreaElement>;
}) {
  return (
    <Textarea
      ref={textareaRef}
      placeholder="Add comment"
      resize="none"
      minHeight="none"
      borderRadius="20px"
      outline="none"
      focusBorderColor="neutral.400"
      background="transparent.white.10"
      fontSize="14px"
      height="48px"
      fontWeight="500"
      lineHeight="18.2px"
      color="dune.100"
      value={newCommentValue}
      onChange={(e) => setNewCommentValue(e.target.value)}
    />
  );
}
