import { useHookstate } from "@hookstate/core";
import { rootComponentsStore } from "../components/layouts/RootLayout";
import { useCallback } from "react";

export function useIsSidebarOpen() {
  const isSidebarOpen = useHookstate(rootComponentsStore.isSidebarOpen).get({
    noproxy: true,
  });

  const toggleIsSidebarOpen = useCallback(() => {
    rootComponentsStore.isSidebarOpen.set(!isSidebarOpen);
  }, [isSidebarOpen]);

  const setIsSidebarOpen = useCallback((value: boolean) => {
    rootComponentsStore.isSidebarOpen.set(value);
  }, []);

  return { isSidebarOpen, toggleIsSidebarOpen, setIsSidebarOpen };
}
