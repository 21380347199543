import { Immutable } from "@hookstate/core";
import { forwardRef, useMemo } from "react";
import { FilterTagButton } from "../../../../components/buttons/FilterTag";
import { CreatorIcon } from "../../../../components/icons/CreatorIcon";
import { ArtistTypeEntity } from "../../../../services/gc/types";
import { Option } from "../../../../types";
import { FilterTitle } from "../../../../components/filters/FilterTitle";
import { RadioFilter } from "../../../../components/filters/RadioFilter";

export const ARTIST_TYPE_ALL = "0";

interface ArtistTypeFilterProps {
  value: Immutable<string | undefined>;
  artistTypes: ArtistTypeEntity[];
  onChange: (nextValue: string) => void;
}
export function ArtistTypeFilter({
  value,
  artistTypes,
  onChange,
}: ArtistTypeFilterProps) {
  const artistTypeOptions: Option<string>[] = useMemo(
    () => [
      { label: "All", value: ARTIST_TYPE_ALL },
      ...artistTypes.map((type) => ({
        label: type.name,
        value: "" + type.id,
      })),
    ],
    [],
  );

  return (
    <RadioFilter
      value={value}
      options={artistTypeOptions}
      onChange={onChange}
    />
  );
}

interface ArtistTypeFilterTitleProps {
  isTrigger: boolean;
  artistTypes: ArtistTypeEntity[];
  open?: boolean;
  value?: string;
}
export const ArtistTypeFilterTitle = forwardRef(
  (
    {
      isTrigger,
      artistTypes,
      open,
      value,
      ...props
    }: ArtistTypeFilterTitleProps,
    ref,
  ) => {
    const artistType = artistTypes.find((type) => `${type.id}` == value);
    if (isTrigger) {
      return (
        <FilterTagButton
          icon={<CreatorIcon />}
          popoverRef={ref}
          text="Creator Type"
          isFiltering={artistType?.plural_name}
          {...props}
        />
      );
    }
    return (
      <FilterTitle
        text="Creator Type"
        filterValue={artistType?.plural_name}
        open={open}
      />
    );
  },
);
