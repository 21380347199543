import { AttributionParams } from "../types";

export function useAttributionParams() {
  const params = new URLSearchParams(window.location.search);

  const referrer = document.referrer;

  const originalUrl =
    referrer.includes(window.location.origin) || !referrer ? null : referrer;

  const queryParams: AttributionParams = {
    utm_source: params.get("utm_source"),
    utm_medium: params.get("utm_medium"),
    utm_campaign: params.get("utm_campaign"),
    utm_term: params.get("utm_term"),
    etId: params.get("etId"),
    original_ref: originalUrl,
  };
  return queryParams;
}
