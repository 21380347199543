import { Icon, IconProps } from "@chakra-ui/react";

export function VaultLogo(props: IconProps) {
  return (
    <Icon viewBox="0 0 200 95" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_79)">
        <mask
          id="mask0_1_79"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="200"
          height="95"
        >
          <path
            d="M199.988 0.491821H0V94.8681H199.988V0.491821Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask0_1_79)">
          <path
            d="M29.656 38.2573L24.4892 51.5278L19.2937 38.2573H0L24.4892 93.3049L48.9784 38.2573H29.6503H29.656Z"
            fill="currentColor"
          />
          <path
            d="M147.872 19.3429V92.9657H168.166V0.491821L147.872 19.3429Z"
            fill="currentColor"
          />
          <path
            d="M190.638 62.4879V61.1832H199.466V39.9987H190.638V0.491821L170.344 19.3429V65.1776C170.344 91.7531 194.448 93.6956 194.448 93.6956H200V72.3157H197.759C190.23 72.3157 190.649 62.4821 190.649 62.4821L190.638 62.4936V62.4879Z"
            fill="currentColor"
          />
          <path
            d="M96.5373 66.1086C96.5373 50.6312 84.0599 38.0848 68.6801 38.0848C53.3004 38.0848 40.823 50.6312 40.823 66.1086C40.823 81.5861 53.3004 94.1324 68.6801 94.1324C70.3239 94.1324 71.9274 93.983 73.5021 93.7014V72.4019C72.2205 73.4422 70.5997 74.0629 68.8181 74.0629C64.6743 74.0629 61.3179 70.6777 61.3179 66.4994C61.3179 62.3212 64.6685 58.936 68.8181 58.936C72.9676 58.936 76.0884 62.1085 76.2953 66.1029H76.3183V92.9485H96.543V66.1144L96.5373 66.1086Z"
            fill="currentColor"
          />
          <path
            d="M125.624 39.7976V66.5168C125.624 67.9536 125.647 69.4077 125.653 70.8445C125.665 71.0226 125.733 72.833 124.354 73.925C123.596 74.5227 122.774 74.6089 122.417 74.6204H121.337C120.98 74.6089 120.159 74.5285 119.4 73.925C118.003 72.8445 118.09 71.0226 118.101 70.8445C118.101 69.4077 118.124 67.9536 118.13 66.5168V39.7976H97.8879V70.6376C97.8879 71.9192 97.9798 82.9023 107.084 89.9715C112.48 94.1612 118.319 94.8911 121.883 94.8682C125.446 94.8911 131.285 94.167 136.682 89.9715C145.786 82.8908 145.889 71.9077 145.878 70.6376V39.7976H125.636H125.624Z"
            fill="currentColor"
          />
          <path
            d="M193.034 35.8492V38.0331H192.557V35.8492H191.977V35.3894H193.621V35.8492H193.04H193.034ZM193.764 38.0331L194.327 35.1537L195.201 37.1078L196.075 35.1537L196.638 38.0331H196.149L195.908 36.5963L195.201 38.0791L194.494 36.5963L194.253 38.0331H193.764Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_79">
          <rect
            width="200"
            height="94.3763"
            fill="currentColor"
            transform="translate(0 0.491821)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
